import React, { useEffect, useState } from "react";
import "../../assets/css/PurchaseOrderPrint.css";
import { useParams } from "react-router-dom";
import ApiUrl from "../Environment/ApiUrl";
import axios from "axios";
import { ToWords } from "to-words";

const PurchaseOrderPrint = () => {
  // CSS for both screen display and print
  const styles = {
    page: {
      width: "210mm",
      height: "297mm",
      padding: "5mm",
      margin: "0 auto",
      backgroundColor: "white",
      boxSizing: "border-box",
      fontFamily: "Arial, sans-serif",
      fontSize: "10pt",
      lineHeight: "1.3",
      overflow: "hidden",
    },
    title: {
      fontSize: "16pt",
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "10px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      // marginBottom: '10px',
      // border: '1px solid black',
    },
    th: {
      border: "1px solid black",
      padding: "5px",
      backgroundColor: "#f2f2f2",
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "10pt",
    },
    td: {
      border: "1px solid black",
      padding: "5px",
      textAlign: "left",
      fontSize: "10pt",
      verticalAlign: "top",
    },
    bold: {
      fontWeight: "bold",
    },
    paragraphMargin: {
      margin: "2px 0",
    },
    centralColumn: {
      textAlign: "center",
    },
    rightColumn: {
      textAlign: "center",
    },
    logoContainer: {
      width: "90%",
      paddingBottom: "30px",
      textAlign: "center",
    },

    logo: {
      width: "100%",
      height: "80px",
      marginTop: "30px",
    },
    signatureLine: {
      width: "150px",
      borderTop: "1px solid #000",
      margin: "30px auto 5px",
    },
    marginleft: {
      marginleft: "400px",
    },
  };

  React.useEffect(() => {
    // Add print-specific styles when component mounts
    const style = document.createElement("style");
    style.innerHTML = `
      @media print {
        @page {
          size: A4 portrait;
          margin: 0;
        }
     
    `;
    document.head.appendChild(style);

    return () => {
      // Cleanup when component unmounts
      document.head.removeChild(style);
    };
  }, []);

  const [responseData, setResponseData] = useState(null); // Store a single object instead of an array
  const { purchase_order_no, id } = useParams();
  const [words, setwords] = useState("");

  console.log("Sales Order No:", purchase_order_no);
  console.log("ID:", id);

  useEffect(() => {
    fetchData();
  }, [id]); // Re-fetch data if `id` changes

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: "Rupees",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paisa",
          symbol: "",
        },
      },
    },
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/purchaseorder/${id}`); // Fetch specific sales order
      console.log("Response Data:", response.data);
      setResponseData(response.data);

      const totalamount = toWords.convert(response.data[0]?.nettotal);
      console.log("the total amopunt", totalamount);
      setwords(totalamount);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  React.useEffect(() => {
    // Add print-specific styles when component mounts
    const style = document.createElement("style");
    style.innerHTML = `
        @media print {
          @page {
            size: A4 portrait;
            margin: 0;
          }
       
      `;
    document.head.appendChild(style);

    return () => {
      // Cleanup when component unmounts
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div className="marginleft">
      <div className="print-container" style={styles.page}>
        <div style={{ padding: "2%" }} className="salesorder">
          PURCHASE ORDER BILL
        </div>

        {/* Header Section with Company and   */}
        <table style={styles.table}>
          <tbody>
            <tr>
              <td style={{ ...styles.td, width: "50%" }}>
                <p style={{ ...styles.bold, ...styles.paragraphMargin }}>
                  Branch: ERODE
                </p>
                <p style={styles.paragraphMargin}>
                  29/30, GH BACK SIDE, Pavadai St,
                </p>
                <p style={styles.paragraphMargin}>Kaikolar Thottam, Erode,</p>
                <p style={styles.paragraphMargin}>Tamil Nadu 638001</p>
                <p style={styles.paragraphMargin}>GSTIN: 33ABOCS7325J1ZY</p>
                <p style={styles.paragraphMargin}>
                  Email: support@skimitarautomation.com
                </p>
                <p style={styles.paragraphMargin}>Phone: 7402574008</p>
              </td>
              <td style={{ ...styles.td, width: "50%" }}>
                <div style={styles.logoContainer}>
                  <img
                    alt="invoice logo"
                    style={styles.logo}
                    src={require("../../assets/img/scimitar logo slogan.png")}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            width: "100%",
            borderLeft: "1px solid black",
          }}
        >
          <div style={{ width: "751px" }}>
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Client Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>To:</span>
                  </td>
                  <td>{responseData?.supplier_name}</td>
                </tr>
                <tr>
                  <td>
                    <span>Address:</span>
                  </td>
                  <td>THALLY ROAD, HOSUR, 635114</td>
                </tr>
                <tr>
                  <td>
                    <span>GSTIN/UIN:</span>
                  </td>
                  <td>33AAMCA4282H1ZN</td>
                </tr>
                <tr>
                  <td>
                    <span>Email:</span>
                  </td>
                  <td>{responseData?.email}</td>
                </tr>
                <tr>
                  <td>
                    <span>Phone:</span>
                  </td>
                  <td>{responseData?.mobile}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ borderLeft: "" }}></div>
          <div style={{ width: "750px" }}>
            <table style={{ width: "100%" }}>
              <tbody style={{ width: "1000px", border: "", borderTop: "none" }}>
                <tr className="" style={{ borderLeft: "1px solid black" }}>
                  <td style={{ borderRight: "1px solid black" }}>
                    <span style={styles.bold}>INVOICE NO:</span>
                  </td>
                  <td
                    style={{ width: "49%", borderRight: "1px solid black" }}
                    className="text-center"
                  >
                    {responseData?.purchase_order_no}
                  </td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td style={{ border: "1px solid black" }}>
                    <span style={styles.bold}>DATE:</span>
                  </td>
                  <td style={{}} className="text-center">
                    {/* {responseData.date} */}
                    {responseData ? formatDate(responseData?.date) : "Loading"}
                  </td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td style={{ border: "1px solid black" }}>
                    <span style={styles.bold}>PO NO:</span>
                  </td>
                  <td style={{}} className="text-center">
                    {responseData?.purchase_order_no}
                  </td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td style={{ border: "1px solid black" }}>
                    <span style={styles.bold}>PO DATE:</span>
                  </td>
                  <td
                    style={{ border: "1px solid black" }}
                    className="text-center"
                  >
                    {/* {responseData.payment_due_date} */}
                    {responseData
                      ? formatDate(responseData?.payment_due_date)
                      : "Loading"}
                  </td>
                </tr>

                <tr
                  style={{
                    borderLeft: "1px solid black",
                    borderBottom: "1px solid black",
                    borderRight: "1px solid black",
                  }}
                >
                  <center>
                    <th colSpan="">Terms and conditions</th>
                  </center>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      padding: "5px",
                    }}
                  >
                    <p style={{ margin: "5px 0" }}>
                      1. Goods Once Sold Cannot Be Taken Back
                    </p>
                    <p style={{ margin: "5px 0" }}>
                      2. Subject to Erode Jurisdiction
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Invoice Items */}
        <table style={{ height: "30vh" }}>
          <thead>
          <tr>
              <th style={{ ...styles.th, width: "5%" }}>Sl</th>
              <th style={{ ...styles.th, width: "28.5%" }}>Description</th>
              <th style={{ ...styles.th, width: "9%" }}>HSN/SAC</th>
              <th style={{ ...styles.th, width: "9%" }}>Qty</th>
              <th style={{ ...styles.th, width: "13%" }}>Rate</th>
              <th style={{ ...styles.th, width: "15%" }}>BASIC</th>
              <th style={{ ...styles.th, width: "3%" }}>18% TAX</th>
              <th style={{ ...styles.th, width: "20%" }}>Total</th>
            </tr>
          </thead>
          <tbody>

            {responseData?.items?.map((item, index) => (
              <tr key={item._id}>
                <td style={{ ...styles.td, ...styles.centralColumn }}>
                  {index + 1} {/* Serial Index */}
                </td>
                <td style={{ ...styles.td, ...styles.centralColumn }}>
                  {item.product_name} {/* Product Name */}
                </td>
                <td style={{ ...styles.td, ...styles.centralColumn }}>
                  {item.hsn_code} {/* HSN Code */}
                </td>
                <td style={{ ...styles.td, ...styles.centralColumn }}>
                  {item.quantity} {/* Quantity */}
                </td>
                <td style={{ ...styles.td, ...styles.centralColumn }}>
                  {item.price} {/* Rate */}
                </td>
                <td style={{ ...styles.td, ...styles.centralColumn }}>
                  {item.price * item.quantity} {/* Basic Amount */}
                </td>
                <td style={{ ...styles.td, ...styles.centralColumn }}>
                  {(item.price * item.quantity * (item.tax / 100)).toFixed(2)} {/* Tax Amount */}
                </td>
                <td style={{ ...styles.td, ...styles.centralColumn }}>
                  {item.total} {/* Total Amount */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div
          style={{
            display: "flex",
            width: "100%",
            borderLeft: "1px solid black",
          }}
        >
          <div style={{ width: "751px" }}>
            <table>
              <tbody>
                <tr>
                  <p style={styles.paragraphMargin}>
                    <span style={styles.bold}>Amount in Words:</span>{" "}
                  </p>
                </tr>
                <tr>
                  <p>Five Thousand Nine Hundred Rupees Only</p>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div style={{ borderLeft: "1px solid black" }}></div> */}
          <div
            style={{ width: "750px", borderRight: "1px solid black" }}
            className="bordernone"
          >
            <table style={{ width: "100%" }} className="">
              <tbody style={{ width: "1000px", border: "", borderTop: "none" }}>
                <tr className="">
                  <td
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    <span style={styles.bold}>Subtotal:</span>
                  </td>
                  <td
                    style={{ borderRight: "1px solid black", width: "55.5%" }}
                  >
                    5000
                  </td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td style={{ border: "1px solid black" }}>
                    <span style={styles.bold}>CGST:</span>
                  </td>
                  <td style={{ border: "1px solid black" }}>450</td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td style={{ border: "1px solid black" }}>
                    <span style={styles.bold}>SGST:</span>
                  </td>
                  <td style={{ border: "1px solid black" }}>450</td>
                </tr>
                <tr style={{ borderLeft: "1px solid black" }}>
                  <td style={{ borderRight: "1px solid black" }}>
                    <span style={styles.bold}>Total:</span>
                  </td>
                  <td style={{ borderRight: "1px solid black" }}>5900</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Bank Details */}
        <table style={styles.table}>
          <tbody>
            <tr>
              <td style={styles.td}>
                <p style={{ ...styles.bold, ...styles.paragraphMargin }}>
                  Bank Details:
                </p>
                <p style={styles.paragraphMargin}>
                  NAME: HDFC{" "}
                  <span style={{ marginLeft: "50%" }}>
                    BRANCH:CATCHERY STREET
                  </span>{" "}
                </p>
                <p style={styles.paragraphMargin}>
                  ACC NO: 50200106369528{" "}
                  <span style={{ marginLeft: "36.5%" }}>IFSC: HDFC0001589</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        {/* Footer with Declaration and Signature */}
        <table style={styles.table}>
          <thead>
            <tr style={{ borderLeft: "1px solid" }}>
              <th style={{ width: "50%", borderRight: "1px solid black" }}>
                Declaration
              </th>
              <th
                style={{
                  width: "50%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                }}
              >
                For Skimitar Hindusthan Pvt Ltd
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ ...styles.td, borderLeft: "1px solid black" }}>
                <p style={styles.paragraphMargin}>
                  Above information is true to my knowledge!
                </p>
                <p style={styles.paragraphMargin}>
                  For clarification, don't hesitate to contact us.
                </p>
              </td>
              <td
                style={{
                  ...styles.td,
                  textAlign: "center",
                  borderRight: "1px solid black",
                }}
              >
                <div style={styles.signatureLine}></div>
                <p style={styles.paragraphMargin}>Authorized Signatory</p>
              </td>
            </tr>
            <tr>
              <td
                colSpan="2"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  paddingTop: "10px",
                  border: "1px solid black",
                  width: "100%",
                  padding: "1%",
                }}
              >
                Thank You
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PurchaseOrderPrint;
