

import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import ApiUrl from '../Environment/ApiUrl';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";



const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(odd) {
    background-color: #f2f2f2; /* Light gray for even rows */
  }

  tr:nth-child(even) {
    background-color: #ffffff; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (

    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};



function Holiday() {


  const [responseData, setResponseData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [previllages, setprevillages] = useState({})
  const [role_id, setroleid] = useState('');


  const [addData, setAddData] = useState({


    Date: '',
    Holiday_name: ''
  });


  const [updateData, setUpdateData] = useState({
    Date: "",
    Holiday_name: ''
  });

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);

  }, []);


  // useEffect(() => {
  //   getprevillages();
  //   gettoken();
  // }, []);

  // const gettoken = async () => {
  //   const token = localStorage.getItem("token");
  //   // console.log("the toekn in the localStorage is : ",token);
  //   try {
  //     const response = await axios.post(ApiUrl + "/get_detail", {
  //       token: token,
  //     });
  //     console.log("token detail from server:", response.data);
  //     // setResponseData(response.data);

  //     console.log("The role id is : ", response.data.detail.rolename);
  //     setroleid(response.data.detail.rolename);
  //     console.log("the role_id is : ", role_id);

  //     localStorage.setItem("role_id", response.data.detail.rolename);

  //     const id = response.data.detail.rolename;


  //     if (id) {
  //       // try {
  //       //     const response = await axios.get(apiUrl + `/role_2/${id}`);

  //       //     console.log("the reponse list in brach is : ", response.data.modules);

  //       //     const modules = response.data.modules;

  //       //     const filtered_access = modules.filter(
  //       //         (item) => item.name == "Branch"
  //       //     );

  //       //     console.log("the modules is : ", filtered_access[0].actions);

  //       //     setprevillages(filtered_access[0].actions);

  //       //     console.log("the assigned previllages is : ", previllages.add);
  //       // } catch (error) {
  //       //     console.error("Error fetching data:", error.message);
  //       // }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };

  // const getprevillages = () => {
  //   const username = JSON.parse(localStorage.getItem("user_previllages"));
  //   console.log("tyhe username get by local stroage : ", username.modules);

  //   const total_previlages = username.modules;

  //   const new_enquiry = total_previlages.find((item) => item.name == "Holiday")

  //   console.log("the new_enquiry is : ", new_enquiry.actions);

  //   setprevillages(new_enquiry.actions)
  // }





  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(ApiUrl + "/holiday");
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());

      if (response && response.status === 200) {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }


    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };





  const handlesubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }
    try {
      setLoading(true);
      const formData = {

        Date: addData.Date,
        Holiday_name: addData.Holiday_name


      }
      const response = await axios.post(ApiUrl + "/Holiday", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("added successfully!");
      setAddData({
        Payment_type: ''
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };


  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {

    setUpdateId(_id)
    console.log(" the data to updata", data)
    setUpdateShowModal(true);
    setUpdateData(data)

  }



  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = {


        Date: updateData.Date,
        Holiday_name: updateData.Holiday_name

      }
      console.log("the update submit data", formData)
      const response = await axios.put(ApiUrl + `/holiday/${updateid}`, formData)
      console.log("response for update", response)
      setUpdateShowModal(false)
      toast.success("Update successfully!");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      fetchData();
    }
    catch (error) {
      console.error("Error updating data:", error.message);
    }

  }



  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const response = await axios.delete(ApiUrl + `/holiday/${getdeleteid}`)
      console.log("delete data ", response)
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      fetchData();
    }
    catch (error) {
      console.error("Error updating data:", error.message);
    }

  };


  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  }

  const [getdeleteid, setdeleteid] = useState('')
  const [getdeletename, setdeletename] = useState('')

  const handleOpenModal = (_id, Holiday_name) => {
    console.log(" the id", _id)
    console.log(" the name", Holiday_name)
    setdeleteid(_id);
    setdeletename(Holiday_name);
    setDeleteShowModals(true);

  }


  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };
  // the pagination  end ---------------------------------------------------------------------





  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`} key="prev">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li className={`page-item ${currentPage === 0 ? "active" : ""}`} key={0}>
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li className={`page-item ${currentPage === 1 ? "active" : ""}`} key={1}>
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 3, currentPage + 1); i++) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li className={`page-item ${currentPage === totalPages - 2 ? "active" : ""}`} key={totalPages - 2}>
          <button className="page-link" onClick={() => gotoPage(totalPages - 2)}>
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li className={`page-item ${currentPage === totalPages - 1 ? "active" : ""}`} key={totalPages - 1}>
          <button className="page-link" onClick={() => gotoPage(totalPages - 1)}>
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };





  // ===================================================================================
  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    let isValid = true;

    if (!addData.Holiday_name) {
      tempErrors.Holiday_name = "Payment Type is required.";
      isValid = false;
    }



    setErrors(tempErrors);
    return isValid;
  }


  const handleDateChangee = (e) => {
    setUpdateData({ ...updateData, Date: e.target.value });
  };



  const formatDatefitching = (isoDate) => {
    if (!isoDate) {
      console.log("No date provided");
      return ''; // Return an empty string if no date is provided
    }

    console.log("Date provided:", isoDate); // Check if the date is being passed correctly
    const date = new Date(isoDate);
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  const formatDatess = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);

    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
    const year = date.getFullYear();

    return `${day}-${month}-${year}`; // Format as "day-month-year"
  };



  const formatDate = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);

    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
    const year = date.getFullYear();

    // Return in the format "yyyy-mm-dd" which is the format required for <input type="date">
    return `${year}-${month}-${day}`;
  };


  const [loading, setLoading] = useState(false)



  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      Holiday_name: item.Holiday_name,
      Date: formatDate(item.Date),
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Holiday Name",
        accessor: "Holiday_name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Date",
        accessor: "Date", // Assuming "Date" is the key in your data
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        Cell: ({ value }) => {
          const formattedDate = formatDatess(value); // Assuming formatDate is your function
          return formattedDate; // This will display the formatted date in the table cell
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  
  return (
    <>

      {/* {loading === true ? (
        <div className="loading-overlay">
          <div className="loading-message">
            <img
              className="loadinggif"
              src={require("../../assets/img/output-onlinegiftools.gif")}
            />{" "}
          </div>
        </div>
      ) : (
        <div className="d-none"></div>
      )} */}
      <div className='top'>
        <div className="" style={{ marginTop: '-80px' }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Holiday</b>
              </Link>
              {/* <Link to="" className="text-danger dashBoard_text">
                <b>Profile</b>
            </Link> */}
            </div>
          </div>
        </div>
        {/* Table */}
        <div className='d-flex'>
          <h5 className='mt-2'>Holiday</h5>
          <div className='mx-3'>
            {/* {previllages.add && ( */}
              <button
                className="btn btn-dark btn-sm add mx-3"
                onClick={() =>
                  setShowModal(true)
                }
              >
                Add
              </button>
            {/* )} */}
          </div>
        </div>

        {/* ------------ search and per page ----------------------------*/}

        <div className="mb-1 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  value={state.pageSize}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === 'All') {
                      setPageSize(data.length);  // 'data' should be your dataset containing all the entries
                    } else {
                      setPageSize(Number(value));
                    }
                  }}
                >
                  <option value="All">All</option> {/* Add 'All' option */}
                  {[10, 20, 100, 200, 500, 1000].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      value={state.globalFilter || ""} // Use the globalFilter value
                      onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* --------------------------------end------------------------------------------- */}
        {/* <div className='card'>
                    <div className="mt-1">
                        <table className="table table-striped "  >
                            <thead >
                                <tr>
                                    <th>S.No</th>
                                    <th> Date</th>
                                    <th> Holiday</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {filtered.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{formatDate(item.Date)}</td>

                                        <td>{item.Holiday_name}</td>
                                        <td className='text-center'>

                                            {previllages.edit && (
                                                <button
                                                    className="btn updelete mx-2"
                                                    onClick={() => handleUpdate(item._id, item)}
                                                >
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </button>
                                            )}


                                            {previllages.delete && (
                                                <button
                                                    className="btn delete"
                                                    onClick={() =>
                                                        handleOpenModal(item._id, item.Holiday_name)
                                                    }
                                                >
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </button>
                                            )}

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div> */}

        <TableContainer>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="d-flex">
                        <div className="mx-2">{column.render("Header")}</div>
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                        <div className="mx-1">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </div>
                      </div>
                    </Th>
                  ))}
                  <Th className="text-center">Action</Th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    ))}
                    <Td className="text-center">
                      {/* {previllages.edit && ( */}
                        <button
                          className="btn updelete mx-2"
                          onClick={() =>
                            handleUpdate(row.original._id, row.original)
                          }
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                      {/* )} */}

                      {/* {previllages.delete && ( */}
                        <button
                          className="btn delete"
                          onClick={() =>
                            handleOpenModal(
                              row.original._id,
                              row.original.Holiday_name
                            )
                          }
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      {/* )} */}
                    </Td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>

        {/* .................................................................................... */}
        {/* Modal for adding a new user */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Holiday Name </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="form-group">

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=''
                  value={addData.Date}
                  onChange={(e) => setAddData({ ...addData, Date: e.target.value })}
                  required
                />
                <label className={addData.Date ? "filled" : ""}>
                  Date
                </label>



              </div>


              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=''
                  value={addData.Holiday_name}
                  onChange={(e) => setAddData({ ...addData, Holiday_name: e.target.value })}
                  required
                />
                <label className={addData.Holiday_name ? "filled" : ""}>
                  Holiday Name
                </label>

                {errors.Payment_type && (
                  <div className="error-text">{errors.Payment_type}</div>
                )}

              </div>

            </div>


          </Modal.Body>

          <Modal.Footer>
            <Button className='cancelbut' variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
            <Button className='submitbut' variant="primary" onClick={handlesubmit}>Submit</Button>
          </Modal.Footer>
        </Modal>


        <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update Holiday Name </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {/* Other input fields */}

            <div className="form-group">

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=''
                  value={formatDate(updateData.Date)}
                  onChange={handleDateChangee} // Ensure this handles the date change correctly
                  required
                />
                <label className={formatDatess(updateData.Date) ? "filled" : ""}>
                  Date
                </label>
              </div>


              <div className="floating-label">
                <input className="form-control" type="text" placeholder='' value={updateData.Holiday_name} onChange={(e) => setUpdateData({ ...updateData, Holiday_name: e.target.value })} required />
                <label className={updateData.Holiday_name ? "filled" : ""}>
                  Holiday Name
                </label>
              </div>

            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
            <Button className='submitbut' variant="primary" onClick={handleupdatesubmit}>Update</Button>
          </Modal.Footer>
        </Modal>

        {/* ----------------delete */}

        <Modal className="modal" show={deleteShowModals} onHide={handleCloseModal}>
          <Modal.Header>
            <p>Are you sure you want to Delete?</p>
          </Modal.Header>
          <Modal.Body>


            <label className="form-label d-flex">
              <h5 className='mx-1'> Holiday Name :</h5> <h5>{getdeletename} </h5>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
          </Modal.Footer>
        </Modal>
        {/* _------------------------------pagination button -------------------------------- */}


        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-7 col-sm-12 mt-1">
              <span className="text-dark">
                Page {currentPage} of {totalPages}
              </span>
            </div>
            <div className="col-lg-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>


        {/* ---------------------------------------end ---------------------------------- */}


      </div>
    </>
  )
}


export default Holiday
