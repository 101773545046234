import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "./../Environment/ApiUrl";
import "../../assets/css/Common.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import ApiUrl from "./../Environment/ApiUrl";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function Customer() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [previewModal, setpreviewModal] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      branch_id:
        item.branch_id && item.branch_id[0]
          ? item.branch_id[0].branch_name
          : "",
      name: item.name,
      mobile: item.mobile,
      email: item.email,
      company_name: item.company_name,
      door_no: item.door_no,
      street_name: item.street_name,
      area: item.area,
      pin_code: item.pin_code,
      gst_no: item.gst_no,
      notes: item.notes,
      status: item.status,
      customer_id: item.customer_id,
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Branch",
        accessor: "branch_id",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Customer ID",
        accessor: "customer_id",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },

      {
        Header: "Status",
        accessor: "status",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        Cell: ({ value }) => {
          // Conditional logic for the status
          if (value === "Active") {
            return (
              <td className="text-center hideres">
                <p className="text-white green_label">{value}</p>
              </td>
            );
          } else {
            return (
              <td className="text-center hideres">
                <p className="text-white red_label">{value}</p>
              </td>
            );
          }
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [branches, setBranches] = useState([]);

  // State for handling the next customer ID
  const [nextCustomerId, setNextCustomerId] = useState("");

  const [addData, setAddData] = useState({
    branch_id: "",
    customer_id: "",
    name: "",
    mobile: "",
    email: "",
    company_name: "",
    door_no: "",
    street_name: "",
    area: "",
    pin_code: "",
    gst_no: "",
    notes: "",
    status: "Active", // Default value
  });

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    customer_id: "",
    name: "",
    mobile: "",
    email: "",
    company_name: "",
    door_no: "",
    street_name: "",
    area: "",
    pin_code: "",
    gst_no: "",
    notes: "",
    status: "", // Default value
  });

  useEffect(() => {
    fetchData();
    fetchBranches();
  }, []);

  // GET request - Fetch all customers
  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/customer");
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
      toast.error("Failed to fetch customers: " + error.message);
    }
  };

  // GET request - Fetch all branches
  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${apiUrl}/branch`);
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
      toast.error("Failed to fetch branches: " + error.message);
    }
  };

  // Function to get the next customer ID
  const getNextCustomerId = async () => {
    try {
      // First try to get from API endpoint
      const response = await axios.get(`${apiUrl}/generate_customer`);
      setNextCustomerId(response.data.customer_id);
      setAddData({ ...addData, customer_id: response.data.customer_id });
    } catch (error) {
      console.error("Error fetching next customer ID:", error.message);
    }
  };

  // Handle opening the add modal and fetching the next ID
  const handleShowModal = () => {
    // Reset form data
    setAddData({
      branch_id: "",
      customer_id: "",
      name: "",
      mobile: "",
      email: "",
      company_name: "",
      door_no: "",
      street_name: "",
      area: "",
      pin_code: "",
      gst_no: "",
      notes: "",
      status: "Active", // Default value
    });

    // Get the next customer ID
    getNextCustomerId();

    // Show the modal
    setShowModal(true);
  };

  // Handle input changes for add form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddData({ ...addData, [name]: value });
  };

  // Handle input changes for update form
  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateData({ ...updateData, [name]: value });
  };

  // POST request - Add new customer
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make sure customer_id is included
      const customerData = {
        ...addData,
        customer_id: nextCustomerId || addData.customer_id,
      };

      const response = await axios.post(apiUrl + "/customer", customerData);
      toast.success("Customer added successfully!");
      setShowModal(false);
      setAddData({
        branch_id: "",
        customer_id: "",
        name: "",
        mobile: "",
        email: "",
        company_name: "",
        door_no: "",
        street_name: "",
        area: "",
        pin_code: "",
        gst_no: "",
        notes: "",
        status: "Active", // Default value
      });
      fetchData(); // Refresh the table
    } catch (error) {
      console.error("Error adding customer:", error.message);
      toast.error(
        "Failed to add customer: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const [updateId, setUpdateId] = useState("");

  // Handle update modal and fetch customer data
  const handleUpdate = (id, data) => {
    console.log("Update customer with ID:", id);
    // Find the original data for this customer in responseData
    const originalData = responseData.find((item) => item._id === id);

    if (!originalData) {
      console.error("Could not find original data for customer:", id);
      return;
    }

    setUpdateId(id);
    setUpdateModal(true);

    // Get the actual branch_id as needed by the API
    const branchId =
      originalData.branch_id && originalData.branch_id[0]
        ? originalData.branch_id[0]._id
        : "";
    setUpdateData({
      branch_id: branchId,
      customer_id: data.customer_id,
      name: data.name,
      mobile: data.mobile,
      email: data.email,
      company_name: data.company_name,
      door_no: data.door_no || "",
      street_name: data.street_name || "",
      area: data.area || "",
      pin_code: data.pin_code || "",
      gst_no: data.gst_no || "",
      notes: data.notes || "",
      status: data.status,
    });
  };

  const handlepreview = (id, data) => {
    console.log("Update customer with ID:", id);
    // Find the original data for this customer in responseData
    const originalData = responseData.find((item) => item._id === id);

    if (!originalData) {
      console.error("Could not find original data for customer:", id);
      return;
    }

    setUpdateId(id);
    // setUpdateModal(true);
    setpreviewModal(true);
    // Get the actual branch_id as needed by the API
    const branchId =
      originalData.branch_id && originalData.branch_id[0]
        ? originalData.branch_id[0]._id
        : "";
    setUpdateData({
      branch_id: branchId,
      customer_id: data.customer_id,
      name: data.name,
      mobile: data.mobile,
      email: data.email,
      company_name: data.company_name,
      door_no: data.door_no || "",
      street_name: data.street_name || "",
      area: data.area || "",
      pin_code: data.pin_code || "",
      gst_no: data.gst_no || "",
      notes: data.notes || "",
      status: data.status,
    });
  };

  // PUT request - Update customer
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        branch_id: updateData.branch_id,
        customer_id: updateData.customer_id,
        name: updateData.name,
        mobile: updateData.mobile,
        email: updateData.email,
        company_name: updateData.company_name,
        door_no: updateData.door_no,
        street_name: updateData.street_name,
        area: updateData.area,
        pin_code: updateData.pin_code,
        gst_no: updateData.gst_no,
        notes: updateData.notes,
        status: updateData.status,
      };

      const response = await axios.put(
        `${ApiUrl}/customer/${updateId}`,
        formData
      );
      console.log("Response for update:", response);
      toast.success("Customer updated successfully!");
      setUpdateModal(false);
      fetchData(); // Refresh the table data
    } catch (error) {
      console.error("Error updating data:", error.message);
      toast.error(
        "Failed to update customer: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  // Show delete confirmation modal
  // const handleShowDeleteModal = (id) => {
  //   setDeleteId(id);
  //   setDeleteModal(true);
  // };

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };
  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");

  const handleShowDeleteModal = (_id, customer_id) => {
    console.log(" the id", _id);
    console.log(" the name", customer_id);
    setdeleteid(_id);
    setdeletename(customer_id);
    setDeleteShowModals(true);
  };

  // DELETE request - Delete customer
  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${ApiUrl}/customer/${getdeleteid}`);
      console.log("Delete response:", response);
      toast.success("Customer deleted successfully!");
      setDeleteShowModals(false);
      fetchData(); // Refresh the table
    } catch (error) {
      console.error("Error deleting customer:", error.message);
      toast.error(
        "Failed to delete customer: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  // the pagination ---------------------------------------------------------------------
  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/" className="text-secondary dashBoard_text">
              <b>Sales / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Customer</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 d-flex">
          <h6 className="mt-2 bold">Customer</h6>
          <div className="">
            <button
              className="btn btn-dark btn-sm add mx-3"
              onClick={handleShowModal}
            >
              Add
            </button>
          </div>
        </div>
        <div className="col-lg-2">
          <div>
            <input
              type="file"
              id="file-upload"
              accept=".xls,.xlsx"
              style={{ display: "none" }}
            />
            <label htmlFor="file-upload" className="btn btn-secondary">
              File Upload
            </label>
          </div>
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}
      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    <button
                      className="btn btn-sm custom-btn-update"
                      onClick={() =>
                        handleUpdate(row.original._id, row.original)
                      }
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button
                      className="btn btn-sm mx-1 custom-btn-delete"
                      onClick={() =>
                        handleShowDeleteModal(
                          row.original._id,
                          row.original.customer_id
                        )
                      }
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>

                    <button
                      className="btn custom-btn-info btn-sm"
                      onClick={() =>
                        handlepreview(row.original._id, row.original)
                      }
                    >
                      <i className="fa-solid fa-eye"></i>
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      {/* Add Customer Modal */}
      <Modal
        className="addmodals"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Customer</Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 ">
                <div className="floating-label ">
                  <select
                    className="form-select"
                    name="branch_id"
                    value={addData.branch_id}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Branch</option>
                    {branches.map((branch) => (
                      <option key={branch._id} value={branch._id}>
                        {branch.branch_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="customer_id"
                    value={nextCustomerId || addData.customer_id}
                    onChange={handleInputChange}
                    disabled
                    required
                  />
                  <label className="">Customer ID</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder=""
                    value={addData.name}
                    onChange={handleInputChange}
                    required
                  />
                  <label className={addData.name ? "filled" : ""}>Name</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    name="mobile"
                    placeholder=""
                    value={addData.mobile}
                    onChange={handleInputChange}
                    required
                  />
                  <label className={addData.mobile ? "filled" : ""}>
                    Mobile
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder=""
                    value={addData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <label className={addData.email ? "filled" : ""}>Email</label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="company_name"
                    placeholder=""
                    value={addData.company_name}
                    onChange={handleInputChange}
                  />
                  <label className={addData.company_name ? "filled" : ""}>
                    Company Name
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="door_no"
                    placeholder=""
                    value={addData.door_no}
                    onChange={handleInputChange}
                  />
                  <label className={addData.door_no ? "filled" : ""}>
                    Door No
                  </label>
                </div>
              </div>

              <div className="col-lg-6 ">
                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="street_name"
                    placeholder=""
                    value={addData.street_name}
                    onChange={handleInputChange}
                  />
                  <label className={addData.street_name ? "filled" : ""}>
                    Street Name
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="area"
                    placeholder=""
                    value={addData.area}
                    onChange={handleInputChange}
                  />
                  <label className={addData.area ? "filled" : ""}>Area</label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="pin_code"
                    placeholder=""
                    value={addData.pin_code}
                    onChange={(e) => {
                      setAddData({
                        ...addData,
                        pin_code: e.target.value,
                      });
                    }}
                  />
                  <label className={addData.pin_code ? "filled" : ""}>
                    Pin Code
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="gst_no"
                    placeholder=""
                    value={addData.gst_no}
                    onChange={(e) => {
                      setAddData({
                        ...addData,
                        gst_no: e.target.value,
                      });
                    }}
                  />
                  <label className={addData.gst_no ? "filled" : ""}>
                    GST Number
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="notes"
                    placeholder=""
                    value={addData.notes}
                    onChange={(e) => {
                      setAddData({
                        ...addData,
                        notes: e.target.value,
                      });
                    }}
                  />
                  <label className={addData.notes ? "filled" : ""}>Notes</label>
                </div>

                <div className="floating-label ">
                  <select
                    className="form-select"
                    name="status"
                    value={addData.status}
                    onChange={(e) => {
                      setAddData({
                        ...addData,
                        status: e.target.value,
                      });
                    }}
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button className="submitbut" type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {/* </div> */}

      <Modal
        className="addmodals"
        show={updateModal}
        onHide={() => setUpdateModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Customer</Modal.Title>
        </Modal.Header>

        <form onSubmit={handleUpdateSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 ">
                <div className="floating-label ">
                  {/* <label className="form-label">Branch</label> */}
                  <select
                    className="form-select"
                    name="branch_id"
                    value={updateData.branch_id}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        branch_id: e.target.value,
                      });
                    }}
                    required
                  >
                    {branches.map((branch) => (
                      <option key={branch._id} value={branch._id}>
                        {branch.branch_name}
                      </option>
                    ))}
                  </select>
                  <label
                    className={updateData.branch_id ? "filled" : ""}
                  ></label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="customer_id"
                    value={nextCustomerId || updateData.customer_id}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        customer_id: e.target.value,
                      });
                    }}
                    disabled
                    required
                  />
                  <label className={updateData.customer_id ? "filled" : ""}>
                    Customer ID
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.name}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, name: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.name ? "filled" : ""}>
                    Name
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.mobile}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        mobile: e.target.value,
                      });
                    }}
                    required
                  />
                  <label className={updateData.mobile ? "filled" : ""}>
                    Mobile
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder=""
                    value={updateData.email}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        email: e.target.value,
                      });
                    }}
                    required
                  />
                  <label className={updateData.email ? "filled" : ""}>
                    Email
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="company_name"
                    placeholder=""
                    value={updateData.company_name}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        company_name: e.target.value,
                      });
                    }}
                  />
                  <label className={updateData.company_name ? "filled" : ""}>
                    Company Name
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="door_no"
                    placeholder=""
                    value={updateData.door_no}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        door_no: e.target.value,
                      });
                    }}
                  />
                  <label className={updateData.door_no ? "filled" : ""}>
                    Door No
                  </label>
                </div>
              </div>

              <div className="col-lg-6 ">
                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="street_name"
                    placeholder=""
                    value={updateData.street_name}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        street_name: e.target.value,
                      });
                    }}
                  />
                  <label className={updateData.street_name ? "filled" : ""}>
                    Street Name
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="area"
                    placeholder=""
                    value={updateData.area}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        area: e.target.value,
                      });
                    }}
                  />
                  <label className={updateData.area ? "filled" : ""}>
                    Area
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="pin_code"
                    placeholder=""
                    value={updateData.pin_code}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        pin_code: e.target.value,
                      });
                    }}
                  />
                  <label className={updateData.pin_code ? "filled" : ""}>
                    Pin Code
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="gst_no"
                    placeholder=""
                    value={updateData.gst_no}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        gst_no: e.target.value,
                      });
                    }}
                  />
                  <label className={updateData.gst_no ? "filled" : ""}>
                    GST Number
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="notes"
                    placeholder=""
                    value={updateData.notes}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        notes: e.target.value,
                      });
                    }}
                  />
                  <label className={updateData.notes ? "filled" : ""}>
                    Notes
                  </label>
                </div>

                <div className="floating-label ">
                  <select
                    className="form-select"
                    name="status"
                    value={updateData.status}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        status: e.target.value,
                      });
                    }}
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
            <Button className="updatebut" type="update" variant="primary">
              Update
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        className="addmodals"
        show={previewModal}
        onHide={() => setpreviewModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview Customer</Modal.Title>
        </Modal.Header>

        <form onSubmit={handleUpdateSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 ">
                <div className="floating-label ">
                  {/* <label className="form-label">Branch</label> */}
                  <select
                    className="form-select"
                    name="branch_id"
                    value={updateData.branch_id}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        branch_id: e.target.value,
                      });
                    }}
                    disabled
                    required
                  >
                    {branches.map((branch) => (
                      <option key={branch._id} value={branch._id}>
                        {branch.branch_name}
                      </option>
                    ))}
                  </select>
                  <label
                    className={updateData.branch_id ? "filled" : ""}
                  ></label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="customer_id"
                    value={nextCustomerId || updateData.customer_id}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        customer_id: e.target.value,
                      });
                    }}
                    disabled
                    required
                  />
                  <label className={updateData.customer_id ? "filled" : ""}>
                    Customer ID
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.name}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, name: e.target.value })
                    }
                    required
                    disabled
                  />
                  <label className={updateData.name ? "filled" : ""}>
                    Name
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.mobile}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        mobile: e.target.value,
                      });
                    }}
                    required
                    disabled
                  />
                  <label className={updateData.mobile ? "filled" : ""}>
                    Mobile
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder=""
                    value={updateData.email}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        email: e.target.value,
                      });
                    }}
                    required
                    disabled
                  />
                  <label className={updateData.email ? "filled" : ""}>
                    Email
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="company_name"
                    placeholder=""
                    disabled
                    value={updateData.company_name}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        company_name: e.target.value,
                      });
                    }}
                  />
                  <label className={updateData.company_name ? "filled" : ""}>
                    Company Name
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="door_no"
                    placeholder=""
                    value={updateData.door_no}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        door_no: e.target.value,
                      });
                    }}
                    disabled
                  />
                  <label className={updateData.door_no ? "filled" : ""}>
                    Door No
                  </label>
                </div>
              </div>

              <div className="col-lg-6 ">
                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="street_name"
                    placeholder=""
                    value={updateData.street_name}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        street_name: e.target.value,
                      });
                    }}
                    disabled
                  />
                  <label className={updateData.street_name ? "filled" : ""}>
                    Street Name
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="area"
                    placeholder=""
                    value={updateData.area}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        area: e.target.value,
                      });
                    }}
                    disabled
                  />
                  <label className={updateData.area ? "filled" : ""}>
                    Area
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="pin_code"
                    placeholder=""
                    value={updateData.pin_code}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        pin_code: e.target.value,
                      });
                    }}
                    disabled
                  />
                  <label className={updateData.pin_code ? "filled" : ""}>
                    Pin Code
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="gst_no"
                    placeholder=""
                    value={updateData.gst_no}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        gst_no: e.target.value,
                      });
                    }}
                    disabled
                  />
                  <label className={updateData.gst_no ? "filled" : ""}>
                    GST Number
                  </label>
                </div>

                <div className="floating-label ">
                  <input
                    className="form-control"
                    type="text"
                    name="notes"
                    placeholder=""
                    value={updateData.notes}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        notes: e.target.value,
                      });
                    }}
                    disabled
                  />
                  <label className={updateData.notes ? "filled" : ""}>
                    Notes
                  </label>
                </div>

                <div className="floating-label ">
                  <select
                    className="form-select"
                    name="status"
                    value={updateData.status}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        status: e.target.value,
                      });
                    }}
                    disabled
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setpreviewModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Customer Id :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pagination */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customer;
