


import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function EventTable() {
  const [events, setEvents] = useState([]);
  const [addData, setAddData] = useState({
    id: "",
    date: "",
    college_name: "",
    department: "",
    event_title: "",
    program_timing: "",
    status: "",
    branch_id: "",
    empid: "",
    staff_name: "",
    event_type: "",
    remarks: "",
    location: "",
  });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null); // To store the event that needs to be deleted
  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});

  const data = useMemo(() => {
    return events.map((item) => ({
      _id: item._id,
      branch_id: {
        _id: item.branch_id?._id ?? null,
        branch_name: item.branch_id?.branch_name ?? "",
      },
      event_type: item.event_type,
      college_name: item.college_name,
      department: item.department,
      program_timing: item.program_timing,
      date: item.date,
      event_title: item.event_title,
      status: item.status,
      staff_name: item.staff_name?.name ?? "", // Assuming `staff_name` has a `name` property
    }));
  }, [events]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Branch",
        accessor: "branch_id.branch_name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Date",
        accessor: (row) => {
          const date = new Date(row.date);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        },
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Event",
        accessor: "event_title",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Staff Name",
        accessor: "staff_name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // useEffect(() => {
  //   gettoken();
  //   getprevillages();
  // }, []);

  // const gettoken = async () => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await axios.post(ApiUrl + "/get_detail", {
  //       token: token,
  //     });
  //     console.log("token detail from server:", response.data);
  //     setroleid(response.data.detail.rolename);
  //     localStorage.setItem("role_id", response.data.detail.rolename);
  //     const id = response.data.detail.rolename;
  //     if (id) {
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };

  // const getprevillages = () => {
  //   const username = JSON.parse(localStorage.getItem("user_previllages"));
  //   console.log("tyhe username get by local stroage : ", username.modules);
  //   const total_previlages = username.modules;
  //   const new_enquiry = total_previlages.find((item) => item.name == "Invoice");
  //   console.log("the new_enquiry is : ", new_enquiry.actions);

  //   setprevillages(new_enquiry.actions);
  // };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const response = await axios.get(ApiUrl + "/events", {
          params: { data: localStorage.getItem("branch_id") },
        });
        setEvents(response.data);

        if (response && response.status === 200) {
          setTimeout(() => {
            setLoading(false);
          }, 300);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEvents();
    fetchBranchData();
    fetchCalendarData();
  }, []);

  // Fetch branch data
  const fetchBranchData = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/Branch`);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fetchEmployeeData = async (branchId) => {
    try {
      const response = await axios.get(
        `${ApiUrl}/staff_based_on_branch/${branchId}`
      );
      setEmpData(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  // const handleUpdateModal = async (eventId, eventss) => {
  //   const event = events.find((e) => e._id === eventId);

  //   if (event) {
  //     setAddData({
  //       id: event._id,
  //       date: event.date,
  //       college_name: event.college_name,
  //       department: event.department,
  //       event_title: event.event_title,
  //       program_timing: event.program_timing,
  //       status: event.status,
  //       branch_id: eventss.branch_id._id,
  //       empid: eventss.staff_name ? eventss.staff_name._id : null, // Ensure null if no staff_name
  //       staff_name: event.staff_name ? event.staff_name.name : "",
  //       event_type: event.event_type,
  //       remarks: event.remarks || "",
  //       location: event.location,
  //     });
  //     setShowUpdateModal(true);
  //   }

  //   try {
  //     const response = await axios.get(
  //       `${apiUrl}/staff_based_on_branch/${eventss.branch_id._id}`
  //     );
  //     setEmpData(response.data);
  //   } catch (error) {
  //     console.error("Error fetching employees:", error);
  //   }
  // };

  const handleUpdateModal = async (eventId, eventss) => {
  console.log("Event ID:", eventId);
  console.log("Events List:", eventss);

  const event = events.find((e) => e._id === eventId);
  console.log("Selected Event:", event);

  if (event) {
    const eventData = {
      id: event._id,
      date: event.date,
      college_name: event.college_name,
      department: event.department,
      event_title: event.event_title,
      program_timing: event.program_timing,
      status: event.status,
      branch_id: eventss.branch_id._id,
      empid: event.staff_name._id, // Ensure null if no staff_name
      staff_name: event.staff_name ? event.staff_name.name : "",
      event_type: event.event_type,
      remarks: event.remarks || "",
      location: event.location,
    };

    console.log("Setting Add Data:", eventData);
    setAddData(eventData);
    setShowUpdateModal(true);
  } else {
    console.warn("Event not found!");
  }

  try {
    console.log("Fetching employees for branch ID:", eventss.branch_id._id);
    const response = await axios.get(
      `${ApiUrl}/staff_based_on_branch/${eventss.branch_id._id}`
    );
    console.log("Employees Data:", response.data);
    setEmpData(response.data);
  } catch (error) {
    console.error("Error fetching employees:", error);
  }
};


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (
      !addData.date ||
      !addData.college_name ||
      !addData.department ||
      !addData.program_timing ||
      !addData.event_title ||
      !addData.branch_id ||
      !addData.empid ||
      !addData.event_type ||
      !addData.status ||
      !addData.location
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    const eventData = {
      ...addData,
      staff_name: addData.empid,
    };

    try {
      if (addData.id) {
        await axios.put(`${ApiUrl}/events/${addData.id}`, eventData);
        toast.success("Event updated successfully!");
      } else {
        await axios.post(ApiUrl + `/events`, eventData);
        toast.success("Event added successfully!");
      }

      const response = await axios.get(ApiUrl + "/events");
      setEvents(response.data);

      setShowUpdateModal(false);
      resetAddData();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error posting/updating event:", error.message);
      toast.error("Error updating event.");
    }
  };

  const resetAddData = () => {
    setAddData({
      id: "",
      date: "",
      college_name: "",
      department: "",
      event_title: "",
      program_timing: "",
      status: "",
      branch_id: "",
      empid: "",
      staff_name: "",
      event_type: "",
      remarks: "",
      location: "",
    });
  };

  function handleOpenModal(id) {
    const event = events.find((e) => e._id === id);
    setEventToDelete(event); // Set the event to be deleted
    setDeleteShowModals(true);
  }

  const handleCloseModal = () => {
    setDeleteShowModals(false);
    setEventToDelete(null);
  };

  async function handleDelete() {
    try {
      setLoading(true);
      if (eventToDelete) {
        const response = await axios.delete(
          ApiUrl + `/events/${eventToDelete._id}`
        );
        console.log("delete data ", response);
        toast.success("Event deleted successfully!");

        // Remove the deleted event from the state directly
        setEvents((prevEvents) =>
          prevEvents.filter((event) => event._id !== eventToDelete._id)
        );

        setDeleteShowModals(false);
        setEventToDelete(null);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error deleting event:", error.message);
      toast.error("Error Deleting Event.");
    }
  }

  const fetchCalendarData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(ApiUrl + `/events`);
      if (response && response.status === 200) {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }
      setCalendarData(response.data);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);

    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
    const year = date.getFullYear();

    return `${day}-${month}-${year}`; // Format as "day-month-year"
  };

  const [rolename, setrolename] = useState([]);
  useEffect(() => {
    const branchName = localStorage.getItem("branch_name");
    setrolename(branchName);
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = events.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const filtered = rows.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  // const createPagination = () => {
  //   let liTag = [];

  //   // Previous Page Button
  //   liTag.push(
  //     <li
  //       className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
  //       key="prev"
  //     >
  //       <button
  //         className="page-link"
  //         onClick={() => canPreviousPage && gotoPage(state.pageIndex - 1)}
  //         disabled={!canPreviousPage}
  //       >
  //         <FaAngleLeft /> Prev
  //       </button>
  //     </li>
  //   );

  //   // Page Numbers
  //   for (let plength = 1; plength <= pageCount; plength++) {
  //     liTag.push(
  //       <li
  //         key={plength}
  //         className={`page-item ${
  //           plength === state.pageIndex + 1 ? "active" : ""
  //         }`}
  //       >
  //         <button
  //           className="page-link"
  //           onClick={() => gotoPage(plength - 1)} // 0-indexed
  //         >
  //           {plength}
  //         </button>
  //       </li>
  //     );
  //   }

  //   // Next Page Button
  //   liTag.push(
  //     <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
  //       <button
  //         className="page-link"
  //         onClick={() => canNextPage && gotoPage(state.pageIndex + 1)}
  //         disabled={!canNextPage}
  //       >
  //         Next <FaAngleRight />
  //       </button>
  //     </li>
  //   );

  //   return liTag;
  // };

  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`} key="prev">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li className={`page-item ${currentPage === 0 ? "active" : ""}`} key={0}>
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li className={`page-item ${currentPage === 1 ? "active" : ""}`} key={1}>
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 3, currentPage + 1); i++) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li className={`page-item ${currentPage === totalPages - 2 ? "active" : ""}`} key={totalPages - 2}>
          <button className="page-link" onClick={() => gotoPage(totalPages - 2)}>
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li className={`page-item ${currentPage === totalPages - 1 ? "active" : ""}`} key={totalPages - 1}>
          <button className="page-link" onClick={() => gotoPage(totalPages - 1)}>
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading === true ? (
        <div className="loading-overlay">
          <div className="loading-message">
            {/* <img
              className="loadinggif"
              src={require("../../assets/img/output-onlinegiftools.gif")}
            />{" "} */}
          </div>
        </div>
      ) : (
        <div className="d-none"></div>
      )}
      <div style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/staff" className="text-secondary dashBoard_text">
              <b>Staff / </b>
            </Link>
            <Link to="/event" className="text-danger dashBoard_text">
              <b>Event / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Event Table</b>
            </Link>
          </div>
        </div>
      </div>

      <div>
        <h5 className="mt-1">Event Table</h5>
      </div>



      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    {/* {previllages.edit && ( */}
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          handleUpdateModal(row.original._id, row.original)
                        }
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                    {/* )} */}

                    {/* {previllages.delete && ( */}
                      <button
                        className="btn btn-danger mx-3"
                        onClick={() => handleOpenModal(row.original._id)}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    {/* )} */}
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      {/* Update Modal */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Inputs for updating event data */}
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    name="date"
                    value={addData.date}
                    onChange={handleInputChange}
                    required
                  />
                  <label>Date</label>
                </div>
                <div className="floating-label">
                  <select
                    className="form-select"
                    name="branch_id"
                    value={addData.branch_id}
                    onChange={(e) => {
                      handleInputChange(e);
                      fetchEmployeeData(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select Branch</option>
                    {responseDatas.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.branch_name}
                      </option>
                    ))}
                  </select>
                  {/* <label>Select Branch</label> */}
                </div>

                <div className="floating-label">
                  <select
                    className="form-select"
                    name="empid"
                    value={addData.empid}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Staff</option>
                    {empData.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.empid} - {item.name}
                      </option>
                    ))}
                  </select>

                  {/* <label>Select Staff</label> */}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="college_name"
                    value={addData.college_name}
                    onChange={handleInputChange}
                    required
                  />
                  <label>College Name</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="event_title"
                    value={addData.event_title}
                    onChange={handleInputChange}
                    required
                  />
                  <label>Event Title</label>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="department"
                    value={addData.department}
                    onChange={handleInputChange}
                    required
                  />
                  <label>Department & Year</label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-select"
                    name="event_type"
                    value={addData.event_type}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Event Type</option>
                    <option value="Seminar">Seminar</option>
                    <option value="Workshop">Workshop</option>
                    <option value="Corporate Trainings">
                      Corporate Trainings
                    </option>
                  </select>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="event_title"
                    value={addData.location}
                    onChange={(e) =>
                      setAddData({ ...addData, location: e.target.value })
                    }
                    required
                  />
                  <label>Location</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="program_timing"
                    value={addData.program_timing}
                    onChange={handleInputChange}
                    required
                  />
                  <label>Program Time</label>
                </div>
                <div className="floating-label">
                  <select
                    className="form-control"
                    name="status"
                    value={addData.status}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="Scheduled">Scheduled</option>
                    <option value="InProgress">InProgress</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Name :</h5>{" "}
            <h5>{eventToDelete ? eventToDelete.event_title : "N/A"}</h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {currentPage} of {totalPages}
            </span>
          </div>
          <div className="col-lg-5">
            <nav>
              <ul className="pagination justify-content-center">
                {createPagination()}
              </ul>
            </nav>
          </div>
        </div>
      </div> */}

      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()} {/* Pagination buttons */}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventTable;

