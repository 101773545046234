import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../assets/css/Addrole.css";
import axios from "axios";
import ApiUrl from "./../Environment/ApiUrl";
import { toast } from "react-toastify";

// Your modulesData array stays the same
const modulesData = [
  {
    name: "Dashboard",
    id: 1,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Roles",
    id: 2,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Users",
    id: 3,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Branch",
    id: 4,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Contact Leads",
    id: 5,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Enquiry",
    id: 6,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "New Enquiry",
    id: 7,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },

  {
    name: "Followups",
    id: 8,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },

  {
    name: "Field Visit",
    id: 9,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Sourcetype",
    id: 10,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Enquirypurpose",
    id: 11,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expected",
    id: 12,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Ongoing",
    id: 13,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Completed",
    id: 14,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Sales Order",
    id: 15,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Customer",
    id: 16,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Upcomings",
    id: 17,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },

  {
    name: "Ongoing",
    id: 18,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Completed",
    id: 19,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Staff",
    id: 20,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Attendance",
    id: 21,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Designation",
    id: 22,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Leave",
    id: 23,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Allowance",
    id: 24,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Allowance Charge",
    id: 25,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Salary Details",
    id: 26,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },

  {
    name: "Holiday",
    id: 27,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Event",
    id: 28,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Daily Task",
    id: 29,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Stocks",
    id: 30,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Product List",
    id: 31,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  // {
  //   name: "Category",
  //   id: 32,
  //   actions: {
  //     all: false,
  //     add: false,
  //     edit: false,
  //     delete: false,
  //     view: false,
  //     download: false,
  //   },
  // },
  {
    name: "Purchase Order",
    id: 32,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Supplier",
    id: 33,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Asset List",
    id: 34,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Asset Type",
    id: 35,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expense List",
    id: 36,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expense Type",
    id: 37,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Vendor Details",
    id: 38,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Office Expense",
    id: 39,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Paytype",
    id: 40,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Income List",
    id: 41,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Income Type",
    id: 42,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Invoice",
    id: 43,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Daily Report",
    id: 44,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Enquiry Report",
    id: 45,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Attendance Report",
    id: 46,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expense Report",
    id: 47,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Project Report",
    id: 48,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Staff Report",
    id: 49,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Income Report",
    id: 50,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
];

function UpdateRole() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // Initialize roleData with empty values
  const [roleData, setRoleData] = useState({
    rolename: "",
    modules: [...modulesData], // Initialize with all available modules
  });

  // Fetch role data if we are editing an existing role
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const response = await axios.get(`${ApiUrl}/role/${id}`);
          console.log("Fetched data:", response.data);

          const { rolename, modules } = response.data;

          // Check if rolename and modules are available
          if (rolename && Array.isArray(modules)) {
            // Create a map of existing modules from the API
            const apiModulesMap = {};
            modules.forEach((module) => {
              apiModulesMap[module.id] = module;
            });

            // Merge the API data with the complete modules list
            const mergedModules = modulesData.map((moduleTemplate) => {
              // If this module exists in the API response, use those permissions
              if (apiModulesMap[moduleTemplate.id]) {
                return {
                  ...moduleTemplate,
                  actions: { ...apiModulesMap[moduleTemplate.id].actions },
                };
              }
              // Otherwise use the default (all false) permissions
              return { ...moduleTemplate };
            });

            setRoleData({
              rolename,
              modules: mergedModules,
            });
          } else {
            throw new Error("Invalid response format.");
          }
        } catch (error) {
          console.error("Error fetching role data:", error);
          toast.error("Error fetching role data. Please try again later.");
          // If there's an error, still show all modules with default permissions
          setRoleData({
            rolename: "",
            modules: [...modulesData],
          });
        } finally {
          setLoading(false);
        }
      } else {
        // If no ID is provided, just use all modules with default permissions
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Handle input change for the role name
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRoleData({ ...roleData, [name]: value });
  };

  // Handle checkbox changes for individual module actions or the "All" checkbox
  const handleCheckboxChange = (moduleId, action) => {
    setRoleData((prevRoleData) => {
      const updatedModules = prevRoleData.modules.map((module) => {
        if (module.id === moduleId) {
          if (action === "all") {
            // When "all" is clicked, set all checkboxes to the same state
            const newAllState = !module.actions.all;
            return {
              ...module,
              actions: {
                all: newAllState,
                add: newAllState,
                edit: newAllState,
                delete: newAllState,
                view: newAllState,
                download: newAllState,
              },
            };
          } else {
            // For individual checkboxes, toggle just that one
            const newActions = {
              ...module.actions,
              [action]: !module.actions[action],
            };

            // Check if all individual checkboxes are checked to determine "all" state
            const allChecked =
              newActions.add &&
              newActions.edit &&
              newActions.delete &&
              newActions.view &&
              newActions.download;

            return {
              ...module,
              actions: {
                ...newActions,
                all: allChecked,
              },
            };
          }
        }
        return module;
      });

      return { ...prevRoleData, modules: updatedModules };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Filter out modules where all actions are false
      const activeModules = roleData.modules.filter((module) => {
        return (
          module.actions.all ||
          module.actions.add ||
          module.actions.edit ||
          module.actions.delete ||
          module.actions.view ||
          module.actions.download
        );
      });

      // Create the payload with only active modules
      const updatePayload = {
        rolename: roleData.rolename,
        modules: activeModules.map((module) => ({
          id: module.id,
          name: module.name,
          actions: { ...module.actions },
        })),
      };

      console.log("Sending update data:", updatePayload);

      const response = await axios.put(`${ApiUrl}/role/${id}`, updatePayload);
      console.log("Update response:", response.data);

      if (response.status === 200 || response.status === 201) {
        toast.success("Role updated successfully!");
        navigate("/role");
      } else {
        throw new Error("Update failed with status: " + response.status);
      }
    } catch (error) {
      console.error("Error updating role:", error);
      toast.error("Failed to update role. Please try again.");
    }
  };

  if (loading) {
    return <div className="text-center p-5">Loading role data...</div>;
  }

  return (
    <>
      <div className="tops">
        <div className="admin-roles-main">
          <h4>Update Role</h4>
        </div>
        <div
          className="card p-3 mt-3"
          style={{ padding: "10px 10px", marginBottom: "20px" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="row mt-2">
              <div className="form-label">
                <b>Role Name:</b>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Role Name"
                  name="rolename"
                  value={roleData.rolename}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="mt-4">
                <div className="card-container table-responsive">
                  <table className="admin-role-card">
                    <thead>
                      <tr className="text-center">
                        <th colSpan="7">Privileges</th>
                      </tr>
                      <tr className="text-center">
                        <th>Module</th>
                        <th>All</th>
                        <th>Add</th>
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>View</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roleData.modules.map((module) => (
                        <tr className="text-start" key={module.id}>
                          <td className="role-data-name">{module.name}</td>
                          <td className="role-data-all">
                            <input
                              type="checkbox"
                              checked={module.actions.all || false}
                              onChange={() =>
                                handleCheckboxChange(module.id, "all")
                              }
                            />
                          </td>
                          <td className="role-data-add">
                            <input
                              type="checkbox"
                              checked={module.actions.add || false}
                              onChange={() =>
                                handleCheckboxChange(module.id, "add")
                              }
                            />
                          </td>
                          <td className="role-data-edit">
                            <input
                              type="checkbox"
                              checked={module.actions.edit || false}
                              onChange={() =>
                                handleCheckboxChange(module.id, "edit")
                              }
                            />
                          </td>
                          <td className="role-data-delete">
                            <input
                              type="checkbox"
                              checked={module.actions.delete || false}
                              onChange={() =>
                                handleCheckboxChange(module.id, "delete")
                              }
                            />
                          </td>
                          <td className="role-data-view">
                            <input
                              type="checkbox"
                              checked={module.actions.view || false}
                              onChange={() =>
                                handleCheckboxChange(module.id, "view")
                              }
                            />
                          </td>
                          <td className="role-data-download">
                            <input
                              type="checkbox"
                              checked={module.actions.download || false}
                              onChange={() =>
                                handleCheckboxChange(module.id, "download")
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => navigate("/role")}
              >
                Back
              </button>
              <button type="submit" className="btn btn-info mx-4">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateRole;
