import { useState, useEffect } from "react";
import ApiUrl from "../Environment/ApiUrl";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function UpdatePurchaseOrder() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [updateData, setUpdateData] = useState({
    purchase_order_no: "",
    date: "",
    supplier_name: "",
    email: "",
    mobile: "",
    items: [],
    paid_till_date: "",
    payment_due_date: "",
    next_due_date: "",
    person_name: "",
    contact: "",
    subtotal: "0.00",
    gst: "0.00",
    discount: "0.00",
    nettotal: "0.00",
    paytype: "",
    paid_by: "",
    paid: "0.00",
    balance: "0.00",
  });

  const [productForm, setProductForm] = useState({
    hsn_code: "",
    product_name: "",
    quantity: "",
    price: "",
    tax: "",
    total: "0.00",
  });

  // Track if we're in edit mode and which item is being edited
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  // Fetch the purchase order data when component mounts
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/purchaseorder/${id}`);
      console.log("Response from server:", response.data);
      const items = response.data.items || [];
      const subtotal = items
        .reduce((sum, item) => sum + (parseFloat(item.total) || 0), 0)
        .toFixed(2);
      setUpdateData({
        ...response.data,
        items: items,
        subtotal: subtotal,
        nettotal: calculateNetTotal(
          subtotal,
          response.data.gst || "0.00",
          response.data.discount || "0.00"
        ),
        balance: calculateBalance(
          calculateNetTotal(
            subtotal,
            response.data.gst || "0.00",
            response.data.discount || "0.00"
          ),
          response.data.paid || "0.00"
        ),
      });
    } catch (error) {
      console.error("Error fetching data:", error.message);
      toast.error("Error fetching purchase order data");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        ...updateData,
      };
      const response = await axios.put(
        `${ApiUrl}/purchaseorder/${id}`,
        formData
      );

      console.log("Purchase order updated successfully", response.data);
      toast.success("Updated Successfully");
      navigate("/purchaseorder");
    } catch (error) {
      console.error("Purchase order update error:", error.message);
      toast.error("Error updating purchase order");
    }
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setProductForm({ ...productForm, [name]: value });

    // Calculate total when quantity or price changes
    if (name === "quantity" || name === "price") {
      const quantity =
        name === "quantity"
          ? parseFloat(value) || 0
          : parseFloat(productForm.quantity) || 0;
      const price =
        name === "price"
          ? parseFloat(value) || 0
          : parseFloat(productForm.price) || 0;
      const tax = parseFloat(productForm.tax) || 0;

      // Calculate total including tax
      const totalBeforeTax = quantity * price;
      const taxAmount = totalBeforeTax * (tax / 100);
      const total = (totalBeforeTax + taxAmount).toFixed(2);

      setProductForm((prev) => ({ ...prev, total }));
    }

    // Recalculate total when tax changes
    if (name === "tax") {
      const quantity = parseFloat(productForm.quantity) || 0;
      const price = parseFloat(productForm.price) || 0;
      const tax = parseFloat(value) || 0;

      const totalBeforeTax = quantity * price;
      const taxAmount = totalBeforeTax * (tax / 100);
      const total = (totalBeforeTax + taxAmount).toFixed(2);

      setProductForm((prev) => ({ ...prev, total }));
    }
  };

  // Function to handle clicking the edit icon
  const handleEdit = (index) => {
    const itemToEdit = updateData.items[index];
    if (!itemToEdit) {
      console.error("Item not found at index:", index);
      toast.error("Error loading item data");
      return;
    }

    // Safely convert values to string with fallbacks for undefined values
    setProductForm({
      product_name: itemToEdit.product_name || "",
      hsn_code: itemToEdit.hsn_code || "",
      quantity:
        itemToEdit.quantity !== undefined && itemToEdit.quantity !== null
          ? String(itemToEdit.quantity)
          : "",
      price:
        itemToEdit.price !== undefined && itemToEdit.price !== null
          ? String(itemToEdit.price)
          : "",
      tax:
        itemToEdit.tax !== undefined && itemToEdit.tax !== null
          ? String(itemToEdit.tax)
          : "",
      total:
        itemToEdit.total !== undefined && itemToEdit.total !== null
          ? parseFloat(itemToEdit.total).toFixed(2)
          : "0.00",
    });

    setEditMode(true);
    setEditIndex(index);
  };

  const handleAddOrUpdate = () => {
    if (
      !productForm.product_name ||
      !productForm.hsn_code ||
      !productForm.quantity ||
      !productForm.price ||
      !productForm.tax
    ) {
      toast.warning("Please fill all required product details");
      return;
    }

    // Create product item from form data
    const productItem = {
      product_name: productForm.product_name,
      hsn_code: productForm.hsn_code,
      quantity: parseFloat(productForm.quantity) || 0,
      price: parseFloat(productForm.price) || 0,
      tax: parseFloat(productForm.tax) || 0,
      total: parseFloat(productForm.total) || 0,
    };

    let updatedItems;

    if (editMode && editIndex !== null) {
      // Update existing item
      updatedItems = [...updateData.items];
      updatedItems[editIndex] = productItem;
    } else {
      // Add new item
      updatedItems = [...updateData.items, productItem];
    }

    // Calculate new subtotal
    const subtotal = updatedItems
      .reduce((sum, item) => sum + (parseFloat(item.total) || 0), 0)
      .toFixed(2);

    // Update state with updated items and recalculated totals
    setUpdateData({
      ...updateData,
      items: updatedItems,
      subtotal,
      nettotal: calculateNetTotal(
        subtotal,
        updateData.gst,
        updateData.discount
      ),
      balance: calculateBalance(
        calculateNetTotal(subtotal, updateData.gst, updateData.discount),
        updateData.paid
      ),
    });

    // Reset form and edit mode
    setProductForm({
      hsn_code: "",
      product_name: "",
      quantity: "",
      price: "",
      tax: "",
      total: "0.00",
    });
    setEditMode(false);
    setEditIndex(null);

    toast.success(
      editMode ? "Item updated successfully" : "Item added successfully"
    );
  };

  const calculateNetTotal = (subtotal, gst, discount) => {
    const subtotalVal = parseFloat(subtotal) || 0;
    const gstVal = parseFloat(gst) || 0;
    const discountVal = parseFloat(discount) || 0;

    return (subtotalVal + gstVal - discountVal).toFixed(2);
  };

  const calculateBalance = (nettotal, paid) => {
    const nettotalVal = parseFloat(nettotal) || 0;
    const paidVal = parseFloat(paid) || 0;

    return (nettotalVal - paidVal).toFixed(2);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox" && name === "gst_enabled") {
      const gstValue = checked
        ? (parseFloat(updateData.subtotal) * 0.18).toFixed(2)
        : "0.00";
      const newNetTotal = calculateNetTotal(
        updateData.subtotal,
        gstValue,
        updateData.discount
      );
      const newBalance = calculateBalance(newNetTotal, updateData.paid);

      setUpdateData({
        ...updateData,
        gst: gstValue,
        nettotal: newNetTotal,
        balance: newBalance,
      });
    } else {
      // For other inputs
      const newValue = value;

      if (name === "discount" || name === "paid") {
        const newNetTotal =
          name === "discount"
            ? calculateNetTotal(updateData.subtotal, updateData.gst, newValue)
            : updateData.nettotal;

        const newBalance = calculateBalance(
          name === "discount" ? newNetTotal : updateData.nettotal,
          name === "paid" ? newValue : updateData.paid
        );

        setUpdateData({
          ...updateData,
          [name]: newValue,
          nettotal: name === "discount" ? newNetTotal : updateData.nettotal,
          balance: newBalance,
        });
      } else {
        setUpdateData({
          ...updateData,
          [name]: newValue,
        });
      }
    }
  };

  const handleDelete = (index) => {
    const updatedItems = updateData.items.filter((_, i) => i !== index);

    // Calculate new subtotal
    const subtotal = updatedItems
      .reduce((sum, item) => sum + (parseFloat(item.total) || 0), 0)
      .toFixed(2);

    // Update state with filtered items and recalculated subtotal
    setUpdateData({
      ...updateData,
      items: updatedItems,
      subtotal,
      nettotal: calculateNetTotal(
        subtotal,
        updateData.gst,
        updateData.discount
      ),
      balance: calculateBalance(
        calculateNetTotal(subtotal, updateData.gst, updateData.discount),
        updateData.paid
      ),
    });

    // If we were editing this item, exit edit mode
    if (editMode && editIndex === index) {
      setEditMode(false);
      setEditIndex(null);
      setProductForm({
        hsn_code: "",
        product_name: "",
        quantity: "",
        price: "",
        tax: "",
        total: "0.00",
      });
    }

    toast.success("Item deleted successfully");
  };

  const cancelEdit = () => {
    setEditMode(false);
    setEditIndex(null);
    setProductForm({
      hsn_code: "",
      product_name: "",
      quantity: "",
      price: "",
      tax: "",
      total: "0.00",
    });
  };

  const resetForm = () => {
    setUpdateData({
      purchase_order_no: "",
      date: "",
      supplier_name: "",
      email: "",
      mobile: "",
      items: [],
      paid_till_date: "",
      payment_due_date: "",
      next_due_date: "",
      person_name: "",
      contact: "",
      subtotal: "0.00",
      gst: "0.00",
      discount: "0.00",
      nettotal: "0.00",
      paytype: "",
      paid_by: "",
      paid: "0.00",
      balance: "0.00",
    });
    setProductForm({
      hsn_code: "",
      product_name: "",
      quantity: "",
      price: "",
      tax: "",
      total: "0.00",
    });
    setEditMode(false);
    setEditIndex(null);
  };
  return (
    <>
      <div>
        <h5
          className="head"
          style={{ textDecoration: "underline", marginTop: "-80px" }}
        >
          Update Purchase Order
        </h5>
      </div>
      <hr />
      {/* invoice no */}
      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-3 ">
            <label>Purchase Order No:</label>
            <input
              type="text"
              className="form-control"
              name="purchase_order_no"
              value={updateData.purchase_order_no}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  purchase_order_no: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="col-lg-6"></div>
          <div className="col-lg-3">
            <label>Date:</label>
            <input
              type="date"
              className="form-control"
              name="date"
              value={updateData.date}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  date: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>

      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-3">
            <label>Supplier Name:</label>
            <input
              type="text"
              className="form-control"
              name="supplier_name"
              value={updateData.supplier_name}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  supplier_name: e.target.value,
                })
              }
              required
            />
          </div>

          <div className="col-lg-3">
            <label>Email:</label>
            <input
              type="text"
              className="form-control"
              name="email"
              value={updateData.email}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  email: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="col-lg-3">
            <label>Mobile:</label>
            <input
              type="text"
              className="form-control"
              name="mobile"
              value={updateData.mobile}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  mobile: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="col-lg-1 mt-4"></div>
        </div>
      </div>

      <div className="card invoice_card p-3">
        <div className="row ">
          <div className="col-lg-3">
            <label>Product Name:</label>
            <input
              className="form-control"
              name="product_name"
              value={productForm.product_name}
              onChange={handleProductChange}
            />
          </div>

          <div className="col-lg-3">
            <div className="form-group">
              <div className="">
                <label>HSN Code:</label>
                <input
                  type="text"
                  className="form-control"
                  name="hsn_code"
                  value={productForm.hsn_code}
                  onChange={handleProductChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <label>Quantity:</label>
            <input
              type="text"
              className="form-control"
              name="quantity"
              value={productForm.quantity}
              onChange={handleProductChange}
              required
            />
          </div>

          <div className="col-lg-3">
            <label>Price:</label>
            <input
              type="text"
              className="form-control"
              name="price"
              value={productForm.price}
              onChange={handleProductChange}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <label>Tax:</label>
            <input
              type="text"
              className="form-control"
              name="tax"
              value={productForm.tax}
              onChange={handleProductChange}
              required
            />
          </div>
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-6">
                <label>Total:</label>
                <input
                  type="text"
                  className="form-control"
                  name="total"
                  value={productForm.total}
                  readOnly
                />
              </div>
              <div className="col-lg-6">
                <button
                  type="button"
                  className={`btn ${
                    editMode ? "btn-primary" : "btn-secondary"
                  } Add mt-4`}
                  onClick={handleAddOrUpdate}
                >
                  {editMode ? "Update" : "Add"}
                </button>
                {editMode && (
                  <button
                    type="button"
                    className="btn btn-danger mt-4 ms-2"
                    onClick={cancelEdit}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-2">
            <label>Paid Till Date:</label>
            <input
              type="text"
              className="form-control"
              name="paid_till_date"
              value={updateData.paid_till_date}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  paid_till_date: e.target.value,
                })
              }
            />
          </div>

          <div className="col-lg-2">
            <label>Payment Due Date:</label>
            <input
              type="date"
              className="form-control"
              name="payment_due_date"
              value={updateData.payment_due_date}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  payment_due_date: e.target.value,
                })
              }
            />
          </div>

          <div className="col-lg-2">
            <label>Next Due Date:</label>
            <input
              type="date"
              className="form-control"
              name="next_due_date"
              value={updateData.next_due_date}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  next_due_date: e.target.value,
                })
              }
            />
          </div>

          <div className="col-lg-3">
            <label>Person Name:</label>
            <input
              type="text"
              className="form-control"
              name="person_name"
              value={updateData.person_name}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  person_name: e.target.value,
                })
              }
            />
          </div>

          <div className="col-lg-3">
            <label>Contact:</label>
            <input
              type="text"
              className="form-control"
              name="contact"
              value={updateData.contact}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  contact: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>

      <div className="card invoice_card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>S.NO</th>
                  <th>Description</th>
                  <th>HSN Code</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Tax</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {updateData.items && updateData.items.length > 0 ? (
                  updateData.items.map((item, index) => (
                    <tr
                      key={index}
                      className={editIndex === index ? "table-primary" : ""}
                    >
                      <td>{index + 1}</td>
                      <td>{item.product_name}</td>
                      <td>{item.hsn_code}</td>
                      <td>{item.quantity}</td>
                      <td>{item.price}</td>
                      <td>{item.tax}</td>
                      <td>{parseFloat(item.total || 0).toFixed(2)}</td>
                      <td>
                        <i
                          className="fa-solid fa-pen-to-square me-2"
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handleEdit(index)}
                        ></i>
                        <i
                          className="fa-solid fa-circle-xmark mx-2"
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => handleDelete(index)}
                        ></i>
                        <input type="checkbox" className="mx-2"></input>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No items added
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card invoice_card p-3">
        <div className="row">
          <h5>Previous Payment History</h5>
          <div className="col-lg-6"></div>

          <div className="col-lg-6 ">
            <div className="row">
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Subtotal</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="subtotal"
                  value={updateData.subtotal}
                  readOnly
                />
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <p>
                  Gst{" "}
                  <input
                    type="checkbox"
                    name="gst_enabled"
                    checked={parseFloat(updateData.gst) > 0}
                    onChange={handleChange}
                  />
                </p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="gst"
                  value={updateData.gst}
                  onChange={handleChange}
                />
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Discount</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="discount"
                  value={updateData.discount}
                  onChange={handleChange}
                />
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Nettotal</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="nettotal"
                  value={updateData.nettotal}
                  readOnly
                />
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Paytype</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <select
                  className="form-control paytype"
                  style={{ width: "80%", marginLeft: "auto" }}
                  name="paytype"
                  value={updateData.paytype}
                  onChange={handleChange}
                >
                  <option value="">Select Paytype</option>
                  <option value="gpay">GPay</option>
                  <option value="phonepe">PhonePe</option>
                  <option value="cash">Cash</option>
                  <option value="paytm">Paytm</option>
                </select>
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Paid By</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <select
                  className="form-control paytype"
                  style={{ width: "80%", marginLeft: "auto" }}
                  name="paid_by"
                  value={updateData.paid_by}
                  onChange={handleChange}
                >
                  <option value="">Select Payment Paid By</option>
                  <option value="Client">Client</option>
                  <option value="Company">Company</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Paid</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="paid"
                  value={updateData.paid}
                  onChange={handleChange}
                />
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Balance</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="balance"
                  value={updateData.balance}
                  readOnly
                />
              </div>
            </div>

            <div className="row">
              <div className="d-flex justify-content-evenly mt-3 buttonstyle ">
                <button
                  type="button"
                  className="btn btn-danger invoice-button"
                  onClick={() => navigate("/purchaseorder")}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-info text-white invoice-button"
                  style={{ backgroundColor: "#007BFF" }}
                  onClick={resetForm}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn btn-secondary invoice-button"
                  onClick={handleUpdate}
                >
                  Update
                </button>
                <button
                  type="button"
                  className="btn btn-success invoice-button"
                  onClick={() => {
                    handleUpdate(new Event("click"));
                    window.print();
                  }}
                >
                  Save&Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdatePurchaseOrder;
