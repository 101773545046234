import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ApiUrl from "../Environment/ApiUrl";
import { RowExpanding } from "@tanstack/react-table";
const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(odd) {
    background-color: #f2f2f2; /* Light gray for even rows */
  }

  tr:nth-child(even) {
    background-color: #ffffff; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function Followups() {
  const [responseData, setResponseData] = useState([]);
  const [Updateshow, setUpdateShow] = useState(false);
  const navigate = useNavigate();
  const updateinfo = (_id) => {
    navigate(`/Leadhistroy/${_id}`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const res = await axios.get(ApiUrl + "/followupsleads");
      setResponseData(res.data.reverse());
      console.log("Follow-up leads:", res);
    } catch (error) {
      console.error("Error fetching follow-ups:", error);
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);
      }
    }
  };

  // /\\=======================================================

  const [UpdatedData, setUpdatedData] = useState([]);
  useEffect(() => {
    console.log("UpdatedData state updated:", UpdatedData);
  }, [UpdatedData]);

  const [updataData, setUpdataData] = useState({
    name: "",
    source_type: "",
    enquiry_purpose: "",
    mobile_no: "",
    source_type_id: "",
    branch_id: "",
    company_name: "",
    designation: "",
    official_email: "",
    alternate_email: "",
    official_mobile: "",
    alternate_mobile: "",
    // location: "",
    door_no: "",
    street_name: "",
    area: "",
    district: "",
    pin_code: "",
    notes: "",
    enquiry_date: "",
    enquiry_time: "",
    followups_status: "",
    followups_time: "",
    assign_staff: "",
    contact_type: "",
    status_date: "",
    interest: "",
    followups_date: "",
    followups_notes: "",
    status_time: "",
  });

  const [updateId, setUpdateId] = useState({});
  const [firstTime, setFirstTime] = useState(null);
  const [lastTime, setLastTime] = useState(null);
  const [timeDifference, setTimeDifference] = useState(null);

  const handleUpdated = (id, data) => {
    const currentTimes = new Date().toLocaleTimeString();
    console.log(`Current Time: ${currentTimes}`);
    setFirstTime(currentTimes);

    console.log("Edit Button Clicked - Data:", data);
    setUpdateId(id);
    setUpdateShow(true);

    const updatedInfo = {
      name: data.name || "",
      source_type: data.source_type_details[0]?.source_type,
      source_type_id: data.source_type_details[0]?._id,
      enquiry_purpose: data.purpose_enquiry?.enquiry_purpose || "",
      mobile_no: data.official_mobile || "",
      branch_id: data.branch_id?._id || "",
      company_name: data.company_name || "",
      designation: data.designation || "",
      official_email: data.official_email || "",
      alternate_email: data.alternate_email || "",
      official_mobile: data.official_mobile || "",
      alternate_mobile: data.alternate_mobile || "",
      // location: data.location || "",
      notes: data.notes || "",
      enquiry_date: data.enquiry_date || "",
      enquiry_time: data.enquiry_time || "",
      followups_status: data.followups_status || "",
      followups_time: data.followups_time || "",
      assign_staff: data.assign_staff || "",
      contact_type: data.contact_type || "",
      status_date: data.status_date || "",
      interest: data.interest || "",
      followups_date: data.followups_date || "",
      followups_notes: data.followups_notes || "",
      status_time: data.status_time || "",
    };

    setUpdataData(updatedInfo);
    console.log("Updated Data:", updatedInfo);
  };

  // const [endTime, setEndTime] = useState(null);

  const handleupdatesubmit = async (_id) => {
    try {
      // console.log(id, "the enquiry update schudule");
      const lastTime = new Date().toLocaleTimeString("en-US", {
        hour12: true,
      });
      console.log(`End Time: ${lastTime}`);
      setLastTime(lastTime);
      console.log(`Start Time: ${firstTime}`);
      const firstTimeDate = new Date(
        `1970-01-01T${convertTo24Hour(firstTime)}Z`
      );
      const lastTimeDate = new Date(`1970-01-01T${convertTo24Hour(lastTime)}Z`);
      const timeDifferenceInMillis = lastTimeDate - firstTimeDate;
      const timeDifferenceInSeconds = timeDifferenceInMillis / 1000;
      console.log(`Time difference: ${timeDifferenceInSeconds} seconds`);
      setTimeDifference(timeDifferenceInSeconds);
      console.log(
        `Time difference: ${timeDifferenceInSeconds} seconds`,
        timeDifference
      );
      function convertTo24Hour(time) {
        const [timePart, modifier] = time.split(" ");
        let [hours, minutes, seconds] = timePart.split(":");
        if (modifier === "PM" && hours !== "12") hours = parseInt(hours) + 12;
        if (modifier === "AM" && hours === "12") hours = "00";
        return `${hours}:${minutes}:${seconds}`;
      }
      console.log(_id, "idddss", updataData);
      const updateDatas = {
        branch_id: updataData.branch_id,
        name: updataData.name,
        company_name: updataData.company_name,
        designation: updataData.designation,
        official_email: updataData.official_email,
        alternate_email: updataData.alternate_email,
        official_mobile: updataData.official_mobile,
        alternate_mobile: updataData.alternate_mobile,
        // location: updataData.location,
        door_no: updataData.door_no,
        street_name: updataData.street_name,
        area: updataData.area,
        district: updataData.district,
        pin_code: updataData.pin_code,
        notes: updataData.notes,
        source_type: updataData.source_type_id,
        enquiry_date: UpdatedData.enquiry_date,
        enquiry_time: updataData.enquiry_time,
        purpose_enquiry: updataData.purpose_enquiry,
        followups_status: UpdatedData.followups_status,
        followups_time: updataData.followups_time,
        assign_staff: updataData.assign_staff,
        contact_type: updataData.contact_type,
        status_date: UpdatedData.status_date,
        interest: updataData.interest,
        start_time: firstTime,
        end_time: lastTime,
      };

      try {
        console.log(
          "The console update data as is update : ",
          updateDatas,
          _id
        );
        console.log("id is:", _id);
        const response = await axios.put(
          ApiUrl + `/leads/${updateId}`,
          updateDatas
        );
        console.log("the response update Student response : ", response);
        const name_id = response.data._id;
        const formDataaa = {
          name_id: name_id,
          official_mobile: updataData.official_mobile,
          enquiry_purpose: updataData.enquiry_purpose,
          source_type: updataData.source_type,
          enquiry_date: updataData.enquiry_date,
          status_date: UpdatedData.status_date,
          contact_type: updataData.contact_type,
          start_time: firstTime,
          end_time: lastTime,
          duration_time: UpdatedData.duration_time,
          assign_staff: updataData.assign_staff,
          notes: updataData.notes,
          followups_status: UpdatedData.followups_status,
        };
        console.log("the responsesssss: ", formDataaa);
        if (response.status == 200) {
          const response12 = await axios.post(
            ApiUrl + "/leadhistroy",
            formDataaa
          );
          console.log("the histroy folloup", response12);
        }

        if (response.status == 200) {
          fetchData();
          toast.success("Converted Sucessfully!");
          setUpdateShow(false);
        } else {
          toast.error("Failed to Convert !");
        }
      } catch (error) {
        toast.error("Error in Convert !");
        console.log("Error Fetching Data:", error.message);
      }
    } catch (error) {
      console.error("Error Updating Data:", error.message);
    }
  };

  // Helper function to format ISO date to YYYY-MM-DD
  const formatDatefitching = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  function interstedHandel(e) {
    console.log(e.target.value);

    setUpdatedData((prevData) => ({
      ...prevData,
      interest: e.target.value,
    }));
  }

  const getStatusLabelClass = (status) => {
    switch (status) {
      case "Unqualified":
        return "bg-danger1 text-white1";
      case "Reachable":
        return "bg-secondary1 text-white1";
      case "converted":
        return "bg-warning1 text-black1"; // Assuming black text for "converted"
      case "Rescheduled":
        return "bg-primary1 text-white1";
      case "Qualified":
        return "bg-success1 text-white1";
      case "NotReachable":
        return "bg-dark1 text-white1";
      case "Scheduled":
        return "bg-info1 text-white1";
      case "New":
        return "bg-dark1 text-white1";
      case "Walk-in-reschedule":
        return "bg-dark1 text-white1";
      default:
        return "";
    }
  };

  const [rolename, setrolename] = useState([]);
  useEffect(() => {
    const branchName = localStorage.getItem("branch_name");
    setrolename(branchName);
  }, []);

  const data = useMemo(() => {
    return responseData.map((item, index) => ({
      _id: item._id,

      branch_id: {
        _id: item.branch_id?._id ?? null,
        branch_name: item.branch_id?.branch_name ?? "",
      },

      purpose_enquiry: item.purpose_enquiry
        ? {
            _id: item.purpose_enquiry._id,
            enquiry_purpose: item.purpose_enquiry.enquiry_purpose,
          }
        : null,

      source_type_details: item.source_type_details?.length
        ? item.source_type_details.map((source) => ({
            _id: source._id,
            source_type: source.source_type,
          }))
        : [],

      name: item.name ?? "",
      company_name: item.company_name ?? "",
      designation: item.designation ?? "",
      official_email: item.official_email ?? "",
      alternate_email: item.alternate_email ?? "",
      official_mobile: item.official_mobile ?? "",
      alternate_mobile: item.alternate_mobile ?? "",
      // location: item.location ?? "",
      door_no: item.door_no,
      street_name: item.street_name,
      area: item.area,
      district: item.district,
      pin_code: item.pin_code,
      notes: item.notes ?? "",
      source_type: item.source_type ?? "",
      enquiry_date: item.enquiry_date ?? "",
      enquiry_time: item.enquiry_time ?? "",
      followups_status: item.followups_status ?? "",
      followups_time: item.followups_time ?? "",
      assign_staff: item.assign_staff ?? "",
      contact_type: item.contact_type ?? "",
      status_date: item.status_date ?? "",

      // Handle missing values gracefully
      followups_date: item.followups_date ?? "",
      status_time: item.status_time ?? "",
      followups_notes: item.followups_notes ?? "",
      interest: item.interest ?? "",

      serialNo: index + 1,
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "serialNo",
        disableFilters: true,
      },
      {
        Header: "Branch",
        accessor: "branch_id.branch_name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Mobile No",
        accessor: "official_mobile",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Type",
        accessor: "purpose_enquiry.enquiry_purpose",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Source",
        accessor: "source_type_details[0].source_type",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Status",
        accessor: "followups_status",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        width: 120,
        height: 10,
        Cell: ({ value }) => {
          const statusClass = getStatusLabelClass(value); // Get the class for the status

          return (
            <span
              className={`${statusClass}`} // Apply the class dynamically based on the status
              style={{
                display: "inline-block",
                width: "120px", // Set fixed width for the content
                height: "20px", // Set fixed height for the content
                lineHeight: "20px", // Vertically center text inside the span
                textAlign: "center", // Center align text horizontally
              }}
            >
              {value}
            </span>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // ========================================= Pagination

  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="#" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Leads / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Followups</b>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 d-flex">
            <h5 className="mt-2">Followups</h5>
          </div>
        </div>

        {/* ///////////////////////////////////////////////////////////////////////////////////// */}

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  value={state.pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 20, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      value={state.globalFilter || ""} // Use the globalFilter value
                      onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <TableContainer>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="d-flex">
                        <div className="mx-2">{column.render("Header")}</div>
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                        <div className="mx-1">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </div>
                      </div>
                    </Th>
                  ))}
                  <Th className="text-center">Action</Th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    ))}
                    <Td className="text-center">
                      <button
                        className="btn custom-btn-update btn-sm "
                        onClick={() =>
                          handleUpdated(row.original._id, row.original)
                        }
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>

                      <button
                        className="btn custom-btn-info btn-sm mx-2"
                        onClick={() => updateinfo(row.original._id)}
                      >
                        <i class="fa-solid fa-circle-info"></i>
                      </button>
                    </Td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>

        <Modal show={Updateshow} onHide={() => setUpdateShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Followups Update</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12">
                <div className="p-2 pb-0">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            disabled
                            value={updataData?.name || ""}
                            onChange={(e) =>
                              setUpdatedData({
                                ...updataData,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                          <label className={updataData.name ? "filled" : ""}>
                            Name
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="tel"
                            placeholder=""
                            disabled
                            value={updataData?.mobile_no || ""} // Fixed key to match state
                            required
                          />
                          <label
                            className={updataData?.mobile_no ? "filled" : ""}
                          >
                            Mobile No
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            disabled
                            value={updataData?.enquiry_purpose || ""} // Fixed key
                            required
                          />
                          <label
                            className={
                              updataData?.enquiry_purpose ? "filled" : ""
                            }
                          >
                            Type
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            disabled
                            value={updataData?.source_type || ""} // Ensure correct state mapping
                            required
                          />
                          <label
                            className={updataData?.source_type ? "filled" : ""}
                          >
                            Source
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="floating-label">
                        <select
                          className="form-control"
                          value={UpdatedData.followups_status || ""}
                          onChange={(e) =>
                            setUpdatedData({
                              ...UpdatedData,
                              followups_status: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select Followups Status</option>
                          <option value="Rescheduled">Rescheduled</option>
                          <option value="Qualified">Qualified</option>
                          <option value="Unqualified">Unqualified</option>
                          <option value="NotReachable">Not Reachable</option>
                        </select>
                        <label
                          className={
                            UpdatedData.followups_status ? "filled" : ""
                          }
                        ></label>
                      </div>
                    </div>

                    <div
                      className={`${
                        UpdatedData.followups_status == "Rescheduled"
                          ? "row"
                          : "d-none"
                      }`}
                    >
                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="date"
                            placeholder=""
                            value={
                              UpdatedData.status_date
                                ? formatDatefitching(UpdatedData.status_date)
                                : ""
                            }
                            onChange={(e) =>
                              setUpdatedData({
                                ...UpdatedData,
                                status_date: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={UpdatedData.status_date ? "filled" : ""}
                          >
                            Reschudule Date
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="time"
                            placeholder=""
                            value={UpdatedData.status_time}
                            onChange={(e) =>
                              setUpdatedData({
                                ...UpdatedData,
                                status_time: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={UpdatedData.mobile_no ? "filled" : ""}
                          >
                            Rescheduled Time
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${
                        UpdatedData.followups_status == "Qualified"
                          ? "row"
                          : "d-none"
                      }`}
                    >
                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="date"
                            placeholder=""
                            value={
                              UpdatedData.status_date
                                ? formatDatefitching(UpdatedData.status_date)
                                : ""
                            }
                            onChange={(e) =>
                              setUpdatedData({
                                ...UpdatedData,
                                status_date: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={UpdatedData.status_date ? "filled" : ""}
                          >
                            Visit Date
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="time"
                            placeholder=""
                            value={UpdatedData.status_time}
                            onChange={(e) =>
                              setUpdatedData({
                                ...UpdatedData,
                                status_time: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={UpdatedData.status_time ? "filled" : ""}
                          >
                            Visit Time
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        UpdatedData.followups_status === "Qualified" ||
                        UpdatedData.followups_status === "Rescheduled"
                          ? "row"
                          : "d-none"
                      }
                    >
                      <div className="floating-label">
                        <textarea
                          className="form-control"
                          value={UpdatedData.followups_notes || ""}
                          onChange={(e) =>
                            setUpdatedData({
                              ...UpdatedData,
                              followups_notes: e.target.value,
                            })
                          }
                          required
                        ></textarea>
                        <label
                          className={
                            UpdatedData.followups_notes ? "filled" : ""
                          }
                        >
                          Followups Notes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setUpdateShow(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={() => handleupdatesubmit(updataData._id)}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-7 col-sm-12 mt-1">
              <span className="text-dark">
                Page {state.pageIndex + 1} of {pageCount}
              </span>
            </div>
            <div className="col-lg-5">
              <div className="mt-3 pb-5">
                <nav>
                  <ul className="pagination justify-content-center">
                    {createPagination()} {/* Pagination buttons */}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Followups;
