




import ApiUrl from "../Environment/ApiUrl";
import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function Stock() {
  const [showModal, setShowModal] = useState(false);
  const [responseData, setResponseData] = useState([]);

  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      name: item.name,
      hsn_code: item.hsn_code,
      description: item.description,
      basic_price: item.basic_price,
      sales_margin: item.sales_margin,
      tax: item.tax,
      buying_quantity: item.buying_quantity,
      solding_quantity: item.solding_quantity,
      available_quantity: item.available_quantity,
      notes: item.notes,
      // status: item.status,
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "HSN Code",
        accessor: "hsn_code",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },

      {
        Header: "Basic Price",
        accessor: "basic_price",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Sales Margin",
        accessor: "sales_margin",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Tax",
        accessor: "tax",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Buying Qty",
        accessor: "buying_quantity",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Solding Qty",
        accessor: "solding_quantity",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Available Qty",
        accessor: "available_quantity",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [addData, setAddData] = useState({
    name: "",
    hsn_code: "",
    description: "",
    basic_price: "",
    sales_margin: "",
    tax: "",
    buying_quantity: "",
    solding_quantity: "",
    available_quantity: "",
    notes: "",
    // status: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      if (updatedData.buying_quantity && updatedData.solding_quantity) {
        updatedData.available_quantity =
          parseInt(updatedData.buying_quantity) -
            parseInt(updatedData.solding_quantity) || 0;
      }
      return updatedData;
    });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    console.log("Updated", setAddData);
    try {
      const formData = {
        name: addData.name,
        hsn_code: addData.hsn_code,
        description: addData.description,
        basic_price: addData.basic_price,
        sales_margin: addData.sales_margin,
        tax: addData.tax,
        buying_quantity: addData.buying_quantity,
        solding_quantity: addData.solding_quantity,
        available_quantity: addData.available_quantity,
        notes: addData.notes,
        // status: addData.status,
      };
      const response = await axios.post(ApiUrl + "/stock", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("added successfully!");
      setAddData({
        name: "",
        hsn_code: "",
        description: "",
        basic_price: "",
        sales_margin: "",
        tax: "",
        buying_quantity: "",
        solding_quantity: "",
        available_quantity: "",
        notes: "",
        // status: "",
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/stock");
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [updateData, setUpdateData] = useState({
    name: "",
    hsn_code: "",
    description: "",
    basic_price: "",
    sales_margin: "",
    tax: "",
    buying_quantity: "",
    solding_quantity: "",
    available_quantity: "",
    notes: "",
    // status: "",
  });

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      if (updatedData.buying_quantity && updatedData.solding_quantity) {
        updatedData.available_quantity =
          parseInt(updatedData.buying_quantity) -
            parseInt(updatedData.solding_quantity) || 0;
      }
      return updatedData;
    });
  };

  const [updateid, setUpdateId] = useState("");
  const handleUpdate = (_id, data) => {
    setUpdateId(_id);
    console.log(" the data to updata", data);
    setUpdateShowModal(true);
    setUpdateData(data);
  };

  const [showPreview, setShowPreview] = useState(false);

  const handlePreview = (data) => {
    setCommonData(data);
    setShowPreview(true);
  };

  const setCommonData = (data) => {
    const commonData = {
      name: data.name,
      hsn_code: data.hsn_code,
      description: data.description,
      basic_price: data.basic_price,
      sales_margin: data.sales_margin,
      tax: data.tax,
      buying_quantity: data.buying_quantity,
      solding_quantity: data.solding_quantity,
      available_quantity: data.available_quantity,
      notes: data.notes,
    };

    console.log("Setting data: ", commonData);
    setUpdateData(commonData);
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        name: updateData.name,
        hsn_code: updateData.hsn_code,
        description: updateData.description,
        basic_price: updateData.basic_price,
        sales_margin: updateData.sales_margin,
        tax: updateData.tax,
        buying_quantity: updateData.buying_quantity,
        solding_quantity: updateData.solding_quantity,
        available_quantity: updateData.available_quantity,
        notes: updateData.notes,
        // status: updateData.status,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(ApiUrl + `/stock/${updateid}`, formData);
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const [deleteShowModals, setDeleteShowModals] = useState(false);

  const handleOpenModal = (_id, name) => {
    console.log(" the id", _id);
    console.log(" the name", name);
    setdeleteid(_id);
    setdeletename(name);
    setDeleteShowModals(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(ApiUrl + `/stock/${getdeleteid}`);
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Stock</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 d-flex">
          <h6 className="mt-2 bold">Stock</h6>
          <div className="">
            <button
              className="btn btn-dark btn-sm add mx-3"
              onClick={() => setShowModal(true)}
            >
              Add
            </button>
          </div>
        </div>
        <div className="col-lg-2">
         
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    <button
                      className="btn custom-btn-update btn-sm mx-1"
                      onClick={() =>
                        handleUpdate(row.original._id, row.original)
                      }
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button
                      className="btn custom-btn-delete btn-sm "
                      onClick={() =>
                        handleOpenModal(row.original._id, row.original.name)
                      }
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>

                    <button
                      className="btn custom-btn-info btn-sm mx-1"
                      onClick={() => handlePreview(row.original)}
                    >
                      <i class="fa-solid fa-eye"></i>
                    </button>

                    <button className="btn custom-btn-info btn-sm">
                      <i class="fa-solid fa-circle-info"></i>
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

      <div className="insermodal">
        <Modal
          className="addmodals"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Stock</Modal.Title>
          </Modal.Header>

          <form>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-6">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.name}
                      onChange={(e) =>
                        setAddData({ ...addData, name: e.target.value })
                      }
                      required
                    />
                    <label className={addData.name ? "filled" : ""}>Name</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.hsn_code}
                      onChange={(e) =>
                        setAddData({ ...addData, hsn_code: e.target.value })
                      }
                      required
                    />
                    <label className={addData.hsn_code ? "filled" : ""}>
                      HSN Code
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={addData.description}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          description: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.description ? "filled" : ""}>
                      Description
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={addData.basic_price}
                      onChange={(e) =>
                        setAddData({ ...addData, basic_price: e.target.value })
                      }
                      required
                    />
                    <label className={addData.basic_price ? "filled" : ""}>
                      Basic Price
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={addData.sales_margin}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          sales_margin: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.sales_margin ? "filled" : ""}>
                      Sales Margin(%)
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={addData.tax}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          tax: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.tax ? "filled" : ""}>
                      Tax(%)
                    </label>
                  </div>
                </div>

                <div className="col-lg-6 ">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      name="buying_quantity"
                      placeholder=""
                      value={addData.buying_quantity}
                      onChange={handleChange}
                      required
                    />
                    <label className={addData.buying_quantity ? "filled" : ""}>
                      Buying Quantity
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      name="solding_quantity"
                      placeholder=""
                      value={addData.solding_quantity}
                      onChange={handleChange}
                      required
                    />
                    <label className={addData.solding_quantity ? "filled" : ""}>
                      Sold Quantity
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      name="available_quantity"
                      placeholder=""
                      value={addData.available_quantity}
                      readOnly
                    />
                    <label
                      className={addData.available_quantity ? "filled" : ""}
                    >
                      Available Quantity
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={addData.notes}
                      onChange={(e) =>
                        setAddData({ ...addData, notes: e.target.value })
                      }
                      required
                    />
                    <label className={addData.notes ? "filled" : ""}>
                      Notes
                    </label>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                className="cancelbut"
                variant="secondary"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                className="submitbut"
                type="submit"
                variant="primary"
                onClick={handlesubmit}
              >
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>

      {/* ///////////////////////////////////////////////////////////////////////////////// */}

      <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Stock </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.name}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, name: e.target.value })
                  }
                  required
                />
                <label className={updateData.name ? "filled" : ""}>Name</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.hsn_code}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, hsn_code: e.target.value })
                  }
                  required
                />
                <label className={updateData.hsn_code ? "filled" : ""}>
                  HSN Code
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  placeholder=""
                  value={updateData.description}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      description: e.target.value,
                    })
                  }
                  required
                />
                <label className={updateData.description ? "filled" : ""}>
                  Description
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  placeholder=""
                  value={updateData.basic_price}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      basic_price: e.target.value,
                    })
                  }
                  required
                />
                <label className={updateData.basic_price ? "filled" : ""}>
                  Basic Price
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  placeholder=""
                  value={updateData.sales_margin}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      sales_margin: e.target.value,
                    })
                  }
                  required
                />
                <label className={updateData.sales_margin ? "filled" : ""}>
                  Sales Margin
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  placeholder=""
                  value={updateData.tax}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      tax: e.target.value,
                    })
                  }
                  required
                />
                <label className={updateData.tax ? "filled" : ""}>Tax</label>
              </div>
            </div>

            <div className="col-lg-6 ">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  name="buying_quantity"
                  placeholder=""
                  value={updateData.buying_quantity}
                  onChange={handleUpdateChange}
                  required
                />
                <label className={updateData.buying_quantity ? "filled" : ""}>
                  Buying Quantity
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  name="solding_quantity"
                  placeholder=""
                  value={updateData.solding_quantity}
                  onChange={handleUpdateChange}
                  required
                />
                <label className={updateData.solding_quantity ? "filled" : ""}>
                  Sold Quantity
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  name="available_quantity"
                  placeholder=""
                  value={updateData.available_quantity}
                  readOnly
                />
                <label
                  className={updateData.available_quantity ? "filled" : ""}
                >
                  Available Quantity
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  placeholder=""
                  value={updateData.notes}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, notes: e.target.value })
                  }
                  required
                />
                <label className={updateData.notes ? "filled" : ""}>
                  Notes
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* preview */}

      <Modal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview Expense list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.name}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, name: e.target.value })
                  }
                  disabled
                />
                <label className={updateData.name ? "filled" : ""}>Name</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.hsn_code}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, hsn_code: e.target.value })
                  }
                  disabled
                />
                <label className={updateData.hsn_code ? "filled" : ""}>
                  HSN Code
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  placeholder=""
                  value={updateData.description}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      description: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.description ? "filled" : ""}>
                  Description
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  placeholder=""
                  value={updateData.basic_price}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      basic_price: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.basic_price ? "filled" : ""}>
                  Basic Price
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  placeholder=""
                  value={updateData.sales_margin}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      sales_margin: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.sales_margin ? "filled" : ""}>
                  Sales Margin
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  placeholder=""
                  value={updateData.tax}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      tax: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.tax ? "filled" : ""}>Tax</label>
              </div>
            </div>

            <div className="col-lg-6 ">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  name="buying_quantity"
                  placeholder=""
                  value={updateData.buying_quantity}
                  onChange={handleUpdateChange}
                  disabled
                />
                <label className={updateData.buying_quantity ? "filled" : ""}>
                  Buying Quantity
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  name="solding_quantity"
                  placeholder=""
                  value={updateData.solding_quantity}
                  onChange={handleUpdateChange}
                  disabled
                />
                <label className={updateData.solding_quantity ? "filled" : ""}>
                  Sold Quantity
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  name="available_quantity"
                  placeholder=""
                  value={updateData.available_quantity}
                  disabled
                />
                <label
                  className={updateData.available_quantity ? "filled" : ""}
                >
                  Available Quantity
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="tel"
                  placeholder=""
                  value={updateData.notes}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, notes: e.target.value })
                  }
                  disabled
                />
                <label className={updateData.notes ? "filled" : ""}>
                  Notes
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowPreview(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Stock :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stock;
