// import React, { useEffect, useState } from "react";
// import "../../assets/css/SalesOrderPrint.css";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import ApiUrl from "../Environment/ApiUrl";

// function SalesOrderPrint() {
//   // CSS for both screen display and print
//   const styles = {
//     page: {
//       width: "210mm",
//       height: "297mm",
//       padding: "5mm",
//       margin: "0 auto",
//       backgroundColor: "white",
//       boxSizing: "border-box",
//       fontFamily: "Arial, sans-serif",
//       fontSize: "10pt",
//       lineHeight: "1.3",
//       overflow: "hidden",
//     },
//     title: {
//       fontSize: "16pt",
//       fontWeight: "bold",
//       textAlign: "center",
//       marginBottom: "10px",
//     },
//     table: {
//       width: "100%",
//       borderCollapse: "collapse",
//       // marginBottom: '10px',
//       // border: '1px solid black',
//     },
//     th: {
//       border: "1px solid black",
//       padding: "5px",
//       backgroundColor: "#f2f2f2",
//       fontWeight: "bold",
//       textAlign: "center",
//       fontSize: "10pt",
//     },
//     td: {
//       border: "1px solid black",
//       padding: "5px",
//       textAlign: "left",
//       fontSize: "10pt",
//       verticalAlign: "top",
//     },
//     bold: {
//       fontWeight: "bold",
//     },
//     paragraphMargin: {
//       margin: "2px 0",
//     },
//     centralColumn: {
//       textAlign: "center",
//     },
//     rightColumn: {
//       textAlign: "center",
//     },
//     logoContainer: {
//       width: "90%",
//       paddingBottom: "30px",
//       textAlign: "center",
//     },

//     logo: {
//       width: "100%",
//       height: "80px",
//       marginTop: "30px",
//     },
//     signatureLine: {
//       width: "150px",
//       borderTop: "1px solid #000",
//       margin: "30px auto 5px",
//     },
//     marginleft: {
//       marginleft: "400px",
//     },
//   };

//   React.useEffect(() => {
//     // Add print-specific styles when component mounts
//     const style = document.createElement("style");
//     style.innerHTML = `
//       @media print {
//         @page {
//           size: A4 portrait;
//           margin: 0;
//         }

//     `;
//     document.head.appendChild(style);

//     return () => {
//       // Cleanup when component unmounts
//       document.head.removeChild(style);
//     };
//   }, []);

//   const handlePrint = () => {
//     window.print();
//   };

//   const [responseData, setResponseData] = useState([]); // Store a single object instead of an array
//   const { sales_order_no, id } = useParams();

//   console.log("Sales Order No:", sales_order_no);
//   console.log("ID:", id);

//   useEffect(() => {
//     fetchData();
//   }, [id]); // Re-fetch data if `id` changes

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`${ApiUrl}/salesorder/${id}`); // Fetch specific sales order
//       console.log("Response Data:", response.data);
//       setResponseData(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   return (
//     <div className="marginleft">
//       <div className="print-container" style={styles.page}>
//         <div style={{ padding: "2%" }} className="salesorder">
//           SALES ORDER BILL
//         </div>

//         {/* Header Section with Company and   */}
//         <table style={styles.table}>
//           <tbody>
//             <tr>
//               <td style={{ ...styles.td, width: "50%" }}>
//                 <p style={{ ...styles.bold, ...styles.paragraphMargin }}>
//                   Branch: ERODE
//                 </p>
//                 <p style={styles.paragraphMargin}>
//                   29/30, GH BACK SIDE, Pavadai St,
//                 </p>
//                 <p style={styles.paragraphMargin}>Kaikolar Thottam, Erode,</p>
//                 <p style={styles.paragraphMargin}>Tamil Nadu 638001</p>
//                 <p style={styles.paragraphMargin}>GSTIN: 33ABOCS7325J1ZY</p>
//                 <p style={styles.paragraphMargin}>
//                   Email: support@skimitarautomation.com
//                 </p>
//                 <p style={styles.paragraphMargin}>Phone: 7402574008</p>
//               </td>
//               <td style={{ ...styles.td, width: "50%" }}>
//                 <div style={styles.logoContainer}>
//                   <img
//                     alt="invoice logo"
//                     style={styles.logo}
//                     src={require("../../assets/img/scimitar logo slogan.png")}
//                   />
//                 </div>
//               </td>
//             </tr>
//           </tbody>
//         </table>
//         <div
//           style={{
//             display: "flex",
//             width: "100%",
//             borderLeft: "1px solid black",
//           }}
//         >
//           <div style={{ width: "751px" }}>
//             <table>
//               <thead>
//                 <tr>
//                   <th colSpan="2">Client Details</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td>
//                     <span>To:</span>
//                   </td>
//                   <td>{responseData?.client_name}</td>
//                 </tr>
//                 <tr>
//                   <td>
//                     <span>Address:</span>
//                   </td>
//                   <td>THALLY ROAD, HOSUR, 635114</td>
//                 </tr>
//                 <tr>
//                   <td>
//                     <span>GSTIN/UIN:</span>
//                   </td>
//                   <td>33AAMCA4282H1ZN</td>
//                 </tr>
//                 <tr>
//                   <td>
//                     <span>Email:</span>
//                   </td>
//                   <td>maintenance@alfapebltd.com</td>
//                 </tr>
//                 <tr>
//                   <td>
//                     <span>Phone:</span>
//                   </td>
//                   <td>88706 05747</td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//           <div style={{ borderLeft: "" }}></div>
//           <div style={{ width: "750px" }}>
//             <table style={{ width: "100%" }}>
//               <tbody style={{ width: "1000px", border: "", borderTop: "none" }}>
//                 <tr className="" style={{ borderLeft: "1px solid black" }}>
//                   <td style={{ borderRight: "1px solid black" }}>
//                     <span style={styles.bold}>INVOICE NO:</span>
//                   </td>
//                   <td style={{ width: "49%", borderRight: "1px solid black" }}>
//                     {responseData?.sales_order_no}
//                   </td>
//                 </tr>
//                 <tr style={{ border: "1px solid black" }}>
//                   <td style={{ border: "1px solid black" }}>
//                     <span style={styles.bold}>DATE:</span>
//                   </td>
//                   <td style={{}}>{responseData?.date}</td>
//                 </tr>
//                 <tr style={{ border: "1px solid black" }}>
//                   <td style={{ border: "1px solid black" }}>
//                     <span style={styles.bold}>PO NO:</span>
//                   </td>
//                   <td style={{}}>242510778</td>
//                 </tr>
//                 <tr style={{ border: "1px solid black" }}>
//                   <td style={{ border: "1px solid black" }}>
//                     <span style={styles.bold}>PO DATE:</span>
//                   </td>
//                   <td style={{ border: "1px solid black" }}>18-Jan-25</td>
//                 </tr>

//                 <tr
//                   style={{
//                     borderLeft: "1px solid black",
//                     borderBottom: "1px solid black",
//                     borderRight: "1px solid black",
//                   }}
//                 >
//                   <center>
//                     <th colSpan="">Terms and conditions</th>
//                   </center>
//                 </tr>
//                 <tr>
//                   <td
//                     colSpan="2"
//                     style={{
//                       borderLeft: "1px solid black",
//                       borderRight: "1px solid black",
//                       padding: "5px",
//                     }}
//                   >
//                     <p style={{ margin: "5px 0" }}>
//                       1. Goods Once Sold Cannot Be Taken Back
//                     </p>
//                     <p style={{ margin: "5px 0" }}>
//                       2. Subject to Erode Jurisdiction
//                     </p>
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//         {/* Invoice Items */}
//         <table>
//           <thead>
//             <tr>
//               <th style={{ ...styles.th, width: "5%" }}>Sl</th>
//               <th style={{ ...styles.th, width: "25%" }}>Description</th>
//               <th style={{ ...styles.th, width: "10%" }}>HSN/SAC</th>
//               <th style={{ ...styles.th, width: "5%" }}>Qty</th>
//               <th style={{ ...styles.th, width: "10%" }}>Rate</th>
//               <th style={{ ...styles.th, width: "10%" }}>BASIC</th>
//               <th style={{ ...styles.th, width: "10%" }}>18% TAX</th>
//               <th style={{ ...styles.th, width: "15%" }}>Total</th>
//             </tr>
//           </thead>

//           <tbody>
//             {" "}
//             {responseData && responseData.items ? (
//               responseData.items.map((item, index) => (
//                 <tr key={index}>
//                   <td style={{ ...styles.td, ...styles.centralColumn }}>
//                     {index + 1}
//                   </td>
//                   <td style={{ ...styles.td, ...styles.centralColumn }}>
//                     Product Name
//                   </td>
//                   <td style={{ ...styles.td, ...styles.centralColumn }}>
//                     HSN/SAC CODE
//                   </td>
//                   <td style={{ ...styles.td, ...styles.centralColumn }}>
//                     {item.quantity}
//                   </td>
//                   <td style={{ ...styles.td, ...styles.rightColumn }}>
//                     {item.rate}
//                   </td>
//                   <td style={{ ...styles.td, ...styles.rightColumn }}>
//                     {(item.quantity * item.rate).toFixed(2)}
//                   </td>
//                   <td style={{ ...styles.td, ...styles.rightColumn }}>
//                     {(item.quantity * item.rate * 0.18).toFixed(2)}
//                   </td>
//                   <td style={{ ...styles.td, ...styles.rightColumn }}>
//                     {item.total.toFixed(2)}
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="8" style={{ textAlign: "center" }}>
//                   Loading...
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>

//         <div
//           style={{
//             display: "flex",
//             width: "100%",
//             borderLeft: "1px solid black",
//           }}
//         >
//           <div style={{ width: "751px" }}>
//             <table>
//               <tbody>
//                 <tr>
//                   <p style={styles.paragraphMargin}>
//                     <span style={styles.bold}>Amount in Words:</span>{" "}
//                   </p>
//                 </tr>
//                 <tr>
//                   <p>Five Thousand Nine Hundred Rupees Only</p>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//           {/* <div style={{ borderLeft: "1px solid black" }}></div> */}
//           <div
//             style={{ width: "750px", borderRight: "1px solid black" }}
//             className="bordernone"
//           >
//             <table style={{ width: "100%" }} className="">
//               <tbody style={{ width: "1000px", border: "", borderTop: "none" }}>
//                 <tr className="">
//                   <td
//                     style={{
//                       borderLeft: "1px solid black",
//                       borderRight: "1px solid black",
//                     }}
//                   >
//                     <span style={styles.bold}>Subtotal:</span>
//                   </td>
//                   <td
//                     style={{ borderRight: "1px solid black", width: "55.5%" }}
//                     className="text-center"
//                   >
//                     {responseData?.subtotal}
//                   </td>
//                 </tr>
//                 <tr style={{ border: "1px solid black" }}>
//                   <td style={{ border: "1px solid black" }}>
//                     <span style={styles.bold}>CGST:</span>
//                   </td>
//                   <td
//                     style={{ border: "1px solid black" }}
//                     className="text-center"
//                   >
//                     450
//                   </td>
//                 </tr>
//                 <tr style={{ border: "1px solid black" }}>
//                   <td style={{ border: "1px solid black" }}>
//                     <span style={styles.bold}>SGST:</span>
//                   </td>
//                   <td
//                     style={{ border: "1px solid black" }}
//                     className="text-center"
//                   >
//                     450
//                   </td>
//                 </tr>
//                 <tr style={{ borderLeft: "1px solid black" }}>
//                   <td style={{ borderRight: "1px solid black" }}>
//                     <span style={styles.bold}>Total:</span>
//                   </td>
//                   <td
//                     style={{ borderRight: "1px solid black" }}
//                     className="text-center"
//                   >
//                     {responseData?.nettotal}
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>

//         {/* Bank Details */}
//         <table style={styles.table}>
//           <tbody>
//             <tr>
//               <td style={styles.td}>
//                 <p style={{ ...styles.bold, ...styles.paragraphMargin }}>
//                   Bank Details:
//                 </p>
//                 <p style={styles.paragraphMargin}>
//                   NAME: HDFC{" "}
//                   <span style={{ marginLeft: "50%" }}>
//                     BRANCH:CATCHERY STREET
//                   </span>{" "}
//                 </p>
//                 <p style={styles.paragraphMargin}>
//                   ACC NO: 50200106369528{" "}
//                   <span style={{ marginLeft: "36.5%" }}>IFSC: HDFC0001589</span>
//                 </p>
//               </td>
//             </tr>
//           </tbody>
//         </table>

//         {/* Footer with Declaration and Signature */}
//         <table style={styles.table}>
//           <thead>
//             <tr style={{ borderLeft: "1px solid" }}>
//               <th style={{ width: "50%", borderRight: "1px solid black" }}>
//                 Declaration
//               </th>
//               <th
//                 style={{
//                   width: "50%",
//                   textAlign: "center",
//                   borderRight: "1px solid black",
//                 }}
//               >
//                 For Skimitar Hindusthan Pvt Ltd
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td style={{ ...styles.td, borderLeft: "1px solid black" }}>
//                 <p style={styles.paragraphMargin}>
//                   Above information is true to my knowledge!
//                 </p>
//                 <p style={styles.paragraphMargin}>
//                   For clarification, don't hesitate to contact us.
//                 </p>
//               </td>
//               <td
//                 style={{
//                   ...styles.td,
//                   textAlign: "center",
//                   borderRight: "1px solid black",
//                 }}
//               >
//                 <div style={styles.signatureLine}></div>
//                 <p style={styles.paragraphMargin}>Authorized Signatory</p>
//               </td>
//             </tr>
//             <tr>
//               <td
//                 colSpan="2"
//                 style={{
//                   textAlign: "center",
//                   fontWeight: "bold",
//                   paddingTop: "10px",
//                   border: "1px solid black",
//                   width: "100%",
//                   padding: "1%",
//                 }}
//               >
//                 Thank You
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default SalesOrderPrint;

import React, { useEffect, useState } from "react";
import "../../assets/css/SalesOrderPrint.css";

import { useParams } from "react-router-dom";
import axios from "axios";
import ApiUrl from "../Environment/ApiUrl";
import { ToWords } from "to-words";

function SalesOrderPrint() {
  const styles = {
    page: {
      width: "210mm",
      height: "297mm",
      padding: "5mm",
      margin: "0 auto",
      backgroundColor: "white",
      boxSizing: "border-box",
      fontFamily: "Arial, sans-serif",
      fontSize: "10pt",
      lineHeight: "1.3",
      overflow: "hidden",
    },
    title: {
      fontSize: "16pt",
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "10px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      // marginBottom: '10px',
      // border: '1px solid black',
    },
    th: {
      border: "1px solid black",
      padding: "5px",
      backgroundColor: "#f2f2f2",
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "10pt",
    },
    td: {
      border: "1px solid black",
      padding: "5px",
      textAlign: "left",
      fontSize: "10pt",
      verticalAlign: "top",
    },
    bold: {
      fontWeight: "bold",
    },
    paragraphMargin: {
      margin: "2px 0",
    },
    centralColumn: {
      textAlign: "center",
    },
    rightColumn: {
      textAlign: "center",
    },
    logoContainer: {
      width: "90%",
      paddingBottom: "30px",
      textAlign: "center",
    },

    logo: {
      width: "100%",
      height: "80px",
      marginTop: "30px",
    },
    signatureLine: {
      width: "150px",
      borderTop: "1px solid #000",
      margin: "30px auto 5px",
    },
    marginleft: {
      marginleft: "400px",
    },
  };

  const [responseData, setResponseData] = useState(null); // Store a single object instead of an array
  const { sales_order_no, id } = useParams();
  const [words, setwords] = useState("");

  console.log("Sales Order No:", sales_order_no);
  console.log("ID:", id);

  useEffect(() => {
    fetchData();
  }, [id]); // Re-fetch data if `id` changes

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: "Rupees",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paisa",
          symbol: "",
        },
      },
    },
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/salesorder/${id}`); // Fetch specific sales order
      console.log("Response Data:", response.data);
      setResponseData(response.data);

      const totalamount = toWords.convert(response.data[0]?.nettotal);
      console.log("the total amopunt", totalamount)
      setwords(totalamount);

    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

 


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  React.useEffect(() => {
    // Add print-specific styles when component mounts
    const style = document.createElement("style");
    style.innerHTML = `
      @media print {
        @page {
          size: A4 portrait;
          margin: 0;
        }
     
    `;
    document.head.appendChild(style);

    return () => {
      // Cleanup when component unmounts
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div className="marginleft">
      {console.log("the data ", words)}
      <div className="print-container" style={styles.page}>
        <div style={{ padding: "2%" }} className="salesorder">
          SALES ORDER BILL
        </div>

        {/* Header Section with Company and   */}
        <table style={styles.table}>
          <tbody>
            <tr>
              <td style={{ ...styles.td, width: "50%" }}>
                <p style={{ ...styles.bold, ...styles.paragraphMargin }}>
                  Branch: ERODE
                </p>
                <p style={styles.paragraphMargin}>
                  29/30, GH BACK SIDE, Pavadai St,
                </p>
                <p style={styles.paragraphMargin}>Kaikolar Thottam, Erode,</p>
                <p style={styles.paragraphMargin}>Tamil Nadu 638001</p>
                <p style={styles.paragraphMargin}>GSTIN: 33ABOCS7325J1ZY</p>
                <p style={styles.paragraphMargin}>
                  Email: support@skimitarautomation.com
                </p>
                <p style={styles.paragraphMargin}>Phone: 7402574008</p>
              </td>
              <td style={{ ...styles.td, width: "50%" }}>
                <div style={styles.logoContainer}>
                  <img
                    alt="invoice logo"
                    style={styles.logo}
                    src={require("../../assets/img/scimitar logo slogan.png")}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            width: "100%",
            borderLeft: "1px solid black",
          }}
        >
          <div style={{ width: "751px" }}>
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Client Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>To:</span>
                  </td>
                  <td>
                    {responseData ? responseData[0]?.client_name : "Loading"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Address:</span>
                  </td>
                  <td>THALLY ROAD, HOSUR, 635114</td>
                </tr>
                <tr>
                  <td>
                    <span>GSTIN/UIN:</span>
                  </td>
                  <td>33AAMCA4282H1ZN</td>
                </tr>
                <tr>
                  <td>
                    <span>Email:</span>
                  </td>
                  <td>{responseData ? responseData[0]?.email : "loading"}</td>
                </tr>
                <tr>
                  <td>
                    <span>Phone:</span>
                  </td>
                  <td>{responseData ? responseData[0]?.mobile : "loading"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ borderLeft: "" }}></div>
          <div style={{ width: "750px" }}>
            <table style={{ width: "100%" }}>
              <tbody style={{ width: "1000px", border: "", borderTop: "none" }}>
                <tr className="" style={{ borderLeft: "1px solid black" }}>
                  <td
                    style={{ borderRight: "1px solid black" }}
                    className="text-center"
                  >
                    <span style={styles.bold}>INVOICE NO:</span>
                  </td>
                  <td
                    style={{ width: "49%", borderRight: "1px solid black" }}
                    className="text-center"
                  >
                    {responseData ? responseData[0]?.sales_order_no : "Loading"}
                  </td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td
                    style={{ border: "1px solid black" }}
                    className="text-center"
                  >
                    <span style={styles.bold}>DATE:</span>
                  </td>
                  <td className="text-center">
                    {responseData
                      ? formatDate(responseData[0]?.date)
                      : "Loading"}
                  </td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td
                    style={{ border: "1px solid black" }}
                    className="text-center"
                  >
                    <span style={styles.bold}>PO NO:</span>
                  </td>
                  <td style={{}} className="text-center">
                    {responseData ? responseData[0]?.paid_till_date : "Loading"}
                  </td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td
                    style={{ border: "1px solid black" }}
                    className="text-center"
                  >
                    <span style={styles.bold}>PO DATE:</span>
                  </td>
                  <td
                    style={{ border: "1px solid black" }}
                    className="text-center"
                  >
                    {responseData
                      ? formatDate(responseData[0]?.payment_due_date)
                      : "loading"}
                  </td>
                </tr>

                <tr
                  style={{
                    borderLeft: "1px solid black",
                    borderBottom: "1px solid black",
                    borderRight: "1px solid black",
                  }}
                >
                  <center>
                    <th colSpan="">Terms and conditions</th>
                  </center>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      padding: "5px",
                    }}
                  >
                    <p style={{ margin: "5px 0" }}>
                      1. Goods Once Sold Cannot Be Taken Back
                    </p>
                    <p style={{ margin: "5px 0" }}>
                      2. Subject to Erode Jurisdiction
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Invoice Items */}
        <table style={{ height: "30vh" }}>
          <thead>
            <tr>
              <th style={{ ...styles.th, width: "5%" }}>Sl</th>
              <th style={{ ...styles.th, width: "25%" }}>Description</th>
              <th style={{ ...styles.th, width: "20%" }}>HSN/SAC</th>
              <th style={{ ...styles.th, width: "20%" }}>Qty</th>
              <th style={{ ...styles.th, width: "10%" }}>Rate</th>
              <th style={{ ...styles.th, width: "10%" }}>BASIC</th>
              <th style={{ ...styles.th, width: "10%" }}>18% TAX</th>
              <th style={{ ...styles.th, width: "15%" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(responseData) && responseData.length > 0 ? (
              responseData.map((item, index) =>
                item.items.map((product, productIndex) => {
                  // Extract the correct product details using the products ID
                  const productDetails = item.product?.find(
                    (prod) => prod._id === product.products
                  );

                  return (
                    <tr key={`${index}-${productIndex}`}>
                      <td style={{ ...styles.td, ...styles.centralColumn }}>
                        {index + 1} {/* Serial Index */}
                      </td>
                      <td style={{ ...styles.td, ...styles.centralColumn }}>
                        {productDetails ? productDetails.name : "No Products"}{" "}
                        {/* Product Name */}
                      </td>
                      <td style={{ ...styles.td, ...styles.centralColumn }}>
                        {responseData[0]?.product[0]?.hsn_code}
                        {console.log(
                          "the data was comsole by ",
                          responseData[0]?.product[0]?.hsn_code
                        )}{" "}
                        {/* HSN Code */}
                      </td>
                      <td style={{ ...styles.td, ...styles.centralColumn }}>
                        {product.quantity || "No Quantity"} {/* Quantity */}
                      </td>
                      <td style={{ ...styles.td, ...styles.centralColumn }}>
                        {product.rate || "No Rate"} {/* Rate */}
                      </td>
                      <td style={{ ...styles.td, ...styles.centralColumn }}>
                        {product.rate * product.quantity || "No Basic"}{" "}
                        {/* Basic Amount */}
                      </td>
                      <td style={{ ...styles.td, ...styles.centralColumn }}>
                        {(product.rate * product.quantity * 0.18).toFixed(2) ||
                          "No Tax"}{" "}
                        {/* 18% Tax */}
                      </td>
                      <td style={{ ...styles.td, ...styles.centralColumn }}>
                        {product.total || "No Total"} {/* Total Amount */}
                      </td>
                    </tr>
                  );
                })
              )
            ) : (
              <tr>
                <td colSpan="8" style={{ textAlign: "center" }}>
                  Loading...
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div
          style={{
            display: "flex",
            width: "100%",
            borderLeft: "1px solid black",
          }}
        >
          <div style={{ width: "751px" }}>
            <table>
              <tbody>
                <tr>
                  <p style={styles.paragraphMargin}>
                    <span style={styles.bold}>Amount in Words:</span>{" "}
                  </p>
                </tr>

                <tr>
                  <p>{words}</p>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div style={{ borderLeft: "1px solid black" }}></div> */}
          <div
            style={{ width: "750px", borderRight: "1px solid black" }}
            className="bordernone"
          >
            <table style={{ width: "100%" }} className="">
              <tbody style={{ width: "1000px", border: "", borderTop: "none" }}>
                <tr className="">
                  <td
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    <span style={styles.bold}>Subtotal:</span>
                  </td>
                  <td
                    style={{ borderRight: "1px solid black", width: "55.5%" }}
                    className="text-center"
                  >
                    {/* {responseData[0]?.subtotal || "no amount"} */}
                    {responseData && responseData[0]?.subtotal}
                  </td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td style={{ border: "1px solid black" }}>
                    <span style={styles.bold}>CGST:</span>
                  </td>
                  <td
                    style={{ border: "1px solid black" }}
                    className="text-center"
                  >
                    {responseData && responseData[0]?.gst}
                  </td>
                </tr>
                <tr style={{ border: "1px solid black" }}>
                  <td style={{ border: "1px solid black" }}>
                    <span style={styles.bold}>SGST:</span>
                  </td>
                  <td
                    style={{ border: "1px solid black" }}
                    className="text-center"
                  >
                    {/* 450 */}0.00
                  </td>
                </tr>
                <tr style={{ borderLeft: "1px solid black" }}>
                  <td style={{ borderRight: "1px solid black" }}>
                    <span style={styles.bold}>Total:</span>
                  </td>
                  <td
                    style={{ borderRight: "1px solid black" }}
                    className="text-center"
                  >
                    {responseData && responseData[0]?.nettotal}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Bank Details */}
        <table style={styles.table}>
          <tbody>
            <tr>
              <td style={styles.td}>
                <p style={{ ...styles.bold, ...styles.paragraphMargin }}>
                  Bank Details:
                </p>
                <p style={styles.paragraphMargin}>
                  NAME: HDFC{" "}
                  <span style={{ marginLeft: "50%" }}>
                    BRANCH:CATCHERY STREET
                  </span>{" "}
                </p>
                <p style={styles.paragraphMargin}>
                  ACC NO: 50200106369528{" "}
                  <span style={{ marginLeft: "36.5%" }}>IFSC: HDFC0001589</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        {/* Footer with Declaration and Signature */}
        <table style={styles.table}>
          <thead>
            <tr style={{ borderLeft: "1px solid" }}>
              <th style={{ width: "50%", borderRight: "1px solid black" }}>
                Declaration
              </th>
              <th
                style={{
                  width: "50%",
                  textAlign: "center",
                  borderRight: "1px solid black",
                }}
              >
                For Skimitar Hindusthan Pvt Ltd
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ ...styles.td, borderLeft: "1px solid black" }}>
                <p style={styles.paragraphMargin}>
                  Above information is true to my knowledge!
                </p>
                <p style={styles.paragraphMargin}>
                  For clarification, don't hesitate to contact us.
                </p>
              </td>
              <td
                style={{
                  ...styles.td,
                  textAlign: "center",
                  borderRight: "1px solid black",
                }}
              >
                <div style={styles.signatureLine}></div>
                <p style={styles.paragraphMargin}>Authorized Signatory</p>
              </td>
            </tr>
            <tr>
              <td
                colSpan="2"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  paddingTop: "10px",
                  border: "1px solid black",
                  width: "100%",
                  padding: "1%",
                }}
              >
                Thank You
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SalesOrderPrint;
