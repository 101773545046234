import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../assets/css/AnnexureThree.css";
import axios from "axios";
import ApiUrl from "../Environment/ApiUrl";
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "background",
  "align",
];

function AnnexureThree() {
  const defaultMessage = `
    <h3>Terms & Conditions</h3>
    <h3>DELIVERY:</h3>
    <p>Ex-works delivery 30 working days from the date of receipt of drawing approval. Drawing submission will be done within 7 working days from the date of receipt of the contract coming into force, i.e., receipt of a techno-commercially clear order along with advance where applicable.</p>

    <h3>PRICE BASE:</h3>
    <p>Ex-Works, Hosur, and firm within the validity period of work, i.e., up to mutually agreed terms.</p>

    <h3>PAYMENT TERMS:</h3>
    <p>90% payment advance on value along with PO as mentioned in perform invoice for material which
    includes tax. Balance 10% within 7 Days from the date of Material received.</p>

    <h3>CONTRACT COMING INTO FORCE:</h3>
    <p>After fulfilling all the following conditions: receipt of confirmed Purchase order, receipt of advance
    payment & clarification of all technical and commercial points.</p>

    <h3>FREIGHT:</h3>
    <p>Freight charges shall be as per expense.</p>

    <h3>INSURANCE:</h3>
    <p>Shall be extra cost at actual.</p>

    <h3>RISK PURCHASE:</h3>
    <p>CONSEQUENTIAL DAMAGES/LATENT DEFECTS: Shall not be acceptable.</p>

    <h3>Yours faithfully,</h3>
    <p>Aravinth</p>
  `;

  const [editorContent, setEditorContent] = useState("");

  useEffect(() => {
    setEditorContent(defaultMessage);
  }, []);

  const navigate = useNavigate();

  const { id: expectedId } = useParams();
  const [responseDatas, setResponseDatas] = useState([]);

  const handleAnnexurePreview = async () => {
    try {
      if (!expectedId) {
        console.error("Error: expectedId is undefined.");
        return;
      }

      const formattedItems = responseDatas.map((item) => ({
        expected_id: expectedId,
        annexure_description_two: item.annexure_description_two,
        annexure_qty_two: parseFloat(item.annexure_qty_two) || 0,
        make: item.make,
      }));

      const cleanedFormData = {
        items: formattedItems,
        terms_and_conditions: editorContent,
      };

      console.log("Sending data:", cleanedFormData);

      const response = await axios.put(
        `${ApiUrl}/quotation_list/${expectedId}`,
        cleanedFormData
      );

      if (response.data) {
        console.log("Successfully posted quotation data", response.data);
        navigate(`/annexurepreview/${expectedId}`);
      } else {
        alert("Server returned an empty response. Please try again.");
      }
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };

  return (
    <>
      <div style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-secondary dashBoard_text">
              <b>Projects / </b>
            </Link>
            <Link to="/upcomings" className="text-secondary dashBoard_text">
              <b>Expected / </b>
            </Link>
            <Link to="/Quotation" className="text-secondary dashBoard_text">
              <b>Quotation / </b>
            </Link>
            <Link to="/annexurethree" className="text-danger dashBoard_text">
              <b>AnnexureThree</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h2 className="mb-0">Full-Featured Text Editor</h2>
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => handleAnnexurePreview(expectedId)} // ✅ Fixed
        >
          Next
        </button>
      </div>

      <ReactQuill
        theme="snow"
        value={editorContent}
        onChange={setEditorContent}
        modules={modules}
        formats={formats}
        placeholder="Write something amazing..."
        style={{ height: "300px", marginBottom: "20px" }}
      />

      <h3 className="mt-5">Editor Output:</h3>
      <div
        className="border p-3"
        style={{ minHeight: "100px", background: "#f8f9fa" }}
        dangerouslySetInnerHTML={{ __html: editorContent }}
      />
    </>
  );
}

export default AnnexureThree;