import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ApiUrl from "./../Environment/ApiUrl";
import "../../assets/css/Common.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function ExpenseList() {
  const [responseData, setResponseData] = useState([]);
  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      branch_id: item.branch_id,
      type_main: item.type[0] ? item.type[0].type : "-",
      type: item.type[0]?._id,
      type_id: item.type.length != 0 ? item.type[0]?.type : "-",  
      date: item.date,
      notes: item.notes,
      amount: item.amount,
      expense_type: item.expense_type,
      // from: item.from,
      paidto: item.paidto,
      paytype: item.paytype,
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Expense Type",
        accessor: "expense_type",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Type",
        accessor: "type_id",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Paid To",
        accessor: "paidto",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      // {
      //   Header: "Date",
      //   accessor: "date",
      //   Filter: DefaultColumnFilter,
      //   filter: "multiSelectFilter",
      // },
      {
        Header: "Date",
        accessor: (row) => {
          const date = new Date(row.date);
          return date
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replace(/\//g, "-"); // Replaces slashes with dashes
        },
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "PayType",
        accessor: "paytype",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [responseDatas, setResponseDatas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [branch, setbranch] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [officeexpense, setOfficeexpense] = useState([]);
  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});
  const [paymenttype, setpaymenttype] = useState([]);
  // current date//////////////////////////////
  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date); // Convert input to Date if it's not already
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [rolename, setrolename] = useState([]);
  useEffect(() => {
    fetchData();
    const rolename = localStorage.getItem("rolename");
    setrolename(rolename);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/expense_list");
      console.log("Response from serversssssssss:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    type: "",
    date: formatDate(new Date()),
    notes: "",
    expense_type: "",
    amount: "",
    // from: "",
    paidto: "",
    paytype: "",
  });

  useEffect(() => {
    fetchDatas();
    fetchDataed();
    gettoken();
    fetchVendorDetails();
    paymenttyperoute();
    fetchExpenseDetails();
  }, []);
  const fetchDatas = async () => {
    try {
      const response = await axios.get(ApiUrl + "/expense_type");
      console.log("Response from server type:", response.data);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // const fetchDataed = async () => {
  //   try {
  //     const response = await axios.get(apiUrl + "/Branch");
  //     console.log("Response from server:", response.data);
  //     setbranch(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };
  const fetchDataed = async () => {
    try {
      const response = await axios.get(ApiUrl + "/Branch");
      console.log("Response from server:", response.data);
      setbranch(response.data); // Ensure you set the data correctly
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const paymenttyperoute = async () => {
    try {
      const response = await axios.get(ApiUrl + "/paytype");
      console.log("Response from server:", response.data);
      setpaymenttype(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchExpenseDetails = async () => {
    try {
      const response = await axios.get(ApiUrl + "/Officeexpense");
      console.log("Response from expense_details API:", response.data);
      setOfficeexpense(response.data); // Set Office Expense data
    } catch (error) {
      console.error("Error fetching Office Expense data:", error.message);
    }
  };

  // Function to fetch vendor details
  const fetchVendorDetails = async () => {
    try {
      const response = await axios.get(ApiUrl + "/vendor_details");
      console.log("Response from vendor_details API:", response.data);
      setVendor(response.data); // Set vendor data
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  const gettoken = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(ApiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);

      setroleid(response.data.detail.rolename);

      localStorage.setItem("role_id", response.data.detail.rolename);

      const id = response.data.detail.rolename;

      if (id) {
        try {
          const response = await axios.get(ApiUrl + `/role_2/${id}`);

          const modules = response.data.modules;

          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );

          setprevillages(filtered_access[0].actions);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  // const handleUpdate = (_id, data) => {
  //   setUpdateId(_id);

  //   const date = new Date(data.date);
  //   if (isNaN(date)) {
  //     console.error("Invalid date:", data.date);
  //     return;
  //   }
  //   setUpdateShowModal(true);
  //   const formattedDate = date.toLocaleDateString("en-CA");
  //   console.log("the data type ", data);
  //   setUpdateData({
  //     branch_id: data.branch_id[0]?._id,
  //     branch_name: data.branch_id[0]?.branch_name,
  //     // type: data.type[0]._id,
  //     // type: data.type.length != 0 ? data.type?.type : "-",
  //     type:
  //       Array.isArray(data.type) && data.type.length > 0
  //         ? data.type[0]?.type
  //         : "-",

  //     // type: data.type,
  //     date: formattedDate,
  //     expense_type: data.expense_type,
  //     notes: data.notes,
  //     amount: data.amount,
  //     // from: data.from,
  //     paidto: data.paidto,
  //     paytype: data.paytype,
  //   });
  //   console.log("the data type updateData", updateData);
  // };

  const setCommonData = (data) => {
    const date = new Date(data.date);
    if (isNaN(date)) {
      console.error("Invalid date:", data.date);
      return;
    }

    const formattedDate = date.toLocaleDateString("en-CA");

    const commonData = {
      branch_id: data.branch_id[0]?._id,
      branch_name: data.branch_id[0]?.branch_name,
      type: data.type,
        
      date: formattedDate,
      expense_type: data.expense_type,
      notes: data.notes,
      amount: data.amount,
      paidto: data.paidto,
      paytype: data.paytype,
    };

    console.log("Setting data: ", commonData);
    setUpdateData(commonData); // Set for both modals
  };

  const handleUpdate = (_id, data) => {
    setUpdateId(_id);
    setCommonData(data);
    setUpdateShowModal(true); // Open update modal
  };

  const handlePreview = (data) => {
    setCommonData(data);
    setShowPreview(true); // Open preview modal
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        branch_id: updateData.branch_id,
        // type: updateData.type[0]._id
        type: updateData.type,
        // type: updateData.type.length != 0 ? updateData.type[0].type : "-",
        date: updateData.date,
        notes: updateData.notes,
        expense_type: updateData.expense_type,
        amount: updateData.amount,
        paidto: updateData.paidto,
        paytype: updateData.paytype,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        ApiUrl + `/expense_list/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (_id) => {
    try {
      await axios.delete(ApiUrl + `/expense_list/${getdeleteid}`);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting role:", error.message);
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const handleOpenModal = (_id, notes) => {
    console.log(" the id", _id);
    console.log(" the name", notes);
    setdeleteid(_id);
    setdeletename(notes);
    setDeleteShowModals(true);
  };

  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  // the pagination  end ---------------------------------------------------------------------

  const navigate = useNavigate();

  const handleExpenseType = () => {
    navigate("/expensetype");
  };

  const [addData, setAddData] = useState({
    branch_id: "",
    type: "",
    date: "",
    notes: "",
    expense_type: "",
    amount: "",
    // from: "",
    paidto: "",
    paytype: "",
  });

  // const handleDateChange = (e) => {
  //   setUpdateData((prevData) => ({
  //     ...prevData,
  //     date: e.target.value, // Input type=date handles the format
  //   }));
  // };

  const handlesubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        branch_id: addData.branch_id,
        type: addData.type ? addData.type : "",
        date: addData.date,
        notes: addData.notes,
        expense_type: addData.expense_type,
        amount: addData.amount,
        // from: addData.from,
        paidto: addData.paidto,
        paytype: addData.paytype,
      };

      const response = await axios.post(ApiUrl + "/expense_list", formData);
      console.log("Addeddddddddd:", response);

      // Reset the form and close the modal
      setShowModal(false);
      toast.success("Added successfully!");
      fetchData(); // Fetch updated data
      setAddData({
        branch_id: "",
        type: "",
        date: "",
        expense_type: "",
        notes: "",
        amount: "",
        // from: "",
        paidto: "",
        paytype: "",
      });
    } catch (error) {
      console.error("Error adding data:", error.message);
      toast.error("Failed to add expense!"); // Optionally show error message
    }
  };

  useEffect(() => {
    if (addData.expense_type === "Office Expense") {
      fetchExpenseDetails(); // Fetch Office Expenses
    } else if (addData.expense_type === "Vendor Expense") {
      fetchVendorDetails(); // Fetch vendor details
    }
  }, [addData.expense_type]);

  useEffect(() => {
    if (updateData.expense_type === "Office Expense") {
      fetchExpenseDetails(); // Fetch Office Expenses
    } else if (updateData.expense_type === "Vendor Expense") {
      fetchVendorDetails(); // Fetch vendor details
    }
  }, [updateData.expense_type]); // Re-run whenever `expense_type` changes

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Expense List</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10">
          <div className="d-flex">
            <h6 className="mt-2 bold">Expense List</h6>
            <div className="">
              <button
                className="btn btn-dark btn-sm add mx-3"
                onClick={() => setShowModal(true)}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <button className="btn btn-secondary" onClick={handleExpenseType}>
            Expense Type
          </button>
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    <button
                      className="btn custom-btn-update btn-sm"
                      onClick={() =>
                        handleUpdate(row.original._id, row.original)
                      }
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button
                      className="btn custom-btn-delete btn-sm mx-1"
                      onClick={() =>
                        handleOpenModal(row.original._id, row.original.notes)
                      }
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                  
                    <button
                      className="btn custom-btn-info btn-sm"
                      onClick={() => handlePreview(row.original)}
                    >
                      <i class="fa-solid fa-eye"></i>
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Expense List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.branch_id}
                    onChange={(e) =>
                      setAddData({ ...addData, branch_id: e.target.value })
                    }
                    required
                  >
                    <option value="">Select Branch</option>
                    {branch.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.branch_name}
                      </option>
                    ))}
                  </select>
                  <label className={addData.branch_id ? "filled" : ""}>
                    Select Branch
                  </label>
                </div>

                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-control"
                    value={addData.expense_type}
                    onChange={(e) =>
                      setAddData({ ...addData, expense_type: e.target.value })
                    }
                  >
                    <option value="">Expense type</option>
                    <option value="Office Expense">Office Expense</option>
                    <option value="Vendor Expense">Vendor Expense</option>
                  </select>
                  <label className={addData.expense_type ? "filled" : ""}>
                    Expense type
                  </label>
                </div>

                {addData.expense_type === "Office Expense" && (
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={addData.type}
                      onChange={(e) =>
                        setAddData({ ...addData, type: e.target.value })
                      }
                    >
                      <option value="">Select Type</option>
                      {responseDatas.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.type}
                        </option>
                      ))}
                    </select>
                    <label className={addData.type ? "filled" : ""}>
                      Select Type
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.paidto}
                    onChange={(e) =>
                      setAddData({ ...addData, paidto: e.target.value })
                    }
                    required
                  >
                    <option value="" disabled>
                      {" "}
                      Select{" "}
                      {addData.expense_type === "Office Expense"
                        ? "Office"
                        : "Vendor"}
                    </option>

                    {addData.expense_type === "Office Expense" &&
                      officeexpense.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}

                    {addData.expense_type === "Vendor Expense" &&
                      vendor.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  <label className={addData.paidto ? "filled" : ""}>
                    Select paid To
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    value={addData.date}
                    // onChange={handleDateChange}
                    onChange={(e) =>
                      setAddData({ ...addData, date: e.target.value })
                    }
                    required
                  />
                  <label className={addData.date ? "filled" : ""}>Date</label>
                  {/* {errors.date && (
                    <div className="error-text">{errors.date}</div>
                  )} */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    value={addData.amount}
                    onChange={(e) =>
                      setAddData({ ...addData, amount: e.target.value })
                    }
                    required
                  />
                  <label className={addData.amount ? "filled" : ""}>
                    Amount
                  </label>
                  {/* {errors.amount && (
                    <div className="error-text">{errors.amount}</div>
                  )} */}
                </div>

                {/* Paid To Dropdown */}

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.paytype}
                    onChange={(e) =>
                      setAddData({ ...addData, paytype: e.target.value })
                    }
                  >
                    <option value="">Select Paytype </option>
                    {paymenttype.map((item, index) => (
                      <option key={index} value={item.Payment_type}>
                        {item.Payment_type}
                      </option>
                    ))}
                  </select>
                  <label className={addData.paytype ? "filled" : ""}></label>
                  {/* {errors.paytype && (
                    <div className="error-text">{errors.paytype}</div>
                  )} */}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    value={addData.notes}
                    onChange={(e) =>
                      setAddData({ ...addData, notes: e.target.value })
                    }
                    required
                  />
                  <label className={addData.notes ? "filled" : ""}>Notes</label>
                  {/* {errors.notes && (
                    <div className="error-text">{errors.notes}</div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handlesubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isUpdateshowModal}
        onHide={() => setUpdateShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Expense list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                {/* Other input fields */}

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.branch_id}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        branch_id: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Branch</option>
                    {branch.length > 0 ? (
                      branch.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.branch_name}
                        </option>
                      ))
                    ) : (
                      <option value="">Loading branches...</option> // Show loading option if data is not available
                    )}
                  </select>
                </div>
                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-control"
                    value={updateData.expense_type}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        expense_type: e.target.value,
                      })
                    }
                  >
                    <option value="">Expense type</option>
                    <option value="Office Expense">Office Expense</option>
                    <option value="Vendor Expense">Vendor Expense</option>
                  </select>
                  <label className={updateData.expense_type ? "filled" : ""}>
                    Expense type
                  </label>
                </div>

                {updateData.expense_type === "Office Expense" && (
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={updateData.type}
                      placeholder=""
                      onChange={(e) =>
                        setUpdateData({ ...updateData, type: e.target.value })
                      }
                    >
                      {responseDatas.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.type}
                        </option>
                      ))}
                    </select>
                    <label className={updateData.type ? "filled" : ""}>
                      Type
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.paidto}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paidto: e.target.value })
                    }
                    required
                  >
                    {/* <option value="">Select Name</option> */}
                    <option value="">
                      Select{" "}
                      {updateData.expense_type === "Office Expense"
                        ? "Office"
                        : "Vendor"}
                    </option>
                    {/* Render options based on selected expense_type */}
                    {updateData.expense_type === "Office Expense" &&
                      officeexpense.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    {updateData.expense_type === "Vendor Expense" &&
                      vendor.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  <label className={updateData.paidto ? "filled" : ""}>
                    {/* Optionally you can add custom label text */}
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={updateData.date}
                    // onChange={handleDateChange}
                    required
                  />
                  <label className={updateData.date ? "filled" : ""}>
                    Date
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, amount: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-control"
                    value={updateData.paytype}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paytype: e.target.value })
                    }
                  >
                    <option value="">Select Paytype </option>
                    {paymenttype.map((item, index) => (
                      <option key={index} value={item.Payment_type}>
                        {item.Payment_type}
                      </option>
                    ))}
                  </select>
                  <label className={updateData.paytype ? "filled" : ""}></label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.notes}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, notes: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.notes ? "filled" : ""}>
                    Notes
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* -----------------preview */}

      <Modal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview Expense list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                {/* Other input fields */}

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.branch_id}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        branch_id: e.target.value,
                      })
                    }
                    disabled
                  >
                    <option value="">Select Branch</option>
                    {branch.length > 0 ? (
                      branch.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.branch_name}
                        </option>
                      ))
                    ) : (
                      <option value="">Loading branches...</option> // Show loading option if data is not available
                    )}
                  </select>
                </div>
                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-control"
                    value={updateData.expense_type}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        expense_type: e.target.value,
                      })
                    }
                    disabled
                  >
                    <option value="">Expense type</option>
                    <option value="Office Expense">Office Expense</option>
                    <option value="Vendor Expense">Vendor Expense</option>
                  </select>
                  <label className={updateData.expense_type ? "filled" : ""}>
                    Expense type
                  </label>
                </div>

                {updateData.expense_type === "Office Expense" && (
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={updateData.type}
                      placeholder=""
                      onChange={(e) =>
                        setUpdateData({ ...updateData, type: e.target.value })
                      }
                      disabled
                    >
                      {responseDatas.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.type}
                        </option>
                      ))}
                    </select>
                    <label className={updateData.type ? "filled" : ""}>
                      Type
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.paidto}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paidto: e.target.value })
                    }
                    disabled
                  >
                    {/* <option value="">Select Name</option> */}
                    <option value="">
                      Select{" "}
                      {updateData.expense_type === "Office Expense"
                        ? "Office"
                        : "Vendor"}
                    </option>
                    {/* Render options based on selected expense_type */}
                    {updateData.expense_type === "Office Expense" &&
                      officeexpense.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    {updateData.expense_type === "Vendor Expense" &&
                      vendor.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  <label className={updateData.paidto ? "filled" : ""}>
                    {/* Optionally you can add custom label text */}
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={updateData.date}
                    // onChange={handleDateChange}
                    disabled
                  />
                  <label className={updateData.date ? "filled" : ""}>
                    Date
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, amount: e.target.value })
                    }
                    disabled
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-control"
                    value={updateData.paytype}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paytype: e.target.value })
                    }
                    disabled
                  >
                    <option value="">Select Paytype </option>
                    {paymenttype.map((item, index) => (
                      <option key={index} value={item.Payment_type}>
                        {item.Payment_type}
                      </option>
                    ))}
                  </select>
                  <label className={updateData.paytype ? "filled" : ""}></label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.notes}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, notes: e.target.value })
                    }
                    disabled
                  />
                  <label className={updateData.notes ? "filled" : ""}>
                    Notes
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowPreview(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Name :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* _------------------------------pagination button -------------------------------- */}

      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------end ---------------------------------- */}
    </>
  );
}

export default ExpenseList;
