// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import ApiUrl from "./../Environment/ApiUrl";
// import { toast } from "react-toastify";

// function AddStaff() {
//   const [activeStep, setActiveStep] = useState(0);



//   const [addData, setAddData] = useState({


//     // branch_id: "",
//     // empid: "",
//     // name: "",
//     // fathername: "",
//     // permanent_email: "",
//     // official_email: "",
//     // personal_Mobileno: "",
//     // official_Mobileno: "",
//     // dateofbirth: "",
//     // interview_date: "",
//     // join_date: "",
//     // address: "",
//     // Permanent_Address: "",
//     // Current_Address: "",
//     // salary: "",
//     // designation: "",
//     // qualification: "",
//     // notes: "",
//     // timings: "",
//     // status: "",
//     // relive_date: "",
//     // documents: "",
//     // aadhar_number: "",
//     // photo_upload: "",
//     // deviceUserId: "",

//     branch_id: "",
//     empid: "",
//     name: "",
//     fathername: "",
//     permanent_email: "",
//     official_email: "",
//     official_Mobileno: "",
//     personal_Mobileno: "",
//     // address: "",
//     Permanent_Address: "",
//     Current_Address: "",
//     // password: "",
//     dateofbirth: "",
//     interview_date: "",
//     salary: "",
//     join_date: "",
//     // address: "",
//     department: "",
//     aadhar_number: "",
//     designation: "",
//     qualification: "",
//     gender: "",
//     marital_status: "",
//     notes: "",
//     timings: "",
//     status: "",
//     relive_date: "",
//     // documents: "",==============================
//     // photo_upload: "",===========================
//     deviceUserId: ""
//   });
//   const [imageFiles, setImageFiles] = useState(null);
//   const [imageFile, setImageFile] = useState(null);

//   const [files, setFiles] = useState([]);
//   const handleFileChange = (e) => {
//     setFiles(e.target.files);
//   };
//   console.log("llolllll", addData);

//   const handleSubmit = async () => {
//     try {
//       // Create FormData
//       const formData = new FormData();
//       formData.append("branch_id", addData.branch_id);
//       formData.append("empid", addData.empid);
//       formData.append("name", addData.name);
//       formData.append("permanent_email", addData.permanent_email);
//       formData.append("official_email", addData.official_email);
//       formData.append("fathername", addData.fathername);
//       formData.append("official_Mobileno", addData.official_Mobileno);
//       formData.append("personal_Mobileno", addData.personal_Mobileno);
//       formData.append("dateofbirth", addData.dateofbirth);
//       formData.append("interview_date", addData.interview_date);
//       formData.append("join_date", addData.join_date);
//       formData.append("salary", addData.salary);
//       formData.append("department", addData.department);
//       formData.append("gender", addData.gender);
//       formData.append("marital_status", addData.marital_status);

//       formData.append("designation", addData.designation);
//       formData.append("qualification", addData.qualification);
//       formData.append("notes", addData.notes);
//       formData.append("timings", addData.timings);
//       formData.append("status", addData.status);
//       formData.append("aadhar_number", addData.aadhar_number);
//       formData.append("relive_date", addData.relive_date);
//       formData.append("Permanent_Address", addData.Permanent_Address);
//       formData.append("Current_Address", addData.Current_Address);
//       formData.append("deviceUserId", addData.deviceUserId);


//       // Append files if any
//       if (files.length > 0) {
//         for (let i = 0; i < files.length; i++) {
//           formData.append("documents", files[i]);
//         }
//       }

//       // Send the form data to the API using axios
//       const response = await axios.post(ApiUrl + `/staff`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data", // Required for file uploads
//         },
//       });

//       // Handle the response status
//       if (response.status === 200) {
//         toast.success("Staff added successfully");
//         navigate("/Staff"); // Navigate to the staff page
//       }
//     } catch (error) {
//       toast.error("Staff addition failed");
//       console.error("Error adding data:", error.message);
//     }
//   };

//   const navigate = useNavigate(); // Navigation function

//   // Handle "Next" click
//   const handleNext = () => {
//     if (activeStep === 0) {
//       setActiveStep(1); // Go to Step 2
//     } else if (activeStep === 1) {
//       setActiveStep(2); // Go to Step 3
//     }
//   };

//   // Handle "Back" click
//   const handleBack = () => {
//     if (activeStep === 1) {
//       setActiveStep(0); // Go back to Step 1
//     } else if (activeStep === 2) {
//       setActiveStep(1); // Go back to Step 2
//     }
//   };

//   // Handle "Cancel" click
//   const handleCancel = () => {
//     navigate("/staff"); // Navigate to the staff page
//   };

//   useEffect(() => {
//     fetchDatas();
//     fetchDesign();
//     fetchD();
//   }, []);

//   //   const handleempid = (e) => {};
//   const [generateempidData, setgenerateempidData] = useState([]);
//   const fetchD = async () => {
//     try {
//       const response = await axios.get(ApiUrl + "/generate_empid");
//       console.log("Response from server generate:", response.data.empid);
//       setgenerateempidData(response.data.empid);
//       addData.empid = response.data.empid;
//       console.log("schudule", response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const [isCheckBox, setIsCheckBox] = useState(false);
//   const handleCheckboxChange = (e) => {
//     setIsCheckBox(e.target.checked); // Updates the state based on the checkbox status
//   };
//   const [responseDatas, setResponseDatas] = useState([]);
//   const [responseDesignation, setResponseDesignation] = useState([]);

//   const fetchDatas = async () => {
//     try {
//       const response = await axios.get(ApiUrl + "/Branch");
//       console.log("Response from server:", response.data);
//       setResponseDatas(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const fetchDesign = async () => {
//     try {
//       const response = await axios.get(ApiUrl + "/designation");
//       console.log("Response from server:", response.data);
//       setResponseDesignation(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   function handleIdProof(e) {
//     const file = e.target.files[0];

//     if (file) {
//       const fileSize = file.size / 1024; // File size in KB
//       // const validFormats = ["image/jpeg", "image/png"];
//       const validFormats = ["image/jpeg", "image/png", "application/pdf"];

//       setAddData({ ...addData, documents: file });

//       if (!validFormats.includes(file.type)) {
//         // setError("Only JPG and PNG images are allowed.");
//         setImageFile(null); // Clear previously set image if invalid file type
//         return;
//       }

//       // // Check if file size exceeds 150KB
//       // if (fileSize > 150) {
//       //   // setError("File size should not exceed 150KB.");
//       //   setImageFile(null);  // Clear previously set image if file size exceeds limit
//       //   return;
//       // }

//       // setError("");
//       const reader = new FileReader();
//       reader.onload = () => {
//         setImageFile(reader.result); // Display the uploaded image as base64
//       };
//       reader.readAsDataURL(file);
//     }
//   }

//   function handleIduploadProof(e) {
//     const file = e.target.files[0];

//     if (file) {
//       const fileSize = file.size / 1024; // File size in KB
//       // const validFormats = ["image/jpeg", "image/png"];
//       const validFormats = ["image/jpeg", "image/png", "application/pdf"];

//       setAddData({ ...addData, documents: file });

//       if (!validFormats.includes(file.type)) {
//         // setError("Only JPG and PNG images are allowed.");
//         setImageFiles(null); // Clear previously set image if invalid file type
//         return;
//       }

//       // // Check if file size exceeds 150KB
//       // if (fileSize > 150) {
//       //   // setError("File size should not exceed 150KB.");
//       //   setImageFiles(null);  // Clear previously set image if file size exceeds limit
//       //   return;
//       // }

//       // setError("");
//       const reader = new FileReader();
//       reader.onload = () => {
//         setImageFiles(reader.result); // Display the uploaded image as base64
//       };
//       reader.readAsDataURL(file);
//     }
//   }

//   return (
//     <>
//       <div className="" style={{ marginTop: "-80px" }}>
//         <div className="row mb-3">
//           <div className="col-lg-11">
//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Dashboard / </b>
//             </Link>
//             <Link to="/staff" className="text-secondary dashBoard_text">
//               <b>Staff / </b>
//             </Link>
//             <Link to="/addstaff" className="text-danger dashBoard_text">
//               <b>Add Staff</b>
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className="container mt-3">
//         <h3>Add Staff</h3>
//         <div className="row mt-3">
//           {activeStep === 0 && (
//             <div className="col-lg-12">
//               <div className="card p-2 pb-0">
//                 <h4 className="mt-1 mb-3">Step 1</h4>
//                 <div className="form-group">
//                   <div className="row mb-5">
//                     <div className="col-lg-6">
//                       <div className="form-group ">
//                         <div className="floating-label">
//                           <select
//                             className="form-select"
//                             value={addData.branch_id}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 branch_id: e.target.value,
//                               })
//                             }
//                             required
//                           >
//                             <option value=""> Branch </option>
//                             {responseDatas.map((item, index) => (
//                               <option key={index} value={item._id}>
//                                 {item.branch_id} - {item.branch_name}
//                               </option>
//                             ))}
//                           </select>
//                           <label className={addData.branch_id ? "filled" : ""}>
//                             Branch
//                           </label>
//                         </div>

//                         {generateempidData && (
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={addData.empid}
//                               // value= {generateempidData}
//                               onChange={(e) => {
//                                 // handleempid(e); // Call your handleempid function
//                                 setAddData({
//                                   ...addData,
//                                   empid: e.target.value, // Update the empid field in the state
//                                 });
//                               }}
//                               required
//                               disabled
//                             />

//                             <label className={addData.empid ? "filled" : ""}>
//                               Staff Id
//                             </label>
//                           </div>
//                         )}

//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData.name}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 name: e.target.value,
//                               })
//                             }
//                             required
//                           />
//                           <label className={addData.name ? "filled" : ""}>
//                             Name
//                           </label>
//                         </div>
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData.fathername}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 fathername: e.target.value,
//                               })
//                             }
//                             required
//                           />
//                           <label className={addData.fathername ? "filled" : ""}>
//                             Father Name
//                           </label>
//                         </div>


//                         <div className="floating-label">
//                           <select
//                             className="form-select"
//                             value={addData.department} // Ensure that the correct value is selected
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 department: e.target.value, // Store the selected designation ID
//                               })
//                             }
//                             required
//                           >
//                             <option value="">Select department</option>
//                             {responseDesignation.map((item, index) => (
//                               <option key={index} value={item._id}>

//                                 {item.department}
//                               </option>
//                             ))}
//                           </select>
//                           <label
//                             className={addData.department ? "filled" : ""}
//                           >
//                             Select department
//                           </label>
//                         </div>
//                         {/* <div className="floating-label">
//                           <select
//                             className="form-select"
//                             value={addData.designation}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 designation: e.target.value,
//                               })
//                             }
//                             required
//                           >
//                             <option value=""> Branch </option>
//                             {responseDesignation.map((item, index) => (
//                               <option key={index} value={item._id}>
//                                 {item.designation}
//                               </option>
//                             ))}
//                           </select>
//                           <label
//                             className={addData.designation ? "filled" : ""}
//                           >
//                             Select Designation
//                           </label>
//                         </div> */}
//                         <div className="floating-label">
//                           <select
//                             className="form-select"
//                             value={addData.designation} // Ensure that the correct value is selected
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 designation: e.target.value, // Store the selected designation ID
//                               })
//                             }
//                             required
//                           >
//                             <option value="">Select Designation</option>
//                             {responseDesignation.map((item, index) => (
//                               <option key={index} value={item._id}>

//                                 {item.designation}
//                               </option>
//                             ))}
//                           </select>
//                           <label
//                             className={addData.designation ? "filled" : ""}
//                           >
//                             Select Designation
//                           </label>
//                         </div>

//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData.aadhar_number}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 aadhar_number: e.target.value,
//                               })
//                             }
//                             required
//                           />
//                           <label
//                             className={addData.aadhar_number ? "filled" : ""}
//                           >
//                             Aadhaar Number
//                           </label>
//                         </div>
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData.qualification}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 qualification: e.target.value,
//                               })
//                             }
//                             required
//                           />
//                           <label
//                             className={addData.qualification ? "filled" : ""}
//                           >
//                             Qualification
//                           </label>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="col-lg-6">
//                       <div className="form-group ">
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData.official_Mobileno}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 official_Mobileno: e.target.value,
//                               })
//                             }
//                             required
//                           />
//                           <label className={addData.official_Mobileno ? "filled" : ""}>
//                             Official Mobile
//                           </label>
//                         </div>
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData.personal_Mobileno}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 personal_Mobileno: e.target.value,
//                               })
//                             }
//                             required
//                           />
//                           <label
//                             className={
//                               addData.personal_Mobileno ? "filled" : ""
//                             }
//                           >
//                             Personal Mobile
//                           </label>
//                         </div>
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData.official_email}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 official_email: e.target.value,
//                               })
//                             }
//                             required
//                           />
//                           <label
//                             className={addData.official_email ? "filled" : ""}
//                           >
//                             Official Email
//                           </label>
//                         </div>
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData.permanent_email}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 permanent_email: e.target.value,
//                               })
//                             }
//                             required
//                           />
//                           <label className={addData.permanent_email ? "filled" : ""}>
//                             Personal Email
//                           </label>
//                         </div>
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="date"
//                             placeholder=""
//                             value={addData.dateofbirth}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 dateofbirth: e.target.value,
//                               })
//                             }
//                             required
//                           />
//                           <label
//                             className={addData.dateofbirth ? "filled" : ""}
//                           >
//                             Date Of Birth
//                           </label>
//                         </div>
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData.Permanent_Address}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 Permanent_Address: e.target.value,
//                               })
//                             }
//                             required
//                           />
//                           <label
//                             className={
//                               addData.Permanent_Address ? "filled" : ""
//                             }
//                           >
//                             Permanent Address
//                           </label>
//                         </div>
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData.Current_Address}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 Current_Address: e.target.value,
//                               })
//                             }
//                             required
//                           />
//                           <label className={addData.Current_Address ? "filled" : ""}>
//                             Current Address
//                           </label>
//                         </div>
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData.deviceUserId}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 deviceUserId: e.target.value,
//                               })
//                             }
//                             required
//                           />
//                           <label
//                             className={addData.deviceUserId ? "filled" : ""}
//                           >
//                             Device User ID
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="d-flex mt-3" style={{ marginLeft: "40%" }}>
//                 <Button
//                   onClick={handleCancel}
//                   className="mx-2"
//                   style={{ width: "100px", height: "40px" }}
//                 >
//                   Cancel
//                 </Button>
//                 <Button
//                   onClick={handleNext}
//                   className="btn-secondary"
//                   style={{ width: "100px", height: "40px" }}
//                 >
//                   Next
//                 </Button>
//               </div>
//             </div>
//           )}

//           {activeStep === 1 && (
//             <div className="col-lg-12">
//               <div className="card p-2 pb-4">
//                 <h4>Step 2</h4>
//                 <div className="form-group">
//                   <div className="row">
//                     <div className="col-lg-6">
//                       {/* <div className="floating-label">
//                         <input
//                           className="form-control"
//                           type="text"
//                           placeholder=""
//                           value={addData.password}
//                           onChange={(e) =>
//                             setAddData({
//                               ...addData,
//                               password: e.target.value,
//                             })
//                           }
//                           required
//                         />
//                         <label className={addData.password ? "filled" : ""}>
//                           Password
//                         </label>
//                       </div> */}
//                       <div className="floating-label">
//                         <input
//                           className="form-control"
//                           type="date"
//                           placeholder=""
//                           value={addData.interview_date}
//                           onChange={(e) =>
//                             setAddData({
//                               ...addData,
//                               interview_date: e.target.value,
//                             })
//                           }
//                           required
//                         />
//                         <label
//                           className={addData.interview_date ? "filled" : ""}
//                         >
//                           Interview Date
//                         </label>
//                       </div>

//                       <div className="floating-label">
//                         <input
//                           className="form-control"
//                           type="date"
//                           placeholder=""
//                           value={addData.join_date}
//                           onChange={(e) =>
//                             setAddData({
//                               ...addData,
//                               join_date: e.target.value,
//                             })
//                           }
//                           required
//                         />
//                         <label className={addData.join_date ? "filled" : ""}>
//                           Joining Date
//                         </label>
//                       </div>
//                       <div className="floating-label">
//                         <select
//                           className="form-select"
//                           value={addData.marital_status}
//                           onChange={(e) =>
//                             setAddData({
//                               ...addData,
//                               marital_status: e.target.value,
//                             })
//                           }
//                           required
//                         >
//                           <option value="">Select Martial</option>
//                           <option value="Male">Married</option>
//                           <option value="Female">UnMarried</option>
//                         </select>
//                         <label
//                           className={addData.marital_status ? "filled" : ""}
//                         >
//                           Martial Status
//                         </label>
//                       </div>
//                       <div className="floating-label">
//                         <select
//                           className="form-select"
//                           value={addData.gender}
//                           onChange={(e) =>
//                             setAddData({
//                               ...addData,
//                               gender: e.target.value,
//                             })
//                           }
//                           required
//                         >
//                           <option value="">Select Gender</option>
//                           <option value="Male">Male</option>
//                           <option value="Female">Female</option>
//                           <option value="Transgender">Transgender</option>
//                         </select>
//                         <label className={addData.gender ? "filled" : ""}>
//                           Select Gender
//                         </label>
//                       </div>

//                       <div className="floating-label">
//                         <input
//                           className="form-control"
//                           type="file"
//                           onChange={handleIduploadProof} // Store the file object
//                           required
//                           accept="image/jpeg, image/png" // Restrict file type in the file picker
//                         />
//                         <label className={imageFiles ? "filled" : ""}>
//                           Photo Upload
//                         </label>

//                         {/* {error && <p className="text-danger">{error}</p>} Display error message */}

//                         {imageFiles && (
//                           <div>
//                             <p>Your Image</p>
//                             <img
//                               src={imageFiles} // Use imageFile, not image
//                               alt="Uploaded or Captured"
//                               width="200"
//                               height="200"
//                               className="object-fit-cover"
//                             />
//                           </div>
//                         )}

//                         <p className="text-muted">
//                           Accepted formats: JPG, PNG. Max file size: 150KB.
//                         </p>
//                       </div>
//                     </div>

//                     <div className="col-lg-6">
//                       <div className="floating-label">
//                         <input
//                           className="form-control"
//                           type="tel"
//                           placeholder=""
//                           value={addData.salary}
//                           onChange={(e) =>
//                             setAddData({
//                               ...addData,
//                               salary: e.target.value,
//                             })
//                           }
//                           required
//                         />
//                         <label className={addData.salary ? "filled" : ""}>
//                           Salary
//                         </label>
//                       </div>
//                       <div className="floating-label">
//                         <input
//                           className="form-control"
//                           type="text"
//                           placeholder=""
//                           value={addData.timings}
//                           onChange={(e) =>
//                             setAddData({
//                               ...addData,
//                               timings: e.target.value,
//                             })
//                           }
//                           required
//                         />
//                         <label className={addData.timings ? "filled" : ""}>
//                           Timings
//                         </label>
//                       </div>
//                       <div className="floating-label">
//                         <input
//                           className="form-control"
//                           type="text"
//                           placeholder=""
//                           value={addData.notes}
//                           onChange={(e) =>
//                             setAddData({
//                               ...addData,
//                               notes: e.target.value,
//                             })
//                           }
//                           required
//                         />
//                         <label className={addData.notes ? "filled" : ""}>
//                           Notes
//                         </label>
//                       </div>
//                       <div className="floating-label">
//                         <select
//                           className="form-select"
//                           value={addData.status}
//                           onChange={(e) =>
//                             setAddData({
//                               ...addData,
//                               status: e.target.value,
//                             })
//                           }
//                           required
//                         >
//                           <option value=""> Select Status</option>
//                           <option value="Active">Active</option>
//                           <option value="InActive">InActive</option>
//                           <option value="Relieve">Relieve</option>
//                         </select>
//                         <label className={addData.status ? "filled" : ""}>
//                           Select Status
//                         </label>
//                       </div>
//                       <div className="floating-label">
//                         <input
//                           className="form-control"
//                           type="file"
//                           onChange={handleIdProof} // Store the file object
//                           required
//                           accept="image/jpeg, image/png" // Restrict file selection to jpg and png
//                         />
//                         <label className={imageFile ? "filled" : ""}>
//                           Id Proof (Upload)
//                         </label>

//                         {/* {error && <p className="text-danger">{error}</p>} Display error message */}

//                         {imageFile && (
//                           <div>
//                             <p>Your Image</p>
//                             <img
//                               src={imageFile} // Display the image preview
//                               alt="Uploaded or Captured"
//                               width="200"
//                               height="200"
//                               className="object-fit-cover"
//                             />
//                           </div>
//                         )}

//                         <p className="text-muted">
//                           Accepted formats: JPG, PNG. Max file size: 150KB.
//                         </p>
//                       </div>
//                     </div>

//                     {/* <Button onClick={handleBack}>Back</Button>
//                     <Button onClick={handleNext}>Overall</Button> */}
//                   </div>
//                 </div>
//               </div>
//               <div className="d-flex mt-3" style={{ marginLeft: "40%" }}>
//                 <Button
//                   onClick={handleBack}
//                   className="mx-2"
//                   style={{ width: "100px", height: "40px" }}
//                 >
//                   Back
//                 </Button>
//                 <Button
//                   onClick={handleNext}
//                   className="btn-secondary"
//                   style={{ width: "100px", height: "40px" }}
//                 >
//                   Overall
//                 </Button>
//               </div>
//             </div>
//           )}

//           {activeStep === 2 && (
//             <div className="col-lg-12">
//               <div className="container">
//                 <div className="row" style={{ marginTop: "" }}>
//                   <div className="col-lg-3">
//                     <div className="card carddetails p-2">
//                       <div>
//                         <div className="imgdetails">
//                           {imageFiles && (
//                             <div>
//                               <p>Your Image</p>
//                               <img
//                                 src={imageFiles} // Use imageFile, not image
//                                 alt="Uploaded or Captured"
//                                 width="200"
//                                 height="200"
//                                 className="object-fit-cover"
//                               />
//                             </div>
//                           )}
//                         </div>
//                       </div>
//                       <div className="text-center text-white">
//                         <h6 className="profile-details bg-secondary">
//                           <div className="row text-center">
//                             <div className="col-lg-1">
//                               <i class="fa-solid fa-user "></i>
//                             </div>
//                             <div className="col-lg-10 text-center">
//                               <b>{addData.name || ""}</b>
//                             </div>
//                           </div>
//                         </h6>
//                         <h6 className="profile-details bg-secondary">
//                           <div className="row text-center">
//                             <div className="col-lg-1">
//                               <i class="fa-solid fa-phone"></i>
//                             </div>
//                             <div className="col-lg-10 text-center">
//                               <b>{addData.official_Mobileno}</b>
//                             </div>
//                           </div>
//                         </h6>
//                         <h6 className="profile-details bg-secondary">
//                           <div className="row text-center">
//                             <div className="col-lg-1">
//                               <i class="fa-solid fa-id-card"></i>
//                             </div>
//                             <div className="col-lg-10 text-center">
//                               <b>{addData.empid || ""}</b>
//                             </div>
//                           </div>
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-lg-5">
//                     <div className="card cardborder">
//                       <div className="lightblued p-2">
//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Branch </strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.branch_id || ""}
//                           </div>
//                         </div>

//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Father Name </strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.fathername || ""}
//                           </div>
//                         </div>
//                         <div className="row mt-1 p-1">
//                           <div className="col-lg-5">
//                             <strong>Qualification</strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.qualification || ""}
//                           </div>
//                         </div>

//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Email</strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.permanent_email}
//                           </div>
//                         </div>
//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Date of Birth</strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.dateofbirth || ""}
//                           </div>
//                         </div>
//                         <div className="row mt-1 p-1">
//                           <div className="col-lg-5">
//                             <strong>Address</strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.Permanent_Address}
//                           </div>
//                         </div>

//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Martial Status </strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.marital_status}
//                           </div>
//                         </div>

//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Timings</strong>
//                           </div>
//                           <div className="col-lg-7 text-secondary">
//                             {addData.timings}
//                           </div>
//                         </div>
//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Notes </strong>
//                           </div>
//                           <div className="col-lg-7 text-secondary">
//                             {addData.notes}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-lg-4">
//                     <div className="card carddetails p-1">
//                       <div className="row p-1">
//                         <div className="col-lg-12 text-danger">
//                           <b>Documents</b>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="card carddetails details-data_overall p-1">
//                       {imageFile && (
//                         <div>
//                           <p>Your Image</p>
//                           <img
//                             src={imageFile} // Use imageFile, not image
//                             alt="Uploaded or Captured"
//                             width="200"
//                             height="200"
//                             className="object-fit-cover"
//                           />
//                         </div>
//                       )}
//                     </div>
//                     <div className="col-lg-12 col-sm-12 col-md-12 mt-3">
//                       <div className="card cardborder ">
//                         {/* <b className="text-danger p-1">Topics Covered</b> */}
//                       </div>
//                       <div className="card cardborder p-1 Completed-profile ">
//                         <div className="row details-datas ">
//                           <div className="col-lg-6 mt-1">
//                             <b> Status</b>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.status}
//                           </div>
//                         </div>
//                         <div className="row mt-2 details-datas">
//                           <div className="col-lg-6 mt-1">
//                             <b>Interview Date</b>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.interview_date}
//                           </div>
//                         </div>
//                         <div className="row mt-2 details-datas">
//                           <div className="col-lg-6 mt-1">
//                             <b>Joining Date</b>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.join_date}
//                           </div>
//                         </div>

//                         <div className="row mt-2 details-datas">
//                           <div className="col-lg-6 mt-1">
//                             <b>Relieve Date</b>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.relive_date}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div>
//                   <input
//                     type="checkbox"
//                     id="agree"
//                     checked={isCheckBox}
//                     required
//                     onClick={handleCheckboxChange}
//                   />
//                   <label htmlFor="agree " className="mx-2">
//                     I Agree to all conditions
//                   </label>
//                 </div>

//                 <Button variant="secondary" onClick={handleCancel}>
//                   Cancel
//                 </Button>
//                 <Button
//                   onClick={handleSubmit}
//                   variant="success"
//                   disabled={!isCheckBox}
//                 //   onClick={handlesubmit}
//                 >
//                   Submit
//                 </Button>
//                 <Button onClick={handleBack}>Tick Tok</Button>
//               </div>
//             </div>
//           )}

//           {/* 
          
          
          

//            <div>
//                     <input type="checkbox" id="agree" required />
//                     <label htmlFor="agree">I Agree to all conditions</label>
//                   </div>

//                   <Button variant="secondary" onClick={handleCancel}>
//                     Cancel
//                   </Button>
//                   <Button onClick={handleSubmit} variant="success">
//                     Submit
//                   </Button>
//                   <Button onClick={handleBack}>Tick Tok</Button>
          
          
          
          
//           */}
//         </div>
//       </div>
//     </>
//   );
// }

// export default AddStaff;



import React, { useState, useEffect } from "react";
import axios from "axios";
import ApiUrl from "../Environment/ApiUrl";
import "../../assets/css/Addstudent.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import Branch from "./Branch";
import { Link } from "react-router-dom";
const steps = [
  "Step 1: Personal Details",
  "Step 2: Address Details",
  "Step 3: Confirmation",
];

function AddStaff() {
  const [responseDatas, setResponseDatas] = useState([]);
  const [responseSchuduleData, setResponseSchuduleData] = useState([]);
  const [generateempidData, setgenerateempidData] = useState([]);
  const [files, setFiles] = useState([]);
  const [branch, setbranch] = useState([]);

  const navigate = useNavigate();
  const handlecancel = () => {
    navigate("/staff");
  };

  const handleBackbutton = () => {
    // navigate("/staff");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  console.log("id is there" + id);

  useEffect(() => {
    fetchDatas();
  }, []);

  const [addData, setAddData] = useState({
    branch_id: "",
    empid: "",
    name: "",
    fathername: "",
    email: "",
    official_email: "",
    personal_Mobileno: "",
    mobile: "",
    dateofbirth: "",
    interview_date: "",
    join_date: "",
    address: "",
    Permanent_Address: "",
    salary: "",
    designation: "",
    qualification: "",
    notes: "",
    timings: "",
    status: "",
    relive_date: "",
    documents: "",
    aadhar_number: "",
    photo_upload: "",

    deviceUserId: "",
  });

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };
  console.log("llolllll", addData);
  const handlesubmit = async (e) => {
    e.preventDefault();

    // }

    const formData = new FormData();
    formData.append("branch_id", addData.branch_id);
    formData.append("empid", addData.empid);
    formData.append("name", addData.name);
    formData.append("email", addData.email);
    formData.append("official_email", addData.official_email);
    formData.append("fathername", addData.fathername);
    formData.append("mobile", addData.mobile);
    formData.append("personal_Mobileno", addData.personal_Mobileno);
    formData.append("dateofbirth", addData.dateofbirth);
    formData.append("interview_date", addData.interview_date);
    formData.append("join_date", addData.join_date);
    formData.append("marital_status", addData.marital_status);
    formData.append("gender", addData.gender);

    formData.append("salary", addData.salary);
    formData.append("department", addData.department);
    // formData.append("password", addData.password);

    formData.append("address", addData.address);

    formData.append("Permanent_Address", addData.Permanent_Address);
    formData.append("designation", addData.designation[0]._id);
    formData.append("qualification", addData.qualification);
    formData.append("notes", addData.notes);
    formData.append("timings", addData.timings);
    formData.append("status", addData.status);
    formData.append("aadhar_number", addData.aadhar_number);
    formData.append("relivedate", addData.relive_date);
    formData.append("documents", addData.documents);
    formData.append("photo_upload", addData.photo_upload);

    formData.append("deviceUserId", addData.deviceUserId);

    for (let i = 0; i < files.length; i++) {
      formData.append("documents", files[i]);
    }

    for (let i = 0; i < files.length; i++) {
      formData.append("photo_upload", files[i]);
    }
    console.log("the console the staff ", addData.empid);

    try {
      setLoading(true);
      console.log("Added:", formData.values);
      const response = await axios.post(ApiUrl + "/staff", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response) {
        if (response.status === 200) {
          toast.success("Staff added successfully");
          navigate("/Staff");
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      }
      // else{
      //   setTimeout(() => {
      //     setLoading(true);
      //   }, 1000);
      // }

  
      fetchDatas();
    }
    catch (error) {
      toast.error("Staff added failed");
      console.error("Error adding data:", error.message);
    }

    // Your API call or logic to add staff
    // } catch (error) {
    //   // Check if the error is related to the specific message and status code
    //   if (error.response && error.response.status === 400 && error.response.data.message === "staff with the same email and mobile number already exists.") {
    //     toast.error("Staff with the same email and mobile number already exists.");
    //   } else {
    //     toast.error("Staff added failed.");
    //   }
    //   console.error("Error adding data:", error.message);
    // }

  };

  useEffect(() => {
    fetchDatas();
    fetchDataed();
    // fetch();
  }, []);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(ApiUrl + "/designation");
      console.log("Response from server type:", response.data);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDataed = async () => {
    try {
      const response = await axios.get(ApiUrl + "/Branch");
      console.log("Response from server:", response.data);
      setbranch(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
    fetchD();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/schedule");
      console.log("Response from server type:", response.data);
      setResponseSchuduleData(response.data);
      console.log("schudule", response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchD = async () => {
    try {
      const response = await axios.get(ApiUrl + "/generate_empid");
      console.log("Response from server generate:", response.data.empid);
      setgenerateempidData(response.data.empid);
      addData.empid = response.data.empid;
      console.log("schudule", response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [showReliveDate, setShowReliveDate] = useState(false);

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setAddData({ ...addData, status: selectedStatus });

    if (selectedStatus === "Relive") {
      setShowReliveDate(true);
    } else {
      setShowReliveDate(false);
      setAddData((prevData) => ({
        ...prevData,
        relive_date: "",
      }));
    }
  };

  // const convertToNormalTime = (railwayTime) => {
  //   const [hours, minutes] = railwayTime.split(":");
  //   const hour = parseInt(hours, 10);
  //   const minute = parseInt(minutes, 10);
  //   const ampm = hour >= 12 ? "PM" : "AM";
  //   const hour12 = hour % 12 || 12;
  //   return ${hour12}:${minutes} ${ampm};
  // };

  const [activeStep, setActiveStep] = useState(0);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageFiles, setImageFiles] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (addData.length > 0) {
      const documents = addData[0]?.documents;

      setImageFile(
        // documents ? http://192.168.1.38:8000/api/uploads/${documents} : null
        documents ? `${ApiUrl}/api/uploads/${documents}` : null
      );
    }
    if (addData.length > 0) {
      const photo_upload = addData[0]?.photo_upload;
      // ${apiUrl}
      setImageFiles(
        // photo_upload ? http://192.168.1.38:8000/api/uploads/${photo_upload} : null
        photo_upload ? `${ApiUrl}/api/uploads/${photo_upload}` : null
      );
    }
  }, [addData]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    // navigate("/staff");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Helper function to format ISO date to YYYY-MM-DD
  const formatDatefitching = (dateString) => {
    if (!dateString) return "";
    return dateString.split("T")[0]; // This will return only the date part (yyyy-mm-dd)
  };

  // Function to handle course fetching

  const handleempid = (e) => { };

  function handleIdProof(e) {
    const file = e.target.files[0];

    if (file) {
      const fileSize = file.size / 1024; // File size in KB
      // const validFormats = ["image/jpeg", "image/png"];
      const validFormats = ["image/jpeg", "image/png", "application/pdf"];

      setAddData({ ...addData, documents: file });

      if (!validFormats.includes(file.type)) {
        setError("Only JPG and PNG images are allowed.");
        setImageFile(null); // Clear previously set image if invalid file type
        return;
      }

      // Check if file size exceeds 150KB
      if (fileSize > 150) {
        setError("File size should not exceed 150KB.");
        setImageFile(null); // Clear previously set image if file size exceeds limit
        return;
      }

      setError("");
      const reader = new FileReader();
      reader.onload = () => {
        setImageFile(reader.result); // Display the uploaded image as base64
      };
      reader.readAsDataURL(file);
    }
  }

  function handleIdphoto_upload(e) {
    const file = e.target.files[0];

    if (file) {
      const fileSize = file.size / 1024; // Size in KB
      const validFormats = ["image/jpeg", "image/png"];
      setAddData({ ...addData, photo_upload: file });

      if (!validFormats.includes(file.type)) {
        setError("Only JPG and PNG images are allowed.");
        setImageFiles(null);
        return;
      }

      // Check file size
      if (fileSize > 150) {
        setError("File size should not exceed 150KB.");
        setImageFiles(null);
        return;
      }

      setError("");
      const reader = new FileReader();
      reader.onload = () => {
        setImageFiles(reader.result); // Display the uploaded image as base64
      };
      reader.readAsDataURL(file);
    }
  }

  const getFilteredDesignations = () => {
    if (!addData.department) return [];
    return responseDatas.filter(
      (item) =>
        item.department ===
        responseDatas.find((d) => d._id === addData.department)?.department
    );
  };

  const handleDepartmentChange = (e) => {
    setAddData({
      ...addData,
      department: e.target.value,
      designation: "", // Reset designation when department changes
    });
  };

  const [isCheckBox, setIsCheckBox] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsCheckBox(e.target.checked); // Updates the state based on the checkbox status
  };
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading === true ? (
        <div className="loading-overlay">
          {/* <div className="loading-message">
            <img
              className="loadinggif"
              src={require("../../assets/img/output-onlinegiftools.gif")}
            />{" "}
          </div> */}
        </div>
      ) : (
        <div className="d-none"></div>
      )}
      <div className="style mb-5" style={{ marginTop: "-80px" }}>
        <div className="insermodal">


          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="/staff" className="text-secondary dashBoard_text">
                <b>Staff / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Add Staff</b>
              </Link>
            </div>
          </div>


          <div>
            <h3>Add Staff</h3>

            <div className="row mt-5 ">
              {activeStep === 0 && (
                <div className="col-lg-12 " style={{ marginTop: "-25px" }}>
                  <div className="card  p-2 pb-0">
                    <h4 className="mt-1 mb-3"> Step 1</h4>
                    <div className="form-group ">
                      <div className="row mb-5">
                        <div className="col-lg-6">
                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={addData.branch_id}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  branch_id: e.target.value,
                                })
                              }
                              required
                            >
                              <option value=""> Select Branch </option>
                              {branch.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.branch_name}
                                </option>
                              ))}
                            </select>
                            <label
                              className={addData.branch_id ? "filled" : ""}
                            >

                            </label>
                          </div>

                          {generateempidData && (
                            <div className="floating-label">
                              <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={addData.empid}
                                // value= {generateempidData}
                                onChange={(e) => {
                                  handleempid(e); // Call your handleempid function
                                  setAddData({
                                    ...addData,
                                    empid: e.target.value, // Update the empid field in the state
                                  });
                                }}
                                required
                                disabled
                              />

                              <label className={addData.empid ? "filled" : ""}>
                                Staff Id
                              </label>
                            </div>
                          )}

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.name}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  name: e.target.value,
                                })
                              }
                              required
                            />
                            <label className={addData.name ? "filled" : ""}>
                              Name
                            </label>
                          </div>

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.fathername}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  fathername: e.target.value,
                                })
                              }
                              required
                            />
                            <label
                              className={addData.fathername ? "filled" : ""}
                            >
                              Father Name
                            </label>
                          </div>

                          {/* <div className="floating-label">
                            <select
                              className="form-select"
                              value={addData.department}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  department: e.target.value,
                                })
                              }
                              required
                            >
                              <option value=""> Department</option>
                              {responseDatas.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.department}
                                </option>
                              ))}
                            </select>
                            <label
                              className={addData.department ? "filled" : ""}
                            >
                              Department12
                            </label>
                          </div>

                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={addData.designation}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  designation: e.target.value,
                                })
                              }
                            >
                              <option value="">Select designation</option>
                              {responseDatas.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.designation}
                                </option>
                              ))}
                            </select>
                            <label
                              className={addData.designation ? "filled" : ""}
                            >
                              Select Designation
                            </label>
                          </div> */}
                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={addData.department}
                              onChange={handleDepartmentChange}
                              required
                            >
                              <option value="">Select Department</option>
                              {[
                                ...new Map(
                                  responseDatas.map((item) => [
                                    item.department,
                                    item,
                                  ])
                                ).values(),
                              ].map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.department}
                                </option>
                              ))}
                            </select>
                            <label
                              className={addData.department ? "filled" : ""}
                            >
                              Select Department
                            </label>
                          </div>

                          {/* Designation Dropdown */}
                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={addData.designation}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  designation: e.target.value,
                                })
                              }
                              disabled={!addData.department} // Disable dropdown if no department is selected
                            >
                              <option value="">Select Designation</option>
                              {getFilteredDesignations().map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.designation}
                                </option>
                              ))}
                            </select>
                            <label
                              className={addData.designation ? "filled" : ""}
                            >
                              Select Designation
                            </label>
                          </div>
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.aadhar_number}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  aadhar_number: e.target.value,
                                })
                              }
                              required
                            />
                            <label
                              className={addData.aadhar_number ? "filled" : ""}
                            >
                              Aadhar Number
                            </label>
                          </div>

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.qualification}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  qualification: e.target.value,
                                })
                              }
                              required
                            />
                            <label
                              className={addData.qualification ? "filled" : ""}
                            >
                              Qualification
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.mobile}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  mobile: e.target.value,
                                })
                              }
                              required
                            />
                            <label className={addData.mobile ? "filled" : ""}>
                              Official Mobile No
                            </label>
                          </div>

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.personal_Mobileno}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  personal_Mobileno: e.target.value,
                                })
                              }
                              required
                            />
                            <label
                              className={
                                addData.personal_Mobileno ? "filled" : ""
                              }
                            >
                              Personal Mobile No
                            </label>
                          </div>

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.official_email}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  official_email: e.target.value,
                                })
                              }
                              required
                            />
                            <label
                              className={addData.official_email ? "filled" : ""}
                            >
                              Official Email
                            </label>
                          </div>

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.email}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  email: e.target.value,
                                })
                              }
                              required
                            />
                            <label className={addData.email ? "filled" : ""}>
                              Personal Email
                            </label>
                          </div>

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="date"
                              value={addData.dateofbirth}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  dateofbirth: e.target.value,
                                })
                              }
                              required
                            />
                            <label
                              className={addData.dateofbirth ? "filled" : ""}
                            >
                              Date of Birth
                            </label>
                          </div>

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.Permanent_Address}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  Permanent_Address: e.target.value,
                                })
                              }
                              required
                            />
                            <label
                              className={
                                addData.Permanent_Address ? "filled" : ""
                              }
                            >
                              Permanent Address
                            </label>
                          </div>

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.address}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  address: e.target.value,
                                })
                              }
                              required
                            />
                            <label className={addData.address ? "filled" : ""}>
                              Current Address
                            </label>
                          </div>

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.deviceUserId}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  deviceUserId: e.target.value,
                                })
                              }
                              required
                            />
                            <label
                              className={addData.deviceUserId ? "filled" : ""}
                            >
                              Device User Id
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ////////////////////////////////////////////////// */}
              {activeStep === 1 && (
                <div className="col-lg-12" style={{ marginTop: "-25px" }}>
                  <div className="card p-2 pb-4">
                    <div className="form-group">
                      <div className="row">
                        <h4 className="mt-2 mb-5"> Step 2</h4>

                        <div className="col-lg-6">
                          {/* <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.password}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  password: e.target.value,
                                })
                              }
                              required
                            />
                            <label className={addData.password ? "filled" : ""}>
                              Password
                            </label>
                          </div> */}

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="date"
                              value={addData.interview_date}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  interview_date: e.target.value,
                                })
                              }
                              required
                            />
                            <label
                              className={addData.interview_date ? "filled" : ""}
                            >
                              Interview Date
                            </label>
                          </div>

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="date"
                              value={addData.join_date}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  join_date: e.target.value,
                                })
                              }
                              required
                            />
                            <label
                              className={addData.join_date ? "filled" : ""}
                            >
                              Joining Date
                            </label>
                          </div>

                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={addData.marital_status}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  marital_status: e.target.value,
                                })
                              }
                              required
                            >
                              <option value=""> Marital Status</option>
                              <option value="Married">Married</option>
                              <option value="Unmarried">Unmarried</option>
                            </select>
                            <label
                              className={addData.marital_status ? "filled" : ""}
                            >
                              Marital Status
                            </label>
                          </div>

                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={addData.gender}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  gender: e.target.value,
                                })
                              }
                              required
                            >
                              <option value=""> Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                            <label className={addData.gender ? "filled" : ""}>
                              Gender
                            </label>
                          </div>

                          {/* <div className="floating-label">
                            <input
                              className="form-control"
                              type="file"
                              onChange={handleIdphoto_upload} // Store the file object
                              required
                            />
                            <label
                              className={addData.documents ? "filled" : ""}
                            >
                              Photo Upload
                            </label>
                            {imageFiles && (
                              <div>
                                <p>Your Image</p>
                                <img
                                  src={imageFiles} // Use imageFile, not image
                                  alt="Uploaded or Captured"
                                  width="200"
                                  height="200"
                                  className="object-fit-cover"
                                />
                              </div>
                            )}
                          </div> */}

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="file"
                              onChange={handleIdphoto_upload} // Store the file object
                              required
                              accept="image/jpeg, image/png" // Restrict file type in the file picker
                            />
                            <label className={imageFiles ? "filled" : ""}>
                              Photo Upload
                            </label>
                            {error && <p className="text-danger">{error}</p>}{" "}
                            {/* Display error message */}
                            {imageFiles && (
                              <div>
                                <p>Your Image</p>
                                <img
                                  src={imageFiles} // Use imageFile, not image
                                  alt="Uploaded or Captured"
                                  width="200"
                                  height="200"
                                  className="object-fit-cover"
                                />
                              </div>
                            )}
                            <p className="text-muted">
                              Accepted formats: JPG, PNG. Max file size: 150KB.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.salary}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  salary: e.target.value,
                                })
                              }
                              required
                            />
                            <label className={addData.salary ? "filled" : ""}>
                              Salary
                            </label>
                          </div>

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.timings}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  timings: e.target.value,
                                })
                              }
                              required
                            />
                            <label className={addData.timings ? "filled" : ""}>
                              Timing
                            </label>
                          </div>

                          <div className="floating-label">
                            <textarea
                              className="form-control"
                              value={addData.notes}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  notes: e.target.value,
                                })
                              }
                              required
                            />
                            <label className={addData.notes ? "filled" : ""}>
                              Notes
                            </label>
                          </div>
                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={addData.status}
                              onChange={handleStatusChange}
                              required
                            >
                              <option value=""> Status</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                              <option value="Relive">Relive</option>
                            </select>
                            <label className={addData.status ? "filled" : ""}>
                              Status
                            </label>
                          </div>
                          {/* 
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="file"
                              onChange={handleIdProof} // Store the file object
                              required
                            />
                            <label
                              className={addData.documents ? "filled" : ""}
                            >
                              Id proof(upload)
                            </label>
                            {imageFile && (
                              <div>
                                <p>Your Image</p>
                                <img
                                  src={imageFile} // Use imageFile, not image
                                  alt="Uploaded or Captured"
                                  width="200"
                                  height="200"
                                  className="object-fit-cover"
                                />
                              </div>
                            )}
                          </div> */}

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="file"
                              onChange={handleIdProof} // Store the file object
                              required
                              accept="image/jpeg, image/png" // Restrict file selection to jpg and png
                            />
                            <label className={imageFile ? "filled" : ""}>
                              Id Proof (Upload)
                            </label>
                            {error && <p className="text-danger">{error}</p>}{" "}
                            {/* Display error message */}
                            {imageFile && (
                              <div>
                                <p>Your Image</p>
                                <img
                                  src={imageFile} // Display the image preview
                                  alt="Uploaded or Captured"
                                  width="200"
                                  height="200"
                                  className="object-fit-cover"
                                />
                              </div>
                            )}
                            <p className="text-muted">
                              Accepted formats: JPG, PNG. Max file size: 150KB.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div style={{ marginTop: "-20px" }}>
          {activeStep === steps.length - 1 ? (
            // <Modal show={true}>
            <div className="container">
              <div className="row" style={{ marginTop: "" }}>
                <div className="col-lg-3">
                  <div className="card carddetails p-2">
                    <div>
                      <div className="imgdetails">
                        <div className="imgdetails">
                          <img
                            src={imageFiles}
                            alt=""
                            className="img-fluid"
                            style={{
                              height: "200px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-center text-white">
                      <h6 className="profile-details bg-secondary">
                        <div className="row text-center">
                          <div className="col-lg-1">
                            <i class="fa-solid fa-user "></i>
                          </div>
                          <div className="col-lg-10 text-center">
                            <b>{addData.name || ""}</b>
                          </div>
                        </div>
                      </h6>
                      <h6 className="profile-details bg-secondary">
                        <div className="row text-center">
                          <div className="col-lg-1">
                            <i class="fa-solid fa-phone"></i>
                          </div>
                          <div className="col-lg-10 text-center">
                            <b>{addData.mobile}</b>
                          </div>
                        </div>
                      </h6>
                      <h6 className="profile-details bg-secondary">
                        <div className="row text-center">
                          <div className="col-lg-1">
                            <i class="fa-solid fa-id-card"></i>
                          </div>
                          <div className="col-lg-10 text-center">
                            <b>{addData.empid || ""}</b>
                          </div>
                        </div>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="card cardborder">
                    <div className="lightblued p-2">
                      {/* <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Branch </strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData?.branch_id[0]?.branch_name || addData.branch_id}
                        
                        </div>
                      </div> */}

                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Father Name </strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.fathername || ""}
                        </div>
                      </div>
                      <div className="row mt-1 p-1">
                        <div className="col-lg-5">
                          <strong>Qualification</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.qualification || ""}
                        </div>
                      </div>

                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Email</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.email}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Date of Birth</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.dateofbirth || ""}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Aadhar number</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.aadhar_number || ""}
                        </div>
                      </div>

                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Official Email</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.official_email || ""}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Mobile Number</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.mobile || ""}
                        </div>
                      </div>

                      <div className="row mt-1 p-1">
                        <div className="col-lg-5">
                          <strong>Device UserId</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.deviceUserId}
                        </div>
                      </div>
                      <div className="row mt-1 p-1">
                        <div className="col-lg-5">
                          <strong>Device UserId</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.deviceUserId}
                        </div>
                      </div>
                      <div className="row mt-1 p-1">
                        <div className="col-lg-5">
                          <strong>Gender</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.gender}
                        </div>
                      </div>
                      <div className="row mt-1 p-1">
                        <div className="col-lg-5">
                          <strong>Salary</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.salary}
                        </div>
                      </div>

                      <div className="row mt-1 p-1">
                        <div className="col-lg-5">
                          <strong>Address</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.address}
                        </div>
                      </div>

                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Martial Status </strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.marital_status}
                        </div>
                      </div>

                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Timings</strong>
                        </div>
                        <div className="col-lg-7 text-secondary">
                          {addData.timings}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Notes </strong>
                        </div>
                        <div className="col-lg-7 text-secondary">
                          {addData.notes}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card carddetails p-1">
                    <div className="row p-1">
                      <div className="col-lg-12 text-danger">
                        <b>Documents</b>
                      </div>
                    </div>
                  </div>
                  <div className="card carddetails details-data_overall p-1">
                    <div>
                      <img
                        // src={`${apiUrl}/uploads/${addData[0]?.documents}` || ""}
                        src={imageFile}
                        alt=""
                        className="img-fluid"
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12 col-md-12 mt-3">
                    <div className="card cardborder ">
                      {/* <b className="text-danger p-1">Topics Covered</b> */}
                    </div>
                    <div className="card cardborder p-1 Completed-profile ">
                      <div className="row details-datas ">
                        <div className="col-lg-6 mt-1">
                          <b> Status</b>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.status}
                        </div>
                      </div>
                      <div className="row mt-2 details-datas">
                        <div className="col-lg-6 mt-1">
                          <b>Interview Date</b>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.interview_date}
                        </div>
                      </div>
                      <div className="row mt-2 details-datas">
                        <div className="col-lg-6 mt-1">
                          <b>Joining Date</b>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.join_date}
                        </div>
                      </div>

                      <div className="row mt-2 details-datas">
                        <div className="col-lg-6 mt-1">
                          <b>Relieve Date</b>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData.relive_date}
                        </div>
                      </div>
                    </div>

                    <div className="row p-3">
                      <div className="text-center ">
                        <input
                          type="checkbox"
                          id="agree"
                          checked={isCheckBox}
                          required
                          onClick={handleCheckboxChange}
                        />
                        <label htmlFor="agree " className="mx-2">
                          I Agree to all conditions
                        </label>
                      </div>

                      <div className="col-lg-6 text-center d-flex justify-content-end">
                        <button
                          className="Cancelbut "
                          variant="secondary"
                          onClick={handlecancel}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-lg-6 text-center d-flex justify-content-start">
                        <button
                          className="btn btn-success"
                          type="submit"
                          variant="primary"
                          disabled={!isCheckBox}
                          onClick={handlesubmit}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="text-center mt-3">
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "blue",
                            alignItems: "center",
                            marginRight: "10px",
                            color: "white",
                            width: "20%",
                          }} // Inline style for background color
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // </Modal>
            <>
              {/* <Button
                variant="contained"
                style={{
                  backgroundColor: "gray",
                  marginRight: "10px",
                  color: "white",
                  marginLeft: "450px",
                  marginTop: "30px",
                }} // Inline style for background color
                // disabled={activeStep === 0}
                onClick={handleBackbutton}
              >
                Back
              </Button> */}

              {activeStep === 0 ? (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "gray",
                    marginRight: "10px",
                    marginTop: "30px",
                    color: "white",
                  }}
                  onClick={() => navigate("/staff")} // Always navigates, no need for handleBack()
                >
                  Back
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "gray",
                    marginRight: "10px",
                    color: "white",
                    marginTop: "30px",
                  }}
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}

              <Button
                variant="contained"
                className="btn btn-danger"
                color="primary"
                onClick={handleNext}
                style={{
                  marginTop: "30px",
                }} // Inline style for background color
              >
                Next
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AddStaff;
