import ApiUrl from "../Environment/ApiUrl";
import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function Travel_Allowance() {
  const [responseData, setResponseData] = useState([]);
  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      name: item.name,
      date: item.date,
      allowance_type: item.allowance_type,
      transport_mode: item.transport_mode,
      from: item.from,
      to: item.to,
      distance: item.distance,
      ticket_bill: item.ticket_bill,
      amount: item.amount,
      status: item.status,
      food_location: item.food_location,
      food_bill: item.food_bill,
      food_amount: item.food_amount,
      stay_location: item.stay_location,
      stay_bill: item.stay_bill,
      stay_amount: item.stay_amount,
      remarks: item.remarks,
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Date",
        accessor: "date",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        Cell: ({ value }) => {
          const date = new Date(value);  // Convert the ISO string to a Date object
          const day = String(date.getDate()).padStart(2, '0');  // Add leading zero if necessary
          const month = String(date.getMonth() + 1).padStart(2, '0');  // Get the month (zero-indexed, so add 1)
          const year = date.getFullYear();

          return `${day}-${month}-${year}`;  // Return the formatted date
        }
      },

      {
        Header: "Type",
        accessor: "allowance_type",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      // {
      //   Header: "Travel Mode",
      //   accessor: "transport_mode",
      //   Filter: DefaultColumnFilter,
      //   filter: "multiSelectFilter",
      // },
      {
        Header: "Amount",
        accessor: "amount",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   Filter: DefaultColumnFilter,
      //   filter: "multiSelectFilter",
      // },
      {
        Header: "Status",
        accessor: "status",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        Cell: ({ value }) => (
          <p className={`text-white text-center ${getStatusLabelClass(value)}`}>
            {value}
          </p>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [showModal, setShowModal] = useState(false);
  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});
  const [addData, setAddData] = useState({
    idlocal: "",
    from: "",
    to: "",
    travel_distance: "",
    visitor_staff: "",
    date: "",
    allowance_type: "",
    transport_mode: "",
    distance: "",
    ticket_bill: "",
    amount: "",
    status: "",
    food_location: "",
    food_bill: "",
    stay_location: "",
    stay_bill: "",
    remarks: "",
  });

  const [updateData, setUpdateData] = useState({
    idlocal: "",
    from: "",
    to: "",
    travel_distance: "",
    visitor_staff: "",
    date: "",
    allowance_type: "",
    transport_mode: "",
    distance: "",
    ticket_bill: "",
    amount: "",
    status: "",
    food_location: "",
    food_bill: "",
    stay_location: "",
    stay_bill: "",
    remarks: "",
  });

  useEffect(() => {
    fetchData();

    gettoken();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      // Retrieve branch details and employee name from localStorage
      const branchId = localStorage.getItem("branch_id");
      const branchName = localStorage.getItem("branch_name");
      const empName = localStorage.getItem("username");

      // Check if the condition to fetch all data is met
      if (
        branchId === null ||
        branchName === "Branches" ||
        empName === undefined
      ) {
        // Fetch all attendance data if condition is true
        const response = await axios.get(ApiUrl + "/travel_allowance");
        console.log(
          "Response from server with all attendance data:",
          response.data
        );
        setResponseData(response.data.reverse());
      } else {
        // Fetch data based on branchId and empid (from localStorage)
        const response = await axios.get(ApiUrl + "/travel_allowance", {
          params: { data: branchId },
        });
        console.log(
          "Response from server with filtered attendance data:",
          response.data
        );

        // Get the empid from localStorage
        const localEmpid = localStorage.getItem("username");

        // Filter data based on the empid from localStorage
        const filteredData = response.data.filter(
          (item) => item.name === localEmpid
        );

        setResponseData(filteredData.reverse());
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const gettoken = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(ApiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);
      setroleid(response.data.detail.rolename);
      localStorage.setItem("role_id", response.data.detail.rolename);
      const id = response.data.detail.rolename;

      if (id) {
        try {
          const response = await axios.get(ApiUrl + `/role_2/${id}`);
          const modules = response.data.modules;
          const filtered_access = modules.filter(
            (item) => item.name == ""
          );
          setprevillages(filtered_access[0].actions);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };


  const handlesubmit = async (e) => {
    e.preventDefault();
    
    // Simple validation
    if (!addData.date || !addData.amount) {
      toast.error("Please fill in all Date And Amount required fields. ");
      return;
    }
  
    try {
      const formData = {
        name: idlocal,
        date: addData.date,
        allowance_type: allowance_type,
        transport_mode: addData.transport_mode,
        from: addData.from,
        to: addData.to,
        distance: addData.distance,
        ticket_bill: addData.ticket_bill,
        amount: addData.amount,
        status: "Applied",
        food_location: addData.food_location,
        food_bill: addData.food_bill,
        stay_location: addData.stay_location,
        stay_bill: addData.stay_bill,
        remarks: addData.remarks,
      };
  
      setAllowance_Type("");
      console.log("The form data:", formData);
  
      const response = await axios.post(ApiUrl + "/travel_allowance", formData);
      console.log("Updated:", response);
  
      // Reset the form
      setShowModal(false);
      toast.success("Added successfully!");
      setAddData({
        name: "",
        date: "",
        allowance_type: "",
        transport_mode: "",
        from: "",
        to: "",
        distance: "",
        ticket_bill: "",
        amount: "",
        status: "",
        food_location: "",
        food_bill: "",
        stay_location: "",
        stay_bill: "",
        remarks: "",
      });
      // setIdlocal(""); // Reset idlocal state if needed
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
      toast.error("Error adding data.");
    }
  };
  
  // const handlesubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const formData = {
  //       name: idlocal,
  //       date: addData.date,
  //       allowance_type: allowance_type,
  //       transport_mode: addData.transport_mode,
  //       from: addData.from,
  //       to: addData.to,
  //       distance: addData.distance,
  //       ticket_bill: addData.ticket_bill,
  //       amount: addData.amount,
  //       status: "Applied",
  //       food_location: addData.food_location,
  //       food_bill: addData.food_bill,
  //       stay_location: addData.stay_location,
  //       stay_bill: addData.stay_bill,
  //       remarks: addData.remarks,
  //     };
  //     setAllowance_Type("");
  //     console.log("the form data ", formData);
  //     const response = await axios.post(ApiUrl + "/travel_allowance", formData);
  //     console.log("Updated:", response);
  //     setShowModal(false);
  //     toast.success("added successfully!");
  //     setAddData({
  //       name: "",
  //       date: "",
  //       allowance_type: "",
  //       transport_mode: "",
  //       from: "",
  //       to: "",
  //       distance: "",
  //       ticket_bill: "",
  //       amount: "",
  //       status: "",
  //       food_location: "",
  //       food_bill: "",
  //       stay_location: "",
  //       stay_bill: "",
  //       remarks: "",
  //     });
  //     fetchData();
  //   } catch (error) {
  //     console.error("Error updating data:", error.message);
  //   }
  // };

  const formatDatefitching = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
    const year = date.getFullYear();

    return `${day}-${month}-${year}`; // Format as "day-month-year"
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        ApiUrl + `/travel_allowance/${getdeleteid}`
      );
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [allowance_type, setAllowance_Type] = useState("");

  const handleAllowanceChange = (event) => {
    setAllowance_Type(event.target.value);
    setAddData((prevData) => ({
      ...prevData,
      amount: "",
      transport_mode: "",
      from: "",
      to: "",
      remarks: "",
    }));
  };

  const handleAllowanceChanges = (event) => {
    const selectedAllowanceType = event.target.value;
    setUpdateData((prevData) => ({
      ...prevData,
      allowance_type: selectedAllowanceType,
      transport_mode: "",
      from: "",
      to: "",
      distance: "",
      amount: "",
      food_location: "",
      food_bill: "",
      remarks: "",
      ticket_bill: "",
    }));
  };

  const [idlocal, setlocalid] = useState([]);
  useEffect(() => {
    fetchData();
    const id_local = localStorage.getItem("username");
    setlocalid(id_local);
  }, []);

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [infoShowModal, setInfoShowModal] = useState(false);
  const [responseStaff, setResponseStaff] = useState("");
  const [responseStaffss, setResponseStaffss] = useState("");

  const fetchStaff = async () => {
    try {
      const responsedstaff = await axios.get(ApiUrl + `/staff`);
      console.log("Response staff datas :", responsedstaff.data);

      const storedUsername = localStorage.getItem("username");
      console.log("this 123", storedUsername);

      const datanew = responsedstaff.data.find((data) => {
        return data.empid === storedUsername;
      });

      console.log("this 123 is datanew", datanew);
      setResponseStaff(responsedstaff.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  const handleOpenInfo = async (_id, item) => {
    console.log(" the data to update onclick _id   ", _id);
    console.log(" the data to update onclick item   ", item);
    console.log(" the data to update onclick item   ", item.name);
    const dataname = item.name;
    console.log(" the data to update onclick responseStaff   ", responseStaff);
    console.log(
      " the data to update onclick responseStaff 1233  ",
      responseStaffss
    );
    const selectedTravelMode = responseStaff.filter(
      (item) => item.empid == dataname
    );

    setResponseStaffss(selectedTravelMode);
    console.log(
      " the data to update onclick item selectedTravelMode  ",
      selectedTravelMode
    );

    const allowance_response = await axios.get(
      ApiUrl + `/travel_allowance/${_id}`
    );
    console.log("response is ok", allowance_response);
    setUpdateData(allowance_response.data); // This should set the correct values for updateData
    setInfoShowModal(true);
  };

  const handleInfoClose = () => {
    setInfoShowModal(false);
  };

  const handleOpenModal = (_id, name) => {
    console.log(" the id", _id);
    console.log(" the name", name);
    setdeleteid(_id);
    setdeletename(name);
    setDeleteShowModals(true);
  };

  useEffect(() => {
    // Set the current date in YYYY-MM-DD format
    const currentDate = new Date().toISOString().split("T")[0];
    setAddData((prevData) => ({
      ...prevData,
      date: currentDate, // Set the default date
    }));
  }, []);

  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [updateid, setUpdateId] = useState("");

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: idlocal,
      mobile_no: updateData.mobile_no,
      from: updateData.from || "",
      to: updateData.to || "",
      travel_distance: updateData.travel_distance || "",
      date: updateData.date || "",
      allowance_type: updateData.allowance_type || "",
      transport_mode: updateData.transport_mode || "",
      distance: updateData.distance || "",
      ticket_bill: updateData.ticket_bill || "",
      amount: updateData.amount || "",
      status: updateData.status || "",
      food_location: updateData.food_location || "",
      food_bill: updateData.food_bill || "",
      stay_location: updateData.stay_location || "",
      stay_bill: updateData.stay_bill || "",
      remarks: updateData.remarks || "",
    };
    console.log("Submit Data: ", formData);
    try {
      const response = await axios.put(
        ApiUrl + `/travel_allowance/${updateid}`,
        formData
      );
      console.log("Response for update: ", response);
      setUpdateShowModal(false);
      toast.success("Update successful!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleUpdate = (_id, data) => {
    setUpdateId(_id);
    console.log(" the data to updata   onclick data   ", data);
    console.log(" the data to updata   onclick course id   ", data.course_id);

    console.log(
      " the data to updata   onclick course id   ",
      data.allowance_type
    );

    setUpdateShowModal(true);
    setUpdateData({
      name: idlocal,
      mobile_no: data.mobile_no,
      from: data.from,
      to: data.to,
      travel_distance: data.travel_distance,
      date: data.date,
      allowance_type: data.allowance_type,
      transport_mode: data.transport_mode,
      distance: data.distance,
      ticket_bill: data.ticket_bill,
      amount: data.amount,
      status: data.status,
      food_location: data.food_location,
      food_bill: data.food_bill,
      stay_location: data.stay_location,
      stay_bill: data.stay_bill,
      remarks: data.remarks,
    });
  };

  const navigate = useNavigate();

  const [responsetype, setResponseType] = useState([]);

  const fetchDatased = async () => {
    try {
      const responsetypes = await axios.get(ApiUrl + "/charge");
      setResponseType(responsetypes.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchDatased();
  }, []);

  const getStatusLabelClass = (status) => {
    switch (status) {
      case "Applied":
        return "bg-secondary1 text-white1 ";
      case "Verified":
        return "bg-warning1 text-white1 ";
      case "Approved":
        return "bg-success1 text-white1 ";
      default:
        return "";
    }
  };

  const handleDistanceChange = (e) => {
    const newDistance = e.target.value;
    yourFunctionToHandleDistance(newDistance);

    setAddData({ ...addData, distance: newDistance });
    setUpdateData({ ...updateData, distance: newDistance });
  };

  const yourFunctionToHandleDistance = (distance) => {
    console.log("Distance value changed to:", distance);

    const value = addData.per_km_charges * distance;
    console.log("multiple is ok", value);
    addData.amount = value;

    const updateDataValue = updateData.per_km_charges * distance;
    console.log("multiple is ok for updateData:", updateDataValue);
    updateData.amount = updateDataValue;
  };

  const getPerKmCharges = (selectedMode) => {
    const selectedTravelMode = responsetype.find(
      (item) => item.travel_mode === selectedMode
    );
    return selectedTravelMode ? selectedTravelMode.per_km_charges : "";
  };

  const handleSelectChange = (e) => {
    const selectedMode = e.target.value;
    const perKmCharges = getPerKmCharges(selectedMode);

    console.log("Selected Mode:", selectedMode);
    console.log("Per Km Charges:", perKmCharges);

    if (updateData && updateData.allowance_type === "Travel") {
      setUpdateData((prev) => ({
        ...prev,
        transport_mode: selectedMode,
        per_km_charges: perKmCharges || 0, // Default to 0 if undefined
        from: "",
        to: "",
        distance: "",
        amount: "",
        remarks: "",
        ticket_bill: "",
      }));
    } else {
      setAddData((prev) => ({
        ...prev,
        transport_mode: selectedMode,
        per_km_charges: perKmCharges || 0, // Default to 0 if undefined
      }));
    }
  };

  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  const [responseProject, setResponseProject] = useState([]);
  const [responseService, setResponseService] = useState([]);

  useEffect(() => {
    fetchProjects();
    fetchServices();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(ApiUrl + "/expected");
      setResponseProject(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error.message);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get(ApiUrl + "/services");
      setResponseService(response.data);
    } catch (error) {
      console.error("Error fetching services:", error.message);
    }
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>

            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Staff / </b>
            </Link>

            <Link to="" className="text-danger dashBoard_text">
              <b>Allowance </b>
            </Link>
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-10 d-flex">
            <h6 className="mt-2 bold">Travel Allowance</h6>
            <button
              className="btn btn-dark btn-sm add mx-3"
              onClick={() => setShowModal(true)}
            >
              Add{" "}
            </button>
          </div>
          <div className="col-lg-2">
            <button
              className="btn  btn-secondary"
              onClick={() => navigate("/charge")}
            >
              Allowance Charge
            </button>
          </div>
        </div>
      </div>

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    <button
                      className="btn custom-btn-update btn-sm"
                      onClick={() =>
                        handleUpdate(row.original._id, row.original)
                      }
                    >
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button
                      className="btn custom-btn-delete btn-sm mx-1"
                      onClick={() =>
                        handleOpenModal(row.original._id, row.original.name)
                      }
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>

                    <button
                      className="btn custom-btn-info btn-sm "
                      onClick={() =>
                        handleOpenInfo(row.original._id, row.original)
                      }
                    >
                      <i className="fa-solid fa-circle-info"></i>
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      {/* Modal for adding a new user */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Allowance </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={idlocal || ""}
                onChange={(e) =>
                  setAddData({
                    ...idlocal,
                    name: e.target.value,
                  })
                }
                disabled
                required
              />
              <label className={addData.idlocal ? "filled" : ""}>
                Staff Name
              </label>
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                type="date"
                placeholder=""
                value={addData.date}
                onChange={(e) =>
                  setAddData({ ...addData, date: e.target.value })
                }
                required
              />
              <label className={addData.date ? "filled" : ""}>Visit Date</label>
            </div>

            <div className="floating-label">
              <select
                className="form-control"
                value={addData.type}
                onChange={(e) =>
                  setAddData({ type: e.target.value, selectedItem: "" })
                }
                required
              >
                <option value="">Select Type</option>
                <option value="Project">Project</option>
                <option value="Service">Service</option>
              </select>
              <label className={addData.type ? "filled" : ""}></label>
            </div>

            {addData.type && (
              <div className="mt-3 mb-4">
                <select
                  className="form-select"
                  value={addData.selectedItem}
                  onChange={(e) =>
                    setAddData({ ...addData, selectedItem: e.target.value })
                  }
                  required
                >
                  <option value="">Select {addData.type}</option>
                  {(addData.type === "Project"
                    ? responseProject
                    : responseService
                  ).map((item) => (
                    <option key={item._id} value={item._id}>
                      {addData.type === "Project"
                        ? `${item.customer_orders[0]?.quotation_no} - ${item.company_name}`
                        : item.service_name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="floating-label">
              <select
                className="form-control"
                value={allowance_type}
                onChange={handleAllowanceChange}
              >
                <option value="" disabled>
                  Select Allowance Type
                </option>
                <option value="Travel">Travel</option>
                <option value="Food">Food</option>
                <option value="Stay">Stay or Accommodation</option>
              </select>
            </div>

            {allowance_type === "Travel" && (
              <>
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.transport_mode}
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="">Select Travel Type</option>
                    {responsetype.map((item, index) => (
                      <option key={index} value={item.travel_mode}>
                        {item.travel_mode}
                      </option>
                    ))}
                  </select>
                  {addData.per_km_charges && <div></div>}
                </div>

                {(addData.transport_mode === "Train" ||
                  addData.transport_mode === "Flight") && (
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.ticket_bill}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          ticket_bill: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.ticket_bill ? "filled" : ""}>
                      Tickets / Bill No
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.from}
                    onChange={(e) =>
                      setAddData({ ...addData, from: e.target.value })
                    }
                    required
                  />
                  <label className={addData.from ? "filled" : ""}>From</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.to}
                    onChange={(e) =>
                      setAddData({ ...addData, to: e.target.value })
                    }
                    required
                  />
                  <label className={addData.to ? "filled" : ""}>To</label>
                </div>

                {(addData.transport_mode === "Bike" ||
                  addData.transport_mode === "Car") && (
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.distance}
                      onChange={handleDistanceChange}
                      required
                    />
                    <label className={addData.distance ? "filled" : ""}>
                      Distance (Kilometer)
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.amount}
                    onChange={(e) =>
                      setAddData({ ...addData, amount: e.target.value })
                    }
                    required
                  />
                  <label className={addData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>
              </>
            )}

            {allowance_type === "Food" && (
              <>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.food_location}
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        food_location: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.food_location ? "filled" : ""}>
                    Location
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.food_bill}
                    onChange={(e) =>
                      setAddData({ ...addData, food_bill: e.target.value })
                    }
                    required
                  />
                  <label className={addData.food_bill ? "filled" : ""}>
                    Bill
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.amount}
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        amount: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>
              </>
            )}

            {allowance_type === "Stay" && (
              <>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.stay_location}
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        stay_location: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.stay_location ? "filled" : ""}>
                    Location
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.stay_bill}
                    onChange={(e) =>
                      setAddData({ ...addData, stay_bill: e.target.value })
                    }
                    required
                  />
                  <label className={addData.stay_bill ? "filled" : ""}>
                    Bill
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.amount}
                    onChange={(e) =>
                      setAddData({ ...addData, amount: e.target.value })
                    }
                    required
                  />
                  <label className={addData.food_location ? "filled" : ""}>
                    Amount
                  </label>
                </div>
              </>
            )}
            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={addData.remarks}
                onChange={(e) =>
                  setAddData({ ...addData, remarks: e.target.value })
                }
                required
              />
              <label className={addData.remarks ? "filled" : ""}>Remarks</label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => {
              setShowModal(false);
              setAllowance_Type("");
            }}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handlesubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update modal start */}

      <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Allowance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={idlocal}
                onChange={(e) =>
                  setUpdateData({
                    ...idlocal,
                    name: e.target.value,
                  })
                }
                disabled
                required
              />
              <label className={updateData.idlocal ? "filled" : ""}>
                Staff Name
              </label>
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                type="date"
                placeholder=""
                value={updateData.date}
                onChange={(e) =>
                  setUpdateData({ ...updateData, date: e.target.value })
                }
                required
                // disabled
              />
              <label className={updateData.date ? "filled" : ""}>
                Visit Date
              </label>
            </div>

            {/* <p>{allowance_type}</p> */}
            <div className="floating-label">
              <select
                className="form-control"
                value={updateData.allowance_type}
                onChange={handleAllowanceChanges}
              >
                <option value="" disabled>
                  Select Allowance Type
                </option>
                <option value="Travel">Travel</option>
                <option value="Food">Food</option>
                <option value="Stay">Stay or Accommodation</option>
              </select>
            </div>

            {updateData.allowance_type === "Travel" && (
              <>
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.transport_mode}
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="">Select Travel Type</option>
                    {responsetype.map((item, index) => (
                      <option key={index} value={item.travel_mode}>
                        {item.travel_mode}
                      </option>
                    ))}
                  </select>
                </div>

                {(updateData.transport_mode === "Train" ||
                  updateData.transport_mode === "Flight") && (
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.ticket_bill}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          ticket_bill: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.ticket_bill ? "filled" : ""}>
                      Tickets / Bill No
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.from}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, from: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.from ? "filled" : ""}>
                    From
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.to}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, to: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.to ? "filled" : ""}>To</label>
                </div>

                {/* {(updateData.transport_mode === "Bike" ||
                  updateData.transport_mode === "Car") && (
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.distance}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          distance: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.distance ? "filled" : ""}>
                      Distance (Kilometer)
                    </label>
                  </div>
                )} */}

                {(updateData.transport_mode === "Bike" ||
                  updateData.transport_mode === "Car") && (
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.distance}
                      onChange={handleDistanceChange}
                      required
                    />
                    <label className={updateData.distance ? "filled" : ""}>
                      Distance (Kilometer)
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        amount: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>
              </>
            )}

            {updateData.allowance_type === "Food" && (
              <>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.food_location}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        food_location: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.food_location ? "filled" : ""}>
                    Location
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.food_bill}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        food_bill: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.food_bill ? "filled" : ""}>
                    Bill
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        amount: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>
              </>
            )}

            {updateData.allowance_type === "Stay" && (
              <>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.stay_location}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        stay_location: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.stay_location ? "filled" : ""}>
                    Location
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        amount: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Bill
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        amount: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>
              </>
            )}

            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={updateData.remarks}
                onChange={(e) =>
                  setUpdateData({ ...updateData, remarks: e.target.value })
                }
                required
              />
              <label className={updateData.remarks ? "filled" : ""}>
                Remarks
              </label>
            </div>

            <div className="floating-label">
              <select
                className="form-control"
                value={updateData.status}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    status: e.target.value,
                  })
                }
                required
              >
                <option value="">Choose Status</option>

                <option value="Verified">Verified</option>
                <option value="Approved">Approved</option>
              </select>
              <label className={updateData.status ? "filled" : ""}>
                {updateData.status ? "Status" : ""}
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update modal end */}

      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Name :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ---------------------------------------end ---------------------------------- */}

      <Modal className="modal" show={infoShowModal} onHide={handleInfoClose}>
        <Modal.Header>
          <p>Allowance Preview</p>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-5">
              <div className="card p-3 mb-4">
                <img
                  className=""
                  alt=""
                  // src={`${apiUrl}/uploads/${responseStaff[0]?.photo_upload || ""}`}
                  src={`${ApiUrl}/uploads/${
                    responseStaffss[0]?.photo_upload ||
                    require("../../assets/img/photo.jfif")
                  }`}
                  style={{ height: "75px" }}
                ></img>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.name}
                  disabled
                  required
                />
                <label className="">ID</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={responseStaffss[0]?.name}
                  storedUsername
                  disabled
                  required
                />
                <label className="">Name</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={responseStaffss[0]?.designation[0]?.designation}
                  disabled
                  required
                />
                <label className="">Designation</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.allowance_type}
                  disabled
                  required
                />
                <label className="">Allowance Type</label>
              </div>
            </div>

            <div className="col-lg-7">
              {updateData.allowance_type === "Travel" ? (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.transport_mode}
                    disabled
                    required
                  />
                  <label className="">Travel Type</label>
                </div>
              ) : null}

              {(updateData.transport_mode === "Train" ||
                updateData.transport_mode === "Flight") && (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.ticket_bill}
                    disabled
                    required
                  />
                  <label className="">Tickets / Bill No</label>
                </div>
              )}

              {updateData.allowance_type === "Travel" ? (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.from}
                    disabled
                    required
                  />
                  <label className="">From</label>
                </div>
              ) : null}

              {updateData.allowance_type === "Travel" ? (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.to}
                    disabled
                    required
                  />
                  <label className="">To</label>
                </div>
              ) : null}

              {(updateData.transport_mode === "Bike" ||
                updateData.transport_mode === "Car") && (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.distance}
                    disabled
                    required
                  />
                  <label className="">Distance</label>
                </div>
              )}

              {(updateData.allowance_type === "Food" ||
                updateData.allowance_type === "Stay") && (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.food_location}
                    disabled
                    required
                  />
                  <label className="">Location</label>
                </div>
              )}

              {(updateData.allowance_type === "Food" ||
                updateData.allowance_type === "Stay") && (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.food_bill}
                    disabled
                    required
                  />
                  <label className="">Bill</label>
                </div>
              )}

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.amount}
                  disabled
                  required
                />
                <label className="">Amount</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.remarks}
                  disabled
                  required
                />
                <label className="">Remarks</label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleInfoClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* </div> */}
    </>
  );
}

export default Travel_Allowance;
