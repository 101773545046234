// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useNavigate, useParams, Link } from "react-router-dom";
// import ApiUrl from "../Environment/ApiUrl";

// function OngoingBillMaterial() {
//   const { id } = useParams(); // Get the expectedBill_id from URL params
//   const [itemsList, setItemsList] = useState([]);
//   const [deleteId, setDeleteId] = useState(null);
//   const [expected_Details, setExpectedDetails] = useState([]);
//   const [formData, setFormData] = useState({
//     annexure_description_one: "",
//     annexure_qty_one: "",
//     basicprice: "",
//     tax: "",
//     expectedBill_id: id || "", // Initialize with the id from URL
//   });
//   const [tempItems, setTempItems] = useState([]);
//   const [editIndex, setEditIndex] = useState(null);
//   const [editId, setEditId] = useState(null);
//   const [isEditing, setIsEditing] = useState(false);

//   useEffect(() => {
//     // Update formData with the id whenever it changes
//     setFormData((prev) => ({
//       ...prev,
//       expectedBill_id: id || "",
//     }));

//     fetchItems();
//     fetchExpectedDetails();
//   }, [id]);

//   const fetchItems = async () => {
//     try {
//       // If we have an ID, fetch items for that specific expected bill
//       const endpoint = id
//         ? `${ApiUrl}/ongoingbillmaterial?expectedBill_id=${id}`
//         : `${ApiUrl}/ongoingbillmaterial`;

//       const response = await axios.get(endpoint);
//       console.log("asdf", response);

//       setItemsList(response.data);
//     } catch (error) {
//       console.error("Error fetching items:", error);
//       toast.error("Failed to load data. Please try again.");
//     }
//   };

//   const fetchExpectedDetails = async () => {
//     try {
//       if (id) {
//         const response = await axios.get(`${ApiUrl}/ongoingbillmaterial/${id}`);
//         console.log("asdasdfaf", response);

//         if (response.data && response.data.expected_Details) {
//           setExpectedDetails(response.data.expected_Details);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching expected details:", error);
//       // toast.error("Failed to load project details.");
//     }
//   };

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const calculateTotal = (price, quantity, tax) => {
//     return (
//       parseFloat(price) *
//       parseFloat(quantity) *
//       (1 + parseFloat(tax) / 100)
//     ).toFixed(2);
//   };

//   const validateForm = () => {
//     if (
//       !formData.annexure_description_one ||
//       !formData.annexure_qty_one ||
//       !formData.basicprice ||
//       !formData.tax
//     ) {
//       toast.warning("Please fill in all fields");
//       return false;
//     }
//     return true;
//   };

//   const handleAddItem = () => {
//     if (!validateForm()) return;

//     const item = {
//       annexure_description_one: formData.annexure_description_one,
//       annexure_qty_one: parseFloat(formData.annexure_qty_one),
//       basicprice: parseFloat(formData.basicprice),
//       tax: parseFloat(formData.tax),
//       expectedBill_id: formData.expectedBill_id, // Include the expectedBill_id
//       total: calculateTotal(
//         formData.basicprice,
//         formData.annexure_qty_one,
//         formData.tax
//       ),
//       isTemp: true, // Flag to identify items not yet submitted to backend
//     };

//     if (isEditing && editId) {
//       // Update existing item from database in the tempItems
//       const existingTempIndex = tempItems.findIndex(
//         (item) => item._id === editId
//       );

//       if (existingTempIndex >= 0) {
//         // Update in tempItems if it's already there
//         const updatedTempItems = [...tempItems];
//         updatedTempItems[existingTempIndex] = { ...item, _id: editId };
//         setTempItems(updatedTempItems);
//       } else {
//         // Add to tempItems if not already there
//         setTempItems([...tempItems, { ...item, _id: editId }]);
//       }

//       // Update in itemsList for immediate UI update
//       const updatedItemsList = itemsList.map((listItem) =>
//         listItem._id === editId ? { ...item, _id: editId } : listItem
//       );
//       setItemsList(updatedItemsList);

//       toast.success("Item updated Successfully");
//     } else if (editIndex !== null) {
//       // Update item in tempItems only
//       const updatedItems = [...tempItems];
//       updatedItems[editIndex] = item;
//       setTempItems(updatedItems);

//       // Also update in the display list
//       const updatedItemsList = [...itemsList];
//       const displayIndex = itemsList.findIndex(
//         (i) => i._id === tempItems[editIndex]._id
//       );
//       if (displayIndex >= 0) {
//         updatedItemsList[displayIndex] = {
//           ...item,
//           _id: tempItems[editIndex]._id,
//         };
//         setItemsList(updatedItemsList);
//       }

//       toast.info("Temporary item updated");
//     } else {
//       // Add new temporary item
//       const newItem = { ...item, _id: `temp-${Date.now()}` };
//       setTempItems([...tempItems, newItem]);
//       setItemsList([...itemsList, newItem]);
//       toast.success("Added Successfully");
//     }
//     resetForm();
//   };

//   const resetForm = () => {
//     setFormData({
//       annexure_description_one: "",
//       annexure_qty_one: "",
//       basicprice: "",
//       tax: "",
//       expectedBill_id: id || "", // Maintain the expectedBill_id
//     });
//     setEditIndex(null);
//     setEditId(null);
//     setIsEditing(false);
//   };

//   const handleEdit = (id) => {
//     const itemToEdit = itemsList.find((item) => item._id === id);
//     if (itemToEdit) {
//       setFormData({
//         annexure_description_one: itemToEdit.annexure_description_one || "",
//         annexure_qty_one: itemToEdit.annexure_qty_one.toString(),
//         basicprice: itemToEdit.basicprice.toString(),
//         tax: itemToEdit.tax.toString(),
//         expectedBill_id: itemToEdit.expectedBill_id || formData.expectedBill_id, // Preserve expectedBill_id
//       });
//       setEditId(id);
//       setIsEditing(true);

//       // Check if this item is already in tempItems
//       const tempIndex = tempItems.findIndex((item) => item._id === id);
//       if (tempIndex >= 0) {
//         setEditIndex(tempIndex);
//       }
//     }
//   };

//   const confirmDelete = (id) => {
//     setDeleteId(id);
//     // Open the bootstrap modal
//     const modal = new window.bootstrap.Modal(
//       document.getElementById("deleteModal")
//     );
//     modal.show();
//   };

//   const handleDelete = async () => {
//     try {
//       if (!deleteId) return;

//       // Check if item is in tempItems
//       const tempIndex = tempItems.findIndex((item) => item._id === deleteId);
//       if (tempIndex >= 0) {
//         const updatedTempItems = tempItems.filter(
//           (item) => item._id !== deleteId
//         );
//         setTempItems(updatedTempItems);
//       }
//       const isTemp = deleteId.toString().startsWith("temp-");

//       if (!isTemp) {
//         await axios.delete(`${ApiUrl}/ongoingbillmaterial/${deleteId}`);
//         toast.success("Item deleted from database!");
//       } else {
//         toast.info("Temporary item removed");
//       }
//       const updatedItemsList = itemsList.filter(
//         (item) => item._id !== deleteId
//       );
//       setItemsList(updatedItemsList);
//     } catch (error) {
//       console.error("Error deleting item:", error);
//       toast.error("Failed to delete item.");
//     }
//   };

//   const navigate = useNavigate();

//   const handleSubmit = async () => {
//     try {
//       for (const item of tempItems) {
//         const { isTemp, _id, ...itemData } = item;
//         const isValidObjectId = /^[0-9a-fA-F]{24}$/.test(_id);

//         if (isValidObjectId) {
//           await axios.put(`${ApiUrl}/ongoingbillmaterial/${_id}`, itemData);
//         } else {
//           await axios.post(`${ApiUrl}/ongoingbillmaterial`, itemData);
//         }
//       }
//       setTempItems([]);
//       navigate("/ongoing");
//       toast.success("All changes saved successfully!");
//       fetchItems();
//     } catch (error) {
//       console.error("Error submitting data:", error);
//       toast.error("Failed to save changes. Please try again.");
//     }
//   };

//   const cancelEdit = () => {
//     resetForm();
//     toast.info("Edit canceled");
//   };

//   // Format date function
//   const formatDate = (dateString) => {
//     if (!dateString) return "";
//     const date = new Date(dateString);
//     return date.toISOString().split("T")[0]; // Returns YYYY-MM-DD format
//   };

//   return (
//     <>
//       <div className="" style={{ marginTop: "-80px" }}>
//         <div className="row mb-3">
//           <div className="col-lg-12">
//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Dashboard / </b>
//             </Link>
//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Projects / </b>
//             </Link>
//             <Link to="/ongoing" className="text-secondary dashBoard_text">
//               <b>OnGoing / </b>
//             </Link>
//             <Link to="" className="text-danger dashBoard_text">
//               <b>Bill Of Material</b>
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className="container">
//         <div className="card p-3">
//           <div className="row">
//             <div className="col-lg-4">
//               <div className="d-flex">
//                 <h1 className="m-2">Name: </h1>
//                 <input
//                   className="form-control"
//                   placeholder="Enter Name"
//                   value={
//                     itemsList[0]?.expected_Details?.name ||
//                     itemsList[0]?.expected_Details?.[0]?.name ||
//                     "N/A"
//                   }
//                   disabled
//                 />
//               </div>
//             </div>
//             <div className="col-lg-4">
//               <div className="d-flex">
//                 <h1 className="m-2">Company: </h1>
//                 <input
//                   className="form-control"
//                   placeholder="Enter Company"
//                   value={
//                     itemsList[0]?.expected_Details?.company_name ||
//                     itemsList[0]?.expected_Details?.[0]?.company_name ||
//                     "N/A"
//                   }
//                   disabled
//                 />
//               </div>
//             </div>
//             <div className="col-lg-4">
//               <div className="d-flex">
//                 <h1 className="m-2">Start: </h1>
//                 <input
//                   className="form-control"
//                   type="date"
//                   placeholder="Start Date"
//                   value={
//                     itemsList[0]?.expected_Details?.start_date
//                       ? new Date(itemsList[0]?.expected_Details?.start_date)
//                           .toISOString()
//                           .split("T")[0]
//                       : itemsList[0]?.expected_Details?.[0]?.start_date
//                       ? new Date(
//                           itemsList[0]?.expected_Details?.[0]?.start_date
//                         )
//                           .toISOString()
//                           .split("T")[0]
//                       : ""
//                   }
//                   disabled
//                 />
//               </div>
//             </div>
//           </div>

//           <div className="row mt-2">
//             <div className="col-lg-4">
//               <div className="d-flex">
//                 <h1 className="m-2">End: </h1>
//                 <input
//                   className="form-control"
//                   type="date"
//                   placeholder="Select End Date"
//                   // value={
//                   //   itemsList[0]?.expected_Details?.end_date ||
//                   //   itemsList[0]?.expected_Details?.[0]?.end_date ||
//                   //   "N/A"
//                   // }
//                   value={
//                     itemsList[0]?.expected_Details?.end_date
//                       ? new Date(itemsList[0]?.expected_Details?.end_date)
//                           .toISOString()
//                           .split("T")[0]
//                       : itemsList[0]?.expected_Details?.[0]?.end_date
//                       ? new Date(itemsList[0]?.expected_Details?.[0]?.end_date)
//                           .toISOString()
//                           .split("T")[0]
//                       : "N/A"
//                   }
//                   disabled
//                 />
//               </div>
//             </div>
//             <div className="col-lg-4">
//               <div className="d-flex">
//                 <h1 className="m-2">Location: </h1>
//                 <input
//                   className="form-control"
//                   placeholder="Enter Location"
//                   value={
//                     itemsList[0]?.expected_Details?.location ||
//                     itemsList[0]?.expected_Details?.[0]?.location ||
//                     "N/A"
//                   }
//                   disabled
//                 />
//               </div>
//             </div>
//             <div className="col-lg-4">
//               <div className="d-flex">
//                 <h1 className="m-2">Mobile: </h1>
//                 <input
//                   className="form-control"
//                   placeholder="Enter Mobile"
//                   value={
//                     itemsList[0]?.expected_Details?.official_mobile ||
//                     itemsList[0]?.expected_Details?.[0]?.official_mobile ||
//                     "N/A"
//                   }
//                   disabled
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container mt-4">
//         <h2 className="text-center mb-4 fs-4">Bill Of Material</h2>

//         {/* Input Form */}
//         <div className="card p-3 mb-4">
//           <div className="row g-3">
//             <div className="col-md-3">
//               <label className="form-label">Description</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 name="annexure_description_one"
//                 value={formData.annexure_description_one}
//                 onChange={handleChange}
//                 placeholder="Enter description"
//               />
//             </div>
//             <div className="col-md-2">
//               <label className="form-label">Quantity</label>
//               <input
//                 type="number"
//                 className="form-control"
//                 name="annexure_qty_one"
//                 value={formData.annexure_qty_one}
//                 onChange={handleChange}
//                 placeholder="Qty"
//               />
//             </div>
//             <div className="col-md-2">
//               <label className="form-label">Basic Price</label>
//               <input
//                 type="number"
//                 className="form-control"
//                 name="basicprice"
//                 value={formData.basicprice}
//                 onChange={handleChange}
//                 placeholder="Unit price"
//               />
//             </div>
//             <div className="col-md-2">
//               <label className="form-label">Tax (%)</label>
//               <input
//                 type="number"
//                 className="form-control"
//                 name="tax"
//                 value={formData.tax}
//                 onChange={handleChange}
//                 placeholder="Tax %"
//               />
//             </div>
//             <div className="col-md-3 d-flex align-items-end">
//               <button className="btn btn-primary me-2" onClick={handleAddItem}>
//                 {isEditing ? "Update" : "Add"}
//               </button>

//               {isEditing && (
//                 <button className="btn btn-secondary" onClick={cancelEdit}>
//                   Cancel
//                 </button>
//               )}

//               <button className="btn btn-success ms-2" onClick={handleSubmit}>
//                 Submit
//               </button>
//             </div>
//           </div>
//         </div>

//         {/* Items Table */}
//         <div className="card p-3">
//           <h5 className="card-title mb-3">Bill Items</h5>
//           <div className="table-responsive">
//             <table className="table table-bordered table-hover">
//               <thead className="table-light">
//                 <tr>
//                   <th width="5%">S.No</th>
//                   <th width="30%">Description</th>
//                   <th width="10%">Quantity</th>
//                   <th width="15%">Price</th>
//                   <th width="10%">Tax (%)</th>
//                   <th width="15%">Total</th>
//                   <th width="15%">Actions</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {itemsList.length > 0 ? (
//                   itemsList.map((item, index) => {
//                     const isItemModified = tempItems.some(
//                       (temp) => temp._id === item._id
//                     );
//                     const isItemTemp = item._id.toString().startsWith("temp-");

//                     return (
//                       <tr
//                         key={item._id}
//                         className={
//                           isItemTemp
//                             ? "table-secondary"
//                             : isItemModified
//                             ? "table-warning"
//                             : ""
//                         }
//                       >
//                         <td>{index + 1}</td>
//                         <td>{item.annexure_description_one}</td>
//                         <td>{item.annexure_qty_one}</td>
//                         <td>${parseFloat(item.basicprice).toFixed(2)}</td>
//                         <td>{item.tax}%</td>
//                         <td>
//                           $
//                           {item.total ||
//                             calculateTotal(
//                               item.basicprice,
//                               item.annexure_qty_one,
//                               item.tax
//                             )}
//                         </td>
//                         <td>
//                           <button
//                             className="btn btn-sm btn-primary me-2"
//                             onClick={() => handleEdit(item._id)}
//                           >
//                             <i className="fas fa-edit"></i>
//                           </button>
//                           <button
//                             className="btn btn-sm btn-danger"
//                             onClick={() => confirmDelete(item._id)}
//                           >
//                             <i className="fas fa-trash"></i>
//                           </button>
//                         </td>
//                       </tr>
//                     );
//                   })
//                 ) : (
//                   <tr>
//                     <td colSpan="7" className="text-center">
//                       No items available
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>

//         {/* Toast Notifications */}
//         <ToastContainer position="top-right" autoClose={3000} />

//         {/* Delete Confirmation Modal */}
//         <div
//           className="modal fade"
//           id="deleteModal"
//           tabIndex="-1"
//           aria-hidden="true"
//         >
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h5 className="modal-title">Confirm Deletion</h5>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   data-bs-dismiss="modal"
//                   aria-label="Close"
//                 ></button>
//               </div>
//               <div className="modal-body">
//                 <p>Are you sure you want to delete this item?</p>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   className="btn btn-secondary"
//                   data-bs-dismiss="modal"
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="button"
//                   className="btn btn-danger"
//                   onClick={handleDelete}
//                   data-bs-dismiss="modal"
//                 >
//                   Delete
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default OngoingBillMaterial;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams, Link } from "react-router-dom";
import ApiUrl from "../Environment/ApiUrl";
import { Modal, Button } from "react-bootstrap";

function OngoingBillMaterial() {
  const { id } = useParams();
  const [itemsList, setItemsList] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [expected_Details, setExpectedDetails] = useState([]);
  const [formData, setFormData] = useState({
    annexure_description_one: "",
    annexure_qty_one: "",
    basicprice: "",
    tax: "",
    expectedBill_id: id || "",
  });
  const [tempItems, setTempItems] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editId, setEditId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Product related states
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [newProductData, setNewProductData] = useState({
    name: "",
    hsn_code: "",
    brand_name: "",
    description: "",
    status: "",
  });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      expectedBill_id: id || "",
    }));

    fetchItems();
    fetchExpectedDetails();
    fetchProducts();
  }, [id]);

  // Fetch all products from API
  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/product`);
      setProducts(response.data);
      setFilteredProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
      toast.error("Failed to load products data");
    }
  };

  const fetchItems = async () => {
    try {
      const endpoint = id
        ? `${ApiUrl}/ongoingbillmaterial?expectedBill_id=${id}`
        : `${ApiUrl}/ongoingbillmaterial`;

      const response = await axios.get(endpoint);
      setItemsList(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
      toast.error("Failed to load data. Please try again.");
    }
  };

  const fetchExpectedDetails = async () => {
    try {
      if (id) {
        const response = await axios.get(`${ApiUrl}/ongoingbillmaterial/${id}`);
        if (response.data && response.data.expected_Details) {
          setExpectedDetails(response.data.expected_Details);
        }
      }
    } catch (error) {
      console.error("Error fetching expected details:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle search in description field
  const handleDescriptionSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    setFormData({
      ...formData,
      annexure_description_one: searchValue,
    });

    // Filter products based on search term
    if (searchValue.trim() !== "") {
      const filtered = products.filter(
        (product) =>
          product.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          product.description.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredProducts(filtered);
      setShowDropdown(true);
    } else {
      setFilteredProducts(products);
      setShowDropdown(false);
    }
  };

  // Select product from dropdown
  const selectProduct = (product) => {
    setFormData({
      ...formData,
      annexure_description_one: product.name,
    });
    setShowDropdown(false);
  };

  // Handle new product form change
  const handleNewProductChange = (e) => {
    setNewProductData({
      ...newProductData,
      [e.target.name]: e.target.value,
    });
  };

  // Submit new product
  const handleAddNewProduct = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${ApiUrl}/product`, newProductData);
      toast.success("Product added successfully!");

      // Add the new product to the products list
      const newProduct = response.data;
      setProducts([...products, newProduct]);
      setFilteredProducts([...products, newProduct]);

      // Select the newly added product
      setFormData({
        ...formData,
        annexure_description_one: newProduct.name,
      });

      // Reset form and close modal
      setNewProductData({
        name: "",
        hsn_code: "",
        brand_name: "",
        description: "",
        status: "",
      });
      setShowAddProductModal(false);
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product. Please try again.");
    }
  };

  const calculateTotal = (price, quantity, tax) => {
    return (
      parseFloat(price) *
      parseFloat(quantity) *
      (1 + parseFloat(tax) / 100)
    ).toFixed(2);
  };

  const validateForm = () => {
    if (
      !formData.annexure_description_one ||
      !formData.annexure_qty_one ||
      !formData.basicprice ||
      !formData.tax
    ) {
      toast.warning("Please fill in all fields");
      return false;
    }
    return true;
  };

  const handleAddItem = () => {
    if (!validateForm()) return;

    const item = {
      annexure_description_one: formData.annexure_description_one,
      annexure_qty_one: parseFloat(formData.annexure_qty_one),
      basicprice: parseFloat(formData.basicprice),
      tax: parseFloat(formData.tax),
      expectedBill_id: formData.expectedBill_id,
      total: calculateTotal(
        formData.basicprice,
        formData.annexure_qty_one,
        formData.tax
      ),
      isTemp: true,
    };

    if (isEditing && editId) {
      const existingTempIndex = tempItems.findIndex(
        (item) => item._id === editId
      );

      if (existingTempIndex >= 0) {
        const updatedTempItems = [...tempItems];
        updatedTempItems[existingTempIndex] = { ...item, _id: editId };
        setTempItems(updatedTempItems);
      } else {
        setTempItems([...tempItems, { ...item, _id: editId }]);
      }

      const updatedItemsList = itemsList.map((listItem) =>
        listItem._id === editId ? { ...item, _id: editId } : listItem
      );
      setItemsList(updatedItemsList);

      toast.success("Item updated Successfully");
    } else if (editIndex !== null) {
      const updatedItems = [...tempItems];
      updatedItems[editIndex] = item;
      setTempItems(updatedItems);

      const updatedItemsList = [...itemsList];
      const displayIndex = itemsList.findIndex(
        (i) => i._id === tempItems[editIndex]._id
      );
      if (displayIndex >= 0) {
        updatedItemsList[displayIndex] = {
          ...item,
          _id: tempItems[editIndex]._id,
        };
        setItemsList(updatedItemsList);
      }

      toast.info("Temporary item updated");
    } else {
      const newItem = { ...item, _id: `temp-${Date.now()}` };
      setTempItems([...tempItems, newItem]);
      setItemsList([...itemsList, newItem]);
      toast.success("Added Successfully");
    }
    resetForm();
  };

  const resetForm = () => {
    setFormData({
      annexure_description_one: "",
      annexure_qty_one: "",
      basicprice: "",
      tax: "",
      expectedBill_id: id || "",
    });
    setEditIndex(null);
    setEditId(null);
    setIsEditing(false);
    setSearchTerm("");
  };

  const handleEdit = (id) => {
    const itemToEdit = itemsList.find((item) => item._id === id);
    if (itemToEdit) {
      setFormData({
        annexure_description_one: itemToEdit.annexure_description_one || "",
        annexure_qty_one: itemToEdit.annexure_qty_one.toString(),
        basicprice: itemToEdit.basicprice.toString(),
        tax: itemToEdit.tax.toString(),
        expectedBill_id: itemToEdit.expectedBill_id || formData.expectedBill_id,
      });
      setEditId(id);
      setIsEditing(true);

      const tempIndex = tempItems.findIndex((item) => item._id === id);
      if (tempIndex >= 0) {
        setEditIndex(tempIndex);
      }
    }
  };

  const confirmDelete = (id) => {
    setDeleteId(id);
    const modal = new window.bootstrap.Modal(
      document.getElementById("deleteModal")
    );
    modal.show();
  };

  const handleDelete = async () => {
    try {
      if (!deleteId) return;

      const tempIndex = tempItems.findIndex((item) => item._id === deleteId);
      if (tempIndex >= 0) {
        const updatedTempItems = tempItems.filter(
          (item) => item._id !== deleteId
        );
        setTempItems(updatedTempItems);
      }
      const isTemp = deleteId.toString().startsWith("temp-");

      if (!isTemp) {
        await axios.delete(`${ApiUrl}/ongoingbillmaterial/${deleteId}`);
        toast.success("Item deleted from database!");
      } else {
        toast.info("Temporary item removed");
      }
      const updatedItemsList = itemsList.filter(
        (item) => item._id !== deleteId
      );
      setItemsList(updatedItemsList);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("Failed to delete item.");
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      for (const item of tempItems) {
        const { isTemp, _id, ...itemData } = item;
        const isValidObjectId = /^[0-9a-fA-F]{24}$/.test(_id);

        if (isValidObjectId) {
          await axios.put(`${ApiUrl}/ongoingbillmaterial/${_id}`, itemData);
        } else {
          await axios.post(`${ApiUrl}/ongoingbillmaterial`, itemData);
        }
      }
      setTempItems([]);
      navigate("/ongoing");
      toast.success("All changes saved successfully!");
      fetchItems();
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to save changes. Please try again.");
    }
  };

  const cancelEdit = () => {
    resetForm();
    toast.info("Edit canceled");
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Projects / </b>
            </Link>
            <Link to="/ongoing" className="text-secondary dashBoard_text">
              <b>OnGoing / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Bill Of Material</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="card p-3">
          <div className="row">
            <div className="col-lg-4">
              <div className="d-flex">
                <h1 className="m-2">Name: </h1>
                <input
                  className="form-control"
                  placeholder="Enter Name"
                  value={
                    itemsList[0]?.expected_Details?.name ||
                    itemsList[0]?.expected_Details?.[0]?.name ||
                    "N/A"
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex">
                <h1 className="m-2">Company: </h1>
                <input
                  className="form-control"
                  placeholder="Enter Company"
                  value={
                    itemsList[0]?.expected_Details?.company_name ||
                    itemsList[0]?.expected_Details?.[0]?.company_name ||
                    "N/A"
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex">
                <h1 className="m-2">Start: </h1>
                <input
                  className="form-control"
                  type="date"
                  placeholder="Start Date"
                  value={
                    itemsList[0]?.expected_Details?.start_date
                      ? new Date(itemsList[0]?.expected_Details?.start_date)
                          .toISOString()
                          .split("T")[0]
                      : itemsList[0]?.expected_Details?.[0]?.start_date
                      ? new Date(
                          itemsList[0]?.expected_Details?.[0]?.start_date
                        )
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-lg-4">
              <div className="d-flex">
                <h1 className="m-2">End: </h1>
                <input
                  className="form-control"
                  type="date"
                  placeholder="Select End Date"
                  value={
                    itemsList[0]?.expected_Details?.end_date
                      ? new Date(itemsList[0]?.expected_Details?.end_date)
                          .toISOString()
                          .split("T")[0]
                      : itemsList[0]?.expected_Details?.[0]?.end_date
                      ? new Date(itemsList[0]?.expected_Details?.[0]?.end_date)
                          .toISOString()
                          .split("T")[0]
                      : "N/A"
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex">
                <h1 className="m-2">Location: </h1>
                <input
                  className="form-control"
                  placeholder="Enter Location"
                  value={
                    itemsList[0]?.expected_Details?.location ||
                    itemsList[0]?.expected_Details?.[0]?.location ||
                    "N/A"
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex">
                <h1 className="m-2">Mobile: </h1>
                <input
                  className="form-control"
                  placeholder="Enter Mobile"
                  value={
                    itemsList[0]?.expected_Details?.official_mobile ||
                    itemsList[0]?.expected_Details?.[0]?.official_mobile ||
                    "N/A"
                  }
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <h2 className="text-center mb-4 fs-4">Bill Of Material</h2>

        {/* Input Form */}
        <div className="card p-3 mb-4">
          <div className="row g-3">
            <div className="col-md-3 position-relative">
              <label className="form-label">Description</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  name="annexure_description_one"
                  value={formData.annexure_description_one}
                  onChange={handleDescriptionSearch}
                  onFocus={() => setShowDropdown(true)}
                  placeholder="Enter or select description"
                  autoComplete="off"
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => setShowAddProductModal(true)}
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>

              {/* Dropdown for product selection */}
              {showDropdown && (
                <div
                  className="position-absolute w-100 mt-1 bg-white border rounded shadow-sm z-3"
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    zIndex: 1000,
                  }}
                >
                  {filteredProducts.length > 0 ? (
                    filteredProducts.map((product) => (
                      <div
                        key={product._id}
                        className="p-2 border-bottom hover-bg-light cursor-pointer"
                        onClick={() => selectProduct(product)}
                        style={{ cursor: "pointer" }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f8f9fa")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.backgroundColor = "")
                        }
                      >
                        {product.name}
                      </div>
                    ))
                  ) : (
                    <div className="p-2 text-center">No products found</div>
                  )}
                </div>
              )}
            </div>
            <div className="col-md-2">
              <label className="form-label">Quantity</label>
              <input
                type="number"
                className="form-control"
                name="annexure_qty_one"
                value={formData.annexure_qty_one}
                onChange={handleChange}
                placeholder="Qty"
              />
            </div>
            <div className="col-md-2">
              <label className="form-label">Basic Price</label>
              <input
                type="number"
                className="form-control"
                name="basicprice"
                value={formData.basicprice}
                onChange={handleChange}
                placeholder="Unit price"
              />
            </div>
            <div className="col-md-2">
              <label className="form-label">Tax (%)</label>
              <input
                type="number"
                className="form-control"
                name="tax"
                value={formData.tax}
                onChange={handleChange}
                placeholder="Tax %"
              />
            </div>
            <div className="col-md-3 d-flex align-items-end">
              <button className="btn btn-primary me-2" onClick={handleAddItem}>
                {isEditing ? "Update" : "Add"}
              </button>

              {isEditing && (
                <button className="btn btn-secondary" onClick={cancelEdit}>
                  Cancel
                </button>
              )}

              <button className="btn btn-success ms-2" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>

        {/* Items Table */}
        <div className="card p-3">
          <h5 className="card-title mb-3">Bill Items</h5>
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="table-light">
                <tr>
                  <th width="5%">S.No</th>
                  <th width="30%">Description</th>
                  <th width="10%">Quantity</th>
                  <th width="15%">Price</th>
                  <th width="10%">Tax (%)</th>
                  <th width="15%">Total</th>
                  <th width="15%">Actions</th>
                </tr>
              </thead>
              <tbody>
                {itemsList.length > 0 ? (
                  itemsList.map((item, index) => {
                    const isItemModified = tempItems.some(
                      (temp) => temp._id === item._id
                    );
                    const isItemTemp = item._id.toString().startsWith("temp-");

                    return (
                      <tr
                        key={item._id}
                        className={
                          isItemTemp
                            ? "table-secondary"
                            : isItemModified
                            ? "table-warning"
                            : ""
                        }
                      >
                        <td>{index + 1}</td>
                        <td>{item.annexure_description_one}</td>
                        <td>{item.annexure_qty_one}</td>
                        <td>${parseFloat(item.basicprice).toFixed(2)}</td>
                        <td>{item.tax}%</td>
                        <td>
                          $
                          {item.total ||
                            calculateTotal(
                              item.basicprice,
                              item.annexure_qty_one,
                              item.tax
                            )}
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-primary me-2"
                            onClick={() => handleEdit(item._id)}
                          >
                            <i className="fas fa-edit"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => confirmDelete(item._id)}
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No items available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Toast Notifications */}
        <ToastContainer position="top-right" autoClose={3000} />

        {/* Delete Confirmation Modal */}
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this item?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDelete}
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add New Product Modal */}
      <Modal
        className="addmodals"
        show={showAddProductModal}
        onHide={() => setShowAddProductModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>New Product</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleAddNewProduct}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    name="name"
                    value={newProductData.name}
                    onChange={handleNewProductChange}
                    required
                  />
                  <label className={newProductData.name ? "filled" : ""}>
                    Name
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    name="hsn_code"
                    value={newProductData.hsn_code}
                    onChange={handleNewProductChange}
                    required
                  />
                  <label className={newProductData.hsn_code ? "filled" : ""}>
                    HSN Code
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    name="brand_name"
                    value={newProductData.brand_name}
                    onChange={handleNewProductChange}
                    required
                  />
                  <label className={newProductData.brand_name ? "filled" : ""}>
                    Brand Name
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    name="description"
                    value={newProductData.description}
                    onChange={handleNewProductChange}
                    required
                  />
                  <label className={newProductData.description ? "filled" : ""}>
                    Description
                  </label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-select"
                    name="status"
                    value={newProductData.status}
                    onChange={handleNewProductChange}
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowAddProductModal(false)}
            >
              Cancel
            </Button>
            <Button className="submitbut" type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default OngoingBillMaterial;
