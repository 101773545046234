// import React, { useEffect, useRef, useState } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import axios from "axios";
// import ApiUrl from "../Environment/ApiUrl";
// import { Modal, Button } from "react-bootstrap";
// import "../../assets/css/AnnexurePreview.css";
// import { toast } from "react-toastify";
// const modules = {
//   toolbar: [
//     [{ header: "1" }, { header: "2" }, { font: [] }],
//     [{ size: [] }],
//     ["bold", "italic", "underline", "strike", "blockquote"],
//     [{ list: "ordered" }, { list: "bullet" }],
//     [{ indent: "-1" }, { indent: "+1" }],
//     [{ align: [] }],
//     [{ color: [] }, { background: [] }],
//     ["link", "image", "video"],
//     ["clean"],
//   ],
// };

// const formats = [
//   "header",
//   "font",
//   "size",
//   "bold",
//   "italic",
//   "underline",
//   "strike",
//   "blockquote",
//   "list",
//   "bullet",
//   "indent",
//   "link",
//   "image",
//   "video",
//   "color",
//   "background",
//   "align",
// ];

// function Annexure() {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const [responseData, setResponseData] = useState({});
//   const [responseDatas, setResponseDatas] = useState([]);
//   const [subTotal, setSubTotal] = useState(0);
//   const [discount, setDiscount] = useState(0);
//   const [netTotal, setNetTotal] = useState(0);

//   // Form state
//   const defaultAddData = {
//     expected_id: "",
//     quotation_no: "",
//     date: new Date().toISOString().split("T")[0],
//     valid_tilldate: "",
//     type: "",
//     name: "",
//     official_mobile: "",
//     official_email: "",
//     gstin: "",
//     location: "",
//     staff_id: "",
//     staff_name: "",
//     project_description: "",
//     annexure_description_one: "",
//     annexure_qty_one: "",
//     basic_price: "",
//     tax: "",
//     total_price: "",
//     subtotal: "",
//     sgst: "",
//     cgst: "",
//     discount: "",
//     nettotal: "",
//     paytype: "",
//     annexure_description_two: "",
//     annexure_qty_two: "",
//     make: "",
//     terms_and_conditions: "",
//   };

//   useEffect(() => {
//     const fetchQuotationNumber = async () => {
//       try {
//         const response = await axios.get(`${ApiUrl}/quotation/latest`);
//         setAddData((prevData) => ({
//           ...prevData,
//           quotation_no: response.data.quotation_no,
//           date: new Date().toISOString().split("T")[0],
//         }));
//       } catch (error) {
//         console.error("Error fetching quotation number:", error);
//       }
//     };

//     fetchQuotationNumber();
//   }, []);

//   const [addData, setAddData] = useState(defaultAddData);
//   const [formData, setFormData] = useState({
//     annexure_description_one: "",
//     annexure_qty_one: "",
//     basicprice: "",
//     tax: "",
//   });

//   // Fetch data when component mounts or ID changes
//   useEffect(() => {
//     if (id) {
//       fetchData(id);
//     }
//   }, [id]);

//   // Calculate totals when responseDatas or discount changes
//   useEffect(() => {
//     const newSubTotal = responseDatas.reduce(
//       (sum, item) => sum + (item.total || 0),
//       0
//     );
//     setSubTotal(newSubTotal);

//     const discountAmount = (newSubTotal * discount) / 100;
//     setNetTotal(newSubTotal - discountAmount);
//   }, [responseDatas, discount]);

//   const fetchData = async (id) => {
//     try {
//       const response = await axios.get(`${ApiUrl}/expected/${id}`);
//       if (response.data) {
//         setResponseData(response.data);
//         setResponseDatas(
//           Array.isArray(response.data.items) ? response.data.items : []
//         );
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const handleDiscountChange = (e) => {
//     setDiscount(parseFloat(e.target.value) || 0);
//   };

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     console.log("gwqekqnelq", formData.annexure_description_one);
//   };

//   const handleAddDataChange = (e) => {
//     setAddData({ ...addData, [e.target.name]: e.target.value });
//   };

//   const handleAdd = () => {
//     const annexure_qty_one = parseFloat(formData.annexure_qty_one) || 0;
//     const basicprice = parseFloat(formData.basicprice) || 0;
//     const taxRate = parseFloat(formData.tax) || 0;
//     const taxAmount = (basicprice * annexure_qty_one * taxRate) / 100;
//     const total = annexure_qty_one * basicprice + taxAmount;

//     setResponseDatas([
//       ...responseDatas,
//       {
//         annexure_description_one: formData.annexure_description_one,
//         annexure_qty_one,
//         basicprice,
//         tax: taxRate,
//         taxAmount,
//         total,
//       },
//     ]);
//     setFormData({
//       annexure_description_one: "",
//       annexure_qty_one: "",
//       basicprice: "",
//       tax: "",
//     });
//   };

//   const handleClear = () => {
//     setFormData({
//       annexure_description_one: "",
//       annexure_qty_one: "",
//       basicprice: "",
//       tax: "",
//     });
//     setResponseDatas([]);
//     setSubTotal(0);
//     setDiscount(0);
//     setNetTotal(0);
//     setAddData(defaultAddData);
//   };
//   const [activeStep, setActiveStep] = useState(0);
//   const [editorContent, setEditorContent] = useState("");

//   // Handlers
//   const handleBack = () => {
//     if (activeStep > 0) setActiveStep((prevStep) => prevStep - 1);
//   };

//   const handleSubmit = async () => {
//     console.error("Invalid responseData: U", editorContent);
//     console.error(
//       "Invalid responseData",
//       responseDatass[0]?.annexure_description_two
//     );

//     try {
//       const formattedSubTotal = subTotal.toFixed(2);
//       const formattedNetTotal = netTotal.toFixed(2);
//       const name_id = responseData._id;
//       const cleanedFormData = {
//         expected_id: id,
//         name_id: name_id,
//         name: responseData.name || "",
//         official_mobile: responseData.official_mobile || "",
//         official_email: responseData.official_email || "",
//         gstin: responseData.gstin || addData.gstin || "",
//         location: responseData.location || "",
//         quotation_no: addData.quotation_no || "",
//         date: addData.date || new Date().toISOString().split("T")[0],
//         valid_tilldate: addData.valid_tilldate || "",
//         staff_id: addData.staff_id || "",
//         staff_name: addData.staff_name || "",
//         project_description: addData.project_description || "",
//         subtotal: formattedSubTotal,
//         sgst: addData.sgst || "0",
//         cgst: addData.cgst || "0",
//         discount: discount.toString() || "0",
//         nettotal: formattedNetTotal,
//         paytype: addData.paytype || "",
//         type: addData.type || "Project",
//         terms_and_conditions: editorContent,
//         items: responseDatas.map((item) => ({
//           annexure_description_one: item.annexure_description_one || "",
//           annexure_qty_one: parseFloat(item.annexure_qty_one) || 0,
//           basicprice: parseFloat(item.basicprice) || 0,
//           tax: parseFloat(item.tax) || 0,
//           taxAmount: parseFloat(item.taxAmount) || 0,
//           total: parseFloat(item.total).toFixed(2),
//         })),
//         itemss: responseDatass.map((item) => ({
//           annexure_description_two: item.annexure_description_two || "",
//           annexure_qty_two: parseFloat(item.annexure_qty_two) || 0,
//           make: item.make || "",
//         })),
//       };

//       console.log("Sending data:", cleanedFormData);

//       const response = await axios.post(
//         `${ApiUrl}/quotation_list`,
//         cleanedFormData
//       );
//       const formData = {
//         expected_id: id,
//         quotation_no: addData.quotation_no || "",
//         project_description: addData.project_description || "",
//         date: addData.date || new Date().toISOString().split("T")[0],
//         nettotal: formattedNetTotal,
//         name_id: name_id,
//         name: responseData.name || "",
//         official_mobile: responseData.official_mobile || "",
//         official_email: responseData.official_email || "",
//       };
//       console.log("the histroy beginssssss====", formData);

//       const response12 = await axios.post(
//         ApiUrl + "/quotation_history",
//         formData
//       );
//       console.log("the histroy begins", response12);
//       if (response.data) {
//         console.log("Successfully posted quotation data", response.data);
//         toast.success("Quotation Successfully Created");

//         navigate("/upcomings");
//       } else {
//         alert("Server returned empty response. Please try again.");
//       }
//     } catch (error) {
//       console.error("Error adding data:", error);
//     }
//   };

//   const [isChecked, setIsChecked] = useState(false);

//   const handleDelete = (indexs) => {
//     setResponseDatas(responseDatas.filter((_, i) => i !== indexs));
//   };

//   //   //////////////////////////////////////////////////////////////////

//   const [responseDatass, setResponseDatass] = useState([]);
//   const [formDatas, setFormDatas] = useState({
//     annexure_description_two: "",
//     annexure_qty_two: "",
//     make: "",
//   });

//   useEffect(() => {
//     if (id) {
//       fetchData(id);
//     }
//   }, [id]);

//   const handleChanges = (e) => {
//     setFormDatas({ ...formDatas, [e.target.name]: e.target.value });
//     console.log("gwqekqnelq", formDatas.annexure_description_two);
//   };

//   const handleAdds = () => {
//     const newItem = {
//       annexure_description_two: formDatas.annexure_description_two,
//       annexure_qty_two: parseFloat(formDatas.annexure_qty_two) || 0,
//       make: formDatas.make,
//     };

//     setResponseDatass([...responseDatass, newItem]);
//     setFormDatas({
//       annexure_description_two: "",
//       annexure_qty_two: "",
//       make: "",
//     });
//   };

//   const handleDeletes = (indexs) => {
//     setResponseDatass(responseDatass.filter((_, i) => i !== indexs));
//   };

//   //   ////////////////////////////////////////////////////////////////

//   // New state variables for product dropdown
//   const [products, setProducts] = useState([]);
//   const [filteredProducts, setFilteredProducts] = useState([]);
//   const [showDropdown, setShowDropdown] = useState(false);
//   const [searchTerm, setSearchTerm] = useState("");
//   const dropdownRef = useRef(null);

//   // State for modal
//   const [showModal, setShowModal] = useState(false);
//   const [newProduct, setNewProduct] = useState({
//     name: "",
//     description: "",
//     price: "",
//     hsn_code: "",
//     brand_name: "",
//     status: "",
//   });

//   // Fetch products on component mount
//   useEffect(() => {
//     fetchProducts();
//   }, []);

//   // Close dropdown when clicking outside
//   useEffect(() => {
//     function handleClickOutside(event) {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setShowDropdown(false);
//       }
//     }

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [dropdownRef]);

//   // Fetch products from API
//   const fetchProducts = async () => {
//     try {
//       const response = await axios.get(`${ApiUrl}/product`);
//       setProducts(response.data);
//       setFilteredProducts(response.data);
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     }
//   };

//   // Handle search input change
//   const handleSearchChange = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);

//     const filtered = products.filter(
//       (product) =>
//         product.name.toLowerCase().includes(term.toLowerCase()) ||
//         product.description.toLowerCase().includes(term.toLowerCase())
//     );

//     setFilteredProducts(filtered);
//   };

//   // Select product from dropdown
//   const selectProduct = (product) => {
//     setFormDatas({
//       ...formDatas,
//       annexure_description_two: product.name,
//       // You can also set make or other fields if available in the product data
//     });
//     setShowDropdown(false);
//     setSearchTerm("");
//   };

//   // Handle new product input change
//   const handleNewProductChange = (e) => {
//     setNewProduct({
//       ...newProduct,
//       [e.target.name]: e.target.value,
//     });
//   };

//   // Submit new product
//   const handleSubmitProduct = async () => {
//     try {
//       const response = await axios.post(`${ApiUrl}/product`, newProduct);
//       console.log("Product added:", response.data);
//       await fetchProducts();
//       setShowModal(false);
//       setFormDatas({
//         ...formDatas,
//         annexure_description_two: newProduct.name,
//       });

//       // Reset new product form
//       setNewProduct({
//         name: "",
//         description: "",
//         price: "",
//       });

//       toast.success("Product added successfully!");
//     } catch (error) {
//       console.error("Error adding product:", error);
//       toast.error("Failed to add product");
//     }
//   };

//   //   ////////////////////////////////////////////////////////////////////////

//   const defaultMessage = `
//       <h3>Terms & Conditions</h3>
//       <h3>DELIVERY:</h3>
//       <p>Ex-works delivery 30 working days from the date of receipt of drawing approval. Drawing submission will be done within 7 working days from the date of receipt of the contract coming into force, i.e., receipt of a techno-commercially clear order along with advance where applicable.</p>

//       <h3>PRICE BASE:</h3>
//       <p>Ex-Works, Hosur, and firm within the validity period of work, i.e., up to mutually agreed terms.</p>

//       <h3>PAYMENT TERMS:</h3>
//       <p>90% payment advance on value along with PO as mentioned in perform invoice for material which
//       includes tax. Balance 10% within 7 Days from the date of Material received.</p>

//       <h3>CONTRACT COMING INTO FORCE:</h3>
//       <p>After fulfilling all the following conditions: receipt of confirmed Purchase order, receipt of advance
//       payment & clarification of all technical and commercial points.</p>

//       <h3>FREIGHT:</h3>
//       <p>Freight charges shall be as per expense.</p>

//       <h3>INSURANCE:</h3>
//       <p>Shall be extra cost at actual.</p>

//       <h3>RISK PURCHASE:</h3>
//       <p>CONSEQUENTIAL DAMAGES/LATENT DEFECTS: Shall not be acceptable.</p>

//       <h3>Yours faithfully,</h3>
//       <p>Aravinth</p>
//     `;

//   useEffect(() => {
//     setEditorContent(defaultMessage);
//   }, []);

//   // //////////////////////////////////////////////////////////////////////////

//   return (
//     <>
//       <div style={{ marginTop: "-80px" }}>
//         <div className="row mb-3">
//           <div className="col-lg-11">
//             <Link to="/dashboard" className="text-secondary">
//               <b>Dashboard / </b>
//             </Link>
//             <Link to="/staff" className="text-secondary">
//               <b>Staff / </b>
//             </Link>
//             <Link to="/addstaff" className="text-danger">
//               <b>Add Staff</b>
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className="container mt-3">
//         <div className="row mt-3">
//           {activeStep === 0 && (
//             <>
//               <div className="card p-3">
//                 <div className="row">
//                   <div className="col-lg-5"></div>
//                   <div className="col-lg-3">
//                     <h5
//                       className="head"
//                       style={{ textDecoration: "underline" }}
//                     >
//                       New Quotation
//                     </h5>
//                   </div>
//                   <div className="col-lg-3 mt-2">
//                     <select
//                       className="form-control"
//                       style={{ width: "150px" }}
//                       name="type"
//                       value={addData.type}
//                       onChange={handleAddDataChange}
//                     >
//                       <option value="">Select dropdown</option>
//                       <option value="Project">Project</option>
//                       <option value="Sales">Sales / Service</option>
//                     </select>
//                   </div>
//                   <div className="col-lg-1"></div>
//                 </div>

//                 {/* First row */}
//                 <div className="row first">
//                   <div className="col-lg-3">
//                     <label>Quotation No:</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       name="quotation_no"
//                       value={addData.quotation_no}
//                       disabled
//                       readOnly // Prevent manual input
//                     />
//                   </div>

//                   <div className="col-lg-4">
//                     <label>Date:</label>
//                     <input
//                       type="date"
//                       name="date"
//                       className="form-control"
//                       value={addData.date}
//                       onChange={handleAddDataChange}
//                     />
//                   </div>

//                   <div className="col-lg-4">
//                     <label>Valid Till Date:</label>
//                     <input
//                       type="date"
//                       name="valid_tilldate"
//                       value={addData.valid_tilldate}
//                       onChange={handleAddDataChange}
//                       className="form-control"
//                     />
//                   </div>
//                 </div>
//               </div>

//               {/* Client and Staff Details */}
//               <div className="card p-3 mt-3">
//                 <div className="row second">
//                   <div className="col-lg-6">
//                     <h1>Client Details (Quotation for)</h1>
//                     <div className="row">
//                       <div
//                         className="col-lg-5 col-md-5 col-sm-12"
//                         style={{ textAlign: "left" }}
//                       >
//                         <p>Client Business Name</p>
//                       </div>
//                       <div
//                         className="col-lg-7 col-md-7 col-sm-12"
//                         style={{ textAlign: "right" }}
//                       >
//                         <input
//                           style={{ width: "80%" }}
//                           type="text"
//                           value={responseData.name || ""}
//                           disabled
//                           className="form-control"
//                         />
//                       </div>

//                       <div
//                         className="col-lg-5 col-md-5 col-sm-12"
//                         style={{ textAlign: "left" }}
//                       >
//                         <p> Mobile / Contact</p>
//                       </div>
//                       <div
//                         className="col-lg-7 col-md-7 col-sm-12"
//                         style={{ textAlign: "right" }}
//                       >
//                         <input
//                           style={{ width: "80%" }}
//                           type="text"
//                           value={responseData.official_mobile || ""}
//                           disabled
//                           className="form-control"
//                         />
//                       </div>

//                       <div
//                         className="col-lg-5 col-md-5 col-sm-12"
//                         style={{ textAlign: "left" }}
//                       >
//                         <p>Official Email</p>
//                       </div>
//                       <div
//                         className="col-lg-7 col-md-7 col-sm-12"
//                         style={{ textAlign: "right" }}
//                       >
//                         <input
//                           style={{ width: "80%" }}
//                           type="text"
//                           value={responseData.official_email || ""}
//                           disabled
//                           className="form-control"
//                         />
//                       </div>

//                       <div
//                         className="col-lg-5 col-md-5 col-sm-12"
//                         style={{ textAlign: "left" }}
//                       >
//                         <p>GSTIN</p>
//                       </div>
//                       <div
//                         className="col-lg-7 col-md-7 col-sm-12"
//                         style={{ textAlign: "right" }}
//                       >
//                         <input
//                           style={{ width: "80%" }}
//                           type="text"
//                           name="gstin"
//                           value={addData.gstin || responseData.gstin || ""}
//                           onChange={handleAddDataChange}
//                           className="form-control"
//                         />
//                       </div>

//                       <div
//                         className="col-lg-5 col-md-5 col-sm-12"
//                         style={{ textAlign: "left" }}
//                       >
//                         <p>Address</p>
//                       </div>
//                       <div
//                         className="col-lg-7 col-md-7 col-sm-12"
//                         style={{ textAlign: "right" }}
//                       >
//                         <textarea
//                           style={{ width: "80%" }}
//                           value={responseData.location || ""}
//                           disabled
//                           className="form-control"
//                         />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="col-lg-6">
//                     <h1> Quotation (Created By)</h1>
//                     <div className="row">
//                       <div
//                         className="col-lg-5 col-md-5 col-sm-12"
//                         style={{ textAlign: "left" }}
//                       >
//                         <p>Staff ID</p>
//                       </div>
//                       <div
//                         className="col-lg-7 col-md-7 col-sm-12"
//                         style={{ textAlign: "right" }}
//                       >
//                         <input
//                           style={{ width: "80%" }}
//                           type="text"
//                           name="staff_id"
//                           value={responseData.assign_staff?.empid || ""}
//                           onChange={handleAddDataChange}
//                           className="form-control"
//                         />
//                       </div>

//                       <div
//                         className="col-lg-5 col-md-5 col-sm-12"
//                         style={{ textAlign: "left" }}
//                       >
//                         <p>Staff Name</p>
//                       </div>
//                       <div
//                         className="col-lg-7 col-md-7 col-sm-12"
//                         style={{ textAlign: "right" }}
//                       >
//                         <input
//                           style={{ width: "80%" }}
//                           type="text"
//                           name="staff_name"
//                           value={responseData.assign_staff?.name || ""}
//                           onChange={handleAddDataChange}
//                           className="form-control"
//                         />
//                       </div>
//                       <div className="" style={{ textAlign: "left" }}>
//                         <p>Project Description : </p>
//                       </div>
//                       <div className="" style={{ textAlign: "right" }}>
//                         <textarea
//                           style={{ width: "80%" }}
//                           name="project_description"
//                           value={addData.project_description || ""}
//                           onChange={handleAddDataChange}
//                           className="form-control"
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               {/* Annexure section */}
//               <div className="card p-2 mt-3">
//                 <div className="text-center mt-4 mb-3">
//                   <h1 className="fs-5">Annexure 1: Quotation</h1>
//                 </div>

//                 <div className="container mb-3">
//                   <div className="row">
//                     <div className="col-lg-4">
//                       <label>Description</label>
//                       <input
//                         className="form-control"
//                         name="annexure_description_one"
//                         value={formData.annexure_description_one}
//                         onChange={handleChange}
//                       />
//                     </div>
//                     <div className="col-lg-2">
//                       <label>Qty</label>
//                       <input
//                         className="form-control"
//                         name="annexure_qty_one"
//                         type="number"
//                         value={formData.annexure_qty_one}
//                         onChange={handleChange}
//                       />
//                     </div>
//                     <div className="col-lg-2">
//                       <label>Basic Price</label>
//                       <input
//                         className="form-control"
//                         name="basicprice"
//                         type="number"
//                         value={formData.basicprice}
//                         onChange={handleChange}
//                       />
//                     </div>

//                     <div className="col-lg-2 position-relative">
//                       <label>Tax</label>
//                       <div className="position-relative">
//                         <input
//                           className="form-control pe-4"
//                           name="tax"
//                           type="number"
//                           value={formData.tax}
//                           onChange={handleChange}
//                           style={{ paddingRight: "25px" }}
//                         />
//                         <span
//                           style={{
//                             position: "absolute",
//                             right: "10px",
//                             top: "50%",
//                             transform: "translateY(-50%)",
//                             pointerEvents: "none",
//                             color: "#777",
//                           }}
//                         >
//                           %
//                         </span>
//                       </div>
//                     </div>

//                     <div className="col-lg-2">
//                       <button
//                         className="btn btn-success btn-sm mt-4"
//                         onClick={handleAdd}
//                       >
//                         Add
//                       </button>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Table of items */}
//                 <div className="card p-3">
//                   <div className="table-responsive">
//                     <table className="table table-bordered">
//                       <thead className="thead-dark">
//                         <tr>
//                           <th>S.NO</th>
//                           <th>Description</th>
//                           <th>Qty</th>
//                           <th>Basic Price</th>
//                           <th>Tax</th>
//                           <th>Total</th>
//                           <th>Action</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {responseDatas.length > 0 ? (
//                           responseDatas.map((item, indexs) => (
//                             <tr key={indexs}>
//                               <td>{indexs + 1}</td>
//                               <td>{item.annexure_description_one}</td>
//                               <td>{item.annexure_qty_one}</td>
//                               <td>{item.basicprice}</td>
//                               <td>{item.tax}%</td>
//                               <td>
//                                 {typeof item.total === "number"
//                                   ? item.total.toFixed(2)
//                                   : "0.00"}
//                               </td>
//                               <td>
//                                 <i
//                                   className="fa-solid fa-circle-xmark mx-2 fs-4"
//                                   style={{ color: "red", cursor: "pointer" }}
//                                   onClick={() => handleDelete(indexs)}
//                                 ></i>
//                               </td>
//                             </tr>
//                           ))
//                         ) : (
//                           <tr>
//                             <td colSpan="7" className="text-center">
//                               No items added
//                             </td>
//                           </tr>
//                         )}
//                       </tbody>
//                     </table>
//                   </div>
//                 </div>

//                 {/* Totals section */}
//                 <div className="row second">
//                   <div className="col-lg-6"></div>
//                   <div className="col-lg-6">
//                     <div className="row">
//                       <div
//                         className="col-lg-5 col-md-5 col-sm-12"
//                         style={{ textAlign: "left" }}
//                       >
//                         <p>Sub Total</p>
//                       </div>
//                       <div
//                         className="col-lg-7 col-md-7 col-sm-12"
//                         style={{ textAlign: "right" }}
//                       >
//                         <input
//                           style={{ width: "80%" }}
//                           type="text"
//                           value={subTotal.toFixed(2)}
//                           readOnly
//                           className="form-control"
//                         />
//                       </div>

//                       <div
//                         className="col-lg-5 col-md-5 col-sm-12"
//                         style={{ textAlign: "left" }}
//                       >
//                         <p>SGST</p>
//                       </div>
//                       <div
//                         className="col-lg-7 col-md-7 col-sm-12"
//                         style={{ textAlign: "right" }}
//                       >
//                         <input
//                           style={{ width: "80%" }}
//                           name="sgst"
//                           value={addData.sgst}
//                           onChange={handleAddDataChange}
//                           type="number"
//                           className="form-control"
//                         />
//                       </div>

//                       <div
//                         className="col-lg-5 col-md-5 col-sm-12"
//                         style={{ textAlign: "left" }}
//                       >
//                         <p>CGST</p>
//                       </div>
//                       <div
//                         className="col-lg-7 col-md-7 col-sm-12"
//                         style={{ textAlign: "right" }}
//                       >
//                         <input
//                           style={{ width: "80%" }}
//                           name="cgst"
//                           value={addData.cgst}
//                           onChange={handleAddDataChange}
//                           type="number"
//                           className="form-control"
//                         />
//                       </div>

//                       <div
//                         className="col-lg-5 col-md-5 col-sm-12"
//                         style={{ textAlign: "left" }}
//                       >
//                         <p>Discount (%)</p>
//                       </div>
//                       <div
//                         className="col-lg-7 col-md-7 col-sm-12"
//                         style={{ textAlign: "right" }}
//                       >
//                         <div
//                           style={{
//                             position: "relative",
//                             display: "inline-block",
//                             width: "80%",
//                           }}
//                         >
//                           <input
//                             style={{ width: "100%", marginLeft: "-67px" }}
//                             type="number"
//                             value={discount}
//                             onChange={handleDiscountChange}
//                             className="form-control"
//                           />
//                           <span
//                             style={{
//                               position: "absolute",
//                               right: "100px",
//                               top: "50%",
//                               transform: "translateY(-50%)",
//                               pointerEvents: "none",
//                               color: "#777",
//                             }}
//                           >
//                             %
//                           </span>
//                         </div>
//                       </div>

//                       <div
//                         className="col-lg-5 col-md-5 col-sm-12"
//                         style={{ textAlign: "left" }}
//                       >
//                         <p>Net Total</p>
//                       </div>
//                       <div
//                         className="col-lg-7 col-md-7 col-sm-12"
//                         style={{ textAlign: "right" }}
//                       >
//                         <input
//                           style={{ width: "80%" }}
//                           type="text"
//                           value={netTotal.toFixed(2)}
//                           readOnly
//                           className="form-control"
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-lg-6"></div>
//                 <div className="col-lg-6">
//                   <div
//                     style={{ marginLeft: "75px" }}
//                     className="d-flex justify-content-evenly mt-3 buttonstyle"
//                   >
//                     <button
//                       type="button"
//                       className="btn btn-danger invoice-button Add"
//                       onClick={handleClear}
//                     >
//                       Clear
//                     </button>

//                     <button
//                       type="button"
//                       className="btn btn-success invoice-button Add"
//                       onClick={() => navigate("/upcomings")}
//                     >
//                       Back
//                     </button>
//                     <button
//                       onClick={() => setActiveStep(1)}
//                       className="btn btn-secondary Add mx-2"
//                       variant="secondary"
//                     >
//                       Next
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </>
//           )}

//           {/* Step 2 - Bill of Materials */}
//           {activeStep === 1 && (
//             <>
//               <div className="mt-4 mb-3">
//                 <h1 className="fs-5 text-center">
//                   Annexure 2: Bill Of Materials
//                 </h1>
//               </div>

//               <div className="container mb-3">
//                 <div className="row">
//                   <div className="col-lg-4">
//                     <label>Description</label>
//                     <div className="position-relative">
//                       <div className="input-group">
//                         <input
//                           className="form-control"
//                           name="annexure_description_two"
//                           value={
//                             searchTerm || formDatas.annexure_description_two
//                           }
//                           onChange={(e) => {
//                             handleSearchChange(e);
//                             setFormDatas({
//                               ...formDatas,
//                               annexure_description_two: e.target.value,
//                             });
//                           }}
//                           onClick={() => setShowDropdown(true)}
//                           placeholder="Search or select a product"
//                         />
//                         <button
//                           className="btn btn-outline-secondary"
//                           type="button"
//                           onClick={() => setShowModal(true)}
//                         >
//                           <i className="fa fa-plus"></i>
//                         </button>
//                       </div>

//                       {showDropdown && (
//                         <div
//                           className="dropdown-menu show w-100"
//                           style={{ maxHeight: "200px", overflow: "auto" }}
//                         >
//                           {filteredProducts.length > 0 ? (
//                             filteredProducts.map((product, index) => (
//                               <button
//                                 key={index}
//                                 className="dropdown-item"
//                                 type="button"
//                                 onClick={() => selectProduct(product)}
//                               >
//                                 {product.name}
//                               </button>
//                             ))
//                           ) : (
//                             <div className="dropdown-item">
//                               No products found
//                             </div>
//                           )}
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                   <div className="col-lg-2">
//                     <label>Qty</label>
//                     <input
//                       className="form-control"
//                       name="annexure_qty_two"
//                       type="number"
//                       value={formDatas.annexure_qty_two}
//                       onChange={handleChanges}
//                     />
//                   </div>
//                   <div className="col-lg-2">
//                     <label>Make</label>
//                     <input
//                       className="form-control"
//                       name="make"
//                       type="text"
//                       value={formDatas.make}
//                       onChange={handleChanges}
//                     />
//                   </div>
//                   <div className="col-lg-2">
//                     <button
//                       className="btn btn-success btn-sm mt-4"
//                       onClick={handleAdds}
//                     >
//                       Add
//                     </button>
//                   </div>
//                 </div>
//               </div>

//               <div className="card p-3">
//                 <div className="table-responsive">
//                   <table className="table table-bordered">
//                     <thead className="thead-dark">
//                       <tr>
//                         <th>S.NO</th>
//                         <th>Description</th>
//                         <th>Qty</th>
//                         <th>Make</th>
//                         <th>Action</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {responseDatass.length > 0 ? (
//                         responseDatass.map((item, index) => (
//                           <tr key={index}>
//                             <td>{index + 1}</td>
//                             <td>{item.annexure_description_two}</td>
//                             <td>{item.annexure_qty_two}</td>
//                             <td>{item.make}</td>
//                             <td>
//                               <i
//                                 className="fa-solid fa-circle-xmark mx-2 fs-4"
//                                 style={{ color: "red", cursor: "pointer" }}
//                                 onClick={() => handleDeletes(index)}
//                               ></i>
//                             </td>
//                           </tr>
//                         ))
//                       ) : (
//                         <tr>
//                           <td colSpan="4" className="text-center">
//                             No items added
//                           </td>
//                         </tr>
//                       )}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>

//               <div className="text-end mt-3">
//                 <button
//                   className="btn btn-secondary btn-sm mx-3"
//                   onClick={handleBack}
//                 >
//                   Back
//                 </button>
//                 <button
//                   className="btn btn-secondary btn-sm"
//                   onClick={() => setActiveStep(2)}
//                 >
//                   Next
//                 </button>
//               </div>
//             </>
//           )}

//           {/* Step 3 - Editor */}
//           {activeStep === 2 && (
//             <>
//               <ReactQuill
//                 theme="snow"
//                 value={editorContent}
//                 onChange={setEditorContent}
//                 modules={modules}
//                 formats={formats}
//                 placeholder="Write something amazing..."
//                 style={{ height: "300px", marginBottom: "20px" }}
//               />

//               <h3 className="mt-5">Editor Output:</h3>
//               <div
//                 className="border p-3"
//                 style={{ minHeight: "100px", background: "#f8f9fa" }}
//                 dangerouslySetInnerHTML={{ __html: editorContent }}
//               />

//               <div className="row mt-3">
//                 <div className="col-lg-10"></div>
//                 <div className="col-lg-2">
//                   <div className="form-check">
//                     <input
//                       type="checkbox"
//                       id="agreeCheckbox"
//                       className="form-check-input"
//                       checked={isChecked}
//                       onChange={(e) => setIsChecked(e.target.checked)}
//                     />
//                     <label
//                       htmlFor="agreeCheckbox"
//                       className="form-check-label ms-2"
//                     >
//                       Agree to proceed
//                     </label>
//                   </div>
//                   <div className="d-flex">
//                     <button
//                       className="btn btn-secondary btn-sm mx-3"
//                       onClick={handleBack}
//                     >
//                       Back
//                     </button>
//                     <button
//                       className={`btn btn-secondary btn-sm ${
//                         !isChecked ? "disabled opacity-50" : ""
//                       }`}
//                       onClick={() => isChecked && setActiveStep(3)}
//                       disabled={!isChecked}
//                     >
//                       Next
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </>
//           )}

//           {/* Step 4 - Review & Submit */}
//           {activeStep === 3 && (
//             <>
//               <h4>Review and Confirm</h4>
//               <div className="row mb-5">
//                 <div className="col-lg-8"></div>
//                 <div className="col-lg-4">
//                   <div className="d-flex">
//                     <button
//                       className="btn btn-secondary Add btn-sm mx-3"
//                       onClick={handleBack}
//                     >
//                       Back
//                     </button>

//                     <button
//                       className="btn btn-secondary Add btn-sm mx-3"
//                       onClick={handleSubmit}
//                     >
//                       Submit
//                     </button>
//                   </div>
//                 </div>
//               </div>

//               <div className="box_border">
//                 <div>
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       gap: "20px",
//                       padding: "10px",
//                     }}
//                   >
//                     <img
//                       alt="invoice logo"
//                       style={{
//                         width: "400px",
//                         objectFit: "contain",
//                         marginLeft: "-30px",
//                       }}
//                       src={require("../../assets/img/scimitar logo slogan.png")}
//                     />
//                     <div className="horizontal"></div>
//                     <div style={{ textAlign: "left" }}>
//                       <h6 className="fs-5 bold text-center">Our Services</h6>
//                       <h6>* ALL PURPOSE MACHINE RETROFIT</h6>
//                       <h6>* PLC, HMI, SCADA, VFD PROGRAMMING</h6>
//                       <h6>* ELECTRICAL AND PLC CONTROL PANEL DISTRIBUTOR</h6>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="bordertop p-2">
//                   <div className="d-flex">
//                     <div style={{ width: "60%" }}>
//                       <h6>To: </h6>
//                       <h6>CONTACT PERSON: </h6>
//                       <h6>PH: </h6>
//                       <h6>EMAIL: </h6>
//                     </div>

//                     <div className="second_row_start " style={{ width: "20%" }}>
//                       <div
//                         className="border_down serial_side"
//                         style={{ height: "40px" }}
//                       >
//                         <h6 className="">SERIAL NO</h6>
//                       </div>
//                       <div
//                         className="border_down serial_side"
//                         style={{ height: "40px" }}
//                       >
//                         <h6 className="">Date</h6>
//                       </div>
//                       <div className="serial_side" style={{ height: "40px" }}>
//                         <h6 className="">Validity Till</h6>
//                       </div>
//                     </div>
//                     <div className="second_row_start" style={{ width: "20%" }}>
//                       <div
//                         className="border_down serial_side"
//                         style={{ height: "40px" }}
//                       >
//                         <h6 className="">{addData.quotation_no}</h6>
//                       </div>
//                       <div
//                         className="border_down serial_side"
//                         style={{ height: "40px" }}
//                       >
//                         <h6 className="">{addData.date}</h6>
//                       </div>
//                       <div className="serial_side" style={{ height: "40px" }}>
//                         <h6 className="">{addData.valid_tilldate}</h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="bordertop p-2" style={{ height: "150px" }}>
//                   <h6>Project Description:</h6>
//                   <p>{addData.project_description}</p>
//                 </div>
//                 <div className="p-2">
//                   <h6>ANNEXURE 1: QUOTATION</h6>
//                   <h6>ANNEXURE 2: BILL OF MATERIALS</h6>
//                   <h6>ANNEXURE 3: TERMS AND CONDITIONS</h6>
//                   <p className="mt-5">
//                     We hope you find the offer suitable for your needs.
//                     <br />
//                     Thank you, and we assure our best services at all times.
//                   </p>
//                 </div>
//                 <h6 className="p-2">ANNEXURE 1: QUOTATION</h6>
//                 <table className="bordertop">
//                   <thead>
//                     <tr className="tbody_line text-center total_leftLine">
//                       <th className="p-2 total_leftLine">S.No</th>
//                       <th className="total_leftLine">DESCRIPTION</th>
//                       <th className="total_leftLine">QTY</th>
//                       <th className="total_leftLine">BASIC PRICE</th>
//                       <th className="total_leftLine">Tax Amount</th>
//                       <th className="total_leftLine">Total</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {responseDatas.length > 0 ? (
//                       responseDatas.map((item, index) => (
//                         <tr key={index}>
//                           <td className="total_leftLine text-center">
//                             {index + 1}
//                           </td>
//                           <td className="total_leftLine text-center">
//                             {item.annexure_description_one}
//                           </td>
//                           <td className="total_leftLine text-center">
//                             {item.annexure_qty_one}
//                           </td>
//                           <td className="total_leftLine text-center">
//                             {item.basicprice}
//                           </td>
//                           <td className="total_leftLine text-center">
//                             {item.tax}
//                           </td>
//                           <td className="total_leftLine text-center">
//                             {item.total}
//                           </td>
//                         </tr>
//                       ))
//                     ) : (
//                       <tr>
//                         <td colSpan="4" className="text-center">
//                           No items added
//                         </td>
//                       </tr>
//                     )}
//                     <tr className="tbody_line text-center">
//                       <td className="total_leftLine" colSpan="2">
//                         Total
//                       </td>
//                       <td className="total_leftLine">0</td>
//                       <td className="total_leftLine">0</td>
//                       <td className="total_leftLine">0</td>
//                       <td>{addData.nettotal}</td>
//                     </tr>
//                   </tbody>
//                 </table>

//                 <table className="bordertop mt-5">
//                   <thead>
//                     <tr className="tbody_line text-center">
//                       <td className="total_leftLine" colSpan="2">
//                         Discount
//                       </td>
//                       <td className="total_leftLine">{addData.discount}</td>
//                       <td className="total_leftLine">0</td>
//                       <td className="total_leftLine">0</td>
//                       <td className="total_leftLine">0</td>

//                       <td>{addData.nettotal}</td>
//                     </tr>
//                   </thead>
//                 </table>

//                 <h6 className="p-2 mt-4">ANNEXURE 2: BILL OF MATERIALS</h6>
//                 <table className="bordertop">
//                   <thead>
//                     <tr className="tbody_line text-center total_leftLine">
//                       <th className="p-2 total_leftLine">S.No</th>
//                       <th className="total_leftLine">DESCRIPTION</th>
//                       <th className="total_leftLine">QTY</th>
//                       <th className="total_leftLine">MAKE</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {responseDatass.length > 0 ? (
//                       responseDatass.map((item, index) => (
//                         <tr key={index}>
//                           <td className="total_leftLine text-center">
//                             {index + 1}
//                           </td>
//                           <td className="total_leftLine text-center">
//                             {item.annexure_description_two}
//                           </td>
//                           <td className="total_leftLine text-center">
//                             {item.annexure_qty_two}
//                           </td>
//                           <td className="total_leftLine text-center">
//                             {item.make}
//                           </td>
//                         </tr>
//                       ))
//                     ) : (
//                       <tr>
//                         <td colSpan="4" className="text-center">
//                           No items added
//                         </td>
//                       </tr>
//                     )}
//                   </tbody>
//                 </table>
//                 <h6 className="p-2 mt-4">ANNEXURE 3: TERMS AND CONDITIONS</h6>

//                 <div className="">
//                   <h6 className="p-2 mt-4">DELIVERY:</h6>

//                   {/* <p>{editorContent}</p> */}
//                   <div dangerouslySetInnerHTML={{ __html: editorContent }} />

//                   <h6 className="p-2 mt-4">Yours faithfully</h6>
//                 </div>
//                 <div className="d-flex">
//                   <img
//                     className=""
//                     alt=""
//                     src={require("../../assets/img/scimitar logo slogan.png")}
//                     style={{ width: "200px", height: "100px" }}
//                   ></img>
//                   <p className="mx-5">
//                     SKIMITAR HINDUSTHAN PVT LTD. <br></br>GH, back side, 29/30,
//                     Pavadai St, <br></br> Kaikolar Thottam, Erode, Tamil Nadu{" "}
//                     <br></br>
//                     63800
//                   </p>
//                 </div>
//               </div>
//             </>
//           )}
//         </div>

//         <Modal show={showModal} onHide={() => setShowModal(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Add Product</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <form onSubmit={handleSubmitProduct}>
//               <Modal.Body>
//                 <div className="row">
//                   <div className="col-lg-6">
//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         name="name"
//                         value={newProduct.name}
//                         onChange={handleNewProductChange}
//                         required
//                       />
//                       <label className="">Name</label>
//                     </div>

//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         name="hsn_code"
//                         value={addData.hsn_code}
//                         onChange={(e) =>
//                           setAddData({ ...addData, hsn_code: e.target.value })
//                         }
//                         required
//                       />
//                       <label className="">HSN Code</label>
//                     </div>

//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         name="brand_name"
//                         value={newProduct.brand_name}
//                         onChange={handleNewProductChange}
//                         required
//                       />
//                       <label className="">Brand Name</label>
//                     </div>
//                   </div>
//                   <div className="col-lg-6">
//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         name="description"
//                         value={newProduct.description}
//                         onChange={handleNewProductChange}
//                         required
//                       />
//                       <label className="">Description</label>
//                     </div>

//                     <div className="floating-label">
//                       <select
//                         className="form-select"
//                         name="status"
//                         value={newProduct.status}
//                         onChange={handleNewProductChange}
//                         required
//                       >
//                         <option value="">Select Status</option>
//                         <option value="Active">Active</option>
//                         <option value="InActive">InActive</option>
//                       </select>
//                     </div>
//                   </div>
//                 </div>
//               </Modal.Body>
//               <Modal.Footer>
//                 <Button
//                   className="cancelbut"
//                   variant="secondary"
//                   onClick={() => setShowModal(false)}
//                 >
//                   Cancel
//                 </Button>
//                 <Button className="submitbut" type="submit" variant="primary">
//                   Submit
//                 </Button>
//               </Modal.Footer>
//             </form>
//           </Modal.Body>
//         </Modal>
//       </div>
//     </>
//   );
// }

// export default Annexure;

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import ApiUrl from "../Environment/ApiUrl";
import { Modal, Button } from "react-bootstrap";
import "../../assets/css/AnnexurePreview.css";
import { toast } from "react-toastify";
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "background",
  "align",
];

function Annexure() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState({});
  const [responseDatas, setResponseDatas] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [Sgst, setSgst] = useState(0);
  const [cgst, setcgst] = useState(0);
  // Form state
  const defaultAddData = {
    expected_id: "",
    quotation_no: "",
    date: new Date().toISOString().split("T")[0],
    valid_tilldate: "",
    type: "",
    name: "",
    official_mobile: "",
    official_email: "",
    gstin: "",
    location: "",
    staff_id: "",
    staff_name: "",
    project_description: "",
    annexure_description_one: "",
    annexure_qty_one: "",
    basic_price: "",
    tax: "",
    total_price: "",
    subtotal: "",
    sgst: "",
    cgst: "",
    discount: "",
    nettotal: "",
    paytype: "",
    annexure_description_two: "",
    annexure_qty_two: "",
    make: "",
    terms_and_conditions: "",
  };

  useEffect(() => {
    const fetchQuotationNumber = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/quotation/latest`);
        setAddData((prevData) => ({
          ...prevData,
          quotation_no: response.data.quotation_no,
          date: new Date().toISOString().split("T")[0],
        }));
      } catch (error) {
        console.error("Error fetching quotation number:", error);
      }
    };

    fetchQuotationNumber();
  }, []);

  const [addData, setAddData] = useState(defaultAddData);
  const [formData, setFormData] = useState({
    annexure_description_one: "",
    annexure_qty_one: "",
    basicprice: "",
    tax: "",
  });

  // Fetch data when component mounts or ID changes
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  // Calculate totals when responseDatas or discount changes
  useEffect(() => {
    const newSubTotal = responseDatas.reduce(
      (sum, item) => sum + (item.total || 0),
      0
    );
    setSubTotal(newSubTotal);

    const discountAmount = (newSubTotal * discount) / 100;
    setNetTotal(newSubTotal - discountAmount);
  }, [responseDatas, discount]);

  const fetchData = async (id) => {
    try {
      const response = await axios.get(`${ApiUrl}/expected/${id}`);
      if (response.data) {
        setResponseData(response.data);
        setResponseDatas(
          Array.isArray(response.data.items) ? response.data.items : []
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [updateData, setUpdateData] = useState({
    annexure_description_one: "",
    annexure_qty_one: "",
    basicprice: "",
    tax: "",
  });

  const handleEdit = (index) => {
    const itemToEdit = responseDatas[index];
    setSelectedIndex(index); // Set the index of the item to edit
    setUpdateData({
      annexure_description_one: itemToEdit.annexure_description_one,
      annexure_qty_one: itemToEdit.annexure_qty_one,
      basicprice: itemToEdit.basicprice,
      tax: itemToEdit.tax,
    });
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      // Extract the updated data
      const annexure_qty_one = parseFloat(updateData.annexure_qty_one) || 0;
      const basicprice = parseFloat(updateData.basicprice) || 0;
      const taxRate = parseFloat(updateData.tax) || 0;

      // Calculate tax amount
      const taxAmount = (basicprice * annexure_qty_one * taxRate) / 100;
      const total = annexure_qty_one * basicprice + taxAmount;

      // Calculate SGST and CGST (each should be half of the total tax amount)
      const sgst = (taxAmount / 2).toFixed(2); // SGST is half of the total tax amount
      const cgst = (taxAmount / 2).toFixed(2); // CGST is also half of the total tax amount

      setSgst(sgst);
      setcgst(cgst);
      // Prepare the updated data with recalculated values
      const updatedData = {
        annexure_description_one: updateData.annexure_description_one,
        annexure_qty_one,
        basicprice,
        tax: taxRate,
        taxAmount,
        sgst, // SGST
        cgst, // CGST
        total,
      };

      // Update the responseDatas state with the updated item
      const updatedResponseDatas = responseDatas.map((item, index) =>
        index === selectedIndex ? { ...item, ...updatedData } : item
      );

      // Set the updated response data to the state
      setResponseDatas(updatedResponseDatas);

      // Reset form after update
      setUpdateData({
        annexure_description_one: "",
        annexure_qty_one: "",
        basicprice: "",
        tax: "",
      });

      toast.success("Update successfully!");
    } catch (error) {
      console.error("Error updating data:", error.message);
      toast.error("Error updating data.");
    }
  };

  const handleDiscountChange = (e) => {
    setDiscount(parseFloat(e.target.value) || 0);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log("gwqekqnelq", formData.annexure_description_one);
  };

  const handleAddDataChange = (e) => {
    setAddData({ ...addData, [e.target.name]: e.target.value });
  };

  // const handleAdd = () => {
  //   // if (
  //   //   !formData.annexure_description_one ||
  //   //   !formData.annexure_qty_one ||
  //   //   !formData.basicprice
  //   // ) {
  //   //   return;
  //   // }

  //   const annexure_qty_one = parseFloat(formData.annexure_qty_one) || 0;
  //   const basicprice = parseFloat(formData.basicprice) || 0;
  //   const taxRate = parseFloat(formData.tax) || 0;

  //   const taxAmount = (basicprice * annexure_qty_one * taxRate) / 100;
  //   const total = annexure_qty_one * basicprice + taxAmount;

  //   setResponseDatas([
  //     ...responseDatas,
  //     {
  //       annexure_description_one: formData.annexure_description_one,
  //       annexure_qty_one,
  //       basicprice,
  //       tax: taxRate,
  //       taxAmount,
  //       total,
  //     },
  //   ]);
  //   setFormData({
  //     annexure_description_one: "",
  //     annexure_qty_one: "",
  //     basicprice: "",
  //     tax: "",
  //   });
  // };

  const handleAdd = () => {
    const annexure_qty_one = parseFloat(formData.annexure_qty_one) || 0;
    const basicprice = parseFloat(formData.basicprice) || 0;
    const taxRate = parseFloat(formData.tax) || 0;

    // Calculate tax amount
    const taxAmount = (basicprice * annexure_qty_one * taxRate) / 100;
    const total = annexure_qty_one * basicprice + taxAmount;

    // Calculate SGST and CGST (each should be half of the total tax amount)
    const sgst = (taxAmount / 2).toFixed(2); // SGST is half of the total tax amount
    const cgst = (taxAmount / 2).toFixed(2); // CGST is also half of the total tax amount

    setSgst(sgst);
    setcgst(cgst);
    // Set the response data
    setResponseDatas([
      ...responseDatas,
      {
        annexure_description_one: formData.annexure_description_one,
        annexure_qty_one,
        basicprice,
        tax: taxRate,
        taxAmount,
        sgst, // Store SGST
        cgst, // Store CGST
        total,
      },
    ]);

    // Reset the form data
    setFormData({
      annexure_description_one: "",
      annexure_qty_one: "",
      basicprice: "",
      tax: "",
    });
  };

  const handleClear = () => {
    setFormData({
      annexure_description_one: "",
      annexure_qty_one: "",
      basicprice: "",
      tax: "",
    });
    setResponseDatas([]);
    setSubTotal(0);
    setDiscount(0);
    setNetTotal(0);
    setSgst(0);
    setcgst(0);
    setAddData(defaultAddData);
    setSelectedIndex(null);
  };
  const [activeStep, setActiveStep] = useState(0);
  const [editorContent, setEditorContent] = useState("");

  // Handlers
  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    // console.error("Invalid responseData: U", editorContent);
    // console.error(
    //   "Invalid responseData",
    //   responseDatass[0]?.annexure_description_two
    // );

    try {
      const formattedSubTotal = subTotal.toFixed(2);
      const formattedNetTotal = netTotal.toFixed(2);
      const formatttedsgst = Sgst;
      const formatttedcgst = cgst;
      const name_id = responseData._id;
      const cleanedFormData = {
        expected_id: id,
        name_id: name_id,
        name: responseData.name || "",
        official_mobile: responseData.official_mobile || "",
        official_email: responseData.official_email || "",
        gstin: responseData.gstin || addData.gstin || "",
        location: responseData.location || "",
        quotation_no: addData.quotation_no || "",
        date: addData.date || new Date().toISOString().split("T")[0],
        valid_tilldate: addData.valid_tilldate || "",
        staff_id: addData.staff_id || "",
        staff_name: addData.staff_name || "",
        project_description: addData.project_description || "",
        subtotal: formattedSubTotal,
        sgst: formatttedsgst || "0",
        cgst: formatttedcgst || "0",
        discount: discount.toString() || "0",
        nettotal: formattedNetTotal,
        paytype: addData.paytype || "",
        type: addData.type || "Project",
        terms_and_conditions: editorContent,
        items: responseDatas.map((item) => ({
          annexure_description_one: item.annexure_description_one || "",
          annexure_qty_one: parseFloat(item.annexure_qty_one) || 0,
          basicprice: parseFloat(item.basicprice) || 0,
          tax: parseFloat(item.tax) || 0,
          taxAmount: parseFloat(item.taxAmount) || 0,
          total: parseFloat(item.total).toFixed(2),
        })),
        itemss: responseDatass.map((item) => ({
          annexure_description_two: item.annexure_description_two || "",
          annexure_qty_two: parseFloat(item.annexure_qty_two) || 0,
          make: item.make || "",
        })),
      };

      console.log("Sending data:", cleanedFormData);

      const response = await axios.post(
        `${ApiUrl}/quotation_list`,
        cleanedFormData
      );
      const formData = {
        expected_id: id,
        quotation_no: addData.quotation_no || "",
        project_description: addData.project_description || "",
        date: addData.date || new Date().toISOString().split("T")[0],
        nettotal: formattedNetTotal,
        name_id: name_id,
        name: responseData.name || "",
        official_mobile: responseData.official_mobile || "",
        official_email: responseData.official_email || "",
      };
      console.log("the histroy beginssssss====", formData);

      const response12 = await axios.post(
        ApiUrl + "/quotation_history",
        formData
      );
      console.log("the histroy begins", response12);
      if (response.data) {
        console.log("Successfully posted quotation data", response.data);
        toast.success("Quotation Successfully Created");

        navigate("/upcomings");
      } else {
        alert("Server returned empty response. Please try again.");
      }
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleDelete = (index) => {
    const updatedData = responseDatas.filter((_, i) => i !== index); // Remove item at index
    setResponseDatas(updatedData); // Update state with new data
  };

  //   //////////////////////////////////////////////////////////////////

  const [responseDatass, setResponseDatass] = useState([]);
  const [formDatas, setFormDatas] = useState({
    annexure_description_two: "",
    annexure_qty_two: "",
    make: "",
  });

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const handleChanges = (e) => {
    setFormDatas({ ...formDatas, [e.target.name]: e.target.value });
    console.log("gwqekqnelq", formDatas.annexure_description_two);
  };

  const handleAdds = () => {
    const newItem = {
      annexure_description_two: formDatas.annexure_description_two,
      annexure_qty_two: parseFloat(formDatas.annexure_qty_two) || 0,
      make: formDatas.make,
    };

    setResponseDatass([...responseDatass, newItem]);
    setFormDatas({
      annexure_description_two: "",
      annexure_qty_two: "",
      make: "",
    });
  };

  const handleDeletes = (indexs) => {
    setResponseDatass(responseDatass.filter((_, i) => i !== indexs));
  };

  //   ////////////////////////////////////////////////////////////////

  // New state variables for product dropdown
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);

  // State for modal
  const [showModal, setShowModal] = useState(false);
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    price: "",
    hsn_code: "",
    brand_name: "",
    status: "",
  });

  // Fetch products on component mount
  useEffect(() => {
    fetchProducts();
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Fetch products from API
  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/product`);
      setProducts(response.data);
      setFilteredProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filtered = products.filter(
      (product) =>
        product.name.toLowerCase().includes(term.toLowerCase()) ||
        product.description.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredProducts(filtered);
  };

  // Select product from dropdown
  const selectProduct = (product) => {
    setFormDatas({
      ...formDatas,
      annexure_description_two: product.name,
      // You can also set make or other fields if available in the product data
    });
    setShowDropdown(false);
    setSearchTerm("");
  };

  // Handle new product input change
  const handleNewProductChange = (e) => {
    setNewProduct({
      ...newProduct,
      [e.target.name]: e.target.value,
    });
  };

  // Submit new product
  const handleSubmitProduct = async () => {
    try {
      const response = await axios.post(`${ApiUrl}/product`, newProduct);
      console.log("Product added:", response.data);
      await fetchProducts();
      setShowModal(false);
      setFormDatas({
        ...formDatas,
        annexure_description_two: newProduct.name,
      });

      // Reset new product form
      setNewProduct({
        name: "",
        description: "",
        price: "",
      });

      toast.success("Product added successfully!");
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product");
    }
  };

  //   ////////////////////////////////////////////////////////////////////////

  const defaultMessage = `
      <h3>Terms & Conditions</h3>
      <h3>DELIVERY:</h3>
      <p>Ex-works delivery 30 working days from the date of receipt of drawing approval. Drawing submission will be done within 7 working days from the date of receipt of the contract coming into force, i.e., receipt of a techno-commercially clear order along with advance where applicable.</p>
  
      <h3>PRICE BASE:</h3>
      <p>Ex-Works, Hosur, and firm within the validity period of work, i.e., up to mutually agreed terms.</p>
  
      <h3>PAYMENT TERMS:</h3>
      <p>90% payment advance on value along with PO as mentioned in perform invoice for material which
      includes tax. Balance 10% within 7 Days from the date of Material received.</p>
  
      <h3>CONTRACT COMING INTO FORCE:</h3>
      <p>After fulfilling all the following conditions: receipt of confirmed Purchase order, receipt of advance
      payment & clarification of all technical and commercial points.</p>
  
      <h3>FREIGHT:</h3>
      <p>Freight charges shall be as per expense.</p>
  
      <h3>INSURANCE:</h3>
      <p>Shall be extra cost at actual.</p>
  
      <h3>RISK PURCHASE:</h3>
      <p>CONSEQUENTIAL DAMAGES/LATENT DEFECTS: Shall not be acceptable.</p>
  
      <h3>Yours faithfully,</h3>
      <p>Aravinth</p>
    `;

  useEffect(() => {
    setEditorContent(defaultMessage);
  }, []);

  // //////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary">
              <b>Dashboard / </b>
            </Link>
            <Link to="/upcomings" className="text-secondary">
              <b>Expected / </b>
            </Link>
            <Link to="/addstaff" className="text-danger">
              <b>Annexure</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div className="row mt-3">
          {activeStep === 0 && (
            <>
              <div className="card p-3">
                <div className="row">
                  <div className="col-lg-5"></div>
                  <div className="col-lg-3">
                    <h5
                      className="head"
                      style={{ textDecoration: "underline" }}
                    >
                      New Quotation
                    </h5>
                  </div>
                  <div className="col-lg-3 mt-2">
                    <select
                      className="form-control"
                      style={{ width: "150px" }}
                      name="type"
                      value={addData.type}
                      onChange={handleAddDataChange}
                    >
                      <option value="">Select dropdown</option>
                      <option value="Project">Project</option>
                      <option value="Sales">Sales / Service</option>
                    </select>
                  </div>
                  <div className="col-lg-1"></div>
                </div>

                {/* First row */}
                <div className="row first">
                  <div className="col-lg-3">
                    <label>Quotation No:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="quotation_no"
                      value={addData.quotation_no}
                      disabled
                      readOnly // Prevent manual input
                    />
                  </div>

                  <div className="col-lg-4">
                    <label>Date:</label>
                    <input
                      type="date"
                      name="date"
                      className="form-control"
                      value={addData.date}
                      onChange={handleAddDataChange}
                    />
                  </div>

                  <div className="col-lg-4">
                    <label>Valid Till Date:</label>
                    <input
                      type="date"
                      name="valid_tilldate"
                      value={addData.valid_tilldate}
                      onChange={handleAddDataChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              {/* Client and Staff Details */}
              <div className="card p-3 mt-3">
                <div className="row second">
                  <div className="col-lg-6">
                    <h1>Client Details (Quotation for)</h1>
                    <div className="row">
                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        style={{ textAlign: "left" }}
                      >
                        <p>Client Business Name</p>
                      </div>
                      <div
                        className="col-lg-7 col-md-7 col-sm-12"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          value={responseData.name || ""}
                          disabled
                          className="form-control"
                        />
                      </div>

                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        style={{ textAlign: "left" }}
                      >
                        <p> Mobile / Contact</p>
                      </div>
                      <div
                        className="col-lg-7 col-md-7 col-sm-12"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          value={responseData.official_mobile || ""}
                          disabled
                          className="form-control"
                        />
                      </div>

                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        style={{ textAlign: "left" }}
                      >
                        <p>Official Email</p>
                      </div>
                      <div
                        className="col-lg-7 col-md-7 col-sm-12"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          value={responseData.official_email || ""}
                          disabled
                          className="form-control"
                        />
                      </div>

                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        style={{ textAlign: "left" }}
                      >
                        <p>GSTIN</p>
                      </div>
                      <div
                        className="col-lg-7 col-md-7 col-sm-12"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          name="gstin"
                          value={addData.gstin || responseData.gstin || ""}
                          onChange={handleAddDataChange}
                          className="form-control"
                        />
                      </div>

                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        style={{ textAlign: "left" }}
                      >
                        <p>Address</p>
                      </div>
                      <div
                        className="col-lg-7 col-md-7 col-sm-12"
                        style={{ textAlign: "right" }}
                      >
                        <textarea
                          style={{ width: "80%" }}
                          value={responseData.district || ""}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <h1> Quotation (Created By)</h1>
                    <div className="row">
                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        style={{ textAlign: "left" }}
                      >
                        <p>Staff ID</p>
                      </div>
                      <div
                        className="col-lg-7 col-md-7 col-sm-12"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          name="staff_id"
                          value={responseData.assign_staff?.empid || ""}
                          onChange={handleAddDataChange}
                          className="form-control"
                        />
                      </div>

                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        style={{ textAlign: "left" }}
                      >
                        <p>Staff Name</p>
                      </div>
                      <div
                        className="col-lg-7 col-md-7 col-sm-12"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          name="staff_name"
                          value={responseData.assign_staff?.name || ""}
                          onChange={handleAddDataChange}
                          className="form-control"
                        />
                      </div>
                      <div className="" style={{ textAlign: "left" }}>
                        <p>Project Description : </p>
                      </div>
                      <div className="" style={{ textAlign: "right" }}>
                        <textarea
                          style={{ width: "80%" }}
                          name="project_description"
                          value={addData.project_description || ""}
                          onChange={handleAddDataChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Annexure section */}
              <div className="card p-2 mt-3">
                <div className="text-center mt-4 mb-3">
                  <h1 className="fs-5">Annexure 1: Quotation</h1>
                </div>

                {/* <div className="container mb-3">
                  <div className="row">
                    <div className="col-lg-4">
                      <label>Description</label>
                      <input
                        className="form-control"
                        name="annexure_description_one"
                        value={formData.annexure_description_one}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-lg-2">
                      <label>Qty</label>
                      <input
                        className="form-control"
                        name="annexure_qty_one"
                        type="number"
                        value={formData.annexure_qty_one}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-2">
                      <label>Basic Price</label>
                      <input
                        className="form-control"
                        name="basicprice"
                        type="number"
                        value={formData.basicprice}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-lg-2 position-relative">
                      <label>Tax</label>
                      <div className="position-relative">
                        <input
                          className="form-control pe-4"
                          name="tax"
                          type="number"
                          value={formData.tax}
                          onChange={handleChange}
                          style={{ paddingRight: "25px" }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            pointerEvents: "none",
                            color: "#777",
                          }}
                        >
                          %
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-1">
                      <button
                        className="btn btn-success btn-sm mt-4"
                        onClick={handleAdd}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div> */}

                <div className="container mb-3">
                  <div className="row">
                    <div className="col-lg-4">
                      <label>Description</label>
                      <input
                        className="form-control"
                        name="annexure_description_one"
                        value={
                          selectedIndex !== null
                            ? updateData.annexure_description_one
                            : formData.annexure_description_one
                        }
                        onChange={(e) => {
                          if (selectedIndex !== null) {
                            setUpdateData({
                              ...updateData,
                              annexure_description_one: e.target.value,
                            });
                          } else {
                            setFormData({
                              ...formData,
                              annexure_description_one: e.target.value,
                            });
                          }
                        }}
                      />
                    </div>

                    <div className="col-lg-2">
                      <label>Qty</label>
                      <input
                        className="form-control"
                        name="annexure_qty_one"
                        type="number"
                        value={
                          selectedIndex !== null
                            ? updateData.annexure_qty_one
                            : formData.annexure_qty_one
                        }
                        onChange={(e) => {
                          if (selectedIndex !== null) {
                            setUpdateData({
                              ...updateData,
                              annexure_qty_one: e.target.value,
                            });
                          } else {
                            setFormData({
                              ...formData,
                              annexure_qty_one: e.target.value,
                            });
                          }
                        }}
                      />
                    </div>

                    <div className="col-lg-2">
                      <label>Basic Price</label>
                      <input
                        className="form-control"
                        name="basicprice"
                        type="number"
                        value={
                          selectedIndex !== null
                            ? updateData.basicprice
                            : formData.basicprice
                        }
                        onChange={(e) => {
                          if (selectedIndex !== null) {
                            setUpdateData({
                              ...updateData,
                              basicprice: e.target.value,
                            });
                          } else {
                            setFormData({
                              ...formData,
                              basicprice: e.target.value,
                            });
                          }
                        }}
                      />
                    </div>

                    <div className="col-lg-2 position-relative">
                      <label>Tax</label>
                      <div className="position-relative">
                        <input
                          className="form-control pe-4"
                          name="tax"
                          type="number"
                          value={
                            selectedIndex !== null
                              ? updateData.tax
                              : formData.tax
                          }
                          onChange={(e) => {
                            if (selectedIndex !== null) {
                              setUpdateData({
                                ...updateData,
                                tax: e.target.value,
                              });
                            } else {
                              setFormData({ ...formData, tax: e.target.value });
                            }
                          }}
                          style={{ paddingRight: "25px" }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            pointerEvents: "none",
                            color: "#777",
                          }}
                        >
                          %
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-1">
                      <button
                        className="btn btn-success btn-sm mt-4"
                        onClick={
                          selectedIndex !== null
                            ? handleupdatesubmit
                            : handleAdd
                        }
                      >
                        {selectedIndex !== null ? "Update" : "Add"}
                      </button>
                    </div>
                  </div>
                </div>

                {/* Table of items */}
                <div className="card p-3">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          <th>S.NO</th>
                          <th>Description</th>
                          <th>Qty</th>
                          <th>Basic Price</th>
                          <th>Tax</th>
                          <th>Total</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {responseDatas.length > 0 ? (
                          responseDatas.map((item, indexs) => (
                            <tr key={indexs}>
                              <td>{indexs + 1}</td>
                              <td>{item.annexure_description_one}</td>
                              <td>{item.annexure_qty_one}</td>
                              <td>{item.basicprice}</td>
                              <td>{item.tax}%</td>
                              <td>
                                {typeof item.total === "number"
                                  ? item.total.toFixed(2)
                                  : "0.00"}
                              </td>
                              <td>
                                <i
                                  className="fa-solid fa-circle-xmark mx-2 fs-4"
                                  style={{ color: "red", cursor: "pointer" }}
                                  onClick={() => handleDelete(indexs)}
                                ></i>

                                <i
                                  className="fa-solid fa-pen-to-square mx-2 fs-5"
                                  style={{ color: "blue", cursor: "pointer" }}
                                  onClick={() => handleEdit(indexs)} // Pass the index to handleEdit
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center">
                              No items added
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Totals section */}
                <div className="row second">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        style={{ textAlign: "left" }}
                      >
                        <p>Sub Total</p>
                      </div>
                      <div
                        className="col-lg-7 col-md-7 col-sm-12"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          value={subTotal.toFixed(2)}
                          readOnly
                          className="form-control"
                        />
                      </div>

                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        style={{ textAlign: "left" }}
                      >
                        <p>SGST</p>
                      </div>

                      <div
                        className="col-lg-7 col-md-7 col-sm-12"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          style={{ width: "80%" }}
                          name="sgst"
                          value={Sgst}
                          onChange={handleAddDataChange}
                          type="number"
                          className="form-control"
                        />
                      </div>

                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        style={{ textAlign: "left" }}
                      >
                        <p>CGST</p>
                      </div>
                      <div
                        className="col-lg-7 col-md-7 col-sm-12"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          style={{ width: "80%" }}
                          name="cgst"
                          value={cgst}
                          onChange={handleAddDataChange}
                          type="number"
                          className="form-control"
                        />
                      </div>

                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        style={{ textAlign: "left" }}
                      >
                        <p>Discount (%)</p>
                      </div>
                      <div
                        className="col-lg-7 col-md-7 col-sm-12"
                        style={{ textAlign: "right" }}
                      >
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            width: "80%",
                          }}
                        >
                          <input
                            style={{ width: "100%", marginLeft: "-67px" }}
                            type="number"
                            value={discount}
                            onChange={handleDiscountChange}
                            className="form-control"
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "100px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              pointerEvents: "none",
                              color: "#777",
                            }}
                          >
                            %
                          </span>
                        </div>
                      </div>

                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        style={{ textAlign: "left" }}
                      >
                        <p>Net Total</p>
                      </div>
                      <div
                        className="col-lg-7 col-md-7 col-sm-12"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          value={netTotal.toFixed(2)}
                          readOnly
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                  <div
                    style={{ marginLeft: "75px" }}
                    className="d-flex justify-content-evenly mt-3 buttonstyle"
                  >
                    <button
                      type="button"
                      className="btn btn-danger invoice-button Add"
                      onClick={handleClear}
                    >
                      Clear
                    </button>

                    <button
                      type="button"
                      className="btn btn-success invoice-button Add"
                      onClick={() => navigate("/upcomings")}
                    >
                      Back
                    </button>
                    <button
                      onClick={() => setActiveStep(1)}
                      className="btn btn-secondary Add mx-2"
                      variant="secondary"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Step 2 - Bill of Materials */}
          {activeStep === 1 && (
            <>
              <div className="mt-4 mb-3">
                <h1 className="fs-5 text-center">
                  Annexure 2: Bill Of Materials
                </h1>
              </div>

              <div className="container mb-3">
                <div className="row">
                  {/* <div className="col-lg-4">
                    <label>Description</label>
                    <input
                      className="form-control"
                      name="annexure_description_two"
                      value={formDatas.annexure_description_two}
                      onChange={handleChanges}
                    />
                  </div> */}
                  <div className="col-lg-4">
                    <label>Description</label>
                    <div className="position-relative">
                      <div className="input-group">
                        <input
                          className="form-control"
                          name="annexure_description_two"
                          value={
                            searchTerm || formDatas.annexure_description_two
                          }
                          onChange={(e) => {
                            handleSearchChange(e);
                            setFormDatas({
                              ...formDatas,
                              annexure_description_two: e.target.value,
                            });
                          }}
                          onClick={() => setShowDropdown(true)}
                          placeholder="Search or select a product"
                        />
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={() => setShowModal(true)}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {showDropdown && (
                        <div
                          className="dropdown-menu show w-100"
                          style={{ maxHeight: "200px", overflow: "auto" }}
                        >
                          {filteredProducts.length > 0 ? (
                            filteredProducts.map((product, index) => (
                              <button
                                key={index}
                                className="dropdown-item"
                                type="button"
                                onClick={() => selectProduct(product)}
                              >
                                {product.name}
                              </button>
                            ))
                          ) : (
                            <div className="dropdown-item">
                              No products found
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <label>Qty</label>
                    <input
                      className="form-control"
                      name="annexure_qty_two"
                      type="number"
                      value={formDatas.annexure_qty_two}
                      onChange={handleChanges}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label>Make</label>
                    <input
                      className="form-control"
                      name="make"
                      type="text"
                      value={formDatas.make}
                      onChange={handleChanges}
                    />
                  </div>
                  <div className="col-lg-2">
                    <button
                      className="btn btn-success btn-sm mt-4"
                      onClick={handleAdds}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>

              <div className="card p-3">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th>S.NO</th>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Make</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {responseDatass.length > 0 ? (
                        responseDatass.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.annexure_description_two}</td>
                            <td>{item.annexure_qty_two}</td>
                            <td>{item.make}</td>
                            <td>
                              <i
                                className="fa-solid fa-circle-xmark mx-2 fs-4"
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => handleDeletes(index)}
                              ></i>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">
                            No items added
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="text-end mt-3">
                <button
                  className="btn btn-secondary btn-sm mx-3"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={() => setActiveStep(2)}
                >
                  Next
                </button>
              </div>
            </>
          )}

          {/* Step 3 - Editor */}
          {activeStep === 2 && (
            <>
              <ReactQuill
                theme="snow"
                value={editorContent}
                onChange={setEditorContent}
                modules={modules}
                formats={formats}
                placeholder="Write something amazing..."
                style={{ height: "300px", marginBottom: "20px" }}
              />

              <h3 className="mt-5">Editor Output:</h3>
              <div
                className="border p-3"
                style={{ minHeight: "100px", background: "#f8f9fa" }}
                dangerouslySetInnerHTML={{ __html: editorContent }}
              />

              <div className="row mt-3">
                <div className="col-lg-9"></div>
                <div className="col-lg-3">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="agreeCheckbox"
                      className="form-check-input border-1 border-dark"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <label
                      htmlFor="agreeCheckbox"
                      className="form-check-label ms-2"
                    >
                      Agree to proceed
                    </label>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn btn-secondary btn-sm mx-3"
                      onClick={handleBack}
                    >
                      Back
                    </button>
                    <button
                      className={`btn btn-secondary btn-sm ${
                        !isChecked ? "disabled opacity-50" : ""
                      }`}
                      onClick={() => isChecked && setActiveStep(3)}
                      disabled={!isChecked}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Step 4 - Review & Submit */}
          {activeStep === 3 && (
            <>
              <h4>Review and Confirm</h4>
              <div className="row mb-5">
                <div className="col-lg-8"></div>
                <div className="col-lg-4">
                  <div className="d-flex">
                    <button
                      className="btn btn-secondary Add btn-sm mx-3"
                      onClick={handleBack}
                    >
                      Back
                    </button>

                    <button
                      className="btn btn-secondary Add btn-sm mx-3"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>

              <div className="box_border">
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                      padding: "10px",
                    }}
                  >
                    <img
                      alt="invoice logo"
                      style={{
                        width: "400px",
                        objectFit: "contain",
                        marginLeft: "-30px",
                      }}
                      src={require("../../assets/img/scimitar logo slogan.png")}
                    />
                    <div className="horizontal"></div>
                    <div style={{ textAlign: "left" }}>
                      <h6 className="fs-5 bold text-center">Our Services</h6>
                      <h6>* ALL PURPOSE MACHINE RETROFIT</h6>
                      <h6>* PLC, HMI, SCADA, VFD PROGRAMMING</h6>
                      <h6>* ELECTRICAL AND PLC CONTROL PANEL DISTRIBUTOR</h6>
                    </div>
                  </div>
                </div>
                <div className="bordertop p-2">
                  <div className="d-flex">
                    <div style={{ width: "60%" }}>
                      <h6>To: {responseData.name || ""}</h6>
                      <h6>CONTACT PERSON: </h6>
                      <h6>PH: {responseData.official_mobile || ""}</h6>
                      <h6>EMAIL: {responseData.official_email || ""}</h6>
                    </div>

                    <div className="second_row_start " style={{ width: "20%" }}>
                      <div
                        className="border_down serial_side"
                        style={{ height: "40px" }}
                      >
                        <h6 className="">SERIAL NO</h6>
                      </div>
                      <div
                        className="border_down serial_side"
                        style={{ height: "40px" }}
                      >
                        <h6 className="">Date</h6>
                      </div>
                      <div className="serial_side" style={{ height: "40px" }}>
                        <h6 className="">Validity Till Date</h6>
                      </div>
                    </div>
                    <div className="second_row_start" style={{ width: "20%" }}>
                      <div
                        className="border_down serial_side"
                        style={{ height: "40px" }}
                      >
                        <h6 className="">{addData.quotation_no}</h6>
                      </div>
                      <div
                        className="border_down serial_side"
                        style={{ height: "40px" }}
                      >
                        <h6 className="">{addData.date}</h6>
                      </div>
                      <div className="serial_side" style={{ height: "40px" }}>
                        <h6 className="">{addData.valid_tilldate}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bordertop p-2" style={{ height: "150px", width: "100%", textAlign: "justify"}}>
                  <h6>Project Description:</h6>
                  <p>{addData.project_description}</p>
                </div>
                <div className="p-2">
                  <h6>ANNEXURE 1: QUOTATION</h6>
                  <h6>ANNEXURE 2: BILL OF MATERIALS</h6>
                  <h6>ANNEXURE 3: TERMS AND CONDITIONS</h6>
                  <p className="mt-5">
                    We hope you find the offer suitable for your needs.
                    <br />
                    Thank you, and we assure our best services at all times.
                  </p>
                </div>
                <h6 className="p-2">ANNEXURE 1: QUOTATION</h6>
                <table className="bordertop">
                  <thead>
                    <tr className="tbody_line text-center total_leftLine">
                      <th className="p-2 total_leftLine">S.No</th>
                      <th className="total_leftLine">DESCRIPTION</th>
                      <th className="total_leftLine">QTY</th>
                      <th className="total_leftLine">BASIC PRICE</th>
                      <th className="total_leftLine">Tax Amount</th>
                      <th className="total_leftLine">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responseDatas.length > 0 ? (
                      responseDatas.map((item, index) => (
                        <tr key={index}>
                          <td className="total_leftLine text-center">
                            {index + 1}
                          </td>
                          <td className="total_leftLine text-center">
                            {item.annexure_description_one}
                          </td>
                          <td className="total_leftLine text-center">
                            {item.annexure_qty_one}
                          </td>
                          <td className="total_leftLine text-center">
                            {item.basicprice}
                          </td>
                          <td className="total_leftLine text-center">
                            {item.tax}
                          </td>
                          <td className="total_leftLine text-center">
                            {item.total}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No items added
                        </td>
                      </tr>
                    )}
                    <tr className="tbody_line text-center">
                      <td className="total_leftLine" colSpan="2">
                        Total
                      </td>
                      <td className="total_leftLine">0</td>
                      <td className="total_leftLine">0</td>
                      <td className="total_leftLine">0</td>
                      <td>{addData.nettotal}</td>
                    </tr>
                  </tbody>
                </table>

                <table className="bordertop mt-5">
                  <thead>
                    <tr className="tbody_line text-center">
                      <td className="total_leftLine" colSpan="2">
                        Discount
                      </td>
                      <td className="total_leftLine">{addData.discount}</td>
                      <td className="total_leftLine">0</td>
                      <td className="total_leftLine">0</td>
                      <td className="total_leftLine">0</td>

                      <td>{addData.nettotal}</td>
                    </tr>
                  </thead>
                </table>

                <h6 className="p-2 mt-4">ANNEXURE 2: BILL OF MATERIALS</h6>
                <table className="bordertop">
                  <thead>
                    <tr className="tbody_line text-center total_leftLine">
                      <th className="p-2 total_leftLine">S.No</th>
                      <th className="total_leftLine">DESCRIPTION</th>
                      <th className="total_leftLine">QTY</th>
                      <th className="total_leftLine">MAKE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responseDatass.length > 0 ? (
                      responseDatass.map((item, index) => (
                        <tr key={index}>
                          <td className="total_leftLine text-center">
                            {index + 1}
                          </td>
                          <td className="total_leftLine text-center">
                            {item.annexure_description_two}
                          </td>
                          <td className="total_leftLine text-center">
                            {item.annexure_qty_two}
                          </td>
                          <td className="total_leftLine text-center">
                            {item.make}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No items added
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <h6 className="p-2 mt-4">ANNEXURE 3: TERMS AND CONDITIONS</h6>

                <div className="">
                  <h6 className="p-2 mt-4">DELIVERY:</h6>

                  {/* <p>{editorContent}</p> */}
                  <div dangerouslySetInnerHTML={{ __html: editorContent }} />

                  <h6 className="p-2 mt-4">Yours faithfully</h6>
                </div>
                <div className="d-flex">
                  <img
                    className=""
                    alt=""
                    src={require("../../assets/img/scimitar logo slogan.png")}
                    style={{ width: "200px", height: "100px" }}
                  ></img>
                  <p className="mx-5">
                    SKIMITAR HINDUSTHAN PVT LTD. <br></br>GH, back side, 29/30,
                    Pavadai St, <br></br> Kaikolar Thottam, Erode, Tamil Nadu{" "}
                    <br></br>
                    63800
                  </p>
                </div>
              </div>
            </>
          )}
        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitProduct}>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="name"
                        value={newProduct.name}
                        onChange={handleNewProductChange}
                        required
                      />
                      <label className="">Name</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="hsn_code"
                        value={addData.hsn_code}
                        onChange={(e) =>
                          setAddData({ ...addData, hsn_code: e.target.value })
                        }
                        required
                      />
                      <label className="">HSN Code</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="brand_name"
                        value={newProduct.brand_name}
                        onChange={handleNewProductChange}
                        required
                      />
                      <label className="">Brand Name</label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="description"
                        value={newProduct.description}
                        onChange={handleNewProductChange}
                        required
                      />
                      <label className="">Description</label>
                    </div>

                    <div className="floating-label">
                      <select
                        className="form-select"
                        name="status"
                        value={newProduct.status}
                        onChange={handleNewProductChange}
                        required
                      >
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                      </select>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="cancelbut"
                  variant="secondary"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
                <Button className="submitbut" type="submit" variant="primary">
                  Submit
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmitProduct}>
              Submit
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </>
  );
}

export default Annexure;
