// // import React, { useMemo, useState, useEffect } from "react";
// // import axios from "axios";
// // import { Modal, Button } from "react-bootstrap";
// // import ApiUrl from "./../Environment/ApiUrl";
// // import "../../assets/css/Common.css";
// // import { toast } from "react-toastify";
// // import { Link, useNavigate } from "react-router-dom";
// // import Select from "react-select";
// // import styled from "styled-components";
// // import {
// //   useTable,
// //   useFilters,
// //   useGlobalFilter,
// //   useSortBy,
// //   usePagination,
// // } from "react-table";
// // import { createPortal } from "react-dom";
// // import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

// // const TableContainer = styled.div`
// //   padding: 20px;
// //   overflow-x: auto;
// // `;

// // const Table = styled.table`
// //   width: 100%;
// //   border-collapse: collapse;
// //   border: 1px solid #ddd;

// //   // Apply striped rows styling
// //   tr:nth-child(even) {
// //     background-color: #ffffff; /* Light gray for even rows */
// //   }

// //   tr:nth-child(odd) {
// //     background-color: #f2f2f2; /* White for odd rows */
// //   }
// // `;

// // const Th = styled.th`
// //   padding: 10px;
// //   background-color: #ffffff;
// //   text-align: left;
// //   cursor: pointer;
// //   position: relative;
// // `;

// // const Td = styled.td`
// //   padding: 10px;
// //   // border: 1px solid #ddd;
// // `;

// // function GlobalFilter({ globalFilter, setGlobalFilter }) {
// //   return (
// //     <div style={{ marginBottom: "10px" }}>
// //       <input
// //         value={globalFilter || ""}
// //         onChange={(e) => setGlobalFilter(e.target.value)}
// //         placeholder="Search all columns..."
// //         style={{
// //           padding: "5px",
// //           border: "1px solid #ddd",
// //           borderRadius: "4px",
// //           width: "100%",
// //         }}
// //       />
// //     </div>
// //   );
// // }
// // // Default Column Filter (Icon-based)
// // const DefaultColumnFilter = ({
// //   column: { filterValue, setFilter, preFilteredRows, id },
// // }) => {
// //   const [isActive, setIsActive] = useState(false);
// //   const [selectedValue, setSelectedValue] = useState(null);
// //   const options = useMemo(() => {
// //     const uniqueValues = Array.from(
// //       new Set(preFilteredRows.map((row) => row.values[id]))
// //     ).map((value) => ({ label: value, value }));
// //     return [{ label: "All", value: "" }, ...uniqueValues];
// //   }, [id, preFilteredRows]);

// //   // Handle icon click
// //   const handleIconClick = () => {
// //     setIsActive(!isActive);
// //   };

// //   const handleMultiSelectChange = (selectedOptions) => {
// //     const values = selectedOptions
// //       ? selectedOptions.map((option) => option.value)
// //       : [];
// //     setFilter(values.length > 0 ? values : undefined);
// //   };

// //   return (
// //     <div style={{ alignItems: "center" }}>
// //       <div>
// //         <i
// //           className="fa-solid fa-ellipsis-vertical"
// //           onClick={handleIconClick}
// //           style={{
// //             cursor: "pointer",
// //             fontSize: "20px",
// //             color: "#AC0000",
// //           }}
// //         ></i>
// //       </div>
// //       <div>
// //         {isActive &&
// //           createPortal(
// //             <div
// //               style={{
// //                 position: "fixed",
// //                 zIndex: 9999,
// //                 left: "50%",
// //                 top: "50%",
// //                 width: "30%",
// //                 transform: "translate(-50%, -50%)",
// //               }}
// //             >
// //               <div
// //                 style={{
// //                   position: "relative",
// //                   background: "white",
// //                   padding: "20px",
// //                   borderRadius: "8px",
// //                   boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
// //                   backgroundColor: "#A70000",
// //                 }}
// //               >
// //                 <Select
// //                   options={options}
// //                   isMulti
// //                   value={options.filter((option) =>
// //                     filterValue?.includes(option.value)
// //                   )}
// //                   onChange={handleMultiSelectChange}
// //                   isClearable
// //                   isSearchable
// //                   placeholder={`Filter ${id}`}
// //                   styles={{
// //                     menu: (provided) => ({
// //                       ...provided,
// //                       zIndex: 9999,
// //                     }),
// //                   }}
// //                 />
// //                 <button
// //                   style={{
// //                     marginTop: "10px",
// //                     marginLeft: "150px",
// //                     padding: "5px 10px",
// //                     background: "#007bff",
// //                     color: "white",
// //                     border: "none",
// //                     borderRadius: "4px",
// //                     cursor: "pointer",
// //                   }}
// //                   onClick={() => setIsActive(false)} // Close the popup
// //                 >
// //                   Close
// //                 </button>
// //               </div>
// //             </div>,
// //             document.body
// //           )}
// //       </div>
// //     </div>
// //   );
// // };

// // function IncomeList() {
// //   const [responseData, setResponseData] = useState([]);

// //   const data = useMemo(() => {
// //     return responseData.map((item) => ({
// //       _id: item._id,
// //       branch_id: item.branch_id,
// //       type: item.type?.type,
// //       typeid: item.type?._id,
// //       paid_by: item.paid_by,
// //       paid_to: item.paid_to,
// //       paytype: item.paytype,
// //       amount: item.amount,
// //       date: item.date,
// //       description: item.description,
// //       person_name: item.person_name,
// //       contact: item.contact,
// //     }));
// //   }, [responseData]);

// //   const columns = useMemo(
// //     () => [
// //       {
// //         Header: "S.No",
// //         id: "serialNo",
// //         Cell: ({ row }) => {
// //           return row.index + 1;
// //         },
// //       },
// //       {
// //         Header: "Branch",
// //         accessor: "branch_id",
// //         Filter: DefaultColumnFilter,
// //         filter: "multiSelectFilter",
// //       },
// //       {
// //         Header: "Type",
// //         accessor: "type",
// //         Filter: DefaultColumnFilter,
// //         filter: "multiSelectFilter",
// //       },
// //       {
// //         Header: "Date",
// //         accessor: (row) => {
// //           const date = new Date(row.date);
// //           const day = String(date.getDate()).padStart(2, "0");
// //           const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
// //           const year = date.getFullYear();
// //           return `${day}-${month}-${year}`;
// //         },
// //         Filter: DefaultColumnFilter,
// //         filter: "multiSelectFilter",
// //       },
// //       {
// //         Header: "Paid By",
// //         accessor: "paid_by",
// //         Filter: DefaultColumnFilter,
// //         filter: "multiSelectFilter",
// //       },
// //       {
// //         Header: "Paid To",
// //         accessor: "paid_to",
// //         Filter: DefaultColumnFilter,
// //         filter: "multiSelectFilter",
// //       },
// //       {
// //         Header: "Paid Type",
// //         accessor: "paytype",
// //         Filter: DefaultColumnFilter,
// //         filter: "multiSelectFilter",
// //       },
// //       {
// //         Header: "Amount",
// //         accessor: "amount",
// //         Filter: DefaultColumnFilter,
// //         filter: "multiSelectFilter",
// //       },
// //     ],
// //     []
// //   );

// //   const {
// //     getTableProps,
// //     getTableBodyProps,
// //     headerGroups,
// //     rows,
// //     prepareRow,
// //     state,
// //     setGlobalFilter,
// //     setPageSize,
// //     page,
// //     canPreviousPage,
// //     canNextPage,
// //     pageCount,
// //     gotoPage,
// //   } = useTable(
// //     {
// //       columns,
// //       data,
// //       initialState: { pageSize: 10 },
// //       defaultColumn: { Filter: DefaultColumnFilter },
// //       filterTypes: {
// //         multiSelectFilter: (rows, columnIds, filterValue) => {
// //           if (!filterValue || filterValue.length === 0) {
// //             return rows;
// //           }
// //           return rows.filter((row) => {
// //             const rowValue = row.values[columnIds[0]];
// //             const rowValueStr = String(rowValue);
// //             return filterValue.some((filterVal) =>
// //               rowValueStr.includes(String(filterVal))
// //             );
// //           });
// //         },
// //       },
// //     },
// //     useFilters,
// //     useGlobalFilter,
// //     useSortBy,
// //     usePagination
// //   );

// //   const [responseDatas, setResponseDatas] = useState([]);
// //   const [showModal, setShowModal] = useState(false);
// //   const [isUpdateshowModal, setUpdateShowModal] = useState(false);
// //   const [selectedRole, setSelectedRole] = useState("");
// //   const [student, setstudent] = useState([]);
// //   const [paymenttype, setpaymenttype] = useState([]);
// //   const [responseDatased, setResponseDatased] = useState([]);

// //   const formatDate = (date) => {
// //     if (!(date instanceof Date)) {
// //       date = new Date(date); // Convert input to Date if it's not already
// //     }
// //     const year = date.getFullYear();
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const day = String(date.getDate()).padStart(2, "0");
// //     return `${year}-${month}-${day}`;
// //   };

// //   const [addData, setAddData] = useState({
// //     branch_id: "",
// //     type: "",
// //     date: formatDate(new Date()),
// //     description: "",
// //     paid_by: "",
// //     paid_to: "",
// //     paytype: "",
// //     amount: "",
// //     person_name: "",
// //     contact: "",
// //   });

// //   const handleDateChange = (e) => {
// //     setUpdateData((prevData) => ({
// //       ...prevData,
// //       date: e.target.value, // Input type=date handles the format
// //     }));
// //   };

// //   useEffect(() => {
// //     fetchData();
// //   }, []);

// //   useEffect(() => {
// //     window.scrollTo(0, 0);
// //   }, []);

// //   const fetchData = async () => {
// //     try {
// //       const response = await axios.get(ApiUrl + "/income_list");
// //       console.log("Response from server:12", response.data);
// //       setResponseData(response.data.reverse());
// //     } catch (error) {
// //       console.error("Error fetching data:", error.message);
// //     }
// //   };

// //   const [updateData, setUpdateData] = useState({
// //     branch_id: "",
// //     type: "",
// //     date: formatDate(new Date()),
// //     description: "",
// //     paid_by: "",
// //     paid_to: "",
// //     paytype: "",
// //     amount: "",
// //     person_name: "",
// //     contact: "",
// //   });

// //   useEffect(() => {
// //     fetchDatas();
// //     fetchDatased();
// //     studyingstudent();
// //     paymenttyperoute();
// //   }, []);

// //   const fetchDatas = async () => {
// //     try {
// //       const response = await axios.get(ApiUrl + "/income_type");
// //       console.log("Response from server type:", response.data);
// //       if (Array.isArray(response.data)) {
// //         setResponseDatas(response.data);
// //       } else {
// //         setResponseDatas([]);
// //       }
// //     } catch (error) {
// //       console.error("Error fetching data:", error.message);
// //       setResponseDatas([]);
// //     }
// //   };

// //   const fetchDatased = async () => {
// //     try {
// //       const response = await axios.get(ApiUrl + "/Branch");
// //       console.log("Response from server brach :", response.data);
// //       setResponseDatased(response.data);
// //     } catch (error) {
// //       console.error("Error fetching data:", error.message);
// //     }
// //   };

// //   const studyingstudent = async () => {
// //     try {
// //       const response = await axios.get(ApiUrl + "/studingstudent");
// //       console.log("Response from server:", response.data);
// //       setstudent(response.data);
// //     } catch (error) {
// //       console.error("Error fetching data:", error.message);
// //     }
// //   };

// //   const paymenttyperoute = async () => {
// //     try {
// //       const response = await axios.get(ApiUrl + "/paytype");
// //       console.log("Response from server:", response.data);
// //       setpaymenttype(response.data);
// //     } catch (error) {
// //       console.error("Error fetching data:", error.message);
// //     }
// //   };

// //   const handlesubmit = async (e) => {
// //     e.preventDefault();

// //     if (!validateFields()) {
// //     }

// //     try {
// //       const formData = {
// //         branch_id: selectedbranch.branch_name,
// //         type: addData.type || "",
// //         date: addData.date || formatDate(new Date()),
// //         description: addData.description || "",
// //         paid_by: addData.paid_by || "",
// //         paid_to: addData.paid_to || "",
// //         paytype: addData.paytype || "",
// //         amount: addData.amount || "",
// //         person_name: addData.person_name || "",
// //         contact: addData.contact || " ",
// //       };
// //       console.log("the post Formdata:", formData);
// //       const response = await axios.post(ApiUrl + "/income_list", formData);
// //       console.log("the post income list:", response);
// //       setShowModal(false);
// //       fetchData();
// //       toast.success("added successfully!");
// //       setAddData({
// //         branch_id: "",
// //         type: "",
// //         date: formatDate(new Date()),
// //         description: "",
// //         paid_by: "",
// //         paid_to: "",
// //         paytype: "",
// //         amount: "",
// //         person_name: "",
// //         contact: "",
// //       });
// //     } catch (error) {
// //       console.error("Error updating data:", error.message);
// //     }
// //   };

// //   const [updateid, setUpdateId] = useState("");

// //   // const handleUpdate = (_id, data) => {
// //   //   setUpdateId(_id);
// //   //   console.log(" the data to updata", data);
// //   //   setUpdateShowModal(true);
// //   //   setUpdateData({
// //   //     branch_id: data.branch_id,
// //   //     branch_name: data.branch_id,
// //   //     type: data.type,
// //   //     typeid: data.typeid,
// //   //     date: data.date,
// //   //     description: data.description,
// //   //     paid_by: data.paid_by,
// //   //     paid_to: data.paid_to,
// //   //     paytype: data.paytype,
// //   //     amount: data.amount,
// //   //     person_name: data.person_name,
// //   //     contact: data.contact,
// //   //   });
// //   //   console.log(" the data to updata1212", updateData.type);
// //   // };

// //   const setCommonData = (data) => {
// //     const commonData = {
// //       branch_id: data.branch_id,
// //       branch_name: data.branch_id,
// //       type: data.type,
// //       typeid: data.typeid,
// //       date: data.date,
// //       description: data.description,
// //       paid_by: data.paid_by,
// //       paid_to: data.paid_to,
// //       paytype: data.paytype,
// //       amount: data.amount,
// //       person_name: data.person_name,
// //       contact: data.contact,
// //     };
// //     console.log("Setting Data: ", commonData);
// //     setUpdateData(commonData);
// //   };

// //   const handleUpdate = (_id, data) => {
// //     setUpdateId(_id);
// //     setUpdateShowModal(true);
// //     setCommonData(data);
// //   };

// //   const handlePreview = (data) => {
// //     setCommonData(data);
// //     setShowPreview(true)
// //   }

// //   const handleupdatesubmit = async (e) => {
// //     e.preventDefault();
// //     try {
// //       const formData = {
// //         branch_id: updateData.branch_id,
// //         type: updateData.type,
// //         date: updateData.date,
// //         description: updateData.description,
// //         paid_by: updateData.paid_by,
// //         paid_to: updateData.paid_to,
// //         paytype: updateData.paytype,
// //         amount: updateData.amount,
// //         person_name: updateData.person_name,
// //         contact: updateData.contact,
// //       };
// //       console.log("the update submit data", formData);
// //       const response = await axios.put(
// //         ApiUrl + `/income_list/${updateid}`,
// //         formData
// //       );
// //       console.log("response for update", response);
// //       setUpdateShowModal(false);
// //       toast.success("Update successfully!");
// //       fetchData();
// //     } catch (error) {
// //       console.error("Error updating data:", error.message);
// //     }
// //   };

// //   const handleDelete = async (id) => {
// //     try {
// //       await axios.delete(ApiUrl + `/income_list/${getdeleteid}`);
// //       toast.error("Delete successfully!");
// //       setDeleteShowModals(false);
// //       fetchData();
// //     } catch (error) {
// //       console.error("Error deleting role:", error.message);
// //     }
// //   };

// //   const handleRoleChange = (event) => {
// //     setSelectedRole(event.target.value);
// //   };

// //   const [deleteShowModals, setDeleteShowModals] = useState(false);
// //   const [showPreview, setShowPreview] = useState(false);
// //   const handleCloseModal = () => {
// //     setDeleteShowModals(false);
// //   };

// //   const [getdeleteid, setdeleteid] = useState("");
// //   const [getdeletename, setdeletename] = useState("");
// //   const handleOpenModal = (_id, description) => {
// //     console.log(" the id", _id);
// //     console.log(" the name", description);
// //     setdeleteid(_id);
// //     setdeletename(description);
// //     setDeleteShowModals(true);
// //   };

// //   // the pagination ---------------------------------------------------------------------
// //   // const [currentPage, setCurrentPage] = useState(1);
// //   // const [itemsPerPage, setItemsPerPage] = useState(10);
// //   // const [searchQuery, setSearchQuery] = useState("");
// //   // // Pagination
// //   // const indexOfLastItem = currentPage * itemsPerPage;
// //   // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
// //   // const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

// //   // const paginate = (pageNumber) => setCurrentPage(pageNumber);

// //   // // Search
// //   // const filtered = currentItems.filter((item) =>
// //   //   Object.values(item).some(
// //   //     (value) =>
// //   //       (typeof value === "string" &&
// //   //         value.toLowerCase().includes(searchQuery.toLowerCase())) ||
// //   //       (typeof value === "number" && value.toString().includes(searchQuery))
// //   //   )
// //   // );

// //   // const handleSearchChange = (event) => {
// //   //   setSearchQuery(event.target.value);
// //   //   setCurrentPage(1);
// //   // };

// //   // const totalPages = Math.ceil(responseData.length / itemsPerPage);

// //   // const goToNextPage = () => {
// //   //   setCurrentPage((prevPage) =>
// //   //     prevPage === totalPages ? prevPage : prevPage + 1
// //   //   );
// //   // };

// //   // const goToPrevPage = () => {
// //   //   setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
// //   // };

// //   const createPagination = () => {
// //     let liTag = [];
// //     let totalPages = pageCount; // Total pages
// //     let currentPage = state.pageIndex;
// //     let active;

// //     // Previous Page Button
// //     liTag.push(
// //       <li
// //         className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
// //         key="prev"
// //       >
// //         <button
// //           className="page-link"
// //           onClick={() => gotoPage(currentPage - 1)}
// //           disabled={!canPreviousPage}
// //         >
// //           <FaAngleLeft /> Prev
// //         </button>
// //       </li>
// //     );

// //     // Always show first page
// //     if (totalPages > 1) {
// //       liTag.push(
// //         <li
// //           className={`page-item ${currentPage === 0 ? "active" : ""}`}
// //           key={0}
// //         >
// //           <button className="page-link" onClick={() => gotoPage(0)}>
// //             1
// //           </button>
// //         </li>
// //       );
// //     }

// //     // Show second page if total pages > 2
// //     if (totalPages > 2) {
// //       liTag.push(
// //         <li
// //           className={`page-item ${currentPage === 1 ? "active" : ""}`}
// //           key={1}
// //         >
// //           <button className="page-link" onClick={() => gotoPage(1)}>
// //             2
// //           </button>
// //         </li>
// //       );
// //     }

// //     // Add "..." before current page group
// //     if (currentPage > 3) {
// //       liTag.push(
// //         <li className="page-item disabled" key="dot-prev">
// //           <span className="page-link">...</span>
// //         </li>
// //       );
// //     }

// //     // Show 3 pages around current page
// //     for (
// //       let i = Math.max(2, currentPage - 1);
// //       i <= Math.min(totalPages - 3, currentPage + 1);
// //       i++
// //     ) {
// //       active = currentPage === i ? "active" : "";
// //       liTag.push(
// //         <li className={`page-item ${active}`} key={i}>
// //           <button className="page-link" onClick={() => gotoPage(i)}>
// //             {i + 1}
// //           </button>
// //         </li>
// //       );
// //     }

// //     // Add "..." after current page group
// //     if (currentPage < totalPages - 4) {
// //       liTag.push(
// //         <li className="page-item disabled" key="dot-next">
// //           <span className="page-link">...</span>
// //         </li>
// //       );
// //     }

// //     // Always show last two pages if totalPages > 2
// //     if (totalPages > 2) {
// //       liTag.push(
// //         <li
// //           className={`page-item ${
// //             currentPage === totalPages - 2 ? "active" : ""
// //           }`}
// //           key={totalPages - 2}
// //         >
// //           <button
// //             className="page-link"
// //             onClick={() => gotoPage(totalPages - 2)}
// //           >
// //             {totalPages - 1}
// //           </button>
// //         </li>
// //       );
// //     }

// //     if (totalPages > 1) {
// //       liTag.push(
// //         <li
// //           className={`page-item ${
// //             currentPage === totalPages - 1 ? "active" : ""
// //           }`}
// //           key={totalPages - 1}
// //         >
// //           <button
// //             className="page-link"
// //             onClick={() => gotoPage(totalPages - 1)}
// //           >
// //             {totalPages}
// //           </button>
// //         </li>
// //       );
// //     }

// //     // Next Page Button
// //     liTag.push(
// //       <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
// //         <button
// //           className="page-link"
// //           onClick={() => gotoPage(currentPage + 1)}
// //           disabled={!canNextPage}
// //         >
// //           Next <FaAngleRight />
// //         </button>
// //       </li>
// //     );

// //     return liTag;
// //   };

// //   // the pagination  end ---------------------------------------------------------------------

// //   const navigate = useNavigate();

// //   const handleIncomeType = () => {
// //     navigate("/incometype");
// //   };

// //   const handleBranchSelection = (e) => {
// //     console.log(responseDatased, "responseDatased", e.target.value);

// //     // Ensure the types of e.target.value and branch_id are the same
// //     const selectedBranch = responseDatased.find(
// //       (item) => item.branch_name === e.target.value.toString()
// //     );

// //     if (selectedBranch) {
// //       console.log("The selected branch is:", selectedBranch);
// //       setselectedbranch({
// //         branch_id: selectedBranch.branch_id,
// //         branch_name: selectedBranch.branch_name,
// //       });
// //     } else {
// //       console.log("No matching branch found.");
// //     }
// //   };

// //   const [selectedbranch, setselectedbranch] = useState({
// //     branch_id: "",
// //     branch_name: "",
// //   });

// //   const [errors, setErrors] = useState({});

// //   const validateFields = () => {
// //     let tempErrors = {};
// //     let isValid = true;

// //     if (!addData.branch_id) {
// //       tempErrors.branch_id = "Branch Name is required.";
// //       isValid = false;
// //     }

// //     if (!addData.type) {
// //       tempErrors.type = "Type is required.";
// //       isValid = false;
// //     }

// //     if (!addData.date) {
// //       tempErrors.date = "Date is required.";
// //       isValid = false;
// //     }

// //     if (!addData.description) {
// //       tempErrors.description = "Description is required.";
// //       isValid = false;
// //     }

// //     if (!addData.paid_by) {
// //       tempErrors.paid_by = "Paid By is required.";
// //       isValid = false;
// //     }

// //     if (!addData.paid_to) {
// //       tempErrors.paid_to = "Paid To is required.";
// //       isValid = false;
// //     }

// //     if (!addData.paytype) {
// //       tempErrors.paytype = "Paytype is required.";
// //       isValid = false;
// //     }

// //     if (!addData.amount) {
// //       tempErrors.amount = "Amount is required.";
// //       isValid = false;
// //     }
// //     setErrors(tempErrors);
// //     return isValid;
// //   };

// //   const formatDatefitching = (isoDate) => {
// //     if (!isoDate) return ""; // Return an empty string if no date is provided
// //     const date = new Date(isoDate);

// //     const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
// //     const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
// //     const year = date.getFullYear();

// //     return `${day}-${month}-${year}`; // Format as "day-month-year"
// //   };

// //   return (
// //     <>
// //       <div className="" style={{ marginTop: "-80px" }}>
// //         <div className="row mb-3">
// //           <div className="col-lg-11">
// //             <Link to="/dashboard" className="text-secondary dashBoard_text">
// //               <b>Dashboard / </b>
// //             </Link>
// //             <Link to="" className="text-danger dashBoard_text">
// //               <b>Income List</b>
// //             </Link>
// //           </div>
// //         </div>
// //       </div>

// //       <div className="row">
// //         <div className="col-lg-10">
// //           <div className="d-flex">
// //             <h6 className="mt-2 bold">Income List</h6>
// //             <div className="">
// //               {/* {previllages.add && ( */}
// //               <button
// //                 className="btn btn-dark btn-sm add mx-3"
// //                 onClick={() => setShowModal(true)}
// //               >
// //                 Add
// //               </button>
// //               {/* )} */}
// //             </div>
// //           </div>
// //         </div>
// //         <div className="col-lg-2">
// //           <button className="btn btn-secondary" onClick={handleIncomeType}>
// //             Income Type
// //           </button>
// //         </div>
// //       </div>

// //       {/* ------------ search and per page ----------------------------*/}

// //       {/* <div className="mb-3 mt-3">
// //         <div className="row">
// //           <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
// //             <label className="form-label text-dark mt-2">Show : </label>
// //             <div>
// //               <select
// //                 className="form-control w-100 mx-2"
// //                 onChange={(e) => {
// //                   setItemsPerPage(e.target.value);
// //                 }}
// //               >
// //                 <option value="10">10</option>
// //                 <option value="20">20</option>
// //                 <option value="50">50</option>
// //               </select>
// //             </div>
// //           </div>
// //           <div className="col-lg-7 col-md-4 col-sm-1"></div>
// //           <div className="col-lg-3 col-md-4 col-sm-7">
// //             <div className="w-full flex-1">
// //               <form>
// //                 <div className="relative">
// //                   <input
// //                     className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
// //                     placeholder="Search ..."
// //                     type="search"
// //                     value={searchQuery}
// //                     onChange={handleSearchChange}
// //                   />
// //                 </div>
// //               </form>
// //             </div>
// //           </div>
// //         </div>
// //       </div>

// //       <div className="card">
// //         <div className="mt-1">
// //           <table className="table table-striped">
// //             <thead>
// //               <tr>
// //                 <th>S.No</th>
// //                 <th>Branch</th>
// //                 <th>Type</th>
// //                 <th>Date</th>
// //                 <th>Paid By</th>
// //                 <th>Paid To</th>
// //                 <th>Pay Type</th>
// //                 <th>Amount</th>

// //                 <th className="text-center">Action</th>
// //               </tr>
// //             </thead>
// //             <tbody>
// //               {filtered.map((item, index) => (
// //                 <tr key={index}>
// //                   <td>{index + 1}</td>
// //                   <td>{item.branch_id}</td>
// //                   <td>{item.type}</td>
// //                   <td>{formatDatefitching(item.date)}</td>
// //                   <td>{item.paid_by}</td>
// //                   <td>{item.paid_to}</td>
// //                   <td>{item.paytype}</td>
// //                   <td>{item.amount}</td>

// //                   <td className="text-center">
// //                     <button
// //                       className="btn updelete mx-2"
// //                       onClick={() => handleUpdate(item._id, item)}
// //                     >
// //                       <i class="fa-solid fa-pen-to-square"></i>
// //                     </button>

// //                     <button
// //                       className="btn delete"
// //                       onClick={() =>
// //                         handleOpenModal(item._id, item.description)
// //                       }
// //                     >
// //                       <i class="fa-solid fa-trash-can"></i>
// //                     </button>
// //                   </td>
// //                 </tr>
// //               ))}
// //             </tbody>
// //           </table>
// //         </div>
// //       </div> */}

// //       <div className="mb-3 mt-3">
// //         <div className="row">
// //           <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
// //             <label className="form-label text-dark mt-2">Show : </label>
// //             <div>
// //               <select
// //                 className="form-control w-100 mx-2"
// //                 value={state.pageSize}
// //                 onChange={(e) => setPageSize(Number(e.target.value))}
// //               >
// //                 {[10, 20, 50].map((pageSize) => (
// //                   <option key={pageSize} value={pageSize}>
// //                     {pageSize}
// //                   </option>
// //                 ))}
// //               </select>
// //             </div>
// //           </div>
// //           <div className="col-lg-7 col-md-4 col-sm-1"></div>
// //           <div className="col-lg-3 col-md-4 col-sm-7">
// //             <div className="w-full flex-1">
// //               <form>
// //                 <div className="relative">
// //                   <input
// //                     className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
// //                     placeholder="Search ..."
// //                     type="search"
// //                     value={state.globalFilter || ""} // Use the globalFilter value
// //                     onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
// //                   />
// //                 </div>
// //               </form>
// //             </div>
// //           </div>
// //         </div>
// //       </div>

// //       <TableContainer>
// //         <Table {...getTableProps()}>
// //           <thead>
// //             {headerGroups.map((headerGroup) => (
// //               <tr {...headerGroup.getHeaderGroupProps()}>
// //                 {headerGroup.headers.map((column) => (
// //                   <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
// //                     <div className="d-flex">
// //                       <div className="mx-2">{column.render("Header")}</div>
// //                       <div>
// //                         {column.canFilter ? column.render("Filter") : null}
// //                       </div>
// //                       <div className="mx-1">
// //                         {column.isSorted
// //                           ? column.isSortedDesc
// //                             ? " 🔽"
// //                             : " 🔼"
// //                           : ""}
// //                       </div>
// //                     </div>
// //                   </Th>
// //                 ))}
// //                 <Th className="text-center">Action</Th>
// //               </tr>
// //             ))}
// //           </thead>
// //           <tbody {...getTableBodyProps()}>
// //             {page.map((row) => {
// //               prepareRow(row);
// //               return (
// //                 <tr {...row.getRowProps()}>
// //                   {row.cells.map((cell) => (
// //                     <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
// //                   ))}
// //                   <Td className="text-center">
// //                     <button
// //                       className="btn custom-btn-update btn-sm"
// //                       onClick={() =>
// //                         handleUpdate(row.original._id, row.original)
// //                       }
// //                     >
// //                       <i className="fa-solid fa-pen-to-square"></i>
// //                     </button>

// //                     <button
// //                       className="btn custom-btn-delete btn-sm mx-1"
// //                       onClick={() =>
// //                         handleOpenModal(
// //                           row.original._id,
// //                           row.original.description
// //                         )
// //                       }
// //                     >
// //                       <i className="fa-solid fa-trash-can"></i>
// //                     </button>

// //                     {/* <button className="btn btn-sm btn-info mx-2" onClick={() => setShowPreview(true)}>
// //                     <i class="fa-solid fa-eye"></i>
// //                     </button> */}
// //                     <button
// //                       className="btn custom-btn-info btn-sm"
// //                       onClick={() => handlePreview(row.original)}
// //                     >
// //                       <i class="fa-solid fa-eye"></i>
// //                     </button>
// //                   </Td>
// //                 </tr>
// //               );
// //             })}
// //           </tbody>
// //         </Table>
// //       </TableContainer>

// //       <Modal
// //         show={showModal}
// //         onHide={() => setShowModal(false)}
// //         className="custom-modal"
// //       >
// //         <Modal.Header closeButton>
// //           <Modal.Title>Add Income List</Modal.Title>
// //         </Modal.Header>
// //         <Modal.Body>
// //           <div className="row">
// //             <div className="col-lg-6">
// //               <div className="form-group">
// //                 <div className="floating-label">
// //                   <select
// //                     className="form-select"
// //                     value={selectedbranch.branch_name} // Controlled by state
// //                     onChange={(e) => handleBranchSelection(e)} // Handles branch selection
// //                     required
// //                   >
// //                     <option value="">Select Branch</option>
// //                     {responseDatased.map((item, index) => (
// //                       <option key={index} value={item.branch_name}>
// //                         {`${item.branch_id}-${item.branch_name}`}
// //                       </option>
// //                     ))}
// //                   </select>

// //                   <label className={selectedbranch.branch_name ? "filled" : ""}>
// //                     Select Branch
// //                   </label>
// //                 </div>

// //                 <div className="floating-label">
// //                   <label className="form-label">
// //                     <b></b>
// //                   </label>
// //                   <select
// //                     className="form-select"
// //                     placeholder=""
// //                     value={addData.type}
// //                     onChange={(e) =>
// //                       setAddData({ ...addData, type: e.target.value })
// //                     }
// //                   >
// //                     <option value="">Select Type</option>
// //                     {Array.isArray(responseDatas) &&
// //                       responseDatas.map((item, index) => (
// //                         <option key={index} value={item._id}>
// //                           {item.type}
// //                         </option>
// //                       ))}
// //                   </select>

// //                   <label className={addData.type ? "filled" : ""}>
// //                     Select type
// //                   </label>
// //                   {errors.type && (
// //                     <div className="error-text">{errors.type}</div>
// //                   )}
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="date"
// //                     placeholder=""
// //                     value={addData.date}
// //                     onChange={handleDateChange}
// //                     required
// //                   />
// //                   <label className={addData.date ? "filled" : ""}>Date</label>
// //                   {errors.date && (
// //                     <div className="error-text">{errors.date}</div>
// //                   )}
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={addData.description}
// //                     onChange={(e) =>
// //                       setAddData({ ...addData, description: e.target.value })
// //                     }
// //                     required
// //                   />
// //                   <label className={addData.description ? "filled" : ""}>
// //                     Notes
// //                   </label>
// //                   {errors.description && (
// //                     <div className="error-text">{errors.description}</div>
// //                   )}
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={addData.paid_to}
// //                     onChange={(e) =>
// //                       setAddData({ ...addData, paid_to: e.target.value })
// //                     }
// //                     required
// //                   />
// //                   <label className={addData.paid_to ? "filled" : ""}>
// //                     Insititue / Client
// //                   </label>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="col-lg-6">
// //               <div className="form-group">
// //                 <div className="floating-label">
// //                   <label className="form-label">
// //                     <b></b>
// //                   </label>
// //                   <select
// //                     className="form-control"
// //                     value={addData.paid_by}
// //                     onChange={(e) =>
// //                       setAddData({ ...addData, paid_by: e.target.value })
// //                     }
// //                   >
// //                     <option value="">Select Paid By</option>
// //                     <option value="Client">Client</option>
// //                     <option value="College">College</option>
// //                     <option value="School">School</option>
// //                     <option value="Others">Others</option>
// //                   </select>

// //                   <label className={addData.paid_by ? "filled" : ""}>
// //                     Select Paid By
// //                   </label>
// //                   {errors.paid_by && (
// //                     <div className="error-text">{errors.paid_by}</div>
// //                   )}
// //                 </div>

// //                 <div className="floating-label">
// //                   <label className="form-label">
// //                     <b></b>
// //                   </label>
// //                   <select
// //                     className="form-select"
// //                     value={addData.paytype}
// //                     onChange={(e) =>
// //                       setAddData({ ...addData, paytype: e.target.value })
// //                     }
// //                     required
// //                   >
// //                     <option value="">Select Paytype </option>
// //                     {paymenttype.map((item, index) => (
// //                       <option key={index} value={item.Payment_type}>
// //                         {item.Payment_type}
// //                       </option>
// //                     ))}
// //                   </select>
// //                   <label className={addData.paytype ? "filled" : ""}></label>
// //                   {errors.paytype && (
// //                     <div className="error-text">{errors.paytype}</div>
// //                   )}
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={addData.amount}
// //                     onChange={(e) =>
// //                       setAddData({ ...addData, amount: e.target.value })
// //                     }
// //                     required
// //                   />
// //                   <label className={addData.amount ? "filled" : ""}>
// //                     Amount
// //                   </label>
// //                   {errors.amount && (
// //                     <div className="error-text">{errors.amount}</div>
// //                   )}
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={addData.person_name}
// //                     onChange={(e) =>
// //                       setAddData({ ...addData, person_name: e.target.value })
// //                     }
// //                     required
// //                   />
// //                   <label className={addData.person_name ? "filled" : ""}>
// //                     Person Name
// //                   </label>
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="tel"
// //                     placeholder=""
// //                     value={addData.contact}
// //                     onChange={(e) =>
// //                       setAddData({ ...addData, contact: e.target.value })
// //                     }
// //                     required
// //                   />
// //                   <label className={addData.contact ? "filled" : ""}>
// //                     Contact
// //                   </label>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         </Modal.Body>

// //         <Modal.Footer>
// //           <Button
// //             className="cancelbut"
// //             variant="secondary"
// //             onClick={() => setShowModal(false)}
// //           >
// //             Cancel
// //           </Button>
// //           <Button
// //             className="submitbut"
// //             variant="primary"
// //             onClick={handlesubmit}
// //           >
// //             {" "}
// //             Submit
// //           </Button>
// //         </Modal.Footer>
// //       </Modal>

// //       <Modal
// //         show={isUpdateshowModal}
// //         onHide={() => setUpdateShowModal(false)}
// //         className="custom-modal"
// //       >
// //         <Modal.Header closeButton>
// //           <Modal.Title>Update Income List</Modal.Title>
// //         </Modal.Header>
// //         <Modal.Body>
// //           <div className="row">
// //             <div className="col-lg-6">
// //               <div className="form-group">
// //                 <div className="floating-label">
// //                   <select
// //                     className="form-select"
// //                     value={updateData.branch_name}
// //                     onChange={(e) =>
// //                       setUpdateData({
// //                         ...updateData,
// //                         branch_id: e.target.value,
// //                       })
// //                     }
// //                     required
// //                   >
// //                     <option value="">Select Branch</option>
// //                     {responseDatased.map((item, index) => (
// //                       <option key={index} value={item.branch_name}>
// //                         {`${item.branch_id}-${item.branch_name}`}
// //                       </option>
// //                     ))}
// //                   </select>

// //                   <label
// //                     className={updateData.branch_id ? "filled" : ""}
// //                   ></label>
// //                 </div>
// //                 {/* <p>{updateData.type}</p> */}
// //                 <div className="floating-label">
// //                   <select
// //                     className="form-select"
// //                     value={updateData.typeid}
// //                     placeholder=""
// //                     onChange={(e) =>
// //                       setUpdateData({ ...updateData, type: e.target.value })
// //                     }
// //                   >
// //                     {responseDatas.map((item, index) => (
// //                       <option key={index} value={item._id}>
// //                         {item.type}
// //                       </option>
// //                     ))}
// //                   </select>
// //                   <label className={updateData.type ? "filled" : ""}></label>
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="date"
// //                     placeholder=""
// //                     value={updateData.date}
// //                     onChange={(e) =>
// //                       setUpdateData({
// //                         ...updateData,
// //                         date: e.target.value,
// //                       })
// //                     }
// //                     required
// //                   />
// //                   <label className={updateData.date ? "filled" : ""}>
// //                     Date
// //                   </label>
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={updateData.description}
// //                     onChange={(e) =>
// //                       setUpdateData({
// //                         ...updateData,
// //                         description: e.target.value,
// //                       })
// //                     }
// //                     required
// //                   />
// //                   <label className={updateData.description ? "filled" : ""}>
// //                     Notes
// //                   </label>
// //                 </div>

// //                 {/* paid_till_date */}

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={updateData.paid_to}
// //                     onChange={(e) =>
// //                       setUpdateData({ ...updateData, paid_to: e.target.value })
// //                     }
// //                     required
// //                   />
// //                   <label className={updateData.paid_to ? "filled" : ""}>
// //                     {" "}
// //                     Insititue / Client
// //                   </label>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="col-lg-6">
// //               <div className="form-group">
// //                 <div className="floating-label">
// //                   <select
// //                     className="form-control"
// //                     value={updateData.paid_by}
// //                     onChange={(e) =>
// //                       setUpdateData({ ...updateData, paid_by: e.target.value })
// //                     }
// //                   >
// //                     <option value="Client">Client</option>
// //                     <option value="College">College</option>
// //                     <option value="School">School</option>
// //                     <option value="Others">Others</option>
// //                   </select>
// //                   <label className={updateData.paid_by ? "filled" : ""}>
// //                     Paid By
// //                   </label>
// //                 </div>

// //                 <div className="floating-label">
// //                   <label className="form-label">
// //                     <b></b>
// //                   </label>
// //                   <select
// //                     className="form-select"
// //                     value={updateData.paytype}
// //                     onChange={(e) =>
// //                       setUpdateData({ ...updateData, paytype: e.target.value })
// //                     }
// //                     required
// //                   >
// //                     <option value="">Select Paid To</option>
// //                     {paymenttype.map((item, index) => (
// //                       <option key={index} value={item.Payment_type}>
// //                         {item.Payment_type}
// //                       </option>
// //                     ))}
// //                   </select>
// //                   <label className={updateData.paytype ? "filled" : ""}></label>
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={updateData.amount}
// //                     onChange={(e) =>
// //                       setUpdateData({ ...updateData, amount: e.target.value })
// //                     }
// //                     required
// //                   />
// //                   <label className={updateData.amount ? "filled" : ""}>
// //                     Amount
// //                   </label>
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={updateData.person_name}
// //                     onChange={(e) =>
// //                       setUpdateData({
// //                         ...updateData,
// //                         person_name: e.target.value,
// //                       })
// //                     }
// //                     required
// //                   />
// //                   <label className={updateData.person_name ? "filled" : ""}>
// //                     Person Name
// //                   </label>
// //                 </div>

// //                 {/* contact */}

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={updateData.contact}
// //                     onChange={(e) =>
// //                       setUpdateData({ ...updateData, contact: e.target.value })
// //                     }
// //                     required
// //                   />
// //                   <label className={updateData.contact ? "filled" : ""}>
// //                     Contact
// //                   </label>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         </Modal.Body>

// //         <Modal.Footer>
// //           <Button
// //             className="cancelbut"
// //             variant="secondary"
// //             onClick={() => setUpdateShowModal(false)}
// //           >
// //             Cancel
// //           </Button>
// //           <Button
// //             className="submitbut"
// //             variant="primary"
// //             onClick={handleupdatesubmit}
// //           >
// //             Update
// //           </Button>
// //         </Modal.Footer>
// //       </Modal>

// //       {/* --------------------------- preview */}

// //       <Modal
// //         show={showPreview}
// //         onHide={() => setShowPreview(false)}
// //         className="custom-modal"
// //       >
// //         <Modal.Header closeButton>
// //           <Modal.Title>Preview Income List</Modal.Title>
// //         </Modal.Header>
// //         <Modal.Body>
// //           <div className="row">
// //             <div className="col-lg-6">
// //               <div className="form-group">
// //                 <div className="floating-label">
// //                   <select
// //                     className="form-select"
// //                     value={updateData.branch_name}
// //                     onChange={(e) =>
// //                       setUpdateData({
// //                         ...updateData,
// //                         branch_id: e.target.value,
// //                       })
// //                     }
// //                     disabled
// //                   >
// //                     <option value="">Select Branch</option>
// //                     {responseDatased.map((item, index) => (
// //                       <option key={index} value={item.branch_name}>
// //                         {`${item.branch_id}-${item.branch_name}`}
// //                       </option>
// //                     ))}
// //                   </select>

// //                   <label
// //                     className={updateData.branch_id ? "filled" : ""}
// //                   ></label>
// //                 </div>
// //                 {/* <p>{updateData.type}</p> */}
// //                 <div className="floating-label">
// //                   <select
// //                     className="form-select"
// //                     value={updateData.typeid}
// //                     placeholder=""
// //                     onChange={(e) =>
// //                       setUpdateData({ ...updateData, type: e.target.value })
// //                     }
// //                     disabled
// //                   >
// //                     {responseDatas.map((item, index) => (
// //                       <option key={index} value={item._id}>
// //                         {item.type}
// //                       </option>
// //                     ))}
// //                   </select>
// //                   <label className={updateData.type ? "filled" : ""}></label>
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="date"
// //                     placeholder=""
// //                     value={updateData.date}
// //                     onChange={(e) =>
// //                       setUpdateData({
// //                         ...updateData,
// //                         date: e.target.value,
// //                       })
// //                     }
// //                     disabled
// //                   />
// //                   <label className={updateData.date ? "filled" : ""}>
// //                     Date
// //                   </label>
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={updateData.description}
// //                     onChange={(e) =>
// //                       setUpdateData({
// //                         ...updateData,
// //                         description: e.target.value,
// //                       })
// //                     }
// //                     disabled
// //                   />
// //                   <label className={updateData.description ? "filled" : ""}>
// //                     Notes
// //                   </label>
// //                 </div>

// //                 {/* paid_till_date */}

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={updateData.paid_to}
// //                     onChange={(e) =>
// //                       setUpdateData({ ...updateData, paid_to: e.target.value })
// //                     }
// //                     disabled
// //                   />
// //                   <label className={updateData.paid_to ? "filled" : ""}>
// //                     {" "}
// //                     Insititue / Client
// //                   </label>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="col-lg-6">
// //               <div className="form-group">
// //                 <div className="floating-label">
// //                   <select
// //                     className="form-control"
// //                     value={updateData.paid_by}
// //                     onChange={(e) =>
// //                       setUpdateData({ ...updateData, paid_by: e.target.value })
// //                     }
// //                     disabled
// //                   >
// //                     <option value="Client">Client</option>
// //                     <option value="College">College</option>
// //                     <option value="School">School</option>
// //                     <option value="Others">Others</option>
// //                   </select>
// //                   <label className={updateData.paid_by ? "filled" : ""}>
// //                     Paid By
// //                   </label>
// //                 </div>

// //                 <div className="floating-label">
// //                   <label className="form-label">
// //                     <b></b>
// //                   </label>
// //                   <select
// //                     className="form-select"
// //                     value={updateData.paytype}
// //                     onChange={(e) =>
// //                       setUpdateData({ ...updateData, paytype: e.target.value })
// //                     }
// //                     disabled
// //                   >
// //                     <option value="">Select Paid To</option>
// //                     {paymenttype.map((item, index) => (
// //                       <option key={index} value={item.Payment_type}>
// //                         {item.Payment_type}
// //                       </option>
// //                     ))}
// //                   </select>
// //                   <label className={updateData.paytype ? "filled" : ""}></label>
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={updateData.amount}
// //                     onChange={(e) =>
// //                       setUpdateData({ ...updateData, amount: e.target.value })
// //                     }
// //                     disabled
// //                   />
// //                   <label className={updateData.amount ? "filled" : ""}>
// //                     Amount
// //                   </label>
// //                 </div>

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={updateData.person_name}
// //                     onChange={(e) =>
// //                       setUpdateData({
// //                         ...updateData,
// //                         person_name: e.target.value,
// //                       })
// //                     }
// //                     disabled
// //                   />
// //                   <label className={updateData.person_name ? "filled" : ""}>
// //                     Person Name
// //                   </label>
// //                 </div>

// //                 {/* contact */}

// //                 <div className="floating-label">
// //                   <input
// //                     className="form-control"
// //                     type="text"
// //                     placeholder=""
// //                     value={updateData.contact}
// //                     onChange={(e) =>
// //                       setUpdateData({ ...updateData, contact: e.target.value })
// //                     }
// //                     disabled
// //                   />
// //                   <label className={updateData.contact ? "filled" : ""}>
// //                     Contact
// //                   </label>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         </Modal.Body>

// //         <Modal.Footer>
// //           <Button
// //             className="cancelbut"
// //             variant="secondary"
// //             onClick={() => setShowPreview(false)}
// //           >
// //             Cancel
// //           </Button>
// //         </Modal.Footer>
// //       </Modal>

// //       {/* ----------------delete */}

// //       <Modal
// //         className="modal"
// //         show={deleteShowModals}
// //         onHide={handleCloseModal}
// //       >
// //         <Modal.Header>
// //           <p>Are you sure you want to Delete?</p>
// //         </Modal.Header>
// //         <Modal.Body>
// //           <label className="form-label d-flex">
// //             <h5 className="mx-1">Description :</h5> <h5>{getdeletename} </h5>
// //           </label>
// //         </Modal.Body>
// //         <Modal.Footer>
// //           <Button
// //             className="cancelbut"
// //             variant="secondary"
// //             onClick={handleCloseModal}
// //           >
// //             Close
// //           </Button>
// //           <Button
// //             className="submitbut"
// //             variant="primary"
// //             onClick={handleDelete}
// //           >
// //             Delete
// //           </Button>
// //         </Modal.Footer>
// //       </Modal>

// //       {/* _------------------------------pagination button -------------------------------- */}
// //       {/* <div className="mt-3 pb-5">
// //         <div className="row">
// //           <div className="col-lg-10 col-sm-12">
// //             <nav className="d-flex float-right">
// //               <ul className="pagination justify-content-center">
// //                 <li
// //                   className={` page-item ${
// //                     currentPage === 1 ? "disabled" : ""
// //                   }`}
// //                 >
// //                   <button className="page-link" onClick={goToPrevPage}>
// //                     Previous
// //                   </button>
// //                 </li>
// //                 {Array.from({ length: totalPages }, (_, i) => (
// //                   <li
// //                     key={i}
// //                     className={`mx-2 page-item ${
// //                       currentPage === i + 1 ? "active" : ""
// //                     }`}
// //                   >
// //                     <button
// //                       className="page-link "
// //                       onClick={() => paginate(i + 1)}
// //                     >
// //                       {i + 1}
// //                     </button>
// //                   </li>
// //                 ))}
// //                 <li
// //                   className={`page-item ${
// //                     currentPage === totalPages ? "disabled" : ""
// //                   }`}
// //                 >
// //                   <button className="page-link " onClick={goToNextPage}>
// //                     Next
// //                   </button>
// //                 </li>
// //               </ul>
// //             </nav>{" "}
// //           </div>
// //           <div className="col-lg-2 col-sm-12 mt-1">
// //             <div className="float-end">
// //               <span className="text-dark ">
// //                 Page {currentPage} of {totalPages}
// //               </span>
// //             </div>
// //           </div>
// //         </div>
// //       </div> */}

// //       <div className="mt-3 pb-5">
// //         <div className="row">
// //           <div className="col-lg-7 col-sm-12 mt-1">
// //             <span className="text-dark">
// //               Page {state.pageIndex + 1} of {pageCount}
// //             </span>
// //           </div>
// //           <div className="col-lg-5">
// //             <div className="mt-3 pb-5">
// //               <nav>
// //                 <ul className="pagination justify-content-center">
// //                   {createPagination()}
// //                 </ul>
// //               </nav>
// //             </div>
// //           </div>
// //         </div>
// //       </div>

// //       {/* ---------------------------------------end ---------------------------------- */}
// //     </>
// //   );
// // }

// // export default IncomeList;

// import React, { useMemo, useState, useEffect } from "react";
// import axios from "axios";
// import { Modal, Button } from "react-bootstrap";
// import ApiUrl from "./../Environment/ApiUrl";
// import "../../assets/css/Common.css";
// import { toast } from "react-toastify";
// import { Link, useNavigate } from "react-router-dom";
// import Select from "react-select";
// import styled from "styled-components";
// import {
//   useTable,
//   useFilters,
//   useGlobalFilter,
//   useSortBy,
//   usePagination,
// } from "react-table";
// import { createPortal } from "react-dom";
// import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

// const TableContainer = styled.div`
//   padding: 20px;
//   overflow-x: auto;
// `;

// const Table = styled.table`
//   width: 100%;
//   border-collapse: collapse;
//   border: 1px solid #ddd;

//   // Apply striped rows styling
//   tr:nth-child(even) {
//     background-color: #ffffff; /* Light gray for even rows */
//   }

//   tr:nth-child(odd) {
//     background-color: #f2f2f2; /* White for odd rows */
//   }
// `;

// const Th = styled.th`
//   padding: 10px;
//   background-color: #ffffff;
//   text-align: left;
//   cursor: pointer;
//   position: relative;
// `;

// const Td = styled.td`
//   padding: 10px;
//   // border: 1px solid #ddd;
// `;

// function GlobalFilter({ globalFilter, setGlobalFilter }) {
//   return (
//     <div style={{ marginBottom: "10px" }}>
//       <input
//         value={globalFilter || ""}
//         onChange={(e) => setGlobalFilter(e.target.value)}
//         placeholder="Search all columns..."
//         style={{
//           padding: "5px",
//           border: "1px solid #ddd",
//           borderRadius: "4px",
//           width: "100%",
//         }}
//       />
//     </div>
//   );
// }
// // Default Column Filter (Icon-based)
// const DefaultColumnFilter = ({
//   column: { filterValue, setFilter, preFilteredRows, id },
// }) => {
//   const [isActive, setIsActive] = useState(false);
//   const [selectedValue, setSelectedValue] = useState(null);
//   const options = useMemo(() => {
//     const uniqueValues = Array.from(
//       new Set(preFilteredRows.map((row) => row.values[id]))
//     ).map((value) => ({ label: value, value }));
//     return [{ label: "All", value: "" }, ...uniqueValues];
//   }, [id, preFilteredRows]);

//   // Handle icon click
//   const handleIconClick = () => {
//     setIsActive(!isActive);
//   };

//   const handleMultiSelectChange = (selectedOptions) => {
//     const values = selectedOptions
//       ? selectedOptions.map((option) => option.value)
//       : [];
//     setFilter(values.length > 0 ? values : undefined);
//   };

//   return (
//     <div style={{ alignItems: "center" }}>
//       <div>
//         <i
//           className="fa-solid fa-ellipsis-vertical"
//           onClick={handleIconClick}
//           style={{
//             cursor: "pointer",
//             fontSize: "20px",
//             color: "#AC0000",
//           }}
//         ></i>
//       </div>
//       <div>
//         {isActive &&
//           createPortal(
//             <div
//               style={{
//                 position: "fixed",
//                 zIndex: 9999,
//                 left: "50%",
//                 top: "50%",
//                 width: "30%",
//                 transform: "translate(-50%, -50%)",
//               }}
//             >
//               <div
//                 style={{
//                   position: "relative",
//                   background: "white",
//                   padding: "20px",
//                   borderRadius: "8px",
//                   boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
//                   backgroundColor: "#A70000",
//                 }}
//               >
//                 <Select
//                   options={options}
//                   isMulti
//                   value={options.filter((option) =>
//                     filterValue?.includes(option.value)
//                   )}
//                   onChange={handleMultiSelectChange}
//                   isClearable
//                   isSearchable
//                   placeholder={`Filter ${id}`}
//                   styles={{
//                     menu: (provided) => ({
//                       ...provided,
//                       zIndex: 9999,
//                     }),
//                   }}
//                 />
//                 <button
//                   style={{
//                     marginTop: "10px",
//                     marginLeft: "150px",
//                     padding: "5px 10px",
//                     background: "#007bff",
//                     color: "white",
//                     border: "none",
//                     borderRadius: "4px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => setIsActive(false)} // Close the popup
//                 >
//                   Close
//                 </button>
//               </div>
//             </div>,
//             document.body
//           )}
//       </div>
//     </div>
//   );
// };

// function IncomeList() {
//   const [responseData, setResponseData] = useState([]);

//   const data = useMemo(() => {
//     return responseData.map((item) => ({
//       _id: item._id,
//       branch_id: item.branch_id,
//       type: item.type?.type || "N/A", // Extract string value
//       typeid: item.type?._id,
//       paid_by: item.paid_by,
//       paid_to: item.paid_to,
//       paytype: item.paytype,
//       amount: item.amount,
//       date: item.date,
//       description: item.description,
//       person_name: item.person_name,
//       contact: item.contact,
//     }));
//   }, [responseData]);

//   const columns = useMemo(
//     () => [
//       {
//         Header: "S.No",
//         id: "serialNo",
//         Cell: ({ row }) => {
//           return row.index + 1;
//         },
//       },
//       {
//         Header: "Branch",
//         accessor: "branch_id",
//         Filter: DefaultColumnFilter,
//         filter: "multiSelectFilter",
//       },
//       // {
//       //   Header: "Type",
//       //   accessor: "type",
//       //   Filter: DefaultColumnFilter,
//       //   filter: "multiSelectFilter",
//       // },
//       {
//         Header: "Type",
//         accessor: (row) => row.type || "N/A", // Ensure valid type display
//         Filter: DefaultColumnFilter,
//         filter: "multiSelectFilter",
//       },
//       {
//         Header: "Date",
//         accessor: (row) => {
//           const date = new Date(row.date);
//           const day = String(date.getDate()).padStart(2, "0");
//           const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
//           const year = date.getFullYear();
//           return `${day}-${month}-${year}`;
//         },
//         Filter: DefaultColumnFilter,
//         filter: "multiSelectFilter",
//       },
//       {
//         Header: "Paid By",
//         accessor: "paid_by",
//         Filter: DefaultColumnFilter,
//         filter: "multiSelectFilter",
//       },
//       {
//         Header: "Paid To",
//         accessor: "paid_to",
//         Filter: DefaultColumnFilter,
//         filter: "multiSelectFilter",
//       },
//       {
//         Header: "Paid Type",
//         accessor: "paytype",
//         Filter: DefaultColumnFilter,
//         filter: "multiSelectFilter",
//       },
//       {
//         Header: "Amount",
//         accessor: "amount",
//         Filter: DefaultColumnFilter,
//         filter: "multiSelectFilter",
//       },
//     ],
//     []
//   );

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow,
//     state,
//     setGlobalFilter,
//     setPageSize,
//     page,
//     canPreviousPage,
//     canNextPage,
//     pageCount,
//     gotoPage,
//   } = useTable(
//     {
//       columns,
//       data,
//       initialState: { pageSize: 10 },
//       defaultColumn: { Filter: DefaultColumnFilter },
//       filterTypes: {
//         multiSelectFilter: (rows, columnIds, filterValue) => {
//           if (!filterValue || filterValue.length === 0) {
//             return rows;
//           }
//           return rows.filter((row) => {
//             const rowValue = row.values[columnIds[0]];
//             const rowValueStr = String(rowValue);
//             return filterValue.some((filterVal) =>
//               rowValueStr.includes(String(filterVal))
//             );
//           });
//         },
//       },
//     },
//     useFilters,
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );

//   const [responseDatas, setResponseDatas] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [isUpdateshowModal, setUpdateShowModal] = useState(false);
//   // const [selectedRole, setSelectedRole] = useState("");
//   const [student, setstudent] = useState([]);
//   const [paymenttype, setpaymenttype] = useState([]);
//   const [responseDatased, setResponseDatased] = useState([]);

//   const formatDate = (date) => {
//     if (!(date instanceof Date)) {
//       date = new Date(date); // Convert input to Date if it's not already
//     }
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${year}-${month}-${day}`;
//   };

 
//   const [addData, setAddData] = useState({
//     branch_id: "",
//     type: "",
//     date: formatDate(new Date()),
//     description: "",
//     paid_by: "",
//     paid_to: "",
//     paytype: "",
//     amount: "",
//     person_name: "",
//     contact: "",
//   });

//   const handleDateChange = (e) => {
//     setUpdateData((prevData) => ({
//       ...prevData,
//       date: e.target.value, // Input type=date handles the format
//     }));
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(ApiUrl + "/income_list");
//       console.log("Response from server:12", response.data);
//       setResponseData(response.data.reverse());
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const [updateData, setUpdateData] = useState({
//     branch_id: "",
//     type: "",
//     date: formatDate(new Date()),
//     description: "",
//     paid_by: "",
//     paid_to: "",
//     paytype: "",
//     amount: "",
//     person_name: "",
//     contact: "",
//   });

//   useEffect(() => {
//     fetchDatas();
//     fetchDatased();
//     studyingstudent();
//     paymenttyperoute();
//   }, []);

//   const fetchDatas = async () => {
//     try {
//       const response = await axios.get(ApiUrl + "/income_type");
//       console.log("Response from server type:", response.data);
//       // setResponseData(response.data)
//       if (Array.isArray(response.data)) {
//         setResponseDatas(response.data);
//       } else {
//         setResponseDatas([]);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//       setResponseDatas([]);
//     }
//   };

//   const fetchDatased = async () => {
//     try {
//       const response = await axios.get(ApiUrl + "/Branch");
//       console.log("Response from server brach :", response.data);
//       setResponseDatased(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const studyingstudent = async () => {
//     try {
//       const response = await axios.get(ApiUrl + "/studingstudent");
//       console.log("Response from server:", response.data);
//       setstudent(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const paymenttyperoute = async () => {
//     try {
//       const response = await axios.get(ApiUrl + "/paytype");
//       console.log("Response from server:", response.data);
//       setpaymenttype(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const handlesubmit = async (e) => {
//     e.preventDefault();

//     if (!validateFields()) {
//     }

//     try {
//       const formData = {
//         branch_id: selectedbranch.branch_name,
//         type: addData.type || "",
//         date: addData.date || formatDate(new Date()),
//         description: addData.description || "",
//         paid_by: addData.paid_by || "",
//         paid_to: addData.paid_to || "",
//         paytype: addData.paytype || "",
//         amount: addData.amount || "",
//         person_name: addData.person_name || "",
//         contact: addData.contact || " ",
//       };
//       console.log("the post Formdata:", formData);
//       const response = await axios.post(ApiUrl + "/income_list", formData);
//       console.log("the post income list:", response);
//       setShowModal(false);
//       fetchData();
//       toast.success("added successfully!");
//       setAddData({
//         branch_id: "",
//         type: "",
//         date: formatDate(new Date()),
//         description: "",
//         paid_by: "",
//         paid_to: "",
//         paytype: "",
//         amount: "",
//         person_name: "",
//         contact: "",
//       });
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const [updateid, setUpdateId] = useState("");

//   // const handleUpdate = (_id, data) => {
//   //   setUpdateId(_id);
//   //   console.log(" the data to updata", data);
//   //   setUpdateShowModal(true);
//   //   setUpdateData({
//   //     branch_id: data.branch_id,
//   //     branch_name: data.branch_id,
//   //     type: data.type,
//   //     typeid: data.typeid,
//   //     date: data.date,
//   //     description: data.description,
//   //     paid_by: data.paid_by,
//   //     paid_to: data.paid_to,
//   //     paytype: data.paytype,
//   //     amount: data.amount,
//   //     person_name: data.person_name,
//   //     contact: data.contact,
//   //   });
//   //   console.log(" the data to updata1212", updateData.type);
//   // };

//   const setCommonData = (data) => {
//     const commonData = {
//       branch_id: data.branch_id,
//       branch_name: data.branch_id,
//       type: data.type,

//       typeid: data.typeid,
//       date: data.date,
//       description: data.description,
//       paid_by: data.paid_by,
//       paid_to: data.paid_to,
//       paytype: data.paytype,
//       amount: data.amount,
//       person_name: data.person_name,
//       contact: data.contact,
//     };
//     console.log("Setting Data: ", commonData);
//     setUpdateData(commonData);
//   };

//   const handleUpdate = (_id, data) => {
//     setUpdateId(_id);
//     setUpdateShowModal(true);
//     setCommonData({
//       branch_id: data.branch_id,
//       branch_name: data.branch_id,
//       type: data.type._id,

//       typeid: data.typeid,
//       date: data.date,
//       description: data.description,
//       paid_by: data.paid_by,
//       paid_to: data.paid_to,
//       paytype: data.paytype,
//       amount: data.amount,
//       person_name: data.person_name,
//       contact: data.contact,
//     });
//   };

//   const handlePreview = (data) => {
//     setCommonData(data);
//     setShowPreview(true);
//   };

//   const handleupdatesubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const formData = {
//         branch_id: updateData.branch_id,
//         type: updateData.type,
//         date: updateData.date,
//         description: updateData.description,
//         paid_by: updateData.paid_by,
//         paid_to: updateData.paid_to,
//         paytype: updateData.paytype,
//         amount: updateData.amount,
//         person_name: updateData.person_name,
//         contact: updateData.contact,
//       };
//       console.log("the update submit data", formData);
//       const response = await axios.put(
//         ApiUrl + `/income_list/${updateid}`,
//         formData
//       );
//       console.log("response for update", response);
//       setUpdateShowModal(false);
//       toast.success("Update successfully!");
//       fetchData();
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       await axios.delete(ApiUrl + `/income_list/${getdeleteid}`);
//       toast.error("Delete successfully!");
//       setDeleteShowModals(false);
//       fetchData();
//     } catch (error) {
//       console.error("Error deleting role:", error.message);
//     }
//   };

//   // const handleRoleChange = (event) => {
//   //   setSelectedRole(event.target.value);
//   // };

//   const [deleteShowModals, setDeleteShowModals] = useState(false);
//   const [showPreview, setShowPreview] = useState(false);
//   const handleCloseModal = () => {
//     setDeleteShowModals(false);
//   };

//   const [getdeleteid, setdeleteid] = useState("");
//   const [getdeletename, setdeletename] = useState("");
//   const handleOpenModal = (_id, description) => {
//     console.log(" the id", _id);
//     console.log(" the name", description);
//     setdeleteid(_id);
//     setdeletename(description);
//     setDeleteShowModals(true);
//   };
//   const createPagination = () => {
//     let liTag = [];
//     let totalPages = pageCount; // Total pages
//     let currentPage = state.pageIndex;
//     let active;

//     // Previous Page Button
//     liTag.push(
//       <li
//         className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
//         key="prev"
//       >
//         <button
//           className="page-link"
//           onClick={() => gotoPage(currentPage - 1)}
//           disabled={!canPreviousPage}
//         >
//           <FaAngleLeft /> Prev
//         </button>
//       </li>
//     );

//     // Always show first page
//     if (totalPages > 1) {
//       liTag.push(
//         <li
//           className={`page-item ${currentPage === 0 ? "active" : ""}`}
//           key={0}
//         >
//           <button className="page-link" onClick={() => gotoPage(0)}>
//             1
//           </button>
//         </li>
//       );
//     }

//     // Show second page if total pages > 2
//     if (totalPages > 2) {
//       liTag.push(
//         <li
//           className={`page-item ${currentPage === 1 ? "active" : ""}`}
//           key={1}
//         >
//           <button className="page-link" onClick={() => gotoPage(1)}>
//             2
//           </button>
//         </li>
//       );
//     }

//     // Add "..." before current page group
//     if (currentPage > 3) {
//       liTag.push(
//         <li className="page-item disabled" key="dot-prev">
//           <span className="page-link">...</span>
//         </li>
//       );
//     }

//     // Show 3 pages around current page
//     for (
//       let i = Math.max(2, currentPage - 1);
//       i <= Math.min(totalPages - 3, currentPage + 1);
//       i++
//     ) {
//       active = currentPage === i ? "active" : "";
//       liTag.push(
//         <li className={`page-item ${active}`} key={i}>
//           <button className="page-link" onClick={() => gotoPage(i)}>
//             {i + 1}
//           </button>
//         </li>
//       );
//     }

//     // Add "..." after current page group
//     if (currentPage < totalPages - 4) {
//       liTag.push(
//         <li className="page-item disabled" key="dot-next">
//           <span className="page-link">...</span>
//         </li>
//       );
//     }

//     // Always show last two pages if totalPages > 2
//     if (totalPages > 2) {
//       liTag.push(
//         <li
//           className={`page-item ${
//             currentPage === totalPages - 2 ? "active" : ""
//           }`}
//           key={totalPages - 2}
//         >
//           <button
//             className="page-link"
//             onClick={() => gotoPage(totalPages - 2)}
//           >
//             {totalPages - 1}
//           </button>
//         </li>
//       );
//     }

//     if (totalPages > 1) {
//       liTag.push(
//         <li
//           className={`page-item ${
//             currentPage === totalPages - 1 ? "active" : ""
//           }`}
//           key={totalPages - 1}
//         >
//           <button
//             className="page-link"
//             onClick={() => gotoPage(totalPages - 1)}
//           >
//             {totalPages}
//           </button>
//         </li>
//       );
//     }

//     // Next Page Button
//     liTag.push(
//       <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
//         <button
//           className="page-link"
//           onClick={() => gotoPage(currentPage + 1)}
//           disabled={!canNextPage}
//         >
//           Next <FaAngleRight />
//         </button>
//       </li>
//     );

//     return liTag;
//   };

//   // the pagination  end ---------------------------------------------------------------------

//   const navigate = useNavigate();

//   const handleIncomeType = () => {
//     navigate("/incometype");
//   };

//   const handleBranchSelection = (e) => {
//     console.log(responseDatased, "responseDatased", e.target.value);

//     // Ensure the types of e.target.value and branch_id are the same
//     const selectedBranch = responseDatased.find(
//       (item) => item.branch_name === e.target.value.toString()
//     );

//     if (selectedBranch) {
//       console.log("The selected branch is:", selectedBranch);
//       setselectedbranch({
//         branch_id: selectedBranch.branch_id,
//         branch_name: selectedBranch.branch_name,
//       });
//     } else {
//       console.log("No matching branch found.");
//     }
//   };

//   const [selectedbranch, setselectedbranch] = useState({
//     branch_id: "",
//     branch_name: "",
//   });

//   const [errors, setErrors] = useState({});

//   const validateFields = () => {
//     let tempErrors = {};
//     let isValid = true;

//     if (!addData.branch_id) {
//       tempErrors.branch_id = "Branch Name is required.";
//       isValid = false;
//     }

//     if (!addData.type) {
//       tempErrors.type = "Type is required.";
//       isValid = false;
//     }

//     if (!addData.date) {
//       tempErrors.date = "Date is required.";
//       isValid = false;
//     }

//     if (!addData.description) {
//       tempErrors.description = "Description is required.";
//       isValid = false;
//     }

//     if (!addData.paid_by) {
//       tempErrors.paid_by = "Paid By is required.";
//       isValid = false;
//     }

//     if (!addData.paid_to) {
//       tempErrors.paid_to = "Paid To is required.";
//       isValid = false;
//     }

//     if (!addData.paytype) {
//       tempErrors.paytype = "Paytype is required.";
//       isValid = false;
//     }

//     if (!addData.amount) {
//       tempErrors.amount = "Amount is required.";
//       isValid = false;
//     }
//     setErrors(tempErrors);
//     return isValid;
//   };

//   return (
//     <>
//       <div className="" style={{ marginTop: "-80px" }}>
//         <div className="row mb-3">
//           <div className="col-lg-11">
//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Dashboard / </b>
//             </Link>
//             <Link to="" className="text-danger dashBoard_text">
//               <b>Income List</b>
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className="row">
//         <div className="col-lg-10">
//           <div className="d-flex">
//             <h6 className="mt-2 bold">Income List</h6>
//             <div className="">
//               {/* {previllages.add && ( */}
//               <button
//                 className="btn btn-dark btn-sm add mx-3"
//                 onClick={() => setShowModal(true)}
//               >
//                 Add
//               </button>
//               {/* )} */}
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-2">
//           <button className="btn btn-secondary" onClick={handleIncomeType}>
//             Income Type
//           </button>
//         </div>
//       </div>

//       <div className="mb-3 mt-3">
//         <div className="row">
//           <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//             <label className="form-label text-dark mt-2">Show : </label>
//             <div>
//               <select
//                 className="form-control w-100 mx-2"
//                 value={state.pageSize}
//                 onChange={(e) => setPageSize(Number(e.target.value))}
//               >
//                 {[10, 20, 50].map((pageSize) => (
//                   <option key={pageSize} value={pageSize}>
//                     {pageSize}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </div>
//           <div className="col-lg-7 col-md-4 col-sm-1"></div>
//           <div className="col-lg-3 col-md-4 col-sm-7">
//             <div className="w-full flex-1">
//               <form>
//                 <div className="relative">
//                   <input
//                     className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
//                     placeholder="Search ..."
//                     type="search"
//                     value={state.globalFilter || ""} // Use the globalFilter value
//                     onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
//                   />
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//       <TableContainer>
//         <Table {...getTableProps()}>
//           <thead>
//             {headerGroups.map((headerGroup) => (
//               <tr {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map((column) => (
//                   <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
//                     <div className="d-flex">
//                       <div className="mx-2">{column.render("Header")}</div>
//                       <div>
//                         {column.canFilter ? column.render("Filter") : null}
//                       </div>
//                       <div className="mx-1">
//                         {column.isSorted
//                           ? column.isSortedDesc
//                             ? " 🔽"
//                             : " 🔼"
//                           : ""}
//                       </div>
//                     </div>
//                   </Th>
//                 ))}
//                 <Th className="text-center">Action</Th>
//               </tr>
//             ))}
//           </thead>
//           <tbody {...getTableBodyProps()}>
//             {page.map((row) => {
//               prepareRow(row);
//               return (
//                 <tr {...row.getRowProps()}>
//                   {row.cells.map((cell) => (
//                     <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
//                   ))}
//                   <Td className="text-center">
//                     <button
//                       className="btn custom-btn-update btn-sm"
//                       onClick={() =>
//                         handleUpdate(row.original._id, row.original)
//                       }
//                     >
//                       <i className="fa-solid fa-pen-to-square"></i>
//                     </button>

//                     <button
//                       className="btn custom-btn-delete btn-sm mx-1"
//                       onClick={() =>
//                         handleOpenModal(
//                           row.original._id,
//                           row.original.description
//                         )
//                       }
//                     >
//                       <i className="fa-solid fa-trash-can"></i>
//                     </button>

//                     <button
//                       className="btn custom-btn-info btn-sm"
//                       onClick={() => handlePreview(row.original)}
//                     >
//                       <i class="fa-solid fa-eye"></i>
//                     </button>
                    
//                   </Td>
//                 </tr>
//               );
//             })}
//           </tbody>
//         </Table>
//       </TableContainer>

//       <Modal
//         show={showModal}
//         onHide={() => setShowModal(false)}
//         className="custom-modal"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Add Income List</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="row">
//             <div className="col-lg-6">
//               <div className="form-group">
//                 <div className="floating-label">
//                   <select
//                     className="form-select"
//                     value={selectedbranch.branch_name} // Controlled by state
//                     onChange={(e) => handleBranchSelection(e)} // Handles branch selection
//                     required
//                   >
//                     <option value="">Select Branch</option>
//                     {responseDatased.map((item, index) => (
//                       <option key={index} value={item.branch_name}>
//                         {`${item.branch_id}-${item.branch_name}`}
//                       </option>
//                     ))}
//                   </select>

//                   <label className={selectedbranch.branch_name ? "filled" : ""}>
//                     Select Branch
//                   </label>
//                 </div>

//                 <div className="floating-label">
//                   <label className="form-label">
//                     <b></b>
//                   </label>
//                   <select
//                     className="form-select"
//                     placeholder=""
//                     value={addData.type}
//                     onChange={(e) =>
//                       setAddData({ ...addData, type: e.target.value })
//                     }
//                   >
//                     <option value="">Select Type</option>
//                     {Array.isArray(responseDatas) &&
//                       responseDatas.map((item, index) => (
//                         <option key={index} value={item._id}>
//                           {item.type}
//                         </option>
//                       ))}
//                   </select>

//                   <label className={addData.type ? "filled" : ""}>
//                     Select type
//                   </label>
//                   {errors.type && (
//                     <div className="error-text">{errors.type}</div>
//                   )}
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="date"
//                     placeholder=""
//                     value={addData.date}
//                     onChange={handleDateChange}
//                     required
//                   />
//                   <label className={addData.date ? "filled" : ""}>Date</label>
//                   {errors.date && (
//                     <div className="error-text">{errors.date}</div>
//                   )}
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={addData.description}
//                     onChange={(e) =>
//                       setAddData({ ...addData, description: e.target.value })
//                     }
//                     required
//                   />
//                   <label className={addData.description ? "filled" : ""}>
//                     Notes
//                   </label>
//                   {errors.description && (
//                     <div className="error-text">{errors.description}</div>
//                   )}
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={addData.paid_to}
//                     onChange={(e) =>
//                       setAddData({ ...addData, paid_to: e.target.value })
//                     }
//                     required
//                   />
//                   <label className={addData.paid_to ? "filled" : ""}>
//                     Insititue / Client
//                   </label>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6">
//               <div className="form-group">
//                 <div className="floating-label">
//                   <label className="form-label">
//                     <b></b>
//                   </label>
//                   <select
//                     className="form-control"
//                     value={addData.paid_by}
//                     onChange={(e) =>
//                       setAddData({ ...addData, paid_by: e.target.value })
//                     }
//                   >
//                     <option value="">Select Paid By</option>
//                     <option value="Client">Client</option>
//                     <option value="College">College</option>
//                     <option value="School">School</option>
//                     <option value="Others">Others</option>
//                   </select>

//                   <label className={addData.paid_by ? "filled" : ""}>
//                     Select Paid By
//                   </label>
//                   {errors.paid_by && (
//                     <div className="error-text">{errors.paid_by}</div>
//                   )}
//                 </div>

//                 <div className="floating-label">
//                   <label className="form-label">
//                     <b></b>
//                   </label>
//                   <select
//                     className="form-select"
//                     value={addData.paytype}
//                     onChange={(e) =>
//                       setAddData({ ...addData, paytype: e.target.value })
//                     }
//                     required
//                   >
//                     <option value="">Select Paytype </option>
//                     {paymenttype.map((item, index) => (
//                       <option key={index} value={item.Payment_type}>
//                         {item.Payment_type}
//                       </option>
//                     ))}
//                   </select>
//                   <label className={addData.paytype ? "filled" : ""}></label>
//                   {errors.paytype && (
//                     <div className="error-text">{errors.paytype}</div>
//                   )}
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={addData.amount}
//                     onChange={(e) =>
//                       setAddData({ ...addData, amount: e.target.value })
//                     }
//                     required
//                   />
//                   <label className={addData.amount ? "filled" : ""}>
//                     Amount
//                   </label>
//                   {errors.amount && (
//                     <div className="error-text">{errors.amount}</div>
//                   )}
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={addData.person_name}
//                     onChange={(e) =>
//                       setAddData({ ...addData, person_name: e.target.value })
//                     }
//                     required
//                   />
//                   <label className={addData.person_name ? "filled" : ""}>
//                     Person Name
//                   </label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="tel"
//                     placeholder=""
//                     value={addData.contact}
//                     onChange={(e) =>
//                       setAddData({ ...addData, contact: e.target.value })
//                     }
//                     required
//                   />
//                   <label className={addData.contact ? "filled" : ""}>
//                     Contact
//                   </label>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button
//             className="cancelbut"
//             variant="secondary"
//             onClick={() => setShowModal(false)}
//           >
//             Cancel
//           </Button>
//           <Button
//             className="submitbut"
//             variant="primary"
//             onClick={handlesubmit}
//           >
//             {" "}
//             Submit
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal
//         show={isUpdateshowModal}
//         onHide={() => setUpdateShowModal(false)}
//         className="custom-modal"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Update Income List</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="row">
//             <div className="col-lg-6">
//               <div className="form-group">
//                 <div className="floating-label">
//                   <select
//                     className="form-select"
//                     value={updateData.branch_name}
//                     onChange={(e) =>
//                       setUpdateData({
//                         ...updateData,
//                         branch_id: e.target.value,
//                       })
//                     }
//                     required
//                   >
//                     <option value="">Select Branch</option>
//                     {responseDatased.map((item, index) => (
//                       <option key={index} value={item.branch_name}>
//                         {`${item.branch_id}-${item.branch_name}`}
//                       </option>
//                     ))}
//                   </select>

//                   <label
//                     className={updateData.branch_id ? "filled" : ""}
//                   ></label>
//                 </div>
//                 {/* <p>{updateData.type}</p> */}
//                 <div className="floating-label">
//                   <select
//                     className="form-select"
//                     value={updateData.typeid}
//                     placeholder=""
//                     onChange={(e) =>
//                       setUpdateData({ ...updateData, type: e.target.value })
//                     }
//                   >
//                     <option value="">Select Income Type</option>

//                     {responseDatas.map((item, index) => (
//                       <option key={index} value={item._id}>
//                         {item.type}
//                       </option>
//                     ))}
//                   </select>
//                   {/* <label className={updateData.type ? "filled" : ""}></label> */}
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="date"
//                     placeholder=""
//                     value={formatDate(updateData.date)}
//                     onChange={(e) =>
//                       setUpdateData({
//                         ...updateData,
//                         date: e.target.value,
//                       })
//                     }
//                     required
//                   />
//                   <label className={updateData.date ? "filled" : ""}>
//                     Date
//                   </label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={updateData.description}
//                     onChange={(e) =>
//                       setUpdateData({
//                         ...updateData,
//                         description: e.target.value,
//                       })
//                     }
//                     required
//                   />
//                   <label className={updateData.description ? "filled" : ""}>
//                     Notes
//                   </label>
//                 </div>

//                 {/* paid_till_date */}

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={updateData.paid_to}
//                     onChange={(e) =>
//                       setUpdateData({ ...updateData, paid_to: e.target.value })
//                     }
//                     required
//                   />
//                   <label className={updateData.paid_to ? "filled" : ""}>
//                     {" "}
//                     Insititue / Client
//                   </label>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6">
//               <div className="form-group">
//                 <div className="floating-label">
//                   <select
//                     className="form-control"
//                     value={updateData.paid_by}
//                     onChange={(e) =>
//                       setUpdateData({ ...updateData, paid_by: e.target.value })
//                     }
//                   >
//                     <option value="Client">Client</option>
//                     <option value="College">College</option>
//                     <option value="School">School</option>
//                     <option value="Others">Others</option>
//                   </select>
//                   <label className={updateData.paid_by ? "filled" : ""}>
//                     Paid By
//                   </label>
//                 </div>

//                 <div className="floating-label">
//                   <label className="form-label">
//                     <b></b>
//                   </label>
//                   <select
//                     className="form-select"
//                     value={updateData.paytype}
//                     onChange={(e) =>
//                       setUpdateData({ ...updateData, paytype: e.target.value })
//                     }
//                     required
//                   >
//                     <option value="">Select Paid To</option>
//                     {paymenttype.map((item, index) => (
//                       <option key={index} value={item.Payment_type}>
//                         {item.Payment_type}
//                       </option>
//                     ))}
//                   </select>
//                   <label className={updateData.paytype ? "filled" : ""}></label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={updateData.amount}
//                     onChange={(e) =>
//                       setUpdateData({ ...updateData, amount: e.target.value })
//                     }
//                     required
//                   />
//                   <label className={updateData.amount ? "filled" : ""}>
//                     Amount
//                   </label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={updateData.person_name}
//                     onChange={(e) =>
//                       setUpdateData({
//                         ...updateData,
//                         person_name: e.target.value,
//                       })
//                     }
//                     required
//                   />
//                   <label className={updateData.person_name ? "filled" : ""}>
//                     Person Name
//                   </label>
//                 </div>

//                 {/* contact */}

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={updateData.contact}
//                     onChange={(e) =>
//                       setUpdateData({ ...updateData, contact: e.target.value })
//                     }
//                     required
//                   />
//                   <label className={updateData.contact ? "filled" : ""}>
//                     Contact
//                   </label>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button
//             className="cancelbut"
//             variant="secondary"
//             onClick={() => setUpdateShowModal(false)}
//           >
//             Cancel
//           </Button>
//           <Button
//             className="submitbut"
//             variant="primary"
//             onClick={handleupdatesubmit}
//           >
//             Update
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {/* --------------------------- preview */}

//       <Modal
//         show={showPreview}
//         onHide={() => setShowPreview(false)}
//         className="custom-modal"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Preview Income List</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="row">
//             <div className="col-lg-6">
//               <div className="form-group">
//                 <div className="floating-label">
//                   <select
//                     className="form-select"
//                     value={updateData.branch_name}
//                     onChange={(e) =>
//                       setUpdateData({
//                         ...updateData,
//                         branch_id: e.target.value,
//                       })
//                     }
//                     disabled
//                   >
//                     <option value="">Select Branch</option>
//                     {responseDatased.map((item, index) => (
//                       <option key={index} value={item.branch_name}>
//                         {`${item.branch_id}-${item.branch_name}`}
//                       </option>
//                     ))}
//                   </select>

//                   <label
//                     className={updateData.branch_id ? "filled" : ""}
//                   ></label>
//                 </div>
//                 {/* <p>{updateData.type}</p> */}
//                 <div className="floating-label">
//                   <select
//                     className="form-select"
//                     value={updateData.type}
//                     placeholder=""
//                     onChange={(e) =>
//                       setUpdateData({ ...updateData, type: e.target.value })
//                     }
//                     disabled
//                   >
//                     {responseDatas.map((item, index) => (
//                       <option key={index} value={item._id}>
//                         {item.type}
//                       </option>
//                     ))}
//                   </select>
//                   <label className={updateData.type ? "filled" : ""}></label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="date"
//                     placeholder=""
//                     value={updateData.date}
//                     onChange={(e) =>
//                       setUpdateData({
//                         ...updateData,
//                         date: e.target.value,
//                       })
//                     }
//                     disabled
//                   />
//                   <label className={updateData.date ? "filled" : ""}>
//                     Date
//                   </label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={updateData.description}
//                     onChange={(e) =>
//                       setUpdateData({
//                         ...updateData,
//                         description: e.target.value,
//                       })
//                     }
//                     disabled
//                   />
//                   <label className={updateData.description ? "filled" : ""}>
//                     Notes
//                   </label>
//                 </div>

//                 {/* paid_till_date */}

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={updateData.paid_to}
//                     onChange={(e) =>
//                       setUpdateData({ ...updateData, paid_to: e.target.value })
//                     }
//                     disabled
//                   />
//                   <label className={updateData.paid_to ? "filled" : ""}>
//                     {" "}
//                     Insititue / Client
//                   </label>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6">
//               <div className="form-group">
//                 <div className="floating-label">
//                   <select
//                     className="form-control"
//                     value={updateData.paid_by}
//                     onChange={(e) =>
//                       setUpdateData({ ...updateData, paid_by: e.target.value })
//                     }
//                     disabled
//                   >
//                     <option value="Client">Client</option>
//                     <option value="College">College</option>
//                     <option value="School">School</option>
//                     <option value="Others">Others</option>
//                   </select>
//                   <label className={updateData.paid_by ? "filled" : ""}>
//                     Paid By
//                   </label>
//                 </div>

//                 <div className="floating-label">
//                   <label className="form-label">
//                     <b></b>
//                   </label>
//                   <select
//                     className="form-select"
//                     value={updateData.paytype}
//                     onChange={(e) =>
//                       setUpdateData({ ...updateData, paytype: e.target.value })
//                     }
//                     disabled
//                   >
//                     <option value="">Select Paid To</option>
//                     {paymenttype.map((item, index) => (
//                       <option key={index} value={item.Payment_type}>
//                         {item.Payment_type}
//                       </option>
//                     ))}
//                   </select>
//                   <label className={updateData.paytype ? "filled" : ""}></label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={updateData.amount}
//                     onChange={(e) =>
//                       setUpdateData({ ...updateData, amount: e.target.value })
//                     }
//                     disabled
//                   />
//                   <label className={updateData.amount ? "filled" : ""}>
//                     Amount
//                   </label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={updateData.person_name}
//                     onChange={(e) =>
//                       setUpdateData({
//                         ...updateData,
//                         person_name: e.target.value,
//                       })
//                     }
//                     disabled
//                   />
//                   <label className={updateData.person_name ? "filled" : ""}>
//                     Person Name
//                   </label>
//                 </div>

//                 {/* contact */}

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={updateData.contact}
//                     onChange={(e) =>
//                       setUpdateData({ ...updateData, contact: e.target.value })
//                     }
//                     disabled
//                   />
//                   <label className={updateData.contact ? "filled" : ""}>
//                     Contact
//                   </label>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button
//             className="cancelbut"
//             variant="secondary"
//             onClick={() => setShowPreview(false)}
//           >
//             Cancel
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {/* ----------------delete */}

//       <Modal
//         className="modal"
//         show={deleteShowModals}
//         onHide={handleCloseModal}
//       >
//         <Modal.Header>
//           <p>Are you sure you want to Delete?</p>
//         </Modal.Header>
//         <Modal.Body>
//           <label className="form-label d-flex">
//             <h5 className="mx-1">Description :</h5> <h5>{getdeletename} </h5>
//           </label>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             className="cancelbut"
//             variant="secondary"
//             onClick={handleCloseModal}
//           >
//             Close
//           </Button>
//           <Button
//             className="submitbut"
//             variant="primary"
//             onClick={handleDelete}
//           >
//             Delete
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {/* _------------------------------pagination button -------------------------------- */}

//       <div className="mt-3 pb-5">
//         <div className="row">
//           <div className="col-lg-7 col-sm-12 mt-1">
//             <span className="text-dark">
//               Page {state.pageIndex + 1} of {pageCount}
//             </span>
//           </div>
//           <div className="col-lg-5">
//             <div className="mt-3 pb-5">
//               <nav>
//                 <ul className="pagination justify-content-center">
//                   {createPagination()}
//                 </ul>
//               </nav>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* ---------------------------------------end ---------------------------------- */}
//     </>
//   );
// }

// export default IncomeList;




import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ApiUrl from "./../Environment/ApiUrl";
import "../../assets/css/Common.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function IncomeList() {
  const [responseData, setResponseData] = useState([]);

  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      branch_id: item.branch_id,
      type: item.type?.type || "N/A", // Extract string value
      typeid: item.type?._id,
      paid_by: item.paid_by,
      paid_to: item.paid_to,
      paytype: item.paytype,
      amount: item.amount,
      date: item.date,
      description: item.description,
      person_name: item.person_name,
      contact: item.contact,
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Branch",
        accessor: "branch_id",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      // {
      //   Header: "Type",
      //   accessor: "type",
      //   Filter: DefaultColumnFilter,
      //   filter: "multiSelectFilter",
      // },
      {
        Header: "Type",
        accessor: (row) => row.type || "N/A", // Ensure valid type display
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Date",
        accessor: (row) => {
          const date = new Date(row.date);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        },
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Paid By",
        accessor: "paid_by",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Paid To",
        accessor: "paid_to",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Paid Type",
        accessor: "paytype",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [responseDatas, setResponseDatas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [student, setstudent] = useState([]);
  const [paymenttype, setpaymenttype] = useState([]);
  const [responseDatased, setResponseDatased] = useState([]);

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date); // Convert input to Date if it's not already
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //  const formatDatefitching = (isoDate) => {
  //   console.log("Received isoDate:", isoDate); // Debugging log

  //   if (!isoDate || typeof isoDate !== "string") {
  //     console.error("Invalid input: isoDate is missing or not a string");
  //     return "Invalid Date";
  //   }

  //   const parsedDate = new Date(isoDate);

  //   if (isNaN(parsedDate.getTime())) {
  //     console.error("Invalid Date Format:", isoDate); // Log invalid formats
  //     return "Invalid Date";
  //   }

  //   const day = parsedDate.getDate().toString().padStart(2, "0");
  //   const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
  //   const year = parsedDate.getFullYear();

  //   return `${day}-${month}-${year}`;
  // };
  const [addData, setAddData] = useState({
    branch_id: "",
    type: "",
    date: formatDate(new Date()),
    description: "",
    paid_by: "",
    paid_to: "",
    paytype: "",
    amount: "",
    person_name: "",
    contact: "",
  });

  const handleDateChange = (e) => {
    setUpdateData((prevData) => ({
      ...prevData,
      date: e.target.value, // Input type=date handles the format
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/income_list");
      console.log("Response from server:12", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    type: "",
    date: formatDate(new Date()),
    description: "",
    paid_by: "",
    paid_to: "",
    paytype: "",
    amount: "",
    person_name: "",
    contact: "",
  });

  useEffect(() => {
    fetchDatas();
    fetchDatased();
    studyingstudent();
    paymenttyperoute();
  }, []);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(ApiUrl + "/income_type");
      console.log("Response from server type:", response.data);
      // setResponseData(response.data)
      if (Array.isArray(response.data)) {
        setResponseDatas(response.data);
      } else {
        setResponseDatas([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setResponseDatas([]);
    }
  };

  const fetchDatased = async () => {
    try {
      const response = await axios.get(ApiUrl + "/Branch");
      console.log("Response from server brach :", response.data);
      setResponseDatased(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const studyingstudent = async () => {
    try {
      const response = await axios.get(ApiUrl + "/studingstudent");
      console.log("Response from server:", response.data);
      setstudent(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const paymenttyperoute = async () => {
    try {
      const response = await axios.get(ApiUrl + "/paytype");
      console.log("Response from server:", response.data);
      setpaymenttype(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
    }

    try {
      const formData = {
        branch_id: selectedbranch.branch_name,
        type: addData.type || "",
        date: addData.date || formatDate(new Date()),
        description: addData.description || "",
        paid_by: addData.paid_by || "",
        paid_to: addData.paid_to || "",
        paytype: addData.paytype || "",
        amount: addData.amount || "",
        person_name: addData.person_name || "",
        contact: addData.contact || " ",
      };
      console.log("the post Formdata:", formData);
      const response = await axios.post(ApiUrl + "/income_list", formData);
      console.log("the post income list:", response);
      setShowModal(false);
      fetchData();
      toast.success("added successfully!");
      setAddData({
        branch_id: "",
        type: "",
        date: formatDate(new Date()),
        description: "",
        paid_by: "",
        paid_to: "",
        paytype: "",
        amount: "",
        person_name: "",
        contact: "",
      });
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  // const handleUpdate = (_id, data) => {
  //   setUpdateId(_id);
  //   console.log(" the data to updata", data);
  //   setUpdateShowModal(true);
  //   setUpdateData({
  //     branch_id: data.branch_id,
  //     branch_name: data.branch_id,
  //     type: data.type,
  //     typeid: data.typeid,
  //     date: data.date,
  //     description: data.description,
  //     paid_by: data.paid_by,
  //     paid_to: data.paid_to,
  //     paytype: data.paytype,
  //     amount: data.amount,
  //     person_name: data.person_name,
  //     contact: data.contact,
  //   });
  //   console.log(" the data to updata1212", updateData.type);
  // };

  const setCommonData = (data) => {
    const commonData = {
      branch_id: data.branch_id,
      branch_name: data.branch_id,
      type: data.type,
      
      typeid: data.typeid,
      date: data.date,
      description: data.description,
      paid_by: data.paid_by,
      paid_to: data.paid_to,
      paytype: data.paytype,
      amount: data.amount,
      person_name: data.person_name,
      contact: data.contact,
    };
    console.log("Setting Data: ", commonData);
    setUpdateData(commonData);
  };

  const handleUpdate = (_id, data) => {
    setUpdateId(_id);
    setUpdateShowModal(true);
    setCommonData(
      {
        branch_id: data.branch_id,
        branch_name: data.branch_id,
        type: data.type._id,
        
        typeid: data.typeid,
        date: data.date,
        description: data.description,
        paid_by: data.paid_by,
        paid_to: data.paid_to,
        paytype: data.paytype,
        amount: data.amount,
        person_name: data.person_name,
        contact: data.contact,
      }
    );


    
  };

  const handlePreview = (data) => {
    setCommonData(data);
    setShowPreview(true)
  }

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        branch_id: updateData.branch_id,
        type: updateData.typeid,
        date: updateData.date,
        description: updateData.description,
        paid_by: updateData.paid_by,
        paid_to: updateData.paid_to,
        paytype: updateData.paytype,
        amount: updateData.amount,
        person_name: updateData.person_name,
        contact: updateData.contact,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        ApiUrl + `/income_list/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(ApiUrl + `/income_list/${getdeleteid}`);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting role:", error.message);
    }
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, description) => {
    console.log(" the id", _id);
    console.log(" the name", description);
    setdeleteid(_id);
    setdeletename(description);
    setDeleteShowModals(true);
  };
  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  // the pagination  end ---------------------------------------------------------------------

  const navigate = useNavigate();

  const handleIncomeType = () => {
    navigate("/incometype");
  };

  const handleBranchSelection = (e) => {
    console.log(responseDatased, "responseDatased", e.target.value);

    // Ensure the types of e.target.value and branch_id are the same
    const selectedBranch = responseDatased.find(
      (item) => item.branch_name === e.target.value.toString()
    );

    if (selectedBranch) {
      console.log("The selected branch is:", selectedBranch);
      setselectedbranch({
        branch_id: selectedBranch.branch_id,
        branch_name: selectedBranch.branch_name,
      });
    } else {
      console.log("No matching branch found.");
    }
  };

  const [selectedbranch, setselectedbranch] = useState({
    branch_id: "",
    branch_name: "",
  });

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    let isValid = true;

    if (!addData.branch_id) {
      tempErrors.branch_id = "Branch Name is required.";
      isValid = false;
    }

    if (!addData.type) {
      tempErrors.type = "Type is required.";
      isValid = false;
    }

    if (!addData.date) {
      tempErrors.date = "Date is required.";
      isValid = false;
    }

    if (!addData.description) {
      tempErrors.description = "Description is required.";
      isValid = false;
    }

    if (!addData.paid_by) {
      tempErrors.paid_by = "Paid By is required.";
      isValid = false;
    }

    if (!addData.paid_to) {
      tempErrors.paid_to = "Paid To is required.";
      isValid = false;
    }

    if (!addData.paytype) {
      tempErrors.paytype = "Paytype is required.";
      isValid = false;
    }

    if (!addData.amount) {
      tempErrors.amount = "Amount is required.";
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };

  const formatDatefitching = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);

    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
    const year = date.getFullYear();

    return `${day}-${month}-${year}`; // Format as "day-month-year"
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Income List</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10">
          <div className="d-flex">
            <h6 className="mt-2 bold">Income List</h6>
            <div className="">
              {/* {previllages.add && ( */}
              <button
                className="btn btn-dark btn-sm add mx-3"
                onClick={() => setShowModal(true)}
              >
                Add
              </button>
              {/* )} */}
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <button className="btn btn-secondary" onClick={handleIncomeType}>
            Income Type
          </button>
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      {/* <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Branch</th>
                <th>Type</th>
                <th>Date</th>
                <th>Paid By</th>
                <th>Paid To</th>
                <th>Pay Type</th>
                <th>Amount</th>

                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.branch_id}</td>
                  <td>{item.type}</td>
                  <td>{formatDatefitching(item.date)}</td>
                  <td>{item.paid_by}</td>
                  <td>{item.paid_to}</td>
                  <td>{item.paytype}</td>
                  <td>{item.amount}</td>

                  <td className="text-center">
                    <button
                      className="btn updelete mx-2"
                      onClick={() => handleUpdate(item._id, item)}
                    >
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button
                      className="btn delete"
                      onClick={() =>
                        handleOpenModal(item._id, item.description)
                      }
                    >
                      <i class="fa-solid fa-trash-can"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    <button
                      className="btn custom-btn-update btn-sm"
                      onClick={() =>
                        handleUpdate(row.original._id, row.original)
                      }
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button
                      className="btn custom-btn-delete btn-sm mx-1"
                      onClick={() =>
                        handleOpenModal(
                          row.original._id,
                          row.original.description
                        )
                      }
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>

                    {/* <button className="btn btn-sm btn-info mx-2" onClick={() => setShowPreview(true)}>
                    <i class="fa-solid fa-eye"></i>
                    </button> */}
                    <button
                      className="btn custom-btn-info btn-sm"
                      onClick={() => handlePreview(row.original)}
                    >
                      <i class="fa-solid fa-eye"></i>
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Income List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={selectedbranch.branch_name} // Controlled by state
                    onChange={(e) => handleBranchSelection(e)} // Handles branch selection
                    required
                  >
                    <option value="">Select Branch</option>
                    {responseDatased.map((item, index) => (
                      <option key={index} value={item.branch_name}>
                        {`${item.branch_id}-${item.branch_name}`}
                      </option>
                    ))}
                  </select>

                  <label className={selectedbranch.branch_name ? "filled" : ""}>
                    Select Branch
                  </label>
                </div>

                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-select"
                    placeholder=""
                    value={addData.type}
                    onChange={(e) =>
                      setAddData({ ...addData, type: e.target.value })
                    }
                  >
                    <option value="">Select Type</option>
                    {Array.isArray(responseDatas) &&
                      responseDatas.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.type}
                        </option>
                      ))}
                  </select>

                  <label className={addData.type ? "filled" : ""}>
                    Select type
                  </label>
                  {errors.type && (
                    <div className="error-text">{errors.type}</div>
                  )}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={addData.date}
                    onChange={handleDateChange}
                    required
                  />
                  <label className={addData.date ? "filled" : ""}>Date</label>
                  {errors.date && (
                    <div className="error-text">{errors.date}</div>
                  )}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.description}
                    onChange={(e) =>
                      setAddData({ ...addData, description: e.target.value })
                    }
                    required
                  />
                  <label className={addData.description ? "filled" : ""}>
                    Notes
                  </label>
                  {errors.description && (
                    <div className="error-text">{errors.description}</div>
                  )}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.paid_to}
                    onChange={(e) =>
                      setAddData({ ...addData, paid_to: e.target.value })
                    }
                    required
                  />
                  <label className={addData.paid_to ? "filled" : ""}>
                    Insititue / Client
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-control"
                    value={addData.paid_by}
                    onChange={(e) =>
                      setAddData({ ...addData, paid_by: e.target.value })
                    }
                  >
                    <option value="">Select Paid By</option>
                    <option value="Client">Client</option>
                    <option value="College">College</option>
                    <option value="School">School</option>
                    <option value="Others">Others</option>
                  </select>

                  <label className={addData.paid_by ? "filled" : ""}>
                    Select Paid By
                  </label>
                  {errors.paid_by && (
                    <div className="error-text">{errors.paid_by}</div>
                  )}
                </div>

                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-select"
                    value={addData.paytype}
                    onChange={(e) =>
                      setAddData({ ...addData, paytype: e.target.value })
                    }
                    required
                  >
                    <option value="">Select Paytype </option>
                    {paymenttype.map((item, index) => (
                      <option key={index} value={item.Payment_type}>
                        {item.Payment_type}
                      </option>
                    ))}
                  </select>
                  <label className={addData.paytype ? "filled" : ""}></label>
                  {errors.paytype && (
                    <div className="error-text">{errors.paytype}</div>
                  )}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.amount}
                    onChange={(e) =>
                      setAddData({ ...addData, amount: e.target.value })
                    }
                    required
                  />
                  <label className={addData.amount ? "filled" : ""}>
                    Amount
                  </label>
                  {errors.amount && (
                    <div className="error-text">{errors.amount}</div>
                  )}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.person_name}
                    onChange={(e) =>
                      setAddData({ ...addData, person_name: e.target.value })
                    }
                    required
                  />
                  <label className={addData.person_name ? "filled" : ""}>
                    Person Name
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={addData.contact}
                    onChange={(e) =>
                      setAddData({ ...addData, contact: e.target.value })
                    }
                    required
                  />
                  <label className={addData.contact ? "filled" : ""}>
                    Contact
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handlesubmit}
          >
            {" "}
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isUpdateshowModal}
        onHide={() => setUpdateShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Income List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.branch_name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        branch_id: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Branch</option>
                    {responseDatased.map((item, index) => (
                      <option key={index} value={item.branch_name}>
                        {`${item.branch_id}-${item.branch_name}`}
                      </option>
                    ))}
                  </select>

                  <label
                    className={updateData.branch_id ? "filled" : ""}
                  ></label>
                </div>
                {/* <p>{updateData.type}</p> */}
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.typeid}
                    placeholder=""
                    onChange={(e) =>
                      setUpdateData({ ...updateData, typeid: e.target.value })
                    }
                  >
                    {responseDatas.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.type}
                      </option>
                    ))}
                  </select>
                  <label className={updateData.type ? "filled" : ""}></label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={formatDate(updateData.date)}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        date: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.date ? "filled" : ""}>
                    Date
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.description}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        description: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.description ? "filled" : ""}>
                    Notes
                  </label>
                </div>

                {/* paid_till_date */}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.paid_to}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paid_to: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.paid_to ? "filled" : ""}>
                    {" "}
                    Insititue / Client
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <select
                    className="form-control"
                    value={updateData.paid_by}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paid_by: e.target.value })
                    }
                  >
                    <option value="Client">Client</option>
                    <option value="College">College</option>
                    <option value="School">School</option>
                    <option value="Others">Others</option>
                  </select>
                  <label className={updateData.paid_by ? "filled" : ""}>
                    Paid By
                  </label>
                </div>

                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-select"
                    value={updateData.paytype}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paytype: e.target.value })
                    }
                    required
                  >
                    <option value="">Select Paid To</option>
                    {paymenttype.map((item, index) => (
                      <option key={index} value={item.Payment_type}>
                        {item.Payment_type}
                      </option>
                    ))}
                  </select>
                  <label className={updateData.paytype ? "filled" : ""}></label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, amount: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.person_name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        person_name: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.person_name ? "filled" : ""}>
                    Person Name
                  </label>
                </div>

                {/* contact */}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.contact}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, contact: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.contact ? "filled" : ""}>
                    Contact
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* --------------------------- preview */}

      <Modal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview Income List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.branch_name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        branch_id: e.target.value,
                      })
                    }
                    disabled
                  >
                    <option value="">Select Branch</option>
                    {responseDatased.map((item, index) => (
                      <option key={index} value={item.branch_name}>
                        {`${item.branch_id}-${item.branch_name}`}
                      </option>
                    ))}
                  </select>

                  <label
                    className={updateData.branch_id ? "filled" : ""}
                  ></label>
                </div>
                {/* <p>{updateData.type}</p> */}
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.type}
                    placeholder=""
                    onChange={(e) =>
                      setUpdateData({ ...updateData, type: e.target.value })
                    }
                    disabled
                  >
                    {responseDatas.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.type}
                      </option>
                    ))}
                  </select>
                  <label className={updateData.type ? "filled" : ""}></label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={formatDate(updateData.date)}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        date: e.target.value,
                      })
                    }
                    required
                    disabled
                  />
                  <label className={updateData.date ? "filled" : ""}>
                    Date
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.description}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        description: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label className={updateData.description ? "filled" : ""}>
                    Notes
                  </label>
                </div>

                {/* paid_till_date */}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.paid_to}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paid_to: e.target.value })
                    }
                    disabled
                  />
                  <label className={updateData.paid_to ? "filled" : ""}>
                    {" "}
                    Insititue / Client
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <select
                    className="form-control"
                    value={updateData.paid_by}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paid_by: e.target.value })
                    }
                    disabled
                  >
                    <option value="Client">Client</option>
                    <option value="College">College</option>
                    <option value="School">School</option>
                    <option value="Others">Others</option>
                  </select>
                  <label className={updateData.paid_by ? "filled" : ""}>
                    Paid By
                  </label>
                </div>

                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-select"
                    value={updateData.paytype}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paytype: e.target.value })
                    }
                    disabled
                  >
                    <option value="">Select Paid To</option>
                    {paymenttype.map((item, index) => (
                      <option key={index} value={item.Payment_type}>
                        {item.Payment_type}
                      </option>
                    ))}
                  </select>
                  <label className={updateData.paytype ? "filled" : ""}></label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, amount: e.target.value })
                    }
                    disabled
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.person_name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        person_name: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label className={updateData.person_name ? "filled" : ""}>
                    Person Name
                  </label>
                </div>

                {/* contact */}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.contact}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, contact: e.target.value })
                    }
                    disabled
                  />
                  <label className={updateData.contact ? "filled" : ""}>
                    Contact
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowPreview(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Description :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* _------------------------------pagination button -------------------------------- */}
      {/* <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>
        </div>
      </div> */}

      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------end ---------------------------------- */}
    </>
  );
}

export default IncomeList;
