import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ApiUrl from "./../Environment/ApiUrl";
import "../../assets/css/Common.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};
// import ApiUrl from "../Environment/ApiUrl";

function Upcoming() {
  const [showModal, setShowModal] = useState(false);
  const [responseData, setResponseData] = useState([]); // Store API data

  // const data = useMemo(() => {
  //   return responseData.map((item) => {
  //     return {
  //       _id: item._id,
  //       branch_id: item.branch_id,
  //       purpose_enquiry: item.purpose_enquiry,
  //       name: item.name,
  //       company_name: item.company_name,
  //       designation: item.designation,
  //       official_email: item.official_email,
  //       alternate_email: item.alternate_email,
  //       official_mobile: item.official_mobile,
  //       alternate_mobile: item.alternate_mobile,
  //       location: item.location,
  //       notes: item.notes,
  //       source_type: item.source_type,
  //       enquiry_date: item.enquiry_date,
  //       followups_status: item.followups_status,
  //       assign_staff: item.assign_staff,
  //       contact_type: item.contact_type,
  //       end_time: item.end_time,
  //       followups_time: item.followups_time,
  //       start_time: item.start_time,
  //       status_date: item.status_date,
  //       start_date: item.start_date,
  //       end_date: item.end_date,
  //       quotation_date: item.customer_orders?.customer_orders,
  //       quotation_status: item.quotation_status,
  //       expected_status: item.expected_status,
  //       purchaseDate: item.purchaseDate,
  //       purchaseEmail: item.purchaseEmail,
  //       netValue: item.netValue,
  //       purchaseTax: item.purchaseTax,
  //       purchaseProjectCode: item.purchaseProjectCode,
  //       rejectedDate: item.rejectedDate,
  //       rejectionReason: item.rejectionReason,
  //       customer_orders: item.customer_orders || [
  //         valid_tilldate: item.valid_tilldate,
  //       ],
  //     };
  //   });
  // }, [responseData]);

  const data = useMemo(() => {
    return responseData
      .filter((item) => item.quotation_status === "Pending")
      .map((item) => {
        return {
          _id: item._id,
          branch_id: item.branch_id,
          purpose_enquiry: item.purpose_enquiry,
          name: item.name,
          company_name: item.company_name,
          designation: item.designation,
          official_email: item.official_email,
          alternate_email: item.alternate_email,
          official_mobile: item.official_mobile,
          alternate_mobile: item.alternate_mobile,
          // location: item.location,
          door_no: item.door_no,
          street_name: item.street_name,
          area: item.area,
          district: item.district,
          pin_code: item.pin_code,
          notes: item.notes,
          source_type: item.source_type,
          enquiry_date: item.enquiry_date,
          followups_status: item.followups_status,
          assign_staff: item.assign_staff,
          contact_type: item.contact_type,
          end_time: item.end_time,
          followups_time: item.followups_time,
          start_time: item.start_time,
          status_date: item.status_date,
          start_date: item.start_date,
          end_date: item.end_date,
          quotation_date: item.customer_orders?.[0]?.date || null,
          quotation_status: item.quotation_status,
          expected_status: item.expected_status,
          purchaseDate: item.purchaseDate,
          purchaseEmail: item.purchaseEmail,
          netValue: item.netValue,
          purchaseTax: item.purchaseTax,
          purchaseProjectCode: item.purchaseProjectCode,
          rejectedDate: item.rejectedDate,
          rejectionReason: item.rejectionReason,
          customer_orders: item.customer_orders?.length
            ? item.customer_orders
            : [{ valid_tilldate: item.valid_tilldate }],
        };
      });
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Enquiry Date",
        accessor: "enquiry_date",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Quotation Status",
        accessor: "quotation_status",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        width: 120,
        height: 10,
        Cell: ({ value }) => {
          const statusClass = getStatusLabelClass(value);
          return (
            <span
              className={`${statusClass}`}
              style={{
                display: "inline-block",
                width: "120px",
                height: "20px",
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Quotation Last Sent",
        accessor: "quotation_date",
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          return `${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()}`;
        },
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    fetchDatased();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/expected");
      setResponseData(response.data.reverse());
      console.log(
        "Response from server student data:",
        response.data.reverse()
      );
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const getStatusLabelClass = (quotation_status) => {
    switch (quotation_status) {
      case "Converted":
        return "bg-success1 text-white1";
      case "Rejected":
        return "bg-danger1 text-white1";
      case "Pending":
        return "bg-warning1 text-white1";
      default:
        return "";
    }
  };

  const [updataData, setUpdataData] = useState({
    _id: "",
    branch_id: "",
    purpose_enquiry: "",
    name: "",
    company_name: "",
    designation: "",
    official_email: "",
    alternate_email: "",
    official_mobile: "",
    alternate_mobile: "",
    // location: "",
    door_no: "",
    street_name: "",
    area: "",
    district: "",
    pin_code: "",
    notes: "",
    source_type: "",
    enquiry_date: "",
    start_date: "",
    end_date: "",
    quotation_date: "",
    followups_time: "",
    quotation_status: "",
    send_quotation: "",
    expected_status: "",
    rejectedDate: "",
    rejectionReason: "",
    purchaseDate: "",
    purchaseNo: "",
    purchaseEmail: "",
    netValue: "",
    purchaseTax: "",
    purchaseProjectCode: "",
  });
  const handleUpdateButton = (_id, data) => {
    console.log("jkewhrjkhwr", data);
    setShowModal(true);
    setUpdataData(data);
    console.log("jkewhrjkhwr", data);
  };

  // const handleupdatesubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const formData = {
  //       source_type: updataData.source_type,
  //       branch_id: updataData.branch_id,
  //       purpose_enquiry: updataData.purpose_enquiry,
  //       name: updataData.name,
  //       company_name: updataData.company_name,
  //       designation: updataData.designation,
  //       official_email: updataData.official_email,
  //       alternate_email: updataData.alternate_email,
  //       official_mobile: updataData.official_mobile,
  //       alternate_mobile: updataData.alternate_mobile,
  //       location: updataData.location,
  //       notes: updataData.notes,
  //       enquiry_date: updataData.enquiry_date,
  //       start_date: updataData.start_date,
  //       end_date: updataData.end_date,
  //       quotation_date: updataData.quotation_date,
  //       followups_time: updataData.followups_time,
  //       quotation_status: updataData.quotation_status,
  //       expected_status: updataData.expected_status,
  //       send_quotation: updataData.send_quotation,
  //       rejectedDate: updataData.rejectedDate,
  //       rejectionReason: updataData.rejectionReason,
  //       purchaseDate: updataData.purchaseDate,
  //       purchaseNo: updataData.purchaseNo,
  //       purchaseEmail: updataData.purchaseEmail,
  //       netValue: updataData.netValuec,
  //       purchaseTax: updataData.purchaseTax,
  //       purchaseProjectCode: updataData.purchaseProjectCode,
  //     };

  //     console.log("Response from PUT:xvzx", formData);

  //     const response = await axios.put(
  //       ApiUrl + `/expectedS/${updataData._id}`,
  //       formData
  //     );
  //     console.log("Response from PUT:", response);
  //     console.log("Response from PUT:", formData);
  //     setShowModal(false);
  //     toast.success("Updated successfully!");
  //     fetchData();
  //   } catch (error) {
  //     console.error("Error updating data:", error.message);
  //   }
  // };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();

    try {
      // Ensure quotation_status is "Converted" before proceeding
      if (updataData.quotation_status === "Converted") {
        const formData = {
          source_type: updataData.source_type,
          branch_id: updataData.branch_id,
          purpose_enquiry: updataData.purpose_enquiry,
          name: updataData.name,
          company_name: updataData.company_name,
          designation: updataData.designation,
          official_email: updataData.official_email,
          alternate_email: updataData.alternate_email,
          official_mobile: updataData.official_mobile,
          alternate_mobile: updataData.alternate_mobile,
          // location: updataData.location,
          door_no: updataData.door_no,
          street_name: updataData.street_name,
          area: updataData.area,
          district: updataData.district,
          pin_code: updataData.pin_code,
          notes: updataData.notes,
          enquiry_date: updataData.enquiry_date,
          start_date: updataData.start_date,
          end_date: updataData.end_date,
          quotation_date: updataData.quotation_date,
          followups_time: updataData.followups_time,
          quotation_status: updataData.quotation_status,
          expected_status: updataData.expected_status,
          send_quotation: updataData.send_quotation,
          rejectedDate: updataData.rejectedDate,
          rejectionReason: updataData.rejectionReason,
          purchaseDate: updataData.purchaseDate,
          purchaseNo: updataData.purchaseNo,
          purchaseEmail: updataData.purchaseEmail,
          netValue: updataData.netValue,
          purchaseTax: updataData.purchaseTax,
          purchaseProjectCode: updataData.purchaseProjectCode,
        };

        console.log("Sending Data for Update:", formData);

        // const response = await axios.put(
        //   `${ApiUrl}/expected`,
        //   formData
        // );

        const response = await axios.put(
          ApiUrl + `/expected/${updataData._id}`,
          formData
        );

        console.log("Update Response:", response.data);
        toast.success("Updated successfully!");
        setShowModal(false);
        fetchData();

        // navigate("/ongoing");
      } else {
        console.log(
          "Quotation status is not 'Converted', no update performed."
        );
      }
    } catch (error) {
      console.error("Error updating data:", error.message);
      toast.error("Update failed. Please try again.");
    }
  };

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");

  const handleOpenModal = (_id, name) => {
    console.log(" the id", _id);
    setdeleteid(_id);
    setdeletename(name);
    setDeleteShowModals(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(ApiUrl + `/expected/${getdeleteid}`);
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleClickQuotation = (id) => {
    navigate(`/Quotation/${id}`);
  };

  const handleClickAnnexure = (id) => {
    navigate(`/Annexure/${id}`);
  };

  const [source, setsource] = useState([]);
  const [branch, setBranch] = useState([]);
  const [purpose, setpurpose] = useState([]);
  const fetchDatased = async () => {
    try {
      const responsesourses = await axios.get(ApiUrl + "/source_type");
      setsource(responsesourses.data);
      const response = await axios.get(ApiUrl + "/branch");
      setBranch(response.data);
      const responsepurposes = await axios.get(ApiUrl + "/enquiry_purpose");
      setpurpose(responsepurposes.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  const handleNavigateQH = (id) => {
    navigate(`/expected_histroy/${id}`);
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Projects / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Expected</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    <button
                      className="btn custom-btn-update btn-sm mx-1"
                      // onClick={() => setShowModal(true)}
                      onClick={() =>
                        handleUpdateButton(row.original._id, row.original)
                      }
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button
                      className="btn custom-btn-delete btn-sm mx-1"
                      onClick={() =>
                        handleOpenModal(row.original._id, row.original.name)
                      }
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>

                    <button
                      className="btn custom-btn-info btn-sm mx-1"
                      onClick={() => handleNavigateQH(row.original._id)}
                    >
                      <i className="fa-solid fa-circle-info"></i>
                    </button>

                    {/* <button
                      className="btn btn-info btn-sm mx-1"
                      onClick={() => handleClickQuotation(row.original._id)}
                    >
                      <i className="fa-solid fa-circle-plus"></i>
                    </button> */}

                    <button
                      className="btn custom-btn-plus btn-sm mx-1"
                      onClick={() => handleClickAnnexure(row.original._id)}
                    >
                      <i className="fa-solid fa-circle-plus"></i>
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

      <div className="insermodal">
        <Modal
          className="addmodals"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Expected Project</Modal.Title>
          </Modal.Header>

          <form>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-6">
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={updataData.branch_id}
                      // onChange={(e) =>
                      //   setUpdataData({
                      //     ...updataData,
                      //     branch_id: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          name_id: {
                            ...updataData.name_id, // Preserve other properties inside name_id
                            branch_id: e.target.value, // Update the name property of name_id
                          },
                        })
                      }
                      required
                    >
                      <option value="">Select Branch</option>
                      {branch.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.branch_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={updataData.purpose_enquiry}
                      // onChange={(e) =>
                      //   setUpdataData({
                      //     ...updataData,
                      //     purpose_enquiry: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          name_id: {
                            ...updataData.name_id, // Preserve other properties inside name_id
                            purpose_enquiry: e.target.value, // Update the name property of name_id
                          },
                        })
                      }
                      required
                    >
                      <option value="">Select Purpose</option>
                      {purpose.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.enquiry_purpose}{" "}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={updataData.name || ""} // Use a fallback to prevent undefined value
                      required
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          name: e.target.value,
                        })
                      }
                    />
                    <label>Name</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={updataData.company_name}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          company_name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Company Name</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={updataData.designation}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          designation: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Designation</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={updataData.official_email}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          official_email: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Official Email</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={updataData.alternate_email}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          alternate_email: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Alternate Email</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={updataData.official_mobile}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          official_mobile: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Official Mobile</label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={updataData.alternate_mobile}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          alternate_mobile: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Alternate Mobile</label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updataData.door_no}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          door_no: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Door No</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updataData.street_name}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          street_name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Street Name</label>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  {/* <div className="floating-label">
                    <textarea
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={updataData.location}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          location: e.target.value,
                        })
                      }
                      required
                    />
                  </div> */}

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updataData.area}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          area: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Area</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updataData.district}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          district: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">District</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updataData.pin_code}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          pin_code: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Pin Code</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      value={updataData.notes}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          notes: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Notes</label>
                  </div>

                  <div className="floating-label">
                    <label className="form-label">
                      <b></b>
                    </label>
                    <select
                      className="form-select"
                      placeholder=""
                      value={updataData.source_type}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          source_type: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Source Type</option>
                      {source.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.source_type}
                        </option>
                      ))}
                    </select>
                    <label
                      className={updataData.source_type ? "filled" : ""}
                    ></label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      placeholder=""
                      value={updataData.enquiry_date}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          enquiry_date: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Enquiry Date</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      placeholder=""
                      value={updataData.start_date}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          start_date: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Start Date</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      placeholder=""
                      value={updataData.end_date}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          end_date: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">End Date</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      placeholder=""
                      value={updataData.send_quotation}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          send_quotation: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Send Quotation</label>
                  </div>

                  <div className="floating-label">
                    <select
                      className="form-control"
                      name="status"
                      value={updataData.quotation_status}
                      onChange={(e) =>
                        setUpdataData({
                          ...updataData,
                          quotation_status: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Status</option>
                      <option value="Converted">Converted</option>
                      <option value="Rejected">Rejected</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr></hr>
              {updataData.quotation_status === "Converted" && (
                <div className="converted-fields">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="date"
                          placeholder=""
                          value={updataData.purchaseDate}
                          onChange={(e) =>
                            setUpdataData({
                              ...updataData,
                              purchaseDate: e.target.value,
                            })
                          }
                          required
                        />
                        <label className="">Purchase Date</label>
                      </div>

                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={updataData.purchaseNo}
                          onChange={(e) =>
                            setUpdataData({
                              ...updataData,
                              purchaseNo: e.target.value,
                            })
                          }
                          required
                        />
                        <label className="">Purchase No</label>
                      </div>

                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={updataData.netValue}
                          onChange={(e) =>
                            setUpdataData({
                              ...updataData,
                              netValue: e.target.value,
                            })
                          }
                          required
                        />
                        <label className="">Net Value</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={updataData.purchaseTax}
                          onChange={(e) =>
                            setUpdataData({
                              ...updataData,
                              purchaseTax: e.target.value,
                            })
                          }
                          required
                        />
                        <label className="">Purchase Tax</label>
                      </div>

                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={updataData.purchaseProjectCode}
                          onChange={(e) =>
                            setUpdataData({
                              ...updataData,
                              purchaseProjectCode: e.target.value,
                            })
                          }
                          required
                        />
                        <label className="">Project Code</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="cancelbut"
                variant="secondary"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                className="submitbut"
                type="submit"
                variant="primary"
                onClick={handleupdatesubmit}
              >
                Update
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>

      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Expected : </h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Upcoming;
