// // import React, { useState, useEffect } from "react";
// // import { FaTh, FaBars } from "react-icons/fa";
// // import { NavLink, useLocation, useNavigate } from "react-router-dom";
// // import { Button, Modal } from "react-bootstrap";
// // import Navbar from "../Navbar";
// // import "../../assets/css/Sidebar.css";
// // import ApiUrl from './../Environment/ApiUrl';
// // import axios from "axios";

// // const Sidebar = ({ children }) => {
// //   const [isOpen, setIsOpen] = useState(false);
// //   const [showModal, setShowModal] = useState(false);
// //   const [dropdownStates, setDropdownStates] = useState({});

// //   const location = useLocation();
// //   const navigate = useNavigate();

// //   useEffect(() => {
// //     setIsOpen(false); // Close sidebar on route change
// //   }, [location]);

// //   const toggle = () => setIsOpen(!isOpen);

// //   const handleDropdownToggle = (index) => {
// //     setDropdownStates((prevState) => ({
// //       ...prevState,
// //       [index]: !prevState[index],
// //     }));
// //   };

// //   const handleLogoClick = () => {
// //     navigate("/");
// //   };

// //   const [role_id, setroleid] = useState();

// //   useEffect(() => {
// //     fetchDatas()
// //   }, []);

// //   const fetchDatas = async () => {
// //     const token = localStorage.getItem("token");
// //     try {
// //       const response = await axios.post(ApiUrl + "/get_detail", {
// //         token: token,
// //       });
// //       console.log("token detail from server:", response.data);

// //       console.log("The role id is  : ", response.data.detail.rolename);

// //       setroleid(response.data.detail.rolename);
// //       if (response.data.detail.rolename) {
// //         const response_role = await axios.get(
// //           ApiUrl + `/role/${response.data.detail.rolename}`
// //         );
// //         console.log("token detail from serverbr for role name:", response_role);
// //         console.log(
// //           "token detail from serverbr for role name:",
// //           response_role.data.rolename
// //         );

// //         console.log(
// //           "token detail from serverbr for modules:",
// //           response_role.data.modules[0].name
// //         );
// //         localStorage.setItem("rolename", response_role.data.rolename);
// //       }

// //       localStorage.setItem("assignId", response.data.detail.assignId);

// //       console.log("the siderbar response", response);
// //       if (response.data.detail.branch_id) {
// //         const responsebranch = await axios.get(
// //           ApiUrl + `/branch/${response.data.detail.branch_id}`
// //         );
// //         console.log("token detail from serverbr:", responsebranch.data);

// //         localStorage.setItem("branch_id", response.data.detail.branch_id);
// //         localStorage.setItem("role_id", response.data.detail.rolename);
// //         localStorage.setItem("username", response.data.detail.username);

// //         localStorage.setItem("emp_name", response.data.detail.emp_name);
// //         localStorage.setItem("branch_code", responsebranch.data[0]?.branch_id);
// //         localStorage.setItem(
// //           "branch_name",
// //           responsebranch.data[0]?.branch_name
// //         );
// //       } else {
// //         console.log("the role_id is : ", role_id);
      

// //         localStorage.setItem("branch_id", response.data.detail.branch_id);
// //         localStorage.setItem("role_id", response.data.detail.rolename);
// //         localStorage.setItem("username", response.data.detail.username);
// //         localStorage.setItem("emp_name", response.data.detail.emp_name);
// //         localStorage.setItem("branch_code", "All");
// //         localStorage.setItem("branch_name", "Branches");
// //       }

// //       const id = response.data.detail.rolename;

// //       if (id) {
// //         try {
// //           const response = await axios.get(
// //             ApiUrl + `/get_one_role_2_pages/${id}`
// //           );

// //           console.log("the reponse page list is : ", response.data);
// //           localStorage.setItem("modules", response.data);
// //           const received_pages = response.data;
      
// //         } catch (error) {
// //           console.error("Error fetching data:", error.message);
// //         }
// //       }
// //     } catch (error) {
// //       console.error("Error fetching data:", error.message);
// //     }
// //   };

// //   const handleCloseModal = () => {
// //     setShowModal(false);
// //   };

// //   const menuItem = [
// //     {
// //       path: "/dashboard",
// //       name: "Dashboard",
// //       icon: <FaTh className={location.pathname === "/" ? "text-white" : ""} />,
// //     },
// //     {
// //       path: "/role",
// //       name: "Roles",
// //       icon: (
// //         <i
// //           className={`fa-solid fa-layer-group ${
// //             location.pathname === "/role" ? "text-white" : ""
// //           }`}
// //         />
// //       ),
// //     },
// //     {
// //       path: "/user",
// //       name: "Users",
// //       icon: (
// //         <i
// //           className={`fa-solid fa-user-tie ${
// //             location.pathname === "/user" ? "text-white" : ""
// //           }`}
// //         />
// //       ),
// //     },
// //     {
// //       path: "/branch",
// //       name: "Branch",
// //       icon: (
// //         <i
// //           className={`fa-solid fa-sitemap ${
// //             location.pathname === "/branch" ? "text-white" : ""
// //           }`}
// //         />
// //       ),
// //     },
// //     {
// //       path: "/student",
// //       name: "Leads",
// //       icon: <i className="fa-solid fa-right-from-bracket"></i>,
// //       subItems: [
// //         {
// //           path: "/contactleads",
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Contact Leads",
// //         },
// //         { path: "/enquiry",
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap" ></i>
// //           ),
// //            name: "Enquiry" },
// //         {
// //           path: "/newenquiry",
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "New Enquiry",
// //         },
       
// //         { path: "/followsups",
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Followups" },
// //         { path: "/Walkin", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Field Visit" },
// //         { path: "/sourcetype", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Sourcetype" },
// //         { path: "/enquirypurpose", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Enquirypurpose" },
// //       ],
// //     },
// //     {
// //       path: "/#",
// //       name: "Projects",
// //       icon: <i class="fa-solid fa-list-check"></i>,
// //       subItems: [
// //         { path: "/upcomings", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Expected" },
// //         { path: "/ongoing", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Ongoing" },
// //         { path: "/completed", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Completed" },
// //       ],
// //     },
// //     {
// //       path: "/#",
// //       name: "Sales",
// //       icon: <i class="fa-solid fa-sack-dollar"></i>,
// //       subItems: [
// //         { path: "/salesorder", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Sales Order" },
// //         { path: "/customer", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Customer" },
// //       ],
// //     },
// //     {
// //       path: "/#",
// //       name: "Services",
// //       icon: <i class="fa-solid fa-gear"></i>,
// //       subItems: [
// //         { path: "/upcomingservices", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Upcomings" },
// //         { path: "/ongoingservices", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Ongoing" },
// //         { path: "/completeservices", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Completed" },
// //       ],
// //     },

// //     {
// //       path: "/#",
// //       name: "Staff",
// //       icon: <i className="fa-solid fa-right-from-bracket"></i>,
// //       subItems: [
// //         { path: "/staff", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Staff" },
// //         { path: "/attendance", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Attendance" },
// //         { path: "/designation", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Designation" },
// //         { path: "/leave", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Leave" },
// //         { path: "/travelallowance", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Allowance" },
// //         { path: "/salarydetails", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Salary Details" },
// //         { path: "/holiday", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Holiday" },
// //           { path: "/event", 
// //             icon: (
// //               <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //             ),
// //             name: "Event" },
// //           { path: "/dailytask", 
// //             icon: (
// //               <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //             ),
// //             name: "Daily Task" },
// //       ],
// //     },

// //     {
// //       path: "/stocks",
// //       name: "Stocks",
// //       icon: (
// //         <i
// //           className={`fa-solid fa-box ${
// //             location.pathname === "/stocks" ? "text-white" : ""
// //           }`}
// //         />
// //       ),
// //     },

// //     {
// //       path: "/products",
// //       name: "Product List",
// //       icon: (
// //         <i
// //           className={`fa-sharp fa-solid fa-window-minimize ${
// //             location.pathname === "/products" ? "text-white" : ""
// //           }`}
// //         />
// //       ),
// //     },

// //     // {
// //     //   path: "/#",
// //     //   name: "Products",
// //     //   icon: <i className="fa-solid fa-right-from-bracket"></i>,
// //     //   subItems: [
// //     //     { path: "/products", 
// //     //       icon: (
// //     //         <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //     //       ),
// //     //       name: "Product List" },
// //     //     { path: "/category", 
// //     //       icon: (
// //     //         <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //     //       ),
// //     //       name: "Category" },
// //     //   ],
// //     // },
// //     {
// //       path: "/#",
// //       name: "Purchase",
// //       icon: <i class="fa-solid fa-cart-shopping"></i>,
// //       subItems: [
// //         { path: "/purchaseorder", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Purchase Order" },
// //         { path: "/supplier", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Supplier" },
// //       ],
// //     },
// //     {
// //       path: "/assetlist",
// //       name: "Asset List",
// //       icon: (
// //         <i
// //           className={`fa-regular fa-compass ${
// //             location.pathname === "/assetlist" ? "text-white" : ""
// //           }`}
// //         />
// //       ),
// //     },
// //     {
// //       path: "/#",
// //       name: "Expense",
// //       icon: <i class="fa-solid fa-bars"></i>,
// //       subItems: [
// //         { path: "/expenselist", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Expense List" },

// //         { path: "/vendordetails", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Vendor Details" },
          
// //         { path: "/officeexpense", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Office Expense" },
// //         { path: "/paytype", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Paytype" },
// //       ],
// //     },
// //     {
// //       path: "/incomelist",
// //       name: "Income List",
// //       icon: (
// //         <i
// //           className={`fa-solid fa-wallet ${
// //             location.pathname === "/incomelist" ? "text-white" : ""
// //           }`}
// //         />
// //       ),
// //     },

// //     {
// //       path: "/invoice",
// //       name: "Invoice",
// //       icon: (
// //         <i
// //           className={`fa-solid fa-file-invoice ${
// //             location.pathname === "/invoice" ? "text-white" : ""
// //           }`}
// //         />
// //       ),
// //     },

// //     {
// //       path: "/#",
// //       name: "Report",
// //       icon: <i class="fa fa-file"></i>,
// //       subItems: [
// //         { path: "/dailyreport", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Daily Report" },
// //         { path: "/enquriyreport", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Enquiry Report" },
// //         { path: "/attendancereport", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Attendance Report" },
// //         { path: "/expensereport", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Expense Report" },
// //         { path: "/projectreport", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Project Report" },
// //         { path: "/staffreport", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Staff Report" },
// //         { path: "/incomereport", 
// //           icon: (
// //             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
// //           ),
// //           name: "Income Report" },
// //       ],
// //     },
// //     {
// //       path: "/logout",
// //       name: "Logout",
// //       icon: (
// //         <i
// //           className={`fa-solid fa-right-from-bracket ${
// //             location.pathname === "/logout" ? "text-white" : ""
// //           }`}
// //         />
// //       ),
// //     },
// //   ];

// //   if (
// //     ["/register", "*"].includes(location.pathname) ||
// //     location.pathname.startsWith("/invoice/invoiceprint/") ||
// //     location.pathname.startsWith("/invoice/bill/")
// //   ) {
// //     return (
// //       <main style={{ padding: "20px", marginTop: "0px" }}>{children}</main>
// //     );
// //   }

  

// //   return (
// //     <>
// //       <Navbar toggle={isOpen} />
// //       <Modal show={showModal} onHide={handleCloseModal}>
// //         <Modal.Header>
// //           <p>Are you sure you want to logout?</p>
// //         </Modal.Header>
// //         <Modal.Footer>
// //           <Button variant="secondary" onClick={handleCloseModal}>
// //             Close
// //           </Button>
// //           <Button variant="primary">Logout</Button>
// //         </Modal.Footer>
// //       </Modal>

// //       <div className="container-main">
// //         <div
// //           className="sidebar"
// //           style={{
// //             position: "fixed",
// //             top: "70px",
// //             bottom: 0,
// //             left: 0,
// //             width: isOpen ? "56px" : "250px",
// //             zIndex: 1000,
// //           }}
// //         >
// //           <div className="top_section">
// //             <p
// //               style={{ display: isOpen ? "none" : "block" }}
// //               className={`logo mx-4 ${isOpen ? "" : "d-flex"}`}
// //             >
// //               <div className="admincont">
// //                 {" "}
// //                 <img
// //                   src={require("../../assets/img/scimitar logo slogan.png")}
// //                   alt="Company Logo"
// //                   style={{
// //                     width: "200px",
// //                     marginTop: "5px",
// //                     marginLeft: "-30px",
// //                   }}
// //                   onClick={handleLogoClick}
// //                 ></img>
// //               </div>
// //             </p>
// //             <div
// //               style={{ marginLeft: isOpen ? "7px" : "70px" }}
// //               className="bars"
// //             >
// //               <FaBars onClick={toggle} />
// //             </div>
// //           </div>

// //           {menuItem.map((item, index) => (
// //             <div key={index}>
// //               {item.subItems ? (
// //                 <>
// //                   <div
// //                     className="link"
// //                     onClick={() => handleDropdownToggle(index)}
// //                   >
// //                     <div className="icon">{item.icon}</div>
// //                     <div className={`link_text ${isOpen ? "d-none" : ""}`}>
// //                       {item.name}
// //                     </div>
// //                     <div className="dropdown_icon">
// //                       {dropdownStates[index] ? (
// //                         <i className="fa-solid fa-chevron-up"></i>
// //                       ) : (
// //                         <i className="fa-solid fa-chevron-down"></i>
// //                       )}
// //                     </div>
// //                   </div>
// //                   {dropdownStates[index] && (
// //                     <div className="subMenu">
// //                       {item.subItems.map((subItem, subIndex) => (
// //                         <NavLink
// //                           key={subIndex}
// //                           to={subItem.path}
// //                           className="link submenu"
// //                           activeClassName="active"
// //                         >
// //                           <div className="icon">{subItem.icon}</div>
// //                           <div
// //                             className={`link_text ${isOpen ? "d-none" : ""}`}
// //                           >
// //                             {subItem.name}
// //                           </div>
// //                         </NavLink>
// //                       ))}
// //                     </div>
// //                   )}
// //                 </>
// //               ) : (
// //                 <NavLink
// //                   to={item.path}
// //                   className="link"
// //                   activeClassName="active"
// //                 >
// //                   <div className="icon">{item.icon}</div>
// //                   <div className={`link_text ${isOpen ? "d-none" : ""}`}>
// //                     {item.name}
// //                   </div>
// //                 </NavLink>
// //               )}
// //             </div>
// //           ))}
// //         </div>
// //         <main className={`main ${isOpen ? "isClose" : "isOpen"}`}>
// //           {children}
// //         </main>
// //       </div>
// //     </>
// //   );
// // };

// // export default Sidebar;



// import React, { useState, useEffect } from "react";
// import { FaTh, FaBars } from "react-icons/fa";
// import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { Button, Modal } from "react-bootstrap";
// import Navbar from "../Navbar";
// import "../../assets/css/Sidebar.css";
// import ApiUrl from './../Environment/ApiUrl';
// import axios from "axios";

// const Sidebar = ({ children }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//   const [dropdownStates, setDropdownStates] = useState({});

//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     setIsOpen(false); // Close sidebar on route change
//   }, [location]);

//   const toggle = () => setIsOpen(!isOpen);

//   // const handleDropdownToggle = (index) => {
//   //   setDropdownStates((prevState) => ({
//   //     ...prevState,
//   //     [index]: !prevState[index],
//   //   }));
//   // };

//   const handleDropdownToggle = (index) => {
//     setDropdownStates((prevState) => ({
//       ...prevState,
//       [index]: !prevState[index],
//     }));
//   };

//   const handleLogoClick = () => {
//     navigate("/");
//   };

//   const [role_id, setroleid] = useState();

//   useEffect(() => {
//     fetchDatas()
//   }, []);

//   const fetchDatas = async () => {
//     const token = localStorage.getItem("token");
 
//     try {
//       const response = await axios.post(ApiUrl + "/get_detail", {
//         token: token,
  
//       });
//       console.log("token detail from server:", response.data);

//       console.log("The role id is  : ", response.data.detail.rolename);

//       setroleid(response.data.detail.rolename);
//       if (response.data.detail.rolename) {
//         const response_role = await axios.get(
//           ApiUrl + `/role/${response.data.detail.rolename}`
//         );
//         console.log("token detail from serverbr for role name:", response_role);
//         console.log(
//           "token detail from serverbr for role name:",
//           response_role.data.rolename
//         );

//         console.log(
//           "token detail from serverbr for modules:",
//           response_role.data.modules[0].name
//         );
//         localStorage.setItem("rolename", response_role.data.rolename);

//       }

//       localStorage.setItem("assignId", response.data.detail.assignId);

//       console.log("the siderbar response", response);
//       if (response.data.detail.branch_id) {
//         const responsebranch = await axios.get(
//           ApiUrl + `/branch/${response.data.detail.branch_id}`
//         );
//         console.log("token detail from serverbr:", responsebranch.data);

//         localStorage.setItem("branch_id", response.data.detail.branch_id);
//         localStorage.setItem("role_id", response.data.detail.rolename);
//         localStorage.setItem("username", response.data.detail.username);

//         localStorage.setItem("emp_name", response.data.detail.emp_name);
//         localStorage.setItem("branch_code", responsebranch.data[0]?.branch_id);
//         localStorage.setItem(
//           "branch_name",
//           responsebranch.data[0]?.branch_name
//         );
//       } else {
//         console.log("the role_id is : ", role_id);


//         localStorage.setItem("branch_id", response.data.detail.branch_id);
//         localStorage.setItem("role_id", response.data.detail.rolename);
//         localStorage.setItem("username", response.data.detail.username);
//         localStorage.setItem("emp_name", response.data.detail.emp_name);
//         localStorage.setItem("branch_code", "All");
//         localStorage.setItem("branch_name", "Branches");
//       }

//       const id = response.data.detail.rolename;

//       if (id) {
//         try {
//           const response = await axios.get(
//             ApiUrl + `/get_one_role_2_pages/${id}`
//           );

//           console.log("the reponse page list is : ", response.data);
//           localStorage.setItem("modules", response.data);
//           const received_pages = response.data;

//         } catch (error) {
//           console.error("Error fetching data:", error.message);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const menuItem = [
//     {
//       path: "/dashboard",
//       name: "Dashboard",
//       icon: <FaTh className={location.pathname === "/" ? "text-white" : ""} />,
//     },
//     {
//       path: "/role",
//       name: "Roles",
//       icon: (
//         <i
//           className={`fa-solid fa-layer-group ${location.pathname === "/role" ? "text-white" : ""
//             }`}
//         />
//       ),
//     },
//     {
//       path: "/user",
//       name: "Users",
//       icon: (
//         <i
//           className={`fa-solid fa-user-tie ${location.pathname === "/user" ? "text-white" : ""
//             }`}
//         />
//       ),
//     },
//     {
//       path: "/branch",
//       name: "Branch",
//       icon: (
//         <i
//           className={`fa-solid fa-sitemap ${location.pathname === "/branch" ? "text-white" : ""
//             }`}
//         />
//       ),
//     },
//     {
//       path: "/student",
//       name: "Leads",
//       icon: <i className="fa-solid fa-right-from-bracket"></i>,
//       subItems: [
//         {
//           path: "/contactleads",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Contact Leads",
//         },
//         {
//           path: "/enquiry",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap" ></i>
//           ),
//           name: "Enquiry"
//         },
//         {
//           path: "/newenquiry",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "New Enquiry",
//         },

//         {
//           path: "/followsups",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Followups"
//         },
//         {
//           path: "/Walkin",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Field Visit"
//         },
//         {
//           path: "/sourcetype",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Sourcetype"
//         },
//         {
//           path: "/enquirypurpose",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Enquirypurpose"
//         },
//       ],
//     },
//     {
//       path: "/#",
//       name: "Projects",
//       icon: <i class="fa-solid fa-list-check"></i>,
//       subItems: [
//         {
//           path: "/upcomings",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Expected"
//         },
//         {
//           path: "/ongoing",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Ongoing"
//         },
//         {
//           path: "/completed",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Completed"
//         },
//       ],
//     },
//     {
//       path: "/#",
//       name: "Sales",
//       icon: <i class="fa-solid fa-sack-dollar"></i>,
//       subItems: [
//         {
//           path: "/salesorder",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Sales Order"
//         },
//         {
//           path: "/customer",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Customer"
//         },
//       ],
//     },
//     {
//       path: "/#",
//       name: "Services",
//       icon: <i class="fa-solid fa-gear"></i>,
//       subItems: [
//         {
//           path: "/upcomingservices",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Upcomings"
//         },
//         {
//           path: "/ongoingservices",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Ongoing"
//         },
//         {
//           path: "/completeservices",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Completed"
//         },
//       ],
//     },

//     {
//       path: "/#",
//       name: "Staff",
//       icon: <i className="fa-solid fa-right-from-bracket"></i>,
//       subItems: [
//         {
//           path: "/staff",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Staff"
//         },
//         {
//           path: "/attendance",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Attendance"
//         },
//         {
//           path: "/designation",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Designation"
//         },
//         {
//           path: "/leave",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Leave"
//         },
//         {
//           path: "/travelallowance",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Allowance"
//         },
//         {
//           path: "/salarydetails",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Salary Details"
//         },
//         {
//           path: "/holiday",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Holiday"
//         },
//         {
//           path: "/event",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Event"
//         },
//         {
//           path: "/dailytask",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Daily Task"
//         },
//       ],
//     },

//     {
//       path: "/stocks",
//       name: "Stocks",
//       icon: (
//         <i
//           className={`fa-solid fa-box ${location.pathname === "/stocks" ? "text-white" : ""
//             }`}
//         />
//       ),
//     },

//     {
//       path: "/products",
//       name: "Product List",
//       icon: (
//         <i
//           className={`fa-sharp fa-solid fa-window-minimize ${location.pathname === "/products" ? "text-white" : ""
//             }`}
//         />
//       ),
//     },

//     // {
//     //   path: "/#",
//     //   name: "Products",
//     //   icon: <i className="fa-solid fa-right-from-bracket"></i>,
//     //   subItems: [
//     //     { path: "/products", 
//     //       icon: (
//     //         <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//     //       ),
//     //       name: "Product List" },
//     //     { path: "/category", 
//     //       icon: (
//     //         <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//     //       ),
//     //       name: "Category" },
//     //   ],
//     // },
//     {
//       path: "/#",
//       name: "Purchase",
//       icon: <i class="fa-solid fa-cart-shopping"></i>,
//       subItems: [
//         {
//           path: "/purchaseorder",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Purchase Order"
//         },
//         {
//           path: "/supplier",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Supplier"
//         },
//       ],
//     },
//     {
//       path: "/assetlist",
//       name: "Asset List",
//       icon: (
//         <i
//           className={`fa-regular fa-compass ${location.pathname === "/assetlist" ? "text-white" : ""
//             }`}
//         />
//       ),
//     },
//     {
//       path: "/#",
//       name: "Expense",
//       icon: <i class="fa-solid fa-bars"></i>,
//       subItems: [
//         {
//           path: "/expenselist",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Expense List"
//         },

//         {
//           path: "/vendordetails",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Vendor Details"
//         },

//         {
//           path: "/officeexpense",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Office Expense"
//         },
//         {
//           path: "/paytype",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Paytype"
//         },
//       ],
//     },
//     {
//       path: "/incomelist",
//       name: "Income List",
//       icon: (
//         <i
//           className={`fa-solid fa-wallet ${location.pathname === "/incomelist" ? "text-white" : ""
//             }`}
//         />
//       ),
//     },

//     {
//       path: "/invoice",
//       name: "Invoice",
//       icon: (
//         <i
//           className={`fa-solid fa-file-invoice ${location.pathname === "/invoice" ? "text-white" : ""
//             }`}
//         />
//       ),
//     },

//     {
//       path: "/#",
//       name: "Report",
//       icon: <i class="fa fa-file"></i>,
//       subItems: [
//         {
//           path: "/dailyreport",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Daily Report"
//         },
//         {
//           path: "/enquriyreport",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Enquiry Report"
//         },
//         {
//           path: "/attendancereport",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Attendance Report"
//         },
//         {
//           path: "/expensereport",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Expense Report"
//         },
//         {
//           path: "/projectreport",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Project Report"
//         },
//         {
//           path: "/staffreport",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Staff Report"
//         },
//         {
//           path: "/incomereport",
//           icon: (
//             <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
//           ),
//           name: "Income Report"
//         },
//       ],
//     },
//     // {
//     //   path: "/logout",
//     //   name: "Logout",
//     //   icon: (
//     //     <i
//     //       className={`fa-solid fa-right-from-bracket ${location.pathname === "/logout" ? "text-white" : ""
//     //         }`}
//     //     />
//     //   ),
//     // },
//   ];

//   if (
//     ["/register", "*"].includes(location.pathname) ||
//     location.pathname.startsWith("/invoice/invoiceprint/") ||
//     location.pathname.startsWith("/invoice/bill/")
//   ) {
//     return (
//       <main style={{ padding: "20px", marginTop: "0px" }}>{children}</main>
//     );
//   }





//   const handleLogout = async () => {
//     const token = localStorage.getItem("token");

//     if (token) {
//       // Assuming username is stored in localStorage
//       const token = localStorage.getItem("token");
//       try {
//         // setLoading(true);

//         const last_logout_time = new Date().toLocaleString();

//         const response = await axios.post(ApiUrl + '/logoutadmin', { token, last_logout_time });
//         // Check if the response includes a message and show it

//         console.log("`the data was`", response)

//         if (response.data && response.data.message) {
//           // alert(response.data.message);  // Show the backend response message
//           // setTimeout(() => {
//           //   setLoading(false);
//           // }, 5000);

//           setTimeout(() => {
//             // setLoading(false);
//             navigate("/");
//             window.location.reload();  // Reload the page to reset any remaining state
//           }, 1000);
//         } else {
//           // If no message in the response
//           alert("Logout failed. Please try again.");
//         }
//         console.log("the response from sidebar 1", response)

//         // Clear localStorage items after successful logout
//         localStorage.removeItem("token");
//         // localStorage.removeItem("id");
//         // localStorage.removeItem("branch_code");
//         // localStorage.removeItem("branch_id");
//         // localStorage.removeItem("branch_name");
//         // localStorage.removeItem("role_id");
//         // localStorage.removeItem("type");
//         // localStorage.removeItem("username");

//         // localStorage.removeItem("assignId");
//         // localStorage.removeItem("emp_name");
//         // localStorage.removeItem("modules");
//         // localStorage.removeItem("rolename");
//         // localStorage.removeItem("user_previllages");





//       } catch (error) {
//         // Error handling in case of API failure
//         if (error.response) {
//           alert(error.response.data.message || "Error logging out.");
//         } else {
//           alert("There was an error logging out. Please try again.");
//         }

//         // Still clear localStorage if logout fails
//         localStorage.removeItem("token");
//         // localStorage.removeItem("id");
//         // localStorage.removeItem("branch_code");
//         // localStorage.removeItem("branch_id");
//         // localStorage.removeItem("branch_name");
//         // localStorage.removeItem("role_id");
//         // localStorage.removeItem("type");
//         // localStorage.removeItem("username");

//         // localStorage.removeItem("assignId");
//         // localStorage.removeItem("emp_name");
//         // localStorage.removeItem("modules");
//         // localStorage.removeItem("rolename");
//         // localStorage.removeItem("user_previllages");

//         // Redirect to the homepage or login page
//         navigate("/");
//         window.location.reload();
//       }
//     } else {
//       // If no token found, just clear localStorage and navigate
//       localStorage.removeItem("token");
//       localStorage.removeItem("id");
//       localStorage.removeItem("branch_code");
//       localStorage.removeItem("branch_id");
//       localStorage.removeItem("branch_name");
//       localStorage.removeItem("role_id");
//       localStorage.removeItem("type");
//       localStorage.removeItem("username");

//       // Navigate to the homepage or login page
//       navigate("/");
//       window.location.reload();
//     }
//   };



//   return (
//     <>
//       <Navbar toggle={isOpen} />
//       <Modal show={showModal} onHide={handleCloseModal}>
//         <Modal.Header>
//           <p>Are you sure you want to logout?</p>
//         </Modal.Header>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleLogout}>Logout</Button>
//         </Modal.Footer>
//       </Modal>

//       <div className="container-main">
//         <div
//           className="sidebar"
//           style={{
//             position: "fixed",
//             top: "70px",
//             bottom: 0,
//             left: 0,
//             width: isOpen ? "56px" : "250px",
//             zIndex: 1000,
//           }}
//         >
//           <div className="top_section">
//             <p
//               style={{ display: isOpen ? "none" : "block" }}
//               className={`logo mx-4 ${isOpen ? "" : "d-flex"}`}
//             >
//               <div className="admincont">
//                 {" "}
//                 <img
//                   src={require("../../assets/img/scimitar logo slogan.png")}
//                   alt="Company Logo"
//                   style={{
//                     width: "200px",
//                     marginTop: "5px",
//                     marginLeft: "-30px",
//                   }}
//                   onClick={handleLogoClick}
//                 ></img>
//               </div>
//             </p>
//             <div
//               style={{ marginLeft: isOpen ? "7px" : "70px" }}
//               className="bars"
//             >
//               <FaBars onClick={toggle} />
//             </div>
//           </div>

//           {menuItem.map((item, index) => (
//             <div key={index}>
//               {item.subItems ? (
//                 <>
//                   <div
//                     className="link"
//                     onClick={() => handleDropdownToggle(index)}
//                   >
//                     <div className="icon">{item.icon}</div>
//                     <div className={`link_text ${isOpen ? "d-none" : ""}`}>
//                       {item.name}
//                     </div>
//                     <div className="dropdown_icon">
//                       {dropdownStates[index] ? (
//                         <i className="fa-solid fa-chevron-up"></i>
//                       ) : (
//                         <i className="fa-solid fa-chevron-down"></i>
//                       )}
//                     </div>
//                   </div>
//                   {dropdownStates[index] && (
//                     <div className="subMenu">
//                       {item.subItems.map((subItem, subIndex) => (
//                         <NavLink
//                           key={subIndex}
//                           to={subItem.path}
//                           className="link submenu"
//                           activeClassName="active"
//                         >
//                           <div className="icon">{subItem.icon}</div>
//                           <div
//                             className={`link_text ${isOpen ? "d-none" : ""}`}
//                           >
//                             {subItem.name}
//                           </div>
//                         </NavLink>
//                       ))}
//                     </div>
//                   )}
//                 </>
//               ) : (
//                 <NavLink
//                   to={item.path}
//                   className="link"
//                   activeClassName="active"
//                 >
//                   <div className="icon">{item.icon}</div>
//                   <div className={`link_text ${isOpen ? "d-none" : ""}`}>
//                     {item.name}
//                   </div>
//                 </NavLink>
//               )}
//             </div>
//           ))}

//           <div className="link" onClick={() => setShowModal(true)}>
//             <div className="icon">
//               <i className="fa-solid fa-right-from-bracket"></i>
//             </div>
//             <div
//               style={{ display: isOpen ? "none" : "block" }}
//               className="link_text"
//             >
//               Logout
//             </div>

//             {showModal && (
//               <div className="logout-modal">
//                 <p>Are you sure you want to log out?</p>
//                 <button onClick={handleLogout}>Yes, log me out</button>
//                 <button onClick={() => setShowModal(false)}>Cancel</button>
//               </div>
//             )}
//           </div>
//         </div>
//         <main className={`main ${isOpen ? "isClose" : "isOpen"}`}>
//           {children}
//         </main>
//       </div>
//     </>
//   );
// };

// export default Sidebar;


import React, { useState, useEffect } from "react";
import { FaTh, FaBars } from "react-icons/fa";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Navbar from "../Navbar";
import "../../assets/css/Sidebar.css";
import ApiUrl from './../Environment/ApiUrl';
import axios from "axios";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({});

  const [openIndex, setOpenIndex] = useState(null); // Track which submenu is open

  const handleDropdownToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle open state
  };

  //this is my preview toogle code 
  // const handleDropdownToggle = (index) => {
  //   setDropdownStates((prevState) => ({
  //     ...prevState,
  //     [index]: !prevState[index],
  //   }));
  // };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(false); // Close sidebar on route change
  }, [location]);

  const toggle = () => setIsOpen(!isOpen);

  const handleLogoClick = () => {
    navigate("/");
  };

  const [role_id, setroleid] = useState();

  useEffect(() => {
    fetchDatas()
  }, []);

  const fetchDatas = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(ApiUrl + "/get_detail", {
        token: token,

      });
      console.log("token detail from server:", response.data);

      console.log("The role id is  : ", response.data.detail.rolename);

      setroleid(response.data.detail.rolename);
      if (response.data.detail.rolename) {
        const response_role = await axios.get(
          ApiUrl + `/role/${response.data.detail.rolename}`
        );
        console.log("token detail from serverbr for role name:", response_role);
        console.log(
          "token detail from serverbr for role name:",
          response_role.data.rolename
        );

        console.log(
          "token detail from serverbr for modules:",
          response_role.data.modules[0].name
        );
        localStorage.setItem("rolename", response_role.data.rolename);

      }

      localStorage.setItem("assignId", response.data.detail.assignId);

      console.log("the siderbar response", response);
      if (response.data.detail.branch_id) {
        const responsebranch = await axios.get(
          ApiUrl + `/branch/${response.data.detail.branch_id}`
        );
        console.log("token detail from serverbr:", responsebranch.data);

        localStorage.setItem("branch_id", response.data.detail.branch_id);
        localStorage.setItem("role_id", response.data.detail.rolename);
        localStorage.setItem("username", response.data.detail.username);

        localStorage.setItem("emp_name", response.data.detail.emp_name);
        localStorage.setItem("branch_code", responsebranch.data[0]?.branch_id);
        localStorage.setItem(
          "branch_name",
          responsebranch.data[0]?.branch_name
        );
      } else {
        console.log("the role_id is : ", role_id);


        localStorage.setItem("branch_id", response.data.detail.branch_id);
        localStorage.setItem("role_id", response.data.detail.rolename);
        localStorage.setItem("username", response.data.detail.username);
        localStorage.setItem("emp_name", response.data.detail.emp_name);
        localStorage.setItem("branch_code", "All");
        localStorage.setItem("branch_name", "Branches");
      }

      const id = response.data.detail.rolename;

      if (id) {
        try {
          const response = await axios.get(
            ApiUrl + `/get_one_role_2_pages/${id}`
          );

          console.log("the reponse page list is : ", response.data);
          localStorage.setItem("modules", response.data);
          const received_pages = response.data;

        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <FaTh className={location.pathname === "/" ? "text-white" : ""} />,
    },
    {
      path: "/role",
      name: "Roles",
      icon: (
        <i
          className={`fa-solid fa-layer-group ${location.pathname === "/role" ? "text-white" : ""
            }`}
        />
      ),
    },

    {
      path: "/user",
      name: "Users",
      icon: (
        <i
          className={`${location.pathname === "/user" ? "text-white" : ""
            } fa-solid fa-user-tie`}
        ></i>
      ),
    },
    {
      path: "/branch",
      name: "Branch",
      icon: (
        <i
          className={`fa-solid fa-sitemap ${location.pathname === "/branch" ? "text-white" : ""
            }`}
        />
      ),
    },
    {
      path: "/student",
      name: "Leads",
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
      subItems: [
        {
          path: "/contactleads",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Contact Leads",
        },
        {
          path: "/enquiry",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap" ></i>
          ),
          name: "Enquiry"
        },
        {
          path: "/newenquiry",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "New Enquiry",
        },

        {
          path: "/followsups",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Followups"
        },
        {
          path: "/Walkin",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Field Visit"
        },
        {
          path: "/sourcetype",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Sourcetype"
        },
        {
          path: "/enquirypurpose",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Enquirypurpose"
        },
      ],
    },
    {
      path: "/#",
      name: "Projects",
      icon: <i class="fa-solid fa-list-check"></i>,
      subItems: [
        {
          path: "/upcomings",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Expected"
        },
        {
          path: "/ongoing",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Ongoing"
        },
        {
          path: "/completed",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Completed"
        },
      ],
    },
    {
      path: "/#",
      name: "Sales",
      icon: <i class="fa-solid fa-sack-dollar"></i>,
      subItems: [
        {
          path: "/salesorder",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Sales Order"
        },
        {
          path: "/customer",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Customer"
        },
      ],
    },
    {
      path: "/#",
      name: "Services",
      icon: <i class="fa-solid fa-gear"></i>,
      subItems: [
        {
          path: "/upcomingservices",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Upcomings"
        },
        {
          path: "/ongoingservices",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Ongoing"
        },
        {
          path: "/completeservices",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Completed"
        },
      ],
    },

    {
      path: "/#",
      name: "Staff",
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
      subItems: [
        {
          path: "/staff",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Staff"
        },
        {
          path: "/attendance",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Attendance"
        },
        {
          path: "/designation",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Designation"
        },
        {
          path: "/leave",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Leave"
        },
        {
          path: "/travelallowance",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Allowance"
        },
        {
          path: "/salarydetails",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Salary Details"
        },
        {
          path: "/holiday",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Holiday"
        },
        {
          path: "/event",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Event"
        },
        {
          path: "/dailytask",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Daily Task"
        },
      ],
    },

    {
      path: "/stocks",
      name: "Stocks",
      icon: (
        <i
          className={`fa-solid fa-box ${location.pathname === "/stocks" ? "text-white" : ""
            }`}
        />
      ),
    },

    {
      path: "/products",
      name: "Product List",
      icon: (
        <i
          className={`fa-sharp fa-solid fa-window-minimize ${location.pathname === "/products" ? "text-white" : ""
            }`}
        />
      ),
    },
    {
      path: "/#",
      name: "Purchase",
      icon: <i class="fa-solid fa-cart-shopping"></i>,
      subItems: [
        {
          path: "/purchaseorder",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Purchase Order"
        },
        {
          path: "/supplier",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Supplier"
        },
      ],
    },
    {
      path: "/assetlist",
      name: "Asset List",
      icon: (
        <i
          className={`fa-regular fa-compass ${location.pathname === "/assetlist" ? "text-white" : ""
            }`}
        />
      ),
    },
    {
      path: "/#",
      name: "Expense",
      icon: <i class="fa-solid fa-bars"></i>,
      subItems: [
        {
          path: "/expenselist",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Expense List"
        },

        {
          path: "/vendordetails",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Vendor Details"
        },

        {
          path: "/officeexpense",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Office Expense"
        },
        {
          path: "/paytype",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Paytype"
        },
      ],
    },
    {
      path: "/incomelist",
      name: "Income List",
      icon: (
        <i
          className={`fa-solid fa-wallet ${location.pathname === "/incomelist" ? "text-white" : ""
            }`}
        />
      ),
    },

    {
      path: "/invoice",
      name: "Invoice",
      icon: (
        <i
          className={`fa-solid fa-file-invoice ${location.pathname === "/invoice" ? "text-white" : ""
            }`}
        />
      ),
    },

    {
      path: "/#",
      name: "Report",
      icon: <i class="fa fa-file"></i>,
      subItems: [
        {
          path: "/dailyreport",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Daily Report"
        },
        {
          path: "/enquriyreport",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Enquiry Report"
        },
        {
          path: "/attendancereport",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Attendance Report"
        },
        {
          path: "/expensereport",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Expense Report"
        },
        {
          path: "/projectreport",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Project Report"
        },
        {
          path: "/staffreport",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Staff Report"
        },
        {
          path: "/incomereport",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),

          name: "Income Report"
        },
      ],
    },

  ];

  if (
    ["/register", "*"].includes(location.pathname) ||
    location.pathname.startsWith("/invoice/invoiceprint/") ||
    location.pathname.startsWith("/invoice/bill/")
  ) {
    return (
      <main style={{ padding: "20px", marginTop: "0px" }}>{children}</main>
    );
  }





  const handleLogout = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      // Assuming username is stored in localStorage
      const token = localStorage.getItem("token");
      try {
        // setLoading(true);

        const last_logout_time = new Date().toLocaleString();

        const response = await axios.post(ApiUrl + '/logoutadmin', { token, last_logout_time });
        // Check if the response includes a message and show it

        console.log("`the data was`", response)

        if (response.data && response.data.message) {
          // alert(response.data.message);  // Show the backend response message
          // setTimeout(() => {
          //   setLoading(false);
          // }, 5000);

          setTimeout(() => {
            // setLoading(false);
            navigate("/");
            window.location.reload();  // Reload the page to reset any remaining state
          }, 1000);
        } else {
          // If no message in the response
          alert("Logout failed. Please try again.");
        }
        console.log("the response from sidebar 1", response)

        // Clear localStorage items after successful logout
        localStorage.removeItem("token");
        // localStorage.removeItem("id");
        // localStorage.removeItem("branch_code");
        // localStorage.removeItem("branch_id");
        // localStorage.removeItem("branch_name");
        // localStorage.removeItem("role_id");
        // localStorage.removeItem("type");
        // localStorage.removeItem("username");

        // localStorage.removeItem("assignId");
        // localStorage.removeItem("emp_name");
        // localStorage.removeItem("modules");
        // localStorage.removeItem("rolename");
        // localStorage.removeItem("user_previllages");





      } catch (error) {
        // Error handling in case of API failure
        if (error.response) {
          alert(error.response.data.message || "Error logging out.");
        } else {
          alert("There was an error logging out. Please try again.");
        }

        // Still clear localStorage if logout fails
        localStorage.removeItem("token");
        // localStorage.removeItem("id");
        // localStorage.removeItem("branch_code");
        // localStorage.removeItem("branch_id");
        // localStorage.removeItem("branch_name");
        // localStorage.removeItem("role_id");
        // localStorage.removeItem("type");
        // localStorage.removeItem("username");

        // localStorage.removeItem("assignId");
        // localStorage.removeItem("emp_name");
        // localStorage.removeItem("modules");
        // localStorage.removeItem("rolename");
        // localStorage.removeItem("user_previllages");

        // Redirect to the homepage or login page
        navigate("/");
        window.location.reload();
      }
    } else {
      // If no token found, just clear localStorage and navigate
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("branch_code");
      localStorage.removeItem("branch_id");
      localStorage.removeItem("branch_name");
      localStorage.removeItem("role_id");
      localStorage.removeItem("type");
      localStorage.removeItem("username");

      // Navigate to the homepage or login page
      navigate("/");
      window.location.reload();
    }
  };



  return (
    <>
      <Navbar toggle={isOpen} />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <p>Are you sure you want to logout?</p>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleLogout}>Logout</Button>
        </Modal.Footer>
      </Modal>

      <div className="container-main">
        <div
          className="sidebar"
          style={{
            position: "fixed",
            top: "70px",
            bottom: 0,
            left: 0,
            width: isOpen ? "56px" : "250px",
            zIndex: 1000,
          }}
        >
          <div className="top_section">
            <p
              style={{ display: isOpen ? "none" : "block" }}
              className={`logo mx-4 ${isOpen ? "" : "d-flex"}`}
            >
              <div className="admincont">
                {" "}
                <img
                  src={require("../../assets/img/scimitar logo slogan.png")}
                  alt="Company Logo"
                  style={{
                    width: "200px",
                    marginTop: "5px",
                    marginLeft: "-30px",
                  }}
                  onClick={handleLogoClick}
                ></img>
              </div>
            </p>
            <div
              style={{ marginLeft: isOpen ? "7px" : "70px" }}
              className="bars"
            >
              <FaBars onClick={toggle} />
            </div>
          </div>

          {menuItem.map((item, index) => (
            <div key={index}>
              {item.subItems ? (
                <>
                  <div className="link" onClick={() => handleDropdownToggle(index)}>
                    <div className="icon">{item.icon}</div>
                    <div className={`link_text ${isOpen ? 'd-none' : ''}`}>
                      {item.name}
                    </div>
                    <div className="dropdown_icon">
                      {openIndex === index ? (
                        <i className="fa-solid fa-chevron-up"></i>
                      ) : (
                        <i className="fa-solid fa-chevron-down"></i>
                      )}
                    </div>
                  </div>
                  {openIndex === index && (
                    <div className="subMenu">
                      {item.subItems.map((subItem, subIndex) => (
                        <NavLink
                          key={subIndex}
                          to={subItem.path}
                          className="link submenu"
                          activeClassName="active"
                        >
                          <div className="icon">{subItem.icon}</div>
                          <div className={`link_text ${isOpen ? 'd-none' : ''}`}>
                            {subItem.name}
                          </div>
                        </NavLink>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <NavLink
                  to={item.path}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon">{item.icon}</div>
                  <div className={`link_text ${isOpen ? 'd-none' : ''}`}>
                    {item.name}
                  </div>
                </NavLink>
              )}
            </div>
          ))}

          <div className="link" onClick={() => setShowModal(true)}>
            <div className="icon">
              <i className="fa-solid fa-right-from-bracket"></i>
            </div>
            <div
              style={{ display: isOpen ? "none" : "block" }}
              className="link_text"
            >
              Logout
            </div>

            {showModal && (
              <div className="logout-modal">
                <p>Are you sure you want to log out?</p>
                <button onClick={handleLogout}>Yes, log me out</button>
                <button onClick={() => setShowModal(false)}>Cancel</button>
              </div>
            )}
          </div>
        </div>
        <main className={`main ${isOpen ? "isClose" : "isOpen"}`}>
          {children}
        </main>
      </div>
    </>
  );
};

export default Sidebar;
