// // // PrivateRoute.js
// // import React from 'react';
// // import { Navigate } from 'react-router-dom';

// // const PrivateRoute = ({ element: Component }) => {
// //   const token = localStorage.getItem('token'); // Check if token exists in localStorage

// //   return token ? Component : <Navigate to="/" />; // Redirect to login if no token
// // };

// // export default PrivateRoute;



// // PrivateRoute.js
// import React from 'react';
// import { Navigate } from 'react-router-dom';

// const PrivateRoute = ({ element: Component }) => {
//   const token = localStorage.getItem('token'); // Check if token exists in localStorage

//   return token ? Component : <Navigate to="/" />; // Redirect to login if no token
// };

// export default PrivateRoute;
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../SignupLogin/AuthContext"; // Ensure the correct path

const PrivateRoute = ({ element }) => {
  const { isAuthenticated } = useAuth(); // Check authentication state

  return isAuthenticated ? element : <Navigate to="/" replace />; // Redirect to login if not authenticated
};

export default PrivateRoute;
