import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "../../assets/css/Common.css";
import "../../assets/css/updelete.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import ApiUrl from "../Environment/ApiUrl";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};
function Supplier() {
  const [responseData, setResponseData] = useState([]);
  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      branch_id: item.branch_id,
      supplier_id: item.supplier_id,
      name: item.name,
      mobile_no: item.mobile_no,
      email: item.email,
      supplier_name: item.supplier_name,
      address: item.address,
      door_no: item.door_no,
      street_name: item.street_name,
      area: item.area,
      pin_code: item.pin_code,
      gst_no: item.gst_no,
      notes: item.notes,
      status: item.status,
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Supplier ID",
        accessor: "supplier_id",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Mobile",
        accessor: "mobile_no",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Supplier Name",
        accessor: "supplier_name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "GST",
        accessor: "gst_no",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // States for modals
  const [showModal, setShowModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [deleteShowModals, setDeleteShowModals] = useState(false);

  // State for branch data
  const [responseDataBranch, setResponseDataBranch] = useState([]);

  // State for delete operation
  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");

  // State for update operation
  const [updateid, setUpdateId] = useState("");

  // Form data states
  const [addData, setAddData] = useState({
    branch_id: "",
    supplier_id: "",
    name: "",
    mobile_no: "",
    email: "",
    supplier_name: "",
    door_no: "",
    street_name: "",
    area: "",
    pin_code: "",
    gst_no: "",
    notes: "",
    status: "",
  });

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    supplier_id: "",
    name: "",
    mobile_no: "",
    email: "",
    supplier_name: "",
    door_no: "",
    street_name: "",
    area: "",
    pin_code: "",
    gst_no: "",
    notes: "",
    status: "",
  });

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
    fetchDatas();
  }, []);

  // Fetch suppliers data
  const fetchData = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/supplier`);
      console.log("Response from server:", response.data);
      setResponseData([...response.data].reverse()); // Ensures correct rendering
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // Fetch branches data
  const fetchDatas = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/branch`);
      console.log("Response from server:", response.data);
      setResponseDataBranch(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // Handle update operation
  const handleUpdate = (_id, data) => {
    setUpdateId(_id);
    setUpdateModal(true);
    setUpdateData({
      branch_id: data.branch_id?._id,
      supplier_id: data.supplier_id,
      name: data.name,
      mobile_no: data.mobile_no,
      email: data.email,
      supplier_name: data.supplier_name,
      door_no: data.door_no || "",
      street_name: data.street_name || "",
      area: data.area || "",
      pin_code: data.pin_code || "",
      gst_no: data.gst_no,
      notes: data.notes || "",
      status: data.status || "",
    });
  };

  // Handle add operation
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = { ...addData };
      console.log("Submitting Data:", formData);

      const response = await axios.post(`${ApiUrl}/supplier`, formData);
      console.log("Added:", response.data);

      setShowModal(false); // Close modal before updating UI
      toast.success("Added Successfully");

      setAddData({
        branch_id: "",
        supplier_id: "",
        name: "",
        mobile_no: "",
        email: "",
        supplier_name: "",
        door_no: "",
        street_name: "",
        area: "",
        pin_code: "",
        gst_no: "",
        notes: "",
        status: "",
      });

      fetchData(); // Re-fetch updated data
    } catch (error) {
      console.error("Error updating data:", error.message);
      toast.error("Error adding supplier");
    }
  };

  // Set common data for preview and update
  const setCommonData = (data) => {
    const branchId =
      data.branch_id && typeof data.branch_id === "object"
        ? data.branch_id._id
        : data.branch_id;

    const commonData = {
      branch_id: branchId,
      supplier_id: data.supplier_id,
      name: data.name,
      mobile_no: data.mobile_no,
      email: data.email,
      supplier_name: data.supplier_name,
      door_no: data.door_no || "",
      street_name: data.street_name || "",
      area: data.area || "",
      pin_code: data.pin_code || "",
      gst_no: data.gst_no,
      notes: data.notes || "",
      status: data.status || "",
    };

    console.log("Setting data: ", commonData);
    setUpdateData(commonData);
  };

  // Handle preview operation
  const handlePreview = (data) => {
    setCommonData(data);
    setShowPreview(true);
  };

  // Handle update operation
  const handleUpdateData = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        branch_id: updateData.branch_id,
        supplier_id: updateData.supplier_id,
        name: updateData.name,
        mobile_no: updateData.mobile_no,
        email: updateData.email,
        supplier_name: updateData.supplier_name,
        door_no: updateData.door_no,
        street_name: updateData.street_name,
        area: updateData.area,
        pin_code: updateData.pin_code,
        gst_no: updateData.gst_no,
        notes: updateData.notes,
        status: updateData.status,
      };
      const response = await axios.put(
        `${ApiUrl}/supplier/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
      toast.error("Error updating supplier");
    }
  };

  // Handle delete confirmation
  const handleOpenModal = (_id, name) => {
    console.log(" the id", _id);
    console.log(" the name", name);
    setdeleteid(_id);
    setdeletename(name);
    setDeleteShowModals(true);
  };

  // Close delete modal
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  // Handle delete operation
  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${ApiUrl}/supplier/${getdeleteid}`);
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error.message);
      toast.error("Error deleting supplier");
    }
  };

  // Create pagination
  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Purchase / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Supplier</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 d-flex">
          <h6 className="mt-2 bold">Supplier</h6>
          <div className="">
            <button
              className="btn btn-dark btn-sm add mx-3"
              onClick={() => setShowModal(true)}
            >
              Add
            </button>
          </div>
        </div>
        <div className="col-lg-2">
          <div>
            <input
              type="file"
              id="file-upload"
              accept=".xls,.xlsx"
              style={{ display: "none" }}
            />
            <label htmlFor="file-upload" className="btn btn-secondary">
              File Upload
            </label>
          </div>
        </div>
      </div>

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    <button
                      className="btn custom-btn-update btn-sm"
                      onClick={() =>
                        handleUpdate(row.original._id, row.original)
                      }
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button
                      className="btn btn-sm mx-1 custom-btn-delete"
                      onClick={() =>
                        handleOpenModal(row.original._id, row.original.name)
                      }
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>

                    <button
                      className="btn custom-btn-info btn-sm"
                      onClick={() => handlePreview(row.original)}
                    >
                      <i className="fa-solid fa-eye"></i>
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      {/* Add Supplier Modal */}
      <Modal
        className="addmodals"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Supplier</Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={addData.branch_id}
                      onChange={(e) => {
                        setAddData({ ...addData, branch_id: e.target.value });
                      }}
                      required
                    >
                      <option value="">Branch</option>
                      {responseDataBranch.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.branch_id} - {item.branch_name}
                        </option>
                      ))}
                    </select>
                    <label className={addData.branch_id ? "filled" : ""}>
                      Branch
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={addData.supplier_id}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          supplier_id: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.supplier_id ? "filled" : ""}>
                      Supplier ID
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={addData.name}
                      onChange={(e) =>
                        setAddData({ ...addData, name: e.target.value })
                      }
                      required
                    />
                    <label className={addData.name ? "filled" : ""}>Name</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={addData.mobile_no}
                      onChange={(e) =>
                        setAddData({ ...addData, mobile_no: e.target.value })
                      }
                      required
                    />
                    <label className={addData.mobile_no ? "filled" : ""}>
                      Mobile
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="email"
                      value={addData.email}
                      onChange={(e) =>
                        setAddData({ ...addData, email: e.target.value })
                      }
                      required
                    />
                    <label className={addData.email ? "filled" : ""}>
                      Email
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={addData.supplier_name}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          supplier_name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.supplier_name ? "filled" : ""}>
                      Supplier Name
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={addData.door_no}
                      onChange={(e) =>
                        setAddData({ ...addData, door_no: e.target.value })
                      }
                      required
                    />
                    <label className={addData.door_no ? "filled" : ""}>
                      Door No
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={addData.street_name}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          street_name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.street_name ? "filled" : ""}>
                      Street Name
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={addData.area}
                      onChange={(e) =>
                        setAddData({ ...addData, area: e.target.value })
                      }
                      required
                    />
                    <label className={addData.area ? "filled" : ""}>Area</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={addData.pin_code}
                      onChange={(e) =>
                        setAddData({ ...addData, pin_code: e.target.value })
                      }
                      required
                    />
                    <label className={addData.pin_code ? "filled" : ""}>
                      Pin Code
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={addData.gst_no}
                      onChange={(e) =>
                        setAddData({ ...addData, gst_no: e.target.value })
                      }
                      required
                    />
                    <label className={addData.gst_no ? "filled" : ""}>
                      GST
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={addData.notes}
                      onChange={(e) =>
                        setAddData({ ...addData, notes: e.target.value })
                      }
                    />
                    <label className={addData.notes ? "filled" : ""}>
                      Notes
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button className="submitbut" type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Update Supplier Modal */}
      <Modal
        className="addmodals"
        show={updateModal}
        onHide={() => setUpdateModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Supplier</Modal.Title>
        </Modal.Header>

        <form onSubmit={handleUpdateData}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={updateData.branch_id}
                      onChange={(e) => {
                        setUpdateData({
                          ...updateData,
                          branch_id: e.target.value,
                        });
                      }}
                      required
                    >
                      <option value="">Branch</option>
                      {responseDataBranch.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.branch_id} - {item.branch_name}
                        </option>
                      ))}
                    </select>
                    <label className={updateData.branch_id ? "filled" : ""}>
                      Branch
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={updateData.supplier_id}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          supplier_id: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.supplier_id ? "filled" : ""}>
                      Supplier ID
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={updateData.name}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, name: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.name ? "filled" : ""}>
                      Name
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={updateData.mobile_no}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          mobile_no: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.mobile_no ? "filled" : ""}>
                      Mobile
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="email"
                      value={updateData.email}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, email: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.email ? "filled" : ""}>
                      Email
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={updateData.supplier_name}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          supplier_name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.supplier_name ? "filled" : ""}>
                      Supplier Name
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={updateData.door_no}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          door_no: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.door_no ? "filled" : ""}>
                      Door No
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={updateData.street_name}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          street_name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.street_name ? "filled" : ""}>
                      Street Name
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={updateData.area}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, area: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.area ? "filled" : ""}>
                      Area
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={updateData.pin_code}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          pin_code: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.pin_code ? "filled" : ""}>
                      Pin Code
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={updateData.gst_no}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, gst_no: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.gst_no ? "filled" : ""}>
                      GST
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={updateData.notes}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, notes: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.notes ? "filled" : ""}>
                      Notes
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setUpdateModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              type="submit"
              variant="primary"
              onClick={handleUpdateData}
            >
              Update
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* previous */}

      <Modal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview Expense list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.branch_id}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        branch_id: e.target.value,
                      });
                    }}
                    disabled
                  >
                    <option value="">Branch</option>
                    {responseDataBranch.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.branch_id} - {item.branch_name}
                      </option>
                    ))}
                  </select>
                  <label className={updateData.branch_id ? "filled" : ""}>
                    Branch
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={updateData.supplier_id}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        supplier_id: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label className={updateData.supplier_id ? "filled" : ""}>
                    Supplier ID
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={updateData.name}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, name: e.target.value })
                    }
                    disabled
                  />
                  <label className={updateData.name ? "filled" : ""}>
                    Name
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={updateData.mobile_no}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        mobile_no: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label className={updateData.mobile_no ? "filled" : ""}>
                    Mobile
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={updateData.email}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, email: e.target.value })
                    }
                    disabled
                  />
                  <label className={updateData.email ? "filled" : ""}>
                    Email
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={updateData.supplier_name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        supplier_name: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label className={updateData.supplier_name ? "filled" : ""}>
                    Supplier Name
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={updateData.door_no}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        door_no: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label className={updateData.door_no ? "filled" : ""}>
                    Door No
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={updateData.street_name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        street_name: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label className={updateData.street_name ? "filled" : ""}>
                    Street Name
                  </label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={updateData.area}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, area: e.target.value })
                    }
                    disabled
                  />
                  <label className={updateData.area ? "filled" : ""}>
                    Area
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={updateData.pin_code}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        pin_code: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label className={updateData.pin_code ? "filled" : ""}>
                    Pin Code
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={updateData.gst_no}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, gst_no: e.target.value })
                    }
                    disabled
                  />
                  <label className={updateData.gst_no ? "filled" : ""}>
                    GST
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={updateData.notes}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, notes: e.target.value })
                    }
                    disabled
                  />
                  <label className={updateData.notes ? "filled" : ""}>
                    Notes
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowPreview(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h6 className="mx-1 bold"> Name :</h6>{" "}
            <h6>{getdeletename} </h6>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Supplier;
