import React, { useEffect, useState } from "react";
import "../../assets/css/Quotation.css";
import axios from "axios";
import ApiUrl from "../Environment/ApiUrl";
import { useNavigate, useParams } from "react-router-dom";

function Quotation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState({});
  const [quotations, setQuotations] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [netTotal, setNetTotal] = useState(0);

  // Form state
  const defaultAddData = {
    expected_id: "",
    quotation_no: "",
    date: new Date().toISOString().split("T")[0],
    valid_tilldate: "",
    type: "",
    name: "",
    official_mobile: "",
    official_email: "",
    gstin: "",
    location: "",
    staff_id: "",
    staff_name: "",
    project_description: "",
    annexure_description_one: "",
    annexure_qty_one: "",
    basic_price: "",
    tax: "",
    total_price: "",
    subtotal: "",
    sgst: "",
    cgst: "",
    discount: "",
    nettotal: "",
    paytype: "",
  };

  const [addData, setAddData] = useState(defaultAddData);
  const [formData, setFormData] = useState({
    description: "",
    qty: "",
    basicprice: "",
    tax: "",
  });

  // Fetch data when component mounts or ID changes
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  // Calculate totals when responseDatas or discount changes
  useEffect(() => {
    const newSubTotal = responseDatas.reduce(
      (sum, item) => sum + (item.total || 0),
      0
    );
    setSubTotal(newSubTotal);

    const discountAmount = (newSubTotal * discount) / 100;
    setNetTotal(newSubTotal - discountAmount);
  }, [responseDatas, discount]);

  const fetchData = async (id) => {
    try {
      const response = await axios.get(`${ApiUrl}/expected/${id}`);
      if (response.data) {
        setResponseData(response.data);
        setResponseDatas(
          Array.isArray(response.data.items) ? response.data.items : []
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleAnnexuretwo = async () => {
    if (!responseData) {
      console.error("Invalid responseData: Unable to process the request");
      return;
    }

    try {
      const formattedSubTotal = subTotal.toFixed(2);
      const formattedNetTotal = netTotal.toFixed(2);
      const cleanedFormData = {
        expected_id: id,
        name: responseData.name_id?.name || "",
        official_mobile: responseData.name_id?.official_mobile || "",
        official_email: responseData.name_id?.official_email || "",
        gstin: responseData.name_id?.gstin || addData.gstin || "",
        location: responseData.name_id?.location || "",
        quotation_no: addData.quotation_no || "",
        date: addData.date || new Date().toISOString().split("T")[0],
        valid_tilldate: addData.valid_tilldate || "",
        staff_id: addData.staff_id || "",
        staff_name: addData.staff_name || "",
        project_description: addData.project_description || "",
        annexure_description_one: "",
        annexure_qty_one: "",
        basic_price: responseDatas[0]?.basicprice || "",
        tax: responseDatas[0]?.tax || "",
        total_price: formattedNetTotal,
        subtotal: formattedSubTotal,
        sgst: addData.sgst || "0",
        cgst: addData.cgst || "0",
        discount: discount.toString() || "0",
        nettotal: formattedNetTotal,
        paytype: addData.paytype || "",
        type: addData.type || "Project",
        items: responseDatas.map((item) => ({
          description: item.description || "",
          qty: parseFloat(item.qty) || 0,
          basicprice: parseFloat(item.basicprice) || 0,
          tax: parseFloat(item.tax) || 0,
          taxAmount: parseFloat(item.taxAmount) || 0,
          total: parseFloat(item.total).toFixed(2),
        })),
      };

      console.log("Sending data:", cleanedFormData);

      const response = await axios.post(
        `${ApiUrl}/quotation_list`,
        cleanedFormData
      );
      if (response.data) {
        console.log("Successfully posted quotation data", response.data);
        navigate(`/annexturetwo/${id}`);
      } else {
        alert("Server returned empty response. Please try again.");
      }
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };

  const handleDiscountChange = (e) => {
    setDiscount(parseFloat(e.target.value) || 0);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddDataChange = (e) => {
    setAddData({ ...addData, [e.target.name]: e.target.value });
  };

  const handleAdd = () => {
    if (!formData.description || !formData.qty || !formData.basicprice) {
      alert("Please fill all required fields.");
      return;
    }

    const qty = parseFloat(formData.qty) || 0;
    const basicprice = parseFloat(formData.basicprice) || 0;
    const taxRate = parseFloat(formData.tax) || 0;
    const taxAmount = (basicprice * qty * taxRate) / 100;
    const total = qty * basicprice + taxAmount;

    setResponseDatas([
      ...responseDatas,
      {
        description: formData.description,
        qty,
        basicprice,
        tax: taxRate,
        taxAmount,
        total,
      },
    ]);
    setFormData({ description: "", qty: "", basicprice: "", tax: "" });
  };

  const handleClear = () => {
    setFormData({ description: "", qty: "", basicprice: "", tax: "" });
    setResponseDatas([]);
    setSubTotal(0);
    setDiscount(0);
    setNetTotal(0);
    setAddData(defaultAddData);
  };

  const handleDelete = (index) => {
    setResponseDatas(responseDatas.filter((_, i) => i !== index));
  };

  return (
    <div className="mainsection">
      <div className="row">
        <div className="col-lg-5"></div>
        <div className="col-lg-3">
          <h5 className="head" style={{ textDecoration: "underline" }}>
            New Quotation
          </h5>
        </div>
        <div className="col-lg-3 mt-2">
          <select
            className="form-control"
            style={{ width: "150px" }}
            name="type"
            value={addData.type}
            onChange={handleAddDataChange}
          >
            <option value="">Select dropdown</option>
            <option value="Project">Project</option>
            <option value="Sales">Sales / Service</option>
          </select>
        </div>
        <div className="col-lg-1"></div>
      </div>

      {/* First row */}
      <div className="row first">
        <div className="col-lg-4">
          <label>Quotation No:</label>
          <input
            type="text"
            name="quotation_no"
            value={addData.quotation_no}
            onChange={handleAddDataChange}
            className="form-control"
          />
        </div>

        <div className="col-lg-4">
          <label>Date:</label>
          <input
            type="date"
            name="date"
            className="form-control"
            value={addData.date}
            onChange={handleAddDataChange}
          />
        </div>

        <div className="col-lg-4">
          <label>Valid Till Date:</label>
          <input
            type="date"
            name="valid_tilldate"
            value={addData.valid_tilldate}
            onChange={handleAddDataChange}
            className="form-control"
          />
        </div>
      </div>

      {/* Client and Staff Details */}
      <div className="row second">
        <div className="col-lg-6">
          <h1>Client Details (Quotation for)</h1>
          <div className="row">
            <div
              className="col-lg-5 col-md-5 col-sm-12"
              style={{ textAlign: "left" }}
            >
              <p>Client Business Name</p>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ textAlign: "right" }}
            >
              <input
                style={{ width: "80%" }}
                type="text"
                value={responseData.name_id?.name || ""}
                disabled
                className="form-control"
              />
            </div>

            <div
              className="col-lg-5 col-md-5 col-sm-12"
              style={{ textAlign: "left" }}
            >
              <p> Mobile / Contact</p>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ textAlign: "right" }}
            >
              <input
                style={{ width: "80%" }}
                type="text"
                value={responseData.name_id?.official_mobile || ""}
                disabled
                className="form-control"
              />
            </div>

            <div
              className="col-lg-5 col-md-5 col-sm-12"
              style={{ textAlign: "left" }}
            >
              <p>Official Email</p>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ textAlign: "right" }}
            >
              <input
                style={{ width: "80%" }}
                type="text"
                value={responseData.name_id?.official_email || ""}
                disabled
                className="form-control"
              />
            </div>

            <div
              className="col-lg-5 col-md-5 col-sm-12"
              style={{ textAlign: "left" }}
            >
              <p>GSTIN</p>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ textAlign: "right" }}
            >
              <input
                style={{ width: "80%" }}
                type="text"
                name="gstin"
                value={addData.gstin || responseData.name_id?.gstin || ""}
                onChange={handleAddDataChange}
                className="form-control"
              />
            </div>

            <div
              className="col-lg-5 col-md-5 col-sm-12"
              style={{ textAlign: "left" }}
            >
              <p>Address</p>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ textAlign: "right" }}
            >
              <textarea
                style={{ width: "80%" }}
                value={responseData.name_id?.location || ""}
                disabled
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <h1> Quotation (Created By)</h1>
          <div className="row">
            <div
              className="col-lg-5 col-md-5 col-sm-12"
              style={{ textAlign: "left" }}
            >
              <p>Staff ID</p>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ textAlign: "right" }}
            >
              <input
                style={{ width: "80%" }}
                type="text"
                name="staff_id"
                value={
                  addData.staff_id || responseData.name_id?.assign_staff || ""
                }
                onChange={handleAddDataChange}
                className="form-control"
              />
            </div>

            <div
              className="col-lg-5 col-md-5 col-sm-12"
              style={{ textAlign: "left" }}
            >
              <p>Staff Name</p>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ textAlign: "right" }}
            >
              <input
                style={{ width: "80%" }}
                type="text"
                name="staff_name"
                value={addData.staff_name || ""}
                onChange={handleAddDataChange}
                className="form-control"
              />
            </div>
            <div className="" style={{ textAlign: "left" }}>
              <p>Project Description : </p>
            </div>
            <div className="" style={{ textAlign: "right" }}>
              <textarea
                style={{ width: "80%" }}
                name="project_description"
                value={addData.project_description || ""}
                onChange={handleAddDataChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Annexure section */}
      <div className="text-center mt-4 mb-3">
        <h1 className="fs-5">Annexure 1: Quotation</h1>
      </div>

      <div className="container mb-3">
        <div className="row">
          <div className="col-lg-4">
            <label>Description</label>
            <input
              className="form-control"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-2">
            <label>Qty</label>
            <input
              className="form-control"
              name="qty"
              type="number"
              value={formData.qty}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-2">
            <label>Basic Price</label>
            <input
              className="form-control"
              name="basicprice"
              type="number"
              value={formData.basicprice}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-2 position-relative">
            <label>Tax</label>
            <div className="position-relative">
              <input
                className="form-control pe-4"
                name="tax"
                type="number"
                value={formData.tax}
                onChange={handleChange}
                style={{ paddingRight: "25px" }}
              />
              <span
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                  color: "#777",
                }}
              >
                %
              </span>
            </div>
          </div>

          <div className="col-lg-2">
            <button className="btn btn-success btn-sm mt-4" onClick={handleAdd}>
              Add
            </button>
          </div>
        </div>
      </div>

      {/* Table of items */}
      <div className="card p-3">
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th>S.NO</th>
                <th>Description</th>
                <th>Qty</th>
                <th>Basic Price</th>
                <th>Tax</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {responseDatas.length > 0 ? (
                responseDatas.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.description}</td>
                    <td>{item.qty}</td>
                    <td>{item.basicprice}</td>
                    <td>{item.tax}%</td>
                    <td>
                      {typeof item.total === "number"
                        ? item.total.toFixed(2)
                        : "0.00"}
                    </td>
                    <td>
                      <i
                        className="fa-solid fa-circle-xmark mx-2 fs-4"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleDelete(index)}
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No items added
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Totals section */}
      <div className="row second">
        <div className="col-lg-6"></div>
        <div className="col-lg-6">
          <div className="row">
            <div
              className="col-lg-5 col-md-5 col-sm-12"
              style={{ textAlign: "left" }}
            >
              <p>Sub Total</p>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ textAlign: "right" }}
            >
              <input
                style={{ width: "80%" }}
                type="text"
                value={subTotal.toFixed(2)}
                readOnly
                className="form-control"
              />
            </div>

            <div
              className="col-lg-5 col-md-5 col-sm-12"
              style={{ textAlign: "left" }}
            >
              <p>SGST</p>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ textAlign: "right" }}
            >
              <input
                style={{ width: "80%" }}
                name="sgst"
                value={addData.sgst}
                onChange={handleAddDataChange}
                type="number"
                className="form-control"
              />
            </div>

            <div
              className="col-lg-5 col-md-5 col-sm-12"
              style={{ textAlign: "left" }}
            >
              <p>CGST</p>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ textAlign: "right" }}
            >
              <input
                style={{ width: "80%" }}
                name="cgst"
                value={addData.cgst}
                onChange={handleAddDataChange}
                type="number"
                className="form-control"
              />
            </div>

            <div
              className="col-lg-5 col-md-5 col-sm-12"
              style={{ textAlign: "left" }}
            >
              <p>Discount (%)</p>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ textAlign: "right" }}
            >
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "80%",
                }}
              >
                <input
                  style={{ width: "100%" }}
                  type="number"
                  value={discount}
                  onChange={handleDiscountChange}
                  className="form-control"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                    color: "#777",
                  }}
                >
                  %
                </span>
              </div>
            </div>

            <div
              className="col-lg-5 col-md-5 col-sm-12"
              style={{ textAlign: "left" }}
            >
              <p>Net Total</p>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ textAlign: "right" }}
            >
              <input
                style={{ width: "80%" }}
                type="text"
                value={netTotal.toFixed(2)}
                readOnly
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Buttons section */}
      <div className="row">
        <div className="col-lg-6"></div>
        <div className="col-lg-6">
          <div
            style={{ marginLeft: "75px" }}
            className="d-flex justify-content-evenly mt-3 buttonstyle"
          >
            <button
              type="button"
              className="btn btn-danger invoice-button Add"
              onClick={handleClear}
            >
              Clear
            </button>

            <button
              type="button"
              className="btn btn-success invoice-button Add"
              onClick={() => navigate("/upcomings")}
            >
              Back
            </button>

            <button
              type="button"
              className="btn btn-secondary Add"
              onClick={() => handleAnnexuretwo(id)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quotation;
