



import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

const convertTo12HourFormat = (time) => {
  let [hours, minutes] = time.split(':');
  let period = 'AM';

  hours = parseInt(hours, 10);

  if (hours >= 12) {
    period = 'PM';
    if (hours > 12) {
      hours -= 12;
    }
  } else if (hours === 0) {
    hours = 12;
  }

  return `${hours}:${minutes} ${period}`;
};


// import { Navigate, useNavigate } from "react-router-dom";

function Attendanceoverall() {
  const [responseData, setResponseData] = useState([]);
  const [attendance_overall, setattendance_overall] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateShowModal, setUpdateShowModal] = useState(false);
  const [responseDatas, setResponseDatas] = useState([]);
  const [addDatass, setAddDatass] = useState({ empid: "", name: "" });
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [branch, setbranch] = useState([]);
  const [role_id, setroleid] = useState("");
  const [loading, setLoading] = useState(false);
  const [previllages, setprevillages] = useState({});

  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      empid: item.empid, // Assuming you want to include the employee ID
      name: item.name, // Assuming you want to include the name
      date: item.date, // The date the attendance is logged
      login: item.login, // Login time
      logout: item.logout, // Logout time
      workhour: item.workhour,
      overtime: item.overtime,
      branch_id: item.branch_id ? {
        _id: item.branch_id._id,
        branch_name: item.branch_id.branch_name,
      } : { _id: null, branch_name: '' }, // Fallback in case branch_id is null/undefined
    }));
  }, [attendance_overall]);


  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Branch",
        accessor: "branch_id.branch_name",
        Cell: ({ row }) => {
          const branch = row.original.branch_id;
          return branch ? branch.branch_name : ''; // Fallback if branch_id is null/undefined
        },
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      }
      ,
      {
        Header: "Staff ID",
        accessor: "empid",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      // {
      //   Header: "Date",
      //   accessor: "date",
      //   Filter: DefaultColumnFilter,
      //   filter: "multiSelectFilter",
      // },
      {
        Header: "Date",
        accessor: (row) => {
          const date = new Date(row.date);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        },
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Log In",
        accessor: "login",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        Cell: ({ value }) => convertTo12HourFormat(value), // convert time here
      },
      {
        Header: "Log Out",
        accessor: "logout",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        Cell: ({ value }) => convertTo12HourFormat(value), // convert time here
      },
      // {
      //   Header: "Log In",
      //   accessor: "login",
      //   Filter: DefaultColumnFilter,
      //   filter: "multiSelectFilter",
      // },
      // {
      //   Header: "Log Out",
      //   accessor: "logout",
      //   Filter: DefaultColumnFilter,
      //   filter: "multiSelectFilter",
      // },
      {
        Header: "Working Hour",
        accessor: "workhour",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "OT",
        accessor: "overtime", // Correct accessor for workhour
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const navigate = useNavigate();

  const handleCourseInfo = (empid) => {
    navigate(`/Calender/${empid}`);
  };

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [addData, setAddData] = useState({
    branch_id: "",
    empid: "",
    name: "",
    date: formatDate(new Date()),
    login: "",
    lunch_out: "",
    lunch_in: "",
    logout: "",
    totalhour: "",
  });

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    empid: "",
    name: "",
    date: formatDate(new Date()),
    login: "",
    lunch_out: "",
    lunch_in: "",
    logout: "",
    totalhour: "",
  });

  const handleDateChange = (e) => {
    setAddData((prevData) => ({
      ...prevData,
      date: e.target.value,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(ApiUrl + "/attendance_overall", {
 
      });

      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500)
  };

  // const fetchData2 = async () => {
  //   try {
  //     const responseovelldata = await axios.get(apiUrl + "/attendance_overall", {
  //       params: { data: localStorage.getItem("branch_id") },
  //     });
  //     console.log("Response from server with :", responseovelldata.data);
  //     setattendance_overall(responseovelldata.data.reverse());
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };

  const fetchData2 = async () => {
    try {
      // Retrieve branch details and employee name from localStorage
      const branchId = localStorage.getItem("branch_id");
      const branchName = localStorage.getItem("branch_name");
      const empName = localStorage.getItem("username");

      // Check if the condition to fetch all data is met
      if (
        branchId === null ||
        branchName === "Branches" ||
        empName === undefined
      ) {
        // Fetch all attendance data if condition is true
        const responseovelldata = await axios.get(
            ApiUrl + "/attendance_overall"
        );
        console.log(
          "Response from server with all attendance data:",
          responseovelldata.data
        );

        setattendance_overall(responseovelldata.data.reverse());
      } else {
        // Fetch data based on branchId and empid (from localStorage)
        const responseovelldata = await axios.get(
            ApiUrl + "/attendance_overall",
       
        );
        console.log(
          "Response from server with filtered attendance data:",
          responseovelldata.data
        );



        
        // Get the empid from localStorage
        const localEmpid = localStorage.getItem("username");

        // Filter data based on the empid from localStorage
        const filteredData = responseovelldata.data.filter(
          (item) => item.empid === localEmpid
        );

        console.log("the filteredData in the else part is : ", filteredData);

        setattendance_overall(filteredData.reverse());
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData2();
    fetchDatas();
    fetchDataed();
    // gettoken();
    // getprevillages();
  }, []);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(ApiUrl + "/staff");
      console.log("Response from:", response.data);
      setResponseDatas(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const formatDatefitching = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);

    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
    const year = date.getFullYear();

    return `${day}-${month}-${year}`; // Format as "day-month-year"
  };

  // Helper function to format ISO date to YYYY-MM-DD
  const formatDates = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  const fetchDataed = async () => {
    try {
      const response = await axios.get(ApiUrl + "/Branch");
      console.log("Response from server:", response.data);
      setbranch(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // const gettoken = async () => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await axios.post(ApiUrl + "/get_detail", {
  //       token: token,
  //     });
  //     console.log("token detail from server:", response.data);

  //     setroleid(response.data.detail.rolename);

  //     localStorage.setItem("role_id", response.data.detail.rolename);

  //     const id = response.data.detail.rolename;
  //     if (id) {
  //       // try {
  //       //   const response = await axios.get(apiUrl + `/role_2/${id}`);
  //       //   const modules = response.data.modules;
  //       //   const filtered_access = modules.filter(
  //       //     (item) => item.name == "Branch"
  //       //   );
  //       //   setprevillages(filtered_access[0].actions);
  //       // } catch (error) {
  //       //   console.error("Error fetching data:", error.message);
  //       // }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };

  // const getprevillages = () => {
  //   const username = JSON.parse(localStorage.getItem("user_previllages"));
  //   console.log("tyhe username get by local stroage : ", username.modules);

  //   const total_previlages = username.modules;

  //   const new_enquiry = total_previlages.find(
  //     (item) => item.name == "Attendance"
  //   );

  //   console.log("the new_enquiry is : ", new_enquiry.actions);

  //   setprevillages(new_enquiry.actions);
  // };

  // const railwayToNormalTime = (railwayTime) => {
  //   const [hour, minute] = railwayTime.split(":");
  //   const parsedHour = parseInt(hour);
  //   const parsedMinute = parseInt(minute);
  //   if (parsedHour < 12) {
  //     return `${parsedHour}:${minute} `;
  //   } else if (parsedHour === 12) {
  //     return `${parsedHour}:${minute} `;
  //   } else {
  //     return `${parsedHour - 12}:${minute} `;
  //   }
  // };

  const railwayToNormalTime = (railwayTime) => {
    const [hour, minute] = railwayTime.split(":");
    let parsedHour = parseInt(hour);
    const parsedMinute = parseInt(minute);
    let period = "AM";

    if (parsedHour === 0) {
      parsedHour = 12; // midnight case (00:xx)
    } else if (parsedHour === 12) {
      period = "PM"; // noon case (12:xx)
    } else if (parsedHour > 12) {
      parsedHour -= 12;
      period = "PM"; // afternoon case
    }

    return `${parsedHour}:${parsedMinute < 10 ? "0" : ""
      }${parsedMinute} ${period}`;
  };

  const calculateWorkHours = (login, logout) => {
    const loginTime = new Date(`1970-01-01T${login}:00`);
    const logoutTime = new Date(`1970-01-01T${logout}:00`);

    const totalWorkTime = logoutTime - loginTime;

    // Check for valid time difference
    if (totalWorkTime < 0) {
      console.error("Logout time must be after login time");
      return "00:00"; // Return default value if times are invalid
    }

    // const hours = Math.floor(totalWorkTime / (1000 * 60 * 60));

    let hours = (Math.floor(totalWorkTime / (1000 * 60 * 60))) - 1;  // the -1 is used to remve the brack time
    let minutes = Math.floor(
      (totalWorkTime % (1000 * 60 * 60)) / (1000 * 60)
    );

    let overtime_hours = 0;
    let overtime_minutes = 0;

    if (hours > 8) {
      overtime_hours = hours - 8;
      hours = 8;
      overtime_minutes = minutes;
      minutes = 0;
    }
    else if (hours == 8) {
      overtime_minutes = minutes;
      minutes = 0;
    }

    console.log("the return value in calculateWorkHours is : ", loginTime, logoutTime);



    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}.${overtime_hours}:${overtime_minutes < 10 ? "0" : ""}${overtime_minutes}`;

  };




  const handleAddSubmit = async (e) => {
    e.preventDefault();

    // if (!validateFields()) {
    //   return;
    // }
    const workhour = calculateWorkHours(addData.login, addData.logout).split(".");

    console.log("the workhour and overtime  in the handleAddSubmit is : ", workhour[0], workhour[1]);

    try {
      setLoading(true);
      const formData = {
        branch_id: addData.branch_id,
        empid: addData.empid,
        name: addData.name,
        date: addData.date,
        login: addData.login,
        lunch_out: addData.lunch_out,
        lunch_in: addData.lunch_in,
        logout: addData.logout,
        workhour: workhour[0],
        overtime: workhour[1]
      };

      const response = await axios.post(ApiUrl + "/attendance", formData);
      console.log("Added:", response);
      setShowModal(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      fetchData();
      toast.success("Added successfully!");
      setAddData({
        branch_id: "",
        empid: "",
        name: "",
        date: "",
        login: "",
        lunch_out: "",
        lunch_in: "",
        logout: "",
        workhour: "",
      });
    } catch (error) {
      console.error("Error adding data:", error.message);
    }
  };

  const [updateId, setUpdateId] = useState("");

  const handleUpdate = async (_id, data) => {
    // if (previllages.edit || previllages.all) {
      setUpdateId(_id);
      console.log("The received _id and data are : ", _id, data);
      // const response = await axios.get(apiUrl + "/attendance", {
      //   params: { data: localStorage.getItem("branch_id") },
      // });
      const response = await axios.get(ApiUrl + `/attendance_overall/${_id}`);
      console.log("Response from server:", response.data);
      setUpdateShowModal(true);
      setUpdateData({
        branch_id: data.branch_id,
        empid: data.empid,
        name: data.name,
        date: data.date,
        login: data.login,
        logout: data.logout,
        workhour: data.workhour,
        overtime: response.data[0].overtime
      });
    // } else {
    //   toast.error("You don't have the permission to update");
    // }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    const workhour = calculateWorkHours(updateData.login, updateData.logout).split(".");


    try {
      setLoading(true);
      const formData = {
        branch_id: updateData.branch_id,
        empid: updateData.empid,
        name: updateData.name,
        date: updateData.date,
        login: updateData.login,
        logout: updateData.logout,
        workhour: workhour[0],
        overtime: workhour[1]
      };
      console.log("The update submit data111", formData);
      const response = await axios.put(
        ApiUrl + `/attendance/${updateId}`,
        formData
      );
      console.log("Response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        ApiUrl + `/attendance/${getdeleteid}`
      );

      console.log("Deleted data", response);

      toast.error("Deleted successfully!");
      setDeleteShowModals(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      fetchData();
      window.location.reload();
    } catch (error) {
      console.error("Error deleting data:", error.message);
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, name) => {
    // if (previllages.all || previllages.delete) {
      console.log(" the id", _id);
      console.log(" the name", name);
      setdeleteid(_id);
      setdeletename(name);
      setDeleteShowModals(true);
    // } else {
    //   toast.error("You don't have the permission to delete");
    // }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const filtered = rows.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  // const createPagination = () => {
  //   let liTag = [];

  //   // Previous Page Button
  //   liTag.push(
  //     <li
  //       className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
  //       key="prev"
  //     >
  //       <button
  //         className="page-link"
  //         onClick={() => canPreviousPage && gotoPage(state.pageIndex - 1)}
  //         disabled={!canPreviousPage}
  //       >
  //         <FaAngleLeft /> Prev
  //       </button>
  //     </li>
  //   );

  //   // Page Numbers
  //   for (let plength = 1; plength <= pageCount; plength++) {
  //     liTag.push(
  //       <li
  //         key={plength}
  //         className={`page-item ${plength === state.pageIndex + 1 ? "active" : ""
  //           }`}
  //       >
  //         <button
  //           className="page-link"
  //           onClick={() => gotoPage(plength - 1)} // 0-indexed
  //         >
  //           {plength}
  //         </button>
  //       </li>
  //     );
  //   }

  //   // Next Page Button
  //   liTag.push(
  //     <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
  //       <button
  //         className="page-link"
  //         onClick={() => canNextPage && gotoPage(state.pageIndex + 1)}
  //         disabled={!canNextPage}
  //       >
  //         Next <FaAngleRight />
  //       </button>
  //     </li>
  //   );

  //   return liTag;
  // };


  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`} key="prev">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li className={`page-item ${currentPage === 0 ? "active" : ""}`} key={0}>
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li className={`page-item ${currentPage === 1 ? "active" : ""}`} key={1}>
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 3, currentPage + 1); i++) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li className={`page-item ${currentPage === totalPages - 2 ? "active" : ""}`} key={totalPages - 2}>
          <button className="page-link" onClick={() => gotoPage(totalPages - 2)}>
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li className={`page-item ${currentPage === totalPages - 1 ? "active" : ""}`} key={totalPages - 1}>
          <button className="page-link" onClick={() => gotoPage(totalPages - 1)}>
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  // ==================================================================================================

  const handleEmpolye = (e) => {
    const selectedEmpData = filteredItems[e.target.selectedIndex - 1];
    setAddData({
      ...addData,
      empid: e.target.value,
      name: selectedEmpData.name,
    });
  };

  useEffect(() => {
    console.log(addData);
  }, [addData]);

  // const formatDatefitching = (isoDate) => {
  //   if (!isoDate) return "";
  //   const date = new Date(isoDate);
  //   return date.toISOString().split("T")[0];
  // };

  const calculateTotalHours = () => {
    const { login, logout } = updateData;

    if (login && logout) {
      const loginTime = new Date(`1970-01-01T${login}`);
      const logoutTime = new Date(`1970-01-01T${logout}`);
      const totalHours = (logoutTime - loginTime) / (1000 * 60 * 60); // Convert ms to hours

      setUpdateData((prevData) => ({
        ...prevData,
        totalhour: totalHours >= 0 ? totalHours.toFixed(2) : "",
      }));
    } else {
      setUpdateData((prevData) => ({
        ...prevData,
        totalhour: "",
      }));
    }
  };

  // Effect to recalculate total hours when login or logout changes
  useEffect(() => {
    calculateTotalHours();
  }, [updateData.login, updateData.logout]);

  function overallattendance() {
    setResponseData(attendance_overall);
    setattendance_overall(responseData);
    // setResponseData(responseData)
    // setattendance_overall(attendance_overall)
  }
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Correct the axios call
        const response = await axios.post(
            ApiUrl + "/staff_attendance_upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Important for file upload
            },
          }
        );

        // Handle the response (assuming the backend sends JSON)
        if (response.status === 200) {
          console.log("File uploaded successfully:", response.data);
        } else {
          console.error("File upload failed:", response.data);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    let isValid = true;

    if (!addData.branch_id) {
      tempErrors.branch_id = "Branch is required.";
      isValid = false;
    }

    if (!addData.empid) {
      tempErrors.empid = "StaffId is required.";
      isValid = false;
    }

    if (!addData.name) {
      tempErrors.name = "Name is required.";
      isValid = false;
    }

    if (!addData.login) {
      tempErrors.login = "Login  is required.";
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };

  const [filteredItems, setfilterdata] = useState([]);

  const handleBranch = (e) => {
    const selectedBranchId = e.target.value; // Selected branch ID
    setAddData({ ...addData, branch_id: selectedBranchId }); // Update state for branch_id
    console.log("Selected Branch ID:", selectedBranchId);

    // Filter staff based on the selected branch
    const filteredData = responseDatas.filter((staff) =>
      staff.branch_id.some((branch) => branch._id === selectedBranchId)
    );
    setfilterdata(filteredData); // Update state with filtered staff
    console.log("Filtered Staff Data:", filteredData);
  };

  // const calculateWorkHours_infrontend = (login, logout) => {
  //   if (!login || !logout) return "-";

  //   // console.log("the login and logout in calculateWorkHours_infrontend is : ",login, logout);

  //   const [loginHours, loginMinutes] = login.split(":").map(Number);
  //   const [logoutHours, logoutMinutes] = logout.split(":").map(Number);

  //   const loginTotalMinutes = loginHours * 60 + loginMinutes;
  //   const logoutTotalMinutes = logoutHours * 60 + logoutMinutes;

  //   let workMinutes = logoutTotalMinutes - loginTotalMinutes;

  //   // Handle midnight crossing
  //   if (workMinutes < 0) {
  //     workMinutes += 24 * 60;
  //   }

  //   const workHours = Math.floor(workMinutes / 60);
  //   const remainingMinutes = workMinutes % 60;

  //   return (workHours + remainingMinutes / 60).toFixed(2); // Format as decimal hours
  // };

  const calculateWorkHours_infrontend = (login, logout) => {
    if (!login || !logout) return "-";

    // Helper function to convert 12-hour time to 24-hour time
    const convertTo24Hour = (time) => {
      const [timePart, modifier] = time.split(" ");
      let [hours, minutes] = timePart.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12; // Convert PM hours to 24-hour format
      }
      if (modifier === "AM" && hours === 12) {
        hours = 0; // Convert midnight (12 AM) to 0 hours
      }

      return { hours, minutes };
    };

    const { hours: loginHours, minutes: loginMinutes } = convertTo24Hour(login);
    const { hours: logoutHours, minutes: logoutMinutes } =
      convertTo24Hour(logout);

    const loginTotalMinutes = loginHours * 60 + loginMinutes;
    const logoutTotalMinutes = logoutHours * 60 + logoutMinutes;

    let workMinutes = logoutTotalMinutes - loginTotalMinutes;

    // Handle midnight crossing
    if (workMinutes < 0) {
      workMinutes += 24 * 60;
    }

    const workHours = Math.floor(workMinutes / 60);
    const remainingMinutes = workMinutes % 60;

    return (workHours + remainingMinutes / 60).toFixed(2); // Format as decimal hours
  };

  const [rolename, setrolename] = useState([]);
  useEffect(() => {
    const branchName = localStorage.getItem("branch_name");
    setrolename(branchName);
  }, []);



  const handleClick = (_id) => {
    navigate(`/Attendance_fileupload`);
  };


  return (
    <>
      {loading === true ? (
        <div className="loading-overlay">
          <div className="loading-message">
            {/* <img
              className="loadinggif"
              src={require("../../assets/img/output-onlinegiftools.gif")}
            />{" "} */}
          </div>
        </div>
      ) : (
        <div className="d-none"></div>
      )}

      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="/attendance" className="text-secondary dashBoard_text">
                <b>Attendance /</b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Attendance Overall</b>
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-9">
            <div className="d-flex ">
              <h4 className="mt-2">Attendance Overall</h4>
              <div className="mx-3">
                {/* {previllages.add && ( */}
                  <button
                    className="btn btn-dark add btn-sm mx-3"
                    onClick={() => setShowModal(true)}
                  >
                    Add
                  </button>
                {/* )} */}
              </div>
            </div>
          </div>

          <div className="col-lg-1">
            <button
              className="btn  btn-danger"
              onClick={() => navigate("/attendance")}
            >
              Overall
            </button>
          </div>
          {rolename === "Branches" && (
            <div className="col-lg-2">
              <div>
                <button className="btn btn-secondary" onClick={handleClick}>
                  File Upload
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="mb-1 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  value={state.pageSize}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === 'All') {
                      setPageSize(data.length);  // 'data' should be your dataset containing all the entries
                    } else {
                      setPageSize(Number(value));
                    }
                  }}
                >
                  <option value="All">All</option> {/* Add 'All' option */}
                  {[10, 20, 100, 200, 500, 1000].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      value={state.globalFilter || ""} // Use the globalFilter value
                      onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <TableContainer>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="d-flex">
                        <div className="mx-2">{column.render("Header")}</div>
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                        <div className="mx-1">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </div>
                      </div>
                    </Th>
                  ))}
                  <Th className="text-center">Action</Th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    ))}
                    <Td className="text-center">
                      {/* {previllages.edit && ( */}
                        <button
                          className="btn updelete mx-2"
                          onClick={() =>
                            handleUpdate(row.original._id, row.original)
                          }
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                      {/* )} */}

                      {/* {previllages.delete && ( */}
                        <button
                          className="btn delete"
                          onClick={() =>
                            handleOpenModal(
                              row.original._id,
                              row.original.empid
                            )
                          }
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      {/* )} */}


                      {/* {previllages.view && ( */}
                        <button
                          className="btn updelete mx-2"
                          onClick={() => handleCourseInfo(row.original.empid)}
                        >
                          <i className="fa-solid fa-circle-info"></i>
                        </button>
                      {/* )} */}



                    </Td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>

        {/* Add Modal */}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          className="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Attendance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAddSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="floating-label">
                      <select
                        className="form-select"
                        value={addData.branch_id}
                        onChange={handleBranch}
                      // onChange={(e) =>
                      //   setAddData({ ...addData, branch_id: e.target.value })
                      // }
                      >
                        <option value="">Select Branch</option>
                        {branch.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.branch_id} - {item.branch_name}
                          </option>
                        ))}
                      </select>
                      <label className={addData.branch_id ? "filled" : ""}>
                        Select Branch
                      </label>
                      {errors.branch_id && (
                        <div className="error-text">{errors.branch_id}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <label className="form-label"></label>
                      <select
                        className="form-select"
                        value={addData.empid}
                        onChange={(e) => handleEmpolye(e)}
                      >
                        <option value="">Select Staff </option>
                        {filteredItems.map((item, index) => (
                          <option key={index} value={item.empid}>
                            {item.empid} - {item.name}
                          </option>
                        ))}
                      </select>
                      {errors.empid && (
                        <div className="error-text">{errors.empid}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        value={addData.name}
                        onChange={(e) =>
                          setAddData({ ...addData, name: e.target.value })
                        }
                      />
                      <label className={addData.name ? "filled" : ""}>
                        Name
                      </label>
                      {errors.name && (
                        <div className="error-text">{errors.name}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="date"
                        value={addData.date}
                        onChange={(e) =>
                          setAddData({ ...addData, date: e.target.value })
                        }
                        required
                      />
                      <label className={addData.date ? "filled" : ""}>
                        Date
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        type="time"
                        className="form-control"
                        value={addData.login}
                        onChange={(e) =>
                          setAddData({ ...addData, login: e.target.value })
                        }
                      />
                      <label className={addData.login ? "filled" : ""}>
                        Log In
                      </label>
                      {errors.login && (
                        <div className="error-text">{errors.login}</div>
                      )}
                    </div>

                    {/* Logout Time */}

                    <div className="floating-label">
                      <input
                        type="time"
                        className="form-control"
                        value={addData.logout}
                        onChange={(e) =>
                          setAddData({ ...addData, logout: e.target.value })
                        }

                        required
                      />
                      <label className={addData.logout ? "filled" : ""}>
                        Logout
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modalbutton">
                <Button
                  variant="secondary"
                  className="mt-3 cancelbut"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
                <button
                  type="submit"
                  className="btn btn-primary mt-3 mx-2 submitbut"
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* Update Modal */}
        <Modal
          className="custom-modal"
          show={isUpdateShowModal}
          onHide={() => setUpdateShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Attendance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleUpdateSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="floating-label">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={updateData.empid}
                      readOnly
                    />
                    <label className={updateData.empid ? "filled" : ""}>
                      Staff ID
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.branch_id.branch_name}
                      onChange={(e) =>
                        setAddData({ ...updateData, branch_id: e.target.value })
                      }
                      required
                    />
                    <label
                      className={
                        updateData.branch_id.branch_name ? "filled" : ""
                      }
                    >
                      Branch
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      type="type"
                      className="form-control"
                      placeholder=""
                      value={updateData.name}
                    />
                    <label className={updateData.name ? "filled" : ""}>
                      Name
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      type="date"
                      className="form-control"
                      placeholder=""
                      value={formatDates(updateData.date)}
                      onChange={handleDateChange}
                    />
                    <label className={updateData.date ? "filled" : ""}>
                      Date
                    </label>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="floating-label">
                    <input
                      type="time"
                      className="form-control"
                      placeholder=""
                      value={updateData.login}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, login: e.target.value })
                      }
                    />
                    <label className={updateData.login ? "filled" : ""}>
                      Log In
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      type="time"
                      className="form-control"
                      placeholder=""
                      value={updateData.logout}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, logout: e.target.value })
                      }
                    />
                    <label className={updateData.logout ? "filled" : ""}>
                      Log Out
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      value={updateData.workhour}
                      readOnly
                    />
                    <label className={updateData.workhour ? "filled" : ""}>
                      Total Hour
                    </label>
                  </div>



                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      value={updateData.overtime}
                      readOnly
                    />
                    <label className={updateData.overtime ? "filled" : ""}>
                      Over Time
                    </label>
                  </div>
                </div>
              </div>

              <div className="modalbutton">
                <Button
                  variant="secondary"
                  className="mt-3 cancelbut mx-2"
                  onClick={() => setUpdateShowModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary mt-3  submitbut"
                >
                  Update
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          className="modal"
          show={deleteShowModals}
          onHide={handleCloseModal}
        >
          <Modal.Header>
            <p>Are you sure you want to delete?</p>
          </Modal.Header>
          <Modal.Body>
            <label className="form-label d-flex">
              <h5 className="mx-1">Staff Id :</h5> <h5>{getdeletename} </h5>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* _------------------------------pagination button -------------------------------- */}
        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-7 col-sm-12 mt-1">
              <span className="text-dark">
                Page {state.pageIndex + 1} of {pageCount}
              </span>
            </div>
            <div className="col-lg-5">
              <div className="mt-3 pb-5">
                <nav>
                  <ul className="pagination justify-content-center">
                    {createPagination()} {/* Pagination buttons */}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* ---------------------------------------end ---------------------------------- */}
      </div>
    </>
  );
}

export default Attendanceoverall;









