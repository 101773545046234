
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import Select from "react-select";
import styled from "styled-components";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";



const TableContainer = styled.div`
padding: 20px;
overflow-x: auto;
`;

const Table = styled.table`
width: 100%;
border-collapse: collapse;
border: 1px solid #ddd;

// Apply striped rows styling
tr:nth-child(odd) {
  background-color: #f2f2f2; /* Light gray for even rows */
}

tr:nth-child(even) {
  background-color: #ffffff; /* White for odd rows */
}
`;

const Th = styled.th`
padding: 10px;
background-color: #fff;
text-align: left;
cursor: pointer;
position: relative;
`;

const Td = styled.td`
padding: 10px;
// border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
    return (
        <div style={{ marginBottom: "10px" }}>
            <input
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search all columns..."
                style={{
                    padding: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    width: "100%",
                }}
            />
        </div>
    );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
}) => {
    const [isActive, setIsActive] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const options = useMemo(() => {
        const uniqueValues = Array.from(
            new Set(preFilteredRows.map((row) => row.values[id]))
        ).map((value) => ({ label: value, value }));
        return [{ label: "All", value: "" }, ...uniqueValues];
    }, [id, preFilteredRows]);

    // Handle icon click
    const handleIconClick = () => {
        setIsActive(!isActive);
    };

    const handleMultiSelectChange = (selectedOptions) => {
        const values = selectedOptions
            ? selectedOptions.map((option) => option.value)
            : [];
        setFilter(values.length > 0 ? values : undefined);
    };

    return (
        <div style={{ alignItems: "center" }}>
            <div>
                <i
                    className="fa-solid fa-ellipsis-vertical"
                    onClick={handleIconClick}
                    style={{
                        cursor: "pointer",
                        fontSize: "20px",
                        color: "#AC0000",
                    }}
                ></i>
            </div>
            <div>
                {isActive &&
                    createPortal(
                        <div
                            style={{
                                position: "fixed",
                                zIndex: 9999,
                                left: "50%",
                                top: "50%",
                                width: "30%",
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            <div
                                style={{
                                    position: "relative",
                                    background: "white",
                                    padding: "20px",
                                    borderRadius: "8px",
                                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                                    backgroundColor: "#A70000",
                                }}
                            >
                                <Select
                                    options={options}
                                    isMulti
                                    value={options.filter((option) =>
                                        filterValue?.includes(option.value)
                                    )}
                                    onChange={handleMultiSelectChange}
                                    isClearable
                                    isSearchable
                                    placeholder={`Filter ${id}`}
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                                <button
                                    style={{
                                        marginTop: "10px",
                                        marginLeft: "150px",
                                        padding: "5px 10px",
                                        background: "#007bff",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setIsActive(false)} // Close the popup
                                >
                                    Close
                                </button>
                            </div>
                        </div>,
                        document.body
                    )}
            </div>
        </div>
    );
};







//  attention  this is backend file name called expense_details 

function Office_Expense() {
    const [responseData, setResponseData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isUpdateshowModal, setUpdateShowModal] = useState(false);
    const [generateempidData, setgenerateempidData] = useState([]);
    const [ispreviewshowModal, setpreviewShowModal] = useState(false);
    const [previllages, setprevillages] = useState({});
    const [branch, setbranch] = useState([]);
    const [role_id, setroleid] = useState("");

    const [addData, setAddData] = useState({
        branch_id: "",
        // expense_code: "",
        name: "",
        mobileno: "",
        email_id: "",
        company_name: "",

        address: "",
        service: "",
        account_no: "",
        bank_branch_name: "",
        ifsc_code: "",
    });

    const [updateData, setUpdateData] = useState({
        branch_id: "",
        expense_code: "",
        name: "",
        mobileno: "",
        email_id: "",
        company_name: "",

        address: "",
        service: "",
        account_no: "",
        bank_branch_name: "",
        ifsc_code: "",
    });

    useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // gettoken();
        fetchD();
        fetchDataed();
        // getprevillages();
    }, []);


    const fetchD = async () => {
        try {
            const response = await axios.get(ApiUrl + "/generate_expense_code");
            console.log("Response from server generate:", response.data.empid);
            setgenerateempidData(response.data.expense_code);
            addData.expense_code = response.data.expense_code;
            console.log("response", response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };



    // const gettoken = async () => {
    //     const token = localStorage.getItem("token");
    //     // console.log("the toekn in the localStorage is : ",token);
    //     try {
    //         const response = await axios.post(ApiUrl + "/get_detail", {
    //             token: token,
    //         });
    //         console.log("token detail from server:", response.data);
    //         // setResponseData(response.data);

    //         console.log("The role id is : ", response.data.detail.rolename);
    //         setroleid(response.data.detail.rolename);
    //         console.log("the role_id is : ", role_id);

    //         localStorage.setItem("role_id", response.data.detail.rolename);

    //         const id = response.data.detail.rolename;

    //         // getpages();

    //         if (id) {
    //             // try {
    //             //     const response = await axios.get(apiUrl + `/role_2/${id}`);

    //             //     console.log("the reponse list in brach is : ", response.data.modules);

    //             //     const modules = response.data.modules;

    //             //     const filtered_access = modules.filter(
    //             //         (item) => item.name == "Branch"
    //             //     );

    //             //     console.log("the modules is : ", filtered_access[0].actions);

    //             //     setprevillages(filtered_access[0].actions);

    //             //     console.log("the assigned previllages is : ", previllages.add);
    //             // } catch (error) {
    //             //     console.error("Error fetching data:", error.message);
    //             // }
    //         }
    //     } catch (error) {
    //         console.error("Error fetching data:", error.message);
    //     }
    // };

    // const getprevillages = () => {
    //     const username = JSON.parse(localStorage.getItem("user_previllages"));
    //     console.log("tyhe username get by local stroage : ", username.modules);
    //     const total_previlages = username.modules;
    //     const new_enquiry = total_previlages.find((item) => item.name == "Office Expense Type")
    //     console.log("the new_enquiry is : ", new_enquiry.actions);

    //     setprevillages(new_enquiry.actions)
    // }




    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(ApiUrl + "/Officeexpense", {
                params: { data: localStorage.getItem("branch_id") }
            });
            console.log("Response from server:", response.data);

            if (response && response.status === 200) {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }


            setResponseData(response.data.reverse());
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    const fetchDataed = async () => {
        try {
            const response = await axios.get(ApiUrl + "/Branch");
            console.log("Response from server :", response.data);
            setbranch(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const handlesubmit = async (e) => {
        e.preventDefault();

        // if (!validateFields()) {
        //     return;
        // }
        try {
            setLoading(true);
            const formData = {
                branch_id: addData.branch_id,
                expense_code: addData.expense_code,
                name: addData.name,
                mobileno: addData.mobileno,
                email_id: addData.email_id,
                company_name: addData.company_name,

                address: addData.address,
                service: addData.service,
                account_no: addData.account_no,
                bank_branch_name: addData.bank_branch_name,
                ifsc_code: addData.ifsc_code,
            };
            const response = await axios.post(ApiUrl + "/Officeexpense", formData);
            console.log("add:", response);
            setShowModal(false);
            toast.success("added successfully!");
            setAddData({
                branch_id: "",
                expense_code: "",
                name: "",
                mobileno: "",
                email_id: "",
                company_name: "",

                address: "",
                service: "",
                account_no: "",
                bank_branch_name: "",
                ifsc_code: "",
            });
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };

    const [updateid, setUpdateId] = useState("");

    const handleUpdate = (_id, data) => {
        setUpdateId(_id);
        console.log(" the data to updata", data);
        setUpdateShowModal(true);



        setUpdateData({

            // branch_id: data.branch_id,
            branch_name: data.branch,
            // branch_id: data.branch_id,
            branch_id: data.branch_id._id,
            name: data.name,
            mobileno: data.mobileno,
            email_id: data.email_id,
            company_name: data.company_name,

            address: data.address,
            service: data.service,
            account_no: data.account_no,
            bank_branch_name: data.bank_branch_name,
            ifsc_code: data.ifsc_code,
        });

        // setUpdateData(data);
    };


    const handlepreview = (_id, data) => {
        setUpdateId(_id);
        console.log(" the data to updata", data);
        setpreviewShowModal(true);
        setUpdateData({

            // branch_id: data.branch_id,
            branch_name: data.branch,
            // branch_id: data.branch_id,
            branch_id: data.branch_id._id,
            name: data.name,
            mobileno: data.mobileno,
            email_id: data.email_id,
            company_name: data.company_name,

            address: data.address,
            service: data.service,
            account_no: data.account_no,
            bank_branch_name: data.bank_branch_name,
            ifsc_code: data.ifsc_code,
        });
        // setUpdateData(data);
    };

    const handleupdatesubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const formData = {
                branch_id: updateData.branch_id,
                expense_code: updateData.expense_code,
                name: updateData.name,
                mobileno: updateData.mobileno,
                email_id: updateData.email_id,
                company_name: updateData.company_name,

                address: updateData.address,
                service: updateData.service,
                account_no: updateData.account_no,
                bank_branch_name: updateData.bank_branch_name,
                ifsc_code: updateData.ifsc_code,
            };
            console.log("the update submit data", formData);
            const response = await axios.put(
              ApiUrl + `/Officeexpense/${updateid}`,
                formData
            );
            console.log("response for update", response);
            setUpdateShowModal(false);
            toast.success("Update successfully!");
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };

    const handleDelete = async (id) => {
        try {
            setLoading(true);
            const response = await axios.delete(
              ApiUrl + `/Officeexpense/${getdeleteid}`
            );
            console.log("delete data ", response);
            toast.error("Delete successfully!");
            setDeleteShowModals(false);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };

    const [deleteShowModals, setDeleteShowModals] = useState(false);
    const handleCloseModal = () => {
        setDeleteShowModals(false);
    };

    const [getdeleteid, setdeleteid] = useState("");
    const [getdeletename, setdeletename] = useState("");

    const handleOpenModal = (_id, name) => {
        console.log(" the id", _id);
        console.log(" the name", name);
        setdeleteid(_id);
        setdeletename(name);
        setDeleteShowModals(true);
    };

    // the pagination ---------------------------------------------------------------------
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Search
    const filtered = currentItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                (typeof value === "string" &&
                    value.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (typeof value === "number" && value.toString().includes(searchQuery))
        )
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(responseData.length / itemsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage === totalPages ? prevPage : prevPage + 1
        );
    };

    const goToPrevPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
    };
    // the pagination  end ---------------------------------------------------------------------


    // const createPagination = () => {
    //     let liTag = [];
    //     let beforePage = currentPage - 1;
    //     let afterPage = currentPage + 1;
    //     let active;

    //     if (currentPage > 1) {
    //         liTag.push(
    //             <li className="page-item" key="prev">
    //                 <button
    //                     className="page-link"
    //                     onClick={() => paginate(currentPage - 1)}
    //                 >
    //                     <FaAngleLeft /> Prev
    //                 </button>
    //             </li>
    //         );
    //     }

    //     if (currentPage > 2) {
    //         liTag.push(
    //             <li className="page-item" key="first" onClick={() => paginate(1)}>
    //                 <button className="page-link">1</button>
    //             </li>
    //         );
    //         if (currentPage > 3) {
    //             liTag.push(
    //                 <li className="page-item disabled" key="dots1">
    //                     <span className="page-link">...</span>
    //                 </li>
    //             );
    //         }
    //     }

    //     if (currentPage === totalPages) {
    //         beforePage -= 2;
    //     } else if (currentPage === totalPages - 1) {
    //         beforePage -= 1;
    //     }

    //     if (currentPage === 1) {
    //         afterPage += 2;
    //     } else if (currentPage === 2) {
    //         afterPage += 1;
    //     }

    //     for (let plength = beforePage; plength <= afterPage; plength++) {
    //         if (plength > totalPages || plength <= 0) continue;
    //         active = currentPage === plength ? "active" : "";
    //         liTag.push(
    //             <li
    //                 className={`page-item ${active}`}
    //                 key={plength}
    //                 onClick={() => paginate(plength)}
    //             >
    //                 <button className="page-link">{plength}</button>
    //             </li>
    //         );
    //     }

    //     if (currentPage < totalPages - 1) {
    //         if (currentPage < totalPages - 2) {
    //             liTag.push(
    //                 <li className="page-item disabled" key="dots2">
    //                     <span className="page-link">...</span>
    //                 </li>
    //             );
    //         }
    //         liTag.push(
    //             <li
    //                 className="page-item"
    //                 key="last"
    //                 onClick={() => paginate(totalPages)}
    //             >
    //                 <button className="page-link">{totalPages}</button>
    //             </li>
    //         );
    //     }

    //     if (currentPage < totalPages) {
    //         liTag.push(
    //             <li className="page-item" key="next">
    //                 <button
    //                     className="page-link"
    //                     onClick={() => paginate(currentPage + 1)}
    //                 >
    //                     Next <FaAngleRight />
    //                 </button>
    //             </li>
    //         );
    //     }

    //     return liTag;
    // };


    const createPagination = () => {
        let liTag = [];
        let totalPages = pageCount; // Total pages
        let currentPage = state.pageIndex;
        let active;

        // Previous Page Button
        liTag.push(
            <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`} key="prev">
                <button
                    className="page-link"
                    onClick={() => gotoPage(currentPage - 1)}
                    disabled={!canPreviousPage}
                >
                    <FaAngleLeft /> Prev
                </button>
            </li>
        );

        // Always show first page
        if (totalPages > 1) {
            liTag.push(
                <li className={`page-item ${currentPage === 0 ? "active" : ""}`} key={0}>
                    <button className="page-link" onClick={() => gotoPage(0)}>
                        1
                    </button>
                </li>
            );
        }

        // Show second page if total pages > 2
        if (totalPages > 2) {
            liTag.push(
                <li className={`page-item ${currentPage === 1 ? "active" : ""}`} key={1}>
                    <button className="page-link" onClick={() => gotoPage(1)}>
                        2
                    </button>
                </li>
            );
        }

        // Add "..." before current page group
        if (currentPage > 3) {
            liTag.push(
                <li className="page-item disabled" key="dot-prev">
                    <span className="page-link">...</span>
                </li>
            );
        }

        // Show 3 pages around current page
        for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 3, currentPage + 1); i++) {
            active = currentPage === i ? "active" : "";
            liTag.push(
                <li className={`page-item ${active}`} key={i}>
                    <button className="page-link" onClick={() => gotoPage(i)}>
                        {i + 1}
                    </button>
                </li>
            );
        }

        // Add "..." after current page group
        if (currentPage < totalPages - 4) {
            liTag.push(
                <li className="page-item disabled" key="dot-next">
                    <span className="page-link">...</span>
                </li>
            );
        }

        // Always show last two pages if totalPages > 2
        if (totalPages > 2) {
            liTag.push(
                <li className={`page-item ${currentPage === totalPages - 2 ? "active" : ""}`} key={totalPages - 2}>
                    <button className="page-link" onClick={() => gotoPage(totalPages - 2)}>
                        {totalPages - 1}
                    </button>
                </li>
            );
        }

        if (totalPages > 1) {
            liTag.push(
                <li className={`page-item ${currentPage === totalPages - 1 ? "active" : ""}`} key={totalPages - 1}>
                    <button className="page-link" onClick={() => gotoPage(totalPages - 1)}>
                        {totalPages}
                    </button>
                </li>
            );
        }

        // Next Page Button
        liTag.push(
            <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
                <button
                    className="page-link"
                    onClick={() => gotoPage(currentPage + 1)}
                    disabled={!canNextPage}
                >
                    Next <FaAngleRight />
                </button>
            </li>
        );

        return liTag;
    };

    // ========================================================================


    const [rolename, setrolename] = useState([]);
    useEffect(() => {

        const branchName = localStorage.getItem("branch_name");
        setrolename(branchName);
    }, []);

    const [loading, setLoading] = useState(false);


    const data = useMemo(() => {
        return responseData.map((item) => ({
            _id: item._id,
            //   payment: item.Payment_type,
            // branch: item.branch_id?.branch_name,
            // branch_id: item.branch_id?._id,
            // branch_id: {
            //     _id: item.branch_id?._id ?? null,
            //     branch_name: item.branch_id?.branch_name ?? '',
            // },
            branch_id: {
                _id: item.branch_id?._id,
                branch_name: item.branch_id?.branch_name,
            },
            name: item.name,
            mobileno: item.mobileno,
            email_id: item.email_id,
            company_name: item.company_name,

            address: item.address,
            service: item.service,
            account_no: item.account_no,
            bank_branch_name: item.bank_branch_name,
            ifsc_code: item.ifsc_code,

        }));
    }, [responseData]);

    const columns = useMemo(
        () => [
            {
                Header: "S.No",
                id: "serialNo",
                Cell: ({ row }) => {
                    return row.index + 1;
                },
            },
            {
                Header: "Branch",
                accessor: "branch_id.branch_name", // The key here is branch_id
                Filter: DefaultColumnFilter,
                filter: "multiSelectFilter",
                // Custom cell rendering to display the branch name
                // Cell: ({ value }) => value?.branch_name || "N/A", // Safely accessing branch_name
            },
            {
                Header: "Name",
                accessor: "name",
                Filter: DefaultColumnFilter,
                filter: "multiSelectFilter",
            },
            {
                Header: "Mobileno",
                accessor: "mobileno",
                Filter: DefaultColumnFilter,
                filter: "multiSelectFilter",
            },
            {
                Header: "Email",
                accessor: "email_id",
                Filter: DefaultColumnFilter,
                filter: "multiSelectFilter",
            },
            {
                Header: "Company Name",
                accessor: "company_name",
                Filter: DefaultColumnFilter,
                filter: "multiSelectFilter",
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
        setPageSize,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10 },
            defaultColumn: { Filter: DefaultColumnFilter },
            filterTypes: {
                multiSelectFilter: (rows, columnIds, filterValue) => {
                    if (!filterValue || filterValue.length === 0) {
                        return rows;
                    }
                    return rows.filter((row) => {
                        const rowValue = row.values[columnIds[0]];
                        const rowValueStr = String(rowValue);
                        return filterValue.some((filterVal) =>
                            rowValueStr.includes(String(filterVal))
                        );
                    });
                },
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );


    return (
        <>
            {loading === true ? (
                <div className="loading-overlay">
                    <div className="loading-message">
                        {/* <img
                            className="loadinggif"
                            src={require("../../assets/img/output-onlinegiftools.gif")}
                        />{" "} */}
                    </div>
                </div>
            ) : (
                <div className="d-none"></div>
            )}

            <div className="top">
                <div className="" style={{ marginTop: "-80px" }}>
                    <div className="row mb-3">
                        <div className="col-lg-11">
                            <Link to="/dashboard" className="text-secondary dashBoard_text">
                                <b>Dashboard / </b>
                            </Link>
                            <Link to="" className="text-danger dashBoard_text">
                                <b>Office Expense</b>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* Table */}
                <div className="d-flex">
                    <h5 className="mt-2">Office Expense</h5>
                    <div className="mx-3">
                        {/* {previllages.add && ( */}
                            <button
                                className="btn btn-dark add mx-3"
                                onClick={() => setShowModal(true)}
                            >
                                Add
                            </button>
                        {/* )} */}
                    </div>
                </div>

                {/* ------------ search and per page ----------------------------*/}

                {/* <div className="mb-3 mt-3">
                    <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                            <label className="form-label text-dark mt-2">Show : </label>
                            <div>
                                <select
                                    className="form-control w-100 mx-2"
                                    onChange={(e) => {
                                        setItemsPerPage(e.target.value);
                                    }}
                                >
                            
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-4 col-sm-1"></div>
                        <div className="col-lg-3 col-md-4 col-sm-7">
                            <div className="w-full flex-1">
                                <form>
                                    <div className="relative">
                                        <input
                                            className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                                            placeholder="Search ..."
                                            type="search"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> */}



                <div className="mb-1 mt-3">
                    <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                            <label className="form-label text-dark mt-2">Show : </label>
                            <div>
                                <select
                                    className="form-control w-100 mx-2"
                                    value={state.pageSize}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value === 'All') {
                                            setPageSize(data.length);  // 'data' should be your dataset containing all the entries
                                        } else {
                                            setPageSize(Number(value));
                                        }
                                    }}
                                >
                                    <option value="All">All</option> {/* Add 'All' option */}
                                    {[10, 20, 100, 200, 500, 1000].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-4 col-sm-1"></div>
                        <div className="col-lg-3 col-md-4 col-sm-7">
                            <div className="w-full flex-1">
                                <form>
                                    <div className="relative">
                                        <input
                                            className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                                            placeholder="Search ..."
                                            type="search"
                                            value={state.globalFilter || ""} // Use the globalFilter value
                                            onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                {/* --------------------------------end------------------------------------------- */}
                {/* <div className="card">
                    <div className="mt-1">
                        <table className="table table-striped ">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <td> {rolename === "Branches" && <th>Branch</th>}</td>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Company Name</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {filtered.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{rolename === "Branches" && item.branch_id.branch_name}</td>
                                        <td>{item.name}</td>
                                        <td>{item.mobileno}</td>
                                        <td>{item.email_id}</td>
                                        <td>{item.company_name}</td>
                                        <td className="text-center">
                                            {previllages.edit && (
                                                <button
                                                    className="btn updelete mx-2"
                                                    onClick={() => handleUpdate(item._id, item)}
                                                >
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </button>
                                            )}

                                            {previllages.delete && (
                                                <button
                                                    className="btn delete"
                                                    onClick={() =>
                                                        handleOpenModal(item._id, item.name)
                                                    }
                                                >
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </button>
                                            )}

                                            <button
                                                className="btn updelete mx-2"
                                                onClick={() => handlepreview(item._id, item)}
                                            >
                                                <i className="fa-solid fa-circle-info"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div> */}



                <TableContainer>
                    <Table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <Th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                        >
                                            <div className="d-flex">
                                                <div className="mx-2">{column.render("Header")}</div>
                                                <div>
                                                    {column.canFilter ? column.render("Filter") : null}
                                                </div>
                                                <div className="mx-1">
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? " 🔽"
                                                            : " 🔼"
                                                        : ""}
                                                </div>
                                            </div>
                                        </Th>
                                    ))}
                                    <Th className="text-center">Action</Th>
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                        ))}
                                        <Td className="text-center">
                                            {/* {previllages.edit && ( */}
                                                <button
                                                    className="btn updelete mx-2"
                                                    onClick={() =>
                                                        handleUpdate(row.original._id, row.original)
                                                    }
                                                >
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </button>
                                            {/* )} */}

                                            {/* {previllages.delete && ( */}
                                                <button
                                                    className="btn delete"
                                                    onClick={() =>
                                                        handleOpenModal(
                                                            row.original._id,
                                                            row.original.name

                                                        )
                                                    }
                                                >
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </button>
                                            {/* )} */}


                                            <button
                                                className="btn updelete mx-2"
                                                onClick={() =>
                                                    handlepreview(row.original._id, row.original)
                                                }
                                            >
                                                <i className="fa-solid fa-circle-info"></i>
                                            </button>


                                        </Td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </TableContainer>


                {/* Modal for adding a new user */}
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Office Expense </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">

                                        <div className="floating-label">
                                            <select
                                                className="form-select"
                                                value={addData.branch_id}
                                                onChange={(e) =>
                                                    setAddData({ ...addData, branch_id: e.target.value })
                                                }
                                                required
                                            >
                                                <option value="">Select Branch</option>
                                                {branch.map((item, index) => (
                                                    <option key={index} value={item._id}>
                                                        {item.branch_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={addData.branch_id ? "filled" : ""}>
                                                Select Branch
                                            </label>

                                        </div>



                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.name}
                                                onChange={(e) =>
                                                    setAddData({ ...addData, name: e.target.value })
                                                }
                                                required
                                            />
                                            <label className={addData.name ? "filled" : ""}>
                                                Name
                                            </label>
                                            {/* {errors.name && (
                                                <div className="error-text">{errors.name}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.mobileno}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        mobileno: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.mobileno ? "filled" : ""}>
                                                Mobile
                                            </label>
                                            {/* {errors.mobileno && (
                                                <div className="error-text">{errors.mobileno}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.email_id}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        email_id: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.email_id ? "filled" : ""}>
                                                Email
                                            </label>
                                            {/* {errors.email_id && (
                                                <div className="error-text">{errors.email_id}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.company_name}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        company_name: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.company_name ? "filled" : ""}>
                                                Company Name
                                            </label>
                                            {/* {errors.company_name && (
                                                <div className="error-text">{errors.company_name}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.ifsc_code}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        ifsc_code: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.ifsc_code ? "filled" : ""}>
                                                IFSC Code
                                            </label>
                                            {/* {errors.ifsc_code && (
                                                <div className="error-text">{errors.ifsc_code}</div>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">


                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.address}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        address: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.address ? "filled" : ""}>
                                                Address
                                            </label>
                                            {/* {errors.address && (
                                                <div className="error-text">{errors.address}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.service}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        service: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.service ? "filled" : ""}>
                                                Service
                                            </label>
                                            {/* {errors.service && (
                                                <div className="error-text">{errors.service}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.account_no}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        account_no: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.account_no ? "filled" : ""}>
                                                Account Number
                                            </label>
                                            {/* {errors.account_no && (
                                                <div className="error-text">{errors.account_no}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.bank_branch_name}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        bank_branch_name: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label
                                                className={addData.bank_branch_name ? "filled" : ""}
                                            >
                                                Bank Branch Name
                                            </label>
                                            {/* {errors.bank_branch_name && (
                                                <div className="error-text">
                                                    {errors.bank_branch_name}
                                                </div>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            className="cancelbut"
                            variant="secondary"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="submitbut"
                            variant="primary"
                            onClick={handlesubmit}
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={isUpdateshowModal}
                    onHide={() => setUpdateShowModal(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Office Expense</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Other input fields */}

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">

                                        {/* 
                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                placeholder=""
                                                type="text"
                                                value={updateData.branch_name}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        branch_id: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.branch_id ? "filled" : ""}>
                                                Branch
                                            </label>
                                        </div> */}



                                        <div className="floating-label">
                                            <select className="form-select" value={updateData.branch_id} onChange={(e) => setUpdateData({ ...updateData, branch_id: e.target.value })}>
                                                <option value="" disabled>Select Brand Id</option>
                                                {branch.map((item, index) => (
                                                    <option key={index} value={item._id}>
                                                        {item.branch_id} - {item.branch_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={updateData.branch_id ? "filled" : ""}>

                                            </label>

                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.name}
                                                onChange={(e) =>
                                                    setUpdateData({ ...updateData, name: e.target.value })
                                                }
                                                required
                                            />
                                            <label className={updateData.name ? "filled" : ""}>
                                                Name
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="number"
                                                placeholder=""
                                                value={updateData.mobileno}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        mobileno: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.mobileno ? "filled" : ""}>
                                                Mobile no
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.email_id}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        email_id: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.email_id ? "filled" : ""}>
                                                Email Id
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.company_name}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        company_name: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.company_name ? "filled" : ""}>
                                                Company Name
                                            </label>
                                        </div>





                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.address}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        address: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.address ? "filled" : ""}>
                                                Address
                                            </label>
                                        </div>



                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">





                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.service}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        service: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.service ? "filled" : ""}>
                                                Service
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.account_no}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        account_no: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.account_no ? "filled" : ""}>
                                                Account No
                                            </label>
                                        </div>
                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.bank_branch_name}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        bank_branch_name: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.bank_branch_name ? "filled" : ""}>
                                                Branch Name
                                            </label>
                                        </div>
                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.ifsc_code}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        ifsc_code: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.ifsc_code ? "filled" : ""}>
                                                IFSC Code
                                            </label>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            className="cancelbut"
                            variant="secondary"
                            onClick={() => setUpdateShowModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="submitbut"
                            variant="primary"
                            onClick={handleupdatesubmit}
                        >
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* ----------------delete */}
                {/* ==================================================================================== */}



                <Modal
                    show={ispreviewshowModal}
                    onHide={() => setpreviewShowModal(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Preview Expense</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Other input fields */}

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">

                                        <div className="floating-label">
                                            <select className="form-select" disabled value={updateData.branch_id} onChange={(e) => setUpdateData({ ...updateData, branch_id: e.target.value })}>
                                                <option value="" disabled>Select Brand Id</option>
                                                {branch.map((item, index) => (
                                                    <option key={index} value={item._id}>
                                                        {item.branch_id} - {item.branch_name}
                                                    </option>
                                                ))}
                                                disabled
                                            </select>
                                            <label className={updateData.branch_id ? "filled" : ""}>

                                            </label>

                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                disabled
                                                value={updateData.name}
                                                onChange={(e) =>
                                                    setUpdateData({ ...updateData, name: e.target.value })
                                                }
                                                required
                                            />
                                            <label className={updateData.name ? "filled" : ""}>
                                                Name
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="number"
                                                placeholder=""
                                                disabled
                                                value={updateData.mobileno}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        mobileno: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.mobileno ? "filled" : ""}>
                                                Mobile no
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                disabled
                                                value={updateData.email_id}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        email_id: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.email_id ? "filled" : ""}>
                                                Email Id
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                disabled
                                                value={updateData.company_name}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        company_name: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.company_name ? "filled" : ""}>
                                                Company Name
                                            </label>
                                        </div>





                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                disabled
                                                value={updateData.address}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        address: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.address ? "filled" : ""}>
                                                Address
                                            </label>
                                        </div>



                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">





                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                disabled
                                                value={updateData.service}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        service: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.service ? "filled" : ""}>
                                                Service
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                disabled
                                                value={updateData.account_no}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        account_no: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.account_no ? "filled" : ""}>
                                                Account No
                                            </label>
                                        </div>
                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                disabled
                                                value={updateData.bank_branch_name}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        bank_branch_name: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.bank_branch_name ? "filled" : ""}>
                                                Branch Name
                                            </label>
                                        </div>
                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                disabled
                                                value={updateData.ifsc_code}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        ifsc_code: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.ifsc_code ? "filled" : ""}>
                                                IFSC Code
                                            </label>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            className="cancelbut"
                            variant="secondary"
                            onClick={() => setpreviewShowModal(false)}
                        >
                            Cancel
                        </Button>

                    </Modal.Footer>
                </Modal>

                <Modal
                    className="modal"
                    show={deleteShowModals}
                    onHide={handleCloseModal}
                >
                    <Modal.Header>
                        <p>Are you sure you want to Delete?</p>
                    </Modal.Header>
                    <Modal.Body>
                        <label className="form-label d-flex">
                            <h5 className="mx-1"> Name :</h5> <h5>{getdeletename} </h5>
                        </label>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="cancelbut"
                            variant="secondary"
                            onClick={handleCloseModal}
                        >
                            Close
                        </Button>
                        <Button
                            className="submitbut"
                            variant="primary"
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* _------------------------------pagination button -------------------------------- */}

                <div className="mt-3 pb-5">
                    <div className="row">
                        <div className="col-lg-7 col-sm-12 mt-1">
                            <span className="text-dark">
                                Page {currentPage} of {totalPages}
                            </span>
                        </div>
                        <div className="col-lg-5">
                            <nav>
                                <ul className="pagination justify-content-center">
                                    {createPagination()}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------end ---------------------------------- */}
            </div>
        </>
    );
}

export default Office_Expense;
