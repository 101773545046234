import React, { useEffect, useState } from "react";
import "../../assets/css/Studentinfo.css";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import ApiUrl from "../Environment/ApiUrl";
import profileImg from "../../assets/img/icon.jpg";

function Leadhistroy() {
  const { id } = useParams();
  const [lead, setLead] = useState([]);
  const [VisitHistory,setvisithistroy] = useState([])
  const [client, setClient] = useState({});
  const [sourceType, setSourceType] = useState("N/A");
  const [purposeEnquiry, setPurposeEnquiry] = useState("N/A");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch lead history
        const response = await axios.get(`${ApiUrl}/leadhistroy/${id}`);
        const response_data = await axios.get(`${ApiUrl}/leadhistroy_visit/${id}`);
        setvisithistroy(response_data.data)
        setLead(response.data);
        console.log("hi", response.data)
        // Fetch client details
        const clientResponse = await axios.get(`${ApiUrl}/leads/${id}`);
        setClient(clientResponse.data);

        // Fetch source type
        if (clientResponse.data.source_type) {
          const sourceResponse = await axios.get(
            `${ApiUrl}/source_type/${clientResponse.data.source_type}`
          );
          setSourceType(sourceResponse.data.source_type || "N/A");
        }

        // Fetch enquiry purpose name
        if (clientResponse.data.purpose_enquiry) {
          const enquiryPurposeResponse = await axios.get(
            `${ApiUrl}/enquiry_purpose/${clientResponse.data.purpose_enquiry}`
          );
          setPurposeEnquiry(enquiryPurposeResponse.data.enquiry_purpose || "N/A");
        }

      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [id]);

  const handleDelete = async (leadId) => {
    try {
      await axios.delete(`${ApiUrl}/leadhistroy/${leadId}`);
      toast.success("Deleted Successfully");
      setLead(lead.filter((item) => item._id !== leadId));
    } catch (error) {
      console.error("Error deleting entry:", error.message);
    }
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    return new Date(isoDate).toISOString().split("T")[0];
  };

  const statusClassMap = {
    Unqualified: "bg-danger1 text-white1",
    Convert: "bg-success1 text-white1",
    Rescheduled: "bg-primary1 text-white1",
    Qualified: "bg-warning1 text-white1",
    NotReachable: "bg-dark1 text-white1",
    New: "bg-info1 text-white1",
    "Walk-in-reschedule": "bg-dark1 text-white1",
    Scheduled: "bg-secondary1 text-white1",
  };

  return (
    <div className="marginup">
      <div className="row">
        <div className="col-lg-11">
          <Link to="/dashboard" className="text-secondary dashBoard_text">
            <b>Dashboard / </b>
          </Link>
          <Link to="#" className="text-secondary dashBoard_text">
            <b>Leads / </b>
          </Link>
          <Link to="" className="text-danger dashBoard_text">
            <b>History</b>
          </Link>
        </div>
      </div>

      {/* Client Profile Section */}
      <div className="row mt-2">
        <div className="col-lg-3">
          <h6>
            <b>Client Profile</b>
          </h6>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-lg-3 col-sm-3 col-md-3">
          <div className="card carddetails p-2">
            <div className="imgdetails">
              <img className="profileimg" src={profileImg} alt="Profile" />
            </div>
            <div className="text-start mt-2 text-white">
              <h6 className="profile-detailsed">
                <i className="fa-solid fa-user px-3"></i>
                <b>{client.name || "N/A"}</b>
              </h6>
              <h6 className="profile-detailsed">
                <i className="fa-solid fa-phone px-3"></i>
                {client.official_mobile || "N/A"}
              </h6>
              <h6 className="profile-detailsed">
                <i className="fa-solid fa-id-card px-3"></i>
                {purposeEnquiry}
              </h6>
              <h6 className="profile-detailsed">
                <i className="fa-solid fa-book px-3"></i>
                {sourceType}
              </h6>
              <h6 className="profile-detailsed">
                <i className="fa-solid fa-calendar-days px-3"></i>
                {client.enquiry_date || "N/A"} - {(client.enquiry_time)}
              </h6>
              <h6 className="profile-detailsed">
                <i className="fa-solid fa-recycle px-3"></i> No. of Attempts: {lead.length}
              </h6>
            </div>
          </div>
        </div>

        {/* Lead History Table */}
        <div className="col-lg-9 col-sm-9 col-md-9">
          <div className="row cardborder">
            <div className="card cardborder p-1">
              <div className="row p-2">
                <div className="col-lg-6">
                  <b>History</b>
                </div>
              </div>
            </div>

            <div className="card cardborder">
              <table className="table table-striped responsive">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>Contact</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Staff</th>
                    <th>Notes</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {lead.length > 0 ? (
                    lead.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{formatDate(item.status_date)}</td>
                        <td>{item.contact_type}</td>
                        <td>{item.start_time}</td>
                        <td>{item.end_time}</td>
                        <td>{item.assign_staff?.name}</td>
                        <td>{item.notes}</td>
                        <td>
                          <span
                            className={`badge ${statusClassMap[item.followups_status] || ""
                              }`}
                          >
                            {item.followups_status}
                          </span>
                        </td>
                        <td className="text-center">
                          <button
                            className="btn delete"
                            onClick={() => handleDelete(item._id)}
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No History Found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-9 col-sm-9 col-md-9">
            <div className="row cardborder">
              <div className="card cardborder p-1">
                <div className="row p-2">
                  <div className="col-lg-6">
                    <b>Field Visit Histroy</b>
                  </div>
                </div>
              </div>

              <div className="card cardborder">
                <table className="table table-striped responsive">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Date</th>
                      <th>Contact</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Staff</th>
                      <th>Notes</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {VisitHistory.length > 0 ? (
                      VisitHistory.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{formatDate(item.status_date)}</td>
                          <td>{item.contact_type}</td>
                          <td>{item.start_time}</td>
                          <td>{item.end_time}</td>
                          <td>{item.assign_staff?.name}</td>
                          <td>{item.notes}</td>
                          <td>
                            <span
                              className={`badge ${statusClassMap[item.followups_status] || ""
                                }`}
                            >
                              {item.followups_status}
                            </span>
                          </td>
                          <td className="text-center">
                            <button
                              className="btn delete"
                              onClick={() => handleDelete(item._id)}
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No History Found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}

export default Leadhistroy;
