import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ApiUrl from "./../Environment/ApiUrl";
import "../../assets/css/Common.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function VendorDetails() {
  const [responseData, setResponseData] = useState([]);
  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      vendor_code: item.vendor_code,
      name: item.name,
      mobileno: item.mobileno,
      email_id: item.email_id,
      company_name: item.company_name,
      gst_no: item.gst_no,
      address: item.address,
      service: item.service,
      account_no: item.account_no,
      bank_branch_name: item.bank_branch_name,
      ifsc_code: item.ifsc_code,
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Vendor Code",
        accessor: "vendor_code",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Mobile",
        accessor: "mobileno",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Email",
        accessor: "email_id",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [generateempidData, setgenerateempidData] = useState([]);

  const [addData, setAddData] = useState({
    vendor_code: "",
    name: "",
    mobileno: "",
    email_id: "",
    company_name: "",
    gst_no: "",
    address: "",
    service: "",
    account_no: "",
    bank_branch_name: "",
    ifsc_code: "",
  });

  const [updateData, setUpdateData] = useState({
    vendor_code: "",
    name: "",
    mobileno: "",
    email_id: "",
    company_name: "",
    gst_no: "",
    address: "",
    service: "",
    account_no: "",
    bank_branch_name: "",
    ifsc_code: "",
  });

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchD();
  }, []);

  const fetchD = async () => {
    try {
      const response = await axios.get(ApiUrl + "/generate_vendor_code");
      console.log("Response from server generate:", response.data.empid);
      setgenerateempidData(response.data.vendor_code);
      addData.vendor_code = response.data.vendor_code;
      console.log("schudule", response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/vendor_details");
      console.log("Response from server  vendor:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }
    try {
      const formData = {
        vendor_code: addData.vendor_code,
        name: addData.name,
        mobileno: addData.mobileno,
        email_id: addData.email_id,
        company_name: addData.company_name,
        gst_no: addData.gst_no,
        address: addData.address,
        service: addData.service,
        account_no: addData.account_no,
        bank_branch_name: addData.bank_branch_name,
        ifsc_code: addData.ifsc_code,
      };
      const response = await axios.post(ApiUrl + "/vendor_details", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("added successfully!");
      setAddData({
        vendor_code: "",
        name: "",
        mobileno: "",
        email_id: "",
        company_name: "",
        gst_no: "",
        address: "",
        service: "",
        account_no: "",
        bank_branch_name: "",
        ifsc_code: "",
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    setUpdateId(_id);
    console.log(" the data to updata", data);
    setUpdateShowModal(true);
    // setUpdateData(data);
    setCommonData({
      vendor_code: data.vendor_code,
      name: data.name,
      mobileno: data.mobileno,
      email_id: data.email_id,
      company_name: data.company_name,
      gst_no: data.gst_no,
      address: data.address,
      service: data.service,
      account_no: data.account_no,
      bank_branch_name: data.bank_branch_name,
      ifsc_code: data.ifsc_code,
    });
  };

  const [showPreview, setShowPreview] = useState(false);

  // Function to handle preview and properly set the data
  const handlePreview = (data) => {
    // Directly set the update data with the correct vendor fields
    setUpdateData({
      vendor_code: data.vendor_code,
      name: data.name,
      mobileno: data.mobileno,
      email_id: data.email_id,
      company_name: data.company_name,
      gst_no: data.gst_no,
      address: data.address,
      service: data.service,
      account_no: data.account_no,
      bank_branch_name: data.bank_branch_name,
      ifsc_code: data.ifsc_code,
    });

    setShowPreview(true);
  };

  // Replace the existing setCommonData function with this corrected version
  const setCommonData = (data) => {
    const vendorData = {
      vendor_code: data.vendor_code,
      name: data.name,
      mobileno: data.mobileno,
      email_id: data.email_id,
      company_name: data.company_name,
      gst_no: data.gst_no,
      address: data.address,
      service: data.service,
      account_no: data.account_no,
      bank_branch_name: data.bank_branch_name,
      ifsc_code: data.ifsc_code,
    };
    console.log("Setting Vendor Data: ", vendorData);
    setUpdateData(vendorData);
  };
  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        vendor_code: updateData.vendor_code,
        name: updateData.name,
        mobileno: updateData.mobileno,
        email_id: updateData.email_id,
        company_name: updateData.company_name,
        gst_no: updateData.gst_no,
        address: updateData.address,
        service: updateData.service,
        account_no: updateData.account_no,
        bank_branch_name: updateData.bank_branch_name,
        ifsc_code: updateData.ifsc_code,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        ApiUrl + `/vendor_details/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        ApiUrl + `/vendor_details/${getdeleteid}`
      );
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");

  const handleOpenModal = (_id, vendor_code) => {
    console.log(" the id", _id);
    console.log(" the name", vendor_code);
    setdeleteid(_id);
    setdeletename(vendor_code);
    setDeleteShowModals(true);
  };

  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  // the pagination  end ---------------------------------------------------------------------

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    let isValid = true;

    if (!addData.vendor_code) {
      tempErrors.vendor_code = "Vendor Code is required.";
      isValid = false;
    }

    if (!addData.name) {
      tempErrors.name = "Name is required.";
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };

  return (
    <>
      <>
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-12">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Vendor</b>
              </Link>
            </div>
          </div>
        </div>
        {/* Table */}
        <div className="d-flex">
          <h6 className="mt-2 bold">Vendor Details</h6>
          <div className="">
            <button
              className="btn btn-dark btn-sm add mx-3"
              onClick={() => setShowModal(true)}
            >
              Add
            </button>
          </div>
        </div>

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  value={state.pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 20, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      value={state.globalFilter || ""} // Use the globalFilter value
                      onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <TableContainer>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="d-flex">
                        <div className="mx-2">{column.render("Header")}</div>
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                        <div className="mx-1">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </div>
                      </div>
                    </Th>
                  ))}
                  <Th className="text-center">Action</Th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    ))}
                    <Td className="text-center">
                      <button
                        className="btn custom-btn-update btn-sm"
                        onClick={() =>
                          handleUpdate(row.original._id, row.original)
                        }
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>

                      <button
                        className="btn custom-btn-delete btn-sm mx-1"
                        onClick={() =>
                          handleOpenModal(
                            row.original._id,
                            row.original.vendor_code
                          )
                        }
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>

                      <button
                        className="btn custom-btn-info btn-sm"
                        onClick={() => handlePreview(row.original)}
                      >
                        <i class="fa-solid fa-eye"></i>
                      </button>
                    </Td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>

        {/* Modal for adding a new user */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Vendor </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    {generateempidData && (
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={addData.vendor_code}
                          // value= {generateempidData}
                          onChange={(e) => {
                            // Call your handleempid function
                            setAddData({
                              ...addData,
                              vendor_code: e.target.value, // Update the empid field in the state
                            });
                          }}
                          required
                          disabled
                        />

                        <label className={addData.vendor_code ? "filled" : ""}>
                          Vendor code
                        </label>
                      </div>
                    )}

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.name}
                        onChange={(e) =>
                          setAddData({ ...addData, name: e.target.value })
                        }
                        required
                      />
                      <label className={addData.name ? "filled" : ""}>
                        Name
                      </label>
                      {errors.name && (
                        <div className="error-text">{errors.name}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.mobileno}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            mobileno: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.mobileno ? "filled" : ""}>
                        Mobile
                      </label>
                      {errors.mobileno && (
                        <div className="error-text">{errors.mobileno}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.email_id}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            email_id: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.email_id ? "filled" : ""}>
                        Email
                      </label>
                      {errors.email_id && (
                        <div className="error-text">{errors.email_id}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.company_name}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            company_name: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.company_name ? "filled" : ""}>
                        Company Name
                      </label>
                      {errors.company_name && (
                        <div className="error-text">{errors.company_name}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.ifsc_code}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            ifsc_code: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.ifsc_code ? "filled" : ""}>
                        IFSC Code
                      </label>
                      {errors.ifsc_code && (
                        <div className="error-text">{errors.ifsc_code}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.gst_no}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            gst_no: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.gst_no ? "filled" : ""}>
                        GST No.
                      </label>
                      {errors.gst_no && (
                        <div className="error-text">{errors.gst_no}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.address}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            address: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.address ? "filled" : ""}>
                        Address
                      </label>
                      {errors.address && (
                        <div className="error-text">{errors.address}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.service}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            service: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.service ? "filled" : ""}>
                        Service
                      </label>
                      {errors.service && (
                        <div className="error-text">{errors.service}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.account_no}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            account_no: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.account_no ? "filled" : ""}>
                        Account Number
                      </label>
                      {errors.account_no && (
                        <div className="error-text">{errors.account_no}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.bank_branch_name}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            bank_branch_name: e.target.value,
                          })
                        }
                        required
                      />
                      <label
                        className={addData.bank_branch_name ? "filled" : ""}
                      >
                        Bank Branch Name
                      </label>
                      {errors.bank_branch_name && (
                        <div className="error-text">
                          {errors.bank_branch_name}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handlesubmit}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={isUpdateshowModal}
          onHide={() => setUpdateShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Vendor </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={updateData.vendor_code}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            vendor_code: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={updateData.vendor_code ? "filled" : ""}>
                        Vendor Code
                      </label>
                    </div>
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={updateData.name}
                        onChange={(e) =>
                          setUpdateData({ ...updateData, name: e.target.value })
                        }
                        required
                      />
                      <label className={updateData.name ? "filled" : ""}>
                        Name
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="number"
                        placeholder=""
                        value={updateData.mobileno}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            mobileno: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={updateData.mobileno ? "filled" : ""}>
                        Mobile no
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={updateData.email_id}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            email_id: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={updateData.email_id ? "filled" : ""}>
                        Email Id
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={updateData.company_name}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            company_name: e.target.value,
                          })
                        }
                        required
                      />
                      <label
                        className={updateData.company_name ? "filled" : ""}
                      >
                        Company Name
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={updateData.gst_no}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            gst_no: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={updateData.gst_no ? "filled" : ""}>
                        GST NO
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={updateData.address}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            address: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={updateData.address ? "filled" : ""}>
                        Address
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={updateData.service}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            service: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={updateData.service ? "filled" : ""}>
                        Service
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={updateData.account_no}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            account_no: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={updateData.account_no ? "filled" : ""}>
                        Account No
                      </label>
                    </div>
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={updateData.bank_branch_name}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            bank_branch_name: e.target.value,
                          })
                        }
                        required
                      />
                      <label
                        className={updateData.bank_branch_name ? "filled" : ""}
                      >
                        Branch Name
                      </label>
                    </div>
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={updateData.ifsc_code}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            ifsc_code: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={updateData.ifsc_code ? "filled" : ""}>
                        IFSC Code
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setUpdateShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleupdatesubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* preview */}

        <Modal
          show={showPreview}
          onHide={() => setShowPreview(false)}
          className="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Preview Income List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.vendor_code}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          vendor_code: e.target.value,
                        })
                      }
                      disabled
                    />
                    <label className={updateData.vendor_code ? "filled" : ""}>
                      Vendor Code
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.name}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, name: e.target.value })
                      }
                      disabled
                    />
                    <label className={updateData.name ? "filled" : ""}>
                      Name
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="number"
                      placeholder=""
                      value={updateData.mobileno}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          mobileno: e.target.value,
                        })
                      }
                      disabled
                    />
                    <label className={updateData.mobileno ? "filled" : ""}>
                      Mobile no
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.email_id}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          email_id: e.target.value,
                        })
                      }
                      disabled
                    />
                    <label className={updateData.email_id ? "filled" : ""}>
                      Email Id
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.company_name}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          company_name: e.target.value,
                        })
                      }
                      disabled
                    />
                    <label className={updateData.company_name ? "filled" : ""}>
                      Company Name
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.gst_no}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          gst_no: e.target.value,
                        })
                      }
                      disabled
                    />
                    <label className={updateData.gst_no ? "filled" : ""}>
                      GST NO
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.address}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          address: e.target.value,
                        })
                      }
                      disabled
                    />
                    <label className={updateData.address ? "filled" : ""}>
                      Address
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.service}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          service: e.target.value,
                        })
                      }
                      disabled
                    />
                    <label className={updateData.service ? "filled" : ""}>
                      Service
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.account_no}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          account_no: e.target.value,
                        })
                      }
                      disabled
                    />
                    <label className={updateData.account_no ? "filled" : ""}>
                      Account No
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.bank_branch_name}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          bank_branch_name: e.target.value,
                        })
                      }
                      disabled
                    />
                    <label
                      className={updateData.bank_branch_name ? "filled" : ""}
                    >
                      Branch Name
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.ifsc_code}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          ifsc_code: e.target.value,
                        })
                      }
                      disabled
                    />
                    <label className={updateData.ifsc_code ? "filled" : ""}>
                      IFSC Code
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowPreview(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ----------------delete */}

        <Modal
          className="modal"
          show={deleteShowModals}
          onHide={handleCloseModal}
        >
          <Modal.Header>
            <p>Are you sure you want to Delete?</p>
          </Modal.Header>
          <Modal.Body>
            <label className="form-label d-flex">
              <h5 className="mx-1"> Name :</h5> <h5>{getdeletename} </h5>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* _------------------------------pagination button -------------------------------- */}

        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-7 col-sm-12 mt-1">
              <span className="text-dark">
                Page {state.pageIndex + 1} of {pageCount}
              </span>
            </div>
            <div className="col-lg-5">
              <div className="mt-3 pb-5">
                <nav>
                  <ul className="pagination justify-content-center">
                    {createPagination()}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* ---------------------------------------end ---------------------------------- */}
      </>
    </>
  );
}

export default VendorDetails;
