



import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/Event.css"
import Calendar from "react-calendar";
import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";

function Event() {
  const formatDate = (date) => date.toLocaleDateString("en-CA");
  const [calendarData, setCalendarData] = useState([]);
  const [addData, setAddData] = useState({
    id: "",
    date: "",
    college_name: "",
    department: "",
    event_title: "",
    program_timing: "",
    status: "",
    branch_id: "",
    empid: "",
    staff_name: "",
    event_type: "",
    remarks: "",
    location: "",

  });
  const [showAddModal, setShowAddModal] = useState(false); // Add modal state
  const [showUpdateModal, setShowUpdateModal] = useState(false); // Update modal state
  const [responseDatas, setResponseDatas] = useState([]);
  const [empData, setEmpData] = useState([]);
  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});

  useEffect(() => {
    fetchCalendarData();
    fetchBranchData();
    // getprevillages();
    // gettoken();
  }, []);




  // const gettoken = async () => {
  //   const token = localStorage.getItem("token");

  //   try {
  //     const response = await axios.post(ApiUrl + "/get_detail", {
  //       token: token,
  //     });
  //     console.log("token detail from server:", response.data);

  //     setroleid(response.data.detail.rolename);

  //     localStorage.setItem("role_id", response.data.detail.rolename);

  //     const id = response.data.detail.rolename;

  //     if (id) { }
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };

  // const getprevillages = () => {
  //   const username = JSON.parse(localStorage.getItem("user_previllages"));
  //   console.log("tyhe username get by local stroage : ", username.modules);
  //   const total_previlages = username.modules;
  //   const new_enquiry = total_previlages.find((item) => item.name == "Event")
  //   console.log("the new_enquiry is : ", new_enquiry.actions);

  //   setprevillages(new_enquiry.actions)
  // }











  const fetchCalendarData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(ApiUrl + `/events`);

      if (response && response.status === 200) {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }
      setCalendarData(response.data);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  const fetchBranchData = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/Branch`);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fetchEmployeeData = async (branchId) => {
    try {
      const response = await axios.get(
        `${ApiUrl}/staff_based_on_branch/${branchId}`
      );
      setEmpData(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const handleBranchChange = (e) => {
    const selectedBranchId = e.target.value;
    setAddData((prevState) => ({
      ...prevState,
      branch_id: selectedBranchId,
      empid: "",
      staff_name: "",
      event_type: "",
    }));

    if (selectedBranchId) {
      fetchEmployeeData(selectedBranchId);
    } else {
      setEmpData([]);
    }
  };

  const handleStaffChange = (e) => {
    const selectedEmpId = e.target.value;
    const selectedStaff = empData.find((staff) => staff._id === selectedEmpId);

    if (selectedStaff) {
      setAddData((prevState) => ({
        ...prevState,
        empid: selectedEmpId,
        staff_name: selectedStaff.name,
      }));
    }
  };

  const handleSubmit = async () => {
    if (
      !addData.date ||
      !addData.college_name ||
      !addData.department ||
      !addData.program_timing ||
      !addData.event_title ||
      !addData.branch_id ||
      !addData.empid ||
      !addData.event_type ||
      !addData.status ||
      !addData.location
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    const eventData = {
      ...addData,
      staff_name: addData.empid, // Send the ObjectId instead of staff name
    };

    try {
      setLoading(true);
      if (addData.id) {
        await axios.put(`${ApiUrl}/events/${addData.id}`, eventData);
      } else {
        await axios.post(ApiUrl + `/events`, eventData);
      }
      fetchCalendarData();
      setShowAddModal(false);
      setShowUpdateModal(false);
      resetAddData();
      toast.success("Successfully Event Added!");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error posting/updating event:", error.message);
    }
  };

  const handleEventClick = (event) => {
    const selectedDate = event.currentTarget.getAttribute("data-event-date");

    if (selectedDate) {
      const eventsOnSameDate = calendarData.filter(
        (entry) => entry.date === selectedDate
      );

      if (eventsOnSameDate.length > 0) {
        setAddData(eventsOnSameDate); // Ensure addData is an array
        setCurrentEventIndex(0); // Reset to first event
      }
    }
  };

  const resetAddData = () => {
    setAddData({
      id: "",
      date: "",
      college_name: "",
      department: "",
      event_title: "",
      program_timing: "",
      status: "",
      branch_id: "",
      empid: "",
      staff_name: "",
      event_type: "",
      remarks: "",
      location: "",
    });
  };

  const getTileClassName = ({ date, view }) => {
    if (view === "month") {
      const formattedDate = formatDate(date);
      const data = calendarData.find((entry) => entry.date === formattedDate);
      if (data) {
        return `tile-${data.status.toLowerCase()}`;
      }
    }
    return "";
  };

  const getTileContent = ({ date, view }) => {
    if (view === "month") {
      const formattedDate = formatDate(date);
      const eventsOnSameDate = calendarData.filter(
        (entry) => entry.date === formattedDate
      );

      if (eventsOnSameDate.length > 0) {
        return (
          <div
            className="tile-content"
            onClick={handleEventClick}
            data-event-date={formattedDate}
          >
            <div style={{ fontSize: "12px", fontWeight: "bold" }}>
              {eventsOnSameDate[0].event_title}
              <br />
              <span style={{ fontSize: "10px", fontStyle: "italic" }}>
                {eventsOnSameDate[0].program_timing}
              </span>
            </div>
            {eventsOnSameDate.length > 1 && (
              <div className="more-events">
                <span style={{ fontSize: "10px", fontStyle: "italic" }}>
                  +{eventsOnSameDate.length - 1} more
                </span>
              </div>
            )}
          </div>
        );
      }
    }
    return null;
  };

  const getBranchName = (branchId) => {
    const branch = responseDatas.find((item) => item._id === branchId);
    return branch ? branch.branch_name : "";
  };

  const [currentEventIndex, setCurrentEventIndex] = useState(0);

  const handleNextEvent = () => {
    if (currentEventIndex < addData.length - 1) {
      setCurrentEventIndex(currentEventIndex + 1);
    }
  };

  const handlePrevEvent = () => {
    if (currentEventIndex > 0) {
      setCurrentEventIndex(currentEventIndex - 1);
    }
  };


  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  return (
    <>
      {loading === true ? (
        <div className="loading-overlay">
          <div className="loading-message">
            {/* <img
              className="loadinggif"
              src={require("../../assets/img/output-onlinegiftools.gif")}
            />{" "} */}
          </div>
        </div>
      ) : (
        <div className="d-none"></div>
      )}
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/attendance" className="text-secondary dashBoard_text">
              <b>Staff / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Event</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="">
        <div className="row">
          <div className="col-lg-10 d-flex">
            <h5 className="mt-1">Event</h5>
            <div className="">
              {previllages.add && (
                <button
                  className="btn btn-success add mx-3"
                  onClick={() => setShowAddModal(true)}
                >
                  Add
                </button>
              )}
            </div>
          </div>
          <div className="col-lg-2">
            <button
              className="btn btn-secondary"
              onClick={() => navigate("/eventTable")}
            >
              OverAll
            </button>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="event-container">
              <Calendar
                tileClassName={getTileClassName}
                tileContent={getTileContent}
              />
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card mt-4 p-3 d-flex">
              <div className="row ">
                <div className="col-lg-8">
                  <h6 className="fs-4">Event Details</h6>
                </div>
                <div className="col-lg-2">
                  <button
                    className="btn btn-secondary"
                    onClick={handlePrevEvent}
                  >
                    ᐊ
                  </button>
                </div>
                <div className="col-lg-2">
                  <button
                    className="btn btn-secondary"
                    onClick={handleNextEvent}
                  >
                    ᐅ
                  </button>
                </div>
              </div>

              {addData && addData.length > 0 ? (
                <div>
                  <div className="Event_card_Det text-white text-center p-2 mt-2">
                    <div className="row">
                      <div className="col-lg-1">
                        <i className="fa-solid fa-calendar"></i>
                      </div>
                      <div className="col-lg-11 text-start text-center">
                        {addData[currentEventIndex].date || "No Date Assigned"}
                      </div>
                    </div>
                  </div>

                  <div className="Event_card_Det text-white text-center p-2 mt-2">
                    <div className="row">
                      <div className="col-lg-1">
                        <i className="fa-solid fa-calendar-days"></i>
                      </div>
                      <div className="col-lg-11 text-start text-center">
                        {addData[currentEventIndex].event_title ||
                          "No Event Assigned"}
                      </div>
                    </div>
                  </div>

                  <div className="Event_card_Det text-white text-center p-2 mt-2">
                    <div className="row">
                      <div className="col-lg-1">
                        <i class="fa-solid fa-code-branch"></i>
                      </div>
                      <div className="col-lg-11 text-start text-center">
                        {addData[currentEventIndex].branch_id?.branch_name ||
                          "No Branch Assigned"}
                      </div>
                    </div>
                  </div>

                  <div className="Event_card_Det text-white text-center p-2 mt-2">
                    <div className="row">
                      <div className="col-lg-1">
                        <i className="fa-solid fa-user"></i>
                      </div>
                      <div className="col-lg-11 text-start text-center">
                        {addData[currentEventIndex].staff_name?.name ||
                          "No Staff Assigned"}
                      </div>
                    </div>
                  </div>

                  <div className="Event_card_Det text-white text-center p-2 mt-2">
                    <div className="row">
                      <div className="col-lg-1">
                        <i class="fa-solid fa-building-columns"></i>
                      </div>
                      <div className="col-lg-11 text-start text-center">
                        {addData[currentEventIndex].college_name ||
                          "No Branch Assigned"}
                      </div>
                    </div>
                  </div>

                  <div className="Event_card_Det text-white text-center p-2 mt-2">
                    <div className="row">
                      <div className="col-lg-1">
                        <i class="fa-solid fa-graduation-cap"></i>
                      </div>
                      <div className="col-lg-11 text-start text-center">
                        {addData[currentEventIndex].department ||
                          "No Branch Assigned"}
                      </div>
                    </div>
                  </div>

                  <div className="Event_card_Det text-white text-center p-2 mt-2">
                    <div className="row">
                      <div className="col-lg-1">
                        <i class="fa-solid fa-location-dot"></i>
                      </div>
                      <div className="col-lg-11 text-start text-center">
                        {addData[currentEventIndex].location ||
                          "No Branch Assigned"}
                      </div>
                    </div>
                  </div>

                  <div className="Event_card_Det text-white text-center p-2 mt-2">
                    <div className="row">
                      <div className="col-lg-1">
                        <i class="fa-solid fa-clock"></i>
                      </div>
                      <div className="col-lg-11 text-start text-center">
                        {addData[currentEventIndex].program_timing ||
                          "No Branch Assigned"}
                      </div>
                    </div>
                  </div>

                  <div className="Event_card_Det text-white text-center p-2 mt-2">
                    <div className="row">
                      <div className="col-lg-1">
                        <i class="fa-solid fa-book"></i>
                      </div>
                      <div className="col-lg-11 text-start text-center">
                        {addData[currentEventIndex].event_type ||
                          "No Branch Assigned"}
                      </div>
                    </div>
                  </div>

                  <div className="Event_card_Det text-white text-center p-2 mt-2">
                    <div className="row">
                      <div className="col-lg-1">
                        <i class="fa-solid fa-calendar-days"></i>
                      </div>
                      <div className="col-lg-11 text-start text-center">
                        {addData[currentEventIndex].status ||
                          "No Branch Assigned"}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="Event_card_Det text-white text-center p-2 mt-2">
                  No Event Data Selected
                </div>
              )}
            </div>
            
          </div>
        </div>
      </div>

      {/* Add Event Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    value={addData.date}
                    onChange={(e) =>
                      setAddData({ ...addData, date: e.target.value })
                    }
                    required
                  />
                  <label>Date</label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.branch_id}
                    onChange={handleBranchChange}
                    required
                  >
                    <option value="">Select Branch</option>
                    {responseDatas.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.branch_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.empid}
                    onChange={handleStaffChange}
                    required
                  >
                    <option value="">Select Staff</option>
                    {empData.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.empid} - {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="event_title"
                    value={addData.college_name}
                    onChange={(e) =>
                      setAddData({ ...addData, college_name: e.target.value })
                    }
                    required
                  />
                  <label>College Name</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="event_title"
                    value={addData.event_title}
                    onChange={(e) =>
                      setAddData({ ...addData, event_title: e.target.value })
                    }
                    required
                  />
                  <label>Event Title</label>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  name="event_title"
                  value={addData.department}
                  onChange={(e) =>
                    setAddData({ ...addData, department: e.target.value })
                  }
                  required
                />
                <label>Department & Year</label>
              </div>

              <div className="floating-label">
                <select
                  className="form-control"
                  name="event_type"
                  value={addData.event_type}
                  onChange={(e) =>
                    setAddData({ ...addData, event_type: e.target.value })
                  }
                  required
                >
                  <option value="">Select Event Type</option>
                  <option value="Seminar">Seminar</option>
                  <option value="Workshop">Workshop</option>
                  <option value="Corporate Trainings">
                    Corporate Trainings
                  </option>
                </select>
              </div>



              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  name="event_title"
                  value={addData.location}
                  onChange={(e) =>
                    setAddData({ ...addData, location: e.target.value })
                  }
                  required
                />
                <label>Location</label>
              </div>


              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  name="event_title"
                  value={addData.program_timing}
                  onChange={(e) =>
                    setAddData({ ...addData, program_timing: e.target.value })
                  }
                  required
                />
                <label>Program Time</label>
              </div>

              <div className="floating-label">
                <select
                  className="form-control"
                  name="status"
                  value={addData.status}
                  onChange={(e) =>
                    setAddData({ ...addData, status: e.target.value })
                  }
                  required
                >
                  <option value="">Select Status</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="InProgress">InProgress</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>

              {addData.status === "Cancelled" && (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="remarks"
                    value={addData.remarks || ""}
                    onChange={(e) =>
                      setAddData({ ...addData, remarks: e.target.value })
                    }
                    required
                  />
                  <label>Remarks</label>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowAddModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Update Event Modal */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    value={addData.date}
                    onChange={(e) =>
                      setAddData({ ...addData, date: e.target.value })
                    }
                    required
                  />
                  <label>Date</label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.branch_id}
                    onChange={handleBranchChange}
                    required
                  >
                    <option value="">Select Branch</option>
                    {responseDatas.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.branch_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.empid}
                    onChange={handleStaffChange}
                    required
                  >
                    <option value="">Select Staff</option>
                    {empData.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.empid} - {item.name}{" "}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="event_title"
                    value={addData.college_name}
                    onChange={(e) =>
                      setAddData({ ...addData, college_name: e.target.value })
                    }
                    required
                  />
                  <label>College Name</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="event_title"
                    value={addData.event_title}
                    onChange={(e) =>
                      setAddData({ ...addData, event_title: e.target.value })
                    }
                    required
                  />
                  <label>Event Title</label>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  name="event_title"
                  value={addData.department}
                  onChange={(e) =>
                    setAddData({ ...addData, department: e.target.value })
                  }
                  required
                />
                <label>Department</label>
              </div>

              <div className="floating-label">
                <select
                  className="form-select"
                  name="event_type"
                  value={addData.event_type}
                  onChange={
                    (e) =>
                      setAddData({ ...addData, event_type: e.target.value }) // Update event type
                  }
                  required
                >
                  <option value="">Select Event Type</option>
                  <option value="Seminar">Seminar</option>
                  <option value="Workshop">Workshop</option>
                  <option value="Corporate Trainings">
                    Corporate Trainings
                  </option>
                </select>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  name="event_title"
                  value={addData.program_timing}
                  onChange={(e) =>
                    setAddData({ ...addData, program_timing: e.target.value })
                  }
                  required
                />
                <label>Program Time</label>
              </div>

              <div className="floating-label">
                <select
                  className="form-control"
                  name="status"
                  value={addData.status}
                  onChange={(e) =>
                    setAddData({ ...addData, status: e.target.value })
                  }
                  required
                >
                  <option value="">Select Status</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="InProgress">InProgress</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowUpdateModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Event;
