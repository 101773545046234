// import React, { useMemo, useState, useEffect } from "react";
// import axios from "axios";
// import { Modal, Button } from "react-bootstrap";
// import ApiUrl from "./../Environment/ApiUrl";
// import "../../assets/css/Common.css";
// import { Link, useNavigate } from "react-router-dom";
// import Select from "react-select";
// import styled from "styled-components";
// import {
//   useTable,
//   useFilters,
//   useGlobalFilter,
//   useSortBy,
//   usePagination,
// } from "react-table";
// import { createPortal } from "react-dom";
// import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

// const TableContainer = styled.div`
//   padding: 20px;
//   overflow-x: auto;
// `;

// const Table = styled.table`
//   width: 100%;
//   border-collapse: collapse;
//   border: 1px solid #ddd;

//   // Apply striped rows styling
//   tr:nth-child(even) {
//     background-color: #ffffff; /* Light gray for even rows */
//   }

//   tr:nth-child(odd) {
//     background-color: #f2f2f2; /* White for odd rows */
//   }
// `;

// const Th = styled.th`
//   padding: 10px;
//   background-color: #ffffff;
//   text-align: left;
//   cursor: pointer;
//   position: relative;
// `;

// const Td = styled.td`
//   padding: 10px;
//   // border: 1px solid #ddd;
// `;

// function GlobalFilter({ globalFilter, setGlobalFilter }) {
//   return (
//     <div style={{ marginBottom: "10px" }}>
//       <input
//         value={globalFilter || ""}
//         onChange={(e) => setGlobalFilter(e.target.value)}
//         placeholder="Search all columns..."
//         style={{
//           padding: "5px",
//           border: "1px solid #ddd",
//           borderRadius: "4px",
//           width: "100%",
//         }}
//       />
//     </div>
//   );
// }
// // Default Column Filter (Icon-based)
// const DefaultColumnFilter = ({
//   column: { filterValue, setFilter, preFilteredRows, id },
// }) => {
//   const [isActive, setIsActive] = useState(false);
//   const [selectedValue, setSelectedValue] = useState(null);
//   const options = useMemo(() => {
//     const uniqueValues = Array.from(
//       new Set(preFilteredRows.map((row) => row.values[id]))
//     ).map((value) => ({ label: value, value }));
//     return [{ label: "All", value: "" }, ...uniqueValues];
//   }, [id, preFilteredRows]);

//   // Handle icon click
//   const handleIconClick = () => {
//     setIsActive(!isActive);
//   };

//   const handleMultiSelectChange = (selectedOptions) => {
//     const values = selectedOptions
//       ? selectedOptions.map((option) => option.value)
//       : [];
//     setFilter(values.length > 0 ? values : undefined);
//   };

//   return (
//     <div style={{ alignItems: "center" }}>
//       <div>
//         <i
//           className="fa-solid fa-ellipsis-vertical"
//           onClick={handleIconClick}
//           style={{
//             cursor: "pointer",
//             fontSize: "20px",
//             color: "#AC0000",
//           }}
//         ></i>
//       </div>
//       <div>
//         {isActive &&
//           createPortal(
//             <div
//               style={{
//                 position: "fixed",
//                 zIndex: 9999,
//                 left: "50%",
//                 top: "50%",
//                 width: "30%",
//                 transform: "translate(-50%, -50%)",
//               }}
//             >
//               <div
//                 style={{
//                   position: "relative",
//                   background: "white",
//                   padding: "20px",
//                   borderRadius: "8px",
//                   boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
//                   backgroundColor: "#A70000",
//                 }}
//               >
//                 <Select
//                   options={options}
//                   isMulti
//                   value={options.filter((option) =>
//                     filterValue?.includes(option.value)
//                   )}
//                   onChange={handleMultiSelectChange}
//                   isClearable
//                   isSearchable
//                   placeholder={`Filter ${id}`}
//                   styles={{
//                     menu: (provided) => ({
//                       ...provided,
//                       zIndex: 9999,
//                     }),
//                   }}
//                 />
//                 <button
//                   style={{
//                     marginTop: "10px",
//                     marginLeft: "150px",
//                     padding: "5px 10px",
//                     background: "#007bff",
//                     color: "white",
//                     border: "none",
//                     borderRadius: "4px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => setIsActive(false)} // Close the popup
//                 >
//                   Close
//                 </button>
//               </div>
//             </div>,
//             document.body
//           )}
//       </div>
//     </div>
//   );
// };

// function Ongoing() {
//   const [showModal, setShowModal] = useState(false);
//   const [responseData, setResponseData] = useState([]);

//   const data = useMemo(() => {
//     return responseData
//       .filter((item) => item.quotation_status === "Converted")
//       .map((item) => ({
//         _id: item._id,
//         branch_id: item.branch_id,
//         purpose_enquiry: item.purpose_enquiry,
//         name: item.name,
//         company_name: item.company_name,
//         designation: item.designation,
//         official_email: item.official_email,
//         alternate_email: item.alternate_email,
//         official_mobile: item.official_mobile,
//         alternate_mobile: item.alternate_mobile,
//         location: item.location,
//         notes: item.notes,
//         source_type: item.source_type,
//         enquiry_date: item.enquiry_date,
//         followups_status: item.followups_status,
//         assign_staff: item.assign_staff,
//         contact_type: item.contact_type,
//         end_time: item.end_time,
//         followups_time: item.followups_time,
//         start_time: item.start_time,
//         status_date: item.status_date,
//         start_date: item.start_date,
//         end_date: item.end_date,
//         quotation_date: item.quotation_date,
//         quotation_status: item.quotation_status,
//         expected_status: item.expected_status,
//         purchaseDate: item.purchaseDate,
//         purchaseEmail: item.purchaseEmail,
//         netValue: item.netValue,
//         purchaseTax: item.purchaseTax,
//         purchaseProjectCode: item.purchaseProjectCode,
//         rejectedDate: item.rejectedDate,
//         rejectionReason: item.rejectionReason,
//       }));
//   }, [responseData]);

//   const columns = useMemo(
//     () => [
//       {
//         Header: "S.No",
//         id: "serialNo",
//         Cell: ({ row }) => {
//           return row.index + 1;
//         },
//       },
//       {
//         Header: "Name",
//         accessor: "name",
//         Filter: DefaultColumnFilter,
//         filter: "multiSelectFilter",
//       },
//       {
//         Header: "Company Name",
//         accessor: "company_name",
//         Filter: DefaultColumnFilter,
//         filter: "multiSelectFilter",
//       },
//       {
//         Header: "Enquiry Date",
//         accessor: "enquiry_date",
//         Filter: DefaultColumnFilter,
//         filter: "multiSelectFilter",
//       },

//       {
//         Header: "Quotation Status",
//         accessor: "quotation_status",
//         Filter: DefaultColumnFilter,
//         filter: "multiSelectFilter",
//         width: 120,
//         height: 10,
//         Cell: ({ value }) => {
//           const statusClass = getStatusLabelClass(value);
//           return (
//             <span
//               className={`${statusClass}`}
//               style={{
//                 display: "inline-block",
//                 width: "120px",
//                 height: "20px",
//                 lineHeight: "20px",
//                 textAlign: "center",
//               }}
//             >
//               {value}
//             </span>
//           );
//         },
//       },
//       {
//         Header: "Quotation Last Sent",
//         accessor: "quotation_date",
//         Cell: ({ value }) => {
//           if (!value) return "";
//           const date = new Date(value);
//           return `${date.getDate()}-${
//             date.getMonth() + 1
//           }-${date.getFullYear()}`;
//         },
//         Filter: DefaultColumnFilter,
//         filter: "multiSelectFilter",
//       },
//     ],
//     []
//   );

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow,
//     state,
//     setGlobalFilter,
//     setPageSize,
//     page,
//     canPreviousPage,
//     canNextPage,
//     pageCount,
//     gotoPage,
//   } = useTable(
//     {
//       columns,
//       data,
//       initialState: { pageSize: 10 },
//       defaultColumn: { Filter: DefaultColumnFilter },
//       filterTypes: {
//         multiSelectFilter: (rows, columnIds, filterValue) => {
//           if (!filterValue || filterValue.length === 0) {
//             return rows;
//           }
//           return rows.filter((row) => {
//             const rowValue = row.values[columnIds[0]];
//             const rowValueStr = String(rowValue);
//             return filterValue.some((filterVal) =>
//               rowValueStr.includes(String(filterVal))
//             );
//           });
//         },
//       },
//     },
//     useFilters,
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(ApiUrl + `/expected`);
//       setResponseData(response.data.reverse());
//       console.log(
//         "Response from server student data:",
//         response.data.reverse()
//       );
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const getStatusLabelClass = (status) => {
//     switch (status) {
//       case "Converted":
//         return "bg-success1 text-white1";
//       case "Rejected":
//         return "bg-danger1 text-white1";
//       case "Pending":
//         return "bg-warning1 text-white1";
//       default:
//         return "";
//     }
//   };

//   const createPagination = () => {
//     let liTag = [];
//     let totalPages = pageCount; // Total pages
//     let currentPage = state.pageIndex;
//     let active;

//     // Previous Page Button
//     liTag.push(
//       <li
//         className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
//         key="prev"
//       >
//         <button
//           className="page-link"
//           onClick={() => gotoPage(currentPage - 1)}
//           disabled={!canPreviousPage}
//         >
//           <FaAngleLeft /> Prev
//         </button>
//       </li>
//     );

//     // Always show first page
//     if (totalPages > 1) {
//       liTag.push(
//         <li
//           className={`page-item ${currentPage === 0 ? "active" : ""}`}
//           key={0}
//         >
//           <button className="page-link" onClick={() => gotoPage(0)}>
//             1
//           </button>
//         </li>
//       );
//     }

//     // Show second page if total pages > 2
//     if (totalPages > 2) {
//       liTag.push(
//         <li
//           className={`page-item ${currentPage === 1 ? "active" : ""}`}
//           key={1}
//         >
//           <button className="page-link" onClick={() => gotoPage(1)}>
//             2
//           </button>
//         </li>
//       );
//     }

//     // Add "..." before current page group
//     if (currentPage > 3) {
//       liTag.push(
//         <li className="page-item disabled" key="dot-prev">
//           <span className="page-link">...</span>
//         </li>
//       );
//     }

//     // Show 3 pages around current page
//     for (
//       let i = Math.max(2, currentPage - 1);
//       i <= Math.min(totalPages - 3, currentPage + 1);
//       i++
//     ) {
//       active = currentPage === i ? "active" : "";
//       liTag.push(
//         <li className={`page-item ${active}`} key={i}>
//           <button className="page-link" onClick={() => gotoPage(i)}>
//             {i + 1}
//           </button>
//         </li>
//       );
//     }

//     // Add "..." after current page group
//     if (currentPage < totalPages - 4) {
//       liTag.push(
//         <li className="page-item disabled" key="dot-next">
//           <span className="page-link">...</span>
//         </li>
//       );
//     }

//     // Always show last two pages if totalPages > 2
//     if (totalPages > 2) {
//       liTag.push(
//         <li
//           className={`page-item ${
//             currentPage === totalPages - 2 ? "active" : ""
//           }`}
//           key={totalPages - 2}
//         >
//           <button
//             className="page-link"
//             onClick={() => gotoPage(totalPages - 2)}
//           >
//             {totalPages - 1}
//           </button>
//         </li>
//       );
//     }

//     if (totalPages > 1) {
//       liTag.push(
//         <li
//           className={`page-item ${
//             currentPage === totalPages - 1 ? "active" : ""
//           }`}
//           key={totalPages - 1}
//         >
//           <button
//             className="page-link"
//             onClick={() => gotoPage(totalPages - 1)}
//           >
//             {totalPages}
//           </button>
//         </li>
//       );
//     }

//     // Next Page Button
//     liTag.push(
//       <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
//         <button
//           className="page-link"
//           onClick={() => gotoPage(currentPage + 1)}
//           disabled={!canNextPage}
//         >
//           Next <FaAngleRight />
//         </button>
//       </li>
//     );

//     return liTag;
//   };

//   // const {id} = useParams

//   const handlePlusIcon = (id) => {
//     navigate(`/ongoingbill/${id}`);
//   };

//   const handleInfoIcon = (id) => {
//     navigate(`/ongoinginfo/${id}`);
//   };

//   return (
//     <>
//       {/* <div className="top"> */}
//       <div className="" style={{ marginTop: "-80px" }}>
//         <div className="row mb-3">
//           <div className="col-lg-12">
//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Dashboard / </b>
//             </Link>
//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Projects / </b>
//             </Link>
//             <Link to="" className="text-danger dashBoard_text">
//               <b>OnGoing</b>
//             </Link>
//           </div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-lg-10 d-flex">
//           <h6 className="mt-2 bold">OnGoing</h6>
//         </div>
//       </div>

//       {/* ------------ search and per page ----------------------------*/}

//       <div className="mb-3 mt-3">
//         <div className="row">
//           <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//             <label className="form-label text-dark mt-2">Show : </label>
//             <div>
//               <select
//                 className="form-control w-100 mx-2"
//                 value={state.pageSize}
//                 onChange={(e) => setPageSize(Number(e.target.value))}
//               >
//                 {[10, 20, 50].map((pageSize) => (
//                   <option key={pageSize} value={pageSize}>
//                     {pageSize}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </div>
//           <div className="col-lg-7 col-md-4 col-sm-1"></div>
//           <div className="col-lg-3 col-md-4 col-sm-7">
//             <div className="w-full flex-1">
//               <form>
//                 <div className="relative">
//                   <input
//                     className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
//                     placeholder="Search ..."
//                     type="search"
//                     value={state.globalFilter || ""} // Use the globalFilter value
//                     onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
//                   />
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//       <TableContainer>
//         <Table {...getTableProps()}>
//           <thead>
//             {headerGroups.map((headerGroup) => (
//               <tr {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map((column) => (
//                   <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
//                     <div className="d-flex">
//                       <div className="mx-2">{column.render("Header")}</div>
//                       <div>
//                         {column.canFilter ? column.render("Filter") : null}
//                       </div>
//                       <div className="mx-1">
//                         {column.isSorted
//                           ? column.isSortedDesc
//                             ? " 🔽"
//                             : " 🔼"
//                           : ""}
//                       </div>
//                     </div>
//                   </Th>
//                 ))}
//                 <Th className="text-center">Action</Th>
//               </tr>
//             ))}
//           </thead>
//           <tbody {...getTableBodyProps()}>
//             {page.map((row) => {
//               prepareRow(row);
//               return (
//                 <tr {...row.getRowProps()}>
//                   {row.cells.map((cell) => (
//                     <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
//                   ))}
//                   <Td className="text-center">
//                     <button className="btn custom-btn-update btn-sm mx-1">
//                       <i className="fa-solid fa-pen-to-square"></i>
//                     </button>

//                     <button className="btn custom-btn-delete btn-sm mx-1">
//                       <i className="fa-solid fa-trash-can"></i>
//                     </button>

//                     <button
//                       className="btn custom-btn-plus btn-sm mx-1"
//                       onClick={() => handlePlusIcon(row.original._id)}
//                     >
//                       <i className="fa-solid fa-circle-plus"></i>
//                     </button>

//                     <button className="btn custom-btn-info btn-sm mx-1" onClick={() => handleInfoIcon(row.original._id)}>
//                       <i className="fa-solid fa-circle-info"></i>
//                     </button>
//                   </Td>
//                 </tr>
//               );
//             })}
//           </tbody>
//         </Table>
//       </TableContainer>

//       {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

//       <div className="insermodal">
//         <Modal
//           className="addmodals"
//           show={showModal}
//           onHide={() => setShowModal(false)}
//         >
//           <Modal.Header closeButton>
//             <Modal.Title>OnGoing Projects</Modal.Title>
//           </Modal.Header>

//           <form>
//             <Modal.Body>
//               <div className="row">
//                 <div className="col-lg-6">
//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="tel"
//                       placeholder=""
//                       required
//                     />
//                     <label className="">Branch</label>
//                   </div>

//                   <div className="floating-label">
//                     <select className="form-control" required>
//                       <option value="" disabled selected>
//                         Select Option
//                       </option>
//                       <option value="Project">Project</option>
//                       <option value="Sales">Sales</option>
//                       <option value="Service">Service</option>
//                     </select>
//                     <label>Select Types</label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="tel"
//                       placeholder=""
//                       required
//                     />
//                     <label className="">Name</label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="tel"
//                       placeholder=""
//                       required
//                     />
//                     <label className="">Company Name</label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="tel"
//                       placeholder=""
//                       required
//                     />
//                     <label className="">Designation</label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="tel"
//                       placeholder=""
//                       required
//                     />
//                     <label className="">Official Email</label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="tel"
//                       placeholder=""
//                       required
//                     />
//                     <label className="">Alternate Email</label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="tel"
//                       placeholder=""
//                       required
//                     />
//                     <label className="">Official Mobile</label>
//                   </div>
//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="tel"
//                       placeholder=""
//                       required
//                     />
//                     <label className="">Alternate Mobile</label>
//                   </div>
//                 </div>
//                 <div className="col-lg-6 ">
//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="tel"
//                       placeholder=""
//                       required
//                     />
//                     <label className="">Location</label>
//                   </div>
//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="tel"
//                       placeholder=""
//                       required
//                     />
//                     <label className="">Notes</label>
//                   </div>
//                   <div className="floating-label">
//                     <select className="form-control" required>
//                       <option value="">Select Option</option>
//                       <option value="optionA">Google Ad</option>
//                       <option value="optionB">Website</option>
//                       <option value="optionC">Facebook</option>
//                       <option value="optionC">Instagram</option>
//                       <option value="optionC">Linked In</option>
//                       <option value="optionC">Referral</option>
//                     </select>
//                     <label>Select Source Type</label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="date"
//                       placeholder=""
//                       required
//                     />
//                     <label className="">Enquiry Date</label>
//                   </div>

//                   <div className="floating-label">
//                     <select className="form-control" required>
//                       <option value="">Select Option</option>
//                       <option value="optionA">Business</option>
//                       <option value="optionB">Study</option>
//                       <option value="optionC">Work</option>
//                     </select>
//                     <label>Select Purpose Enquiry</label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="date"
//                       placeholder=""
//                       required
//                     />
//                     <label className="">Send Quotation</label>
//                   </div>

//                   <div className="floating-label">
//                     <select className="form-control" required>
//                       <option value="">Select Option</option>
//                       <option value="Completed">Completed</option>
//                     </select>
//                     <label>Assign Staff</label>
//                   </div>

//                   <div className="floating-label">
//                     <select className="form-control" required>
//                       <option value="">Select Option</option>
//                       <option value="Metals">Metals</option>
//                       <option value="Plastics">Plastics</option>
//                       <option value="Foams">Foams</option>
//                     </select>
//                     <label>Select Material</label>
//                   </div>

//                   <div className="floating-label">
//                     <select className="form-control" required>
//                       <option value="">Select Option</option>
//                       <option value="Completed">Report</option>
//                     </select>
//                     <label>Project Status</label>
//                   </div>

//                   <div className="floating-label">
//                     <select className="form-control" required>
//                       <option value="">Select Option</option>
//                       <option value="Completed">Completed</option>
//                     </select>
//                     <label>Select Status</label>
//                   </div>
//                 </div>
//               </div>
//             </Modal.Body>

//             <Modal.Footer>
//               <Button
//                 className="cancelbut"
//                 variant="secondary"
//                 onClick={() => setShowModal(false)}
//               >
//                 Cancel
//               </Button>
//               <Button className="submitbut" type="submit" variant="primary">
//                 Submit
//               </Button>
//             </Modal.Footer>
//           </form>
//         </Modal>
//       </div>

//       {/* _------------------------------pagination button -------------------------------- */}
//       <div className="mt-3 pb-5">
//         <div className="row">
//           <div className="col-lg-7 col-sm-12 mt-1">
//             <span className="text-dark">
//               Page {state.pageIndex + 1} of {pageCount}
//             </span>
//           </div>
//           <div className="col-lg-5">
//             <div className="mt-3 pb-5">
//               <nav>
//                 <ul className="pagination justify-content-center">
//                   {createPagination()}
//                 </ul>
//               </nav>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Ongoing;

import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ApiUrl from "./../Environment/ApiUrl";
import "../../assets/css/Common.css";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import { toast } from "react-toastify";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function Ongoing() {
  const [showModal, setShowModal] = useState(false);
  const [responseData, setResponseData] = useState([]);

  const data = useMemo(() => {
    return responseData
      .filter((item) => item.quotation_status === "Converted")
      .map((item) => ({
        _id: item._id,
        branch_id: item.branch_id,
        purpose_enquiry: item.purpose_enquiry,
        name: item.name,
        company_name: item.company_name,
        designation: item.designation,
        official_email: item.official_email,
        alternate_email: item.alternate_email,
        official_mobile: item.official_mobile,
        alternate_mobile: item.alternate_mobile,
        location: item.location,
        notes: item.notes,
        source_type: item.source_type,
        enquiry_date: item.enquiry_date,
        followups_status: item.followups_status,
        assign_staff: item.assign_staff,
        contact_type: item.contact_type,
        end_time: item.end_time,
        followups_time: item.followups_time,
        start_time: item.start_time,
        status_date: item.status_date,
        start_date: item.start_date,
        end_date: item.end_date,
        quotation_date: item.quotation_date,
        quotation_status: item.quotation_status,
        expected_status: item.expected_status,
        purchaseDate: item.purchaseDate,
        purchaseEmail: item.purchaseEmail,
        netValue: item.netValue,
        purchaseTax: item.purchaseTax,
        purchaseProjectCode: item.purchaseProjectCode,
        rejectedDate: item.rejectedDate,
        rejectionReason: item.rejectionReason,
      }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Enquiry Date",
        accessor: "enquiry_date",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },

      {
        Header: "Quotation Status",
        accessor: "quotation_status",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        width: 120,
        height: 10,
        Cell: ({ value }) => {
          const statusClass = getStatusLabelClass(value);
          return (
            <span
              className={`${statusClass}`}
              style={{
                display: "inline-block",
                width: "120px",
                height: "20px",
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Quotation Last Sent",
        accessor: "quotation_date",
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          return `${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()}`;
        },
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + `/expected`);
      setResponseData(response.data.reverse());
      console.log(
        "Response from server student data:",
        response.data.reverse()
      );
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const getStatusLabelClass = (status) => {
    switch (status) {
      case "Converted":
        return "bg-success1 text-white1";
      case "Rejected":
        return "bg-danger1 text-white1";
      case "Pending":
        return "bg-warning1 text-white1";
      default:
        return "";
    }
  };

  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  // const {id} = useParams

  const handlePlusIcon = (id) => {
    navigate(`/ongoingbill/${id}`);
  };

  const handleInfoIcon = (id) => {
    navigate(`/ongoinginfo/${id}`);
  };

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");

  const handleOpenModal = (_id, name) => {
    console.log(" the id", _id);
    setdeleteid(_id);
    setdeletename(name);
    setDeleteShowModals(true);
  };
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(ApiUrl + `/expected/${getdeleteid}`);
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  return (
    <>
      {/* <div className="top"> */}
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Projects / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>OnGoing</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 d-flex">
          <h6 className="mt-2 bold">OnGoing</h6>
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    <button className="btn custom-btn-update btn-sm mx-1">
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button
                      className="btn custom-btn-delete btn-sm mx-1"
                      onClick={() =>
                        handleOpenModal(row.original._id, row.original.name)
                      }
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                    <button
                      className="btn custom-btn-plus btn-sm mx-1"
                      onClick={() => handlePlusIcon(row.original._id)}
                    >
                      <i className="fa-solid fa-circle-plus"></i>
                    </button>

                    <button
                      className="btn custom-btn-info btn-sm mx-1"
                      onClick={() => handleInfoIcon(row.original._id)}
                    >
                      <i className="fa-solid fa-circle-info"></i>
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

      <div className="insermodal">
        <Modal
          className="addmodals"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>OnGoing Projects</Modal.Title>
          </Modal.Header>

          <form>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-6">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      required
                    />
                    <label className="">Branch</label>
                  </div>

                  <div className="floating-label">
                    <select className="form-control" required>
                      <option value="" disabled selected>
                        Select Option
                      </option>
                      <option value="Project">Project</option>
                      <option value="Sales">Sales</option>
                      <option value="Service">Service</option>
                    </select>
                    <label>Select Types</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      required
                    />
                    <label className="">Name</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      required
                    />
                    <label className="">Company Name</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      required
                    />
                    <label className="">Designation</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      required
                    />
                    <label className="">Official Email</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      required
                    />
                    <label className="">Alternate Email</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      required
                    />
                    <label className="">Official Mobile</label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      required
                    />
                    <label className="">Alternate Mobile</label>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      required
                    />
                    <label className="">Location</label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder=""
                      required
                    />
                    <label className="">Notes</label>
                  </div>
                  <div className="floating-label">
                    <select className="form-control" required>
                      <option value="">Select Option</option>
                      <option value="optionA">Google Ad</option>
                      <option value="optionB">Website</option>
                      <option value="optionC">Facebook</option>
                      <option value="optionC">Instagram</option>
                      <option value="optionC">Linked In</option>
                      <option value="optionC">Referral</option>
                    </select>
                    <label>Select Source Type</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      placeholder=""
                      required
                    />
                    <label className="">Enquiry Date</label>
                  </div>

                  {/* <div className="floating-label">
                    <select className="form-control" required>
                      <option value="">Select Option</option>
                      <option value="optionA">Business</option>
                      <option value="optionB">Study</option>
                      <option value="optionC">Work</option>
                    </select>
                    <label>Select Purpose Enquiry</label>
                  </div> */}

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      placeholder=""
                      required
                    />
                    <label className="">Send Quotation</label>
                  </div>

                  <div className="floating-label">
                    <select className="form-control" required>
                      <option value="">Select Option</option>
                      <option value="Completed">Completed</option>
                    </select>
                    <label>Assign Staff</label>
                  </div>

                  <div className="floating-label">
                    <select className="form-control" required>
                      <option value="">Select Option</option>
                      <option value="Metals">Metals</option>
                      <option value="Plastics">Plastics</option>
                      <option value="Foams">Foams</option>
                    </select>
                    <label>Select Material</label>
                  </div>

                  <div className="floating-label">
                    <select className="form-control" required>
                      <option value="">Select Option</option>
                      <option value="Completed">Report</option>
                    </select>
                    <label>Project Status</label>
                  </div>

                  <div className="floating-label">
                    <select className="form-control" required>
                      <option value="">Select Option</option>
                      <option value="Completed">Completed</option>
                    </select>
                    <label>Select Status</label>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                className="cancelbut"
                variant="secondary"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button className="submitbut" type="submit" variant="primary">
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h6 className="mx-1">New Enquiry : </h6> <h6>{getdeletename} </h6>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ongoing;
