// import axios from "axios";
// import { useState, useEffect, useRef } from "react";
// import ApiUrl from "../Environment/ApiUrl";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { Modal, Button } from "react-bootstrap";

// function AddSalesInvoice() {
//   const navigate = useNavigate();
//   const [productsMap, setProductsMap] = useState([]);
//   const [customerMap, setCustomerMap] = useState([]);
//   const [filteredCustomers, setFilteredCustomers] = useState([]);
//   const [stockMap, setStockMap] = useState([]);
//   const [responseDatas, setResponseDatas] = useState([]);
//   const [paymentHistory, setPaymentHistory] = useState([]);
//   const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
//   const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
//   const dropdownRef = useRef(null);
//   const [newCustomer, setNewCustomer] = useState({
//     branch_id: "",
//     customer_id: "",
//     name: "",
//     mobile: "",
//     email: "",
//     company_name: "",
//     door_no: "",
//     street_name: "",
//     area: "",
//     pin_code: "",
//     gst_no: "",
//     notes: "",
//     status: "",
//   });

//   const [addData, setAddData] = useState({
//     sales_order_no: "",
//     date: "",
//     client_name: "",
//     company_name: "",
//     customer_id: "", // Add this field
//     door_no: "",
//     street_name: "",
//     area: "",
//     pin_code: "",
//     gst_no: "",
//     notes: "",
//     status: "",
//     email: "",
//     mobile: "",
//     paid_till_date: "0",
//     payment_due_date: "",
//     next_due_date: "",
//     person_name: "",
//     contact: "",
//     items: [],
//     subtotal: "0.00",
//     gst: "0.00",
//     discount: "0.00",
//     nettotal: "0.00",
//     paytype: "",
//     paidby: "",
//     paid: "0.00",
//     balance: "0.00",
//     payment_history: [],
//     branch: "",
//     branch_id:'',
//   });

//   const [productForm, setProductForm] = useState({
//     products: "",
//     quantity: "1",
//     rate: "",
//     tax: "",
//     value: "0.00",
//     total: "0.00",
//     availableQuantity: "0",
//   });

//   // Close dropdown when clicking outside
//   useEffect(() => {
//     function handleClickOutside(event) {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setShowCustomerDropdown(false);
//       }
//     }

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   useEffect(() => {
//     // Fetch the latest sales order number
//     axios
//       .get(ApiUrl + "/salesorder/latest")
//       .then((response) => {
//         setAddData((prevData) => ({
//           ...prevData,
//           sales_order_no: response.data.sales_order_no,
//           date: new Date().toISOString().split("T")[0],
//         }));
//       })
//       .catch((error) =>
//         console.error("Error fetching sales order number:", error)
//       );

//     // Fetch products and stock data
//     fetchDatas();
//   }, []);

//   useEffect(() => {
//     getNextCustomerId();
//   }, []);

//   const [branches, setBranches] = useState([]);

//   const getNextCustomerId = async () => {
//     try {
//       // First try to get from API endpoint
//       const response = await axios.get(`${ApiUrl}/generate_customer`);
//       console.log("the curster id ", response.data.customer_id);
//       setNewCustomer(response.data.customer_id);
//       setNewCustomer({
//         ...newCustomer,
//         customer_id: response.data.customer_id,
//       });
//     } catch (error) {
//       console.error("Error fetching next customer ID:", error.message);
//     }
//   };

//   useEffect(() => {
//     fetchBranches();
//   }, []);
//   // GET request - Fetch all branches
//   const fetchBranches = async () => {
//     try {
//       const response = await axios.get(`${ApiUrl}/branch`);
//       setBranches(response.data);
//     } catch (error) {
//       console.error("Error fetching branches:", error.message);
//       toast.error("Failed to fetch branches: " + error.message);
//     }
//   };

//   useEffect(() => {
//     const items = responseDatas.map((item) => ({
//       products: item.products || "",
//       quantity: parseFloat(item.quantity) || 0,
//       rate: parseFloat(item.rate) || 0,
//       tax: parseFloat(item.tax) || 0,
//       value: parseFloat(item.value) || 0,
//       total: parseFloat(item.total).toFixed(2),
//     }));

//     // Calculate subtotal without tax
//     const subtotal = items
//       .reduce((sum, item) => {
//         const quantity = parseFloat(item.quantity) || 0;
//         const rate = parseFloat(item.rate) || 0;
//         return sum + quantity * rate;
//       }, 0)
//       .toFixed(2);

//     setAddData({
//       ...addData,
//       items,
//       subtotal,
//       nettotal: calculateNetTotal(subtotal, addData.gst, addData.discount),
//       balance: calculateBalance(
//         calculateNetTotal(subtotal, addData.gst, addData.discount),
//         addData.paid
//       ),
//     });
//   }, [responseDatas]);

//   const fetchDatas = async () => {
//     try {
//       const response = await axios.get(ApiUrl + "/product");
//       console.log("Response from products:", response.data);
//       setProductsMap(response.data);

//       const responsedd = await axios.get(ApiUrl + "/customer");
//       console.log("Response from customers:", responsedd.data);
//       setCustomerMap(responsedd.data);
//       setFilteredCustomers(responsedd.data);

//       const responsed = await axios.get(ApiUrl + "/stock");
//       console.log("Response from stock:", responsed.data);
//       setStockMap(responsed.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   // Filter customers based on input
//   const handleCustomerFilter = (e) => {
//     const searchText = e.target.value;
//     setAddData({
//       ...addData,
//       client_name: searchText,
//     });

//     if (searchText.trim() === "") {
//       setFilteredCustomers(customerMap);
//     } else {
//       const filtered = customerMap.filter((customer) =>
//         customer.name.toLowerCase().includes(searchText.toLowerCase())
//       );
//       setFilteredCustomers(filtered);
//     }

//     setShowCustomerDropdown(true);
//   };

//   // Select customer from dropdown
//   const selectCustomer = (customer) => {
//     setAddData({
//       ...addData,
//       client_name: customer.name,
//       customer_id: customer.customer_id, // Add this line to capture the ID
//       email: customer.email || "",
//       mobile: customer.mobile || "",
//       company_name: customer.company_name || "",
//       door_no: customer.door_no || "",
//       street_name: customer.street_name || "",
//       area: customer.area || "",
//       pin_code: customer.pin_code || "",
//       gst_no: customer.gst_no || "",
//       notes: customer.notes || "",
//       status: customer.status || "",
//       branch_id:customer.branch_id[0].branch_name ||""
//     });

//     setShowCustomerDropdown(false);
//   };

//   const handleNewCustomerChange = (e) => {
//     const { name, value } = e.target;
//     setNewCustomer({
//       ...newCustomer,
//       [name]: value,
//     });
//   };

//   const submitNewCustomer = async (e) => {
//     e.preventDefault(); // Prevent default form submission

//     try {
//       const response = await axios.post(ApiUrl + "/customer", newCustomer);
//       toast.success("Customer added successfully");
//       console.log("Customer added successfully", response.data);

//       // Add new customer to customer map
//       const updatedCustomerMap = [...customerMap, response.data];
//       setCustomerMap(updatedCustomerMap);
//       setFilteredCustomers(updatedCustomerMap);

//       // Update the main form with the newly added customer data
//       setAddData({
//         ...addData,

//         client_name: response.data.name,
//         email: response.data.email || "",
//         mobile: response.data.mobile || "",
//         company_name: response.data.company_name || "",
//         door_no: response.data.door_no || "",
//         street_name: response.data.street_name || "",
//         area: response.data.area || "",
//         pin_code: response.data.pin_code || "",
//         gst_no: response.data.gst_no || "",
//         notes: response.data.notes || "",
//         status: response.data.status || "",
//       });

//       // Close modal and reset form
//       setShowNewCustomerModal(false);
//       setNewCustomer({
//         branch_id: "",
//         customer_id: "",
//         name: "",
//         mobile: "",
//         email: "",
//         company_name: "",
//         door_no: "",
//         street_name: "",
//         area: "",
//         pin_code: "",
//         gst_no: "",
//         notes: "",
//         status: "Active",
//       });
//     } catch (error) {
//       console.error("Error adding customer:", error);
//       toast.error("Failed to add customer");
//     }
//   };

//   // Calculate net total
//   const calculateNetTotal = (subtotal, gst, discount) => {
//     const subtotalVal = parseFloat(subtotal) || 0;
//     const gstVal = parseFloat(gst) || 0;
//     const discountVal = parseFloat(discount) || 0;

//     return (subtotalVal + gstVal - discountVal).toFixed(2);
//   };

//   // Calculate balance
//   const calculateBalance = (nettotal, paid) => {
//     const nettotalVal = parseFloat(nettotal) || 0;
//     const paidVal = parseFloat(paid) || 0;

//     return (nettotalVal - paidVal).toFixed(2);
//   };

//   // Function to get product name by ID
//   const getProductNameById = (productId) => {
//     const product = productsMap.find((item) => item._id === productId);
//     return product
//       ? `${product.hsn_code} - ${product.name}`
//       : "Unknown Product";
//   };

//   const handleSubmit = async (e) => {
//     if (e) e.preventDefault();
//     console.log("Submitting sales order:", addData);

//     try {
//       const formData = {
//         ...addData,
//         payment_history: paymentHistory,
//       };
//       console.log("asdfsd232f22222222233333", formData);

//       const response = await axios.post(`${ApiUrl}/salesorder`, formData);

//       if (response.status === 201) {
//         console.log("Sales order added successfully:", response.data);
//         console.log("asdfsd232f22222222222222222", formData);

//         toast.success("Sales Order Added Successfully");
//         console.log("asdfasdf", productForm.quantity);
//         console.log("asdfsd232f", stockMap.solding_quantity);
//         console.log("Stock dataasdf:", stockMap); // Log stock data

//         await updateStockForSoldItems();
//         resetForm();
//         navigate("/salesorder");
//       }
//     } catch (error) {
//       console.error("Error adding sales order:", error.response?.data || error);
//       toast.error("Error adding sales order");
//     }
//   };

//   const updateStockForSoldItems = async () => {
//     console.log("Updating stock...");
//     const updatePromises = addData.items.map(async (item) => {
//       const product = productsMap.find((prod) => prod._id === item.products);
//       if (product) {
//         return updateStock(product.hsn_code, item.quantity);
//       }
//     });

//     await Promise.all(updatePromises.filter(Boolean));
//     console.log("Stock update completed.");
//   };

//   const updateStock = async (hsn_code, soldQty) => {
//     try {
//       if (!hsn_code || !soldQty || soldQty <= 0) {
//         toast.error("Invalid stock update request");
//         return;
//       }

//       console.log(
//         `Updating stock for HSN: ${hsn_code}, Sold Quantity: ${soldQty}`
//       );

//       const response = await axios.put(
//         `${ApiUrl}/stock/update-solding-quantity/${hsn_code}`,
//         { soldQty }
//       );

//       console.log(`Stock updated for HSN: ${hsn_code}`, response.data);
//       toast.success(`Stock updated for product HSN: ${hsn_code}`);
//     } catch (error) {
//       console.error(
//         `Error updating stock for HSN: ${hsn_code}`,
//         error.response?.data || error
//       );
//       toast.error(`Failed to update stock for product HSN: ${hsn_code}`);
//     }
//   };

//   const resetForm = () => {
//     setAddData({
//       sales_order_no: "",
//       date: "",
//       client_name: "",
//       company_name: "",
//       door_no: "",
//       street_name: "",
//       area: "",
//       pin_code: "",
//       gst_no: "",
//       notes: "",
//       status: "",
//       email: "",
//       mobile: "",
//       paid_till_date: "0",
//       payment_due_date: "",
//       next_due_date: "",
//       person_name: "",
//       contact: "",
//       items: [],
//       subtotal: "0.00",
//       gst: "0.00",
//       discount: "0.00",
//       nettotal: "0.00",
//       paytype: "",
//       paidby: "",
//       paid: "0.00",
//       balance: "0.00",
//       branch: "",
//     });
//     setResponseDatas([]);
//     setPaymentHistory([]); // Reset payment history
//     setProductForm({
//       products: "",
//       quantity: "1",
//       rate: "",
//       tax: "",
//       value: "0.00",
//       total: "0.00",
//       availableQuantity: "0",
//     });
//   };

//   const handleProductChange = (e) => {
//     const { name, value } = e.target;

//     if (name === "products") {
//       const selectedProduct = productsMap.find(
//         (product) => product._id === value
//       );

//       if (selectedProduct) {
//         const hsnCode = selectedProduct.hsn_code;
//         const stockItem = stockMap.find((stock) => stock.hsn_code === hsnCode);
//         if (stockItem) {
//           const basicPrice = parseFloat(stockItem.basic_price) || 0;
//           const salesMargin = parseFloat(stockItem.sales_margin) || 0;
//           const taxPercentage = parseFloat(stockItem.tax) || 0;
//           const marginPrice = basicPrice * (salesMargin / 100);
//           const rate = basicPrice + marginPrice;
//           const taxValue = rate * (taxPercentage / 100);
//           const quantity = 1;
//           const total = (quantity * (rate + taxValue)).toFixed(2);

//           setProductForm({
//             ...productForm,
//             products: value,
//             quantity: "1",
//             availableQuantity: stockItem.available_quantity || "0",
//             rate: rate.toFixed(2),
//             tax: taxPercentage.toFixed(2),
//             value: taxValue.toFixed(2),
//             total: total,
//           });
//         } else {
//           setProductForm({
//             ...productForm,
//             products: value,
//             quantity: "1",
//             availableQuantity: "0",
//             rate: "0.00",
//             tax: "0.00",
//             value: "0.00",
//             total: "0.00",
//           });
//         }
//       } else {
//         setProductForm({
//           ...productForm,
//           [name]: value,
//           quantity: "1",
//           availableQuantity: "0",
//           rate: "0.00",
//           tax: "0",
//           value: "0.00",
//           total: "0.00",
//         });
//       }
//     } else if (name === "quantity") {
//       const quantity = parseFloat(value) || 0;
//       const rate = parseFloat(productForm.rate) || 0;
//       const taxValue = parseFloat(productForm.value) || 0;
//       const total = (quantity * (rate + taxValue)).toFixed(2);

//       setProductForm({
//         ...productForm,
//         quantity: value,
//         total: total,
//       });
//     } else if (name === "rate") {
//       const rate = parseFloat(value) || 0;
//       const quantity = parseFloat(productForm.quantity) || 0;
//       const taxPercentage = parseFloat(productForm.tax) || 0;

//       const taxValue = rate * (taxPercentage / 100);

//       const total = (quantity * (rate + taxValue)).toFixed(2);

//       setProductForm({
//         ...productForm,
//         rate: value,
//         value: taxValue.toFixed(2),
//         total: total,
//       });
//     } else if (name === "tax") {
//       const taxPercentage = parseFloat(value) || 0;
//       const rate = parseFloat(productForm.rate) || 0;
//       const quantity = parseFloat(productForm.quantity) || 0;
//       const taxValue = rate * (taxPercentage / 100);
//       const total = (quantity * (rate + taxValue)).toFixed(2);

//       setProductForm({
//         ...productForm,
//         tax: value,
//         value: taxValue.toFixed(2),
//         total: total,
//       });
//     } else if (name === "value") {
//       const taxValue = parseFloat(value) || 0;
//       const quantity = parseFloat(productForm.quantity) || 0;
//       const rate = parseFloat(productForm.rate) || 0;
//       const total = (quantity * (rate + taxValue)).toFixed(2);

//       setProductForm({
//         ...productForm,
//         value: value,
//         total: total,
//       });
//     } else {
//       setProductForm({
//         ...productForm,
//         [name]: value,
//       });
//     }
//   };

//   const [editIndex, setEditIndex] = useState(-1);
//   const handleEdit = (index) => {
//     const item = responseDatas[index];
//     setProductForm({
//       products: item.products,
//       quantity: item.quantity.toString(),
//       rate: item.rate.toString(),
//       tax: item.tax.toString(),
//       value: item.value.toString(),
//       total: item.total.toString(),
//       availableQuantity: getAvailableQuantity(item.products),
//     });
//     setEditIndex(index);
//   };

//   // Helper function to get available quantity
//   const getAvailableQuantity = (productId) => {
//     const product = productsMap.find((p) => p._id === productId);
//     if (product) {
//       const stockItem = stockMap.find((s) => s.hsn_code === product.hsn_code);
//       return stockItem ? stockItem.available_quantity : "0";
//     }
//     return "0";
//   };

//   const handleAdd = () => {
//     const products = productForm.products;
//     const quantity = parseFloat(productForm.quantity) || 0;
//     const rate = parseFloat(productForm.rate) || 0;
//     const tax = parseFloat(productForm.tax) || 0;
//     const value = parseFloat(productForm.value) || 0;

//     // Calculate total: quantity * (rate + tax value)
//     const total = parseFloat((quantity * (rate + value)).toFixed(2));

//     if (!products) {
//       toast.error("Please select a product");
//       return;
//     }

//     if (quantity <= 0) {
//       toast.error("Quantity must be greater than zero");
//       return;
//     }

//     if (rate <= 0) {
//       toast.error("Rate must be greater than zero");
//       return;
//     }

//     // Add the item to response data
//     if (editIndex >= 0) {
//       // Update existing item
//       const updatedItems = [...responseDatas];
//       updatedItems[editIndex] = {
//         products,
//         quantity,
//         rate,
//         tax,
//         value,
//         total,
//       };
//       setResponseDatas(updatedItems);
//       setEditIndex(-1); // Reset edit index
//     } else {
//       // Add new item
//       setResponseDatas([
//         ...responseDatas,
//         {
//           products,
//           quantity,
//           rate,
//           tax,
//           value,
//           total,
//         },
//       ]);
//     }
//     // Reset product form
//     setProductForm({
//       products: "",
//       quantity: "1",
//       rate: "",
//       tax: "",
//       value: "0.00",
//       total: "0.00",
//       availableQuantity: "0",
//     });
//   };

//   // Handle
//   // ing payment to history
//   const handleAddPayment = () => {
//     if (addData.paid && addData.paytype) {
//       const newPayment = {
//         id: Date.now(), // Generate a unique ID using timestamp
//         details: `Payment for ${addData.sales_order_no}`,
//         date: new Date().toISOString().split("T")[0], // Current date
//         paid: addData.paid,
//         paytype: addData.paytype,
//         paidby: addData.paidby || "Unknown",
//       };

//       // Add to payment history
//       setPaymentHistory([...paymentHistory, newPayment]);

//       // Calculate new balance
//       const totalPaid = [...paymentHistory, newPayment]
//         .reduce((sum, payment) => sum + parseFloat(payment.paid), 0)
//         .toFixed(2);

//       const newBalance = (
//         parseFloat(addData.nettotal) - parseFloat(totalPaid)
//       ).toFixed(2);

//       // Reset payment fields but keep the calculated balance
//       setAddData({
//         ...addData,
//         paid: "0.00",
//         paytype: "",
//         paidby: "",
//         balance: newBalance,
//       });
//     } else {
//       toast.error("Please enter payment amount and payment type");
//     }
//   };

//   const handleDeletePayment = (id) => {
//     const updatedPaymentHistory = paymentHistory.filter(
//       (payment) => payment.id !== id
//     );
//     setPaymentHistory(updatedPaymentHistory);

//     // Recalculate balance
//     const totalPaid = updatedPaymentHistory
//       .reduce((sum, payment) => sum + parseFloat(payment.paid), 0)
//       .toFixed(2);

//     const newBalance = (
//       parseFloat(addData.nettotal) - parseFloat(totalPaid)
//     ).toFixed(2);

//     setAddData({
//       ...addData,
//       balance: newBalance,
//     });
//   };

//   const handleDelete = (index) => {
//     setResponseDatas(responseDatas.filter((_, i) => i !== index));
//   };

//   // const handleChange = (e) => {
//   //   const { name, value, type, checked } = e.target;

//   //   // Handle different types of inputs
//   //   if (type === "checkbox" && name === "gst_enabled") {
//   //     // If GST checkbox is toggled
//   //     const gstValue = checked
//   //       ? (parseFloat(addData.subtotal) * 0.18).toFixed(2)
//   //       : "0.00";
//   //     const newNetTotal = calculateNetTotal(
//   //       addData.subtotal,
//   //       gstValue,
//   //       addData.discount
//   //     );
//   //     const newBalance = calculateBalance(newNetTotal, addData.paid);

//   //     setAddData({
//   //       ...addData,
//   //       gst: gstValue,
//   //       nettotal: newNetTotal,
//   //       balance: newBalance,
//   //     });
//   //   } else {
//   //     // For other inputs
//   //     const newValue = value;

//   //     if (name === "discount" || name === "paid") {
//   //       // Recalculate nettotal and balance when discount or paid changes
//   //       const newNetTotal =
//   //         name === "discount"
//   //           ? calculateNetTotal(addData.subtotal, addData.gst, newValue)
//   //           : addData.nettotal;

//   //       const newBalance = calculateBalance(
//   //         name === "discount" ? newNetTotal : addData.nettotal,
//   //         name === "paid" ? newValue : addData.paid
//   //       );

//   //       setAddData({
//   //         ...addData,
//   //         [name]: newValue,
//   //         nettotal: name === "discount" ? newNetTotal : addData.nettotal,
//   //         balance: newBalance,
//   //       });
//   //     } else {
//   //       // For other fields, just update the value
//   //       setAddData({
//   //         ...addData,
//   //         [name]: newValue,
//   //       });
//   //     }
//   //   }
//   // };

//   const [gstPercentage, setGstPercentage] = useState(18); // Default to 18%

//   // ... other existing code

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;

//     // Handle different types of inputs
//     if (type === "checkbox" && name === "gst_enabled") {
//       // If GST checkbox is toggled
//       const gstValue = checked
//         ? (parseFloat(addData.subtotal) * (gstPercentage / 100)).toFixed(2)
//         : "0.00";
//       const newNetTotal = calculateNetTotal(
//         addData.subtotal,
//         gstValue,
//         addData.discount
//       );
//       const newBalance = calculateBalance(newNetTotal, addData.paid);

//       setAddData({
//         ...addData,
//         gst: gstValue,
//         nettotal: newNetTotal,
//         balance: newBalance,
//       });
//     } else if (name === "gstPercentage") {
//       // Handle GST percentage change
//       setGstPercentage(parseFloat(value) || 0);

//       // Only recalculate if GST is enabled
//       if (document.querySelector('input[name="gst_enabled"]').checked) {
//         const gstValue = (
//           parseFloat(addData.subtotal) *
//           (parseFloat(value) / 100)
//         ).toFixed(2);
//         const newNetTotal = calculateNetTotal(
//           addData.subtotal,
//           gstValue,
//           addData.discount
//         );
//         const newBalance = calculateBalance(newNetTotal, addData.paid);

//         setAddData({
//           ...addData,
//           gst: gstValue,
//           nettotal: newNetTotal,
//           balance: newBalance,
//         });
//       }
//     } else {
//       // For other inputs (your existing code)
//       const newValue = value;

//       if (name === "discount" || name === "paid") {
//         // Recalculate nettotal and balance when discount or paid changes
//         const newNetTotal =
//           name === "discount"
//             ? calculateNetTotal(addData.subtotal, addData.gst, newValue)
//             : addData.nettotal;

//         const newBalance = calculateBalance(
//           name === "discount" ? newNetTotal : addData.nettotal,
//           name === "paid" ? newValue : addData.paid
//         );

//         setAddData({
//           ...addData,
//           [name]: newValue,
//           nettotal: name === "discount" ? newNetTotal : addData.nettotal,
//           balance: newBalance,
//         });
//       } else {
//         // For other fields, just update the value
//         setAddData({
//           ...addData,
//           [name]: newValue,
//         });
//       }
//     }
//   };

//   return (
//     <>
//       <div>
//         <h5
//           className="head"
//           style={{ textDecoration: "underline", marginTop: "-80px" }}
//         >
//           New Sales Order
//         </h5>
//       </div>
//       <hr></hr>
//       {/* invoice no */}
//       <div className="card invoice_card p-3">
//         <div className="row first">
//           <div className="col-lg-3">
//             <label>Sales Order No:</label>
//             <input
//               type="text"
//               className="form-control"
//               name="sales_order_no"
//               value={addData.sales_order_no}
//               disabled
//               readOnly // Prevent manual input
//             />
//           </div>

//           {/* date */}
//           <div className="col-lg-6"></div>

//           <div className="col-lg-3">
//             <label>Date:</label>
//             <input
//               type="date"
//               className="form-control"
//               name="date"
//               value={addData.date}
//               onChange={handleChange}
//               required
//             />
//           </div>
//         </div>
//       </div>
//       {/* type */}

//       <div className="card invoice_card p-3">
//         <div className="row first">
//           <div className="col-lg-3" ref={dropdownRef}>
//             <label>Select Customer:</label>
//             <div className="input-group">
//               <input
//                 type="text"
//                 className="form-control"
//                 name="client_name"
//                 value={addData.client_name}
//                 onChange={handleCustomerFilter}
//                 onFocus={() => setShowCustomerDropdown(true)}
//                 placeholder="Type to search customers"
//                 required
//               />
//               <button
//                 className="btn btn-outline-secondary"
//                 type="button"
//                 onClick={() => setShowNewCustomerModal(true)}
//               >
//                 <i className="fa fa-plus"></i>
//               </button>
//             </div>

//             {showCustomerDropdown && (
//               <div
//                 // className="dropdown-menu show w-100"
//                 // style={{ display: "block", position: "absolute", zIndex: 1000 }}
//                 className="dropdown-menu show"
//                 style={{ maxHeight: "200px", width: "25%", overflow: "auto" }}
//               >
//                 {filteredCustomers.length > 0 ? (
//                   filteredCustomers.map((customer, index) => (
//                     <button
//                       key={index}
//                       className="dropdown-item"
//                       type="button"
//                       onClick={() => selectCustomer(customer)}
//                     >
//                       {customer.name}
//                     </button>
//                   ))
//                 ) : (
//                   <button className="dropdown-item" type="button" disabled>
//                     No customers found
//                   </button>
//                 )}
//               </div>
//             )}
//           </div>

//           {/*  email*/}
//           <div className="col-lg-3">
//             <label>Email:</label>
//             <input
//               type="text"
//               className="form-control"
//               name="email"
//               value={addData.email}
//               onChange={(e) =>
//                 setAddData({
//                   ...addData,
//                   email: e.target.value,
//                 })
//               }
//               required
//             ></input>
//           </div>
//           {/* mobile */}
//           <div className="col-lg-3">
//             <label>Mobile:</label>
//             <input
//               type="text"
//               className="form-control"
//               name="mobile"
//               value={addData.mobile}
//               onChange={(e) =>
//                 setAddData({
//                   ...addData,
//                   mobile: e.target.value,
//                 })
//               }
//               required
//             ></input>
//           </div>
//           <div className="col-lg-3">
//             <label>Branch:</label>
//             <input
//               type="text"
//               className="form-control"
//               name="branch"
//               value={addData.branch_id}
//               onChange={(e) =>
//                 setAddData({
//                   ...addData,
//                   branch_id: e.target.value,
//                 })
//               }
//               required
//             ></input>
//           </div>
//           {/* add button */}
//           <div className="col-lg-1 mt-4"></div>
//         </div>
//         <div className="col-lg-2"></div>
//       </div>

//       {/* Customer Modal */}
//       {showNewCustomerModal && (
//         <div
//           className="modal"
//           style={{ display: "block", backgroundColor: "rgba(0,0,0,0.4)" }}
//         >
//           <div className="modal-dialog">
//             <Modal
//               className="addmodals"
//               show={showNewCustomerModal}
//               onHide={() => setShowNewCustomerModal(false)}
//             >
//               <Modal.Header closeButton>
//                 <Modal.Title>Customer</Modal.Title>
//               </Modal.Header>

//               <form onSubmit={submitNewCustomer}>
//                 <Modal.Body>
//                   <div className="row">
//                     <div className="col-lg-6 ">
//                       <div className="floating-label ">
//                         <select
//                           className="form-select"
//                           name="branch_id"
//                           value={newCustomer.branch_id}
//                           onChange={handleNewCustomerChange}
//                           required
//                         >
//                           <option value="">Select Branch</option>
//                           {branches.map((branch) => (
//                             <option key={branch._id} value={branch._id}>
//                               {branch.branch_name}
//                             </option>
//                           ))}
//                         </select>
//                       </div>

//                       <div className="floating-label">
//                         <input
//                           className="form-control"
//                           type="text"
//                           name="customer_id"
//                           value={newCustomer?.customer_id || ""}
//                           onChange={handleNewCustomerChange}
//                           disabled
//                           required
//                         />

//                         <label className="">Customer ID</label>
//                       </div>

//                       <div className="floating-label">
//                         <input
//                           className="form-control"
//                           type="text"
//                           name="name"
//                           value={newCustomer.name}
//                           onChange={handleNewCustomerChange}
//                           required
//                         />
//                         <label className={newCustomer.name ? "filled" : ""}>
//                           Name
//                         </label>
//                       </div>

//                       <div className="floating-label">
//                         <input
//                           className="form-control"
//                           type="tel"
//                           name="mobile"
//                           placeholder=""
//                           value={newCustomer.mobile}
//                           onChange={handleNewCustomerChange}
//                           required
//                         />
//                         <label className={newCustomer.mobile ? "filled" : ""}>
//                           Mobile
//                         </label>
//                       </div>

//                       <div className="floating-label">
//                         <input
//                           className="form-control"
//                           type="email"
//                           name="email"
//                           placeholder=""
//                           value={newCustomer.email}
//                           onChange={handleNewCustomerChange}
//                           required
//                         />
//                         <label className={newCustomer.email ? "filled" : ""}>
//                           Email
//                         </label>
//                       </div>

//                       <div className="floating-label ">
//                         <input
//                           className="form-control"
//                           type="text"
//                           name="company_name"
//                           placeholder=""
//                           value={newCustomer.company_name}
//                           onChange={handleNewCustomerChange}
//                         />
//                         <label
//                           className={newCustomer.company_name ? "filled" : ""}
//                         >
//                           Company Name
//                         </label>
//                       </div>

//                       <div className="floating-label ">
//                         <input
//                           className="form-control"
//                           type="text"
//                           name="door_no"
//                           placeholder=""
//                           value={newCustomer.door_no}
//                           onChange={handleNewCustomerChange}
//                         />
//                         <label className={newCustomer.door_no ? "filled" : ""}>
//                           Door No
//                         </label>
//                       </div>
//                     </div>

//                     <div className="col-lg-6 ">
//                       <div className="floating-label ">
//                         <input
//                           className="form-control"
//                           type="text"
//                           name="street_name"
//                           placeholder=""
//                           value={newCustomer.street_name}
//                           onChange={handleNewCustomerChange}
//                         />
//                         <label
//                           className={newCustomer.street_name ? "filled" : ""}
//                         >
//                           Street Name
//                         </label>
//                       </div>

//                       <div className="floating-label ">
//                         <input
//                           className="form-control"
//                           type="text"
//                           name="area"
//                           placeholder=""
//                           value={newCustomer.area}
//                           onChange={handleNewCustomerChange}
//                         />
//                         <label className={newCustomer.area ? "filled" : ""}>
//                           Area
//                         </label>
//                       </div>

//                       <div className="floating-label ">
//                         <input
//                           className="form-control"
//                           type="text"
//                           name="pin_code"
//                           placeholder=""
//                           value={newCustomer.pin_code}
//                           onChange={(e) => {
//                             setNewCustomer({
//                               ...newCustomer,
//                               pin_code: e.target.value,
//                             });
//                           }}
//                         />
//                         <label className={newCustomer.pin_code ? "filled" : ""}>
//                           Pin Code
//                         </label>
//                       </div>

//                       <div className="floating-label ">
//                         <input
//                           className="form-control"
//                           type="text"
//                           name="gst_no"
//                           placeholder=""
//                           value={newCustomer.gst_no}
//                           onChange={(e) => {
//                             setNewCustomer({
//                               ...newCustomer,
//                               gst_no: e.target.value,
//                             });
//                           }}
//                         />
//                         <label className={newCustomer.gst_no ? "filled" : ""}>
//                           GST Number
//                         </label>
//                       </div>

//                       <div className="floating-label ">
//                         <input
//                           className="form-control"
//                           type="text"
//                           name="notes"
//                           placeholder=""
//                           value={newCustomer.notes}
//                           onChange={(e) => {
//                             setNewCustomer({
//                               ...newCustomer,
//                               notes: e.target.value,
//                             });
//                           }}
//                         />
//                         <label className={newCustomer.notes ? "filled" : ""}>
//                           Notes
//                         </label>
//                       </div>

//                       <div className="floating-label ">
//                         <select
//                           className="form-select"
//                           name="status"
//                           value={newCustomer.status}
//                           onChange={(e) => {
//                             setNewCustomer({
//                               ...newCustomer,
//                               status: e.target.value,
//                             });
//                           }}
//                           required
//                         >
//                           <option value="">Select Status</option>
//                           <option value="Active">Active</option>
//                           <option value="InActive">InActive</option>
//                         </select>
//                       </div>
//                     </div>
//                   </div>
//                 </Modal.Body>

//                 <Modal.Footer>
//                   <Button
//                     className="cancelbut"
//                     variant="secondary"
//                     onClick={() => setShowNewCustomerModal(false)}
//                   >
//                     Cancel
//                   </Button>
//                   <Button className="submitbut" type="submit" variant="primary">
//                     Submit
//                   </Button>
//                 </Modal.Footer>
//               </form>
//             </Modal>
//           </div>
//         </div>
//       )}

//       <div className="card invoice_card p-3">
//         <div className="row first">
//           <div className="col-lg-2">
//             <label>Products:</label>
//             <select
//               className="form-control"
//               name="products"
//               value={productForm.products}
//               onChange={handleProductChange}
//             >
//               <option value="">Select Product</option>
//               {productsMap.map((item, index) => (
//                 <option key={index} value={item._id}>
//                   {item.hsn_code} - {item.name}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div className="col-lg-2">
//             <label>Available Qty:</label>
//             <input
//               type="text"
//               className="form-control"
//               value={productForm.availableQuantity}
//               disabled
//               readOnly
//             />
//           </div>

//           <div className="col-lg-2">
//             <label>Qty:</label>
//             <input
//               type="text"
//               className="form-control"
//               name="quantity"
//               value={productForm.quantity}
//               onChange={handleProductChange}
//               required
//             />
//           </div>

//           <div className="col-lg-1">
//             <label>Rate:</label>
//             <input
//               type="text"
//               className="form-control"
//               name="rate"
//               value={productForm.rate}
//               onChange={handleProductChange}
//               required
//             />
//           </div>

//           <div className="col-lg-1">
//             <label>Tax (%):</label>
//             <input
//               type="text"
//               className="form-control"
//               name="tax"
//               value={productForm.tax}
//               onChange={handleProductChange}
//               required
//             />
//           </div>

//           {/* Tax Value Input Field */}
//           <div className="col-lg-1">
//             <label>Value:</label>
//             <input
//               type="text"
//               className="form-control"
//               name="value"
//               value={productForm.value}
//               onChange={handleProductChange}
//               required
//             />
//           </div>

//           <div className="col-lg-2">
//             <label>Total:</label>
//             <input
//               type="text"
//               className="form-control"
//               value={productForm.total}
//               readOnly
//             />
//           </div>

//           <div className="col-lg-1 mt-4">
//             <div className="" style={{ marginTop: "-9px" }}>
//               <button
//                 type="button"
//                 className="btn btn-secondary btn-sm m-3"
//                 onClick={handleAdd}
//               >
//                 Add
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="card invoice_card p-3">
//         <div className="row first">
//           <div className="col-lg-2">
//             <label>CUSPUR Order No:</label>
//             <input
//               type="text"
//               className="form-control"
//               name="paid_till_date"
//               value={addData.paid_till_date}
//               onChange={(e) =>
//                 setAddData({
//                   ...addData,
//                   paid_till_date: e.target.value,
//                 })
//               }
//             ></input>
//           </div>

//           <div className="col-lg-2">
//             <label>Purchase Date:</label>
//             <input
//               type="date"
//               className="form-control"
//               name="payment_due_date"
//               value={addData.payment_due_date}
//               onChange={(e) =>
//                 setAddData({
//                   ...addData,
//                   payment_due_date: e.target.value,
//                 })
//               }
//             ></input>
//           </div>

//           <div className="col-lg-2">
//             <label>Next Due Date:</label>
//             <input
//               type="date"
//               className="form-control"
//               name="next_due_date"
//               value={addData.next_due_date}
//               onChange={(e) =>
//                 setAddData({
//                   ...addData,
//                   next_due_date: e.target.value,
//                 })
//               }
//             ></input>
//           </div>

//           <div className="col-lg-3">
//             <label>Person Name:</label>
//             <input
//               type="text"
//               className="form-control"
//               name="person_name"
//               value={addData.person_name}
//               onChange={(e) =>
//                 setAddData({
//                   ...addData,
//                   person_name: e.target.value,
//                 })
//               }
//             />
//           </div>

//           <div className="col-lg-3">
//             <label>Contact:</label>
//             <input
//               type="text"
//               className="form-control"
//               name="contact"
//               value={addData.contact}
//               onChange={(e) =>
//                 setAddData({
//                   ...addData,
//                   contact: e.target.value,
//                 })
//               }
//             />
//           </div>
//         </div>
//       </div>

//       {/* ////////////////////////////////////////////////////////////////////////////// */}
//       <div className="card invoice_card">
//         <div className="card-body">
//           <div className="table-responsive">
//             <table className="table table-bordered">
//               <thead className="thead-dark">
//                 <tr>
//                   <th>S.NO</th>
//                   <th>Description</th>
//                   <th>Qty</th>
//                   <th>Rate</th>
//                   <th>Tax (%)</th>
//                   <th>Tax Value</th>
//                   <th>Total</th>
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {responseDatas.map((item, index) => (
//                   <tr key={index}>
//                     <td>{index + 1}</td>
//                     <td>{getProductNameById(item.products)}</td>
//                     <td>{item.quantity}</td>
//                     <td>{item.rate}</td>
//                     <td>{item.tax}</td>
//                     <td>
//                       {item.value || ((item.rate * item.tax) / 100).toFixed(2)}
//                     </td>
//                     <td>{item.total.toFixed(2)}</td>
//                     <td>
//                       <i
//                         className="fa-solid fa-pen-to-square mx-2 fs-5"
//                         style={{ color: "blue", cursor: "pointer" }}
//                         onClick={() => handleEdit(index)}
//                       ></i>
//                       <i
//                         className="fa-sharp-duotone fa-solid fa-circle-xmark mx-2 fs-2"
//                         style={{ color: "red", cursor: "pointer" }}
//                         onClick={() => handleDelete(index)}
//                       ></i>
//                     </td>
//                   </tr>
//                 ))}
//                 {responseDatas.length === 0 && (
//                   <tr>
//                     <td colSpan="8" className="text-center">
//                       No items added
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>

//       <div className="card invoice_card p-3">
//         <div className="row">
//           <h5>Previous Payment History</h5>
//           <div className="col-lg-7">
//             <table className="table table-bordered">
//               <thead>
//                 <tr>
//                   <th>S.No</th>
//                   <th>Details</th>
//                   <th>Date</th>
//                   <th>Paid</th>
//                   <th>Paytype</th>
//                   <th>Paid By</th>
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {paymentHistory.map((payment, index) => (
//                   <tr key={payment.id}>
//                     <td>{index + 1}</td>
//                     <td>{payment.details}</td>
//                     <td>{payment.date}</td>
//                     <td>{payment.paid}</td>
//                     <td>{payment.paytype}</td>
//                     <td>{payment.paidby}</td>
//                     <td>
//                       <i
//                         className="fa-sharp-duotone fa-solid fa-circle-xmark mx-2 fs-5"
//                         style={{ color: "red", cursor: "pointer" }}
//                         onClick={() => handleDeletePayment(payment.id)}
//                       ></i>
//                     </td>
//                   </tr>
//                 ))}
//                 {paymentHistory.length === 0 && (
//                   <tr>
//                     <td colSpan="8" className="text-center">
//                       No payment history
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>

//           <div className="col-lg-5 ">
//             <div className="row">
//               <div
//                 className="col-lg-5 col-md-5 col-sm-12 "
//                 style={{ textAlign: "center" }}
//               >
//                 <p>Subtotal</p>
//               </div>
//               <div
//                 className="col-lg-7 col-md-7 col-sm-12"
//                 style={{ textAlign: "right" }}
//               >
//                 <input
//                   style={{ width: "80%" }}
//                   placeholder="0.00"
//                   type="number"
//                   className="form-control"
//                   name="subtotal"
//                   value={addData.subtotal}
//                   readOnly
//                 ></input>
//               </div>
//               {/* gst */}
             
//               <div
//                 className="col-lg-5 col-md-5 col-sm-12"
//                 style={{ textAlign: "center" }}
//               >
//                 <p>
//                   GST{" "}
//                   <input
//                     type="checkbox"
//                     name="gst_enabled"
//                     onChange={handleChange}
//                   />
//                 </p>
//               </div>
//               <div
//                 className="col-lg-7 col-md-7 col-sm-12"
//                 style={{ textAlign: "right" }}
//               >
//                 <div
//                   className="d-flex"
//                   style={{ width: "80%" }}
//                 >
//                   <input
//                     type="number"
//                     className="form-control mr-2"
//                     name="gstPercentage"
//                     value={gstPercentage}
//                     onChange={handleChange}
//                     style={{ width: "40%" }}
//                     placeholder="GST %"
//                   />
//                   <input
//                     style={{ width: "60%" }}
//                     placeholder="0.00"
//                     type="number"
//                     className="form-control"
//                     name="gst"
//                     value={addData.gst}
//                     onChange={handleChange}
//                   />
//                 </div>
//               </div>
//               <div
//                 className="col-lg-5 col-md-5 col-sm-12 "
//                 style={{ textAlign: "center" }}
//               >
//                 <p>Discount</p>
//               </div>
//               <div
//                 className="col-lg-7 col-md-7 col-sm-12"
//                 style={{ textAlign: "right" }}
//               >
//                 <input
//                   style={{ width: "80%" }}
//                   placeholder="0.00"
//                   type="number"
//                   className="form-control"
//                   name="discount"
//                   value={addData.discount}
//                   onChange={handleChange}
//                 />
//               </div>
//               <div
//                 className="col-lg-5 col-md-5 col-sm-12 "
//                 style={{ textAlign: "center" }}
//               >
//                 <p>Nettotal</p>
//               </div>
//               <div
//                 className="col-lg-7 col-md-7 col-sm-12"
//                 style={{ textAlign: "right" }}
//               >
//                 <input
//                   style={{ width: "80%" }}
//                   placeholder="0.00"
//                   type="number"
//                   className="form-control"
//                   name="nettotal"
//                   value={addData.nettotal}
//                   readOnly
//                 ></input>
//               </div>
//               {/* paytype */}
//               <div
//                 className="col-lg-5 col-md-5 col-sm-12 "
//                 style={{ textAlign: "center" }}
//               >
//                 <p>Paytype</p>
//               </div>{" "}
//               <div
//                 className="col-lg-7 col-md-7 col-sm-12"
//                 style={{ textAlign: "right" }}
//               >
//                 <select
//                   className="form-control paytype w-50"
//                   name="paytype"
//                   value={addData.paytype}
//                   onChange={handleChange}
//                   style={{ width: "80%" }}
//                 >
//                   <option value="">Select Paytype</option>
//                   <option value="gpay">GPay</option>
//                   <option value="phonepe">PhonePe</option>
//                   <option value="cash">Cash</option>
//                   <option value="paytm">Paytm</option>
//                 </select>
//               </div>
//               {/* paid_by */}
//               <div
//                 className="col-lg-5 col-md-5 col-sm-12 "
//                 style={{ textAlign: "center" }}
//               >
//                 <p>Paid By</p>
//               </div>{" "}
//               <div
//                 className="col-lg-7 col-md-7 col-sm-12"
//                 style={{ textAlign: "right" }}
//               >
//                 <select
//                   className="form-control paytype"
//                   name="paidby"
//                   value={addData.paidby}
//                   onChange={handleChange}
//                   style={{ width: "80%" }}
//                 >
//                   <option value="">Select Payment Paid By</option>
//                   <option value="Client">Client</option>
//                   <option value="Company">Company</option>
//                   <option value="Others">Others</option>
//                 </select>
//               </div>
//               {/* paid */}
//               <div
//                 className="col-lg-5 col-md-5 col-sm-12 "
//                 style={{ textAlign: "center" }}
//               >
//                 <p>Paid</p>
//               </div>
//               <div
//                 className="col-lg-5 col-md-5 col-sm-12 "
//                 style={{ textAlign: "right" }}
//               >
//                 <input
//                   style={{ width: "80%" }}
//                   placeholder="0.00"
//                   type="number"
//                   className="form-control"
//                   name="paid"
//                   value={addData.paid}
//                   onChange={handleChange}
//                 ></input>
//               </div>
//               <div className="col-lg-2">
//                 <button
//                   className="btn btn-primary btn-sm"
//                   onClick={handleAddPayment}
//                 >
//                   Add
//                 </button>
//               </div>
//               {/* balance */}
//               <div
//                 className="col-lg-5 col-md-5 col-sm-12 "
//                 style={{ textAlign: "center" }}
//               >
//                 <p>Balance</p>
//               </div>
//               <div
//                 className="col-lg-7 col-md-7 col-sm-12"
//                 style={{ textAlign: "right" }}
//               >
//                 <input
//                   style={{ width: "80%" }}
//                   placeholder="0.00"
//                   type="number"
//                   className="form-control"
//                   name="balance"
//                   value={addData.balance}
//                   readOnly
//                 ></input>
//               </div>
//             </div>

//             <div className="row">
//               <div className="d-flex justify-content-evenly mt-3 buttonstyle ">
//                 <button
//                   type="button"
//                   className="btn btn-danger invoice-button"
//                   onClick={resetForm}
//                 >
//                   Close
//                 </button>
//                 <button
//                   type="button"
//                   className="btn btn-info text-white invoice-button"
//                   style={{ backgroundColor: "#007BFF" }}
//                   onClick={resetForm}
//                 >
//                   Clear
//                 </button>

//                 <button
//                   type="button"
//                   className="btn btn-secondary invoice-button"
//                   onClick={handleSubmit}
//                 >
//                   Save
//                 </button>

//                 <button
//                   type="button"
//                   className="btn btn-success invoice-button"
//                   onClick={() => {
//                     handleSubmit();
//                     window.print();
//                   }}
//                 >
//                   Save&Print
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default AddSalesInvoice;




import axios from "axios";
import { useState, useEffect, useRef } from "react";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

function AddSalesInvoice() {
  const navigate = useNavigate();
  const [productsMap, setProductsMap] = useState([]);
  const [customerMap, setCustomerMap] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [stockMap, setStockMap] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
  const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
  const dropdownRef = useRef(null);
  const [newCustomer, setNewCustomer] = useState({
    branch_id: "",
    customer_id: "",
    name: "",
    mobile: "",
    email: "",
    company_name: "",
    door_no: "",
    street_name: "",
    area: "",
    pin_code: "",
    gst_no: "",
    notes: "",
    status: "",
    branch_from:"",
  });

  const [addData, setAddData] = useState({
    sales_order_no: "",
    date: "",
    client_name: "",
    company_name: "",
    customer_id: "", // Add this field
    door_no: "",
    street_name: "",
    area: "",
    pin_code: "",
    gst_no: "",
    notes: "",
    status: "",
    email: "",
    mobile: "",
    paid_till_date: "0",
    payment_due_date: "",
    next_due_date: "",
    person_name: "",
    contact: "",
    items: [],
    subtotal: "0.00",
    gst: "0.00",
    discount: "0.00",
    nettotal: "0.00",
    paytype: "",
    paidby: "",
    paid: "0.00",
    balance: "0.00",
    payment_history: [],
    branch: "",
    branch_id: "",
    branch_from:"",
  });

  const [productForm, setProductForm] = useState({
    products: "",
    hsn_code: "",
    quantity: "1",
    rate: "",
    tax: "",
    value: "0.00",
    total: "0.00",
    availableQuantity: "0",
  });

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCustomerDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Fetch the latest sales order number
    axios
      .get(ApiUrl + "/salesorder/latest")
      .then((response) => {
        setAddData((prevData) => ({
          ...prevData,
          sales_order_no: response.data.sales_order_no,
          date: new Date().toISOString().split("T")[0],
        }));
      })
      .catch((error) =>
        console.error("Error fetching sales order number:", error)
      );

    // Fetch products and stock data
    fetchDatas();
  }, []);

  useEffect(() => {
    getNextCustomerId();
  }, []);

  const [branches, setBranches] = useState([]);

  const getNextCustomerId = async () => {
    try {
      // First try to get from API endpoint
      const response = await axios.get(`${ApiUrl}/generate_customer`);
      console.log("the curster id ", response.data.customer_id);
      setNewCustomer(response.data.customer_id);
      setNewCustomer({
        ...newCustomer,
        customer_id: response.data.customer_id,
      });
    } catch (error) {
      console.error("Error fetching next customer ID:", error.message);
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);
  // GET request - Fetch all branches
  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/branch`);
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
      toast.error("Failed to fetch branches: " + error.message);
    }
  };

  useEffect(() => {
    const items = responseDatas.map((item) => ({
      products: item.products || "",
      quantity: parseFloat(item.quantity) || 0,
      rate: parseFloat(item.rate) || 0,
      tax: parseFloat(item.tax) || 0,
      value: parseFloat(item.value) || 0,
      total: parseFloat(item.total).toFixed(2),
    }));

    // Calculate subtotal without tax
    const subtotal = items
      .reduce((sum, item) => {
        const quantity = parseFloat(item.quantity) || 0;
        const rate = parseFloat(item.rate) || 0;
        return sum + quantity * rate;
      }, 0)
      .toFixed(2);

    setAddData({
      ...addData,
      items,
      subtotal,
      nettotal: calculateNetTotal(subtotal, addData.gst, addData.discount),
      balance: calculateBalance(
        calculateNetTotal(subtotal, addData.gst, addData.discount),
        addData.paid
      ),
    });
  }, [responseDatas]);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(ApiUrl + "/product");
      console.log("Response from products:", response.data);
      setProductsMap(response.data);

      const responsedd = await axios.get(ApiUrl + "/customer");
      console.log("Response from customers:", responsedd.data);
      setCustomerMap(responsedd.data);
      setFilteredCustomers(responsedd.data);

      const responsed = await axios.get(ApiUrl + "/stock");
      console.log("Response from stock:", responsed.data);
      setStockMap(responsed.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // Filter customers based on input
  const handleCustomerFilter = (e) => {
    const searchText = e.target.value;
    setAddData({
      ...addData,
      client_name: searchText,
    });

    if (searchText.trim() === "") {
      setFilteredCustomers(customerMap);
    } else {
      const filtered = customerMap.filter((customer) =>
        customer.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredCustomers(filtered);
    }

    setShowCustomerDropdown(true);
  };

  // Select customer from dropdown
  const selectCustomer = (customer) => {
    setAddData({
      ...addData,
      client_name: customer.name,
      customer_id: customer.customer_id, // Add this line to capture the ID
      email: customer.email || "",
      mobile: customer.mobile || "",
      company_name: customer.company_name || "",
      door_no: customer.door_no || "",
      street_name: customer.street_name || "",
      area: customer.area || "",
      pin_code: customer.pin_code || "",
      gst_no: customer.gst_no || "",
      notes: customer.notes || "",
      status: customer.status || "",
      branch_id: customer.branch_id[0].branch_name || "",
      branch_from: customer.branch_id[0]._id || "",
    });

    setShowCustomerDropdown(false);
  };

  const handleNewCustomerChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer({
      ...newCustomer,
      [name]: value,
    });
  };

  const submitNewCustomer = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const response = await axios.post(ApiUrl + "/customer", newCustomer);
      toast.success("Customer added successfully");
      console.log("Customer added successfully", response.data);

      // Add new customer to customer map
      const updatedCustomerMap = [...customerMap, response.data];
      setCustomerMap(updatedCustomerMap);
      setFilteredCustomers(updatedCustomerMap);

      // Update the main form with the newly added customer data
      setAddData({
        ...addData,

        client_name: response.data.name,
        email: response.data.email || "",
        mobile: response.data.mobile || "",
        company_name: response.data.company_name || "",
        door_no: response.data.door_no || "",
        street_name: response.data.street_name || "",
        area: response.data.area || "",
        pin_code: response.data.pin_code || "",
        gst_no: response.data.gst_no || "",
        notes: response.data.notes || "",
        status: response.data.status || "",
      });

      // Close modal and reset form
      setShowNewCustomerModal(false);
      setNewCustomer({
        branch_id: "",
        branch_from:"",
        customer_id: "",
        name: "",
        mobile: "",
        email: "",
        company_name: "",
        door_no: "",
        street_name: "",
        area: "",
        pin_code: "",
        gst_no: "",
        notes: "",
        status: "Active",
      });
    } catch (error) {
      console.error("Error adding customer:", error);
      toast.error("Failed to add customer");
    }
  };

  // Calculate net total
  const calculateNetTotal = (subtotal, gst, discount) => {
    const subtotalVal = parseFloat(subtotal) || 0;
    const gstVal = parseFloat(gst) || 0;
    const discountVal = parseFloat(discount) || 0;

    return (subtotalVal + gstVal - discountVal).toFixed(2);
  };

  // Calculate balance
  const calculateBalance = (nettotal, paid) => {
    const nettotalVal = parseFloat(nettotal) || 0;
    const paidVal = parseFloat(paid) || 0;

    return (nettotalVal - paidVal).toFixed(2);
  };

  // Function to get product name by ID
  const getProductNameById = (productId) => {
    const product = productsMap.find((item) => item._id === productId);
    return product
      ? `${product.hsn_code} - ${product.name}`
      : "Unknown Product";
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    console.log("Submitting sales order:", addData);

    try {
      const formData = {
        ...addData,
        items: responseDatas.map((item) => ({
          ...item, // This will include the hsn_code in each item
          products: item.products,
          quantity: item.quantity,
          rate: item.rate,
          tax: item.tax,
          value: item.value,
          total: item.total,
          hsn_code: item.hsn_code, // Explicitly include hsn_code
        })),
        paid: addData.paid || "", // Ensure paid value is preserved
        balance: addData.balance || "", // Ensure balance value is preserved
        payment_history: paymentHistory,
      };
      console.log("asdfsd232f22222222233333", formData);

      const response = await axios.post(`${ApiUrl}/salesorder`, formData);

      if (response.status === 201) {
        console.log("Sales order added successfully:", response.data);
        console.log("asdfsd232f22222222222222222", formData);

        toast.success("Sales Order Added Successfully");
        console.log("asdfasdf", productForm.quantity);
        console.log("asdfsd232f", stockMap.solding_quantity);
        console.log("Stock dataasdf:", stockMap); // Log stock data

        await updateStockForSoldItems();
        resetForm();
        navigate("/salesorder");
      }
    } catch (error) {
      console.error("Error adding sales order:", error.response?.data || error);
      toast.error("Error adding sales order");
    }
  };

  const updateStockForSoldItems = async () => {
    console.log("Updating stock...");
    const updatePromises = addData.items.map(async (item) => {
      const product = productsMap.find((prod) => prod._id === item.products);
      if (product) {
        return updateStock(product.hsn_code, item.quantity);
      }
    });

    await Promise.all(updatePromises.filter(Boolean));
    console.log("Stock update completed.");
  };

  const updateStock = async (hsn_code, soldQty) => {
    try {
      if (!hsn_code || !soldQty || soldQty <= 0) {
        toast.error("Invalid stock update request");
        return;
      }

      console.log(
        `Updating stock for HSN: ${hsn_code}, Sold Quantity: ${soldQty}`
      );

      const response = await axios.put(
        `${ApiUrl}/stock/update-solding-quantity/${hsn_code}`,
        { soldQty }
      );

      console.log(`Stock updated for HSN: ${hsn_code}`, response.data);
      toast.success(`Stock updated for product HSN: ${hsn_code}`);
    } catch (error) {
      console.error(
        `Error updating stock for HSN: ${hsn_code}`,
        error.response?.data || error
      );
      toast.error(`Failed to update stock for product HSN: ${hsn_code}`);
    }
  };

  const resetForm = () => {
    setAddData({
      sales_order_no: "",
      date: "",
      client_name: "",
      company_name: "",
      door_no: "",
      street_name: "",
      area: "",
      pin_code: "",
      gst_no: "",
      notes: "",
      status: "",
      email: "",
      mobile: "",
      paid_till_date: "0",
      payment_due_date: "",
      next_due_date: "",
      person_name: "",
      contact: "",
      items: [],
      subtotal: "0.00",
      gst: "0.00",
      discount: "0.00",
      nettotal: "0.00",
      paytype: "",
      paidby: "",
      paid: "0.00",
      balance: "0.00",
      branch: "",
    });
    setResponseDatas([]);
    setPaymentHistory([]); // Reset payment history
    setProductForm({
      products: "",
      quantity: "1",
      rate: "",
      tax: "",
      value: "0.00",
      total: "0.00",
      availableQuantity: "0",
    });
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;

    if (name === "products") {
      const selectedProduct = productsMap.find(
        (product) => product._id === value
      );

      if (selectedProduct) {
        const hsnCode = selectedProduct.hsn_code;
        const stockItem = stockMap.find((stock) => stock.hsn_code === hsnCode);

        if (stockItem) {
          const basicPrice = parseFloat(stockItem.basic_price) || 0;
          const salesMargin = parseFloat(stockItem.sales_margin) || 0;
          const taxPercentage = parseFloat(stockItem.tax) || 0;
          const marginPrice = basicPrice * (salesMargin / 100);
          const rate = basicPrice + marginPrice;
          const taxValue = rate * (taxPercentage / 100);
          const quantity = 1;
          const total = (quantity * (rate + taxValue)).toFixed(2);

          setProductForm({
            ...productForm,
            products: value,
            hsn_code: hsnCode, // Preserve HSN code
            quantity: "1",
            availableQuantity: stockItem.available_quantity || "0",
            rate: rate.toFixed(2),
            tax: taxPercentage.toFixed(2),
            value: taxValue.toFixed(2),
            total: total,
          });
        } else {
          setProductForm({
            ...productForm,
            products: value,
            hsn_code: hsnCode,
            quantity: "1",
            availableQuantity: "0",
            rate: "0.00",
            tax: "0.00",
            value: "0.00",
            total: "0.00",
          });
        }
      } else {
        setProductForm({
          ...productForm,
          [name]: value,
          quantity: "1",
          availableQuantity: "0",
          rate: "0.00",
          tax: "0",
          value: "0.00",
          total: "0.00",
          hsn_code: "", // Reset HSN code
        });
      }
    } else if (name === "quantity") {
      const quantity = parseFloat(value) || 0;
      const rate = parseFloat(productForm.rate) || 0;
      const taxValue = parseFloat(productForm.value) || 0;
      const total = (quantity * (rate + taxValue)).toFixed(2);

      setProductForm({
        ...productForm,
        quantity: value,
        total: total,
      });
    } else if (name === "rate") {
      const rate = parseFloat(value) || 0;
      const quantity = parseFloat(productForm.quantity) || 0;
      const taxPercentage = parseFloat(productForm.tax) || 0;

      const taxValue = rate * (taxPercentage / 100);

      const total = (quantity * (rate + taxValue)).toFixed(2);

      setProductForm({
        ...productForm,
        rate: value,
        value: taxValue.toFixed(2),
        total: total,
      });
    } else if (name === "tax") {
      const taxPercentage = parseFloat(value) || 0;
      const rate = parseFloat(productForm.rate) || 0;
      const quantity = parseFloat(productForm.quantity) || 0;
      const taxValue = rate * (taxPercentage / 100);
      const total = (quantity * (rate + taxValue)).toFixed(2);

      setProductForm({
        ...productForm,
        tax: value,
        value: taxValue.toFixed(2),
        total: total,
      });
    } else if (name === "value") {
      const taxValue = parseFloat(value) || 0;
      const quantity = parseFloat(productForm.quantity) || 0;
      const rate = parseFloat(productForm.rate) || 0;
      const total = (quantity * (rate + taxValue)).toFixed(2);

      setProductForm({
        ...productForm,
        value: value,
        total: total,
      });
    } else {
      setProductForm({
        ...productForm,
        [name]: value,
      });
    }
  };

  const [editIndex, setEditIndex] = useState(-1);
  const handleEdit = (index) => {
    const item = responseDatas[index];
    setProductForm({
      products: item.products,
      quantity: item.quantity.toString(),
      rate: item.rate.toString(),
      tax: item.tax.toString(),
      value: item.value.toString(),
      total: item.total.toString(),
      availableQuantity: getAvailableQuantity(item.products),
    });
    setEditIndex(index);
  };

  // Helper function to get available quantity
  const getAvailableQuantity = (productId) => {
    const product = productsMap.find((p) => p._id === productId);
    if (product) {
      const stockItem = stockMap.find((s) => s.hsn_code === product.hsn_code);
      return stockItem ? stockItem.available_quantity : "0";
    }
    return "0";
  };

  const handleAdd = () => {
    const products = productForm.products;
    const hsn_code = productForm.hsn_code;
    const quantity = parseFloat(productForm.quantity) || 0;
    const rate = parseFloat(productForm.rate) || 0;
    const tax = parseFloat(productForm.tax) || 0;
    const value = parseFloat(productForm.value) || 0;

    // Calculate total: quantity * (rate + tax value)
    const total = parseFloat((quantity * (rate + value)).toFixed(2));

    if (!products) {
      toast.error("Please select a product");
      return;
    }

    if (quantity <= 0) {
      toast.error("Quantity must be greater than zero");
      return;
    }

    if (rate <= 0) {
      toast.error("Rate must be greater than zero");
      return;
    }

    // Add the item to response data
    if (editIndex >= 0) {
      // Update existing item
      const updatedItems = [...responseDatas];
      updatedItems[editIndex] = {
        products,
        hsn_code, // Include hsn_code
        quantity,
        rate,
        tax,
        value,
        total,
      };
      setResponseDatas(updatedItems);
      setEditIndex(-1); // Reset edit index
    } else {
      // Add new item
      setResponseDatas([
        ...responseDatas,
        {
          products,
          hsn_code, // Include hsn_code
          quantity,
          rate,
          tax,
          value,
          total,
        },
      ]);
    }
    // Reset product form
    setProductForm({
      products: "",
      hsn_code, // Include hsn_code
      quantity: "1",
      rate: "",
      tax: "",
      value: "0.00",
      total: "0.00",
      availableQuantity: "0",
    });
  };

  const handleAddPayment = () => {
    if (addData.paid && addData.paytype) {
      const newPayment = {
        id: Date.now(),
        details: `Payment for ${addData.sales_order_no}`,
        date: new Date().toISOString().split("T")[0],
        paid: addData.paid,
        paytype: addData.paytype,
        paidby: addData.paidby || "Unknown",
      };

      // Add to payment history
      const updatedPaymentHistory = [...paymentHistory, newPayment];
      setPaymentHistory(updatedPaymentHistory);

      // Calculate total paid amount
      const totalPaid = updatedPaymentHistory
        .reduce((sum, payment) => sum + parseFloat(payment.paid), 0)
        .toFixed(2);

      const newBalance = (
        parseFloat(addData.nettotal) - parseFloat(totalPaid)
      ).toFixed(2);

      // Update the state with the current payment details preserved
      setAddData((prevData) => ({
        ...prevData,
        paid: addData.paid, // Keep the current paid amount
        paytype: addData.paytype, // Keep the current payment type
        paidby: addData.paidby, // Keep the current paid by value
        balance: newBalance,
        payment_history: updatedPaymentHistory, // Add payment history to the main data
      }));
    } else {
      toast.error("Please enter payment amount and payment type");
    }
  };

  const handleDeletePayment = (id) => {
    const updatedPaymentHistory = paymentHistory.filter(
      (payment) => payment.id !== id
    );
    setPaymentHistory(updatedPaymentHistory);

    // Recalculate balance
    const totalPaid = updatedPaymentHistory
      .reduce((sum, payment) => sum + parseFloat(payment.paid), 0)
      .toFixed(2);

    const newBalance = (
      parseFloat(addData.nettotal) - parseFloat(totalPaid)
    ).toFixed(2);

    setAddData({
      ...addData,
      balance: newBalance,
    });
  };

  const handleDelete = (index) => {
    setResponseDatas(responseDatas.filter((_, i) => i !== index));
  };

  const [gstPercentage, setGstPercentage] = useState(18); // Default to 18%
  const [discountPercentage, setDiscountPercentage] = useState(0); // Default to 0%

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Handle different types of inputs
    if (type === "checkbox" && name === "gst_enabled") {
      // If GST checkbox is toggled
      const gstValue = checked
        ? (parseFloat(addData.subtotal) * (gstPercentage / 100)).toFixed(2)
        : "0.00";
      const newNetTotal = calculateNetTotal(
        addData.subtotal,
        gstValue,
        addData.discount
      );
      const newBalance = calculateBalance(newNetTotal, addData.paid);

      setAddData({
        ...addData,
        gst: gstValue,
        nettotal: newNetTotal,
        balance: newBalance,
      });
    } else if (name === "gstPercentage") {
      // Handle GST percentage change
      setGstPercentage(parseFloat(value) || 0);

      // Only recalculate if GST is enabled
      if (document.querySelector('input[name="gst_enabled"]').checked) {
        const gstValue = (
          parseFloat(addData.subtotal) *
          (parseFloat(value) / 100)
        ).toFixed(2);
        const newNetTotal = calculateNetTotal(
          addData.subtotal,
          gstValue,
          addData.discount
        );
        const newBalance = calculateBalance(newNetTotal, addData.paid);

        setAddData({
          ...addData,
          gst: gstValue,
          nettotal: newNetTotal,
          balance: newBalance,
        });
      }
    } else if (name === "discountPercentage") {
      // Handle discount percentage change
      const percentage = parseFloat(value) || 0;
      setDiscountPercentage(percentage);

      // Calculate discount amount based on percentage of subtotal
      const discountAmount = (
        parseFloat(addData.subtotal) *
        (percentage / 100)
      ).toFixed(2);

      // Update discount amount and recalculate totals
      const newNetTotal = calculateNetTotal(
        addData.subtotal,
        addData.gst,
        discountAmount
      );

      const newBalance = calculateBalance(newNetTotal, addData.paid);

      setAddData({
        ...addData,
        discount: discountAmount,
        nettotal: newNetTotal,
        balance: newBalance,
      });
    } else if (name === "discount") {
      // If user directly enters a discount amount instead of percentage
      const discountAmount = parseFloat(value) || 0;

      // Update discount amount and recalculate totals
      const newNetTotal = calculateNetTotal(
        addData.subtotal,
        addData.gst,
        discountAmount
      );

      const newBalance = calculateBalance(newNetTotal, addData.paid);

      // Also update the percentage based on the entered amount
      const percentage = (
        (discountAmount / parseFloat(addData.subtotal)) *
        100
      ).toFixed(2);
      setDiscountPercentage(percentage);

      setAddData({
        ...addData,
        discount: discountAmount,
        nettotal: newNetTotal,
        balance: newBalance,
      });
    } else if (name === "paid") {
      // For paid amount changes
      const newValue = value;
      const newBalance = calculateBalance(addData.nettotal, newValue);

      setAddData({
        ...addData,
        paid: newValue,
        balance: newBalance,
      });
    } else {
      // For other fields, just update the value
      setAddData({
        ...addData,
        [name]: value,
      });
    }
  };

  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div>
        <h5
          className="head"
          style={{ textDecoration: "underline", marginTop: "-80px" }}
        >
          New Sales Order
        </h5>
      </div>
      <hr></hr>
      {/* invoice no */}
      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-3">
            <label>Sales Order No:</label>
            <input
              type="text"
              className="form-control"
              name="sales_order_no"
              value={addData.sales_order_no}
              disabled
              readOnly // Prevent manual input
            />
          </div>

          {/* date */}
          <div className="col-lg-6"></div>

          <div className="col-lg-3">
            <label>Date:</label>
            <input
              type="date"
              className="form-control"
              name="date"
              value={addData.date}
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>
      {/* type */}

      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-3" ref={dropdownRef}>
            <label>Select Customer:</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                name="client_name"
                value={addData.client_name}
                onChange={handleCustomerFilter}
                onFocus={() => setShowCustomerDropdown(true)}
                placeholder="Type to search customers"
                required
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => setShowNewCustomerModal(true)}
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>

            {showCustomerDropdown && (
              <div
                className="dropdown-menu show w-100"
                style={{ display: "block", position: "absolute", zIndex: 1000 }}
              >
                {filteredCustomers.length > 0 ? (
                  filteredCustomers.map((customer, index) => (
                    <button
                      key={index}
                      className="dropdown-item"
                      type="button"
                      onClick={() => selectCustomer(customer)}
                    >
                      {customer.name}
                    </button>
                  ))
                ) : (
                  <button className="dropdown-item" type="button" disabled>
                    No customers found
                  </button>
                )}
              </div>
            )}
          </div>

          {/*  email*/}
          <div className="col-lg-3">
            <label>Email:</label>
            <input
              type="text"
              className="form-control"
              name="email"
              value={addData.email}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  email: e.target.value,
                })
              }
              required
            ></input>
          </div>
          {/* mobile */}
          <div className="col-lg-3">
            <label>Mobile:</label>
            <input
              type="text"
              className="form-control"
              name="mobile"
              value={addData.mobile}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  mobile: e.target.value,
                })
              }
              required
            ></input>
          </div>
          <div className="col-lg-3">
            <label>Branch:</label>
            <input
              type="text"
              className="form-control"
              name="branch"
              value={addData.branch_id}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  branch_id: e.target.value,
                })
              }
              required
            ></input>
          </div>
          {/* add button */}
          <div className="col-lg-1 mt-4"></div>
        </div>
        <div className="col-lg-2"></div>
      </div>

      {/* Customer Modal */}
      {showNewCustomerModal && (
        <div
          className="modal"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.4)" }}
        >
          <div className="modal-dialog">
            <Modal
              className="addmodals"
              show={showNewCustomerModal}
              onHide={() => setShowNewCustomerModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Customer</Modal.Title>
              </Modal.Header>

              <form onSubmit={submitNewCustomer}>
                <Modal.Body>
                  <div className="row">
                    <div className="col-lg-6 ">
                      <div className="floating-label ">
                        <select
                          className="form-select"
                          name="branch_id"
                          value={newCustomer.branch_id}
                          onChange={handleNewCustomerChange}
                          required
                        >
                          <option value="">Select Branch</option>
                          {branches.map((branch) => (
                            <option key={branch._id} value={branch._id}>
                              {branch.branch_name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          name="customer_id"
                          value={newCustomer?.customer_id || ""}
                          onChange={handleNewCustomerChange}
                          disabled
                          required
                        />

                        <label className="">Customer ID</label>
                      </div>

                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          value={newCustomer.name}
                          onChange={handleNewCustomerChange}
                          required
                        />
                        <label className={newCustomer.name ? "filled" : ""}>
                          Name
                        </label>
                      </div>

                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="tel"
                          name="mobile"
                          placeholder=""
                          value={newCustomer.mobile}
                          onChange={handleNewCustomerChange}
                          required
                        />
                        <label className={newCustomer.mobile ? "filled" : ""}>
                          Mobile
                        </label>
                      </div>

                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder=""
                          value={newCustomer.email}
                          onChange={handleNewCustomerChange}
                          required
                        />
                        <label className={newCustomer.email ? "filled" : ""}>
                          Email
                        </label>
                      </div>

                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="company_name"
                          placeholder=""
                          value={newCustomer.company_name}
                          onChange={handleNewCustomerChange}
                        />
                        <label
                          className={newCustomer.company_name ? "filled" : ""}
                        >
                          Company Name
                        </label>
                      </div>

                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="door_no"
                          placeholder=""
                          value={newCustomer.door_no}
                          onChange={handleNewCustomerChange}
                        />
                        <label className={newCustomer.door_no ? "filled" : ""}>
                          Door No
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-6 ">
                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="street_name"
                          placeholder=""
                          value={newCustomer.street_name}
                          onChange={handleNewCustomerChange}
                        />
                        <label
                          className={newCustomer.street_name ? "filled" : ""}
                        >
                          Street Name
                        </label>
                      </div>

                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="area"
                          placeholder=""
                          value={newCustomer.area}
                          onChange={handleNewCustomerChange}
                        />
                        <label className={newCustomer.area ? "filled" : ""}>
                          Area
                        </label>
                      </div>

                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="pin_code"
                          placeholder=""
                          value={newCustomer.pin_code}
                          onChange={(e) => {
                            setNewCustomer({
                              ...newCustomer,
                              pin_code: e.target.value,
                            });
                          }}
                        />
                        <label className={newCustomer.pin_code ? "filled" : ""}>
                          Pin Code
                        </label>
                      </div>

                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="gst_no"
                          placeholder=""
                          value={newCustomer.gst_no}
                          onChange={(e) => {
                            setNewCustomer({
                              ...newCustomer,
                              gst_no: e.target.value,
                            });
                          }}
                        />
                        <label className={newCustomer.gst_no ? "filled" : ""}>
                          GST Number
                        </label>
                      </div>

                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="notes"
                          placeholder=""
                          value={newCustomer.notes}
                          onChange={(e) => {
                            setNewCustomer({
                              ...newCustomer,
                              notes: e.target.value,
                            });
                          }}
                        />
                        <label className={newCustomer.notes ? "filled" : ""}>
                          Notes
                        </label>
                      </div>

                      <div className="floating-label ">
                        <select
                          className="form-select"
                          name="status"
                          value={newCustomer.status}
                          onChange={(e) => {
                            setNewCustomer({
                              ...newCustomer,
                              status: e.target.value,
                            });
                          }}
                          required
                        >
                          <option value="">Select Status</option>
                          <option value="Active">Active</option>
                          <option value="InActive">InActive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    className="cancelbut"
                    variant="secondary"
                    onClick={() => setShowNewCustomerModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button className="submitbut" type="submit" variant="primary">
                    Submit
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>
        </div>
      )}

      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-3">
            <label>Products:</label>
            <select
              className="form-control"
              name="products"
              value={productForm.products}
              onChange={handleProductChange}
            >
              <option value="">Select Product</option>
              {productsMap.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.hsn_code} - {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-3">
            <label>HSN Code:</label>
            <input
              type="text"
              className="form-control"
              value={productForm.hsn_code}
              disabled
              readOnly
            />
          </div>
          <div className="col-lg-3">
            <label>Available Qty:</label>
            <input
              type="text"
              className="form-control"
              value={productForm.availableQuantity}
              disabled
              readOnly
            />
          </div>
          <div className="col-lg-3">
            <label>Qty:</label>
            <input
              type="text"
              className="form-control"
              name="quantity"
              value={productForm.quantity}
              onChange={handleProductChange}
              required
            />
          </div>
        </div>

        <div className="row first">
          <div className="col-lg-2">
            <label>Qty:</label>
            <input
              type="text"
              className="form-control"
              name="quantity"
              value={productForm.quantity}
              onChange={handleProductChange}
              required
            />
          </div>

          <div className="col-lg-1">
            <label>Rate:</label>
            <input
              type="text"
              className="form-control"
              name="rate"
              value={productForm.rate}
              onChange={handleProductChange}
              required
            />
          </div>

          <div className="col-lg-1">
            <label>Tax (%):</label>
            <input
              type="text"
              className="form-control"
              name="tax"
              value={productForm.tax}
              onChange={handleProductChange}
              required
            />
          </div>

          <div className="col-lg-1">
            <label>Value:</label>
            <input
              type="text"
              className="form-control"
              name="value"
              value={productForm.value}
              onChange={handleProductChange}
              required
            />
          </div>

          <div className="col-lg-2">
            <label>Total:</label>
            <input
              type="text"
              className="form-control"
              value={productForm.total}
              readOnly
            />
          </div>

          <div className="col-lg-1 mt-4">
            <div className="" style={{ marginTop: "-9px" }}>
              <button
                type="button"
                className="btn btn-secondary btn-sm m-3"
                onClick={handleAdd}
              >
                Add
              </button>
            </div>
          </div>
        </div>

        {/* <div className="col-lg-2">
            <label>Products:</label>
            <select
              className="form-control"
              name="products"
              value={productForm.products}
              onChange={handleProductChange}
            >
              <option value="">Select Product</option>
              {productsMap.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.hsn_code} - {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-lg-2">
            <label>Available Qty:</label>
            <input
              type="text"
              className="form-control"
              value={productForm.availableQuantity}
              disabled
              readOnly
            />
          </div>

          <div className="col-lg-2">
            <label>Qty:</label>
            <input
              type="text"
              className="form-control"
              name="quantity"
              value={productForm.quantity}
              onChange={handleProductChange}
              required
            />
          </div>

          <div className="col-lg-1">
            <label>Rate:</label>
            <input
              type="text"
              className="form-control"
              name="rate"
              value={productForm.rate}
              onChange={handleProductChange}
              required
            />
          </div>

          <div className="col-lg-1">
            <label>Tax (%):</label>
            <input
              type="text"
              className="form-control"
              name="tax"
              value={productForm.tax}
              onChange={handleProductChange}
              required
            />
          </div>

          <div className="col-lg-1">
            <label>Value:</label>
            <input
              type="text"
              className="form-control"
              name="value"
              value={productForm.value}
              onChange={handleProductChange}
              required
            />
          </div>

          <div className="col-lg-2">
            <label>Total:</label>
            <input
              type="text"
              className="form-control"
              value={productForm.total}
              readOnly
            />
          </div>

          <div className="col-lg-1 mt-4">
            <div className="" style={{ marginTop: "-9px" }}>
              <button
                type="button"
                className="btn btn-secondary btn-sm m-3"
                onClick={handleAdd}
              >
                Add
              </button>
            </div>
          </div> */}
      </div>

      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-2">
            <label>CUSPUR Order No:</label>
            <input
              type="text"
              className="form-control"
              name="paid_till_date"
              value={addData.paid_till_date}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  paid_till_date: e.target.value,
                })
              }
            ></input>
          </div>

          <div className="col-lg-2">
            <label>Purchase Date:</label>
            <input
              type="date"
              className="form-control"
              name="payment_due_date"
              value={addData.payment_due_date}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  payment_due_date: e.target.value,
                })
              }
            ></input>
          </div>

          <div className="col-lg-2">
            <label>Next Due Date:</label>
            <input
              type="date"
              className="form-control"
              name="next_due_date"
              value={addData.next_due_date}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  next_due_date: e.target.value,
                })
              }
            ></input>
          </div>

          <div className="col-lg-3">
            <label>Person Name:</label>
            <input
              type="text"
              className="form-control"
              name="person_name"
              value={addData.person_name}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  person_name: e.target.value,
                })
              }
            />
          </div>

          <div className="col-lg-3">
            <label>Contact:</label>
            <input
              type="text"
              className="form-control"
              name="contact"
              value={addData.contact}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  contact: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>

      {/* ////////////////////////////////////////////////////////////////////////////// */}
      <div className="card invoice_card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>S.NO</th>
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Tax (%)</th>
                  <th>Tax Value</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {responseDatas.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{getProductNameById(item.products)}</td>
                    <td>{item.quantity}</td>
                    <td>{item.rate}</td>
                    <td>{item.tax}</td>
                    <td>
                      {item.value || ((item.rate * item.tax) / 100).toFixed(2)}
                    </td>
                    <td>{item.total.toFixed(2)}</td>
                    <td>
                      <i
                        className="fa-solid fa-pen-to-square mx-2 fs-5"
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => handleEdit(index)}
                      ></i>
                      <i
                        className="fa-sharp-duotone fa-solid fa-circle-xmark mx-2 fs-2"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleDelete(index)}
                      ></i>
                    </td>
                  </tr>
                ))}
                {responseDatas.length === 0 && (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No items added
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card invoice_card p-3">
        <div className="row">
          <h5>Previous Payment History</h5>
          <div className="col-lg-7">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Details</th>
                  <th>Date</th>
                  <th>Paid</th>
                  <th>Paytype</th>
                  <th>Paid By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory.map((payment, index) => (
                  <tr key={payment.id}>
                    <td>{index + 1}</td>
                    <td>{payment.details}</td>
                    <td>{payment.date}</td>
                    <td>{payment.paid}</td>
                    <td>{payment.paytype}</td>
                    <td>{payment.paidby}</td>
                    <td>
                      <i
                        className="fa-sharp-duotone fa-solid fa-circle-xmark mx-2 fs-5"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleDeletePayment(payment.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
                {paymentHistory.length === 0 && (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No payment history
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="col-lg-5 ">
            <div className="row">
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Subtotal</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="subtotal"
                  value={addData.subtotal}
                  readOnly
                ></input>
              </div>
              {/* gst */}
              <div
                className="col-lg-5 col-md-5 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <p>
                  GST{" "}
                  <input
                    type="checkbox"
                    name="gst_enabled"
                    onChange={handleChange}
                  />
                </p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <div className="d-flex" style={{ width: "80%" }}>
                  <input
                    type="number"
                    className="form-control mr-2"
                    name="gstPercentage"
                    value={gstPercentage}
                    onChange={handleChange}
                    style={{ width: "40%" }}
                    placeholder="GST %"
                  />
                  <input
                    style={{ width: "60%" }}
                    placeholder="0.00"
                    type="number"
                    className="form-control"
                    name="gst"
                    value={addData.gst}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Discount Section */}
              <div
                className="col-lg-5 col-md-5 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <p>Discount</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <div className="d-flex" style={{ width: "80%" }}>
                  <input
                    type="number"
                    className="form-control mr-2"
                    name="discountPercentage"
                    value={discountPercentage}
                    onChange={handleChange}
                    style={{ width: "40%" }}
                    placeholder="Discount %"
                  />
                  <input
                    style={{ width: "60%" }}
                    placeholder="0.00"
                    type="number"
                    className="form-control"
                    name="discount"
                    value={addData.discount}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Nettotal</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="nettotal"
                  value={addData.nettotal}
                  readOnly
                ></input>
              </div>
              {/* paytype */}
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Paytype</p>
              </div>{" "}
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <select
                  className="form-control paytype w-50"
                  name="paytype"
                  value={addData.paytype}
                  onChange={handleChange}
                  style={{ width: "80%" }}
                >
                  <option value="">Select Paytype</option>
                  <option value="gpay">GPay</option>
                  <option value="phonepe">PhonePe</option>
                  <option value="cash">Cash</option>
                  <option value="paytm">Paytm</option>
                </select>
              </div>
              {/* paid_by */}
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Paid By</p>
              </div>{" "}
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <select
                  className="form-control paytype"
                  name="paidby"
                  value={addData.paidby}
                  onChange={handleChange}
                  style={{ width: "80%" }}
                >
                  <option value="">Select Payment Paid By</option>
                  <option value="Client">Client</option>
                  <option value="Company">Company</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              {/* paid */}
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Paid</p>
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="paid"
                  value={addData.paid}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="col-lg-2">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleAddPayment}
                >
                  Add
                </button>
              </div>
              {/* balance */}
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Balance</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="balance"
                  value={addData.balance}
                  readOnly
                ></input>
              </div>
            </div>

            <div className="row">
              <div className="d-flex justify-content-evenly mt-3 buttonstyle ">
                <button
                  type="button"
                  className="btn btn-danger invoice-button"
                  onClick={resetForm}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-info text-white invoice-button"
                  style={{ backgroundColor: "#007BFF" }}
                  onClick={resetForm}
                >
                  Clear
                </button>

                <button
                  type="button"
                  className="btn btn-secondary invoice-button"
                  onClick={handleSubmit}
                >
                  Save
                </button>

                <button
                  type="button"
                  className="btn btn-success invoice-button"
                  onClick={() => {
                    handleSubmit();
                    window.print();
                  }}
                >
                  Save&Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddSalesInvoice;
