// import { useRef } from "react";
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";
// import "../../assets/css/AnnexurePreview.css";

// function AnnexurePreview() {
//   const pdfRef = useRef(); // Reference for PDF content

//   const handleDownload = async () => {
//     const element = pdfRef.current;
//     const canvas = await html2canvas(element);
//     const imgData = canvas.toDataURL("image/png");

//     const pdf = new jsPDF("p", "mm", "a4");
//     const imgWidth = 210;
//     const imgHeight = (canvas.height * imgWidth) / canvas.width;

//     pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
//     pdf.save("AnnexurePreview.pdf");
//   };

//   return (
//     <>
//       <div className="text-end mb-3" style={{ marginTop: "-60px" }}>
//         <button className="btn btn-primary" onClick={handleDownload}>
//           Download PDF
//         </button>
//       </div>

//       {/* PDF Preview Section */}
//       <div ref={pdfRef} style={{ padding: "20px", background: "#fff" }}>
//         <div className="box_border">
//           <div>
//             <div
//               className=""
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 gap: "20px",
//                 padding: "10px",
//               }}
//             >
//               {/* Logo Image */}
//               <img
//                 alt="invoice logo"
//                 style={{
//                   width: "400px",
//                   objectFit: "contain",
//                   marginLeft: "-30px",
//                 }}
//                 src={require("../../assets/img/scimitar logo slogan.png")}
//               />
//               <div className="horizontal"></div>
//               {/* Text Content */}
//               <div style={{ textAlign: "left" }}>
//                 <h6 className="fs-5 bold text-center">Our Services</h6>
//                 <h6 className="">* ALL PURPOSE MACHINE RETROFIT</h6>
//                 <h6 className="">* PLC, HMI, SCADA, VFD PROGRAMMING</h6>
//                 <h6 className="">
//                   * ELECTRICAL AND PLC CONTROL PANEL DISTRIBUTOR
//                 </h6>
//               </div>
//             </div>
//           </div>
//           <div className="bordertop p-2">
//             <div className="d-flex">
//               <div className="" style={{ width: "60%" }}>
//                 <h6 className="">To: </h6>
//                 <h6 className="">CONTACT PERSON: </h6>
//                 <h6 className="">PH: </h6>
//                 <h6 className="">EMAIL: </h6>
//               </div>
//               <div className="second_row_start " style={{ width: "20%" }}>
//                 <div className="border_down serial_side" style={{height: '40px'}}>

//                   <h6 className="">SERIAL NO</h6>
//                 </div>
//                 <div className="border_down serial_side" style={{height: '40px'}}>
//                   <h6 className="">Date</h6>
//                 </div>
//                 <div className="serial_side" style={{height: '40px'}}>
//                   <h6 className="">Validity Till</h6>
//                 </div>
//               </div>
//               <div className="second_row_start" style={{ width: "20%" }}>
//                 <div className="border_down serial_side" style={{height: '40px'}}>

//                   <h6 className="">SERIAL NO</h6>
//                 </div>
//                 <div className="border_down serial_side" style={{height: '40px'}}>
//                   <h6 className="">Date</h6>
//                 </div>
//                 <div className="serial_side" style={{height: '40px'}}>
//                   <h6 className="">Validity Till</h6>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="bordertop " style={{height: '150px'}}>
//             <h6 className="">Project Description:</h6>
//           </div>
//           <div className=" p-2">
//             <h6 className="">ANNEXURE 1:QUOTATION:</h6>
//             <h6 className="">ANNEXURE 2:BILL OF MATERIALS:</h6>{" "}
//             <h6 className="">ANNEXURE 3:TERMS AND CONDITION:</h6>
//             <p className="mt-5">
//               We hope you will find the offer in line with your requirement.
//               <br></br> Thanking you and assuring our best and prompt services
//               at all times.<br></br> Awaiting{" "}
//             </p>
//           </div>
//           <h6 className="">ANNEXURE 1:QUOTATION</h6>
//           <table className="bordertop p-2">
//            <thead>
//             <tr>
//               <th>S.No</th>
//               <th>DESCRIPTION</th>
//               <th>QTY</th>
//               <th>BASIC PRICE</th>
//               <th>Tax Amount</th>
//               <th>Total</th>
//             </tr>
//            </thead>
//            <tbody>
//             <tr>
//               <td>1</td>
//               <td>asdf</td>
//               <td>adf</td>
//               <td>1asdf</td>
//               <td>asdf</td>
//               <td>asdf</td>
//             </tr>
//             <tr>
//               <td>Total</td>
//               <td>{Qty value}</td>
//               <td>{Basic Price}</td>
//               <td>{Tax Amount}</td>
//               <td>{Total}</td>
//             </tr>
//            </tbody>
//           </table>
//         </div>
//       </div>
//     </>
//   );
// }

// export default AnnexurePreview;

import { useEffect, useRef, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "../../assets/css/AnnexurePreview.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import ApiUrl from "../Environment/ApiUrl";

function AnnexurePreview() {
  const pdfRef = useRef(); // Reference for PDF content

  const handleDownload = async () => {
    const element = pdfRef.current;
    const canvas = await html2canvas(element, { scale: 2 }); // Higher quality PDF
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    pdf.save("AnnexurePreview.pdf");
  };
  const { id } = useParams();
  const [data, setData] = useState("");
  useEffect(() => {
    const fetchQuotation = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/quotation_list/${id}`);
        console.log("Fetched Data:", response.data); // Log the fetched data
        setData(response.data); // Store fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    if (id) fetchQuotation();
  }, [id]);

  return (
    <>
      <div className="text-end mb-3" style={{ marginTop: "-60px" }}>
        <button className="btn btn-primary" onClick={handleDownload}>
          Download PDF
        </button>
      </div>

      {/* PDF Preview Section */}
      <div ref={pdfRef} style={{ padding: "20px", background: "#fff" }}>
        <div className="box_border">
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                padding: "10px",
              }}
            >
              <img
                alt="invoice logo"
                style={{
                  width: "400px",
                  objectFit: "contain",
                  marginLeft: "-30px",
                }}
                src={require("../../assets/img/scimitar logo slogan.png")}
              />
              <div className="horizontal"></div>
              <div style={{ textAlign: "left" }}>
                <h6 className="fs-5 bold text-center">Our Services</h6>
                <h6>* ALL PURPOSE MACHINE RETROFIT</h6>
                <h6>* PLC, HMI, SCADA, VFD PROGRAMMING</h6>
                <h6>* ELECTRICAL AND PLC CONTROL PANEL DISTRIBUTOR</h6>
              </div>
            </div>
          </div>
          <div className="bordertop p-2">
            <div className="d-flex">
              <div style={{ width: "60%" }}>
                <h6>To: {data.name_id?.name}</h6>
                <h6>CONTACT PERSON: {data.name_id?.name}</h6>
                <h6>PH: {data.name_id?.quotation_no}</h6>
                <h6>EMAIL: {data.name_id?.official_email}</h6>
              </div>

              <div className="second_row_start " style={{ width: "20%" }}>
                <div
                  className="border_down serial_side"
                  style={{ height: "40px" }}
                >
                  <h6 className="">SERIAL NO</h6>
                </div>
                <div
                  className="border_down serial_side"
                  style={{ height: "40px" }}
                >
                  <h6 className="">Date</h6>
                </div>
                <div className="serial_side" style={{ height: "40px" }}>
                  <h6 className="">Validity Till</h6>
                </div>
              </div>
              <div className="second_row_start" style={{ width: "20%" }}>
                <div
                  className="border_down serial_side"
                  style={{ height: "40px" }}
                >
                  <h6 className="">{data.quotation_no}</h6>
                </div>
                <div
                  className="border_down serial_side"
                  style={{ height: "40px" }}
                >
                  <h6 className="">{data.date}</h6>
                </div>
                <div className="serial_side" style={{ height: "40px" }}>
                  <h6 className="">{data.valid_tilldate}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="bordertop p-2" style={{ height: "150px" }}>
            <h6>Project Description:</h6>
            <p>{data.project_description}</p>
          </div>
          <div className="p-2">
            <h6>ANNEXURE 1: QUOTATION</h6>
            <h6>ANNEXURE 2: BILL OF MATERIALS</h6>
            <h6>ANNEXURE 3: TERMS AND CONDITIONS</h6>
            <p className="mt-5">
              We hope you find the offer suitable for your needs.
              <br />
              Thank you, and we assure our best services at all times.
            </p>
          </div>
          <h6 className="p-2">ANNEXURE 1: QUOTATION</h6>
          <table className="bordertop">
            <thead>
              <tr className="tbody_line text-center total_leftLine">
                <th className="p-2 total_leftLine">S.No</th>
                <th className="total_leftLine">DESCRIPTION</th>
                <th className="total_leftLine">QTY</th>
                <th className="total_leftLine">BASIC PRICE</th>
                <th className="total_leftLine">Tax Amount</th>
                <th className="total_leftLine">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr className=" text-center">
                <td className="total_leftLine"> 1</td>
                <td className="total_leftLine">{data.annexure_description_one}</td>
                <td className="total_leftLine">{data.annexure_qty_one}</td>
                <td className="total_leftLine">{data.basic_price}</td>
                <td className="total_leftLine">{data.tax}</td>
                <td className="total_leftLine">{data.total_price}</td>
              </tr>
              <tr className="tbody_line text-center">
                <td className="total_leftLine" colSpan="2">
                  Total
                </td>
                <td className="total_leftLine">0</td>
                <td className="total_leftLine">0</td>
                <td className="total_leftLine">0</td>
                <td>{data.total_price}</td>
              </tr>
            </tbody>
          </table>

          <table className="bordertop mt-5">
            <thead>
              <tr className="tbody_line text-center">
                <td className="total_leftLine" colSpan="2">
                  Discount
                </td>
                <td className="total_leftLine">0</td>
                <td className="total_leftLine">0</td>
                <td className="total_leftLine">0</td>
                <td className="total_leftLine">0</td>

                <td>{data.discount}</td>
              </tr>
            </thead>
          </table>

          <h6 className="p-2 mt-4">ANNEXURE 2: BILL OF MATERIALS</h6>
          <table className="bordertop">
            <thead>
              <tr className="tbody_line text-center total_leftLine">
                <th className="p-2 total_leftLine">S.No</th>
                <th className="total_leftLine">DESCRIPTION</th>
                <th className="total_leftLine">QTY</th>
                <th className="total_leftLine">MAKE</th>
              </tr>
            </thead>
            <tbody>
              <tr className=" text-center" style={{ height: "100px" }}>
                <td className="total_leftLine">1</td>
                <td className="total_leftLine">{data.annexure_description_two}</td>
                <td className="total_leftLine">{data.annexure_qty_two}</td>
                <td className="total_leftLine">{data.make}</td>
              </tr>
            </tbody>
          </table>
          <h6 className="p-2 mt-4">ANNEXURE 3: TERMS AND CONDITIONS</h6>

          <div className="">
            <h6 className="p-2 mt-4">DELIVERY:</h6>
            <p className="text-justify p-2">
              Ex -works delivery 30 working days from the date of receipt of
              drawing approval. Drawing submission 7 working days from the date
              of receipt of contract coming in to Force i.e. receipt of techno-
              commercially clear order along with advance where applicable.
              PRICE BASE: Ex-Works, Hosur and are Firm within the validity
              period of
            </p>

            <h6 className="p-2 mt-4">PAYMENT TERMS:</h6>
            <p className="text-justify p-2">
              90% payment advance on value along with PO as mentioned in perform
              invoice for material which includes tax.Balance 10% within 7 Days
              from the date of Material
            </p>

            <h6 className="p-2 mt-4">CONTRACT COMING INTO FORCE:</h6>
            <p className="text-justify p-2">
              After fulfilling all the following conditions, receipt of
              confirmed Purchase order, receipt of advance payment &
              clarification of all technical and commercial points
            </p>
            <h6 className="p-2 mt-4">FRIGHT:</h6>
            <p className="text-justify p-2">
              Fright charges shall be as per expens
            </p>

            <h6 className="p-2 mt-4">INSURANCE:</h6>
            <p className="text-justify p-2">Shall be extra cost at actual.</p>

            <h6 className="p-2 mt-4">RISK PURCHASE:</h6>
            <p className="text-justify p-2">
              CONSEQUENTIAL DAMAGES/LATENT DEFECTS: Shall not be accept
            </p>

            <h6 className="p-2 mt-4">Yours faithfully</h6>
          </div>
          <div className="d-flex">
            <img className="" alt="" src={require("../../assets/img/scimitar logo slogan.png")} style={{width: '200px', height: '100px'}}></img>
            <p className="mx-5">SKIMITAR HINDUSTHAN PVT LTD. <br></br>GH, back side, 29/30, Pavadai St, <br></br> Kaikolar Thottam, Erode, Tamil Nadu <br></br>
            63800</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnnexurePreview;
