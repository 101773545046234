// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Modal, Button } from "react-bootstrap";
// import apiUrl from "./../Environment/ApiUrl";
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ApiUrl from "./../Environment/ApiUrl";
import "../../assets/css/Common.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function IncomeType() {

  const [responseData, setResponseData] = useState([]);
  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "	Income Type Name",
        accessor: "type",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  // const [branch, setbranch] = useState([]);
  // const [role_id, setroleid] = useState("");
  // const [previllages, setprevillages] = useState({});

  const [addData, setAddData] = useState({
    // branch_id: '',
    type: "",
  });

  const [updateData, setUpdateData] = useState({
    // branch_id: '',
    type: "",
  });

  useEffect(() => {
    fetchData();
    // fetchDataed();
    // gettoken();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/income_type", {
        params: { data: localStorage.getItem("branch_id") },
      });
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // const fetchDataed = async () => {
  //   try {
  //     const response = await axios.get(ApiUrl + "/Branch");
  //     console.log("Response from server:", response.data);
  //     setbranch(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };

  // const gettoken = async () => {
  //   const token = localStorage.getItem("token");

  //   try {
  //     const response = await axios.post(ApiUrl + "/get_detail", {
  //       token: token,
  //     });
  //     console.log("token detail from server:", response.data);

  //     setroleid(response.data.detail.rolename);

  //     localStorage.setItem("role_id", response.data.detail.rolename);

  //     const id = response.data.detail.rolename;

  //     if (id) {
  //       try {
  //         const response = await axios.get(ApiUrl + `/role_2/${id}`);

  //         const modules = response.data.modules;

  //         const filtered_access = modules.filter(
  //           (item) => item.name == "Branch"
  //         );

  //         setprevillages(filtered_access[0].actions);
  //       } catch (error) {
  //         console.error("Error fetching data:", error.message);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };

  //     const handleNewButtonClick = () => {
  //         if (previllages.add || previllages.all) {
  //         setShowModal(true);
  //     }else{
  //         toast.error("You don't have the permission to add");
  //     }
  // }

  const handlesubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    try {
      const formData = {
        // branch_id: addData.branch_id,
        type: addData.type,
      };
      const response = await axios.post(ApiUrl + "/income_type", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("added successfully!");
      setAddData({
        // branch_id: '',
        type: "",
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    // if (previllages.edit || previllages.all) {
    setUpdateId(_id);
    console.log(" the data to updata", data);
    setUpdateShowModal(true);
    setUpdateData({
      // branch_id: data.branch_id,
      type: data.type,
    });
    // } else {
    //   toast.error("You don't have the permission to update");
    // }
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        // branch_id: updateData.branch_id,
        type: updateData.type,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        ApiUrl + `/income_type/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        ApiUrl + `/income_type/${getdeleteid}`
      );
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, name) => {
    // if (previllages.all || previllages.delete) {
    console.log(" the id", _id);
    console.log(" the name", name);
    setdeleteid(_id);
    setdeletename(name);
    setDeleteShowModals(true);
    // } else {
    //   toast.error("You don't have the permission");
    // }
  };

  // the pagination ---------------------------------------------------------------------
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  // const [searchQuery, setSearchQuery] = useState("");
  // // Pagination
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // // Search
  // const filtered = currentItems.filter((item) =>
  //   Object.values(item).some(
  //     (value) =>
  //       (typeof value === "string" &&
  //         value.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //       (typeof value === "number" && value.toString().includes(searchQuery))
  //   )
  // );

  // const handleSearchChange = (event) => {
  //   setSearchQuery(event.target.value);
  //   setCurrentPage(1);
  // };

  // const totalPages = Math.ceil(responseData.length / itemsPerPage);

  // const goToNextPage = () => {
  //   setCurrentPage((prevPage) =>
  //     prevPage === totalPages ? prevPage : prevPage + 1
  //   );
  // };

  // const goToPrevPage = () => {
  //   setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  // };
  // the pagination  end ---------------------------------------------------------------------

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    let isValid = true;

    if (!addData.type) {
      tempErrors.type = "Type is required.";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/incomelist" className="text-secondary dashBoard_text">
              <b>Income List / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Income Type</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <h6 className="mt-2 bold"> Income Type</h6>

        <div className="">
          {/* {previllages.add && ( */}
          <button
            className="btn btn-dark btn-sm add mx-3"
            onClick={() => setShowModal(true)}
          >
            Add{" "}
          </button>
          {/* )} */}
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      {/* <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
     

      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Income Type Name</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.type}</td>
                  <td className="text-center">
                    <button
                      className="btn updelete mx-2"
                      onClick={() => handleUpdate(item._id, item)}
                    >
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button
                      className="btn delete"
                      onClick={() => handleOpenModal(item._id, item.type)}
                    >
                      <i class="fa-solid fa-trash-can"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    <button
                      className="btn custom-btn-update btn-sm"
                      onClick={() =>
                        handleUpdate(row.original._id, row.original)
                      }
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button
                      className="btn custom-btn-delete btn-sm mx-1 "
                      onClick={() =>
                        handleOpenModal(row.original._id, row.original.type)
                      }
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      {/* Modal for adding a new user */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Income Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="floating-label">
              <input
                className="form-control"
                placeholder=""
                type="text"
                value={addData.type}
                onChange={(e) =>
                  setAddData({ ...addData, type: e.target.value })
                }
                required
              />
              <label className={addData.type ? "filled" : ""}>
                Income Type Name
              </label>
              {errors.type && <div className="error-text">{errors.type}</div>}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handlesubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Income Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="form-group">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.type}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, type: e.target.value })
                  }
                  required
                />
                <label className={updateData.type ? "filled" : ""}>
                  Income Type Name
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Income Type Name :</h5>{" "}
            <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* _------------------------------pagination button -------------------------------- */}
      {/* <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>
        </div>
      </div> */}

      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------end ---------------------------------- */}
    </>
  );
}

export default IncomeType;
