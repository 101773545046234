// // // import { useEffect, useState } from "react";
// // // import { Link } from "react-router-dom";
// // // import "../../assets/css/ExpectedHistory.css";
// // // import ApiUrl from "../Environment/ApiUrl";
// // // import axios from "axios";

// // // const Ongoinginfo = () => {
// // //   const [ongoingList, setOngoingList] = useState([]);

// // //   useEffect(() => {
// // //     fetchOngoingList();
// // //   }, []);

// // //   const fetchOngoingList = async () => {
// // //     try {
// // //       const response = await axios.get(`${ApiUrl}/ongoingbillmaterial`);
// // //       setOngoingList(response.data);
// // //       console.log("Error fetching ongoing list:", response);

// // //     } catch (error) {
// // //       console.error("Error fetching ongoing list:", error.message);
// // //     }
// // //   };

// // //   return (
// // //     <>
// // //       <div className="container mt-4">
// // //         <div className="row mb-3" style={{ marginTop: "-80px" }}>
// // //           <div className="col-lg-12">
// // //             <Link to="/dashboard" className="text-secondary dashBoard_text">
// // //               <b>Dashboard / </b>
// // //             </Link>
// // //             <Link to="/dashboard" className="text-secondary dashBoard_text">
// // //               <b>Projects / </b>
// // //             </Link>
// // //             <Link to="" className="text-danger dashBoard_text">
// // //               <b>Expected / </b>
// // //             </Link>
// // //             <Link to="" className="text-danger dashBoard_text">
// // //               <b>Project History</b>
// // //             </Link>
// // //           </div>
// // //         </div>

// // //         <div className="card p-4 shadow-lg" style={{ minHeight: "350px" }}>
// // //           <h3 className="text-center mb-4">Project Overall Details</h3>

// // //           <div className="row h-100">
// // //             <div className="col-lg-6 border-end d-flex flex-column justify-content-center align-items-start p-4 gap-2">
// // //               <p>
// // //                 <i className="fa-solid fa-user me-2"></i> <b>Name:</b> {ongoingList[0]?.expected_Details?.name}
// // //               </p>
// // //               <p>
// // //                 <i className="fa-solid fa-building me-2"></i>{" "}
// // //                 <b>Company Name:</b> Aalan Tech Soft
// // //               </p>
// // //               <p>
// // //                 <i className="fa-solid fa-envelope me-2"></i>{" "}
// // //                 <b>Official Email:</b> Siva@gmail.com
// // //               </p>
// // //               <p>
// // //                 <i className="fa-solid fa-phone me-2"></i>{" "}
// // //                 <b>Official Mobile:</b> 8574838292
// // //               </p>
// // //               <p>
// // //                 <i className="fa-solid fa-map-marker-alt me-2"></i>{" "}
// // //                 <b>Location:</b> 13/B Anna Nagar
// // //               </p>
// // //               <p>
// // //                 <i className="fa-solid fa-globe me-2"></i> <b>Source Type:</b>{" "}
// // //                 Website
// // //               </p>
// // //             </div>

// // //             <div className="col-lg-6 d-flex flex-column justify-content-center align-items-start p-4 gap-2">
// // //               <p>
// // //                 <i className="fa-solid fa-folder me-2"></i> <b>Enquiry Date:</b>{" "}
// // //                 03-Jan-2024
// // //               </p>
// // //               <p>
// // //                 <i className="fa-solid fa-folder me-2"></i> <b>Start Date:</b>{" "}
// // //                 03-Jan-2024
// // //               </p>
// // //               <p>
// // //                 <i className="fa-solid fa-calendar-check me-2"></i>{" "}
// // //                 <b>End Date:</b> 08-Jan-2024
// // //               </p>
// // //               <p>
// // //                 <i className="fa-brands fa-hornbill me-2"></i>{" "}
// // //                 <b>Confirm Quatation Number:</b>23232
// // //               </p>
// // //               <p>
// // //                 <i className="fa-solid fa-clock me-2"></i> <b>Total Value:</b>
// // //                 86950
// // //               </p>
// // //               <p>
// // //                 <i className="fa-solid fa-money-bill-wave me-2"></i>{" "}
// // //                 <b>Paid:</b> $5000
// // //               </p>
// // //             </div>
// // //           </div>
// // //         </div>
// // //         <div className="flex flex-col md:flex-row gap-6 p-6 ">
// // //           <h1 className="d-flex justify-content-center mt-5 fs-4">
// // //             Quatation 1
// // //           </h1>
// // //           <div className="w-full md:w-1/2 bg-white shadow-lg rounded-2xl p-6 mt-5">
// // //             <div className="table-responsive">
// // //               <table className="table table-bordered">
// // //                 <thead className="thead-dark text-center">
// // //                   <tr>
// // //                     <th>S.NO</th>
// // //                     <th>Description</th>
// // //                     <th>Qty</th>
// // //                     <th>Basic Price</th>
// // //                     <th>Tax</th>
// // //                     <th>Total</th>
// // //                     {/* <th>Action</th> */}
// // //                   </tr>
// // //                 </thead>
// // //                 <tbody>
// // //                   {ongoingList.length > 0 ? (
// // //                     ongoingList.map((item, index) => (
// // //                       <tr key={item._id}>
// // //                         <td>{index + 1}</td>
// // //                         <td>{item.annexure_description_one || "N/A"}</td>
// // //                         <td>{item.annexure_qty_one || 0}</td>
// // //                         <td>
// // //                           {item.basicprice
// // //                             ? `$${item.basicprice.toFixed(2)}`
// // //                             : "$0.00"}
// // //                         </td>
// // //                         <td>{item.tax ? `${item.tax}%` : "0%"}</td>
// // //                         <td>
// // //                           {(
// // //                             item.basicprice *
// // //                             item.annexure_qty_one *
// // //                             (1 + item.tax / 100)
// // //                           ).toFixed(2)}
// // //                         </td>
// // //                         {/* <td>
// // //                      <i className="fa-solid fa-pen-to-square mx-2 fs-5" style={{ color: "blue", cursor: "pointer" }}
// // //                      // onClick={() => handleEdit(item._id)}
// // //                      ></i>
// // //                      <i className="fa-solid fa-circle-xmark mx-2 fs-4" style={{ color: "red", cursor: "pointer" }}
// // //                      // onClick={() => confirmDelete(item._id)}
// // //                      ></i>
// // //                    </td> */}
// // //                       </tr>
// // //                     ))
// // //                   ) : (
// // //                     <tr>
// // //                       <td colSpan="7" className="text-center">
// // //                         No items added
// // //                       </td>
// // //                     </tr>
// // //                   )}
// // //                 </tbody>
// // //               </table>
// // //             </div>
// // //           </div>

// // //           <h1 className="d-flex justify-content-center mt-5 fs-4">
// // //             Bill Of Materials
// // //           </h1>
// // //           <div className="w-full md:w-1/2 bg-white shadow-lg rounded-2xl p-6 mt-5">
// // //             <div className="table-responsive">
// // //               <table className="table table-bordered">
// // //                 <thead className="thead-dark text-center">
// // //                   <tr>
// // //                     <th>S.NO</th>
// // //                     <th>Description</th>
// // //                     <th>Qty</th>
// // //                     <th>Basic Price</th>
// // //                     <th>Tax</th>
// // //                     <th>Total</th>
// // //                     {/* <th>Action</th> */}
// // //                   </tr>
// // //                 </thead>
// // //                 <tbody className="text-center">
// // //                   {ongoingList.length > 0 ? (
// // //                     ongoingList.map((item, index) => (
// // //                       <tr key={item._id}>
// // //                         <td>{index + 1}</td>
// // //                         <td>{item.annexure_description_one || "N/A"}</td>
// // //                         <td>{item.annexure_qty_one || 0}</td>
// // //                         <td>
// // //                           {item.basicprice
// // //                             ? `$${item.basicprice.toFixed(2)}`
// // //                             : "$0.00"}
// // //                         </td>
// // //                         <td>{item.tax ? `${item.tax}%` : "0%"}</td>
// // //                         <td>
// // //                           {(
// // //                             item.basicprice *
// // //                             item.annexure_qty_one *
// // //                             (1 + item.tax / 100)
// // //                           ).toFixed(2)}
// // //                         </td>
// // //                         {/* <td>
// // //                      <i className="fa-solid fa-pen-to-square mx-2 fs-5" style={{ color: "blue", cursor: "pointer" }}
// // //                      // onClick={() => handleEdit(item._id)}
// // //                      ></i>
// // //                      <i className="fa-solid fa-circle-xmark mx-2 fs-4" style={{ color: "red", cursor: "pointer" }}
// // //                      // onClick={() => confirmDelete(item._id)}
// // //                      ></i>
// // //                    </td> */}
// // //                       </tr>
// // //                     ))
// // //                   ) : (
// // //                     <tr>
// // //                       <td colSpan="7" className="text-center">
// // //                         No items added
// // //                       </td>
// // //                     </tr>
// // //                   )}
// // //                 </tbody>
// // //               </table>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </div>
// // //     </>
// // //   );
// // // };

// // // export default Ongoinginfo;

// // import { useEffect, useState } from "react";
// // import { Link } from "react-router-dom";
// // import "../../assets/css/ExpectedHistory.css";
// // import ApiUrl from "../Environment/ApiUrl";
// // import axios from "axios";

// // const Ongoinginfo = () => {
// //   const [ongoingList, setOngoingList] = useState([]);

// //   useEffect(() => {
// //     fetchOngoingList();
// //   }, []);

// //   const fetchOngoingList = async () => {
// //     try {
// //       const response = await axios.get(`${ApiUrl}/ongoingbillmaterial`);
// //       setOngoingList(response.data);
// //       console.log("Ongoing list data:", response.data);
// //     } catch (error) {
// //       console.error("Error fetching ongoing list:", error.message);
// //     }
// //   };

// //   // Calculate total amount for all items
// //   const calculateTotal = () => {
// //     if (!ongoingList || ongoingList.length === 0) return 0;
// //     return ongoingList
// //       .reduce((total, item) => {
// //         const itemTotal =
// //           item.basicprice * item.annexure_qty_one * (1 + item.tax / 100);
// //         return total + itemTotal;
// //       }, 0)
// //       .toFixed(2);
// //   };

// //   return (
// //     <>
// //       <div className="container mt-4">
// //         <div className="row mb-3" style={{ marginTop: "-80px" }}>
// //           <div className="col-lg-12">
// //             <Link to="/dashboard" className="text-secondary dashBoard_text">
// //               <b>Dashboard / </b>
// //             </Link>
// //             <Link to="/dashboard" className="text-secondary dashBoard_text">
// //               <b>Projects / </b>
// //             </Link>
// //             <Link to="" className="text-danger dashBoard_text">
// //               <b>Expected / </b>
// //             </Link>
// //             <Link to="" className="text-danger dashBoard_text">
// //               <b>Project History</b>
// //             </Link>
// //           </div>
// //         </div>
// //       </div>

// //       <div className="card p-4 shadow-lg" style={{ minHeight: "350px" }}>
// //         <h3 className="text-center mb-4">Project Overall Details</h3>

// //         <div className="row h-100">
// //           <div className="col-lg-6 border-end d-flex flex-column justify-content-center align-items-start p-4 gap-2">
// //             <p>
// //               <i className="fa-solid fa-user me-2"></i> <b>Name:</b>{" "}
// //               {ongoingList[0]?.expected_Details?.name ||
// //                 ongoingList[0]?.expected_Details?.[0]?.name ||
// //                 "N/A"}
// //             </p>
// //             <p>
// //               <i className="fa-solid fa-building me-2"></i> <b>Company Name:</b>{" "}
// //               {ongoingList[0]?.expected_Details?.[0]?.company_name || "N/A"}
// //             </p>
// //             <p>
// //               <i className="fa-solid fa-envelope me-2"></i>{" "}
// //               <b>Official Email:</b>{" "}
// //               {ongoingList[0]?.expected_Details?.[0]?.official_email || "N/A"}
// //             </p>
// //             <p>
// //               <i className="fa-solid fa-phone me-2"></i> <b>Official Mobile:</b>{" "}
// //               {ongoingList[0]?.expected_Details?.[0]?.official_mobile || "N/A"}
// //             </p>
// //             <p>
// //               <i className="fa-solid fa-map-marker-alt me-2"></i>{" "}
// //               <b>Location:</b>{" "}
// //               {ongoingList[0]?.expected_Details?.[0]?.location || "N/A"}
// //             </p>
// //             <p>
// //               <i className="fa-solid fa-globe me-2"></i> <b>Source Type:</b>{" "}
// //               {ongoingList[0]?.expected_Details?.[0]?.source_type || "N/A"}
// //             </p>
// //           </div>

// //           <div className="col-lg-6 d-flex flex-column justify-content-center align-items-start p-4 gap-2">
// //             <p>
// //               <i className="fa-solid fa-folder me-2"></i> <b>Enquiry Date:</b>{" "}
// //               {ongoingList[0]?.expected_Details?.[0]?.enquiry_date
// //                 ? new Date(
// //                     ongoingList[0]?.expected_Details?.[0]?.enquiry_date
// //                   ).toLocaleDateString()
// //                 : "N/A"}
// //             </p>
// //             <p>
// //               <i className="fa-solid fa-folder me-2"></i> <b>Start Date:</b>{" "}
// //               {ongoingList[0]?.expected_Details?.[0]?.start_date
// //                 ? new Date(
// //                     ongoingList[0]?.expected_Details?.[0]?.start_date
// //                   ).toLocaleDateString()
// //                 : "N/A"}
// //             </p>
// //             <p>
// //               <i className="fa-solid fa-calendar-check me-2"></i>{" "}
// //               <b>End Date:</b>{" "}
// //               {ongoingList[0]?.expected_Details?.[0]?.end_date
// //                 ? new Date(
// //                     ongoingList[0]?.expected_Details?.[0]?.end_date
// //                   ).toLocaleDateString()
// //                 : "N/A"}
// //             </p>
// //             <p>
// //               <i className="fa-brands fa-hornbill me-2"></i>{" "}
// //               <b>Confirm Quatation Number:</b>{" "}
// //               {ongoingList[0]?.expected_Details?.[0]?.purchaseNo || "N/A"}
// //             </p>
// //             <p>
// //               <i className="fa-solid fa-clock me-2"></i> <b>Total Value:</b>{" "}
// //               {calculateTotal()}
// //             </p>
// //             <p>
// //               <i className="fa-solid fa-money-bill-wave me-2"></i> <b>Paid:</b>{" "}
// //               {ongoingList[0]?.expected_Details?.[0]?.netValue
// //                 ? `$${ongoingList[0]?.expected_Details?.[0]?.netValue}`
// //                 : "N/A"}
// //             </p>
// //           </div>
// //         </div>
// //       </div>

// //       <div className="container mt-5">
// //         <h1 className="text-center fs-4">Annexure 1</h1>
// //         <div className="bg-white shadow-lg rounded p-6">
// //           <div className="table-responsive p-2">
// //             <table className="table table-bordered">
// //               <thead className="thead-dark text-center">
// //                 <tr>
// //                   <th>S.NO</th>
// //                   <th>Description</th>
// //                   <th>Qty</th>
// //                   <th>Tax</th>
// //                   <th>Total</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {ongoingList.length > 0 ? (
// //                   ongoingList.map((item, index) =>
// //                     (item.items || []).map((subItem, subIndex) => (
// //                       <tr key={`${item._id}-${subIndex}`}>
// //                         <td>{index + 1}</td>
// //                         <td>{subItem?.annexure_description_one || "N/A"}</td>
// //                         <td>{subItem.annexure_qty_one || 0}</td>
// //                         <td>{subItem.tax ? `${subItem.tax}%` : "0%"}</td>
// //                         <td>{subItem.taxAmount || 0}</td>
// //                       </tr>
// //                     ))
// //                   )
// //                 ) : (
// //                   <tr>
// //                     <td colSpan="5" className="text-center">
// //                       No items added
// //                     </td>
// //                   </tr>
// //                 )}
// //               </tbody>
// //             </table>
// //           </div>
// //         </div>

// //         <h1 className="text-center fs-4 mt-5">Annexure 2</h1>
// //         <div className="bg-white shadow-lg rounded p-6">
// //           <div className="table-responsive p-2">
// //             <table className="table table-bordered">
// //               <thead className="thead-dark text-center">
// //                 <tr>
// //                   <th>S.NO</th>
// //                   <th>Description</th>
// //                   <th>Qty</th>
// //                   <th>Make</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {ongoingList.length > 0 ? (
// //                   ongoingList.map((item, index) =>
// //                     (item.itemss || []).map((subItem, subIndex) => (
// //                       <tr key={`${item._id}-${subIndex}-annexure2`}>
// //                         <td>{index + 1}</td>
// //                         <td>{subItem.annexure_description_two || "N/A"}</td>
// //                         <td>{subItem.annexure_qty_two || 0}</td>
// //                         <td>{subItem.make || "N/A"}</td>
// //                       </tr>
// //                     ))
// //                   )
// //                 ) : (
// //                   <tr>
// //                     <td colSpan="4" className="text-center">
// //                       No items added
// //                     </td>
// //                   </tr>
// //                 )}
// //               </tbody>
// //             </table>
// //           </div>
// //         </div>
// //       </div>

// //       <div className="container mt-5">
// //         <h1 className="text-center fs-4">Bill Of Material</h1>
// //         <div className="bg-white shadow-lg rounded p-6">
// //           <div className="table-responsive">
// //             <table className="table table-bordered">
// //               <thead className="thead-dark text-center">
// //                 <tr>
// //                   <th>S.NO</th>
// //                   <th>Description</th>
// //                   <th>Qty</th>
// //                   <th>Basic Price</th>
// //                   <th>Tax</th>
// //                   <th>Total</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {ongoingList.length > 0 ? (
// //                   ongoingList.map((item, index) => (
// //                     <tr key={item._id}>
// //                       <td>{index + 1}</td>
// //                       <td>{item.annexure_description_one || "N/A"}</td>
// //                       <td>{item.annexure_qty_one || 0}</td>
// //                       <td>
// //                         {item.basicprice
// //                           ? `$${item.basicprice.toFixed(2)}`
// //                           : "$0.00"}
// //                       </td>
// //                       <td>{item.tax ? `${item.tax}%` : "0%"}</td>
// //                       <td>
// //                         $
// //                         {(
// //                           item.basicprice *
// //                           item.annexure_qty_one *
// //                           (1 + item.tax / 100)
// //                         ).toFixed(2)}
// //                       </td>
// //                     </tr>
// //                   ))
// //                 ) : (
// //                   <tr>
// //                     <td colSpan="6" className="text-center">
// //                       No items added
// //                     </td>
// //                   </tr>
// //                 )}
// //                 {ongoingList.length > 0 && (
// //                   <tr>
// //                     <td colSpan="5" className="text-end">
// //                       <strong>Grand Total:</strong>
// //                     </td>
// //                     <td>${calculateTotal()}</td>
// //                   </tr>
// //                 )}
// //               </tbody>
// //             </table>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default Ongoinginfo;



// import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import "../../assets/css/ExpectedHistory.css";
// import ApiUrl from "../Environment/ApiUrl";
// import axios from "axios";

// const Ongoinginfo = () => {
//   const [ongoingList, setOngoingList] = useState([]);

//   useEffect(() => {
//     fetchOngoingList();
//   }, []);

//   const fetchOngoingList = async () => {
//     try {
//       const response = await axios.get(`${ApiUrl}/ongoingbillmaterial`);
//       setOngoingList(response.data || []);
//       console.log("Ongoing list data:", response.data);
//     } catch (error) {
//       console.error("Error fetching ongoing list:", error.message);
//     }
//   };

//   // Calculate total amount for all items
//   const calculateTotal = () => {
//     if (!ongoingList || ongoingList.length === 0) return 0;
//     return ongoingList
//       .reduce((total, item) => {
//         const itemTotal =
//           (item?.basicprice || 0) * (item?.annexure_qty_one || 0) * (1 + (item?.tax || 0) / 100);
//         return total + itemTotal;
//       }, 0)
//       .toFixed(2);
//   };

//   // Safe access expected details
//   const expectedDetails =
//     ongoingList[0]?.expected_Details?.length > 0
//       ? ongoingList[0]?.expected_Details[0]
//       : ongoingList[0]?.expected_Details || {};

//   return (
//     <>
//       <div className="container mt-4">
//         <div className="row mb-3" style={{ marginTop: "-80px" }}>
//           <div className="col-lg-12">
//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Dashboard / </b>
//             </Link>
//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Projects / </b>
//             </Link>
//             <Link to="" className="text-danger dashBoard_text">
//               <b>Expected / </b>
//             </Link>
//             <Link to="" className="text-danger dashBoard_text">
//               <b>Project History</b>
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className="card p-4 shadow-lg" style={{ minHeight: "350px" }}>
//         <h3 className="text-center mb-4">Project Overall Details</h3>

//         <div className="row h-100">
//           <div className="col-lg-6 border-end p-4">
//             <p><b>Name:</b> {expectedDetails?.name || "N/A"}</p>
//             <p><b>Company Name:</b> {expectedDetails?.company_name || "N/A"}</p>
//             <p><b>Official Email:</b> {expectedDetails?.official_email || "N/A"}</p>
//             <p><b>Official Mobile:</b> {expectedDetails?.official_mobile || "N/A"}</p>
//             <p><b>Location:</b> {expectedDetails?.location || "N/A"}</p>
//             <p><b>Source Type:</b> {expectedDetails?.source_type || "N/A"}</p>
//           </div>

//           <div className="col-lg-6 p-4">
//             <p><b>Enquiry Date:</b> {expectedDetails?.enquiry_date ? new Date(expectedDetails?.enquiry_date).toLocaleDateString() : "N/A"}</p>
//             <p><b>Start Date:</b> {expectedDetails?.start_date ? new Date(expectedDetails?.start_date).toLocaleDateString() : "N/A"}</p>
//             <p><b>End Date:</b> {expectedDetails?.end_date ? new Date(expectedDetails?.end_date).toLocaleDateString() : "N/A"}</p>
//             <p><b>Confirm Quotation Number:</b> {expectedDetails?.purchaseNo || "N/A"}</p>
//             <p><b>Total Value:</b> ${calculateTotal()}</p>
//             <p><b>Paid:</b> {expectedDetails?.netValue ? `$${expectedDetails?.netValue}` : "N/A"}</p>
//           </div>
//         </div>
//       </div>

//       {/* Annexure 1 */}
//       <div className="container mt-5">
//         <h1 className="text-center fs-4">Annexure 1</h1>
//         <div className="bg-white shadow-lg rounded p-6">
//           <div className="table-responsive p-2">
//             <table className="table table-bordered">
//               <thead className="thead-dark text-center">
//                 <tr>
//                   <th>S.NO</th>
//                   <th>Description</th>
//                   <th>Qty</th>
//                   <th>Tax</th>
//                   <th>Total</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {ongoingList.length > 0 ? (
//                   ongoingList.flatMap((item, index) =>
//                     (item.items || []).map((subItem, subIndex) => (
//                       <tr key={`${item._id}-${subIndex}`}>
//                         <td>{index + 1}</td>
//                         <td>{subItem?.annexure_description_one || "N/A"}</td>
//                         <td>{subItem?.annexure_qty_one || 0}</td>
//                         <td>{subItem?.tax ? `${subItem.tax}%` : "0%"}</td>
//                         <td>{subItem?.taxAmount || 0}</td>
//                       </tr>
//                     ))
//                   )
//                 ) : (
//                   <tr>
//                     <td colSpan="5" className="text-center">No items added</td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>

//         {/* Bill of Material */}
//         <h1 className="text-center fs-4 mt-5">Annexure 2: Bill Of Material</h1>
//         <div className="bg-white shadow-lg rounded p-6">
//           <div className="table-responsive">
//             <table className="table table-bordered">
//               <thead className="thead-dark text-center">
//                 <tr>
//                   <th>S.NO</th>
//                   <th>Description</th>
//                   <th>Qty</th>
//                   <th>Basic Price</th>
//                   <th>Tax</th>
//                   <th>Total</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {ongoingList.length > 0 ? (
//                   ongoingList.map((item, index) => (
//                     <tr key={item._id}>
//                       <td>{index + 1}</td>
//                       <td>{item.annexure_description_one || "N/A"}</td>
//                       <td>{item.annexure_qty_one || 0}</td>
//                       <td>${item.basicprice?.toFixed(2) || "0.00"}</td>
//                       <td>{item.tax ? `${item.tax}%` : "0%"}</td>
//                       <td>${((item.basicprice || 0) * (item.annexure_qty_one || 0) * (1 + (item.tax || 0) / 100)).toFixed(2)}</td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="6" className="text-center">No items added</td>
//                   </tr>
//                 )}
//                 {ongoingList.length > 0 && (
//                   <tr>
//                     <td colSpan="5" className="text-end"><strong>Grand Total:</strong></td>
//                     <td>${calculateTotal()}</td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>





//         {/* Bill of Material */}
//           <h1 className="text-center fs-4 mt-5">Bill Of Material</h1>
//         <div className="bg-white shadow-lg rounded p-6">
//           <div className="table-responsive">
//             <table className="table table-bordered">
//               <thead className="thead-dark text-center">
//                 <tr>
//                   <th>S.NO</th>
//                   <th>Description</th>
//                   <th>Qty</th>
//                   <th>Price</th>
//                   <th>Tax</th>
//                   <th>Total</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {ongoingList.length > 0 ? (
//                   ongoingList.map((item, index) => (
//                     <tr key={item._id}>
//                       <td>{index + 1}</td>
//                       <td>{item.annexure_description_one || "N/A"}</td>
//                       <td>{item.annexure_qty_one || 0}</td>
//                       <td>${item.basicprice?.toFixed(2) || "0.00"}</td>
//                       <td>{item.tax ? `${item.tax}%` : "0%"}</td>
//                       <td>${((item.basicprice || 0) * (item.annexure_qty_one || 0) * (1 + (item.tax || 0) / 100)).toFixed(2)}</td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="6" className="text-center">No items added</td>
//                   </tr>
//                 )}
//                 {ongoingList.length > 0 && (
//                   <tr>
//                     <td colSpan="5" className="text-end"><strong>Grand Total:</strong></td>
//                     <td>${calculateTotal()}</td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>





//       </div>
//     </>
//   );
// };

// export default Ongoinginfo;




import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/ExpectedHistory.css";
import ApiUrl from "../Environment/ApiUrl";
import axios from "axios";

const Ongoinginfo = () => {
  const [ongoingList, setOngoingList] = useState([]);

  useEffect(() => {
    fetchOngoingList();
  }, []);

  const fetchOngoingList = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/ongoingbillmaterial`);
      setOngoingList(response.data || []);
      console.log("Ongoing list data:", response.data);
    } catch (error) {
      console.error("Error fetching ongoing list:", error.message);
    }
  };

  // Calculate total amount for all items
  const calculateTotal = () => {
    if (!ongoingList || ongoingList.length === 0) return 0;
    return ongoingList
      .reduce((total, item) => {
        const itemTotal =
          (item?.basicprice || 0) * (item?.annexure_qty_one || 0) * (1 + (item?.tax || 0) / 100);
        return total + itemTotal;
      }, 0)
      .toFixed(2);
  };

  // Safe access expected details
  const expectedDetails =
    ongoingList[0]?.expected_Details?.length > 0
      ? ongoingList[0]?.expected_Details[0]
      : ongoingList[0]?.expected_Details || {};

  return (
    <>
      <div className="container mt-4">
        <div className="row mb-3" style={{ marginTop: "-80px" }}>
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Projects / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Expected / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Project History</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="card p-4 shadow-lg" style={{ minHeight: "350px" }}>
        <h3 className="text-center mb-4">Project Overall Details</h3>

        <div className="row h-100">
          <div className="col-lg-6 border-end p-4">
            <p><b>Name:</b> {expectedDetails?.name || "N/A"}</p>
            <p><b>Company Name:</b> {expectedDetails?.company_name || "N/A"}</p>
            <p><b>Official Email:</b> {expectedDetails?.official_email || "N/A"}</p>
            <p><b>Official Mobile:</b> {expectedDetails?.official_mobile || "N/A"}</p>
            <p><b>Location:</b> {expectedDetails?.location || "N/A"}</p>
            <p><b>Source Type:</b> {expectedDetails?.source_type || "N/A"}</p>
          </div>

          <div className="col-lg-6 p-4">
            <p><b>Enquiry Date:</b> {expectedDetails?.enquiry_date ? new Date(expectedDetails?.enquiry_date).toLocaleDateString() : "N/A"}</p>
            <p><b>Start Date:</b> {expectedDetails?.start_date ? new Date(expectedDetails?.start_date).toLocaleDateString() : "N/A"}</p>
            <p><b>End Date:</b> {expectedDetails?.end_date ? new Date(expectedDetails?.end_date).toLocaleDateString() : "N/A"}</p>
            <p><b>Confirm Quotation Number:</b> {expectedDetails?.purchaseNo || "N/A"}</p>
            <p><b>Total Value:</b> ${calculateTotal()}</p>
            <p><b>Paid:</b> {expectedDetails?.netValue ? `$${expectedDetails?.netValue}` : "N/A"}</p>
          </div>
        </div>
      </div>

      {/* Annexure 1 */}
      <div className="container mt-5">
        <h1 className="text-center fs-4">Annexure 1</h1>
        <div className="bg-white shadow-lg rounded p-6">
          <div className="table-responsive p-2">
            <table className="table table-bordered">
              <thead className="thead-dark text-center">
                <tr>
                  <th>S.NO</th>
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Tax</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {ongoingList.length > 0 ? (
                  ongoingList.flatMap((item) =>
                    item.quotation_list?.flatMap((quotation) =>
                      quotation.items?.map((subItem, subIndex) => {
                        // Calculate the serial number dynamically
                        const serialNumber =
                          ongoingList
                            .slice(0, ongoingList.indexOf(item))
                            .flatMap((i) => i.quotation_list?.flatMap((q) => q.items || []))
                            .length +
                          item.quotation_list
                            .slice(0, item.quotation_list.indexOf(quotation))
                            .flatMap((q) => q.items || [])
                            .length +
                          subIndex +
                          1;

                        return (
                          <tr key={`${item._id}-${subIndex}`}>
                            <td>{serialNumber}</td>
                            <td>{subItem?.annexure_description_one || "N/A"}</td>
                            <td>{subItem?.annexure_qty_one || 0}</td>
                            <td>{subItem?.tax ? `${subItem.tax}%` : "0%"}</td>
                            <td>{subItem?.taxAmount || 0}</td>
                          </tr>
                        );
                      })
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">No items added</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Bill of Material */}
        <h1 className="text-center fs-4 mt-5">Annexure 2: Bill Of Material</h1>
        <div className="bg-white shadow-lg rounded p-6">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-dark text-center">
                <tr className="text-center">
                  <th>S.NO</th>
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Basic Price</th>
                  {/* <th>Tax</th>
                  <th>Total</th> */}
                </tr>
              </thead>
              <tbody className="text-center">
          {ongoingList.length > 0 ? (
            ongoingList.flatMap((item) =>
              item.quotation_list?.flatMap((quotation) =>
                quotation.itemss?.map((subItem, subIndex) => {
                  // Calculate the serial number dynamically
                  const serialNumber =
                    ongoingList
                      .slice(0, ongoingList.indexOf(item))
                      .flatMap((i) => i.quotation_list?.flatMap((q) => q.itemss || []))
                      .length +
                    item.quotation_list
                      .slice(0, item.quotation_list.indexOf(quotation))
                      .flatMap((q) => q.itemss || [])
                      .length +
                    subIndex +
                    1;

                  return (
                    <tr key={`${item._id}-${subIndex}-annexure2`}>
                      <td>{serialNumber}</td>
                      <td>{subItem?.annexure_description_two || "N/A"}</td>
                      <td>{subItem?.annexure_qty_two || 0}</td>
                      <td>{subItem?.make || "N/A"}</td>
                    </tr>
                  );
                })
              )
            )
          ) : (
            <tr>
              <td colSpan="4" className="text-center">No items added</td>
            </tr>
          )}
        </tbody>
            </table>
          </div>
        </div>





        {/* Bill of Material */}
        <h1 className="text-center fs-4 mt-5">Bill Of Material</h1>
        <div className="bg-white shadow-lg rounded p-6">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-dark text-center">
                <tr>
                  <th>S.NO</th>
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Tax</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {ongoingList.length > 0 ? (
                  ongoingList.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item.annexure_description_one || "N/A"}</td>
                      <td>{item.annexure_qty_one || 0}</td>
                      <td>${item.basicprice?.toFixed(2) || "0.00"}</td>
                      <td>{item.tax ? `${item.tax}%` : "0%"}</td>
                      <td>${((item.basicprice || 0) * (item.annexure_qty_one || 0) * (1 + (item.tax || 0) / 100)).toFixed(2)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">No items added</td>
                  </tr>
                )}
                {ongoingList.length > 0 && (
                  <tr>
                    <td colSpan="5" className="text-end"><strong>Grand Total:</strong></td>
                    <td>${calculateTotal()}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>





      </div>
    </>
  );
};

export default Ongoinginfo;
