

// import { useNavigate, useParams } from "react-router-dom";
// import "../../assets/css/Addrole.css";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import apiUrl from "./../Environment/ApiUrl";
// import ApiUrl from "./../Environment/ApiUrl";

// const modules = [
//   {
//     name: "Dashboard",
//     id: 1,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Roles",
//     id: 2,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Users",
//     id: 3,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Branch",
//     id: 4,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Contact Leads",
//     id: 5,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Enquiry",
//     id: 6,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "New Enquiry",
//     id: 7,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },

//   {
//     name: "Followups",
//     id: 8,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },

//   {
//     name: "Field Visit",
//     id: 9,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Sourcetype",
//     id: 10,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Enquirypurpose",
//     id: 11,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Expected",
//     id: 12,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Ongoing",
//     id: 13,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Completed",
//     id: 14,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Sales Order",
//     id: 15,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Customer",
//     id: 16,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Upcomings",
//     id: 17,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },

//   {
//     name: "Ongoing",
//     id: 18,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Completed",
//     id: 19,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Staff",
//     id: 20,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Attendance",
//     id: 21,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Designation",
//     id: 22,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Leave",
//     id: 23,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Allowance",
//     id: 24,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Allowance Charge",
//     id: 25,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Salary Details",
//     id: 26,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },

//   {
//     name: "Holiday",
//     id: 27,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Event",
//     id: 28,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Daily Task",
//     id: 29,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Stocks",
//     id: 30,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Product List",
//     id: 31,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   // {
//   //   name: "Category",
//   //   id: 32,
//   //   actions: {
//   //     all: false,
//   //     add: false,
//   //     edit: false,
//   //     delete: false,
//   //     view: false,
//   //     download: false,
//   //   },
//   // },
//   {
//     name: "Purchase Order",
//     id: 32,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Supplier",
//     id: 33,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Asset List",
//     id: 34,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Asset Type",
//     id: 35,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Expense List",
//     id: 36,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Expense Type",
//     id: 37,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Vendor Details",
//     id: 38,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Office Expense",
//     id: 39,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Paytype",
//     id: 40,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Income List",
//     id: 41,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Income Type",
//     id: 42,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Invoice",
//     id: 43,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Daily Report",
//     id: 44,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Enquiry Report",
//     id: 45,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Attendance Report",
//     id: 46,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Expense Report",
//     id: 47,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Project Report",
//     id: 48,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Staff Report",
//     id: 49,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
//   {
//     name: "Income Report",
//     id: 50,
//     actions: {
//       all: false,
//       add: false,
//       edit: false,
//       delete: false,
//       view: false,
//       download: false,
//     },
//   },
 
// ];

// function AddRole() {
//   const { id } = useParams();
//   console.log("params id", id);

//   const [moduleActions, setModuleActions] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(apiUrl + `/role_2/${id}`);
//         if (response && response.data) {
//           const { rolename, modules } = response.data;
//           console.log("modules", modules);
//           setRoleData({
//             rolename: rolename,
//             modules: modules.map((module) => ({
//               id: module.id,
//               name: module.name,
//               actions: { ...module.actions },
//             })),
//           });
//           setModuleActions(
//             modules.map((module) => ({
//               id: module.id,
//               actions: { ...module.actions },
//             }))
//           );
//         }
//       } catch (error) {
//         console.error("Error fetching update data:", error);
//       }
//     };

//     if (id) {
//       fetchData();
//     } else {
//       setModuleActions(
//         modules.map((module) => ({
//           id: module.id,
//           actions: { ...module.actions },
//         }))
//       );
//     }
//   }, [id]);

//   const navigate = useNavigate();

//   const [roleData, setRoleData] = useState({
//     branch_id: "66d00c5f0d2fcb921b0186da",
//     rolename: "",
//     modules: modules.map((module) => ({
//       id: module.id,
//       name: module.name,
//       actions: {
//         all: false,
//         add: false,
//         edit: false,
//         delete: false,
//         view: false,
//         download: false,
//       },
//     })),
//   });

//   console.log(roleData);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setRoleData({
//       ...roleData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Debugging output
//     console.log("Role data to submit:", roleData);
//     console.log("API URL:", `192.168.1.38/role_2/${id || ""}`);

//     try {
//       let response;

//       if (id) {
//         console.log("Updating role with ID:", id);
//         response = await axios.put(`${apiUrl}/role/${id}`, roleData);
//       } else {
//         console.log("Creating new role");
//         response = await axios.post(`${apiUrl}/role_2`, roleData);
//       }

//       if (response) {
//         setRoleData({
//           rolename: "",
//           modules: modules.map((module) => ({
//             id: module.id,
//             name: module.name,
//             actions: {
//               all: false,
//               add: false,
//               edit: false,
//               delete: false,
//               view: false,
//               download: false,
//             },
//           })),
//         });
//         navigate("/role");
//       }
//       console.log("Server response:", response.data);
//     } catch (err) {
//       console.error("Error during submit:", err);
//       alert(
//         "An error occurred while processing your request. Please try again."
//       );
//     }
//   };

//   const handleCheckboxChange = (moduleId, action) => {
//     setModuleActions((prevModuleActions) =>
//       prevModuleActions.map((module) => {
//         if (module.id === moduleId) {
//           if (action === "all") {
//             const updatedActions = Object.keys(module.actions).reduce(
//               (acc, key) => {
//                 acc[key] = !module.actions.all;
//                 return acc;
//               },
//               {}
//             );
//             return {
//               ...module,
//               actions: updatedActions,
//             };
//           } else {
//             return {
//               ...module,
//               actions: {
//                 ...module.actions,
//                 [action]: !module.actions[action],
//               },
//             };
//           }
//         }
//         return module;
//       })
//     );

//     setRoleData((prevRoleData) => ({
//       ...prevRoleData,
//       modules: prevRoleData.modules.map((module) => {
//         if (module.id === moduleId) {
//           if (action === "all") {
//             const updatedActions = Object.keys(module.actions).reduce(
//               (acc, key) => {
//                 acc[key] = !module.actions.all;
//                 return acc;
//               },
//               {}
//             );
//             return {
//               ...module,
//               actions: updatedActions,
//             };
//           } else {
//             return {
//               ...module,
//               actions: {
//                 ...module.actions,
//                 [action]: !module.actions[action],
//               },
//             };
//           }
//         }
//         return module;
//       }),
//     }));
//   };

//   return (




//     <div className="tops"   >
    

//       <div className="admin-roles-main">
//         {id ? (
//           <h1 className="text-center">Update Roles</h1>
//         ) : (
//           <h4 className="">Add Roles</h4>
//         )}
//       </div>
//       <div
//         className="card p-3 mt-3"
//         style={{ padding: "10px 10px", marginBottom: "20px" }}
//       >
//         <form onSubmit={handleSubmit}>
//           <div className="row mt-2">
//             <div className="form-label">
//               <b>Role Name:</b>
//             </div>
//             <div className="col-md-6">
//               <input
//                 type="text"
//                 id="role"
//                 className="form-control"
//                 placeholder="Role Name"
//                 name="role"
//                 value={roleData.rolename}
//                 onChange={(e) =>
//                   setRoleData({ ...roleData, rolename: e.target.value })
//                 }
//               />
//             </div>

//             <div className="mt-4">
//               <div className="card-container table-responsive">
//                 <table className=" admin-role-card">
//                   <thead>
//                     <tr className="text-center">
//                       <th colSpan="7">Privileges</th>
//                     </tr>
//                     <tr className="text-center">
//                       <th>Module</th>
//                       <th>All</th>
//                       <th>Add</th>
//                       <th>Edit</th>
//                       <th>Delete</th>
//                       <th>View</th>
//                       <th>Download</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {moduleActions.map((module, index) => (
//                       <tr className="text-start" key={index}>
//                         <td className="role-data-name">
//                           {modules.find((item) => item.id === module.id).name}
//                         </td>
//                         <td className="role-data-all">
//                           <input
//                             type="checkbox"
//                             checked={module.actions.all}
//                             onChange={() =>
//                               handleCheckboxChange(module.id, "all")
//                             }
//                           />
//                         </td>
//                         <td className="role-data-add">
//                           <input
//                             type="checkbox"
//                             checked={module.actions.add}
//                             onChange={() =>
//                               handleCheckboxChange(module.id, "add")
//                             }
//                           />
//                         </td>
//                         <td className="role-data-edit">
//                           <input
//                             type="checkbox"
//                             checked={module.actions.edit}
//                             onChange={() =>
//                               handleCheckboxChange(module.id, "edit")
//                             }
//                           />
//                         </td>
//                         <td className="role-data-delete">
//                           <input
//                             type="checkbox"
//                             checked={module.actions.delete}
//                             onChange={() =>
//                               handleCheckboxChange(module.id, "delete")
//                             }
//                           />
//                         </td>
//                         <td className="role-data-view">
//                           <input
//                             type="checkbox"
//                             checked={module.actions.view}
//                             onChange={() =>
//                               handleCheckboxChange(module.id, "view")
//                             }
//                           />
//                         </td>
//                         <td className="role-data-download">
//                           <input
//                             type="checkbox"
//                             checked={module.actions.download}
//                             onChange={() =>
//                               handleCheckboxChange(module.id, "download")
//                             }
//                           />
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>

//           <div className="d-flex justify-content-center mt-3">
//             <button
//               className="btn btn-secondary"
//               onClick={() => navigate("/role")}
//             >
//               Back
//             </button>
//             {id ? (
//               <button type="submit" className="btn btn-info mx-4">
//                 Update
//               </button>
//             ) : (
//               <button type="submit" className="btn btn-info mx-4">
//                 Submit
//               </button>
//             )}
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default AddRole;





import { useNavigate, useParams } from "react-router-dom";
import "../../assets/css/Addrole.css";
import { useEffect, useState } from "react";
import axios from "axios";
import apiUrl from "./../Environment/ApiUrl";
import ApiUrl from "./../Environment/ApiUrl";

const modulesData = [
  {
    name: "Dashboard",
    id: 1,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Roles",
    id: 2,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Users",
    id: 3,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Branch",
    id: 4,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Contact Leads",
    id: 5,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Enquiry",
    id: 6,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "New Enquiry",
    id: 7,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },

  {
    name: "Followups",
    id: 8,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },

  {
    name: "Field Visit",
    id: 9,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Sourcetype",
    id: 10,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Enquirypurpose",
    id: 11,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expected",
    id: 12,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Ongoing",
    id: 13,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Completed",
    id: 14,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Sales Order",
    id: 15,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Customer",
    id: 16,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Upcomings",
    id: 17,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },

  {
    name: "Ongoing",
    id: 18,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Completed",
    id: 19,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Staff",
    id: 20,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Attendance",
    id: 21,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Designation",
    id: 22,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Leave",
    id: 23,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Allowance",
    id: 24,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Allowance Charge",
    id: 25,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Salary Details",
    id: 26,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },

  {
    name: "Holiday",
    id: 27,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Event",
    id: 28,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Daily Task",
    id: 29,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Stocks",
    id: 30,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Product List",
    id: 31,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  // {
  //   name: "Category",
  //   id: 32,
  //   actions: {
  //     all: false,
  //     add: false,
  //     edit: false,
  //     delete: false,
  //     view: false,
  //     download: false,
  //   },
  // },
  {
    name: "Purchase Order",
    id: 32,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Supplier",
    id: 33,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Asset List",
    id: 34,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Asset Type",
    id: 35,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expense List",
    id: 36,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expense Type",
    id: 37,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Vendor Details",
    id: 38,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Office Expense",
    id: 39,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Paytype",
    id: 40,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Income List",
    id: 41,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Income Type",
    id: 42,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Invoice",
    id: 43,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Daily Report",
    id: 44,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Enquiry Report",
    id: 45,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Attendance Report",
    id: 46,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expense Report",
    id: 47,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Project Report",
    id: 48,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Staff Report",
    id: 49,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Income Report",
    id: 50,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
 
];

function AddRole() {
  const { id } = useParams();
  console.log("params id", id);

  const [roleData, setRoleData] = useState({
    branch_id: "",
    rolename: "",
    modules: [],
  });

  const [moduleActions, setModuleActions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/role/${id}`);
        if (response && response.data) {
          const { rolename, modules } = response.data;
          console.log("modules", modules);
          setRoleData({
            rolename: rolename,
            modules: modules.map((module) => ({
              id: module.id,
              name: module.name,
              actions: { ...module.actions },
            })),
          });
          setModuleActions(
            modules.map((module) => ({
              id: module.id,
              actions: { ...module.actions },
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching update data:", error);
      }
    };

    if (id) {
      fetchData();
    } else {
      setRoleData({
        rolename: "",
        modules: modulesData.map((module) => ({
          id: module.id,
          name: module.name,
          actions: { ...module.actions },
        })),
      });
    }
  }, [id]);

  // Ensure roleData is initialized before rendering
  if (!roleData || !roleData.modules) {
    return <div>Loading...</div>; // You can replace this with a proper loading indicator
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRoleData({
      ...roleData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (moduleId, action) => {
    setRoleData((prevRoleData) => {
      const updatedModules = prevRoleData.modules.map((module) => {
        if (module.id === moduleId) {
          if (action === "all") {
            const updatedActions = Object.keys(module.actions).reduce(
              (acc, key) => {
                acc[key] = !module.actions.all;
                return acc;
              },
              {}
            );
            return { ...module, actions: updatedActions };
          } else {
            return {
              ...module,
              actions: {
                ...module.actions,
                [action]: !module.actions[action],
              },
            };
          }
        }
        return module;
      });

      return { ...prevRoleData, modules: updatedModules };
    });
  };

  const handleRoleNameChange = (e) => {
    setRoleData((prevRoleData) => ({
      ...prevRoleData,
      rolename: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Role data to submit:", roleData);
    console.log("API URL:", `192.168.1.243/role/${id || ""}`);

    try {
       const  response = await axios.post(`${apiUrl}/role`, roleData);
      if (response) {
        setRoleData({
          rolename: "",
          modules: modulesData.map((module) => ({
            id: module.id,
            name: module.name,
            actions: {
              all: false,
              add: false,
              edit: false,
              delete: false,
              view: false,
              download: false,
            },
          })),
        });
        navigate("/role");
      }
      console.log("Server response:", response.data);
    } catch (err) {
      console.error("Error during submit:", err);
    }
  };

  return (
    <>
      <div className="tops">
        <div className="admin-roles-main">
          <h4>{id ? "Update Role" : "Add Role"}</h4>
        </div>
        <div
          className="card p-3 mt-3"
          style={{ padding: "10px 10px", marginBottom: "20px" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="row mt-2">
              <div className="form-label">
                <b>Role Name:</b>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  id="role"
                  className="form-control"
                  placeholder="Role Name"
                  name="rolename"
                  value={roleData.rolename}
                  onChange={handleRoleNameChange}
                />
              </div>

              <div className="mt-4">
                <div className="card-container table-responsive">
                  <table className="admin-role-card">
                    <thead>
                      <tr className="text-center">
                        <th colSpan="7">Privileges</th>
                      </tr>
                      <tr className="text-center">
                        <th>Module</th>
                        <th>All</th>
                        <th>Add</th>
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>View</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roleData.modules.map((module) => (
                        <tr key={module.id} className="text-start">
                          <td className="role-data-name">{module.name}</td>
                          <td className="role-data-all">
                            <input
                              type="checkbox"
                              checked={module.actions.all}
                              onChange={() =>
                                handleCheckboxChange(module.id, "all")
                              }
                            />
                          </td>
                          <td className="role-data-add">
                            <input
                              type="checkbox"
                              checked={module.actions.add}
                              onChange={() =>
                                handleCheckboxChange(module.id, "add")
                              }
                            />
                          </td>
                          <td className="role-data-edit">
                            <input
                              type="checkbox"
                              checked={module.actions.edit}
                              onChange={() =>
                                handleCheckboxChange(module.id, "edit")
                              }
                            />
                          </td>
                          <td className="role-data-delete">
                            <input
                              type="checkbox"
                              checked={module.actions.delete}
                              onChange={() =>
                                handleCheckboxChange(module.id, "delete")
                              }
                            />
                          </td>
                          <td className="role-data-view">
                            <input
                              type="checkbox"
                              checked={module.actions.view}
                              onChange={() =>
                                handleCheckboxChange(module.id, "view")
                              }
                            />
                          </td>
                          <td className="role-data-download">
                            <input
                              type="checkbox"
                              checked={module.actions.download}
                              onChange={() =>
                                handleCheckboxChange(module.id, "download")
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button
                className="btn btn-secondary"
                onClick={() => navigate("/role")}
              >
                Back
              </button>

              <button type="submit" className="btn btn-info mx-4">
             Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddRole;

