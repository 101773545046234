
import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function Leave() {
  const [responseData, setResponseData] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [branchDatas, setBranchDatas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [deleteShowModals, setDeleteShowModals] = useState(false);

  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      empid: item.empid,
      type: item.type,
      branch_id: {
        _id: item.branch_id?._id ?? null,
        branch_name: item.branch_id?.branch_name ?? "",
      },
      date: item.date,
      from_date: item.from_date,
      to_date: item.to_date,
      reason: item.reason,
      status: item.status,
      staff_name: item.staff_name,

    }));
  }, [responseData]);



  const [rolename, setrolename] = useState([]);

  useEffect(() => {
    const branchName = localStorage.getItem("branch_name");
    setrolename(branchName);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => row.index + 1,
      },
      {
        Header: "Branch",
        accessor: "branch_id.branch_name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        Cell: ({ row }) => {
          const branch = row.original.branch_id;
          return branch ? branch.branch_name : ''; // Fallback if branch_id is null/undefined
        },
      },
      {
        Header: "Staff ID",
        accessor: "empid",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },

      {
        Header: "Staff Name",
        accessor: "staff_name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Date",
        accessor: (row) => {
          const date = new Date(row.date);
          return date.toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
        },
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "From Date",
        accessor: (row) => {
          const date = new Date(row.from_date);
          return date.toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
        },
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "To Date",
        accessor: (row) => {
          const date = new Date(row.to_date);
          return date.toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
        },
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Reason",
        accessor: "reason",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        Cell: ({ value }) => {
          const statusClass =
            value === "Approved"
              ? "text-white text-center green-label"
              : value === "Rejected"
                ? "text-white text-center red-label"
                : value === "Pending"
                  ? "text-white text-center yellow-label"
                  : "text-white text-center";

          return <p className={statusClass}>{value}</p>;
        },
      },
    ];
  }, []); // No dependencies needed since we removed the conditional logic



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});

  // Apply on Current date //
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    // Get the current date in YYYY-MM-DD format
    const date = new Date();
    const formattedDate = date.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  // current date//////////////////////////////
  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date); // Convert input to Date if it's not already
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [addData, setAddData] = useState({
    // branch_id : req.body.branch_id,
    // empid : req.body.empid,
    // date : req.body.date,
    // reason : req.body.reason,
    // status : req.body.status

    branch_id: "",
    empid: "",
    staff_name: "",
    type: "",
    date: formatDate(new Date()),
    from_date: formatDate(new Date()),
    to_date: "",
    reason: "",
    status: "",
  });

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    empid: "",
    staff_name: "",
    type: "",
    date: formatDate(new Date()),
    from_date: formatDate(new Date()),
    to_date: "",
    reason: "",
    status: "",
  });

  const handleDateChange = (e) => {
    setAddData((prevData) => ({
      ...prevData,
      date: e.target.value,
      to_date: e.target.value,
      from_date: e.target.value, // Input type=date handles the format
    }));
  };

  const handleDateChanged = (e) => {
    setUpdateData((prevData) => ({
      ...prevData,
      date: e.target.value,
      to_date: e.target.value,
      from_date: e.target.value, // Input type=date handles the format
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const fetchData = async () => {
  //     try {
  //         const response = await axios.get(apiUrl + "/leave", {
  //             params: { data: localStorage.getItem("branch_id") },
  //         });

  //         setResponseData(response.data.reverse());
  //     } catch (error) {
  //         console.error("Error fetching data:", error.message);
  //     }
  // };

  const fetchData = async () => {
    try {
      // Retrieve branch details and employee name from localStorage
      const branchId = localStorage.getItem("branch_id");
      const branchName = localStorage.getItem("branch_name");
      const empName = localStorage.getItem("username");

      setLoading(true);
      // Check if the condition to fetch all data is met
      if (
        branchId === null ||
        branchName === "Branches" ||
        empName === undefined
      ) {
        // Fetch all attendance data if condition is true
        const response = await axios.get(ApiUrl + "/leave");

        if (response && response.status === 200) {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }

        console.log(
          "Response from server with all attendance data:",
          response.data
        );

        setResponseData(response.data.reverse());
      } else {
        // Fetch data based on branchId and empid (from localStorage)
        const response = await axios.get(ApiUrl + "/leave", {
          params: { data: branchId },
        });

        if (response && response.status === 200) {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
        console.log(
          "Response from server with filtered attendance data:",
          response.data
        );

        // Get the empid from localStorage
        const localEmpid = localStorage.getItem("username");
        const internal_emp = localStorage.getItem("internal_emp");

        // Filter data based on the empid from localStorage
        const filteredData = response.data.filter(
          (item) => item.empid === localEmpid || item.empid === internal_emp
        );
     
        // console.log("the filter data ", filteredData)
  
        // console.log("the filter data was", responseData)
        setResponseData(filteredData.reverse());
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchDatas();
    fetchDat();
    gettoken();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const fetchDat = async () => {
  //   try {
  //     const response = await axios.get(apiUrl + "/Branch");
  //     console.log("Responseee from server:", response.data);
  //     setBranchDatas(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };


  const fetchDat = async () => {
    try {
      const response = await axios.get(ApiUrl + "/branch");
      console.log("Response from server:", response.data);
      const branchName = localStorage.getItem("branch_name");
      const branchid = localStorage.getItem("branch_id");

      if (branchName === 'Branches') {
        setBranchDatas(response.data); // If branchName is 'Branches', set all branches
      } else {
        const filteredData = response.data.filter(branch => branch._id === branchid);
        setBranchDatas(filteredData); // If not, filter based on branch_id and set the filtered branches
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };


  const fetchDatas = async () => {
    try {
      const response = await axios.get(ApiUrl + "/staff");
      console.log("Response from server:", response.data);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const gettoken = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(ApiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);

      setroleid(response.data.detail.rolename);

      localStorage.setItem("role_id", response.data.detail.rolename);

      const id = response.data.detail.rolename;
      if (id) {
        try {
          const response = await axios.get(ApiUrl + `/role_2/${id}`);

          const modules = response.data.modules;

          const filtered_access = modules.filter(
            (item) => item.name == "Leave"
          );

          setprevillages(filtered_access[0].actions);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    // if (!validateFields()) {
    //   return;
    // }

    try {
      setLoading(true);
      const formData = {
        branch_id: addData.branch_id,
        empid: addData.empid,
        staff_name: addData.staff_name,
        date: addData.date,
        type: addData.type,
        from_date: addData.from_date,
        to_date: addData.to_date,
        reason: addData.reason,
        status: addData.status,
      };
      const response = await axios.post(ApiUrl + "/leave", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("User added successfully!");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      fetchData();
      setAddData({
        branch_id: "",
        empid: "",
        staff_name: "",
        type: "",
        date: formatDate(new Date()),
        from_date: formatDate(new Date()),
        to_date: "",
        reason: "",
        status: "",
      });
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    // if (previllages.edit || previllages.all) {
      setUpdateId(_id);
      console.log("The data to update", data);

      // Format date fields to YYYY-MM-DD
      const formatToYYYYMMDD = (inputDate) => {
        const date = new Date(inputDate);
        return !isNaN(date) ? date.toISOString().split("T")[0] : ""; // Format to YYYY-MM-DD or return an empty string if invalid
      };

      const formattedDate = formatToYYYYMMDD(data.date);
      const formattedToDate = formatToYYYYMMDD(data.to_date); // Format to_date correctly

      if (!formattedDate) {
        console.error("Invalid date:", data.date);
        return;
      }

      setUpdateShowModal(true);

      setUpdateData({
        branch_id: data.branch_id,
        empid: data.empid,
        staff_name: data.staff_name,
        type: data.type,
        date: formattedDate, // Formatted date
        from_date: data.from_date, // No change
        to_date: formattedToDate, // Formatted to_date
        reason: data.reason,
        status: data.status,
      });

      console.log("Formatted data for update:", {
        date: formattedDate,
        to_date: formattedToDate,
      });
    // } else {
    //   toast.error("You don't have the permission to update");
    // }
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = {
        branch_id: updateData.branch_id,
        empid: updateData.empid,
        staff_name: updateData.staff_name,
        type: updateData.type,
        date: updateData.date,
        from_date: updateData.from_date,
        to_date: updateData.to_date,
        reason: updateData.reason,
        status: updateData.status,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(ApiUrl + `/leave/${updateid}`, formData);
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await axios.delete(ApiUrl + `/leave/${getdeleteid}`);
      toast.error("Deleted successfully!");
      setDeleteShowModals(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      fetchData();
    } catch (error) {
      console.error("Error deleting role:", error.message);
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, empid) => {
    // if (previllages.all || previllages.delete) {
      console.log(" the id", _id);
      console.log(" the name", empid);
      setdeleteid(_id);
      setdeletename(empid);
      setDeleteShowModals(true);
    // } else {
    //   toast.error("You don't have the permission to delete");
    // }
  };

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const filtered = rows.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  // const createPagination = () => {
  //   let liTag = [];

  //   // Previous Page Button
  //   liTag.push(
  //     <li
  //       className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
  //       key="prev"
  //     >
  //       <button
  //         className="page-link"
  //         onClick={() => canPreviousPage && gotoPage(state.pageIndex - 1)}
  //         disabled={!canPreviousPage}
  //       >
  //         <FaAngleLeft /> Prev
  //       </button>
  //     </li>
  //   );

  //   // Page Numbers
  //   for (let plength = 1; plength <= pageCount; plength++) {
  //     liTag.push(
  //       <li
  //         key={plength}
  //         className={`page-item ${plength === state.pageIndex + 1 ? "active" : ""
  //           }`}
  //       >
  //         <button
  //           className="page-link"
  //           onClick={() => gotoPage(plength - 1)} // 0-indexed
  //         >
  //           {plength}
  //         </button>
  //       </li>
  //     );
  //   }

  //   // Next Page Button
  //   liTag.push(
  //     <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
  //       <button
  //         className="page-link"
  //         onClick={() => canNextPage && gotoPage(state.pageIndex + 1)}
  //         disabled={!canNextPage}
  //       >
  //         Next <FaAngleRight />
  //       </button>
  //     </li>
  //   );

  //   return liTag;
  // };




  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`} key="prev">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li className={`page-item ${currentPage === 0 ? "active" : ""}`} key={0}>
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li className={`page-item ${currentPage === 1 ? "active" : ""}`} key={1}>
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 3, currentPage + 1); i++) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li className={`page-item ${currentPage === totalPages - 2 ? "active" : ""}`} key={totalPages - 2}>
          <button className="page-link" onClick={() => gotoPage(totalPages - 2)}>
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li className={`page-item ${currentPage === totalPages - 1 ? "active" : ""}`} key={totalPages - 1}>
          <button className="page-link" onClick={() => gotoPage(totalPages - 1)}>
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };
  // =====================================================================================

  const formatDated = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);

    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
    const year = date.getFullYear();

    return `${day}-${month}-${year}`; // Format as "day-month-year"
  };

  const formatDatefitchinggg = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);

    // Add 7 days to the selected date
    date.setDate(date.getDate());

    // Format the date as DD-MM-YYYY
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const formatDatefitching = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided

    const selectedDate = new Date(isoDate);
    const currentDate = new Date();

    // Set both dates to midnight to avoid time differences affecting comparison
    currentDate.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    // Check if the selected date is before today
    if (selectedDate < currentDate) return ""; // Return an empty string if the selected date is in the past

    // Add 8 days to the selected date
    selectedDate.setDate(selectedDate.getDate() + 9);

    return selectedDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  // the pagination  end ---------------------------------------------------------------------

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    let isValid = true;

    if (!addData.branch_id) {
      tempErrors.branch_id = "Branch Name is required.";
      isValid = false;
    }

    if (!addData.empid) {
      tempErrors.empid = "Staff is required.";
      isValid = false;
    }

    // if (!addData.reason) {
    //     tempErrors.reason = "Reason  is required.";
    //     isValid = false;
    // }

    // if (!addData.status) {
    //     tempErrors.status = "Status is required.";
    //     isValid = false;
    // }

    setErrors(tempErrors);
    return isValid;
  };

  const [filteredItems, setfilterdata] = useState([]);

  const handleBranch = (e) => {
    const selectedBranchId = e.target.value; // Selected branch ID
    setAddData({ ...addData, branch_id: selectedBranchId }); // Update state for branch_id
    console.log("Selected Branch ID:", selectedBranchId);

    // Filter staff based on the selected branch
    const filteredData = responseDatas.filter((staff) =>
      staff.branch_id.some((branch) => branch._id === selectedBranchId)
    );

    setfilterdata(filteredData); // Update state with filtered staff
    console.log("Filtered Staff Data:", filteredData);
  };


  const handleEmpolye = (e) => {
    const selectedEmpId = e.target.value;
    const selectedEmpData = filteredItems.find((item) => item.empid === selectedEmpId);

    if (selectedEmpData) {
      setAddData({
        ...addData,
        empid: selectedEmpData.empid,
        staff_name: selectedEmpData.name, // Directly use staff name here
      });
    }
  };



  const [loading, setLoading] = useState(false);


  const [isPreviewshowModal, setPreviewShowModal] = useState(false);

  // const handlePreview = (_id, data) => {
  //   if (!_id) {
  //     console.error("Missing _id in data:", data);
  //     return;
  //   }

  //   setUpdateId(_id);
  //   console.log("Data to update:", data);

  //   setPreviewShowModal(true);

  //   setUpdateData({
  //     branch_id: data.branch_id || "",
  //     staff_id: data.staff_id || "",
  //     staff_name: data.staff_name || "",
  //     date: data.date || "",
  //     Description: data.Description || "",
  //     notes: data.notes || "",
  //     status: data.status || "",
  //   });
  // };


  const handlePreview = (_id, data) => {
    // if (previllages.edit || previllages.all) {
      setUpdateId(_id);
      console.log("The data to update", data);

      // Format date fields to YYYY-MM-DD
      const formatToYYYYMMDD = (inputDate) => {
        const date = new Date(inputDate);
        return !isNaN(date) ? date.toISOString().split("T")[0] : ""; // Format to YYYY-MM-DD or return an empty string if invalid
      };

      const formattedDate = formatToYYYYMMDD(data.date);
      const formattedToDate = formatToYYYYMMDD(data.to_date); // Format to_date correctly

      if (!formattedDate) {
        console.error("Invalid date:", data.date);
        return;
      }

      setPreviewShowModal(true);

      setUpdateData({
        branch_id: data.branch_id,
        empid: data.empid,
        staff_name: data.staff_name,
        type: data.type,
        date: formattedDate, // Formatted date
        from_date: data.from_date, // No change
        to_date: formattedToDate, // Formatted to_date
        reason: data.reason,
        status: data.status,
      });

      console.log("Formatted data for update:", {
        date: formattedDate,
        to_date: formattedToDate,
      });
    // } else {
    //   toast.error("You don't have the permission to update");
    // }
  };


  // ===== code for set the value in sales dropdown =========
  const [isBranchselectDisabled, setIsBranchDisabled] = useState(false);
  const [isStaffselectDisabled, setIsStaffDisabled] = useState(false);

  function handleAddClick() {

    // // Filter staff based on the selected branch
    // const filteredData = responseDatas.filter((staff) =>
    //   staff.branch_id.some((branch) => branch._id === selectedBranchId)
    // );

    // setfilterdata(filteredData); // Update state with filtered staff

    const dropdown_branchcode = localStorage.getItem("branch_code");

    if (dropdown_branchcode != "All") {
      const dropdown_branch_id = localStorage.getItem("branch_id");
      const dropdown_username = localStorage.getItem("username");
      const dropdown_name = localStorage.getItem("emp_name");
      // console.log("the dropdown_branch_id is : ",dropdown_branch_id);

      console.log("the addData is  : ", addData);
      console.log("the addData.staff_name is : ", addData.staff_name)
      console.log("the dropdown_username is : ", dropdown_username);

      addData.branch_id = dropdown_branch_id;
      addData.staff_name = dropdown_name;
      addData.empid = dropdown_username;

      console.log("the filteredItems is : ", filteredItems);

      setIsBranchDisabled(true)
      setIsStaffDisabled(true);
    }

    setShowModal(true)
  }
  // ===== code for set the value in sales dropdown =========


  return (
    <>
      {/* {loading === true ? (
        <div className="loading-overlay">
          <div className="loading-message">
            <img
              className="loadinggif"
              src={require("../../assets/img/output-onlinegiftools.gif")}
            />{" "}
          </div>
        </div>
      ) : (
        <div className="d-none"></div>
      )} */}
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Leave</b>
              </Link>
              {/* <Link to="" className="text-danger dashBoard_text">
                            <b>Profile</b>
                        </Link> */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className=" col-lg-9 d-flex">
            <h5 className="mt-2">Leave Details</h5>
            <div className="mx-3">
              {/* <button className='btn add' onClick={handleNewButtonClick}>Add </button> */}
              {/* {previllages.add && ( */}
                <button
                  className="btn btn-dark add mx-3"
                  // onClick={() => setShowModal(true)}
                  onClick={handleAddClick}
                >
                  Add
                </button>
              {/* )} */}
            </div>
          </div>
        </div>

        {/* ------------ search and per page ----------------------------*/}
        <div className="mb-1 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  value={state.pageSize}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === 'All') {
                      setPageSize(data.length);  // 'data' should be your dataset containing all the entries
                    } else {
                      setPageSize(Number(value));
                    }
                  }}
                >
                  <option value="All">All</option> {/* Add 'All' option */}
                  {[10, 20, 100, 200, 500, 1000].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      value={state.globalFilter || ""} // Use the globalFilter value
                      onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <TableContainer>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="d-flex">
                        <div className="mx-2">{column.render("Header")}</div>
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                        <div className="mx-1">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </div>
                      </div>
                    </Th>
                  ))}
                  <Th className="text-center">Action</Th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    ))}
                    <Td className="text-center">
                      {/* {previllages.edit && ( */}
                        <button
                          className="btn updelete mx-2"
                          onClick={() =>
                            handleUpdate(row.original._id, row.original)
                          }
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                      {/* )} */}

                      {/* {previllages.delete && ( */}
                        <button
                          className="btn delete"
                          onClick={() =>
                            handleOpenModal(
                              row.original._id,
                              row.original.empid
                            )
                          }
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      {/* )} */}

                      {/* <button
                        className="btn updelete mx-2"
                        onClick={() =>
                          handlePreview(row.original._id, row.original.empid)
                        }
                      >
                        <i className="fa-solid fa-circle-info"></i>
                      </button> */}

                      <button
                        className="btn updelete mx-2"
                        onClick={() => {
                          if (row.original) {
                            handlePreview(row.original._id, row.original);
                          } else {
                            console.error("row.original is undefined");
                          }
                        }}
                      >
                        <i className="fa-solid fa-circle-info"></i>
                      </button>



                    </Td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>

        {/* Modal for adding a new user */}

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Leave</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* branch id */}
            <div className="form-group">
              <div className="floating-label">
                <label className="form-label">
                  <b></b>
                </label>
                <select
                  className="form-select"
                  // value={addData.branch_id._id}
                  value={addData.branch_id}
                  placeholder=""
                  onChange={handleBranch}
                  // onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })}
                  disabled={isBranchselectDisabled}
                >
                  <option value="">Select Branch</option>
                  {branchDatas.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.branch_id} - {item.branch_name}
                    </option>
                  ))}
                </select>

                <label className={addData.branch_id ? "filled" : ""}>
                  Select Branch
                </label>
                {errors.branch_id && (
                  <div className="error-text">{errors.branch_id}</div>
                )}
              </div>

              {/* EMP ID */}
              <div className="floating-label">
                <label className="form-label">
                  <b></b>
                </label>
                <select
                  className="form-select"
                  value={addData.empid}
                  onChange={(e) => handleEmpolye(e)}
                  // onChange={(e) =>
                  //   setAddData({ ...addData, empid: e.target.value })
                  // }
                  disabled={isStaffselectDisabled}
                >
                  <option value="">Select Staff</option>
                  {filteredItems.map((item, index) => (
                    <option key={index} value={item.empid}>
                      {item.empid} - {item.name}
                    </option>
                  ))}
                </select>

                <label className={addData.empid ? "filled" : ""}>
                  Select Staff
                </label>
                {errors.empid && (
                  <div className="error-text">{errors.empid}</div>
                )}
              </div>
              {/* 
              <div className="floating-label">
                <label className="form-label">
                  <b></b>
                </label>
                <select
                  className="form-select"
                  value={addData.staff_name}
                  onChange={(e) =>
                    setAddData({ ...addData, staff_name: e.target.value })
                  }
                >
                  <option value="">Select Name</option>
                  {filteredItems.map((item, index) => (
                    <option key={index} value={item.name}>
                 
                      {item.name}
                    </option>
                  ))}
                </select>

                <label className={addData.staff_name ? "filled" : ""}>
         
                </label>
              </div> */}

              <div className="floating-label">
                <select
                  className="form-control"
                  value={addData.type}
                  onChange={(e) =>
                    setAddData({ ...addData, type: e.target.value })
                  }
                >
                  <option value="">Select Type</option>
                  <option value="SickLeave">Sick Leave</option>
                  <option value="CasualLeave">Casual Leave</option>
                  <option value="Unplanned Leave">Unplanned Leave</option>
                  <option value="Others">Others</option>
                </select>
                <label className={addData.type ? "filled" : ""}>
                  Select Type
                </label>
              </div>

              {/* Apply on date */}

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=""
                  value={currentDate}
                  onChange={(e) => setCurrentDate(e.target.value)}
                  required
                />
                <label className={addData.date ? "filled" : ""}>Apply On</label>
              </div>

              {/* Other input fields */}
              {/* <div className="floating-label">
                            <input className="form-control" type="date" placeholder='' value={formatDatefitching(addData.date)} onChange={(e) => setAddData({ ...addData, date: e.target.value })} required />
                            <label className={addData.date ? "filled" : ""}>
                                Date
                            </label>

                        </div> */}

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=""
                  value={formatDatefitching(addData.from_date)}
                  onChange={(e) =>
                    setAddData({ ...addData, from_date: e.target.value })
                  }
                  required
                />
                <label className={addData.from_date ? "filled" : ""}>
                  From Date
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=""
                  value={addData.to_date}
                  onChange={(e) =>
                    setAddData({ ...addData, to_date: e.target.value })
                  }
                  required
                />
                <label className={addData.to_date ? "filled" : ""}>
                  To date
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={addData.reason}
                  onChange={(e) =>
                    setAddData({ ...addData, reason: e.target.value })
                  }
                  required
                />
                <label className={addData.reason ? "filled" : ""}>Reason</label>
              </div>

              <div className="floating-label">
                <select
                  className="form-control"
                  value={addData.status}
                  onChange={(e) =>
                    setAddData({ ...addData, status: e.target.value })
                  }
                >
                  <option value="">Select Status</option>
                  <option value="Approved">Approved</option>
                  <option value="Pending">Pending</option>
                  <option value="Rejected">Rejected</option>
                </select>
                <label className={addData.status ? "filled" : ""}>
                  Select Status
                </label>
                {/* {errors.status && (
                                <div className="error-text">{errors.status}</div>
                            )} */}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handlesubmit}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ----------------update */}

        <Modal
          show={isUpdateshowModal}
          onHide={() => setUpdateShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Leave</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="floating-label">
                <select
                  className="form-select"
                  value={updateData.branch_id._id}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, branch_id: e.target.value })
                  }
                >
                  <option value="">Select Branch Id</option>
                  {branchDatas.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.branch_id} - {item.branch_name}
                    </option>
                  ))}
                </select>
                <label
                  className={updateData.branch_id.branch_name ? "filled" : ""}
                ></label>
              </div>

              <div className="floating-label ">
                <select
                  className="form-select"
                  value={updateData.empid}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, empid: e.target.value })
                  }
                >
                  <option value="">Select Staff</option>
                  {responseDatas.map((item, index) => (
                    <option key={index} value={item.empid}>
                      {item.empid}- {item.name}
                    </option>
                  ))}
                </select>
                <label className={updateData.empid ? "filled" : ""}>
                  Select Staff
                </label>
              </div>

              {/* emp ID */}

              {/* <div className="floating-label ">
                <select
                  className="form-select"
                  value={updateData.staff_name}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, staff_name: e.target.value })
                  }
                >
                  <option value="">Select Staff</option>
                  {responseDatas.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.empid}- {item.name}
                    </option>
                  ))}
                </select>
                <label className={updateData.staff_name ? "filled" : ""}>
                  Select Staff
                </label>
              </div> */}

              <div className="floating-label">
                <select
                  className="form-control"
                  value={updateData.type}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, type: e.target.value })
                  }
                >
                  <option value="">Select type</option>
                  <option value="SickLeave">Sick Leave</option>
                  <option value="CasualLeave">Casual Leave</option>
                  <option value="Unplanned Leave">Unplanned Leave</option>
                  <option value="Others">Others</option>
                </select>
                <label className={updateData.type ? "filled" : ""}>
                  Select Type
                </label>
              </div>

              {/* Apply on date */}

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=""
                  value={currentDate}
                  onChange={(e) => setCurrentDate(e.target.value)}
                  required
                />
                <label className={addData.date ? "filled" : ""}>Apply On</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=""
                  value={
                    updateData.from_date
                      ? formatDatefitching(updateData.from_date)
                      : ""
                  }
                  onChange={(e) =>
                    setUpdateData({ ...updateData, from_date: e.target.value })
                  }
                  required
                />
                <label className={updateData.from_date ? "filled" : ""}>
                  From Date
                </label>
              </div>



              {/* formatDatefitchinggg */}
              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  value={updateData.to_date || ""}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, to_date: e.target.value })
                  }
                  required
                // disabled
                />
                <label className={updateData.to_date ? "filled" : ""}>
                  To Date
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.reason}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, reason: e.target.value })
                  }
                  required
                />
                <label className={updateData.reason ? "filled" : ""}>
                  Reason
                </label>
              </div>

              <div className="floating-label">
                <select
                  className="form-control"
                  value={updateData.status}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, status: e.target.value })
                  }
                >
                  <option value="">Select Status</option>
                  <option value="Approved">Approved</option>
                  <option value="Pending">Pending</option>
                  <option value="Rejected">Rejected</option>
                </select>
                <label className={updateData.status ? "filled" : ""}>
                  Select Status
                </label>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setUpdateShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleupdatesubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ----------------delete */}

        <Modal
          className="modal"
          show={deleteShowModals}
          onHide={handleCloseModal}
        >
          <Modal.Header>
            <p>Are you sure you want to delete?</p>
          </Modal.Header>
          <Modal.Body>
            <label className="form-label d-flex">
              <h5 className="mx-1">Staff Id:</h5> <h5>{getdeletename} </h5>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal show={isPreviewshowModal} onHide={() => setPreviewShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Preview Leave</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {/* Other input fields */}



            <div className="form-group">
              <div className="floating-label">
                <select
                  className="form-select"
                  value={updateData.branch_id._id}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, branch_id: e.target.value })
                  }
                  disabled
                >
                  <option value="">Select Brand Id</option>
                  {branchDatas.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.branch_id} - {item.branch_name}
                    </option>
                  ))}
                </select>
                <label
                  className={updateData.branch_id.branch_name ? "filled" : ""}
                ></label>
              </div>

              <div className="floating-label ">
                <select
                  className="form-select"
                  value={updateData.empid}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, empid: e.target.value })
                  }
                  disabled
                >
                  <option value="">Select Staff</option>
                  {responseDatas.map((item, index) => (
                    <option key={index} value={item.empid}>
                      {item.empid}- {item.name}
                    </option>
                  ))}
                </select>
                <label className={updateData.empid ? "filled" : ""}>
                  Select Staff
                </label>
              </div>

              {/* emp ID */}

              {/* <div className="floating-label ">
                <select
                  className="form-select"
                  value={updateData.staff_name}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, staff_name: e.target.value })
                  }
                  disabled
                >
                  <option value="">Select Staff</option>
                  {responseDatas.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.empid}- {item.name}
                    </option>
                  ))}
                </select>
                <label className={updateData.staff_name ? "filled" : ""}>
                  Select Staff
                </label>
              </div> */}

              <div className="floating-label">
                <select
                  className="form-control"
                  value={updateData.type}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, type: e.target.value })
                  }
                  disabled
                >
                  <option value="">Select type</option>
                  <option value="SickLeave">Sick Leave</option>
                  <option value="CasualLeave">Casual Leave</option>
                  <option value="Unplanned Leave">Unplanned Leave</option>
                  <option value="Others">Others</option>
                </select>
                <label className={updateData.type ? "filled" : ""}>
                  Select Type
                </label>
              </div>

              {/* Apply on date */}

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=""
                  value={currentDate}
                  onChange={(e) => setCurrentDate(e.target.value)}
                  required
                  disabled
                />
                <label className={addData.date ? "filled" : ""}>Apply On</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=""
                  value={
                    updateData.from_date
                      ? formatDatefitching(updateData.from_date)
                      : ""
                  }
                  onChange={(e) =>
                    setUpdateData({ ...updateData, from_date: e.target.value })
                  }
                  disabled
                />
                <label className={updateData.from_date ? "filled" : ""}>
                  From Date
                </label>
              </div>



              {/* formatDatefitchinggg */}
              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  value={updateData.to_date || ""}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, to_date: e.target.value })
                  }
                  disabled
                // disabled
                />
                <label className={updateData.to_date ? "filled" : ""}>
                  To Date
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.reason}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, reason: e.target.value })
                  }
                  disabled
                />
                <label className={updateData.reason ? "filled" : ""}>
                  Reason
                </label>
              </div>

              <div className="floating-label">
                <select
                  className="form-control"
                  value={updateData.status}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, status: e.target.value })
                  }
                  disabled
                >
                  <option value="">Select Status</option>
                  <option value="Approved">Approved</option>
                  <option value="Pending">Pending</option>
                  <option value="Rejected">Rejected</option>
                </select>
                <label className={updateData.status ? "filled" : ""}>
                  Select Status
                </label>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button className='cancelbut' variant="secondary" onClick={() => setPreviewShowModal(false)}>Cancel</Button>

          </Modal.Footer>
        </Modal>




        {/* ----------------delete */}

        <Modal className="modal" show={deleteShowModals} onHide={handleCloseModal}>
          <Modal.Header>
            <p>Are you sure you want to Delete?</p>
          </Modal.Header>
          <Modal.Body>


            <label className="form-label d-flex">
              <h5 className='mx-1'>Staff Id:</h5> <h5>{getdeletename} </h5>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
          </Modal.Footer>
        </Modal>

        {/* _------------------------------pagination button -------------------------------- */}

        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-7 col-sm-12 mt-1">
              <span className="text-dark">
                Page {state.pageIndex + 1} of {pageCount}
              </span>
            </div>
            <div className="col-lg-5">
              <div className="mt-3 pb-5">
                <nav>
                  <ul className="pagination justify-content-center">
                    {createPagination()} {/* Pagination buttons */}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* ---------------------------------------end ---------------------------------- */}
      </div>
    </>
  );
}
export default Leave;
