// import { Modal, Button } from "react-bootstrap";
// import { useState } from "react";
// import { Link } from "react-router-dom";

// function SalaryDetails() {
//   const [showModal, setShowModal] = useState(false);

//   const toggleModal = () => setShowModal(!showModal);
//   return (
//     <>
//       <div style={{ marginTop: "-80px" }}>
//         <div className="row mb-3">
//           <div className="col-lg-12">
//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Dashboard / </b>
//             </Link>
//             <Link to="/staff" className="text-secondary dashBoard_text">
//               <b>Staff / </b>
//             </Link>
//             <Link to="" className="text-danger dashBoard_text">
//               <b>Salary</b>
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className="row">
//         <div className="col-lg-9 d-flex">
//           <h6 className="mt-2 bold">Salary Details</h6>
//         </div>
//       </div>

//       <div className="mb-3 mt-3">
//           <div className="row">
//             <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//               <label className="form-label text-dark mt-2">Show : </label>
//               <div>
//                 <select
//                   className="form-control w-100 mx-2"
//                   // onChange={(e) => {
//                   //   setItemsPerPage(e.target.value);
//                   // }}
//                 >
//                   {/* <option value='5'>5</option> */}
//                   <option value="10">10</option>
//                   <option value="20">20</option>
//                   <option value="50">50</option>
//                 </select>
//               </div>
//             </div>
//             <div className="col-lg-7 col-md-4 col-sm-1"></div>
//             <div className="col-lg-3 col-md-4 col-sm-7">
//               <div className="w-full flex-1">
//                 <form>
//                   <div className="relative">
//                     <input
//                       className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
//                       placeholder="Search ..."
//                       type="search"
//                       // value={searchQuery}
//                       // onChange={handleSearchChange}
//                     />
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>

//       <div className="card">
//         <table className="table table-striped">
//           <thead>
//             <tr>
//               <th>S.No</th>
//               <th>Staff ID</th>

//               <th>Staff Name</th>
//               <th>Designation</th>
//               <th>Month & Year</th>
//               <th>Actual Salary</th>
//               <th>Paid Salary</th>
//               <th>Status</th>
//               <th className="text-right">Action</th>
//             </tr>
//           </thead>
//           <tbody>
          
            
//           </tbody>
//         </table>
//       </div>

//       <Modal show={showModal} onHide={toggleModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>Update Salary</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="row">
//             <div className="col-lg-6">
//               <div className="form-group">
//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
                    
//                     required
//                   />
//                   <label className="">Staff ID</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
                    
//                     required
//                   />
//                   <label className="">Staff Name</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
                    
//                     required
//                   />
//                   <label className="">Designation</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
                    
//                     required
//                   />
//                   <label className="">Month & Year</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
                    
//                     required
//                   />
//                   <label className="">Salary Per Day</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
                    
//                     required
//                   />
//                   <label className="">Actual Salary</label>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-6">
//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
                  
//                   required
//                 />
//                 <label className="">Paid Salary</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="date"
//                   placeholder=""
                 
//                   required
//                 />
//                 <label className="">Paid Date</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
                 
//                   required
//                 />
//                 <label className="">Detection</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
                  
//                   required
//                 />
//                 <label className="">Present Day</label>
//               </div>
//               <div className="floating-label">
//                 {" "}
//                 <label className="form-label"></label>{" "}
//                 <select
//                   className="form-control"
                 
//                 >
//                   <option value="">Select Status</option>{" "}
//                   <option value="Approved">Paid</option>{" "}
//                   <option value="Pending">Pending</option>{" "}
//                   <option value="Rejected">Process</option>{" "}
//                 </select>{" "}
//               </div>

          
//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
                   
//                     required
//                   />
//                   <label className="">Reason</label>
//                 </div>
           
//             </div>
//           </div>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button variant="secondary" >
//             Close
//           </Button>
//           <Button variant="primary" >
//             Submit
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <div className="mt-3 pb-5">
//         <div className="row">
//           <div className="col-lg-10 col-sm-12">
//             <nav className="d-flex float-right">
//               <ul className="pagination justify-content-center">
//                 <li className="">
//                   <button className="page-link" o>
//                     Previous
//                   </button>
//                 </li>

//                 <li className="">
//                   <button className="page-link ">1</button>
//                 </li>

//                 <li className="">
//                   <button className="page-link ">Next</button>
//                 </li>
//               </ul>
//             </nav>{" "}
//           </div>
//           <div className="col-lg-2 col-sm-12 mt-1">
//             <div className="float-end">
//               <span className="text-dark ">Page 1 of 1</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default SalaryDetails;




import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ApiUrl from "../Environment/ApiUrl";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

function SalaryDetails() {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [salaryData, setSalaryData] = useState([]);
  const [status, setStatus] = useState("");

  const [type, settype] = useState("");
  const [expense_type, setexpense_type] = useState("");

  const [reason, setReason] = useState("");
  const [role_id, setroleid] = useState('');
  const [previllages, setprevillages] = useState({})
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [modalData, setModalData] = useState({
    staffId: "",
    staffName: "",
    designation: "",
    monthYear: "",
    salaryPerDay: "",
    actualSalary: "",
    paidSalary: "",
    presentDay: "",
    paidDate: "",
    detection: "",
    branch_id: "",
    totalWorkedHours: "",
    leave: "",
    status: "",
    bonus: "",
    incentive: "",
    Allowance: "",
    deduction: "",
    type: '',
    expense_type: "",
    overtime: "",
    overtimePay: "",
    base_salary: ""


  });



  // const [searchQuery, setSearchQuery] = useState("");
  const [showCount, setShowCount] = useState(10);

  const toggleModal = () => setShowModal(!showModal);



  const navigate = useNavigate();

  const handlesalary = () => {
    navigate("/salary_generate");
  };



  const [lastselectpaidsalary, setlastselctpaidsalary] = useState(0);
  const [lastselectallowance, setlastselctallowance] = useState(0);


  const handleRowClick = (data) => {
    console.log("the data waas console", data)
    setModalData({
      _id: data._id,
      staffId: data.staffId || "",
      staffName: data.staffName || "",
      designation: data.designation || "",
      monthYear: data.monthYear,
      salaryPerDay: data.salaryPerDay || "",
      actualSalary: data.actualSalary || "",
      paidSalary: data.paidSalary || "",
      presentDay: data.presentDay || "",
      paidDate: data.paidDate || "",
      detection: data.detection || "",
      branch_id: data.branch_id || "",
      totalWorkedHours: data.totalWorkedHours || "",
      leave: data.leave || "",
      bonus: data.bonus || "",
      incentive: data.incentive || "",
      Allowance: data.allowance || "",
      deduction: data.deduction || "",

      overtime: data.overtime || "",
      overtimePay: data.overtimePay || "",
      base_salary: data.base_salary || ""


    });


    setStatus(data.status || "");
    setReason("");
    toggleModal();

    setlastselctpaidsalary(data.paidSalary);
    setlastselctallowance(data.allowance);
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, staffName) => {

    console.log(" the id", _id);
    console.log(" the staffName", staffName);
    setdeleteid(_id);
    setdeletename(staffName);
    setDeleteShowModals(true);

  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        ApiUrl + `/salary/${getdeleteid}`
      );
      if (response == 200) {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } else {

      }
      console.log("Deleted data", response);

      toast.error("Deleted successfully!");

      setDeleteShowModals(false);

      window.location.reload();
    } catch (error) {
      console.error("Error deleting data:", error.message);
    }
  };




  const handleFormSubmit = async () => {
    console.log("the salary update button ", modalData)
    const updatedData = {
      ...modalData,
      paidSalary: modalData.paidSalary,// Ensure modalData contains all the necessary fields
      status,
      reason, // Adding reason to the updated data
      expense_type,
      type
    };
    console.log("the salary update button 1212", updatedData)
    try {
      setLoading(true);
      // Send data in the body of the PUT request
      const response = await axios.put(ApiUrl + `/salary/${modalData._id}`, updatedData);

      console.log("Salary updated successfully", response.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);






      // If the status is 'Paid', post to expense list
      if (status === "Paid") {


        const expenseData = {
          branch_id: "67a1aa1901b06c1c1cdaeb42",
          type: "67b06d3fbd7a562c700db1c6",
          date: updatedData.paidDate, // Expense date (could be adjusted to match salary paid date)
          notes: "Paid",
          amount: updatedData.paidSalary, // Assuming you want to set paidSalary as the amount
          expense_type: "Office expense", // You could change this if the expense type is different
          paidto: updatedData.staffName, // Paid to staff name
          paytype: "Gpay", // Assuming pay type is Gpay, but could be another
        };

        // POST the expense to the expense list API
        const expenseResponse = await axios.post(ApiUrl + `/expense_list`, expenseData);
        console.log("Expense posted successfully", expenseResponse.data);
      }

      // Update the UI based on the response data
      setSalaryData((prevData) =>
        prevData.map((data) =>
          data.staffId === updatedData.staffId
            ? { ...data, ...response.data }
            : data
        )

      );

      // Close the modal after updating
      setShowModal(false);
    } catch (error) {
      console.error("Error updating salary:", error);
      // Optionally show an error message or handle error cases
    }


  };














  useEffect(() => {

    // gettoken();
    fetchData();
    // getprevillages();
  }, []);




  // const gettoken = async () => {
  //   const token = localStorage.getItem('token');
  //   // console.log("the toekn in the localStorage is : ",token);
  //   try {
  //     const response = await axios.post(ApiUrl + "/get_detail", { "token": token });
  //     console.log("token detail from server:", response.data);
  //     // setResponseData(response.data);

  //     console.log("The role id is : ", response.data.detail.rolename);
  //     setroleid(response.data.detail.rolename);
  //     console.log("the role_id is : ", role_id);

  //     localStorage.setItem('role_id', response.data.detail.rolename);

  //     const id = response.data.detail.rolename

  //     // getpages();

  //     if (id) {
   
  //     }

  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // }


  // const getprevillages = () => {
  //   const username = JSON.parse(localStorage.getItem("user_previllages"));
  //   console.log("tyhe username get by local stroage : ", username.modules);

  //   const total_previlages = username.modules;

  //   const new_enquiry = total_previlages.find((item) => item.name == "Salary Details")

  //   console.log("the new_enquiry is : ", new_enquiry.actions);

  //   setprevillages(new_enquiry.actions)
  // }




 

  const fetchData = async () => {
    try {
      setLoading(true);
      // Retrieve branch details and employee name from localStorage
      const branchId = localStorage.getItem("branch_id");
      const branchName = localStorage.getItem("branch_name");
      const empName = localStorage.getItem("username");

      // Check if the condition to fetch all data is met
      if (branchId === null || branchName === "Branches" || empName === undefined) {
        // Fetch all attendance data if condition is true
        const leadsResponse = await axios.get(ApiUrl + "/salary");
        // const leadsResponse = await axios.get(apiUrl + "/salary_filter");
        console.log("Response from server with all attendance data in if condition :", leadsResponse.data);
        setSalaryData(leadsResponse.data.reverse());
      } else {
        // Fetch data based on branchId and empid (from localStorage)
        const leadsResponse = await axios.get(ApiUrl + "/salary", {
          params: { data: branchId },
        });
        console.log("Response from server with filtered attendance data in else condition :", leadsResponse.data);

        // Get the empid from localStorage
        const localEmpid = localStorage.getItem("username");

        // Filter data based on the empid from localStorage
        const filteredData = leadsResponse.data.filter(item => item.staffId === localEmpid);




        setSalaryData(filteredData.reverse());
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }

  };






  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = salaryData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filteredData = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(salaryData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };

  // =============================

  const createPagination = () => {
    let liTag = [];
    let beforePage = currentPage - 1;
    let afterPage = currentPage + 1;
    let active;

    if (currentPage > 1) {
      liTag.push(
        <li className="page-item" key="prev">
          <button
            className="page-link"
            onClick={() => paginate(currentPage - 1)}
          >
            <FaAngleLeft /> Prev
          </button>
        </li>
      );
    }

    if (currentPage > 2) {
      liTag.push(
        <li className="page-item" key="first" onClick={() => paginate(1)}>
          <button className="page-link">1</button>
        </li>
      );
      if (currentPage > 3) {
        liTag.push(
          <li className="page-item disabled" key="dots1">
            <span className="page-link">...</span>
          </li>
        );
      }
    }

    if (currentPage === totalPages) {
      beforePage -= 2;
    } else if (currentPage === totalPages - 1) {
      beforePage -= 1;
    }

    if (currentPage === 1) {
      afterPage += 2;
    } else if (currentPage === 2) {
      afterPage += 1;
    }

    for (let plength = beforePage; plength <= afterPage; plength++) {
      if (plength > totalPages || plength <= 0) continue;
      active = currentPage === plength ? "active" : "";
      liTag.push(
        <li
          className={`page-item ${active}`}
          key={plength}
          onClick={() => paginate(plength)}
        >
          <button className="page-link">{plength}</button>
        </li>
      );
    }

    if (currentPage < totalPages - 1) {
      if (currentPage < totalPages - 2) {
        liTag.push(
          <li className="page-item disabled" key="dots2">
            <span className="page-link">...</span>
          </li>
        );
      }
      liTag.push(
        <li
          className="page-item"
          key="last"
          onClick={() => paginate(totalPages)}
        >
          <button className="page-link">{totalPages}</button>
        </li>
      );
    }

    if (currentPage < totalPages) {
      liTag.push(
        <li className="page-item" key="next">
          <button
            className="page-link"
            onClick={() => paginate(currentPage + 1)}
          >
            Next <FaAngleRight />
          </button>
        </li>
      );
    }

    return liTag;
  };



  const incentiveonchange = (e) => {
    console.log("the modalData.Allowance in incentiveonchange : ", modalData.incentive);

    setModalData((prev) => ({
      ...prev,
      incentive: e.target.value,
      paidSalary: lastselectpaidsalary ? (Number(e.target.value) + Number(lastselectpaidsalary) + Number(modalData.Allowance) + Number(modalData.bonus)) - lastselectallowance : Number(e.target.value),
    }));
  }



  const bonusonchange = (e) => {
    setModalData((prev) => ({
      ...prev,
      bonus: e.target.value,
      // paidSalary: e.target.value ? modalData.paidSalary - e.target.value : modalData.paidSalary
      paidSalary: lastselectpaidsalary ? (Number(e.target.value) + Number(lastselectpaidsalary) + Number(modalData.incentive) + Number(modalData.Allowance)) - lastselectallowance : Number(e.target.value),
    }));
  }



  // ============================================================================


  // const filteredData = salaryData.filter((data) => {
  //   return (
  //     (data.staffId &&
  //       data.staffId.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //     (data.empid &&
  //       data.empid.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //     (data.name &&
  //       data.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //     (data.designation_detail[0]?.designation &&
  //       data.designation_detail[0]?.designation
  //         .toLowerCase()
  //         .includes(searchQuery.toLowerCase()))
  //   );
  // });

  const [rolename, setrolename] = useState([]);
  useEffect(() => {

    const branchName = localStorage.getItem("branch_name");
    setrolename(branchName);
  }, []);

  const [loading, setLoading] = useState(false)


  return (
    <>
      {/* {loading === true ? (
        <div className="loading-overlay">
          <div className="loading-message">
          
          </div>
        </div>
      ) : (
        <div className="d-none"></div>
      )} */}

      <div style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/staff" className="text-secondary dashBoard_text">
              <b>Staff / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Salary</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10">
          <div className="d-flex">
            <h5 className="mt-2">Salary Details</h5>

          </div>
        </div>
        <div className="col-lg-2">
          <button className="btn btn-dark" onClick={handlesalary}>
            Salary Generate
          </button>
        </div>
      </div>

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show: </label>
            <select
              className="form-control w-100 mx-2"
              value={showCount}
              onChange={(e) => setShowCount(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <form>
              <input
                className="form-control w-full bg-white pl-8"
                placeholder="Search ..."
                type="search"
                value={searchQuery}
                onChange={handleSearchChange}
              // onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>




        </div>
      </div>



      <div className="card">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>S.No</th>
              {/* {rolename === "Branches" && <th>Branch</th>} */}
              <th>Staff ID</th>
              <th>Staff Name</th>
              <th>Designation</th>
              <th>Month & Year</th>
              <th>Actual Salary</th>
              <th>Paid Salary</th>
              <th >Status</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>

            {filteredData.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                {/* <td>{data.branch_id}</td> */}
                <td>{data.staffId}</td>
                <td>{data.staffName}</td>
                <td>{data.designation}</td>
                <td>{data.monthYear}</td>
                <td>{data.actualSalary}</td>
                <td>{data.paidSalary}</td>

                <td>
                  <p className={data.status === 'Paid' ? 'active-row' : 'inactive-row'}>
                    {data.status}
                  </p>
                </td>

                <td className="text-center ">
                  {/* {previllages.edit && ( */}
                    <button
                      className="btn updelete mx-2"
                      onClick={() => handleRowClick(data)}
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                  {/* )} */}
                  {/* {previllages.delete && ( */}
                    <button
                      className="btn delete "
                      onClick={() => handleOpenModal(data._id, data.staffName)}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                  {/* )} */}



                  <button
                    className="btn delete "
                    onClick={() => navigate('/payslip')}
                  >
                    <i className="fa-solid fa-print"></i>
                  </button>
                </td>



              </tr>
            ))

            }

          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Salary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.branch_id}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        branch_id: e.target.value,
                      })
                    }
                    required
                  />
                  <label className="">Branch</label>
                </div>


                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.staffId}
                    onChange={(e) =>
                      setModalData({ ...modalData, staffId: e.target.value })
                    }
                    required
                  />
                  <label className="">Staff ID</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.staffName}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        staffName: e.target.value,
                      })
                    }
                    required
                  />
                  <label className="">Staff Name</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.designation}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        designation: e.target.value,
                      })
                    }
                    required
                  />
                  <label className="">Designation</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.monthYear}
                    onChange={(e) =>
                      setModalData({ ...modalData, monthYear: e.target.value })
                    }
                    required
                  />
                  <label className="">Month & Year</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.salaryPerDay}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        salaryPerDay: e.target.value,
                      })
                    }
                    required
                  />
                  <label className="">Salary Per Day</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.actualSalary}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        actualSalary: e.target.value,
                      })
                    }
                    required
                  />
                  <label className="">Actual Salary</label>
                </div>
              </div>
            </div>

            <div className="col-lg-6">

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={modalData.deduction}

                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      deduction: e.target.value,
                    })
                  }
                  // onChange={detectiononchange}
                  required
                />
                <label className="">Detection</label>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={modalData.overtime}
                      onChange={(e) =>
                        setModalData({
                          ...modalData,
                          overtime: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">OverTime</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={modalData.overtimePay}
                      onChange={(e) =>
                        setModalData({
                          ...modalData,
                          overtimePay: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">OT Pay</label>
                  </div>
                </div>

              </div>



              <div className="row">
                <div className="col-lg-6">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={modalData.presentDay}
                      onChange={(e) =>
                        setModalData({
                          ...modalData,
                          presentDay: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">present</label>
                  </div>
                </div>
                <div className="col-lg-6">

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={modalData.leave}
                      onChange={(e) =>
                        setModalData({
                          ...modalData,
                          leave: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">Leave </label>
                  </div>
                </div>


                <div className="col-lg-6">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={modalData.Allowance}
                      onChange={(e) =>
                        setModalData({
                          ...modalData,
                          Allowance: e.target.value,
                          // paidSalary: e.target.value ? paidSalary - e.target.value : paidSalary
                        })
                      }
                      // onChange={allowanceonchange}
                      required
                    />
                    <label className="">Allowance</label>
                  </div>
                </div>
                <div className="col-lg-6">

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={modalData.totalWorkedHours}
                      onChange={(e) =>
                        setModalData({
                          ...modalData,
                          totalWorkedHours: e.target.value,
                        })
                      }
                      required
                    />
                    <label className="">TotalHours </label>
                  </div>
                </div>





                <div className="col-lg-6">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={modalData.bonus}
                      // onChange={(e) =>
                      //   setModalData({
                      //     ...modalData,
                      //     bonus: e.target.value,
                      //   })
                      // }
                      onChange={bonusonchange}
                      required
                    />
                    <label className="">Bonus</label>
                  </div>
                </div>
                <div className="col-lg-6">

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={modalData.incentive}
                      // onChange={(e) =>
                      //   setModalData({
                      //     ...modalData,
                      //     incentive: e.target.value,
                      //   })
                      // }
                      onChange={incentiveonchange}
                      required
                    />
                    <label className="">Incentive </label>
                  </div>
                </div>
              </div>


              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={modalData.paidSalary}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      paidSalary: e.target.value,
                    })
                  }
                  required
                />
                <label className="">Paid Salary</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={modalData.base_salary}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      base_salary: e.target.value,
                    })
                  }
                  required
                />
                <label className="">Basic Salary</label>
              </div>



              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  value={
                    modalData.paidDate
                      ? new Date(modalData.paidDate).toISOString().split("T")[0]
                      : ""
                  }
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      paidDate: e.target.value,
                    })
                  }
                  required
                />
                <label className="">Paid Date</label>
              </div>



              {/* 
              branch_id:data.branch_id || "",
              totalWorkedHours:data.totalWorkedHours||"",
        */}

              <div className="floating-label">
                {" "}
                <label className="form-label"></label>{" "}
                <select
                  className="form-control"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >

                  <option value="">Select Status</option>{" "}
                  <option value="Paid">Paid</option>{" "}
                  <option value="Pending">Pending</option>{" "}
                  <option value="Process">Process</option>{" "}
                </select>{" "}
              </div>

              {status === "Pending" && (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    required
                  />
                  <label className="">Reason</label>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFormSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Staff Name :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>


      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {currentPage} of {totalPages}
            </span>
          </div>
          <div className="col-lg-5">
            <nav>
              <ul className="pagination justify-content-center">
                {createPagination()}
              </ul>
            </nav>
          </div>
        </div>
      </div>


    </>
  );
}

export default SalaryDetails;

