
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Modal, Button } from "react-bootstrap";
// import apiUrl from "../../environment/Api";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
// import { FaAngleLeft, FaAngleRight } from "react-icons/fa";


// function Staff() {
//   const [responseData, setResponseData] = useState([]);
//   const [isUpdateshowModal, setUpdateShowModal] = useState(false);
//   const [deleteShowModals, setDeleteShowModals] = useState(false);
//   const [role_id, setroleid] = useState("");
//   const [previllages, setprevillages] = useState({});

//   const navigate = useNavigate();




//   const handleUpdate = (_id, data) => {
//     if (previllages.edit || previllages.all) {
//       navigate(`/updatestaff/${_id}`);
//     } else {
//       toast.error("You don't have the permission to update");
//     }
//   };

//   const [addData, setAddData] = useState({
//     branch_id: "",
//     empid: "",
//     name: "",
//     fathername: "",
//     mobile: "",

//     dateofbirth: "",
//     interview_date: "",
//     join_date: "",
//     address: "",
//     salary: "",
//     designation: "",
//     qualification: "",
//     notes: "",
//     timings: "",
//     status: "",
//     relive_date: "",
//     documents: "",
//   });

//   const [updateData, setUpdateData] = useState({
//     branch_id: "",
//     empid: "",
//     name: "",
//     fathername: "",
//     mobile: "",
//     dateofbirth: "",
//     interview_date: "",
//     join_date: "",
//     address: "",
//     salary: "",
//     designation: "",
//     qualification: "",
//     notes: "",
//     timings: "",
//     status: "",
//     relive_date: "",
//     documents: "",
//   });




//   useEffect(() => {
//     fetchData();
//     gettoken();
//     window.scrollTo(0, 0);
//     getprevillages();
//   }, []);



//   // const fetchData = async () => {
//   //   try {
//   //     const response = await axios.get(apiUrl + "/staff_data", {
//   //       params: { data: localStorage.getItem("branch_id","username",)}
//   //     });

//   //     console.log("Response from server:", response.data);
//   //     setResponseData(response.data.reverse());
//   //   } catch (error) {
//   //     console.error("Error fetching data:", error.message);
//   //   }
//   // };



//   // username only based data from local storage

//   // const fetchData = async () => {
//   //   try {
//   //     const username = localStorage.getItem("username");  // Retrieve 'username' from localStorage
//   //     const branchId = localStorage.getItem("branch_id");  // Retrieve 'branch_id' from localStorage
//   //     const branchName = localStorage.getItem("branch_name");  // Retrieve 'branch_name' from localStorage
//   //     const empName = localStorage.getItem("emp_name");  // Retrieve 'emp_name' from localStorage

//   //     // Check if branch_id is null or branch_name is "Branches" and emp_name is undefined
//   //     if (branchId === null || branchName === "Branches" || empName === undefined) {
//   //       // Fetch all staff data if the condition is true
//   //       const response = await axios.get(apiUrl + "/staff");
//   //       console.log("Response from server:", response.data);

//   //       // Set the response data to show all staff
//   //       setResponseData(response.data);  // Display all staff data
//   //     } else {
//   //       // Fetch staff data based on the branch_id
//   //       const response = await axios.get(apiUrl + "/staff_data", {
//   //         params: { branch_id: branchId },
//   //       });
//   //       console.log("Response from server:", response.data);

//   //       // Filter the data where the username matches the value from localStorage
//   //       const staffData = response.data.reverse().filter(staff => staff.empid === username);

//   //       if (staffData.length > 0) {
//   //         console.log("Staff data matching username:", staffData);

//   //         // Get branch_name and empid
//   //         const empData = staffData;
//   //         const empId = empData.empid;
//   //         const branchName = empData.branch_name;

//   //         console.log("Employee ID:", empId);
//   //         console.log("Branch Name:", branchName);

//   //         setResponseData(empData);  // Set the specific staff data
//   //       } else {
//   //         console.log("No matching staff found for the given username");
//   //       }
//   //     }
//   //   } catch (error) {
//   //     console.error("Error fetching data:", error.message);
//   //   }
//   // };


//   // username and empid only based data from local storage

//   const fetchData = async () => {
//     try {
//       const username = localStorage.getItem("username");  // Retrieve 'username' from localStorage
//       const branchId = localStorage.getItem("branch_id");  // Retrieve 'branch_id' from localStorage
//       const branchName = localStorage.getItem("branch_name");  // Retrieve 'branch_name' from localStorage
//       const empName = localStorage.getItem("emp_name");  // Retrieve 'emp_name' from localStorage

//       // Check if branch_id is null or branch_name is "Branches" and emp_name is undefined
//       if (branchId === null || branchName === "Branches" || empName === undefined) {
//         // Fetch all staff data if the condition is true
//         const response = await axios.get(apiUrl + "/staff");
//         console.log("Response from server:", response.data);

//         // Set the response data to show all staff
//         setResponseData(response.data);  // Display all staff data
//       } else {
//         // Fetch staff data based on the branch_id
//         const response = await axios.get(apiUrl + "/staff_data", {
//           params: { branch_id: branchId },
//         });
//         console.log("Response from server:", response.data);

//         // Filter the data where the username matches the value from localStorage
//         const staffData = response.data.reverse().filter(staff => staff.empid === username);

//         if (staffData.length > 0) {
//           console.log("Staff data matching username:", staffData);

//           // Get branch_name and empid
//           const empData = staffData;
//           const empId = empData.empid;
//           const branchName = empData.branch_name;

//           console.log("Employee ID:", empId);
//           console.log("Branch Name:", branchName);

//           setResponseData(empData);  // Set the specific staff data
//         } else {
//           console.log("No matching staff found for the given username");
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };





//   const gettoken = async () => {
//     const token = localStorage.getItem("token");
//     try {
//       const response = await axios.post(apiUrl + "/get_detail", {
//         token: token,
//       });
//       console.log("token detail from server:", response.data);

//       setroleid(response.data.detail.rolename);

//       localStorage.setItem("role_id", response.data.detail.rolename);

//       const id = response.data.detail.rolename;
//       if (id) {
//         // try {
//         //   const response = await axios.get(apiUrl + `/role_2/${id}`);

//         //   const modules = response.data.modules;

//         //   const filtered_access = modules.filter(
//         //     (item) => item.name == "Branch"
//         //   );

//         //   setprevillages(filtered_access[0].actions);
//         // } catch (error) {
//         //   console.error("Error fetching data:", error.message);
//         // }
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };



//   const getprevillages = () => {
//     const username = JSON.parse(localStorage.getItem("user_previllages"));
//     console.log("tyhe username get by local stroage : ", username.modules);

//     const total_previlages = username.modules;

//     const new_enquiry = total_previlages.find((item) => item.name == "Staff")

//     console.log("the new_enquiry is : ", new_enquiry.actions);

//     setprevillages(new_enquiry.actions)
//   }







//   const handlesubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const formData = {
//         branch_id: addData.branch_id,
//         empid: addData.empid,
//         name: addData.name,
//         fathername: addData.fathername,
//         mobile: addData.mobile,
//         dateofbirth: addData.dateofbirth,
//         interview_date: addData.interview_date,
//         join_date: addData.join_date,
//         address: addData.address,
//         salary: addData.salary,
//         designation: addData.designation,
//         qualification: addData.qualification,
//         notes: addData.notes,
//         timings: addData.timings,
//         status: addData.status,
//         relive_date: addData.relive_date,
//         documents: addData.documents,
//       };

//       const response = await axios.post(apiUrl + "/staff", formData);
//       console.log("Updated:", response);

//       fetchData();
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const [updateid, setUpdateId] = useState("");

//   const handleupdatesubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const formData = {
//         branch_id: updateData.branch_id,
//         empid: updateData.empid,
//         name: updateData.name,
//         fathername: updateData.fathername,
//         mobile: updateData.mobile,
//         dateofbirth: updateData.dateofbirth,
//         interview_date: updateData.interview_date,
//         join_date: updateData.join_date,
//         address: updateData.address,
//         salary: updateData.salary,
//         designation: updateData.designation,
//         qualification: updateData.qualification,
//         notes: updateData.notes,
//         timings: updateData.timings,
//         status: updateData.status,
//         relive_date: updateData.relive_date,
//         documents: updateData.documents,
//       };

//       console.log("the update submit data", formData);
//       const response = await axios.put(apiUrl + `/staff/${updateid}`, formData);
//       console.log("response for update", response);
//       setUpdateShowModal(false);
//       fetchData();
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       const response = await axios.delete(apiUrl + `/staff/${getdeleteid}`);
//       console.log("delete data ", response);
//       toast.error("Delete successfully!");
//       setDeleteShowModals(false);
//       fetchData();
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const handleCloseModal = () => {
//     setDeleteShowModals(false);
//   };

//   const [getdeleteid, setdeleteid] = useState("");
//   const [getdeletename, setdeletename] = useState("");
//   const handleOpenModal = (id, name) => {
//     if (previllages.all || previllages.delete) {
//       console.log(" the id", id);
//       console.log(" the name", name);
//       setdeleteid(id);
//       setdeletename(name);
//       setDeleteShowModals(true);
//     } else {
//       toast.error("You don't have the permission to delete");
//     }
//   };

//   // the pagination ---------------------------------------------------------------------
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [searchQuery, setSearchQuery] = useState("");
//   // Pagination
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);



//   const filtered = currentItems.filter((item) =>
//     Object.values(item).some(
//       (value) =>
//         (typeof value === "string" &&
//           value.toLowerCase().includes(searchQuery.toLowerCase())) ||
//         (typeof value === "number" && value.toString().includes(searchQuery))
//     )
//   );

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//     setCurrentPage(1);
//   };

//   const totalPages = Math.ceil(responseData.length / itemsPerPage);

//   const goToNextPage = () => {
//     setCurrentPage((prevPage) =>
//       prevPage === totalPages ? prevPage : prevPage + 1
//     );
//   };

//   const goToPrevPage = () => {
//     setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
//   };
//   // the pagination  end ---------------------------------------------------------------------

//   const createPagination = () => {
//     let liTag = [];
//     let beforePage = currentPage - 1;
//     let afterPage = currentPage + 1;
//     let active;

//     if (currentPage > 1) {
//       liTag.push(
//         <li className="page-item" key="prev">
//           <button
//             className="page-link"
//             onClick={() => paginate(currentPage - 1)}
//           >
//             <FaAngleLeft /> Prev
//           </button>
//         </li>
//       );
//     }

//     if (currentPage > 2) {
//       liTag.push(
//         <li className="page-item" key="first" onClick={() => paginate(1)}>
//           <button className="page-link">1</button>
//         </li>
//       );
//       if (currentPage > 3) {
//         liTag.push(
//           <li className="page-item disabled" key="dots1">
//             <span className="page-link">...</span>
//           </li>
//         );
//       }
//     }

//     if (currentPage === totalPages) {
//       beforePage -= 2;
//     } else if (currentPage === totalPages - 1) {
//       beforePage -= 1;
//     }

//     if (currentPage === 1) {
//       afterPage += 2;
//     } else if (currentPage === 2) {
//       afterPage += 1;
//     }

//     for (let plength = beforePage; plength <= afterPage; plength++) {
//       if (plength > totalPages || plength <= 0) continue;
//       active = currentPage === plength ? "active" : "";
//       liTag.push(
//         <li
//           className={`page-item ${active}`}
//           key={plength}
//           onClick={() => paginate(plength)}
//         >
//           <button className="page-link">{plength}</button>
//         </li>
//       );
//     }

//     if (currentPage < totalPages - 1) {
//       if (currentPage < totalPages - 2) {
//         liTag.push(
//           <li className="page-item disabled" key="dots2">
//             <span className="page-link">...</span>
//           </li>
//         );
//       }
//       liTag.push(
//         <li
//           className="page-item"
//           key="last"
//           onClick={() => paginate(totalPages)}
//         >
//           <button className="page-link">{totalPages}</button>
//         </li>
//       );
//     }

//     if (currentPage < totalPages) {
//       liTag.push(
//         <li className="page-item" key="next">
//           <button
//             className="page-link"
//             onClick={() => paginate(currentPage + 1)}
//           >
//             Next <FaAngleRight />
//           </button>
//         </li>
//       );
//     }

//     return liTag;
//   };






//   // =====================================================================
//   const Navigate = useNavigate();

//   const handleStaffInfo = (id) => {
//     Navigate(`/staffinfo/${id}`);
//   };






//   const [rolename, setrolename] = useState([]);
//   useEffect(() => {

//     const branchName = localStorage.getItem("branch_name");
//     setrolename(branchName);
//   }, []);






//   return (
//     <div className="top">
//       <div className="" style={{ marginTop: "-80px" }}>
//         <div className="row mb-3">
//           <div className="col-lg-11">
//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Dashboard / </b>
//             </Link>
//             {/* <Link to="" className="text-secondary dashBoard_text">
//               <b>Staff / </b>
//             </Link> */}
//             <Link to="" className="text-danger dashBoard_text">
//               <b>Staff</b>
//             </Link>
//           </div>
//         </div>
//       </div>
//       <div className=" row">
//         <div className="col-lg-4">
//           <div className="d-flex ">
//             <h4 className="mt-2">Staff</h4>
//             <div className="mx-3">
//               {/* <button className="btn add" onClick={handleNewButtonClick}>
//                 Add
//               </button> */}
//               {previllages.add && (
//                 <button
//                   className="btn btn-success add mx-3"
//                   onClick={() => navigate("/addstaff")}
//                 >
//                   Add
//                 </button>
//               )}
//             </div>
//           </div>
//         </div>

//         <div className="col-lg-8 text-end">

//           {/* <div>
//             <input
//               type="file"
//               id="file-upload"
//               accept=".xls,.xlsx"
//               style={{ display: "none" }}
//               // onChange={handleFileUpload}
//             />
//             <label htmlFor="file-upload" className="btn btn-secondary">
//               File Upload
//             </label>
//           </div> */}
//         </div>
//       </div>

//       {/* ------------ search and per page ----------------------------*/}

//       <div className="mb-3 mt-3">
//         <div className="row">
//           <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//             <label className="form-label text-dark mt-2">Show : </label>
//             <div>
//               <select
//                 className="form-control w-100 mx-2"
//                 onChange={(e) => {
//                   setItemsPerPage(e.target.value);
//                 }}
//               >
//                 {/* <option value="5">5</option> */}
//                 <option value="10">10</option>
//                 <option value="20">20</option>
//                 <option value="50">50</option>
//               </select>
//             </div>
//           </div>
//           <div className="col-lg-7 col-md-4 col-sm-1"></div>
//           <div className="col-lg-3 col-md-4 col-sm-7">
//             <div className="w-full flex-1">
//               <form>
//                 <div className="relative">
//                   <input
//                     className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
//                     placeholder="Search ..."
//                     type="search"
//                     value={searchQuery}
//                     onChange={handleSearchChange}
//                   />
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* --------------------------------end------------------------------------------- */}
//       <div className="card">
//         <div className="mt-1">
//           <table className="table table-striped">
//             <thead>
//               <tr>
//                 <th>S No.</th>
//                 {rolename === "Branches" && (<th>Branch</th>)}
//                 <th>Staff Id</th>
//                 <th>Staff Name</th>
//                 <th>Mobile No</th>
//                 {/* <th>Designation</th> */}
//                 <th>Status</th>
//                 <th className="text-center">Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filtered.map((item, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   {rolename === "Branches" && (<td>{item.branch_id[0]?.branch_name}</td>)}
//                   <td>{item.empid}</td>
//                   <td>{item.name}</td>
//                   <td>{item.mobile}</td>
//                   {/* <td>{item.designation[0]?.designation}</td> */}

//                   {item.status == "Active" ? (
//                     <td className="text-center hideres">
//                       <p className=" text-white green-label">{item.status}</p>
//                     </td>
//                   ) : (
//                     <td className="text-center hideres">
//                       <p className="text-white red-label">{item.status}</p>
//                     </td>
//                   )}
//                   <td className="text-center">
//                     {/* <button
//                       className="btn updelete mx-2"
//                       onClick={() => handleUpdate(item._id, item)}
//                     >
//                       <i class="fa-solid fa-pen-to-square"></i>
//                     </button>
//                     <button
//                       className="btn delete"
//                       onClick={() => handleOpenModal(item._id, item.empid)}
//                     >
//                       <i class="fa-solid fa-trash-can"></i>
//                     </button> */}
//                     {previllages.edit && (
//                       <button
//                         className="btn updelete mx-2"
//                         onClick={() => handleUpdate(item._id, item)}
//                       >
//                         <i className="fa-solid fa-pen-to-square"></i>
//                       </button>
//                     )}
//                     {previllages.delete && (
//                       <button
//                         className="btn delete"
//                         onClick={() => handleOpenModal(item._id, item.empid)}
//                       >
//                         <i className="fa-solid fa-trash-can"></i>
//                       </button>
//                     )}
//                     <button
//                       className="btn"
//                       onClick={() => handleStaffInfo(item._id)}
//                     >
//                       <i class="fa-solid fa-circle-info"></i>
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>

//       <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Update </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {/* Other input fields */}
//           <label className="form-label">
//             <b>Branch Id :</b>
//           </label>
//           <input
//             className="form-control"
//             type="text"
//             value={addData.branch_id}
//             onChange={(e) =>
//               setAddData({ ...addData, branch_id: e.target.value })
//             }
//             required
//           />
//           <label className="form-label">
//             <b>Empid :</b>
//           </label>
//           <input
//             className="form-control"
//             type="text"
//             value={addData.empid}
//             onChange={(e) => setAddData({ ...addData, empid: e.target.value })}
//             required
//           />
//           <label className="form-label pt-2">
//             <b>Name :</b>
//           </label>
//           <input
//             className="form-control"
//             type="text"
//             value={addData.name}
//             onChange={(e) => setAddData({ ...addData, name: e.target.value })}
//             required
//           />
//           <label className="form-label pt-2">
//             <b>Fathername :</b>
//           </label>
//           <input
//             className="form-control"
//             type="text"
//             value={addData.fathername}
//             onChange={(e) =>
//               setAddData({ ...addData, fathername: e.target.value })
//             }
//             required
//           />
//           <label className="form-label pt-2">
//             <b>Mobile :</b>
//           </label>
//           <input
//             className="form-control"
//             type="text"
//             value={addData.mobile}
//             onChange={(e) => setAddData({ ...addData, mobile: e.target.value })}
//             required
//           />
//           <label className="form-label pt-2">
//             <b>Dateofbirth :</b>
//           </label>
//           <input
//             className="form-control"
//             type="date"
//             value={addData.dateofbirth}
//             onChange={(e) =>
//               setAddData({ ...addData, dateofbirth: e.target.value })
//             }
//             required
//           />

//           <label className="form-label pt-2">
//             <b>Salary :</b>
//           </label>
//           <input
//             className="form-control"
//             type="date"
//             value={addData.salary}
//             onChange={(e) => setAddData({ ...addData, salary: e.target.value })}
//             required
//           />

//           <label className="form-label pt-2">
//             <b>Interviewdate :</b>
//           </label>

//           <input
//             className="form-control"
//             type="date"
//             value={addData.interview_date}
//             onChange={(e) =>
//               setAddData({ ...addData, interview_date: e.target.value })
//             }
//             required
//           />
//           <label className="form-label pt-2">
//             <b>joindate :</b>
//           </label>
//           <input
//             className="form-control"
//             type="date"
//             value={addData.join_date}
//             onChange={(e) =>
//               setAddData({ ...addData, join_date: e.target.value })
//             }
//             required
//           />
//           <label className="form-label pt-2">
//             <b>Address :</b>
//           </label>
//           <input
//             className="form-control"
//             type="text"
//             value={addData.address}
//             onChange={(e) =>
//               setAddData({ ...addData, address: e.target.value })
//             }
//             required
//           />
//           <label className="form-label pt-2">
//             <b>Designation :</b>
//           </label>
//           <input
//             className="form-control"
//             type="text"
//             value={addData.designation}
//             onChange={(e) =>
//               setAddData({ ...addData, designation: e.target.value })
//             }
//             required
//           />
//           <label className="form-label pt-2">
//             <b>Qualification :</b>
//           </label>
//           <input
//             className="form-control"
//             type="text"
//             value={addData.qualification}
//             onChange={(e) =>
//               setAddData({ ...addData, qualification: e.target.value })
//             }
//             required
//           />
//           <label className="form-label pt-2">
//             <b>Notes :</b>
//           </label>
//           <input
//             className="form-control"
//             type="text"
//             value={addData.notes}
//             onChange={(e) => setAddData({ ...addData, notes: e.target.value })}
//             required
//           />
//           <label className="form-label pt-2">
//             <b>Timings :</b>
//           </label>
//           <input
//             className="form-control"
//             type="text"
//             value={addData.timings}
//             onChange={(e) =>
//               setAddData({ ...addData, timings: e.target.value })
//             }
//             required
//           />

//           <label className="form-label pt-2">
//             <b>Relive Date :</b>
//           </label>
//           <input
//             className="form-control"
//             type="text"
//             value={addData.relive_date}
//             onChange={(e) =>
//               setAddData({ ...addData, relive_date: e.target.value })
//             }
//             required
//           />
//           <label className="form-label pt-2">
//             <b>Status :</b>
//           </label>
//           <select
//             className="form-control"
//             value={addData.status}
//             onChange={(e) => setAddData({ ...addData, status: e.target.value })}
//           >
//             <option value="">Select status</option>
//             <option value="Active">Active</option>
//             <option value="Inactive">Inactive</option>
//             <option value="Relived">Relived</option>
//           </select>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setUpdateShowModal(false)}>
//             Cancel
//           </Button>
//           <Button variant="primary" onClick={handleupdatesubmit}>
//             Add User
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {/* ----------------delete */}

//       <Modal
//         className="modal"
//         show={deleteShowModals}
//         onHide={handleCloseModal}
//       >
//         <Modal.Header>
//           <p>Are you sure you want to Delete?</p>
//         </Modal.Header>
//         <Modal.Body>
//           <label className="form-label d-flex">
//             <h5 className="mx-1"> Employee Id :</h5> <h5>{getdeletename} </h5>
//           </label>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             className="cancelbut"
//             variant="secondary"
//             onClick={handleCloseModal}
//           >
//             Close
//           </Button>
//           <Button
//             className="submitbut"
//             variant="primary"
//             onClick={handleDelete}
//           >
//             Delete
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       {/* _------------------------------pagination button -------------------------------- */}

//       <div className="mt-3 pb-5">
//         <div className="row">
//           <div className="col-lg-7 col-sm-12 mt-1">
//             <span className="text-dark">
//               Page {currentPage} of {totalPages}
//             </span>
//           </div>
//           <div className="col-lg-5">
//             <nav>
//               <ul className="pagination justify-content-center">
//                 {createPagination()}
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>


//       {/* ---------------------------------------end ---------------------------------- */}
//     </div>
//   );
// }

// export default Staff;







// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Modal, Button } from "react-bootstrap";
// import apiUrl from "../../environment/Api";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
// import { FaAngleLeft, FaAngleRight } from "react-icons/fa";


// function Staff() {
//   const [responseData, setResponseData] = useState([]);
//   const [isUpdateshowModal, setUpdateShowModal] = useState(false);
//   const [deleteShowModals, setDeleteShowModals] = useState(false);
//   const [role_id, setroleid] = useState("");
//   const [previllages, setprevillages] = useState({});
//   const [loading, setLoading] = useState(false)
//   const navigate = useNavigate();




//   const handleUpdate = (_id, data) => {
//     if (previllages.edit || previllages.all) {
//       navigate(`/updatestaff/${_id}`);
//     } else {
//       toast.error("You don't have the permission to update");
//     }
//   };

//   const [addData, setAddData] = useState({
//     branch_id: "",
//     empid: "",
//     name: "",
//     fathername: "",
//     mobile: "",

//     dateofbirth: "",
//     interview_date: "",
//     join_date: "",
//     address: "",
//     salary: "",
//     designation: "",
//     qualification: "",
//     notes: "",
//     timings: "",
//     status: "",
//     relive_date: "",
//     documents: "",
//   });

//   const [updateData, setUpdateData] = useState({
//     branch_id: "",
//     empid: "",
//     name: "",
//     fathername: "",
//     mobile: "",
//     dateofbirth: "",
//     interview_date: "",
//     join_date: "",
//     address: "",
//     salary: "",
//     designation: "",
//     qualification: "",
//     notes: "",
//     timings: "",
//     status: "",
//     relive_date: "",
//     documents: "",
//   });




//   useEffect(() => {
//     fetchData();
//     gettoken();
//     window.scrollTo(0, 0);
//     getprevillages();
//   }, []);



//   // const fetchData = async () => {
//   //   try {
//   //     const response = await axios.get(apiUrl + "/staff_data", {
//   //       params: { data: localStorage.getItem("branch_id","username",)}
//   //     });

//   //     console.log("Response from server:", response.data);
//   //     setResponseData(response.data.reverse());
//   //   } catch (error) {
//   //     console.error("Error fetching data:", error.message);
//   //   }
//   // };



//   // username only based data from local storage

//   // const fetchData = async () => {
//   //   try {
//   //     const username = localStorage.getItem("username");  // Retrieve 'username' from localStorage
//   //     const branchId = localStorage.getItem("branch_id");  // Retrieve 'branch_id' from localStorage
//   //     const branchName = localStorage.getItem("branch_name");  // Retrieve 'branch_name' from localStorage
//   //     const empName = localStorage.getItem("emp_name");  // Retrieve 'emp_name' from localStorage

//   //     // Check if branch_id is null or branch_name is "Branches" and emp_name is undefined
//   //     if (branchId === null || branchName === "Branches" || empName === undefined) {
//   //       // Fetch all staff data if the condition is true
//   //       const response = await axios.get(apiUrl + "/staff");
//   //       console.log("Response from server:", response.data);

//   //       // Set the response data to show all staff
//   //       setResponseData(response.data);  // Display all staff data
//   //     } else {
//   //       // Fetch staff data based on the branch_id
//   //       const response = await axios.get(apiUrl + "/staff_data", {
//   //         params: { branch_id: branchId },
//   //       });
//   //       console.log("Response from server:", response.data);

//   //       // Filter the data where the username matches the value from localStorage
//   //       const staffData = response.data.reverse().filter(staff => staff.empid === username);

//   //       if (staffData.length > 0) {
//   //         console.log("Staff data matching username:", staffData);

//   //         // Get branch_name and empid
//   //         const empData = staffData;
//   //         const empId = empData.empid;
//   //         const branchName = empData.branch_name;

//   //         console.log("Employee ID:", empId);
//   //         console.log("Branch Name:", branchName);

//   //         setResponseData(empData);  // Set the specific staff data
//   //       } else {
//   //         console.log("No matching staff found for the given username");
//   //       }
//   //     }
//   //   } catch (error) {
//   //     console.error("Error fetching data:", error.message);
//   //   }
//   // };


//   // username and empid only based data from local storage

//   const fetchData = async () => {
//     try {
//       setLoading(true);
//       const username = localStorage.getItem("username");  // Retrieve 'username' from localStorage
//       const branchId = localStorage.getItem("branch_id");  // Retrieve 'branch_id' from localStorage
//       const branchName = localStorage.getItem("branch_name");  // Retrieve 'branch_name' from localStorage
//       const empName = localStorage.getItem("emp_name");  // Retrieve 'emp_name' from localStorage

//       // Check if branch_id is null or branch_name is "Branches" and emp_name is undefined
//       if (branchId === null || branchName === "Branches" || empName === undefined) {
//         // Fetch all staff data if the condition is true
//         const response = await axios.get(apiUrl + "/staff");
//         if (response && response.status === 200) {
//           setTimeout(() => {
//             setLoading(false);
//           }, 1000);
//         }
//         console.log("Response from server:", response.data);

//         // Set the response data to show all staff
//         setResponseData(response.data);  // Display all staff data
//       } else {
//         // Fetch staff data based on the branch_id
//         const response = await axios.get(apiUrl + "/staff_data", {
//           params: { branch_id: branchId },
//         });
//         if (response && response.status === 200) {
//           setTimeout(() => {
//             setLoading(false);
//           }, 500);
//         }
//         console.log("Response from server:", response.data);

//         // Filter the data where the username matches the value from localStorage
//         const staffData = response.data.reverse().filter(staff => staff.empid === username);

//         if (staffData.length > 0) {
//           console.log("Staff data matching username:", staffData);

//           // Get branch_name and empid
//           const empData = staffData;
//           const empId = empData.empid;
//           const branchName = empData.branch_name;

//           console.log("Employee ID:", empId);
//           console.log("Branch Name:", branchName);

//           setResponseData(empData);  // Set the specific staff data
//         } else {
//           console.log("No matching staff found for the given username");
//         }

//       }
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }

//   };





//   const gettoken = async () => {
//     const token = localStorage.getItem("token");
//     try {
//       const response = await axios.post(apiUrl + "/get_detail", {
//         token: token,
//       });
//       console.log("token detail from server:", response.data);

//       setroleid(response.data.detail.rolename);

//       localStorage.setItem("role_id", response.data.detail.rolename);

//       const id = response.data.detail.rolename;
//       if (id) {
//         // try {
//         //   const response = await axios.get(apiUrl + `/role_2/${id}`);

//         //   const modules = response.data.modules;

//         //   const filtered_access = modules.filter(
//         //     (item) => item.name == "Branch"
//         //   );

//         //   setprevillages(filtered_access[0].actions);
//         // } catch (error) {
//         //   console.error("Error fetching data:", error.message);
//         // }
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };



//   const getprevillages = () => {
//     const username = JSON.parse(localStorage.getItem("user_previllages"));
//     console.log("tyhe username get by local stroage : ", username.modules);

//     const total_previlages = username.modules;

//     const new_enquiry = total_previlages.find((item) => item.name == "Staff")

//     console.log("the new_enquiry is : ", new_enquiry.actions);

//     setprevillages(new_enquiry.actions)
//   }







//   const handlesubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const formData = {
//         branch_id: addData.branch_id,
//         empid: addData.empid,
//         name: addData.name,
//         fathername: addData.fathername,
//         mobile: addData.mobile,
//         dateofbirth: addData.dateofbirth,
//         interview_date: addData.interview_date,
//         join_date: addData.join_date,
//         address: addData.address,
//         salary: addData.salary,
//         designation: addData.designation,
//         qualification: addData.qualification,
//         notes: addData.notes,
//         timings: addData.timings,
//         status: addData.status,
//         relive_date: addData.relive_date,
//         documents: addData.documents,
//       };

//       const response = await axios.post(apiUrl + "/staff", formData);
//       console.log("Updated:", response);

//       fetchData();
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const [updateid, setUpdateId] = useState("");

//   const handleupdatesubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const formData = {
//         branch_id: updateData.branch_id,
//         empid: updateData.empid,
//         name: updateData.name,
//         fathername: updateData.fathername,
//         mobile: updateData.mobile,
//         dateofbirth: updateData.dateofbirth,
//         interview_date: updateData.interview_date,
//         join_date: updateData.join_date,
//         address: updateData.address,
//         salary: updateData.salary,
//         designation: updateData.designation,
//         qualification: updateData.qualification,
//         notes: updateData.notes,
//         timings: updateData.timings,
//         status: updateData.status,
//         relive_date: updateData.relive_date,
//         documents: updateData.documents,
//       };

//       console.log("the update submit data", formData);
//       const response = await axios.put(apiUrl + `/staff/${updateid}`, formData);
//       console.log("response for update", response);
//       setUpdateShowModal(false);
//       fetchData();
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       const response = await axios.delete(apiUrl + `/staff/${getdeleteid}`);
//       console.log("delete data ", response);
//       toast.error("Delete successfully!");
//       setDeleteShowModals(false);
//       fetchData();
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const handleCloseModal = () => {
//     setDeleteShowModals(false);
//   };

//   const [getdeleteid, setdeleteid] = useState("");
//   const [getdeletename, setdeletename] = useState("");
//   const handleOpenModal = (id, name) => {
//     if (previllages.all || previllages.delete) {
//       console.log(" the id", id);
//       console.log(" the name", name);
//       setdeleteid(id);
//       setdeletename(name);
//       setDeleteShowModals(true);
//     } else {
//       toast.error("You don't have the permission to delete");
//     }
//   };

//   // the pagination ---------------------------------------------------------------------
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [searchQuery, setSearchQuery] = useState("");
//   // Pagination
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);



//   const filtered = currentItems.filter((item) =>
//     Object.values(item).some(
//       (value) =>
//         (typeof value === "string" &&
//           value.toLowerCase().includes(searchQuery.toLowerCase())) ||
//         (typeof value === "number" && value.toString().includes(searchQuery))
//     )
//   );

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//     setCurrentPage(1);
//   };

//   const totalPages = Math.ceil(responseData.length / itemsPerPage);

//   const goToNextPage = () => {
//     setCurrentPage((prevPage) =>
//       prevPage === totalPages ? prevPage : prevPage + 1
//     );
//   };

//   const goToPrevPage = () => {
//     setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
//   };
//   // the pagination  end ---------------------------------------------------------------------

//   const createPagination = () => {
//     let liTag = [];
//     let beforePage = currentPage - 1;
//     let afterPage = currentPage + 1;
//     let active;

//     if (currentPage > 1) {
//       liTag.push(
//         <li className="page-item" key="prev">
//           <button
//             className="page-link"
//             onClick={() => paginate(currentPage - 1)}
//           >
//             <FaAngleLeft /> Prev
//           </button>
//         </li>
//       );
//     }

//     if (currentPage > 2) {
//       liTag.push(
//         <li className="page-item" key="first" onClick={() => paginate(1)}>
//           <button className="page-link">1</button>
//         </li>
//       );
//       if (currentPage > 3) {
//         liTag.push(
//           <li className="page-item disabled" key="dots1">
//             <span className="page-link">...</span>
//           </li>
//         );
//       }
//     }

//     if (currentPage === totalPages) {
//       beforePage -= 2;
//     } else if (currentPage === totalPages - 1) {
//       beforePage -= 1;
//     }

//     if (currentPage === 1) {
//       afterPage += 2;
//     } else if (currentPage === 2) {
//       afterPage += 1;
//     }

//     for (let plength = beforePage; plength <= afterPage; plength++) {
//       if (plength > totalPages || plength <= 0) continue;
//       active = currentPage === plength ? "active" : "";
//       liTag.push(
//         <li
//           className={`page-item ${active}`}
//           key={plength}
//           onClick={() => paginate(plength)}
//         >
//           <button className="page-link">{plength}</button>
//         </li>
//       );
//     }

//     if (currentPage < totalPages - 1) {
//       if (currentPage < totalPages - 2) {
//         liTag.push(
//           <li className="page-item disabled" key="dots2">
//             <span className="page-link">...</span>
//           </li>
//         );
//       }
//       liTag.push(
//         <li
//           className="page-item"
//           key="last"
//           onClick={() => paginate(totalPages)}
//         >
//           <button className="page-link">{totalPages}</button>
//         </li>
//       );
//     }

//     if (currentPage < totalPages) {
//       liTag.push(
//         <li className="page-item" key="next">
//           <button
//             className="page-link"
//             onClick={() => paginate(currentPage + 1)}
//           >
//             Next <FaAngleRight />
//           </button>
//         </li>
//       );
//     }

//     return liTag;
//   };






//   // =====================================================================
//   const Navigate = useNavigate();

//   const handleStaffInfo = (id) => {
//     Navigate(`/staffinfo/${id}`);
//   };






//   const [rolename, setrolename] = useState([]);
//   useEffect(() => {

//     const branchName = localStorage.getItem("branch_name");
//     setrolename(branchName);
//   }, []);






//   return (
//     <>

//       {loading === true ? (
//         <div className="loading-overlay">
//           <div className="loading-message">
//             <img
//               className="loadinggif"
//               src={require("../../assets/img/output-onlinegiftools.gif")}
//             />{" "}
//           </div>
//         </div>
//       ) : (
//         <div className="d-none"></div>
//       )}

//       <div className="top">
//         <div className="" style={{ marginTop: "-80px" }}>
//           <div className="row mb-3">
//             <div className="col-lg-11">
//               <Link to="/dashboard" className="text-secondary dashBoard_text">
//                 <b>Dashboard / </b>
//               </Link>
//               {/* <Link to="" className="text-secondary dashBoard_text">
//               <b>Staff / </b>
//             </Link> */}
//               <Link to="" className="text-danger dashBoard_text">
//                 <b>Staff</b>
//               </Link>
//             </div>
//           </div>
//         </div>
//         <div className=" row">
//           <div className="col-lg-4">
//             <div className="d-flex ">
//               <h4 className="mt-2">Staff</h4>
//               <div className="mx-3">
//                 {/* <button className="btn add" onClick={handleNewButtonClick}>
//                 Add
//               </button> */}
//                 {previllages.add && (

//                   <button
//                     className="btn btn-success add mx-3"
//                     onClick={() => {
//                       setLoading(true);
//                       setTimeout(() => {
//                         navigate("/addstaff");
//                         setLoading(false);
//                       }, 1000);
//                     }}
//                   >
//                     Add
//                   </button>

//                 )}
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-8 text-end">

//             {/* <div>
//             <input
//               type="file"
//               id="file-upload"
//               accept=".xls,.xlsx"
//               style={{ display: "none" }}
//               // onChange={handleFileUpload}
//             />
//             <label htmlFor="file-upload" className="btn btn-secondary">
//               File Upload
//             </label>
//           </div> */}
//           </div>
//         </div>

//         {/* ------------ search and per page ----------------------------*/}

//         <div className="mb-3 mt-3">
//           <div className="row">
//             <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//               <label className="form-label text-dark mt-2">Show : </label>
//               <div>
//                 <select
//                   className="form-control w-100 mx-2"
//                   onChange={(e) => {
//                     setItemsPerPage(e.target.value);
//                   }}
//                 >
//                   {/* <option value="5">5</option> */}
//                   <option value="10">10</option>
//                   <option value="20">20</option>
//                   <option value="50">50</option>
//                 </select>
//               </div>
//             </div>
//             <div className="col-lg-7 col-md-4 col-sm-1"></div>
//             <div className="col-lg-3 col-md-4 col-sm-7">
//               <div className="w-full flex-1">
//                 <form>
//                   <div className="relative">
//                     <input
//                       className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
//                       placeholder="Search ..."
//                       type="search"
//                       value={searchQuery}
//                       onChange={handleSearchChange}
//                     />
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* --------------------------------end------------------------------------------- */}
//         <div className="card">
//           <div className="mt-1">
//             <table className="table table-striped">
//               <thead>
//                 <tr>
//                   <th>S No.</th>
//                   {rolename === "Branches" && (<th>Branch</th>)}
//                   <th>Staff Id</th>
//                   <th>Staff Name</th>
//                   <th>Mobile No</th>
//                   {/* <th>Designation</th> */}
//                   <th>Status</th>
//                   <th className="text-center">Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filtered.map((item, index) => (
//                   <tr key={index}>
//                     <td>{index + 1}</td>
//                     {rolename === "Branches" && (<td>{item.branch_id[0]?.branch_name}</td>)}
//                     <td>{item.empid}</td>
//                     <td>{item.name}</td>
//                     <td>{item.mobile}</td>
//                     {/* <td>{item.designation[0]?.designation}</td> */}

//                     {item.status == "Active" ? (
//                       <td className="text-center hideres">
//                         <p className=" text-white green-label">{item.status}</p>
//                       </td>
//                     ) : (
//                       <td className="text-center hideres">
//                         <p className="text-white red-label">{item.status}</p>
//                       </td>
//                     )}
//                     <td className="text-center">
//                       {/* <button
//                       className="btn updelete mx-2"
//                       onClick={() => handleUpdate(item._id, item)}
//                     >
//                       <i class="fa-solid fa-pen-to-square"></i>
//                     </button>
//                     <button
//                       className="btn delete"
//                       onClick={() => handleOpenModal(item._id, item.empid)}
//                     >
//                       <i class="fa-solid fa-trash-can"></i>
//                     </button> */}
//                       {previllages.edit && (
//                         <button
//                           className="btn updelete mx-2"
//                           onClick={() => handleUpdate(item._id, item)}
//                         >
//                           <i className="fa-solid fa-pen-to-square"></i>
//                         </button>
//                       )}
//                       {previllages.delete && (
//                         <button
//                           className="btn delete"
//                           onClick={() => handleOpenModal(item._id, item.empid)}
//                         >
//                           <i className="fa-solid fa-trash-can"></i>
//                         </button>
//                       )}
//                       <button
//                         className="btn"
//                         // onClick={() => handleStaffInfo(item._id)}
//                         onClick={() => {
//                           setLoading(true);
//                           setTimeout(() => {
//                             handleStaffInfo(item._id)
//                             setLoading(false);
//                           }, 1000);
//                         }}
//                       >
//                         <i class="fa-solid fa-circle-info"></i>
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>

//         <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Update </Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             {/* Other input fields */}
//             <label className="form-label">
//               <b>Branch Id :</b>
//             </label>
//             <input
//               className="form-control"
//               type="text"
//               value={addData.branch_id}
//               onChange={(e) =>
//                 setAddData({ ...addData, branch_id: e.target.value })
//               }
//               required
//             />
//             <label className="form-label">
//               <b>Empid :</b>
//             </label>
//             <input
//               className="form-control"
//               type="text"
//               value={addData.empid}
//               onChange={(e) => setAddData({ ...addData, empid: e.target.value })}
//               required
//             />
//             <label className="form-label pt-2">
//               <b>Name :</b>
//             </label>
//             <input
//               className="form-control"
//               type="text"
//               value={addData.name}
//               onChange={(e) => setAddData({ ...addData, name: e.target.value })}
//               required
//             />
//             <label className="form-label pt-2">
//               <b>Fathername :</b>
//             </label>
//             <input
//               className="form-control"
//               type="text"
//               value={addData.fathername}
//               onChange={(e) =>
//                 setAddData({ ...addData, fathername: e.target.value })
//               }
//               required
//             />
//             <label className="form-label pt-2">
//               <b>Mobile :</b>
//             </label>
//             <input
//               className="form-control"
//               type="text"
//               value={addData.mobile}
//               onChange={(e) => setAddData({ ...addData, mobile: e.target.value })}
//               required
//             />
//             <label className="form-label pt-2">
//               <b>Dateofbirth :</b>
//             </label>
//             <input
//               className="form-control"
//               type="date"
//               value={addData.dateofbirth}
//               onChange={(e) =>
//                 setAddData({ ...addData, dateofbirth: e.target.value })
//               }
//               required
//             />

//             <label className="form-label pt-2">
//               <b>Salary :</b>
//             </label>
//             <input
//               className="form-control"
//               type="date"
//               value={addData.salary}
//               onChange={(e) => setAddData({ ...addData, salary: e.target.value })}
//               required
//             />

//             <label className="form-label pt-2">
//               <b>Interviewdate :</b>
//             </label>

//             <input
//               className="form-control"
//               type="date"
//               value={addData.interview_date}
//               onChange={(e) =>
//                 setAddData({ ...addData, interview_date: e.target.value })
//               }
//               required
//             />
//             <label className="form-label pt-2">
//               <b>joindate :</b>
//             </label>
//             <input
//               className="form-control"
//               type="date"
//               value={addData.join_date}
//               onChange={(e) =>
//                 setAddData({ ...addData, join_date: e.target.value })
//               }
//               required
//             />
//             <label className="form-label pt-2">
//               <b>Address :</b>
//             </label>
//             <input
//               className="form-control"
//               type="text"
//               value={addData.address}
//               onChange={(e) =>
//                 setAddData({ ...addData, address: e.target.value })
//               }
//               required
//             />
//             <label className="form-label pt-2">
//               <b>Designation :</b>
//             </label>
//             <input
//               className="form-control"
//               type="text"
//               value={addData.designation}
//               onChange={(e) =>
//                 setAddData({ ...addData, designation: e.target.value })
//               }
//               required
//             />
//             <label className="form-label pt-2">
//               <b>Qualification :</b>
//             </label>
//             <input
//               className="form-control"
//               type="text"
//               value={addData.qualification}
//               onChange={(e) =>
//                 setAddData({ ...addData, qualification: e.target.value })
//               }
//               required
//             />
//             <label className="form-label pt-2">
//               <b>Notes :</b>
//             </label>
//             <input
//               className="form-control"
//               type="text"
//               value={addData.notes}
//               onChange={(e) => setAddData({ ...addData, notes: e.target.value })}
//               required
//             />
//             <label className="form-label pt-2">
//               <b>Timings :</b>
//             </label>
//             <input
//               className="form-control"
//               type="text"
//               value={addData.timings}
//               onChange={(e) =>
//                 setAddData({ ...addData, timings: e.target.value })
//               }
//               required
//             />

//             <label className="form-label pt-2">
//               <b>Relive Date :</b>
//             </label>
//             <input
//               className="form-control"
//               type="text"
//               value={addData.relive_date}
//               onChange={(e) =>
//                 setAddData({ ...addData, relive_date: e.target.value })
//               }
//               required
//             />
//             <label className="form-label pt-2">
//               <b>Status :</b>
//             </label>
//             <select
//               className="form-control"
//               value={addData.status}
//               onChange={(e) => setAddData({ ...addData, status: e.target.value })}
//             >
//               <option value="">Select status</option>
//               <option value="Active">Active</option>
//               <option value="Inactive">Inactive</option>
//               <option value="Relived">Relived</option>
//             </select>
//           </Modal.Body>

//           <Modal.Footer>
//             <Button variant="secondary" onClick={() => setUpdateShowModal(false)}>
//               Cancel
//             </Button>
//             <Button variant="primary" onClick={handleupdatesubmit}>
//               Add User
//             </Button>
//           </Modal.Footer>
//         </Modal>

//         {/* ----------------delete */}

//         <Modal
//           className="modal"
//           show={deleteShowModals}
//           onHide={handleCloseModal}
//         >
//           <Modal.Header>
//             <p>Are you sure you want to Delete?</p>
//           </Modal.Header>
//           <Modal.Body>
//             <label className="form-label d-flex">
//               <h5 className="mx-1"> Employee Id :</h5> <h5>{getdeletename} </h5>
//             </label>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button
//               className="cancelbut"
//               variant="secondary"
//               onClick={handleCloseModal}
//             >
//               Close
//             </Button>
//             <Button
//               className="submitbut"
//               variant="primary"
//               onClick={handleDelete}
//             >
//               Delete
//             </Button>
//           </Modal.Footer>
//         </Modal>
//         {/* _------------------------------pagination button -------------------------------- */}

//         <div className="mt-3 pb-5">
//           <div className="row">
//             <div className="col-lg-7 col-sm-12 mt-1">
//               <span className="text-dark">
//                 Page {currentPage} of {totalPages}
//               </span>
//             </div>
//             <div className="col-lg-5">
//               <nav>
//                 <ul className="pagination justify-content-center">
//                   {createPagination()}
//                 </ul>
//               </nav>
//             </div>
//           </div>
//         </div>


//         {/* ---------------------------------------end ---------------------------------- */}
//       </div>
//     </>
//   );
// }

// export default Staff;


import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function Staff() {
  const [responseData, setResponseData] = useState([]);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [role_id, setroleid] = useState("");


  const [previllages, setprevillages] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

   const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/staff", {
        // params: { data: localStorage.getItem("branch_id","username",)}
      });

      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };



  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      branch_id: item.branch_id[0]?.branch_id || "", // Grabbing branch_id from the first item in the branch_id array
      branch_name: item.branch_id[0]?.branch_name || "",
      branch_address: item.branch_id[0]?.address || "",
      branch_email: item.branch_id[0]?.email_id || "",
      branch_phone: item.branch_id[0]?.phone_no || "",
      empid: item.empid,
      name: item.name,
      fathername: item.fathername,
      email: item.email,
      official_email: item.official_email,
      mobile: item.mobile,
      personal_Mobileno: item.personal_Mobileno,
      address: item.address,
      Permanent_Address: item.Permanent_Address,
      dateofbirth: item.dateofbirth,
      interview_date: item.interview_date,
      salary: item.salary,
      join_date: item.join_date,
      department: item.department,
      aadhar_number: item.aadhar_number,
      designation: item.designation[0]?.designation || "", // Grabbing designation from the first item in the designation array
      department_name: item.designation[0]?.department || "",
      qualification: item.qualification,
      gender: item.gender,
      marital_status: item.marital_status,
      notes: item.notes,
      timings: item.timings,
      status: item.status,
      documents: item.documents,
      photo_upload: item.photo_upload,
      deviceUserId: item.deviceUserId,
      relive_date: item.relive_date
    }));
  }, [responseData]);


  const [rolename, setrolename] = useState([]);
  useEffect(() => {
    const branchName = localStorage.getItem("branch_name");
    setrolename(branchName);
  }, []);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => row.index + 1,
      },
      // Check for rolename and conditionally add columns
      
        {
          Header: "Branch",
          accessor: "branch_name",
          Filter: DefaultColumnFilter,
          filter: "multiSelectFilter",
        },
      {
        Header: "Staff Id",
        accessor: "empid",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Email",
        accessor: "email",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        Cell: ({ value }) => (
          <div className="text-center hideres">
            <p className={`text-white ${value === "Active" ? "green-label" : "red-label"}`}>
              {value}
            </p>
          </div>
        ),
      },
    ];

    return baseColumns;
  }, [rolename]);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleUpdate = (_id, data) => {
    // if (previllages.edit || previllages.all) {
      navigate(`/updatestaff/${_id}`);
    // } else {
    //   toast.error("You don't have the permission to update");
    // }
  };

  const [addData, setAddData] = useState({
    branch_id: "",
    empid: "",
    name: "",
    fathername: "",
    mobile: "",

    dateofbirth: "",
    interview_date: "",
    join_date: "",
    address: "",
    salary: "",
    designation: "",
    qualification: "",
    notes: "",
    timings: "",
    status: "",
    relive_date: "",
    documents: "",
  });

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    empid: "",
    name: "",
    fathername: "",
    mobile: "",
    dateofbirth: "",
    interview_date: "",
    join_date: "",
    address: "",
    salary: "",
    designation: "",
    qualification: "",
    notes: "",
    timings: "",
    status: "",
    relive_date: "",
    documents: "",
  });

  useEffect(() => {
    fetchData();
    // gettoken();
    window.scrollTo(0, 0);
    // getprevillages();
  }, []);

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(apiUrl + "/staff_data", {
  //       params: { data: localStorage.getItem("branch_id","username",)}
  //     });

  //     console.log("Response from server:", response.data);
  //     setResponseData(response.data.reverse());
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };

  // username only based data from local storage

  // const fetchData = async () => {
  //   try {
  //     const username = localStorage.getItem("username");  // Retrieve 'username' from localStorage
  //     const branchId = localStorage.getItem("branch_id");  // Retrieve 'branch_id' from localStorage
  //     const branchName = localStorage.getItem("branch_name");  // Retrieve 'branch_name' from localStorage
  //     const empName = localStorage.getItem("emp_name");  // Retrieve 'emp_name' from localStorage

  //     // Check if branch_id is null or branch_name is "Branches" and emp_name is undefined
  //     if (branchId === null || branchName === "Branches" || empName === undefined) {
  //       // Fetch all staff data if the condition is true
  //       const response = await axios.get(apiUrl + "/staff");
  //       console.log("Response from server:", response.data);

  //       // Set the response data to show all staff
  //       setResponseData(response.data);  // Display all staff data
  //     } else {
  //       // Fetch staff data based on the branch_id
  //       const response = await axios.get(apiUrl + "/staff_data", {
  //         params: { branch_id: branchId },
  //       });
  //       console.log("Response from server:", response.data);

  //       // Filter the data where the username matches the value from localStorage
  //       const staffData = response.data.reverse().filter(staff => staff.empid === username);

  //       if (staffData.length > 0) {
  //         console.log("Staff data matching username:", staffData);

  //         // Get branch_name and empid
  //         const empData = staffData;
  //         const empId = empData.empid;
  //         const branchName = empData.branch_name;

  //         console.log("Employee ID:", empId);
  //         console.log("Branch Name:", branchName);

  //         setResponseData(empData);  // Set the specific staff data
  //       } else {
  //         console.log("No matching staff found for the given username");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };

  // username and empid only based data from local storage



  // const gettoken = async () => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await axios.post(ApiUrl + "/get_detail", {
  //       token: token,
  //     });
  //     console.log("token detail from server:", response.data);

  //     setroleid(response.data.detail.rolename);

  //     localStorage.setItem("role_id", response.data.detail.rolename);

  //     const id = response.data.detail.rolename;
  //     if (id) {
  //     console.error("Error fetching data:", error.message);
  //       // }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };

  // const getprevillages = () => {
  //   const username = JSON.parse(localStorage.getItem("user_previllages"));
  //   console.log("tyhe username get by local stroage : ", username.modules);

  //   const total_previlages = username.modules;

  //   const new_enquiry = total_previlages.find((item) => item.name == "");

  //   console.log("the new_enquiry is : ", new_enquiry.actions);

  //   setprevillages(new_enquiry.actions);
  // };

  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        branch_id: addData.branch_id,
        empid: addData.empid,
        name: addData.name,
        fathername: addData.fathername,
        mobile: addData.mobile,
        dateofbirth: addData.dateofbirth,
        interview_date: addData.interview_date,
        join_date: addData.join_date,
        address: addData.address,
        salary: addData.salary,
        designation: addData.designation,
        qualification: addData.qualification,
        notes: addData.notes,
        timings: addData.timings,
        status: addData.status,
        relive_date: addData.relive_date,
        documents: addData.documents,
      };

      const response = await axios.post(ApiUrl + "/staff", formData);
      console.log("Updated:", response);

      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        branch_id: updateData.branch_id,
        empid: updateData.empid,
        name: updateData.name,
        fathername: updateData.fathername,
        mobile: updateData.mobile,
        dateofbirth: updateData.dateofbirth,
        interview_date: updateData.interview_date,
        join_date: updateData.join_date,
        address: updateData.address,
        salary: updateData.salary,
        designation: updateData.designation,
        qualification: updateData.qualification,
        notes: updateData.notes,
        timings: updateData.timings,
        status: updateData.status,
        relive_date: updateData.relive_date,
        documents: updateData.documents,
      };

      console.log("the update submit data", formData);
      const response = await axios.put(ApiUrl + `/staff/${updateid}`, formData);
      console.log("response for update", response);
      setUpdateShowModal(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };



  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (id, name) => {

    console.log(" the id", id);
    console.log(" the name", name);
    setdeleteid(id);
    setdeletename(name);
    setDeleteShowModals(true);

  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(ApiUrl + `/staff/${getdeleteid}`);
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };
  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const filtered = rows.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  // const createPagination = () => {
  //   let liTag = [];

  //   // Previous Page Button
  //   liTag.push(
  //     <li
  //       className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
  //       key="prev"
  //     >
  //       <button
  //         className="page-link"
  //         onClick={() => canPreviousPage && gotoPage(state.pageIndex - 1)}
  //         disabled={!canPreviousPage}
  //       >
  //         <FaAngleLeft /> Prev
  //       </button>
  //     </li>
  //   );

  //   // Page Numbers
  //   for (let plength = 1; plength <= pageCount; plength++) {
  //     liTag.push(
  //       <li
  //         key={plength}
  //         className={`page-item ${plength === state.pageIndex + 1 ? "active" : ""
  //           }`}
  //       >
  //         <button
  //           className="page-link"
  //           onClick={() => gotoPage(plength - 1)} // 0-indexed
  //         >
  //           {plength}
  //         </button>
  //       </li>
  //     );
  //   }

  //   // Next Page Button
  //   liTag.push(
  //     <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
  //       <button
  //         className="page-link"
  //         onClick={() => canNextPage && gotoPage(state.pageIndex + 1)}
  //         disabled={!canNextPage}
  //       >
  //         Next <FaAngleRight />
  //       </button>
  //     </li>
  //   );

  //   return liTag;
  // };






  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`} key="prev">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li className={`page-item ${currentPage === 0 ? "active" : ""}`} key={0}>
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li className={`page-item ${currentPage === 1 ? "active" : ""}`} key={1}>
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 3, currentPage + 1); i++) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li className={`page-item ${currentPage === totalPages - 2 ? "active" : ""}`} key={totalPages - 2}>
          <button className="page-link" onClick={() => gotoPage(totalPages - 2)}>
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li className={`page-item ${currentPage === totalPages - 1 ? "active" : ""}`} key={totalPages - 1}>
          <button className="page-link" onClick={() => gotoPage(totalPages - 1)}>
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };
  // =====================================================================
  const Navigate = useNavigate();

  const handleStaffInfo = (id) => {
    Navigate(`/staffinfo/${id}`);
  };



  return (
    <>
      {/* {loading === true ? (
        <div className="loading-overlay">
          <div className="loading-message">
            <img
              className="loadinggif"
              src={require("../../assets/img/output-onlinegiftools.gif")}
            />{" "}
          </div>
        </div>
      ) : (
        <div className="d-none"></div>
      )} */}

      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              {/* <Link to="" className="text-secondary dashBoard_text">
              <b>Staff / </b>
            </Link> */}
              <Link to="" className="text-danger dashBoard_text">
                <b>Staff</b>
              </Link>
            </div>
          </div>
        </div>
        <div className=" row">
          <div className="col-lg-4">
            <div className="d-flex ">
              <h4 className="mt-2">Staff</h4>
              <div className="mx-3">
                {/* <button className="btn add" onClick={handleNewButtonClick}>
                Add
              </button> */}
                {/* {previllages.add && ( */}
                  <button
                    className="btn btn-dark btn-sm add mx-3"
                    onClick={() => {
                      setLoading(true);
                      setTimeout(() => {
                        navigate("/addstaff");
                        setLoading(false);
                      }, 1000);
                    }}
                  >
                    Add
                  </button>
                {/* )} */}
              </div>
            </div>
          </div>

          <div className="col-lg-8 text-end">
            {/* <div>
            <input
              type="file"
              id="file-upload"
              accept=".xls,.xlsx"
              style={{ display: "none" }}
              // onChange={handleFileUpload}
            />
            <label htmlFor="file-upload" className="btn btn-secondary">
              File Upload
            </label>
          </div> */}
          </div>
        </div>

        {/* ------------ search and per page ----------------------------*/}
        <div className="mb-1 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  value={state.pageSize}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === 'All') {
                      setPageSize(data.length);  // 'data' should be your dataset containing all the entries
                    } else {
                      setPageSize(Number(value));
                    }
                  }}
                >
                  <option value="All">All</option> {/* Add 'All' option */}
                  {[10, 20, 100, 200, 500, 1000].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      value={state.globalFilter || ""} // Use the globalFilter value
                      onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>





        <TableContainer>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="d-flex">
                        <div className="mx-2">
                          {column.render("Header")}</div>
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                        <div className="mx-1">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </div>
                      </div>
                    </Th>
                  ))}
                  <Th className="text-center">Action</Th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    ))}
                    <Td className="text-center">
                      {/* {previllages.edit && ( */}
                        <button
                          className="btn updelete mx-2"


                          onClick={() => {
                            setLoading(true);
                            setTimeout(() => {
                              handleUpdate(row.original._id, row.original)
                              setLoading(false);
                            }, 500);
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                      {/* )} */}

                      {/* {previllages.delete && ( */}
                        <button
                          className="btn delete"
                          onClick={() =>
                            handleOpenModal(
                              row.original._id,
                              row.original.empid
                            )
                          }
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      {/* )} */}



                      <button
                        className="btn"
                        // onClick={() => handleStaffInfo(item._id)}
                        onClick={() => {
                          setLoading(true);
                          setTimeout(() => {
                            handleStaffInfo(row.original._id)
                            setLoading(false);
                          }, 1000);
                        }}
                      >
                        <i class="fa-solid fa-circle-info"></i>
                      </button>



                    </Td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>


        <Modal
          show={isUpdateshowModal}
          onHide={() => setUpdateShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Other input fields */}
            <label className="form-label">
              <b>Branch Id :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={addData.branch_id}
              onChange={(e) =>
                setAddData({ ...addData, branch_id: e.target.value })
              }
              required
            />
            <label className="form-label">
              <b>Empid :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={addData.empid}
              onChange={(e) =>
                setAddData({ ...addData, empid: e.target.value })
              }
              required
            />
            <label className="form-label pt-2">
              <b>Name :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={addData.name}
              onChange={(e) => setAddData({ ...addData, name: e.target.value })}
              required
            />
            <label className="form-label pt-2">
              <b>Fathername :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={addData.fathername}
              onChange={(e) =>
                setAddData({ ...addData, fathername: e.target.value })
              }
              required
            />
            <label className="form-label pt-2">
              <b>Mobile :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={addData.mobile}
              onChange={(e) =>
                setAddData({ ...addData, mobile: e.target.value })
              }
              required
            />
            <label className="form-label pt-2">
              <b>Dateofbirth :</b>
            </label>
            <input
              className="form-control"
              type="date"
              value={addData.dateofbirth}
              onChange={(e) =>
                setAddData({ ...addData, dateofbirth: e.target.value })
              }
              required
            />

            <label className="form-label pt-2">
              <b>Salary :</b>
            </label>
            <input
              className="form-control"
              type="date"
              value={addData.salary}
              onChange={(e) =>
                setAddData({ ...addData, salary: e.target.value })
              }
              required
            />

            <label className="form-label pt-2">
              <b>Interviewdate :</b>
            </label>

            <input
              className="form-control"
              type="date"
              value={addData.interview_date}
              onChange={(e) =>
                setAddData({ ...addData, interview_date: e.target.value })
              }
              required
            />
            <label className="form-label pt-2">
              <b>joindate :</b>
            </label>
            <input
              className="form-control"
              type="date"
              value={addData.join_date}
              onChange={(e) =>
                setAddData({ ...addData, join_date: e.target.value })
              }
              required
            />
            <label className="form-label pt-2">
              <b>Address :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={addData.address}
              onChange={(e) =>
                setAddData({ ...addData, address: e.target.value })
              }
              required
            />
            <label className="form-label pt-2">
              <b>Designation :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={addData.designation}
              onChange={(e) =>
                setAddData({ ...addData, designation: e.target.value })
              }
              required
            />
            <label className="form-label pt-2">
              <b>Qualification :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={addData.qualification}
              onChange={(e) =>
                setAddData({ ...addData, qualification: e.target.value })
              }
              required
            />
            <label className="form-label pt-2">
              <b>Notes :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={addData.notes}
              onChange={(e) =>
                setAddData({ ...addData, notes: e.target.value })
              }
              required
            />
            <label className="form-label pt-2">
              <b>Timings :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={addData.timings}
              onChange={(e) =>
                setAddData({ ...addData, timings: e.target.value })
              }
              required
            />

            <label className="form-label pt-2">
              <b>Relive Date :</b>
            </label>
            <input
              className="form-control"
              type="text"
              value={addData.relive_date}
              onChange={(e) =>
                setAddData({ ...addData, relive_date: e.target.value })
              }
              required
            />
            <label className="form-label pt-2">
              <b>Status :</b>
            </label>
            <select
              className="form-control"
              value={addData.status}
              onChange={(e) =>
                setAddData({ ...addData, status: e.target.value })
              }
            >
              <option value="">Select status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
              <option value="Relived">Relived</option>
            </select>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setUpdateShowModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleupdatesubmit}>
              Add User
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ----------------delete */}

        <Modal
          className="modal"
          show={deleteShowModals}
          onHide={handleCloseModal}
        >
          <Modal.Header>
            <p>Are you sure you want to Delete?</p>
          </Modal.Header>
          <Modal.Body>
            <label className="form-label d-flex">
              <h5 className="mx-1"> Staff Id :</h5> <h5>{getdeletename} </h5>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* _------------------------------pagination button -------------------------------- */}

        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-7 col-sm-12 mt-1">
              <span className="text-dark">
                Page {state.pageIndex + 1} of {pageCount}
              </span>
            </div>
            <div className="col-lg-5">
              <div className="mt-3 pb-5">
                <nav>
                  <ul className="pagination justify-content-center">
                    {createPagination()} {/* Pagination buttons */}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* ---------------------------------------end ---------------------------------- */}
      </div>
    </>
  );
}

export default Staff;
