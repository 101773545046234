// function EnquiryReport() {
//   return (
//     <>
//       <div className="container-fluid">
//         <div
//           className="card p-2"
//           style={{
//             marginTop: "-80px",
//             boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
//           }}
//         >
//           <div className="row">
//             <h6 className="text-center">
//               <b>Enquiry Report</b>
//             </h6>
//           </div>
//           <hr />
//           <div className="row p-2">
//             <div className="col-lg-2 col-md-3 col-sm-12 mx-3">
//               <input type="date" className="form-control" />
//             </div>
//             <div className="col-lg-2 col-md-3 col-sm-12">
//               <input type="date" className="form-control" />
//             </div>
//             <div className="col-lg-2 col-md-3 col-sm-12">
//               <select className="form-control">
//                 <option value="">Company</option>
//               </select>
//             </div>
//             <div className="col-lg-2 col-md-3 col-sm-12">
//               <select className="form-control">
//                 <option value="">Select Status</option>
//               </select>
//             </div>

//             <div className="col-lg-1 col-md-2 col-sm-12">
//               <button className="btn btn-danger">Clear</button>
//             </div>
//           </div>
//           <hr />
//           <div className="row">
//             <div className="col-md-3 d-flex align-items-center">
//               <p className="mb-0 mr-2">Show: </p>
//               <select className="form-control mx-2" style={{ width: "50px" }}>
//                 {/* <option value={5}>5</option> */}
//                 <option value={10}>10</option>
//                 <option value={15}>20</option>
//                 <option value="">All</option>
//               </select>
//             </div>

//             <div className="col-md-6">
//               <button className="btn btn-secondary mx-2">Copy</button>
//               <button className="btn btn-success mx-2">CSV</button>
//               <button className="btn btn-danger mx-2">Excel</button>
//               <button className="btn btn-info mx-2">PDF</button>
//               <button className="btn btn-warning mx-2">Print</button>
//             </div>

//             <div className="col-md-3 d-flex align-items-center">
//               <input
//                 type="text"
//                 placeholder="Search"
//                 className="form-control"
//               />
//             </div>
//           </div>
//           <table
//             id="tableId"
//             className="table table-striped table-bordered mt-2"
//           >
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>Name</th>
//                 <th>Mobile No</th>
//                 <th>Company</th>
//                 <th>Source Type</th>
//                 <th>Enquiry Date</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>1</td>
//                 <td>VenuAravind</td>
//                 <td>9988776655</td>
//                 <td>HTML</td>
//                 <td>Google Ad</td>
//                 <td>November 30, 2024</td>
//                 <td>Qualified</td>
//               </tr>
//             </tbody>
//           </table>

//           <div className="row">
//             <div className="col-lg-4">
//               <div className="dataTables_info" role="status" aria-live="polite">
//                 Showing 1 to 1 entries
//               </div>
//             </div>
//             <div className="col-lg-5"></div>
//             <div className="col-lg-3">
//               <nav aria-label="Page navigation example">
//                 <ul className="pagination justify-content-center">
//                   <li className="">
//                     <a className="page-link">Previous</a>
//                   </li>

//                   <li className="">
//                     <a className="page-link">1</a>
//                   </li>

//                   <li className="">
//                     <a className="page-link">Next</a>
//                   </li>
//                 </ul>
//               </nav>
//             </div>

//             {/* <div className="col-lg-5"></div> */}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default EnquiryReport;


import axios from "axios";
import ApiUrl from "../Environment/ApiUrl";
import { useEffect, useState, useRef } from "react";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import "jspdf-autotable";
import * as XLSX from "xlsx";


function EnquiryReport() {
  const [responseData, setResponseData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showCount, setShowCount] = useState(10);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);



  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/leads");
      const reversedData = response.data.reverse();
      setResponseData(reversedData);
      setFilteredData(reversedData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  const handleCopy = async () => {
    try {
      let textToCopy = responseData.map(
        (row, index) =>
          `${index + 1}\t${row.name}\t${row.official_mobile}\t${row.company_name}\t${row.source_type}\t${row.enquiry_date}\t${row.followups_status}`
      ).join("\n");
  
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(textToCopy);
        alert("Copied to clipboard");
      } else {
        // Fallback for HTTP or unsupported browsers
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        alert("Copied to clipboard");
      }
    } catch (error) {
      console.error("Failed to copy: ", error);
      alert("Failed to copy text");
    }
  };
  
  

  const handleExcel = () => {
    const tableData = responseData.map((row, index) => ({
      "S.No": index + 1,
      "Name": row.name,
      "Mobile No": row.official_mobile,
      "Company": row.company_name,
      "Source Type": row.source_type,
      "Enquiry Date": row.enquiry_date,
      "Status": row.followups_status,
    }));

    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Enquiries");
    XLSX.writeFile(wb, "Enquiry_Report.xlsx");
  };


  const handlePDF = () => {
    const doc = new jsPDF();
    autoTable(doc, {
      head: [["S.No", "Name", "Mobile No", "Company", "Source Type", "Enquiry Date", "Status"]],
      body: responseData.map((row, index) => [
        index + 1,
        row.name,
        row.official_mobile,
        row.company_name,
        row.source_type,
        row.enquiry_date,
        row.followups_status,
      ]),
    });
    doc.save("Enquiry_Report.pdf");
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`; // Format for input type="date"
  };

  const uniqueCompanies = [...new Set(responseData.map((item) => item.company_name))];
  const uniqueStatuses = [...new Set(responseData.map((item) => item.followups_status))];


  useEffect(() => {
    let filtered = responseData;

    // **Filter by Date Range**
    if (fromDate && toDate) {
      filtered = filtered.filter((item) => {
        const enquiryDate = item.enquiry_date ? new Date(item.enquiry_date) : null;
        return enquiryDate && enquiryDate >= new Date(fromDate) && enquiryDate <= new Date(toDate);
      });
    }

    // **Filter by Company Name**
    if (selectedCompany) {
      filtered = filtered.filter((item) =>
        (item.company_name || "").toLowerCase() === selectedCompany.toLowerCase()
      );
    }

    // **Filter by Status**
    if (selectedStatus) {
      filtered = filtered.filter((item) =>
        (item.followups_status || "").toLowerCase() === selectedStatus.toLowerCase()
      );
    }

    // **Search Filter //
    if (searchQuery) {
      const lowerSearch = searchQuery.toLowerCase();
      filtered = filtered.filter((item) =>
        (item.name || "").toLowerCase().includes(lowerSearch) ||
        (item.official_mobile || "").includes(searchQuery) ||
        (item.company_name || "").toLowerCase().includes(lowerSearch) ||
        (item.source_type || "").toLowerCase().includes(lowerSearch) ||
        (item.followups_status || "").toLowerCase().includes(lowerSearch) ||
        (item.enquiry_date || "").includes(searchQuery)
      );
    }

    setFilteredData(filtered);
  }, [fromDate, toDate, selectedCompany, selectedStatus, searchQuery, responseData]);


  // Clear Filters
  const handleClearFilters = () => {
    setFromDate("");
    setToDate("");
    setSelectedCompany("");
    setSelectedStatus("");
    setSearchQuery("");
    setFilteredData(responseData);
  };
  const handleEntriesPerPageChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };


  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const paginatedData = filteredData.slice((currentPage - 1) * showCount, currentPage * showCount);

  return (
    <>
      <div className="">
        <div
          className="card p-2"
          style={{
            marginTop: "-80px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <div className="row">
            <h6 className="text-center">
              <b>Enquiry Report</b>
            </h6>
          </div>
          <hr />

          <div className="row p-2">
            <div className="col-lg-2 col-md-3 col-sm-12 mx-3">
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>

            <div className="col-lg-2 col-md-3 col-sm-12">
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            {/* Company Name Filter */}
            <div className="col-lg-2 col-md-3 col-sm-12">
              <select
                className="form-control"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              >
                <option value="">All Companies</option>
                {uniqueCompanies.map((company, index) => (
                  <option key={index} value={company}>{company}</option>
                ))}
              </select>
            </div>

            {/* Status Filter */}
            <div className="col-lg-2 col-md-3 col-sm-12">
              <select
                className="form-control"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">All Status</option>
                {uniqueStatuses.map((status, index) => (
                  <option key={index} value={status}>{status}</option>
                ))}
              </select>
            </div>

            <div className="col-lg-1 col-md-2 col-sm-12">
              <button className="btn btn-danger" onClick={handleClearFilters}>Clear</button>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-3 d-flex align-items-center">
              <p className="mb-0 mr-2">Show: </p>
              <select className="form-control mx-2"
                value={entriesPerPage}
                onChange={handleEntriesPerPageChange}
                style={{ width: "50px" }}>
                {/* <option value={5}>5</option> */}
                <option value={10}>10</option>
                <option value={15}>20</option>
                <option value={paginatedData.length}>All</option>
              </select>
            </div>

            <div className="col-md-6">
              <button className="btn btn-secondary mx-2" onClick={handleCopy}>Copy</button>
              <CSVLink className="btn btn-success mx-2" data={responseData} filename="Enquiry_Report.csv">CSV</CSVLink>
              <button className="btn btn-danger mx-2" onClick={handleExcel}>Excel</button>
              <button className="btn btn-info mx-2" onClick={handlePDF}>PDF</button>
              <button className="btn btn-warning mx-2" onClick={() => window.print()}>Print</button>
            </div>

            <div className="col-md-3 d-flex align-items-center">
              <input type="text" placeholder="Search" className="form-control" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
            </div>
          </div>
          <table
            id="tableId"
            className="table table-striped table-bordered mt-2"
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Mobile No</th>
                <th>Company</th>
                <th>Source Type</th>
                <th>Enquiry Date</th>
                <th>Status</th>
              </tr>
            </thead>
            {filteredData.map((item, index) => (
              <tr key={index} >
                <td className="fs-5">{index + 1}</td>
                <td className="fs-5">{item.name}</td>
                <td className="fs-5">{item.official_mobile}</td>
                <td className="fs-5">{item.company_name}</td>
                <td className="fs-5">{item.source_type}</td>
                <td className="fs-5">{formatDate(item.enquiry_date)}</td>
                <td>
                  <span className={`badge ${item.followups_status === "Qualified" ? "badge-success" : "badge-danger"}`}>
                    {item.followups_status}
                  </span>
                </td>

              </tr>
            ))}
          </table>

          <div className="row">
            <div className="col-lg-4">
              <div className="dataTables_info" role="status" aria-live="polite">
                Showing {filteredData.length === 0 ? 0 : (currentPage - 1) * entriesPerPage + 1}
                to {Math.min(currentPage * entriesPerPage, filteredData.length)}
                of {filteredData.length} entries

              </div>
            </div>
            <div className="col-lg-5"></div>
            <div className="col-lg-3">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className="">
                    <a className="page-link">Previous</a>
                  </li>

                  <li className="">
                    <a className="page-link">1</a>
                  </li>

                  <li className="">
                    <a className="page-link">Next</a>
                  </li>
                </ul>
              </nav>
            </div>

            {/* <div className="col-lg-5"></div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default EnquiryReport;
