import ApiUrl from "../Environment/ApiUrl";
import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import dayjs from "dayjs";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function DailyTask() {
  const [responseData, setResponseData] = useState([]);
  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      staff_id: item.staff_id,
      date: item.date,
      time: item.time,
      type: item.type,
      Description: item.Description,
      notes: item.notes,
      status: item.status,
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Staff ID",
        accessor: "staff_id",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Description",
        accessor: "Description",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },

      {
        Header: "Date",
        accessor: (row) => dayjs(row.date).format("D-M-YYYY"), // Ensures only date, no timezone
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },

      {
        Header: "Notes",
        accessor: "notes",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [staffId, Setstaffid] = useState([]);

  const [addData, setAddData] = useState({
    staff_id: "",
    date: "",
    time: "",
    Description: "",
    notes: "",
    status: "Schedule",
    type: "",
    projectItems: [],
    serviceItems: [],
  });

  useEffect(() => {
    const currentTime = new Date().toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    setAddData((prev) => ({ ...prev, time: currentTime }));
  }, []);

  const [updateData, setUpdateData] = useState({
    staff_id: "",
    date: "",
    time: "",
    Description: "",
    notes: "",
    status: "",
    type: "",
    projectItems: [],
    serviceItems: [],
  });

  useEffect(() => {
    fetchData();
    fetchDatastaff();
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/dailytask");
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDatastaff = async () => {
    try {
      const response = await axios.get(ApiUrl + "/staff");
      console.log("Response from server staff:", response.data);
      Setstaffid(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        staff_id: addData.staff_id,
        date: new Date().toISOString().split("T")[0],
        time: addData.time,
        Description: addData.Description,
        notes: addData.notes,
        status: addData.status,
        type: addData.type,
        // projectItems: [],
        // serviceItems: [],
      };
      console.log("sumbit data", formData);

      const response = await axios.post(ApiUrl + "/dailytask", formData);
      console.log("Updated:", response.data);
      console.log("Updated:", response.data.date);
      setShowModal(false);
      toast.success("added successfully!");
      setAddData({
        staff_id: "",
        date: "",
        time: "",
        Description: "",
        notes: "",
        status: "Schedule",
        type: "",
        // projectItems: [],
        // serviceItems: [],
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    setUpdateId(_id);
    console.log("the data to update", data);
    setUpdateShowModal(true);
    if (!updateData.date || isNaN(new Date(updateData.date))) {
      console.error("Invalid date:", updateData.date);
      return; // Stop execution if date is invalid
    }

    const formattedDate = new Date(updateData.date).toISOString();
    console.log("Formatted Date:", formattedDate);

    setUpdateData({
      staff_id: data.staff_id,
      date: formattedDate,
      Description: data.Description,
      notes: data.notes,
      status: data.status,
      type: data.type,
      projectItems: data.projectItems,
      serviceItems: data.serviceItems,
    });
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        staff_id: updateData.staff_id,
        date: updateData.date,
        Description: updateData.Description,
        notes: updateData.notes,
        status: updateData.status,
        type: updateData.type,
        projectItems: updateData.projectItems,
        serviceItems: updateData.serviceItems,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        ApiUrl + `/dailytask/${updateid}`,
        formData
      );
      console.log("response for update", response.data.date);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(ApiUrl + `/dailytask/${getdeleteid}`);
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");

  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, name) => {
    console.log(" the id", _id);
    console.log(" the name", name);
    setdeleteid(_id);
    setdeletename(name);
    setDeleteShowModals(true);
  };

  useEffect(() => {
    // Get the current date
    const currentDate = new Date().toISOString().split("T")[0]; // Format to YYYY-MM-DD
    setAddData((prevData) => ({ ...prevData, date: currentDate }));
  }, []);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0]; // Format to YYYY-MM-DD
    setUpdateData((prevData) => ({
      ...prevData,
      date: prevData.date || currentDate, // Only set if date is not already defined
    }));
  }, []);

  const [responseProject, setResponseProject] = useState([]);
  const [responseService, setResponseService] = useState([]);

  useEffect(() => {
    fetchProjects();
    fetchServices();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(ApiUrl + "/expected");
      setResponseProject(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error.message);
    }
  };

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date); // Convert input to Date if it's not already
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get(ApiUrl + "/services");
      setResponseService(response.data);
    } catch (error) {
      console.error("Error fetching services:", error.message);
    }
  };

  // the pagination -------------------------------------------------------------------

  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  const handleDateChange = (e) => {
    setUpdateData((prevData) => ({
      ...prevData,
      date: e.target.value, // Input type=date handles the format
    }));
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/assetlist" className="text-secondary dashBoard_text">
              <b>Staff / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>DailyTask</b>
            </Link>
          </div>
        </div>
      </div>
      {/* Table */}
      <div className="d-flex">
        <h6 className="mt-2 bold">Daily Task</h6>
        <div className="mx-3">
          <button
            className="btn btn-dark btn-sm  add mx-3"
            onClick={() => setShowModal(true)}
          >
            Add
          </button>
        </div>
      </div>

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    <button
                      className="btn custom-btn-update btn-sm"
                      onClick={() =>
                        handleUpdate(row.original._id, row.original)
                      }
                    >
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button
                      className="btn custom-btn-delete btn-sm mx-1"
                      onClick={() =>
                        handleOpenModal(row.original._id, row.original.name)
                      }
                    >
                      {" "}
                      <i class="fa-solid fa-trash-can"></i>
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      {/* Modal for adding a new user */}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Daily Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="floating-label">
              <select
                className="form-control"
                value={addData.staff_id}
                onChange={(e) => {
                  console.log(e.target.value);
                  setAddData((prev) => ({ ...prev, staff_id: e.target.value }));
                }}
                required
              >
                <option value="">Select Staff</option>
                {staffId.map((item, index) => (
                  <option key={index} value={item.empid}>
                    {item.empid} - {item.name}
                  </option>
                ))}
              </select>
              <label className={addData.staff_id ? "filled" : ""}></label>
            </div>

            <div className="floating-label">
              <select
                className="form-control"
                value={addData.type}
                onChange={(e) =>
                  setAddData({ type: e.target.value, selectedItem: "" })
                }
                required
              >
                <option value="">Select Type</option>
                <option value="Project">Project</option>
                <option value="Service">Service</option>
              </select>
              <label className={addData.type ? "filled" : ""}></label>
            </div>

            {addData.type && (
              <div className="mt-3 mb-4">
                <select
                  className="form-select"
                  value={addData.selectedItem}
                  onChange={(e) =>
                    setAddData({ ...addData, selectedItem: e.target.value })
                  }
                  required
                >
                  <option value="">Select {addData.type}</option>
                  {(addData.type === "Project"
                    ? responseProject
                    : responseService
                  ).map((item) => (
                    <option key={item._id} value={item._id}>
                      {addData.type === "Project"
                        ? `${item.customer_orders[0]?.quotation_no} - ${item.company_name}`
                        : item.service_name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="floating-label">
              <input
                className="form-control"
                type="date"
                placeholder=""
                value={addData.date}
                onChange={handleDateChange}
                required
              />
              <label className={addData.date ? "filled" : ""}>Date</label>
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                placeholder=""
                type="time"
                value={addData.time}
                onChange={(e) =>
                  setAddData({ ...addData, time: e.target.value })
                }
                disabled
              />
              <label className={addData.time ? "filled" : ""}>Time</label>
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                placeholder=""
                type="text"
                value={addData.Description}
                onChange={(e) =>
                  setAddData({ ...addData, Description: e.target.value })
                }
                required
              />
              <label className={addData.Description ? "filled" : ""}>
                Description
              </label>
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                placeholder=""
                type="text"
                value={addData.notes}
                onChange={(e) =>
                  setAddData({ ...addData, notes: e.target.value })
                }
                required
              />
              <label className={addData.notes ? "filled" : ""}>Notes</label>
            </div>

            <div className="floating-label">
              <select
                className="form-control"
                value={addData.status}
                onChange={(e) =>
                  setAddData({ ...addData, status: e.target.value })
                }
              >
                <option value="">Select Status</option>
                <option value="Schedule">Schedule</option>
                <option value="Inprogress">Inprogress</option>
                <option value="completed">completed</option>
                <option value="Incompleted">Incompleted</option>
              </select>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handlesubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update DailyTask</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Other input fields */}

          <div className="form-group">
            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={updateData.staff_id}
                onChange={(e) =>
                  setUpdateData({ ...updateData, staff_id: e.target.value })
                }
                required
              />
              <label className={updateData.staff_id ? "filled" : ""}>
                Staff Id
              </label>
            </div>
            <div className="floating-label">
              <input
                id="staff-id"
                className="form-control"
                type="text"
                value={updateData.staff_id}
                onChange={(e) =>
                  setUpdateData({ ...updateData, staff_id: e.target.value })
                }
                required
              />
              <label
                htmlFor="staff-id"
                className={updateData.staff_id ? "filled" : ""}
              >
                Staff Id
              </label>
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                type="date"
                placeholder=""
                value={formatDate(updateData.date)}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    date: e.target.value,
                  })
                }
                required
              />
              <label className={updateData.date ? "filled" : ""}>Date</label>
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={updateData.Description}
                onChange={(e) =>
                  setUpdateData({ ...updateData, Description: e.target.value })
                }
                required
              />
              <label className={updateData.Description ? "filled" : ""}>
                Description
              </label>
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={updateData.notes}
                onChange={(e) =>
                  setUpdateData({ ...updateData, notes: e.target.value })
                }
                required
              />
              <label className={updateData.notes ? "filled" : ""}>Notes</label>
            </div>

            <div className="floating-label">
              <select
                className="form-control"
                value={updateData.status}
                onChange={(e) =>
                  setUpdateData({ ...updateData, status: e.target.value })
                }
              >
                <option value="">Select Status</option>
                <option value="Inprogress">Inprogress</option>
                <option value="completed">completed</option>
                <option value="Incompleted">Incompleted</option>
              </select>
              <label className="">Select Status</label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Asset Type Name :</h5>{" "}
            <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* _------------------------------pagination button -------------------------------- */}

      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------end ---------------------------------- */}
    </>
  );
}

export default DailyTask;
