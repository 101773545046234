import { Chart } from "react-google-charts";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Dashboard.css";
import { useEffect, useState } from "react";
import axios from "axios";
import ApiUrl from "../Environment/ApiUrl";

function Dashboard() {
  const navigate = useNavigate();

  const [responseDatas, setResponseDatas] = useState([]);

  // Hardcoded data for other charts
  const double_bar_data = [
    ["Month", "Revenue", "Expense"],
    ["Jan", 1000, 1200],
    ["Feb", 1170, 1300],
    ["Mar", 660, 900],
    ["Apr", 1030, 1150],
    ["May", 1200, 1400],
    ["Jun", 950, 1100],
    ["Jul", 1300, 1500],
  ];

  // const double_bar_options = {
  //   title: "Revenue and Expense",
  //   legend: { position: "bottom" },
  //   colors: ["#f1e101", "#cfab07"],
  // };

  // Fetch branches data
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axios.get(ApiUrl + "/branch");
        setResponseDatas(response.data);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };
    fetchBranches();
  }, []);

  // Fetch leads years and initial data
  useEffect(() => {
    const fetchLeadsYearsAndData = async () => {
      try {
        const branch_id = localStorage.getItem("branch_id");
        const query =
          branch_id && branch_id !== "null" ? `?branch_id=${branch_id}` : "";

        const response = await axios.get(ApiUrl + `/leads_dashboard_2${query}`);

        // Extract unique years
        const uniqueYears = [
          ...new Set(response.data.map((item) => item.year)),
        ];
        setLeadsyears(uniqueYears);

        // If years exist, fetch data for the first/current year
        if (uniqueYears.length > 0) {
          const currentYear = selectedleadsYear;
          const filteredData = response.data.filter(
            (item) => item.year === currentYear
          );

          const formattedData = [
            ["Category", "Leads", "Conversions"],
            ...filteredData.map((monthData) => [
              monthData.month,
              monthData.totalLeads || 0,
              monthData.totalConversions || 0,
            ]),
          ];

          setChartData(formattedData);
        }
      } catch (error) {
        console.error("Error fetching leads data:", error);
      }
    };

    fetchLeadsYearsAndData();
  }, []);

  // Fetch leads data when year is changed
  const filter_fetchLeadsData = async (e) => {
    try {
      const selectedYear = parseInt(e.target.value);
      setSelectedleadsYear(selectedYear);

      const branch_id = localStorage.getItem("branch_id");
      const query =
        branch_id && branch_id !== "null" ? `?branch_id=${branch_id}` : "";

      const response = await axios.get(ApiUrl + `/leads_dashboard_2${query}`);

      const filteredData = response.data.filter(
        (item) => item.year === selectedYear
      );

      const formattedData = [
        ["Category", "Leads", "Conversions"],
        ...filteredData.map((monthData) => [
          monthData.month,
          monthData.totalLeads || 0,
          monthData.totalConversions || 0,
        ]),
      ];

      setChartData(formattedData);
    } catch (error) {
      console.error("Error fetching leads data for selected year:", error);
    }
  };

  const [selectedleadsYear, setSelectedleadsYear] = useState(2025);
  const [leadsyears, setLeadsyears] = useState([2025]);
  const [chartData, setChartData] = useState([
    ["Category", "Leads", "Conversions"],
  ]);

  // Chart options with more descriptive labels
  const chartOptions = {
    title: "Monthly Leads and Conversions for 2025",
    chartArea: { width: "60%" },
    hAxis: {
      title: "Month",
      slantedText: true,
      slantedTextAngle: 45,
    },
    vAxis: {
      title: "Number of Leads/Conversions",
      minValue: 0,
    },
    legend: { position: "bottom" },
    colors: ["#850000", "#FDB304"],
    bar: { groupWidth: "75%" },
  };

  useEffect(() => {
    const fetchLeadsData = async () => {
      try {
        const response = await axios.get(ApiUrl + "/leads_dashboard_2");

        // Format data, filtering out zero-value months if desired
        const formattedData = [
          ["Category", "Leads", "Conversions"],
          ...response.data
            .filter((item) => item.year === 2025) // Ensure we're using 2025 data
            .map((monthData) => [
              monthData.month,
              monthData.totalLeads,
              monthData.totalConversions,
            ]),
        ];

        setChartData(formattedData);
      } catch (error) {
        console.error("Error fetching leads data:", error);
      }
    };

    fetchLeadsData();
  }, []);

  // //////////////////////////////////////////////////////////////////////////////////////////////

  // const [responseDatas, setResponseDatas] = useState([]);
  const [revenueyears, setRevenueyears] = useState([2025]);
  const [selectedYear, setSelectedYear] = useState(2025);
  const [revenueExpenseData, setRevenueExpenseData] = useState([
    ["Month", "Revenue", "Expense"],
    ["Jan", 0, 0],
    ["Feb", 0, 0],
    ["Mar", 0, 0],
    ["Apr", 0, 0],
    ["May", 0, 0],
    ["Jun", 0, 0],
    ["Jul", 0, 0],
    ["Aug", 0, 0],
    ["Sep", 0, 0],
    ["Oct", 0, 0],
    ["Nov", 0, 0],
    ["Dec", 0, 0],
  ]);

  // Fetch branches data
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axios.get(ApiUrl + "/branch");
        setResponseDatas(response.data);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };
    fetchBranches();
  }, []);

  // Fetch revenue years and initial data
  useEffect(() => {
    const fetchRevenueData = async () => {
      try {
        const branch_id = localStorage.getItem("branch_id");
        const query =
          branch_id && branch_id !== "null" ? `?branch_id=${branch_id}` : "";

        const response = await axios.get(
          ApiUrl + `/revenue_expense_finally${query}`
        );
        const data = response.data;

        console.log("Raw Revenue Data:", data);

        // Create a mapping for easy month lookup
        const monthOrder = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Initialize data structure for all months with default values
        const chartData = monthOrder.map((month) => ({
          month,
          revenue: 0,
          expense: 0,
        }));

        // Fill in data from API
        data.forEach((item) => {
          const monthIndex = chartData.findIndex((m) => m.month === item.month);
          if (monthIndex !== -1) {
            chartData[monthIndex].revenue = item.revenue || 0;
            chartData[monthIndex].expense = item.expense || 0;
          }
        });

        // Convert to chart format
        const formattedData = [
          ["Month", "Revenue", "Expense"],
          ...chartData.map((item) => [
            item.month,
            Number(item.revenue), // Ensure numeric conversion
            Number(item.expense), // Ensure numeric conversion
          ]),
        ];

        console.log("Formatted Chart Data:", formattedData);

        setRevenueExpenseData(formattedData);

        // Set years (with fallback)
        const uniqueYears =
          data.length > 0
            ? [...new Set(data.map((item) => item.year))]
            : [2025];
        setRevenueyears(uniqueYears);
      } catch (error) {
        console.error("Error fetching revenue and expense data:", error);
      }
    };

    fetchRevenueData();
  }, []);

  // Chart options for Revenue/Expense
  const double_bar_options = {
    title: "Revenue and Expense",
    legend: { position: "bottom" },
    colors: ["#f1e101", "#cfab07"], // Colors for revenue and expense
    vAxis: {
      title: "Amount (₹)",
      minValue: 0, // Ensure the y-axis starts from 0
    },
    hAxis: {
      title: "Month",
      slantedText: true, // Slant the x-axis labels
      slantedTextAngle: 45, // Angle of the slanted text
    },
    bar: {
      groupWidth: "75%", // Bar width for better visibility
    },
    series: {
      0: { targetAxisIndex: 0 }, // Revenue series
      1: { targetAxisIndex: 1 }, // Expense series
    },
    // Adding right axis for Expense
    vAxes: {
      1: {
        title: "Expense Amount (₹)",
        minValue: 0,
      },
    },
  };

  // Handler for year selection
  const handleYearChange = async (e) => {
    const year = parseInt(e.target.value);
    setSelectedYear(year);

    try {
      const branch_id = localStorage.getItem("branch_id");
      const query =
        branch_id && branch_id !== "null" ? `?branch_id=${branch_id}` : "";

      const response = await axios.get(
        ApiUrl + `/revenue_expense_finally${query}`
      );
      const data = response.data;

      // Filter data for selected year
      const yearData = data.filter((item) => item.year === year);

      // Create a mapping for easy month lookup
      const monthOrder = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Initialize data structure for all months
      const chartData = monthOrder.map((month) => ({
        month,
        revenue: 0,
        expense: 0,
      }));

      // Fill in data from API
      yearData.forEach((item) => {
        const monthIndex = chartData.findIndex((m) => m.month === item.month);
        if (monthIndex !== -1) {
          chartData[monthIndex].revenue = Number(item.revenue) || 0;
          chartData[monthIndex].expense = Number(item.expense) || 0;
        }
      });

      // Convert to chart format
      const formattedData = [
        ["Month", "Revenue", "Expense"],
        ...chartData.map((item) => [
          item.month,
          Number(item.revenue),
          Number(item.expense),
        ]),
      ];

      setRevenueExpenseData(formattedData);
    } catch (error) {
      console.error("Error filtering revenue and expense data:", error);
    }
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{ background: "#ebebeb", marginTop: "-80px" }}
      >
        <div className="row mb-3">
          <div className="col-lg-9">
            <div className="mt-2">
              <h6>
                <b>Dashboard</b>
              </h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="position-relative">
              <select className="form-select" required>
                <option value="">All Branches</option>
                {responseDatas.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.branch_id} - {item.branch_name}
                  </option>
                ))}
              </select>
              <i
                className="fa fa-chevron-down position-absolute"
                style={{
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                }}
              ></i>
            </div>
          </div>
        </div>

        <div className="row  ">
          <div className="col-lg-12  col-md-8 col-sm-6">
            <div className="row">
              <div className="col-lg-3">
                <div
                  className="card one p-2"
                  onClick={() => navigate("/newenquiry")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className="fa-solid fa-graduation-cap iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Leads</b>
                    </h6>
                  </div>
                </div>
              </div>

              {/* Course */}

              <div className="col-lg-3">
                <div
                  className="card one p-2"
                  onClick={() => navigate("/followsups")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className="fa-solid fa-user-plus iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Pending Followups</b>{" "}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div
                  className="card one p-2"
                  onClick={() => navigate("/upcomings")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className="fa-brands fa-readme iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Project due Followups</b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div
                  className="card one p-2"
                  onClick={() => navigate("/products")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className="fa-solid fa-toggle-on iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Product</b>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12  col-md-8 col-sm-6 mt-2">
            <div className="row">
              <div className="col-lg-3">
                <div
                  className="card one p-2"
                  onClick={() => navigate("/upcomings")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className="fa-solid fa-chalkboard-user iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Projects</b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div
                  className="card one p-2 "
                  onClick={() => navigate("/upcomingservices")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className=" fa-solid fa-gears iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Services</b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div
                  className="card one p-2 "
                  onClick={() => navigate("/incomelist")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className=" fa-solid fa-hand-holding-dollar iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Income</b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div
                  className="card one p-2"
                  onClick={() => navigate("/expenselist")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className=" fa-solid fa-scale-unbalanced iconcards iconCOlors"
                        style={{
                          color: "white",

                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Expense</b>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* charts */}

        <div className="Row">
          <div className="col-lg-12 pt-2">
            <div className=" charts-container">
              <div className="col-lg-6 px-2">
                <div className="col-lg-12">
                  <h5 className="pb-2">Leads and Conversions</h5>
                  <div className="card studentcard">
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="400px"
                      data={chartData}
                      options={chartOptions}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 salescard">
                <div className="d-flex align-items-center">
                  <div>
                    <h5 className="pb-2 mb-0">Revenue / Expense</h5>
                  </div>

                  <div style={{ marginLeft: "15px" }}>
                    <select
                      className="form-select form-select-sm"
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      {revenueyears.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="card">
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={revenueExpenseData}
                    options={double_bar_options}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* charts end */}

        {/* card part 2 */}

        <div className="row mt-4">
          <div className="col-lg-12">
            <h5 className="">Pending Payment</h5>
            <div className="card p-3 mb-5">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card p-3">
                    <h5
                      className="text-center  text-white p-3"
                      style={{ backgroundColor: "#DCC005" }}
                    >
                      Salem
                    </h5>
                    <hr></hr>
                    <div className="card">
                      <div className="row details-data">
                        <div className="col-lg-6 mt-1">
                          <b>Pending Payment</b>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {/* {paymentoverallDetails?.fees || ""} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card p-3">
                    <h5
                      className="text-center  text-white p-3"
                      style={{ backgroundColor: "#DCC005" }}
                    >
                      Erode
                    </h5>
                    <hr></hr>
                    <div className="card">
                      <div className="row details-data">
                        <div className="col-lg-6 mt-1">
                          <b>Pending Payment</b>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {/* {paymentoverallDetails?.fees || ""} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
