import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "./../Environment/ApiUrl";
import "../../assets/css/Common.css";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function Contact_Leads() {
  const [responseData, setResponseData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [responsesource, setResponsesource] = useState([]);
  const [responsepurpose, setResponsepurpose] = useState([]);
  const [category, setcategory] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [staff, setstaff] = useState("");

  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      name: item.name,
      visitor_staff: item.visitor_staff,
      mobile_no: item.mobile_no,
      email: item.email,
      department: item.department,
      designation: item.designation,
      company_name: item.company_name,
      visit_date: item.visit_date,
      company_location: item.company_location,
      notes: item.notes,
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Contact Person",
        accessor: "visitor_staff",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Mobile",
        accessor: "mobile_no",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Visit Date",
        accessor: (row) => {
          const date = row.visit_date;
          return date ? format(new Date(date), "dd-MM-yyyy") : "Invalid Date";
        },
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Staff",
        accessor: "name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [addData, setAddData] = useState({
    idlocal: "",
    visitor_staff: "",
    mobile_no: "",
    email: "",
    department: "",
    designation: "",
    company_name: "",
    visit_date: new Date().toISOString().split("T")[0],
    company_location: "",
    notes: "",
  });

  const [updateData, setUpdateData] = useState({
    idlocal: "",
    visitor_staff: "",
    mobile_no: "",
    email: "",
    department: "",
    designation: "",
    company_name: "",
    visit_date: new Date().toISOString().split("T")[0],
    company_location: "",
    notes: "",
  });

  useEffect(() => {
    fetchData();
    fetch();
    fetchDa();
    Enquirypurpose();
    fetchDatag();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/update_lead_with_empid");
      console.log("Response from server conatct :", response.data);
      console.log("Response from server:12345", response.data[0]?.course_id);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDatag = async () => {
    try {
      const response = await axios.get(apiUrl + "/staff");
      console.log("Response from server:", response.data);

      setstaff(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDa = async () => {
    try {
      const responsesources = await axios.get(apiUrl + "/source_type");
      console.log("Response from Source Type:", responsesources.data);
      setResponsesource(responsesources.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const Enquirypurpose = async () => {
    try {
      const responsepurposes = await axios.get(apiUrl + "/enquiry_purpose");
      console.log("Response from Enquiry purpose:", responsepurposes.data);

      setResponsepurpose(responsepurposes.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetch = async () => {
    try {
      const response = await axios.get(apiUrl + "/category_list");
      console.log("Response from server:", response.data);
      console.log("Response from category list :", category);
      setcategory(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        name: idlocal,
        visitor_staff: addData.visitor_staff,
        mobile_no: addData.mobile_no,
        email: addData.email,
        department: addData.department,
        designation: addData.designation,
        company_name: addData.company_name,
        visit_date: addData.visit_date,
        company_location: addData.company_location,
        notes: addData.notes,
      };
      const response = await axios.post(apiUrl + "/contact_leads", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("added successfully!");
      setAddData({
        name: "",
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");
  const [showPreview, setShowPreview] = useState(false);

  const handlePreview = (data) => {
    setCommonData(data);
    setShowPreview(true);
  };

  const setCommonData = (data) => {
    const formattedDate = data.visit_date
      ? format(new Date(data.visit_date), "yyyy-MM-dd")
      : "";

    const commonData = {
      name: idlocal,
      visitor_staff: data.visitor_staff,
      mobile_no: data.mobile_no,
      email: data.email,
      department: data.department,
      designation: data.designation,
      company_name: data.company_name,
      visit_date: formattedDate, // Use the formatted date
      company_location: data.company_location,
      notes: data.notes,
    };

    console.log("Setting data: ", commonData);
    setUpdateData(commonData);
  };

  const handleUpdate = (_id, data) => {
    setUpdateId(_id);
    console.log(" the data to update onclick data   ", data);
    console.log(" the data to update onclick course id   ", data.course_id);
    setUpdateShowModal(true);
    setCommonData(data);

    console.log(" the data to update onclick   12", updateData.course_id);
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        name: idlocal,
        visitor_staff: updateData.visitor_staff,
        mobile_no: updateData.mobile_no,
        email: updateData.email,
        department: updateData.department,
        designation: updateData.designation,
        company_name: updateData.company_name,
        visit_date: updateData.visit_date,
        company_location: updateData.company_location,
        notes: updateData.notes,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        apiUrl + `/contact_leads/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        apiUrl + `/contact_leads/${getdeleteid}`
      );
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");

  const handleOpenModal = (_id, name) => {
    console.log(" the id", _id);
    console.log(" the name", name);
    setdeleteid(_id);
    setdeletename(name);
    setDeleteShowModals(true);
  };

  // the pagination ---------------------------------------------------------------------
  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchDatas = async (selected_category_2) => {
    try {
      const response = await axios.get(apiUrl + "/course");
      console.log("Response from server:123", response.data);
      console.log("Response from category list :", category);
      console.log("the selected_category is : ", selected_category_2);

      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  const [idlocal, setlocalid] = useState([]);

  useEffect(() => {
    fetchData();
    const id_local = localStorage.getItem("username");
    setlocalid(id_local);
  }, []);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
    setAddData({ visit_date: currentDate });
  }, []);

  return (
    <>
      {/* <div className="top"> */}
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="#" className="text-secondary dashBoard_text">
              <b>Leads / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Contact Leads</b>
            </Link>
          </div>
        </div>
      </div>
      {/* Table */}
      <div className="d-flex">
        <h6 className="mt-2 bold">Contact Leads</h6>
        <div className="">
          <button
            className="btn btn-dark btn-sm add mx-3"
            onClick={() => setShowModal(true)}
          >
            Add
          </button>
        </div>
      </div>

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    <button
                      className="btn custom-btn-update btn-sm"
                      onClick={() =>
                        handleUpdate(row.original._id, row.original)
                      }
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button
                      className="btn custom-btn-delete btn-sm mx-1"
                      onClick={() =>
                        handleOpenModal(
                          row.original._id,
                          row.original.name
                        )
                      }
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>

                    <button
                      className="btn custom-btn-info btn-sm"
                      onClick={() => handlePreview(row.original)}
                    >
                      <i class="fa-solid fa-eye"></i>
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      {/* Modal for adding a new user */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Contact Leads</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={idlocal || ""} // Use an empty string if idlocal is null or undefined
                    onChange={(e) =>
                      setAddData({ ...addData, idlocal: e.target.value })
                    }
                    disabled
                    required
                  />
                  <label className={idlocal ? "filled" : ""}>Staff</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.visitor_staff}
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        visitor_staff: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.visitor_staff ? "filled" : ""}>
                    Contact Person
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.mobile_no}
                    onChange={(e) =>
                      setAddData({ ...addData, mobile_no: e.target.value })
                    }
                    required
                  />
                  <label className={addData.mobile_no ? "filled" : ""}>
                    Mobile No
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="Email"
                    placeholder=""
                    value={addData.email}
                    onChange={(e) =>
                      setAddData({ ...addData, email: e.target.value })
                    }
                    required
                  />
                  <label className={addData.email ? "filled" : ""}>Email</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={addData.visit_date}
                    onChange={(e) =>
                      setAddData({ ...addData, visit_date: e.target.value })
                    }
                    required
                  />
                  <label className={addData.visit_date ? "filled" : ""}>
                    Visit Date
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={addData.department}
                  onChange={(e) =>
                    setAddData({
                      ...addData,
                      department: e.target.value,
                    })
                  }
                  required
                />
                <label className={addData.department ? "filled" : ""}>
                  Department
                </label>
              </div>
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={addData.designation}
                  onChange={(e) =>
                    setAddData({
                      ...addData,
                      designation: e.target.value,
                    })
                  }
                  required
                />
                <label className={addData.designation ? "filled" : ""}>
                  Designation
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={addData.company_name}
                  onChange={(e) =>
                    setAddData({ ...addData, company_name: e.target.value })
                  }
                  required
                />
                <label className={addData.company_name ? "filled" : ""}>
                  Company Name
                </label>
              </div>
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={addData.company_location}
                  onChange={(e) =>
                    setAddData({
                      ...addData,
                      company_location: e.target.value,
                    })
                  }
                  required
                />
                <label className={addData.company_location ? "filled" : ""}>
                  Company Location
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={addData.notes}
                  onChange={(e) =>
                    setAddData({
                      ...addData,
                      notes: e.target.value,
                    })
                  }
                  required
                />
                <label className={addData.notes ? "filled" : ""}>Remarks</label>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handlesubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Contact Leads</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={idlocal}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        idlocal: e.target.value,
                      })
                    }
                    disabled
                    required
                  />
                  <label className={idlocal ? "filled" : ""}>Staff</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.visitor_staff}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        visitor_staff: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.visitor_staff ? "filled" : ""}>
                    Contact Person
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.mobile_no}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        mobile_no: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.mobile_no ? "filled" : ""}>
                    Mobile_no
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.email}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, email: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.email ? "filled" : ""}>
                    Email
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={updateData.visit_date}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        visit_date: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.visit_date ? "filled" : ""}>
                    Visit Date
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.department}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        department: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.department ? "filled" : ""}>
                    Department
                  </label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.designation}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        designation: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.designation ? "filled" : ""}>
                    Designation
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.company_name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        company_name: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.company_name ? "filled" : ""}>
                    Company Name
                  </label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.company_location}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        company_location: e.target.value,
                      })
                    }
                    required
                  />
                  <label
                    className={updateData.company_location ? "filled" : ""}
                  >
                    Company Location
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.notes}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        notes: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.notes ? "filled" : ""}>
                    Remarks
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* preview */}

      <Modal show={showPreview} onHide={() => setShowPreview(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Preview Contact Leads</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={idlocal}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        idlocal: e.target.value,
                      })
                    }
                    disabled
                    required
                  />
                  <label className={idlocal ? "filled" : ""}>Staff</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.visitor_staff}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        visitor_staff: e.target.value,
                      })
                    }
                    disabled
                    required
                  />
                  <label className={updateData.visitor_staff ? "filled" : ""}>
                    Contact Person
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.mobile_no}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        mobile_no: e.target.value,
                      })
                    }
                    disabled
                    required
                  />
                  <label className={updateData.mobile_no ? "filled" : ""}>
                    Mobile_no
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.email}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, email: e.target.value })
                    }
                    disabled
                    required
                  />
                  <label className={updateData.email ? "filled" : ""}>
                    Email
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={updateData.visit_date}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        visit_date: e.target.value,
                      })
                    }
                    disabled
                    required
                  />
                  <label className={updateData.visit_date ? "filled" : ""}>
                    Visit Date
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.department}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        department: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label className={updateData.department ? "filled" : ""}>
                    Department
                  </label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.designation}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        designation: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label className={updateData.designation ? "filled" : ""}>
                    Designation
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.company_name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        company_name: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label className={updateData.company_name ? "filled" : ""}>
                    Company Name
                  </label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.company_location}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        company_location: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label
                    className={updateData.company_location ? "filled" : ""}
                  >
                    Company Location
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.notes}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        notes: e.target.value,
                      })
                    }
                    disabled
                  />
                  <label className={updateData.notes ? "filled" : ""}>
                    Remarks
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowPreview(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Name :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* _------------------------------pagination button -------------------------------- */}

      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact_Leads;
