import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../../assets/css/ExpectedHistory.css";
import ApiUrl from "../Environment/ApiUrl";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

function ExpectedHistory() {
  const { id } = useParams();
  const [history, setHistory] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + `/quotation_history/${id}`);
      const reversedData = response.data.reverse();
      setHistory(reversedData);
      console.log("Response from server:", reversedData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const latestHistory = history[0] || {}; // Get the latest history entry

  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleEdit = (item) => {
    setEditData(item);
    setShowModal(true);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(ApiUrl + `/update_quotation/${editData._id}`, editData);
      setHistory(
        history.map((item) => (item._id === editData._id ? editData : item))
      );
      setShowModal(false);
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const getStatusLabelClass = (status) => {
    switch (status) {
      case "Approved":
        return "bg-success1 text-white1";
      case "Rejected":
        return "bg-danger1 text-white1";
      case "Pending":
        return "bg-warning1 text-white1";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Projects / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Expected / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Project History</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 d-flex">
          <h5 className="mt-2">Project History</h5>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-lg-3 col-sm-3 col-md-3">
          <div className="card carddetails p-2">
            <div className="imgdetails">
              <h1 className="text-center fs-3 mb-4">Client Details</h1>
            </div>

            <div className="text-start mt-2 text-white">
              <h6 className="profile-detailsed">
                <div className="d-flex">
                  <i className="fa-solid fa-user px-3"></i>
                  <p>{latestHistory.name || "N/A"}</p>
                </div>
              </h6>
              <h6 className="profile-detailsed">
                <div className="d-flex">
                  <i className="fa-solid fa-phone px-3"></i>
                  <p>{latestHistory.official_mobile || "N/A"}</p>
                </div>
              </h6>
              <h6 className="profile-detailsed">
                <div className="d-flex">
                  <i className="fa-solid fa-id-card px-3"></i>
                  <p>{latestHistory.official_email || "N/A"}</p>
                </div>
              </h6>
              <h6 className="profile-detailsed">
                <div className="d-flex">
                  <i className="fa-solid fa-recycle px-3"></i>
                  No. of Attempts:
                  <p>{history.length}</p>
                </div>
              </h6>
            </div>
          </div>
        </div>

        <div className="col-lg-9 col-sm-9 col-md-9">
          <div className="row cardborder">
            <div className="card cardborder p-1">
              <div className="row p-2">
                <div className="col-lg-6">
                  <b>History</b>
                </div>
              </div>
            </div>

            <div className="card cardborder">
              <table className="table table-striped responsive">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>QN No.</th>
                    <th>Send By</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {history.length > 0 ? (
                    history.map((item, index) => (
                      <tr key={item._id}>
                        <td>{index + 1}</td>
                        <td>{item.quotation_no}</td>
                        <td>{item.send_by || "N/A"}</td>
                        <td>{new Date(item.date).toLocaleDateString()}</td>
                        <td>{item.nettotal}</td>
                        {/* <td className={getStatusLabelClass(item.status)}>
                          {item.status || "Pending"}
                        </td> */}

                        <td>
                          <span
                            className={`badge ${(() => {
                              const status = item.status
                                ? item.status.toLowerCase()
                                : ""; // Safe fallback
                              switch (status) {
                                case "Rejected":
                                  return "bg-danger";
                                case "Approved":
                                  return "bg-success";
                                case "Pending":
                                  return "bg-warning";
                                default:
                                  return "bg-secondary"; // Fallback class
                              }
                            })()}`}
                            style={{ width: "100%" }}
                          >
                            {item.status || "Pending"}{" "}
                            {/* Show "Unknown" if status is missing */}
                          </span>
                        </td>

                        <td>
                          <button
                            className="btn btn-primary btn-sm mx-1"
                            onClick={() => handleEdit(item)}
                          >
                            <i className="fa-solid fa-pen-to-square"></i>
                          </button>
                          <button className="btn btn-danger btn-sm mx-1">
                            <i className="fa-solid fa-trash-can"></i>
                          </button>
                          <button className="btn btn-info btn-sm mx-1">
                            <i className="fa-solid fa-download"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <h1 className="fs-4">Update Expected History</h1>
        </Modal.Header>
        <Modal.Body>
          <div className="floating-label">
            <input
              className="form-control"
              type="text"
              value={editData?.quotation_no || ""}
              onChange={(e) =>
                setEditData({ ...editData, quotation_no: e.target.value })
              }
              required
            />
            <label className="">Quotation No</label>
          </div>

          <div className="floating-label">
            <select
              className="form-control"
              type="text"
              value={editData?.status || ""}
              onChange={(e) =>
                setEditData({ ...editData, status: e.target.value })
              }
              required
            >
              <option value="">Select Status</option>
              <option value="Pending">Pending</option>
              <option value="Approved">Approved</option>
              <option value="Rejected">Rejected</option>
            </select>
            {/* <label className="">Quotation No</label> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ExpectedHistory;
