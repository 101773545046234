import { useState, useEffect, useRef } from "react";
import ApiUrl from "../Environment/ApiUrl";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";

function UpdateSalesInvoice() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [productsMap, setProductsMap] = useState([]);
  const [customerMap, setCustomerMap] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [stockMap, setStockMap] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
  const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
  const dropdownRef = useRef(null);
  const [branches, setBranches] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);

  const [newCustomer, setNewCustomer] = useState({
    branch_id: "",
    customer_id: "",
    name: "",
    mobile: "",
    email: "",
    company_name: "",
    door_no: "",
    street_name: "",
    area: "",
    pin_code: "",
    gst_no: "",
    notes: "",
    status: "Active",
  });

  const [updateData, setUpdateData] = useState({
    sales_order_no: "",
    date: "",
    client_name: "",
    email: "",
    mobile: "",
    paid_till_date: "",
    payment_due_date: "",
    next_due_date: "",
    person_name: "",
    contact: "",
    items: [],
    subtotal: "0.00",
    gst: "0.00",
    discount: "0.00",
    nettotal: "0.00",
    paytype: "",
    paidby: "",
    paid: "0.00",
    balance: "0.00",
    company_name: "",
    customer_id: "",
    door_no: "",
    street_name: "",
    area: "",
    pin_code: "",
    gst_no: "",
    notes: "",
    status: "",
    payment_history: [],
    branch: "",
  });

  const [productForm, setProductForm] = useState({
    products: "",
    quantity: "1",
    rate: "",
    tax: "",
    value: "0.00",
    total: "0.00",
    availableQuantity: "0",
  });

  useEffect(() => {
    axios
      .get(ApiUrl + "/salesorder/latest")
      .then((response) => {
        setUpdateData((prevData) => ({
          ...prevData,
          sales_order_no: response.data.sales_order_no,
          date: new Date().toISOString().split("T")[0],
        }));
      })
      .catch((error) =>
        console.error("Error fetching sales order number:", error)
      );
    fetchDatas();
    fetchBranches();
  }, []);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (productForm.products) {
      const availableQty = getAvailableQuantity(productForm.products);
      setProductForm((prev) => ({
        ...prev,
        availableQuantity: availableQty,
      }));
    }
  }, [productForm.products, stockMap]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCustomerDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(ApiUrl + "/product");
      console.log("Response from products:", response.data);
      setProductsMap(response.data);

      const responsedd = await axios.get(ApiUrl + "/customer");
      console.log("Response from customers:", responsedd.data);
      setCustomerMap(responsedd.data);
      setFilteredCustomers(responsedd.data);

      const responsed = await axios.get(ApiUrl + "/stock");
      console.log("Response from stock:", responsed.data);
      setStockMap(responsed.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [originalItems, setOriginalItems] = useState([]);

  const updateStockForSoldItems = async () => {
    console.log("Updating stock based on quantity changes...");

    // Create an array of promises for each item update
    const updatePromises = updateData.items.map(async (item) => {
      // Find the corresponding product in the products list
      const product = productsMap.find((prod) => prod._id === item.products);

      if (!product) {
        console.log(`Product not found for ID: ${item.products}`);
        return null;
      }

      // Find the corresponding original item (before editing)
      const originalItem = originalItems.find(
        (origItem) => origItem.products === item.products
      );

      if (!originalItem) {
        // This is a new item added to the order
        console.log(
          `New item added: ${product.name} (${product.hsn_code}), Qty: ${item.quantity}`
        );
        return updateStock(product.hsn_code, item.quantity);
      }

      // Calculate the quantity difference between original and updated
      const quantityDifference = item.quantity - originalItem.quantity;

      if (quantityDifference !== 0) {
        console.log(
          `Quantity changed for ${product.name} (${product.hsn_code}): ${originalItem.quantity} -> ${item.quantity}, Difference: ${quantityDifference}`
        );
        return updateStockWithDifference(product.hsn_code, quantityDifference);
      }

      console.log(
        `No quantity change for ${product.name} (${product.hsn_code})`
      );
      return null;
    });

    // Wait for all updates to complete
    await Promise.all(updatePromises.filter(Boolean));
    console.log("Stock update completed.");
  };

  // Function to update stock with a quantity difference
  const updateStockWithDifference = async (hsn_code, quantityDifference) => {
    try {
      if (!hsn_code || quantityDifference === 0) {
        return null;
      }

      console.log(
        `Updating stock for HSN: ${hsn_code}, Quantity Difference: ${quantityDifference}`
      );

      const response = await axios.put(
        `${ApiUrl}/stock/update-solding-quantity/${hsn_code}`,
        { soldQty: quantityDifference }
      );

      console.log(`Stock updated for HSN: ${hsn_code}`, response.data);
      toast.success(`Stock updated for product HSN: ${hsn_code}`);
      return response.data;
    } catch (error) {
      console.error(
        `Error updating stock for HSN: ${hsn_code}`,
        error.response?.data || error.message
      );

      // Show more specific error message from the server if available
      const errorMessage =
        error.response?.data?.message ||
        `Failed to update stock for product HSN: ${hsn_code}`;
      toast.error(errorMessage);
      throw error; // Re-throw to let the caller handle it
    }
  };

  // Function to update stock for a new item
  const updateStock = async (hsn_code, soldQty) => {
    try {
      if (!hsn_code || !soldQty || soldQty <= 0) {
        return null;
      }

      console.log(
        `Updating stock for new item HSN: ${hsn_code}, Sold Quantity: ${soldQty}`
      );

      const response = await axios.put(
        `${ApiUrl}/stock/update-solding-quantity/${hsn_code}`,
        { soldQty }
      );

      console.log(`Stock updated for HSN: ${hsn_code}`, response.data);
      toast.success(`Stock updated for product HSN: ${hsn_code}`);
      return response.data;
    } catch (error) {
      console.error(
        `Error updating stock for HSN: ${hsn_code}`,
        error.response?.data || error.message
      );

      const errorMessage =
        error.response?.data?.message ||
        `Failed to update stock for product HSN: ${hsn_code}`;
      toast.error(errorMessage);
      throw error;
    }
  };

  // Modified handleUpdate function to handle stock updates
  const handleUpdate = async (e) => {
    if (e) e.preventDefault();

    try {
      const formData = {
        sales_order_no: updateData.sales_order_no,
        date: updateData.date,
        client_name: updateData.client_name,
        email: updateData.email,
        mobile: updateData.mobile,
        paid_till_date: updateData.paid_till_date,
        payment_due_date: updateData.payment_due_date,
        next_due_date: updateData.next_due_date,
        person_name: updateData.person_name,
        contact: updateData.contact,
        items: updateData.items,
        subtotal: updateData.subtotal,
        gst: updateData.gst,
        discount: updateData.discount,
        nettotal: updateData.nettotal,
        paytype: updateData.paytype,
        paidby: updateData.paidby,
        paid: updateData.paid,
        balance: updateData.balance,
        company_name: updateData.company_name,
        customer_id: updateData.customer_id,
        door_no: updateData.door_no,
        street_name: updateData.street_name,
        area: updateData.area,
        pin_code: updateData.pin_code,
        gst_no: updateData.gst_no,
        notes: updateData.notes,
        status: updateData.status,
        payment_history: updateData.payment_history || [],
        branch: updateData.branch,
      };

      // First update the sales order
      const response = await axios.put(`${ApiUrl}/salesorder/${id}`, formData);
      console.log("Sales order updated successfully:", response.data);

      // Then update the stock quantities
      await updateStockForSoldItems();

      toast.success("Sales Order Updated Successfully");
      navigate("/salesorder");
    } catch (error) {
      console.error("Sales order update error:", error.message);
      toast.error("Error updating sales order: " + error.message);
    }
  };

  // Make sure to initialize originalItems when fetching the initial data
  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(`${ApiUrl}/salesorder/${id}`);
  //     console.log("Response from server:", response.data);

  //     // Process the items data
  //     let items = [];
  //     if (response.data && response.data[0] && response.data[0].items) {
  //       items = Array.isArray(response.data[0].items)
  //         ? response.data[0].items
  //         : response.data[0].items[0] || [];
  //     }

  //     if (!Array.isArray(items)) {
  //       items = [];
  //     }

  //     // Important: Save the original items for comparison during update
  //     setOriginalItems(JSON.parse(JSON.stringify(items)));

  //     // Set the response data
  //     setResponseDatas(
  //       items.map((item) => ({
  //         products: item.products,
  //         quantity: item.quantity,
  //         rate: item.rate,
  //         tax: item.tax || 0,
  //         value: item.value || ((item.rate * (item.tax || 0)) / 100).toFixed(2),
  //         total: parseFloat(item.total) || 0,
  //       }))
  //     );

  //     // Update the form data
  //     setUpdateData({
  //       ...response.data[0],
  //       items: items,
  //     });

  //     // Set payment history if available
  //     if (response.data.payment_history) {
  //       setPaymentHistory(response.data.payment_history);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //     toast.error("Error fetching sales order data");
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/salesorder/${id}`);
      console.log("Response from server:", response.data);

      // Process the items data
      let items = [];
      if (response.data && response.data[0] && response.data[0].items) {
        items = Array.isArray(response.data[0].items)
          ? response.data[0].items
          : response.data[0].items[0] || [];
      }

      if (!Array.isArray(items)) {
        items = [];
      }

      // Important: Save the original items for comparison during update
      setOriginalItems(JSON.parse(JSON.stringify(items)));

      // Set the response data
      setResponseDatas(
        items.map((item) => ({
          products: item.products,
          quantity: item.quantity,
          rate: item.rate,
          tax: item.tax || 0,
          value: item.value || ((item.rate * (item.tax || 0)) / 100).toFixed(2),
          total: parseFloat(item.total) || 0,
        }))
      );

      // Update the form data
      setUpdateData({
        ...response.data[0],
        items: items,
      });

      // Set payment history if available - FIX HERE
      if (response.data[0] && response.data[0].payment_history) {
        setPaymentHistory(response.data[0].payment_history);
      } else {
        setPaymentHistory([]); // Ensure it's an empty array if no payment history
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      toast.error("Error fetching sales order data");
    }
  };

  // GET request - Fetch all branches
  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${ApiUrl}/branch`);
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
      toast.error("Failed to fetch branches: " + error.message);
    }
  };

  // Calculate net total
  const calculateNetTotal = (subtotal, gst, discount) => {
    const subtotalVal = parseFloat(subtotal) || 0;
    const gstVal = parseFloat(gst) || 0;
    const discountVal = parseFloat(discount) || 0;
    return (subtotalVal + gstVal - discountVal).toFixed(2);
  };

  // Calculate balance
  const calculateBalance = (nettotal, paid) => {
    const nettotalVal = parseFloat(nettotal) || 0;
    const paidVal = parseFloat(paid) || 0;
    return (nettotalVal - paidVal).toFixed(2);
  };

  const resetForm = () => {
    fetchData();
    setProductForm({
      products: "",
      quantity: "1",
      rate: "",
      tax: "",
      value: "0.00",
      total: "0.00",
      availableQuantity: "0",
    });
    setEditIndex(-1);
  };

  const handleClose = () => {
    navigate("/salesorder");
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;

    if (name === "products") {
      const selectedProduct = productsMap.find((p) => p._id === value);
      let availableQty = "0";
      let rate = "0.00";
      let taxPercentage = "0.00";

      if (selectedProduct) {
        const stockItem = stockMap.find(
          (s) => s.hsn_code === selectedProduct.hsn_code
        );

        if (stockItem) {
          availableQty = stockItem.available_quantity || "0";
          const basicPrice = parseFloat(stockItem.basic_price) || 0;
          const salesMargin = parseFloat(stockItem.sales_margin) || 0;
          taxPercentage = parseFloat(stockItem.tax) || 0;

          const marginPrice = (basicPrice * salesMargin) / 100;
          rate = (basicPrice + marginPrice).toFixed(2);
        }
      }

      setProductForm((prev) => ({
        ...prev,
        products: value,
        quantity: "1",
        availableQuantity: availableQty,
        rate: rate,
        tax: taxPercentage.toFixed(2),
      }));
      recalculateTotal(1, rate, taxPercentage);
    } else {
      setProductForm((prev) => ({ ...prev, [name]: value }));
    }

    if (["quantity", "rate", "tax"].includes(name)) {
      const quantity =
        name === "quantity"
          ? parseFloat(value) || 0
          : parseFloat(productForm.quantity) || 0;
      const rate =
        name === "rate"
          ? parseFloat(value) || 0
          : parseFloat(productForm.rate) || 0;
      const taxPercentage =
        name === "tax"
          ? parseFloat(value) || 0
          : parseFloat(productForm.tax) || 0;

      recalculateTotal(quantity, rate, taxPercentage);
    }
  };

  // Helper function to recalculate tax value and total amount
  const recalculateTotal = (quantity, rate, taxPercentage) => {
    const taxValue = ((rate * taxPercentage) / 100).toFixed(2);
    const total = (quantity * (rate + parseFloat(taxValue))).toFixed(2);

    setProductForm((prev) => ({
      ...prev,
      value: taxValue,
      total: total,
    }));
  };

  const handleAdd = () => {
    if (!productForm.products) {
      toast.error("Please select a product");
      return;
    }

    const quantity = parseFloat(productForm.quantity) || 0;
    if (quantity <= 0) {
      toast.error("Quantity must be greater than zero");
      return;
    }

    const rate = parseFloat(productForm.rate) || 0;
    if (rate <= 0) {
      toast.error("Rate must be greater than zero");
      return;
    }

    const products = productForm.products;
    const tax = parseFloat(productForm.tax) || 0;
    const value = parseFloat(productForm.value) || 0;
    const total = parseFloat(productForm.total) || 0;

    // Create item object
    const newItem = {
      products,
      quantity,
      rate,
      tax,
      value,
      total,
    };

    // Add or update item in responseDatas
    if (editIndex >= 0) {
      const updatedItems = [...responseDatas];
      updatedItems[editIndex] = newItem;
      setResponseDatas(updatedItems);
      const updatedDataItems = [...updateData.items];
      updatedDataItems[editIndex] = newItem;
      const subtotal = updatedDataItems
        .reduce((sum, item) => sum + (parseFloat(item.total) || 0), 0)
        .toFixed(2);

      setUpdateData({
        ...updateData,
        items: updatedDataItems,
        subtotal,
        nettotal: calculateNetTotal(
          subtotal,
          updateData.gst,
          updateData.discount
        ),
        balance: calculateBalance(
          calculateNetTotal(subtotal, updateData.gst, updateData.discount),
          updateData.paid
        ),
      });

      setEditIndex(-1); // Reset edit index
    } else {
      setResponseDatas([...responseDatas, newItem]);
      const updatedItems = [...updateData.items, newItem];

      const subtotal = updatedItems
        .reduce((sum, item) => sum + (parseFloat(item.total) || 0), 0)
        .toFixed(2);

      setUpdateData({
        ...updateData,
        items: updatedItems,
        subtotal,
        nettotal: calculateNetTotal(
          subtotal,
          updateData.gst,
          updateData.discount
        ),
        balance: calculateBalance(
          calculateNetTotal(subtotal, updateData.gst, updateData.discount),
          updateData.paid
        ),
      });
    }
    // Reset product form
    setProductForm({
      products: "",
      quantity: "1",
      rate: "",
      tax: "",
      value: "0.00",
      total: "0.00",
      availableQuantity: "0",
    });
  };

  const handleDelete = (index) => {
    const updatedResponseDatas = responseDatas.filter((_, i) => i !== index);
    setResponseDatas(updatedResponseDatas);
    const updatedItems = updateData.items.filter((_, i) => i !== index);
    const subtotal = updatedItems
      .reduce((sum, item) => sum + (parseFloat(item.total) || 0), 0)
      .toFixed(2);
    setUpdateData({
      ...updateData,
      items: updatedItems,
      subtotal,
      nettotal: calculateNetTotal(
        subtotal,
        updateData.gst,
        updateData.discount
      ),
      balance: calculateBalance(
        calculateNetTotal(subtotal, updateData.gst, updateData.discount),
        updateData.paid
      ),
    });
  };

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;

  //   if (type === "checkbox" && name === "gst_enabled") {
  //     const gstValue = checked
  //       ? (parseFloat(updateData.subtotal) * 0.18).toFixed(2)
  //       : "0.00";
  //     const newNetTotal = calculateNetTotal(
  //       updateData.subtotal,
  //       gstValue,
  //       updateData.discount
  //     );
  //     const newBalance = calculateBalance(newNetTotal, updateData.paid);

  //     setUpdateData({
  //       ...updateData,
  //       gst: gstValue,
  //       nettotal: newNetTotal,
  //       balance: newBalance,
  //     });
  //   } else {
  //     const newValue = value;

  //     if (name === "discount" || name === "paid" || name === "gst") {
  //       const newNetTotal =
  //         name === "gst" || name === "discount"
  //           ? calculateNetTotal(
  //               updateData.subtotal,
  //               name === "gst" ? newValue : updateData.gst,
  //               name === "discount" ? newValue : updateData.discount
  //             )
  //           : updateData.nettotal;

  //       const newBalance = calculateBalance(
  //         name === "gst" || name === "discount"
  //           ? newNetTotal
  //           : updateData.nettotal,
  //         name === "paid" ? newValue : updateData.paid
  //       );

  //       setUpdateData({
  //         ...updateData,
  //         [name]: newValue,
  //         nettotal:
  //           name === "gst" || name === "discount"
  //             ? newNetTotal
  //             : updateData.nettotal,
  //         balance: newBalance,
  //       });
  //     } else {
  //       setUpdateData({
  //         ...updateData,
  //         [name]: newValue,
  //       });
  //     }
  //   }
  // };
  const [gstPercentage, setGstPercentage] = useState(18);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Handle different types of inputs
    if (type === "checkbox" && name === "gst_enabled") {
      // If GST checkbox is toggled
      const gstValue = checked
        ? (parseFloat(updateData.subtotal) * (gstPercentage / 100)).toFixed(2)
        : "0.00";
      const newNetTotal = calculateNetTotal(
        updateData.subtotal,
        gstValue,
        updateData.discount
      );
      const newBalance = calculateBalance(newNetTotal, updateData.paid);

      setUpdateData({
        ...updateData,
        gst: gstValue,
        nettotal: newNetTotal,
        balance: newBalance,
      });
    } else if (name === "gstPercentage") {
      // Handle GST percentage change
      setGstPercentage(parseFloat(value) || 0);

      // Only recalculate if GST is enabled
      if (document.querySelector('input[name="gst_enabled"]').checked) {
        const gstValue = (
          parseFloat(updateData.subtotal) *
          (parseFloat(value) / 100)
        ).toFixed(2);
        const newNetTotal = calculateNetTotal(
          updateData.subtotal,
          gstValue,
          updateData.discount
        );
        const newBalance = calculateBalance(newNetTotal, updateData.paid);

        setUpdateData({
          ...updateData,
          gst: gstValue,
          nettotal: newNetTotal,
          balance: newBalance,
        });
      }
    } else {
      // For other inputs (your existing code)
      const newValue = value;

      if (name === "discount" || name === "paid") {
        // Recalculate nettotal and balance when discount or paid changes
        const newNetTotal =
          name === "discount"
            ? calculateNetTotal(updateData.subtotal, updateData.gst, newValue)
            : updateData.nettotal;

        const newBalance = calculateBalance(
          name === "discount" ? newNetTotal : updateData.nettotal,
          name === "paid" ? newValue : updateData.paid
        );

        setUpdateData({
          ...updateData,
          [name]: newValue,
          nettotal: name === "discount" ? newNetTotal : updateData.nettotal,
          balance: newBalance,
        });
      } else {
        // For other fields, just update the value
        setUpdateData({
          ...updateData,
          [name]: newValue,
        });
      }
    }
  };

  // Select customer from dropdown
  const selectCustomer = (customer) => {
    setUpdateData({
      ...updateData,
      client_name: customer.name,
      customer_id: customer.customer_id, // Capture the ID
      email: customer.email || "",
      mobile: customer.mobile || "",
      company_name: customer.company_name || "",
      door_no: customer.door_no || "",
      street_name: customer.street_name || "",
      area: customer.area || "",
      pin_code: customer.pin_code || "",
      gst_no: customer.gst_no || "",
      notes: customer.notes || "",
      status: customer.status || "",
    });

    setShowCustomerDropdown(false);
  };

  const submitNewCustomer = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const response = await axios.post(ApiUrl + "/customer", newCustomer);
      toast.success("Customer added successfully");
      console.log("Customer added successfully", response.data);

      // Add new customer to customer map
      const updatedCustomerMap = [...customerMap, response.data];
      setCustomerMap(updatedCustomerMap);
      setFilteredCustomers(updatedCustomerMap);

      // Update the main form with the newly added customer data
      setUpdateData({
        ...updateData,
        client_name: response.data.name,
        customer_id: response.data.customer_id, // Capture the ID
        email: response.data.email || "",
        mobile: response.data.mobile || "",
        company_name: response.data.company_name || "",
        door_no: response.data.door_no || "",
        street_name: response.data.street_name || "",
        area: response.data.area || "",
        pin_code: response.data.pin_code || "",
        gst_no: response.data.gst_no || "",
        notes: response.data.notes || "",
        status: response.data.status || "",
      });

      // Close modal and reset form
      setShowNewCustomerModal(false);
      setNewCustomer({
        branch_id: "",
        customer_id: "",
        name: "",
        mobile: "",
        email: "",
        company_name: "",
        door_no: "",
        street_name: "",
        area: "",
        pin_code: "",
        gst_no: "",
        notes: "",
        status: "Active",
      });
    } catch (error) {
      console.error("Error adding customer:", error);
      toast.error("Failed to add customer");
    }
  };

  const handleDeletePayment = (id) => {
    const updatedPaymentHistory = paymentHistory.filter(
      (payment) => payment.id !== id
    );
    setPaymentHistory(updatedPaymentHistory);

    // Recalculate balance
    const totalPaid = updatedPaymentHistory
      .reduce((sum, payment) => sum + parseFloat(payment.paid), 0)
      .toFixed(2);

    const newBalance = (
      parseFloat(updateData.nettotal) - parseFloat(totalPaid)
    ).toFixed(2);

    setUpdateData({
      ...updateData,
      balance: newBalance,
    });
  };

  const handleNewCustomerChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer({
      ...newCustomer,
      [name]: value,
    });
  };

  // Helper function to get available quantity
  const getAvailableQuantity = (productId) => {
    const product = productsMap.find((p) => p._id === productId);
    if (product) {
      const stockItem = stockMap.find((s) => s.hsn_code === product.hsn_code);
      return stockItem ? stockItem.available_quantity : "0";
    }
    return "0";
  };

  // Fix the handleEdit function
  const handleEdit = (index) => {
    const items = Array.isArray(updateData.items) ? updateData.items : [];
    const item = items[index];

    if (item) {
      setProductForm({
        products: item.products,
        quantity: item.quantity.toString(),
        rate: item.rate.toString(),
        tax: item.tax ? item.tax.toString() : "0",
        value: item.value
          ? item.value.toString()
          : ((item.rate * (item.tax || 0)) / 100).toFixed(2),
        total: item.total.toString(),
        availableQuantity: getAvailableQuantity(item.products),
      });
      setEditIndex(index);
    } else {
      toast.error("Unable to edit item. Item data is missing.");
    }
  };

  const getProductNameById = (productId) => {
    const product = productsMap.find((item) => item._id === productId);
    return product
      ? `${product.hsn_code} - ${product.name}`
      : "Unknown Product";
  };

  return (
    <>
      <div>
        <h5
          className="head"
          style={{ textDecoration: "underline", marginTop: "-80px" }}
        >
          Update Sales Order
        </h5>
      </div>
      <hr></hr>
      {/* invoice no */}
      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-3 ">
            <label>Sales Order No:</label>
            <input
              type="text"
              className="form-control"
              name="sales_order_no"
              value={updateData.sales_order_no}
              onChange={(e) =>
                setUpdateData({ ...updateData, sales_order_no: e.target.value })
              }
              required
            ></input>
          </div>
          {/* date */}
          <div className="col-lg-6"></div>

          <div className="col-lg-3">
            <label>Date:</label>
            <input
              type="date"
              className="form-control"
              name="date"
              value={updateData.date}
              onChange={(e) =>
                setUpdateData({ ...updateData, date: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      {/* type */}

      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-3" ref={dropdownRef}>
            <label>Customer Name:</label>

            <div className="input-group">
              <input
                type="text"
                className="form-control"
                name="client_name"
                value={updateData.client_name}
                onChange={(e) => {
                  setUpdateData({ ...updateData, client_name: e.target.value });
                  // Filter customers based on input
                  const filtered = customerMap.filter((customer) =>
                    customer.name
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  );
                  setFilteredCustomers(filtered);
                  if (e.target.value && !showCustomerDropdown) {
                    setShowCustomerDropdown(true);
                  }
                }}
                onFocus={() => setShowCustomerDropdown(true)}
                required
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => setShowNewCustomerModal(true)}
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
            {showCustomerDropdown && (
              <div
                className="dropdown-menu show w-100"
                style={{ display: "block", position: "absolute", zIndex: 1000 }}
              >
                {filteredCustomers.length > 0 ? (
                  filteredCustomers.map((customer, index) => (
                    <button
                      key={index}
                      className="dropdown-item"
                      type="button"
                      onClick={() => selectCustomer(customer)}
                    >
                      {customer.name}
                    </button>
                  ))
                ) : (
                  <button className="dropdown-item" type="button" disabled>
                    No customers found
                  </button>
                )}
              </div>
            )}
          </div>

          {/*  email*/}
          <div className="col-lg-3">
            <label>Email:</label>
            <input
              type="text"
              className="form-control"
              name="email"
              value={updateData.email}
              onChange={(e) =>
                setUpdateData({ ...updateData, email: e.target.value })
              }
              required
            ></input>
          </div>
          {/* mobile */}
          <div className="col-lg-3">
            <label>Mobile:</label>
            <input
              type="text"
              className="form-control"
              name="mobile"
              value={updateData.mobile}
              onChange={(e) =>
                setUpdateData({ ...updateData, mobile: e.target.value })
              }
              required
            ></input>
          </div>
          {/* add button */}
          <div className="col-lg-1 mt-4"></div>
        </div>
        <div className="col-lg-2"></div>
      </div>

      {showNewCustomerModal && (
        <div
          className="modal"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.4)" }}
        >
          <div className="modal-dialog">
            <Modal
              className="addmodals"
              show={showNewCustomerModal}
              onHide={() => setShowNewCustomerModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Customer</Modal.Title>
              </Modal.Header>

              <form onSubmit={submitNewCustomer}>
                <Modal.Body>
                  <div className="row">
                    <div className="col-lg-6 ">
                      <div className="floating-label ">
                        <select
                          className="form-select"
                          name="branch_id"
                          value={newCustomer.branch_id}
                          onChange={handleNewCustomerChange}
                          required
                        >
                          <option value="">Select Branch</option>
                          {branches.map((branch) => (
                            <option key={branch._id} value={branch._id}>
                              {branch.branch_name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          name="customer_id"
                          value={newCustomer?.customer_id || ""}
                          onChange={handleNewCustomerChange}
                          disabled
                          required
                        />

                        <label className="">Customer ID</label>
                      </div>

                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          value={newCustomer.name}
                          onChange={handleNewCustomerChange}
                          required
                        />
                        <label className={newCustomer.name ? "filled" : ""}>
                          Name
                        </label>
                      </div>

                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="tel"
                          name="mobile"
                          placeholder=""
                          value={newCustomer.mobile}
                          onChange={handleNewCustomerChange}
                          required
                        />
                        <label className={newCustomer.mobile ? "filled" : ""}>
                          Mobile
                        </label>
                      </div>

                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder=""
                          value={newCustomer.email}
                          onChange={handleNewCustomerChange}
                          required
                        />
                        <label className={newCustomer.email ? "filled" : ""}>
                          Email
                        </label>
                      </div>

                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="company_name"
                          placeholder=""
                          value={newCustomer.company_name}
                          onChange={handleNewCustomerChange}
                        />
                        <label
                          className={newCustomer.company_name ? "filled" : ""}
                        >
                          Company Name
                        </label>
                      </div>

                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="door_no"
                          placeholder=""
                          value={newCustomer.door_no}
                          onChange={handleNewCustomerChange}
                        />
                        <label className={newCustomer.door_no ? "filled" : ""}>
                          Door No
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-6 ">
                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="street_name"
                          placeholder=""
                          value={newCustomer.street_name}
                          onChange={handleNewCustomerChange}
                        />
                        <label
                          className={newCustomer.street_name ? "filled" : ""}
                        >
                          Street Name
                        </label>
                      </div>

                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="area"
                          placeholder=""
                          value={newCustomer.area}
                          onChange={handleNewCustomerChange}
                        />
                        <label className={newCustomer.area ? "filled" : ""}>
                          Area
                        </label>
                      </div>

                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="pin_code"
                          placeholder=""
                          value={newCustomer.pin_code}
                          onChange={handleNewCustomerChange}
                        />
                        <label className={newCustomer.pin_code ? "filled" : ""}>
                          Pin Code
                        </label>
                      </div>

                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="gst_no"
                          placeholder=""
                          value={newCustomer.gst_no}
                          onChange={handleNewCustomerChange}
                        />
                        <label className={newCustomer.gst_no ? "filled" : ""}>
                          GST Number
                        </label>
                      </div>

                      <div className="floating-label ">
                        <input
                          className="form-control"
                          type="text"
                          name="notes"
                          placeholder=""
                          value={newCustomer.notes}
                          onChange={handleNewCustomerChange}
                        />
                        <label className={newCustomer.notes ? "filled" : ""}>
                          Notes
                        </label>
                      </div>

                      <div className="floating-label ">
                        <select
                          className="form-select"
                          name="status"
                          value={newCustomer.status}
                          onChange={handleNewCustomerChange}
                          required
                        >
                          <option value="">Select Status</option>
                          <option value="Active">Active</option>
                          <option value="InActive">InActive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    className="cancelbut"
                    variant="secondary"
                    onClick={() => setShowNewCustomerModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button className="submitbut" type="submit" variant="primary">
                    Submit
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>
        </div>
      )}

      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-2">
            <label>Products:</label>
            <select
              className="form-control"
              name="products"
              value={productForm.products}
              onChange={handleProductChange}
            >
              <option value="">Select Product</option>
              {productsMap.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.hsn_code} - {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-lg-2">
            <label>Available Qty:</label>
            <input
              type="text"
              className="form-control"
              value={productForm.availableQuantity}
              disabled
              readOnly
            />
          </div>

          <div className="col-lg-2">
            <label>Qty:</label>
            <input
              type="text"
              className="form-control"
              name="quantity"
              value={productForm.quantity}
              onChange={handleProductChange}
              required
            />
          </div>

          <div className="col-lg-1">
            <label>Rate:</label>
            <input
              type="text"
              className="form-control"
              name="rate"
              value={productForm.rate}
              onChange={handleProductChange}
              required
            />
          </div>

          <div className="col-lg-1">
            <label>Tax (%):</label>
            <input
              type="text"
              className="form-control"
              name="tax"
              value={productForm.tax}
              onChange={handleProductChange}
              required
            />
          </div>

          {/* Tax Value Input Field */}
          <div className="col-lg-1">
            <label>Value:</label>
            <input
              type="text"
              className="form-control"
              name="value"
              value={productForm.value}
              onChange={handleProductChange}
              required
            />
          </div>

          <div className="col-lg-2">
            <label>Total:</label>
            <input
              type="text"
              className="form-control"
              value={productForm.total}
              readOnly
            />
          </div>

          <div className="col-lg-1 mt-4">
            <div className="" style={{ marginTop: "-9px" }}>
              <button
                type="button"
                className="btn btn-secondary btn-sm m-3"
                onClick={handleAdd}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-2">
            <label>Purchase Order No:</label>
            <input
              type="text"
              className="form-control"
              name="paid_till_date"
              value={updateData.paid_till_date}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  paid_till_date: e.target.value,
                })
              }
            ></input>
          </div>

          <div className="col-lg-2">
            <label>Purchase Date:</label>
            <input
              type="date"
              className="form-control"
              name="payment_due_date"
              value={updateData.payment_due_date}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  payment_due_date: e.target.value,
                })
              }
            ></input>
          </div>

          <div className="col-lg-2">
            <label>Next Due Date:</label>
            <input
              type="date"
              className="form-control"
              name="next_due_date"
              value={updateData.next_due_date}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  next_due_date: e.target.value,
                })
              }
            ></input>
          </div>

          <div className="col-lg-3">
            <label>Person Name:</label>
            <input
              type="text"
              className="form-control"
              name="person_name"
              value={updateData.person_name}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  person_name: e.target.value,
                })
              }
            />
          </div>

          <div className="col-lg-3">
            <label>Contact:</label>
            <input
              type="text"
              className="form-control"
              name="contact"
              value={updateData.contact}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  contact: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>

      {/* ////////////////////////////////////////////////////////////////////////////// */}
      <div className="card invoice_card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>S.NO</th>
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Tax (%)</th>
                  <th>Tax Value</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(updateData.items) &&
                updateData.items.length > 0 ? (
                  updateData.items.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{getProductNameById(item.products)}</td>
                      <td>{item.quantity}</td>
                      <td>{item.rate}</td>
                      <td>{item.tax || 0}</td>
                      <td>
                        {item.value ||
                          ((item.rate * (item.tax || 0)) / 100).toFixed(2)}
                      </td>
                      <td>{item.total}</td>
                      <td>
                        <i
                          className="fa-solid fa-pen-to-square mx-2 fs-5"
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => handleEdit(index)}
                        ></i>
                        <i
                          className="fa-sharp-duotone fa-solid fa-circle-xmark mx-2 fs-2"
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => handleDelete(index)}
                        ></i>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No items available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card invoice_card p-3">
        <div className="row">
          <h5>Previous Payment History</h5>
          <div className="col-lg-7">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Details</th>
                  <th>Date</th>
                  <th>Paid</th>
                  <th>Paytype</th>
                  <th>Paid By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory.map((payment, index) => (
                  <tr key={payment.id}>
                    <td>{index + 1}</td>
                    <td>{payment.details}</td>
                    <td>{payment.date}</td>
                    <td>{payment.paid}</td>
                    <td>{payment.paytype}</td>
                    <td>{payment.paidby}</td>
                    <td>
                      <i
                        className="fa-sharp-duotone fa-solid fa-circle-xmark mx-2 fs-5"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleDeletePayment(payment.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
                {paymentHistory.length === 0 && (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No payment history
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="col-lg-5 ">
            <div className="row">
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Subtotal</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="subtotal"
                  value={updateData?.subtotal}
                  readOnly
                ></input>
              </div>
              {/* gst */}
              {/* <div
                className="col-lg-5 col-md-5 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <p>
                  Gst{" "}
                  <input
                    type="checkbox"
                    name="gst_enabled"
                    onChange={handleChange}
                  />
                </p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="gst"
                  value={updateData?.gst}
                  onChange={handleChange}
                />
              </div> */}
              <div
                className="col-lg-5 col-md-5 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <p>
                  GST{" "}
                  <input
                    type="checkbox"
                    name="gst_enabled"
                    onChange={handleChange}
                  />
                </p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <div className="d-flex" style={{ width: "80%" }}>
                  <input
                    type="number"
                    className="form-control mr-2"
                    name="gstPercentage"
                    value={gstPercentage}
                    onChange={handleChange}
                    style={{ width: "40%" }}
                    placeholder="GST %"
                  />
                  <input
                    style={{ width: "60%" }}
                    placeholder="0.00"
                    type="number"
                    className="form-control"
                    name="gst"
                    value={updateData.gst}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Discount</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="discount"
                  value={updateData?.discount}
                  onChange={handleChange}
                />
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Nettotal</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="nettotal"
                  value={updateData?.nettotal}
                  readOnly
                ></input>
              </div>
              {/* paytype */}
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Paytype</p>
              </div>{" "}
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <select
                  className="form-control paytype w-50"
                  name="paytype"
                  value={updateData?.paytype}
                  onChange={handleChange}
                  style={{ width: "80%" }}
                >
                  <option value="">Select Paytype</option>
                  <option value="gpay">GPay</option>
                  <option value="phonepe">PhonePe</option>
                  <option value="cash">Cash</option>
                  <option value="paytm">Paytm</option>
                </select>
              </div>
              {/* paid_by */}
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Paid By</p>
              </div>{" "}
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <select
                  className="form-control paytype"
                  name="paidby"
                  value={updateData?.paidby}
                  onChange={handleChange}
                  style={{ width: "80%" }}
                >
                  <option value="">Select Payment Paid By</option>
                  <option value="parent">Parent</option>
                  <option value="student">Student</option>
                  <option value="Gradian">Gradian</option>
                  <option value="other">Other</option>
                </select>
              </div>
              {/* paid */}
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Paid</p>
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="paid"
                  value={updateData?.paid}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="col-lg-2">
                <button
                  className="btn btn-primary btn-sm"
                  // onClick={handleAddPayment}
                >
                  Add
                </button>
              </div>
              {/* balance */}
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Balance</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="balance"
                  value={updateData?.balance}
                  readOnly
                ></input>
              </div>
            </div>

            <div className="row">
              <div className="d-flex justify-content-evenly mt-3 buttonstyle ">
                <button
                  type="button"
                  className="btn btn-danger invoice-button"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-info text-white invoice-button"
                  style={{ backgroundColor: "#007BFF" }}
                  onClick={resetForm}
                >
                  Clear
                </button>

                <button
                  type="button"
                  className="btn btn-secondary invoice-button"
                  onClick={handleUpdate}
                >
                  Update
                </button>

                <button
                  type="button"
                  className="btn btn-success invoice-button"
                  onClick={() => {
                    handleUpdate();
                    window.print();
                  }}
                >
                  Save&Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateSalesInvoice;
