import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ApiUrl from "../Environment/ApiUrl";

function AnnexureTwo() {
  const { id: expectedId } = useParams();
  const navigate = useNavigate();

  const [responseData, setResponseData] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [formData, setFormData] = useState({
    annexure_description_two: "",
    annexure_qty_two: "",
    make: "",
  });

  useEffect(() => {
    if (expectedId) {
      fetchData(expectedId);
    }
  }, [expectedId]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAdd = () => {
    const newItem = {
      annexure_description_two: formData.annexure_description_two,
      annexure_qty_two: parseFloat(formData.annexure_qty_two) || 0,
      make: formData.make,
    };

    setResponseDatas([...responseDatas, newItem]);
    setFormData({
      annexure_description_two: "",
      annexure_qty_two: "",
      make: "",
    });
  };

  const handleDelete = (index) => {
    setResponseDatas(responseDatas.filter((_, i) => i !== index));
  };

  const fetchData = async (id) => {
    try {
      const response = await axios.get(`${ApiUrl}/expected/${id}`);
      if (response.data) {
        setResponseData(Array.isArray(response.data) ? response.data : []);
        setResponseDatas(response.data.items || []);
      }

      const quotationData = await axios.get(`${ApiUrl}/quotation/${id}`);
      if (quotationData.data) {
        setQuotations(quotationData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleAnnexureThree = async () => {
    try {
      const formattedItems = responseDatas.map((item) => ({
        expected_id: expectedId, // Now correctly passing expected_id
        annexure_description_two: item.annexure_description_two,
        annexure_qty_two: parseFloat(item.annexure_qty_two) || 0,
        make: item.make,
      }));
  
      const cleanedFormData = { items: formattedItems };
  
      console.log("Sending data:", cleanedFormData);
  
      const response = await axios.put(
        `${ApiUrl}/quotation_list/${expectedId}`, // ✅ Correct URL
        cleanedFormData
      );
  
      if (response.data) {
        console.log("Successfully posted quotation data", response.data);
        navigate(`/annexturethree/${expectedId}`);
      } else {
        alert("Server returned an empty response. Please try again.");
      }
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };
  

  return (
    <>
      <div style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-secondary dashBoard_text">
              <b>Projects / </b>
            </Link>
            <Link to="/upcomings" className="text-secondary dashBoard_text">
              <b>Expected / </b>
            </Link>
            <Link to="/Quotation" className="text-secondary dashBoard_text">
              <b>Quotation / </b>
            </Link>
            <Link to="/annexuretwo" className="text-danger dashBoard_text">
              <b>Annexure Two </b>
            </Link>
          </div>
        </div>
      </div>

      <div className="mt-4 mb-3">
        <h1 className="fs-5 text-center">Annexure 2: Bill Of Materials</h1>
      </div>

      <div className="container mb-3">
        <div className="row">
          <div className="col-lg-4">
            <label>Description</label>
            <input
              className="form-control"
              name="annexure_description_two"
              value={formData.annexure_description_two}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-2">
            <label>Qty</label>
            <input
              className="form-control"
              name="annexure_qty_two"
              type="number"
              value={formData.annexure_qty_two}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-2">
            <label>Make</label>
            <input
              className="form-control"
              name="make"
              type="text"
              value={formData.make}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-2">
            <button className="btn btn-success btn-sm mt-4" onClick={handleAdd}>
              Add
            </button>
          </div>
        </div>
      </div>

      <div className="card p-3">
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th>S.NO</th>
                <th>Description</th>
                <th>Make</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {responseDatas.length > 0 ? (
                responseDatas.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.annexure_description_two}</td>
                    <td>{item.make}</td>
                    <td>
                      <i
                        className="fa-solid fa-circle-xmark mx-2 fs-4"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleDelete(index)}
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No items added
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="text-end mt-3">
        <button
          className="btn btn-secondary btn-sm"
          onClick={handleAnnexureThree}
        >
          Next
        </button>
      </div>
    </>
  );
}

export default AnnexureTwo;
