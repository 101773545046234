import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "../../assets/css/Common.css";
import "../../assets/css/updelete.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { createPortal } from "react-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import ApiUrl from "./../Environment/ApiUrl";

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #ffffff; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  position: relative;
`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <div style={{ marginBottom: "10px" }}>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search all columns..."
        style={{
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "100%",
        }}
      />
    </div>
  );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [isActive, setIsActive] = useState(false);
  const options = useMemo(() => {
    const uniqueValues = Array.from(
      new Set(preFilteredRows.map((row) => row.values[id]))
    ).map((value) => ({ label: value, value }));
    return [{ label: "All", value: "" }, ...uniqueValues];
  }, [id, preFilteredRows]);

  // Handle icon click
  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilter(values.length > 0 ? values : undefined);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <div>
        <i
          className="fa-solid fa-ellipsis-vertical"
          onClick={handleIconClick}
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#AC0000",
          }}
        ></i>
      </div>
      <div>
        {isActive &&
          createPortal(
            <div
              style={{
                position: "fixed",
                zIndex: 9999,
                left: "50%",
                top: "50%",
                width: "30%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  backgroundColor: "#A70000",
                }}
              >
                <Select
                  options={options}
                  isMulti
                  value={options.filter((option) =>
                    filterValue?.includes(option.value)
                  )}
                  onChange={handleMultiSelectChange}
                  isClearable
                  isSearchable
                  placeholder={`Filter ${id}`}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <button
                  style={{
                    marginTop: "10px",
                    marginLeft: "150px",
                    padding: "5px 10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsActive(false)} // Close the popup
                >
                  Close
                </button>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

function NewEnquiry() {
  const [showModal, setShowModal] = useState(false);
  const [updateShowModal, setUpdateShowModal] = useState(false);
  const [branch, setBranch] = useState([]);
  const [purpose, setpurpose] = useState([]);
  const [source, setsource] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [addData, setAddData] = useState({
    branch_id: "",
    name: "",
    company_name: "",
    designation: "",
    official_email: "",
    alternate_email: "",
    official_mobile: "",
    alternate_mobile: "",
    // location: "",
    door_no: "",
    street_name: "",
    area: "",
    district: "",
    pin_code: "",
    notes: "",
    source_type: "",
    enquiry_date: "",
    enquiry_time: "",
    purpose_enquiry: "",
    followups_status: "New",
    followups_time: "",
    assign_staff: "",
    contact_type: "",
    status_date: "",
  });

  const data = useMemo(() => {
    return responseData.map((item) => ({
      _id: item._id,
      branch_id: item.branch_id
        ? {
            _id: item.branch_id._id,
            branch_id: item.branch_id.branch_id,
            branch_name: item.branch_id.branch_name,
          }
        : null,
      purpose_enquiry: item.purpose_enquiry
        ? {
            _id: item.purpose_enquiry._id,
            enquiry_purpose: item.purpose_enquiry.enquiry_purpose,
          }
        : null,
      name: item.name,
      company_name: item.company_name,
      designation: item.designation,
      official_email: item.official_email,
      alternate_email: item.alternate_email,
      official_mobile: item.official_mobile,
      alternate_mobile: item.alternate_mobile,
      // location: item.location,
      door_no: item.door_no,
      street_name: item.street_name,
      area: item.area,
      district: item.district,
      pin_code: item.pin_code,
      notes: item.notes,
      source_type: item.source_type,
      enquiry_date: item.enquiry_date,
      enquiry_time:
        item.enquiry_time ||
        new Date().toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        }), // Set default time
      followups_status: item.followups_status,
      __v: item.__v,
      source_type_details: item.source_type_details
        ? item.source_type_details.map((src) => ({
            _id: src._id,
            source_type: src.source_type,
            __v: src.__v,
          }))
        : [],
    }));
  }, [responseData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        id: "serialNo",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Type",
        accessor: "purpose_enquiry.enquiry_purpose",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Source",
        accessor: (row) => {
          return row.source_type_details
            .map((src) => src.source_type)
            .join(", ");
        },
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Date",
        accessor: "enquiry_date",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },
      {
        Header: "Time",
        accessor: "enquiry_time",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
      },

      {
        Header: "Status",
        accessor: "followups_status",
        Filter: DefaultColumnFilter,
        filter: "multiSelectFilter",
        width: 120,
        height: 10,
        Cell: ({ value }) => {
          const statusClass = getStatusLabelClass(value); // Get the class for the status

          return (
            <span
              className={`${statusClass}`} // Apply the class dynamically based on the status
              style={{
                display: "inline-block",
                width: "120px", // Set fixed width for the content
                height: "20px", // Set fixed height for the content
                lineHeight: "20px", // Vertically center text inside the span
                textAlign: "center", // Center align text horizontally
              }}
            >
              {value}
            </span>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes: {
        multiSelectFilter: (rows, columnIds, filterValue) => {
          if (!filterValue || filterValue.length === 0) {
            return rows;
          }
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            const rowValueStr = String(rowValue);
            return filterValue.some((filterVal) =>
              rowValueStr.includes(String(filterVal))
            );
          });
        },
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // const [searchQuery, setSearchQuery] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  const [updateData, setupdateData] = useState("");

  // Pagination Logic
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  // const filtered = currentItems.filter((item) =>
  //   Object.values(item).some(
  //     (value) =>
  //       (typeof value === "string" &&
  //         value.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //       (typeof value === "number" && value.toString().includes(searchQuery))
  //   )
  // );

  // Fetch data from API
  useEffect(() => {
    fetchData();
    fetchDatased();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/leadsnew");
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDatased = async () => {
    try {
      const response = await axios.get(ApiUrl + "/branch");
      const responsepurposes = await axios.get(ApiUrl + "/enquiry_purpose");
      const responsesourses = await axios.get(ApiUrl + "/source_type");
      setsource(responsesourses.data);
      setpurpose(responsepurposes.data);
      setBranch(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      const formdata = {
        branch_id: addData.branch_id,
        name: addData.name,
        company_name: addData.company_name,
        designation: addData.designation,
        official_email: addData.official_email,
        alternate_email: addData.alternate_email,
        official_mobile: addData.official_mobile,
        alternate_mobile: addData.alternate_mobile,
        // location: addData.location,
        door_no: addData.door_no,
        street_name: addData.street_name,
        area: addData.area,
        district: addData.district,
        pin_code: addData.pin_code,
        notes: addData.notes,
        source_type: addData.source_type,
        enquiry_date: addData.enquiry_date,
        enquiry_time: new Date().toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        }),
        purpose_enquiry: addData.purpose_enquiry,
        followups_status: "New",
        followups_time: addData.followups_time,
        assign_staff: addData.assign_staff,
        contact_type: addData.contact_type,
        status_date: addData.status_date,
        enquiry_time: new Date().toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        }),
      };
      console.log("Form Data:", formdata);

      const responseFormData = await axios.post(ApiUrl + "/leads", formdata);
      console.log("Response from POST:", responseFormData);
      setShowModal(false);
      toast.success("Added successfully!");

      fetchData(); // Refresh the data after adding
      setAddData({
        branch_id: "",
        name: "",
        company_name: "",
        designation: "",
        official_email: "",
        alternate_email: "",
        official_mobile: "",
        alternate_mobile: "",
        // location: "",
        door_no: "",
        street_name: "",
        area: "",
        district: "",
        pin_code: "",
        notes: "",
        source_type: "",
        enquiry_date: "",
        enquiry_time: "",
        purpose_enquiry: "",
        followups_status: "New",
        followups_time: "",
        assign_staff: "",
        contact_type: "",
        status_date: "",
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to add enquiry.");
    }
  };

  const handleupdate = (key, value) => {
    setupdateData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");

  const handleOpenModal = (_id, name) => {
    console.log(" the id", _id);
    setdeleteid(_id);
    setdeletename(name);
    setDeleteShowModals(true);
  };

  const handleUpdateModal = (id, data) => {
    setUpdateShowModal(true);
    console.log("thisis slkms", data);
    setupdateData({
      _id: data._id,
      branch_id: data.branch_id._id,
      name: data.name,
      company_name: data.company_name,
      designation: data.designation,
      official_email: data.official_email,
      alternate_email: data.alternate_email,
      official_mobile: data.official_mobile,
      alternate_mobile: data.alternate_mobile,
      // location: data.location,
      door_no: data.door_no,
      street_name: data.street_name,
      area: data.area,
      district: data.district,
      pin_code: data.pin_code,
      notes: data.notes,
      source_type: data.source_type_details[0]?._id,
      enquiry_date: data.enquiry_date,
      enquiry_time: data.enquiry_time,
      purpose_enquiry: data.purpose_enquiry._id,
      followups_status: data.followups_status,
      followups_time: data.followups_time,
      assign_staff: data.assign_staff,
      contact_type: data.contact_type,
      status_date: data.status_date,
    });
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedData = {
        branch_id: updateData.branch_id,
        name: updateData.name,
        company_name: updateData.company_name,
        designation: updateData.designation,
        official_email: updateData.official_email,
        alternate_email: updateData.alternate_email,
        official_mobile: updateData.official_mobile,
        alternate_mobile: updateData.alternate_mobile,
        // location: updateData.location,
        door_no: updateData.door_no,
        street_name: updateData.street_name,
        area: updateData.area,
        district: updateData.district,
        pin_code: updateData.pin_code,
        notes: updateData.notes,
        source_type: updateData.source_type,
        enquiry_date: updateData.enquiry_date,
        enquiry_time: updateData.enquiry_time,
        purpose_enquiry: updateData.purpose_enquiry,
        followups_status: updateData.followups_status,
        followups_time: updateData.followups_time,
        assign_staff: updateData.assign_staff,
        contact_type: updateData.contact_type,
        status_date: updateData.status_date,
      };

      const response = await axios.put(
        ApiUrl + `/leads/${updateData._id}`,
        updatedData
      );
      console.log("Response from PUT:", response);
      toast.success("Updated successfully!");
      setUpdateShowModal(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
      toast.error("Failed to update enquiry.");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(ApiUrl + `/leads/${getdeleteid}`);
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setAddData((prevData) => ({
      ...prevData,
      enquiry_date: currentDate,
    }));
  }, []);

  const getStatusLabelClass = (status) => {
    switch (status) {
      case "New":
        return "bg-primary1 text-white1";

      default:
        return "";
    }
  };

  const [firstTime, setFirstTime] = useState(null);
  const [lastTime, setLastTime] = useState(null);
  const [timeDifference, setTimeDifference] = useState(null);

  const handleupdatesubmitdetails = async (id) => {
    try {
      console.log(id, "the enquiry update schudule");
      const lastTime = new Date().toLocaleTimeString("en-US", {
        hour12: true,
      });
      console.log(`End Time: ${lastTime}`);
      setLastTime(lastTime);
      console.log(`Start Time: ${firstTime}`);
      const firstTimeDate = new Date(
        `1970-01-01T${convertTo24Hour(firstTime)}Z`
      );
      const lastTimeDate = new Date(`1970-01-01T${convertTo24Hour(lastTime)}Z`);
      const timeDifferenceInMillis = lastTimeDate - firstTimeDate;
      const timeDifferenceInSeconds = timeDifferenceInMillis / 1000;
      console.log(`Time difference: ${timeDifferenceInSeconds} seconds`);
      setTimeDifference(timeDifferenceInSeconds);
      console.log(
        `Time difference: ${timeDifferenceInSeconds} seconds`,
        timeDifference
      );
      function convertTo24Hour(time) {
        const [timePart, modifier] = time.split(" ");
        let [hours, minutes, seconds] = timePart.split(":");
        if (modifier === "PM" && hours !== "12") hours = parseInt(hours) + 12;
        if (modifier === "AM" && hours === "12") hours = "00";
        return `${hours}:${minutes}:${seconds}`;
      }

      const updateDatasDetails = {
        branch_id: updateData.branch_id,
        name: updateData.name,
        company_name: updateData.company_name,
        designation: updateData.designation,
        official_email: updateData.official_email,
        alternate_email: updateData.alternate_email,
        official_mobile: updateData.official_mobile,
        alternate_mobile: updateData.alternate_mobile,
        // location: updateData.location,
        door_no: updateData.door_no,
        street_name: updateData.street_name,
        area: updateData.area,
        district: updateData.district,
        pin_code: updateData.pin_code,
        notes: updateData.notes,
        source_type: updateData.source_type,
        enquiry_date: updateData.enquiry_date,
        enquiry_time: updateData.enquiry_time,
        purpose_enquiry: updateData.purpose_enquiry,
        followups_status:
          updateData.followups_status === "New"
            ? "Scheduled"
            : updateData.followups_status,
        followups_time: updateData.followups_time,
        assign_staff: updateData.assign_staff,
        contact_type: updateData.contact_type,
        status_date: updateData.status_date,
        interest: updateData.interest,
        start_time: firstTime,
        end_time: lastTime,
      };

      try {
        console.log(
          "The console update  data as is : ",
          updateDatasDetails,
          updateid
        );
        const response = await axios.put(
          ApiUrl + `/leads/${id}`,
          updateDatasDetails
        );
        console.log("the response  leads update : ", response);
        const name_id = response.data._id;
        const formDataaa = {
          name_id: name_id,
          official_mobile: updateData.official_mobile,
          enquiry_purpose: updateData.enquiry_purpose,
          source_type: updateData.source_type,
          enquiry_date: updateData.enquiry_date,
          status_date: updateData.status_date,
          contact_type: updateData.contact_type,
          start_time: firstTime,
          end_time: lastTime,
          duration_time: updateData.duration_time,
          assign_staff: updateData.assign_staff,
          notes: updateData.notes,
          enquiry_time: updateData.enquiry_time,
          followups_status:
            updateData.followups_status === "New"
              ? "Scheduled"
              : updateData.followups_status,
        };
        console.log("the histroy beginssssss====", formDataaa);
        if (response.status == 200) {
          const response12 = await axios.post(
            ApiUrl + "/leadhistroy",
            formDataaa
          );
          console.log("the histroy begins", response12);
        }

        if (response.status == 200) {
          fetchData();
          setUpdateShowModal(false);
          toast.success("Scheduled Sucessfully!");
          // setUpdateShowModal(false);
          setUpdateScheduleModal(false);
        } else {
          toast.error("Failed to Convert !");
        }
        setUpdateShowModal(false);
      } catch (error) {
        toast.error("Error in Convert !");
        console.log("Error Fetching Data:", error.message);
      }
    } catch (error) {
      console.error("Error Updating Data:", error.message);
    }
  };

  const [updateScheduleModal, setUpdateScheduleModal] = useState(false);

  const [updateid, setUpdateId] = useState("");

  const updateshow = async (_id, data) => {
    const currentTimes = new Date().toLocaleTimeString();
    console.log(`Current Time: ${currentTimes}`);
    setFirstTime(currentTimes);

    setUpdateId(data._id);
    console.log("this vv", data);
    const filterUpdateData = await axios.get(ApiUrl + `/leads/${_id}`);
    console.log(
      "the filter daata is find here",
      filterUpdateData.data?.[0],
      "filterUpdateData___999"
    );
    console.log(" the data to updata", updateData);
    setUpdateScheduleModal(true);
    setupdateData({
      _id: data._id,
      branch_id: data.branch_id._id,
      name: data.name,
      company_name: data.company_name,
      designation: data.designation,
      official_email: data.official_email,
      alternate_email: data.alternate_email,
      official_mobile: data.official_mobile,
      alternate_mobile: data.alternate_mobile,
      // location: data.location,
      door_no: data.door_no,
      street_name: data.street_name,
      area: data.area,
      district: data.district,
      pin_code: data.pin_code,
      notes: data.notes,
      source_type: data.source_type_details[0]?._id,
      enquiry_date: data.enquiry_date,
      enquiry_time: data.enquiry_time,
      purpose_enquiry: data.purpose_enquiry._id,
      followups_status: "New",
    });
  };

  const [staff, setStaff] = useState([]);
  useEffect(() => {
    fetchStaffData();
  }, []);

  const fetchStaffData = async () => {
    console.log("the staff is getting:", setStaff);
    try {
      const responseStaff = await axios.get(ApiUrl + "/staff");
      console.log("Fetched Staff Data:", responseStaff.data);
      setStaff(responseStaff.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    console.log("Updated Staff State:", staff); // Log whenever staff state updates
  }, [staff]);
  const navigate = useNavigate();

  const updateinfo = (_id) => {
    navigate(`/leadhistroy/${_id}`);
  };

  // ========================================== Pagination

  const createPagination = () => {
    let liTag = [];
    let totalPages = pageCount; // Total pages
    let currentPage = state.pageIndex;
    let active;

    // Previous Page Button
    liTag.push(
      <li
        className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft /> Prev
        </button>
      </li>
    );

    // Always show first page
    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 0 ? "active" : ""}`}
          key={0}
        >
          <button className="page-link" onClick={() => gotoPage(0)}>
            1
          </button>
        </li>
      );
    }

    // Show second page if total pages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
          key={1}
        >
          <button className="page-link" onClick={() => gotoPage(1)}>
            2
          </button>
        </li>
      );
    }

    // Add "..." before current page group
    if (currentPage > 3) {
      liTag.push(
        <li className="page-item disabled" key="dot-prev">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Show 3 pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 3, currentPage + 1);
      i++
    ) {
      active = currentPage === i ? "active" : "";
      liTag.push(
        <li className={`page-item ${active}`} key={i}>
          <button className="page-link" onClick={() => gotoPage(i)}>
            {i + 1}
          </button>
        </li>
      );
    }

    // Add "..." after current page group
    if (currentPage < totalPages - 4) {
      liTag.push(
        <li className="page-item disabled" key="dot-next">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show last two pages if totalPages > 2
    if (totalPages > 2) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 2 ? "active" : ""
          }`}
          key={totalPages - 2}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 2)}
          >
            {totalPages - 1}
          </button>
        </li>
      );
    }

    if (totalPages > 1) {
      liTag.push(
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "active" : ""
          }`}
          key={totalPages - 1}
        >
          <button
            className="page-link"
            onClick={() => gotoPage(totalPages - 1)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    // Next Page Button
    liTag.push(
      <li className={`page-item ${!canNextPage ? "disabled" : ""}`} key="next">
        <button
          className="page-link"
          onClick={() => gotoPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          Next <FaAngleRight />
        </button>
      </li>
    );

    return liTag;
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/" className="text-secondary dashBoard_text">
              <b>Leads / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>New Enquiry</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 d-flex">
          <h6 className="mt-2 bold">New Enquiry</h6>
          <div className="">
            <button
              className="btn btn-dark btn-sm add mx-3"
              onClick={() => setShowModal(true)}
            >
              Add
            </button>
          </div>
        </div>
      </div>

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                value={state.pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 20, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={state.globalFilter || ""} // Use the globalFilter value
                    onChange={(e) => setGlobalFilter(e.target.value)} // Update global filter
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <TableContainer>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex">
                      <div className="mx-2">{column.render("Header")}</div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                      <div className="mx-1">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                    </div>
                  </Th>
                ))}
                <Th className="text-center">Action</Th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                  <Td className="text-center">
                    <button
                      className="btn custom-btn-update btn-sm mx-1"
                      onClick={() =>
                        handleUpdateModal(row.original._id, row.original)
                      }
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>

                    <button
                      className="btn custom-btn-delete btn-sm mx-1"
                      onClick={() =>
                        handleOpenModal(row.original._id, row.original.name)
                      }
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>

                    <button
                      className={
                        row.original.assign_staff == null
                          ? `btn custom-btn-user btn-sm mx-1`
                          : "d-none"
                      }
                      onClick={() => updateshow(row.original._id, row.original)}
                    >
                      <i class="fa-solid fa-user-tag"></i>
                    </button>

                    <button
                      className="btn custom-btn-info btn-sm mx-1"
                      onClick={() => updateinfo(row.original._id)}
                    >
                      <i class="fa-solid fa-circle-info"></i>
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>

      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mt-1">
            <span className="text-dark">
              Page {state.pageIndex + 1} of {pageCount}
            </span>
          </div>
          <div className="col-lg-5">
            <div className="mt-3 pb-5">
              <nav>
                <ul className="pagination justify-content-center">
                  {createPagination()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Popup Start */}
      <div className="insermodal">
        <Modal
          className="addmodals"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>New Enquiry</Modal.Title>
          </Modal.Header>
          <form onSubmit={handlesubmit}>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-6">
                  <div className="floating-label mt-1">
                    <select
                      className="form-select"
                      value={addData.branch_id}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          branch_id: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Branch</option>
                      {branch.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.branch_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={addData.purpose_enquiry}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          purpose_enquiry: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Purpose Enquiry</option>
                      {purpose.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.enquiry_purpose}{" "}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.name}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.name ? "filled" : ""}>Name</label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.company_name}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          company_name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.company_name ? "filled" : ""}>
                      Company Name
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.designation}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          designation: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.designation ? "filled" : ""}>
                      Designation
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="email"
                      placeholder=""
                      value={addData.official_email}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          official_email: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.official_email ? "filled" : ""}>
                      Official Email
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.alternate_email}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          alternate_email: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.alternate_email ? "filled" : ""}>
                      Alternate Email
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.official_mobile}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          official_mobile: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.official_mobile ? "filled" : ""}>
                      Official Mobile
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.alternate_mobile}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          alternate_mobile: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.alternate_mobile ? "filled" : ""}>
                      Alternate Mobile
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                  {/* <div className="floating-label">
                    <textarea
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.location}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          location: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.location ? "filled" : ""}>
                      Company Address
                    </label>
                  </div> */}

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.door_no}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          door_no: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.door_no ? "filled" : ""}>
                      Door No
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.street_name}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          street_name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.street_name ? "filled" : ""}>
                      Street Name
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.area}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          area: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.area ? "filled" : ""}>Area</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.district}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          district: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.district ? "filled" : ""}>
                      District
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.pin_code}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          pin_code: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.pin_code ? "filled" : ""}>
                      Pin Code
                    </label>
                  </div>

                  <div className="floating-label">
                    <textarea
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.notes}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          notes: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.notes ? "filled" : ""}>
                      Remarks
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      placeholder=""
                      value={addData.enquiry_date}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          enquiry_date: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.enquiry_date ? "filled" : ""}>
                      Enquiry Date
                    </label>
                  </div>

                  <div className="floating-label mt-1">
                    <label className="form-label">
                      <b></b>
                    </label>
                    <select
                      className="form-select"
                      placeholder=""
                      value={addData.source_type}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          source_type: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Source Type</option>
                      {source.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.source_type}
                        </option>
                      ))}
                    </select>
                    <label
                      className={addData.source_type ? "filled" : ""}
                    ></label>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="cancelbut"
                variant="secondary"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button className="submitbut" type="submit" variant="primary">
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
      {/* </div> */}

      {/* update modal */}

      <div className="insermodal">
        <Modal
          className="addmodals"
          show={updateShowModal}
          onHide={() => setUpdateShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update New Enquiry</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleupdate}>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-6">
                  <div className="floating-label mt-1">
                    <select
                      className="form-select"
                      value={updateData.branch_id}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          branch_id: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Branch</option>
                      {branch.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.branch_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={updateData.purpose_enquiry}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          purpose_enquiry: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Purpose Enquiry</option>
                      {purpose.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.enquiry_purpose}{" "}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.name}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.name ? "filled" : ""}>
                      Name
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.company_name}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          company_name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.company_name ? "filled" : ""}>
                      Company Name
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.designation}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          designation: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.designation ? "filled" : ""}>
                      Designation
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="email"
                      placeholder=""
                      value={updateData.official_email}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          official_email: e.target.value,
                        })
                      }
                      required
                    />
                    <label
                      className={updateData.official_email ? "filled" : ""}
                    >
                      Official Email
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.alternate_email}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          alternate_email: e.target.value,
                        })
                      }
                      required
                    />
                    <label
                      className={updateData.alternate_email ? "filled" : ""}
                    >
                      Alternate Email
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.official_mobile}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          official_mobile: e.target.value,
                        })
                      }
                      required
                    />
                    <label
                      className={updateData.official_mobile ? "filled" : ""}
                    >
                      Official Mobile
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.alternate_mobile}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          alternate_mobile: e.target.value,
                        })
                      }
                      required
                    />
                    <label
                      className={updateData.alternate_mobile ? "filled" : ""}
                    >
                      Alternate Mobile
                    </label>
                  </div>
                </div>

                <div className="col-lg-6">
                  {/* <div className="floating-label">
                    <textarea
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.location}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          location: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.location ? "filled" : ""}>
                      Company Address
                    </label>
                  </div> */}

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.door_no}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          door_no: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.door_no ? "filled" : ""}>
                      Door No
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.street_name}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          street_name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.street_name ? "filled" : ""}>
                      Street Name
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.area}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          area: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.area ? "filled" : ""}>
                      Area
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.district}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          district: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.district ? "filled" : ""}>
                      District
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.pin_code}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          pin_code: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.pin_code ? "filled" : ""}>
                      Pin Code
                    </label>
                  </div>

                  <div className="floating-label">
                    <textarea
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.notes}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          notes: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.notes ? "filled" : ""}>
                      Remarks
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      placeholder=""
                      value={updateData.enquiry_date}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          enquiry_date: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.enquiry_date ? "filled" : ""}>
                      Enquiry Date
                    </label>
                  </div>

                  <div className="floating-label mt-1">
                    <label className="form-label">
                      <b></b>
                    </label>
                    <select
                      className="form-select"
                      placeholder=""
                      value={updateData.source_type}
                      onChange={(e) =>
                        setupdateData({
                          ...updateData,
                          source_type: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Source Type</option>
                      {source.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.source_type}
                        </option>
                      ))}
                    </select>
                    <label
                      className={updateData.source_type ? "filled" : ""}
                    ></label>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="cancelbut"
                variant="secondary"
                onClick={() => setUpdateShowModal(false)}
              >
                Cancel
              </Button>
              {/* <Button className="submitbut" type="submit" variant="primary">
                Update
              </Button> */}
              <Button
                className="submitbut"
                type="submit"
                variant="primary"
                onClick={(e) => handleUpdateSubmit(e)}
              >
                Update
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>

      {/* update modal */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h6 className="mx-1">New Enquiry : </h6> <h6>{getdeletename} </h6>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update schedule */}

      <Modal
        className="addmodals"
        show={updateScheduleModal}
        onHide={() => setUpdateScheduleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Other input fields */}
          <div className="row">
            <div className="col-lg-6">
              <div className="floating-label mt-1">
                <select
                  className="form-select"
                  value={updateData.branch_id}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      branch_id: e.target.value,
                    })
                  }
                  disabled
                >
                  <option value="">Select Branch</option>
                  {branch.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.branch_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="floating-label">
                <select
                  className="form-select"
                  value={updateData.purpose_enquiry}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      purpose_enquiry: e.target.value,
                    })
                  }
                  disabled
                >
                  <option value="">Select Purpose Enquiry</option>
                  {purpose.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.enquiry_purpose}{" "}
                    </option>
                  ))}
                </select>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.name}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      name: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.name ? "filled" : ""}>Name</label>
              </div>
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.company_name}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      company_name: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.company_name ? "filled" : ""}>
                  Company Name
                </label>
              </div>
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.designation}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      designation: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.designation ? "filled" : ""}>
                  Designation
                </label>
              </div>
              <div className="floating-label">
                <input
                  className="form-control"
                  type="email"
                  placeholder=""
                  value={updateData.official_email}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      official_email: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.official_email ? "filled" : ""}>
                  Official Email
                </label>
              </div>
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.alternate_email}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      alternate_email: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.alternate_email ? "filled" : ""}>
                  Alternate Email
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.official_mobile}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      official_mobile: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.official_mobile ? "filled" : ""}>
                  Official Mobile
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.alternate_mobile}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      alternate_mobile: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.alternate_mobile ? "filled" : ""}>
                  Alternate Mobile
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              {/* <div className="floating-label">
                <textarea
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.location}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      location: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.location ? "filled" : ""}>
                  Location
                </label>
              </div> */}

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.door_no}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      door_no: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.door_no ? "filled" : ""}>
                  Door No
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.street_name}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      street_name: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.street_name ? "filled" : ""}>
                  Street Name
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.area}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      area: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.area ? "filled" : ""}>Area</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.district}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      district: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.district ? "filled" : ""}>
                  District
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.pin_code}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      pin_code: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.pin_code ? "filled" : ""}>
                  Pin Code
                </label>
              </div>

              <div className="floating-label">
                <textarea
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.notes}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      notes: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.notes ? "filled" : ""}>
                  Notes
                </label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=""
                  value={updateData.enquiry_date}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      enquiry_date: e.target.value,
                    })
                  }
                  disabled
                />
                <label className={updateData.enquiry_date ? "filled" : ""}>
                  Enquiry Date
                </label>
              </div>

              <div className="floating-label mt-1">
                <label className="form-label">
                  <b></b>
                </label>
                <select
                  className="form-select"
                  placeholder=""
                  value={updateData.source_type}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      source_type: e.target.value,
                    })
                  }
                  disabled
                >
                  <option value="">Select Source Type</option>
                  {source.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.source_type}
                    </option>
                  ))}
                </select>
                <label
                  className={updateData.source_type ? "filled" : ""}
                ></label>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div className="col-lg-6">
              <div className="floating-label">
                <select
                  className="form-select"
                  value={updateData.assign_staff}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      assign_staff: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select Staff</option>
                  {staff.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  // value={
                  //   updateData.status_date ||
                  //   new Date().toISOString().split("T")[0]
                  // }
                  value={updateData.status_date}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      status_date: e.target.value,
                    })
                  }
                  required
                />
                <label className={updateData.status_date ? "filled" : ""}>
                  Date
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="floating-label">
                <select
                  className="form-control"
                  value={updateData.contact_type}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      contact_type: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select Contact Type</option>
                  <option value="Phone">Phone</option>
                  <option value="Email">Email</option>
                  <option value="Whatsapp">Whatsapp</option>
                </select>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="time"
                  placeholder=""
                  value={updateData.followups_time}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      followups_time: e.target.value,
                    })
                  }
                  required
                />
                <label className={updateData.followups_time ? "filled" : ""}>
                  Time
                </label>
              </div>

              <div className="floating-label">
                <select
                  className="form-control"
                  value={updateData.followups_status || ""}
                  onChange={(e) =>
                    setupdateData({
                      ...updateData,
                      followups_status: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select Followups Status</option>
                  <option value="Rescheduled">Rescheduled</option>
                  <option value="Qualified">Qualified</option>
                  <option value="Unqualified">Unqualified</option>
                  <option value="NotReachable">Not Reachable</option>
                </select>
                <label className={updateData.followups_status ? "filled" : ""}>
                  Select Followups Status
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateScheduleModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={(e) => handleupdatesubmitdetails(updateData._id)}
          >
            Schedule
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update schedule */}
    </>
  );
}

export default NewEnquiry;
