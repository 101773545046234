import axios from "axios";
import { useState, useEffect, useRef } from "react";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

function AddPurchaseOrder() {
  const [responseDatas, setResponseDatas] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [responseDataBranch, setResponseDataBranch] = useState([]);
  const [supplierData, setSupplierData] = useState({
    branch_id: "",
    supplier_id: "",
    name: "",
    mobile_no: "",
    email: "",
    supplier_name: "",
    door_no: "",
    street_name: "",
    area: "",
    pin_code: "",
    gst_no: "",
    notes: "",
  });

  useEffect(() => {
    // Fetch latest purchase order number
    axios
      .get(ApiUrl + "/purchaseorder/latest")
      .then((response) => {
        setAddData((prevData) => ({
          ...prevData,
          purchase_order_no: response.data.purchase_order_no,
          date: new Date().toISOString().split("T")[0],
        }));
      })
      .catch((error) =>
        console.error("Error fetching purchase order number:", error)
      );
    fetchSuppliers();
    fetchBranches();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to fetch suppliers
  const fetchSuppliers = () => {
    axios
      .get(ApiUrl + "/supplier")
      .then((response) => {
        setSuppliers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching suppliers:", error);
      });
  };

  // Function to fetch branches
  const fetchBranches = () => {
    axios
      .get(ApiUrl + "/branch")
      .then((response) => {
        setResponseDataBranch(response.data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  };

  // Handle click outside dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  // Filter suppliers based on search term
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredSuppliers([]);
    } else {
      const filtered = suppliers.filter(
        (supplier) =>
          supplier.supplier_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          supplier.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          supplier.mobile_no.includes(searchTerm)
      );
      setFilteredSuppliers(filtered);
    }
  }, [searchTerm, suppliers]);

  const [addData, setAddData] = useState({
    purchase_order_no: "",
    date: "",
    supplier_name: "",
    email: "",
    mobile: "",
    items: [],
    paid_till_date: "",
    payment_due_date: "",
    next_due_date: "",
    person_name: "",
    contact: "",
    subtotal: "",
    gst: "",
    discount: "",
    nettotal: "",
    paytype: "",
    paid_by: "",
    paid: "",
    balance: "",
    branch_id: "",
  });

  const [productForm, setProductForm] = useState({
    hsn_code: "",
    product_name: "",
    quantity: "",
    price: "",
    tax: "",
    total: "0.00",
  });

  useEffect(() => {
    const items = responseDatas.map((item) => ({
      hsn_code: item.hsn_code || "",
      product_name: item.product_name || "",
      category: item.category || "",
      quantity: parseFloat(item.quantity) || 0,
      price: parseFloat(item.price) || 0,
      tax: parseFloat(item.tax) || 0,
      total: parseFloat(item.total).toFixed(2),
    }));

    // Calculate subtotal
    const subtotal = items
      .reduce((sum, item) => sum + parseFloat(item.total), 0)
      .toFixed(2);

    setAddData({
      ...addData,
      items,
      subtotal,
      nettotal: calculateNetTotal(subtotal, addData.gst, addData.discount),
      balance: calculateBalance(
        calculateNetTotal(subtotal, addData.gst, addData.discount),
        addData.paid
      ),
    });
  }, [responseDatas]);

  // Select supplier from dropdown
  const handleSelectSupplier = (supplier) => {
    setAddData({
      ...addData,
      supplier_name: supplier.supplier_name,
      email: supplier.email,
      mobile: supplier.mobile_no,
      branch_id: supplier.branch_id ? supplier.branch_id.branch_name : "",
    });
    setSearchTerm(supplier.supplier_name);
    setShowDropdown(false);
  };

  const handleSupplierSearch = (e) => {
    setSearchTerm(e.target.value);
    setAddData({
      ...addData,
      supplier_name: e.target.value,
    });
    setShowDropdown(true);
  };

  const handleSupplierInputFocus = () => {
    setShowDropdown(true);
    setFilteredSuppliers(suppliers); // Show all suppliers when focused
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredSuppliers(suppliers); // Show all suppliers when search is empty
    } else {
      const filtered = suppliers.filter(
        (supplier) =>
          supplier.supplier_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          supplier.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          supplier.mobile_no.includes(searchTerm)
      );
      setFilteredSuppliers(filtered);
    }
  }, [searchTerm, suppliers]);

  // Handle supplier modal form change
  const handleSupplierFormChange = (e) => {
    const { name, value } = e.target;
    setSupplierData({
      ...supplierData,
      [name]: value,
    });
  };

  // Submit new supplier
  const handleSupplierSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(ApiUrl + "/supplier", supplierData);
      toast.success("Supplier added successfully");
      setShowModal(false);

      console.log("the data was ressponbse", response);
      setSupplierData({
        branch_id: "",
        supplier_id: "",
        name: "",
        mobile_no: "",
        email: "",
        supplier_name: "",
        door_no: "",
        street_name: "",
        area: "",
        pin_code: "",
        gst_no: "",
        notes: "",
      });
      fetchSuppliers();

      setAddData({
        ...addData,
        supplier_name: supplierData.supplier_name,
        email: supplierData.email,
        mobile: supplierData.mobile_no,
        branch_id: supplierData.branch_id
          ? supplierData.branch_id.branch_name
          : "",
      });
      setSearchTerm(supplierData.supplier_name);
    } catch (error) {
      console.error("Error adding supplier:", error);
      toast.error("Error adding supplier");
    }
  };

  const calculateNetTotal = (subtotal, gst, discount) => {
    const subtotalVal = parseFloat(subtotal) || 0;
    const gstVal = parseFloat(gst) || 0;
    const discountVal = parseFloat(discount) || 0;

    return (subtotalVal + gstVal - discountVal).toFixed(2);
  };

  const calculateBalance = (nettotal, paid) => {
    const nettotalVal = parseFloat(nettotal) || 0;
    const paidVal = parseFloat(paid) || 0;

    return (nettotalVal - paidVal).toFixed(2);
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setProductForm({ ...productForm, [name]: value });

    if (name === "quantity" || name === "price") {
      const quantity =
        name === "quantity"
          ? parseFloat(value) || 0
          : parseFloat(productForm.quantity) || 0;
      const price =
        name === "price"
          ? parseFloat(value) || 0
          : parseFloat(productForm.price) || 0;

      const total = (quantity * price).toFixed(2);
      setProductForm((prev) => ({ ...prev, total }));
    }
  };

  const handleDelete = (index) => {
    setResponseDatas(responseDatas.filter((_, i) => i !== index));
  };

  const handleAdd = () => {
    if (
      !productForm.hsn_code ||
      !productForm.product_name ||
      !productForm.quantity ||
      !productForm.price ||
      !productForm.tax
    ) {
      toast.warning("Please fill all product details");
      return;
    }

    const hsn_code = productForm.hsn_code;
    const product_name = productForm.product_name;
    const category = productForm.category;
    const quantity = parseFloat(productForm.quantity) || 0;
    const price = parseFloat(productForm.price) || 0;
    const tax = parseFloat(productForm.tax) || 0;
    const total = parseFloat((quantity * price).toFixed(2));

    setResponseDatas([
      ...responseDatas,
      {
        hsn_code,
        product_name,
        category,
        quantity,
        price,
        tax,
        total,
      },
    ]);

    // Reset product form
    setProductForm({
      hsn_code: "",
      product_name: "",
      category: "",
      quantity: "",
      price: "",
      tax: "",
      total: "0.00",
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Handle different types of inputs
    if (type === "checkbox" && name === "gst_enabled") {
      const gstValue = checked
        ? (parseFloat(addData.subtotal) * 0.18).toFixed(2)
        : "0.00";
      const newNetTotal = calculateNetTotal(
        addData.subtotal,
        gstValue,
        addData.discount
      );
      const newBalance = calculateBalance(newNetTotal, addData.paid);

      setAddData({
        ...addData,
        gst: gstValue,
        nettotal: newNetTotal,
        balance: newBalance,
      });
    } else {
      const newValue = value;

      if (name === "discount" || name === "paid") {
        const newNetTotal =
          name === "discount"
            ? calculateNetTotal(addData.subtotal, addData.gst, newValue)
            : addData.nettotal;

        const newBalance = calculateBalance(
          name === "discount" ? newNetTotal : addData.nettotal,
          name === "paid" ? newValue : addData.paid
        );

        setAddData({
          ...addData,
          [name]: newValue,
          nettotal: name === "discount" ? newNetTotal : addData.nettotal,
          balance: newBalance,
        });
      } else {
        setAddData({
          ...addData,
          [name]: newValue,
        });
      }
    }
  };

  const [stockMap, setStockMap] = useState([]);
  const [productsMap, setProductsMap] = useState([]);

  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(ApiUrl + "/product");
      console.log("Response from products:", response.data);
      setProductsMap(response.data);

      const responsed = await axios.get(ApiUrl + "/stock");
      console.log("Response from stock:", responsed.data);
      setStockMap(responsed.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        ...addData,
        payment_history: paymentHistory,
      };

      const response = await axios.post(ApiUrl + "/purchaseorder", formData);

      if (response.status === 201) {
        console.log("Purchase Order Added Successfully", formData);

        // Update stock for purchased items
        await updateStockForPurchasedItems();

        toast.success("Purchase Order Added Successfully");
        resetForm();
        navigate("/purchaseorder");
      }
    } catch (error) {
      console.error("Error adding purchase order:", error.message);
      toast.error("Error adding purchase order");
    }
  };

  const updateStockForPurchasedItems = async () => {
    console.log("Updating stock for purchased items...");

    const updatePromises = addData.items.map(async (item) => {
      // For purchase orders, we're adding to buying_quantity and available_quantity
      return updateStockForPurchase(item.hsn_code, item.quantity);
    });

    await Promise.all(updatePromises.filter(Boolean));
    console.log("Stock update completed for purchased items.");
  };

  const updateStockForPurchase = async (hsn_code, purchasedQty) => {
    try {
      if (!hsn_code || !purchasedQty || purchasedQty <= 0) {
        toast.error(`Invalid stock update request for HSN: ${hsn_code}`);
        return;
      }

      console.log(
        `Updating stock for HSN: ${hsn_code}, Purchased Quantity: ${purchasedQty}`
      );

      // Change this to use the dedicated endpoint for buying quantity updates
      const response = await axios.put(
        `${ApiUrl}/stock/update-buying-quantity/${hsn_code}`,
        {
          purchasedQty: purchasedQty,
        }
      );

      console.log(`Stock updated for HSN: ${hsn_code}`, response.data);
      toast.success(`Stock updated for product HSN: ${hsn_code}`);
    } catch (error) {
      console.error(
        `Error updating stock for HSN: ${hsn_code}`,
        error.response?.data || error
      );
      toast.error(`Failed to update stock for product HSN: ${hsn_code}`);
    }
  };

  const resetForm = () => {
    setAddData({
      purchase_order_no: "",
      date: "",
      supplier_name: "",
      email: "",
      mobile: "",
      items: [],
      paid_till_date: "",
      payment_due_date: "",
      next_due_date: "",
      person_name: "",
      contact: "",
      subtotal: "",
      gst: "",
      discount: "",
      nettotal: "",
      paytype: "",
      paid_by: "",
      paid: "",
      balance: "",
    });
    setResponseDatas([]);
    setProductForm({
      hsn_code: "",
      product_name: "",
      category: "",
      quantity: "",
      price: "",
      tax: "",
      total: "0.00",
    });
    setSearchTerm("");
    setPaymentHistory([]); // Reset payment history
  };

  // ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // New states for product functionality
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showProductDropdown, setShowProductDropdown] = useState(false);
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const [showProductModal, setShowProductModal] = useState(false);
  const [availableQuantity, setAvailableQuantity] = useState("0");
  const productDropdownRef = useRef(null);

  // New state for product modal
  const [newProductData, setNewProductData] = useState({
    name: "",
    hsn_code: "",
    brand_name: "",
    description: "",
    status: "Active",
  });

  // Fetch products on component mount
  useEffect(() => {
    fetchProducts();

    // Add event listener for product dropdown
    document.addEventListener("mousedown", handleProductClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleProductClickOutside);
    };
  }, []);

  // Function to fetch products
  const fetchProducts = () => {
    axios
      .get(ApiUrl + "/product")
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  };

  // Handle click outside product dropdown
  const handleProductClickOutside = (event) => {
    if (
      productDropdownRef.current &&
      !productDropdownRef.current.contains(event.target)
    ) {
      setShowProductDropdown(false);
    }
  };

  // Filter products based on search term
  useEffect(() => {
    if (productSearchTerm.trim() === "") {
      setFilteredProducts(products); // Show all products when search is empty
    } else {
      const filtered = products.filter(
        (product) =>
          product.name
            .toLowerCase()
            .includes(productSearchTerm.toLowerCase()) ||
          product.hsn_code
            .toLowerCase()
            .includes(productSearchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  }, [productSearchTerm, products]);

  // Handle product search input
  const handleProductSearch = (e) => {
    setProductSearchTerm(e.target.value);
    setProductForm({
      ...productForm,
      product_name: e.target.value,
    });
    setShowProductDropdown(true);
  };

  // Handle product input focus
  const handleProductInputFocus = () => {
    setShowProductDropdown(true);
    setFilteredProducts(products); // Show all products when focused
  };

  // Select product from dropdown
  const handleSelectProduct = (product) => {
    setProductForm({
      ...productForm,
      product_name: product.name,
      hsn_code: product.hsn_code,
    });
    setProductSearchTerm(product.name);
    setShowProductDropdown(false);

    // Fetch stock information based on hsn_code
    fetchStockByHsnCode(product.hsn_code);
  };

  // Fetch stock info by hsn_code
  const fetchStockByHsnCode = (hsnCode) => {
    axios
      .get(ApiUrl + "/stock")
      .then((response) => {
        const stockItem = response.data.find(
          (item) => item.hsn_code === hsnCode
        );
        if (stockItem) {
          setAvailableQuantity(stockItem.available_quantity.toString());
          setProductForm((prevForm) => ({
            ...prevForm,
            price: stockItem.basic_price.toString(),
            tax: stockItem.tax.toString(),
          }));
          const quantity = parseFloat(productForm.quantity) || 0;
          const price = parseFloat(stockItem.basic_price) || 0;
          const total = (quantity * price).toFixed(2);

          setProductForm((prevForm) => ({
            ...prevForm,
            total: total,
          }));
        } else {
          setAvailableQuantity("0");
        }
      })
      .catch((error) => {
        console.error("Error fetching stock information:", error);
        setAvailableQuantity("0");
      });
  };

  // Handle product modal form change
  const handleProductFormChange = (e) => {
    const { name, value } = e.target;
    setNewProductData({
      ...newProductData,
      [name]: value,
    });
  };

  // Submit new product
  const handleProductSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(ApiUrl + "/product", newProductData);
      toast.success("Product added successfully");
      setShowProductModal(false);
      setNewProductData({
        name: "",
        hsn_code: "",
        brand_name: "",
        description: "",
        status: "Active",
      });
      fetchProducts();
      setProductForm({
        ...productForm,
        product_name: newProductData.name,
        hsn_code: newProductData.hsn_code,
      });
      setProductSearchTerm(newProductData.name);
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Error adding product");
    }
  };

  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [paymentHistory, setPaymentHistory] = useState([]);

  const handleDeletePayment = (id) => {
    const updatedPaymentHistory = paymentHistory.filter(
      (payment) => payment.id !== id
    );
    setPaymentHistory(updatedPaymentHistory);

    // Recalculate balance
    const totalPaid = updatedPaymentHistory
      .reduce((sum, payment) => sum + parseFloat(payment.paid), 0)
      .toFixed(2);

    const newBalance = (
      parseFloat(addData.nettotal) - parseFloat(totalPaid)
    ).toFixed(2);

    setAddData({
      ...addData,
      balance: newBalance,
    });
  };

  const handleAddPayment = () => {
    if (addData.paid && addData.paytype) {
      const newPayment = {
        id: Date.now(), // Generate a unique ID using timestamp
        details: `Payment for ${addData.sales_order_no}`,
        date: new Date().toISOString().split("T")[0], // Current date
        paid: addData.paid,
        paytype: addData.paytype,
        paidby: addData.paidby || "Unknown",
      };

      // Add to payment history
      setPaymentHistory([...paymentHistory, newPayment]);

      // Calculate new balance
      const totalPaid = [...paymentHistory, newPayment]
        .reduce((sum, payment) => sum + parseFloat(payment.paid), 0)
        .toFixed(2);

      const newBalance = (
        parseFloat(addData.nettotal) - parseFloat(totalPaid)
      ).toFixed(2);

      // Reset payment fields but keep the calculated balance
      setAddData({
        ...addData,
        paid: "0.00",
        paytype: "",
        paidby: "",
        balance: newBalance,
      });
    } else {
      toast.error("Please enter payment amount and payment type");
    }
  };

  return (
    <>
      <div>
        <h5
          className="head"
          style={{ textDecoration: "underline", marginTop: "-80px" }}
        >
          New Purchase Order
        </h5>
      </div>
      <hr />
      {/* invoice no */}
      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-3 ">
            <label>Purchase Order No:</label>
            <input
              type="text"
              className="form-control"
              name="purchase_order_no"
              value={addData.purchase_order_no}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  purchase_order_no: e.target.value,
                })
              }
              required
            />
          </div>
          {/* date */}
          <div className="col-lg-6"></div>

          <div className="col-lg-3">
            <label>Date:</label>
            <input
              type="date"
              className="form-control"
              name="date"
              value={addData.date}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  date: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      {/* type */}

      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-3" ref={dropdownRef}>
            <label> Select Supplier:</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                name="supplier_name"
                value={searchTerm}
                onChange={handleSupplierSearch}
                onFocus={handleSupplierInputFocus}
                required
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => setShowModal(true)}
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
            {showDropdown && (
              <div
                className="dropdown-menu show"
                style={{ maxHeight: "200px", width: "25%", overflow: "auto" }}
              >
                {filteredSuppliers.length > 0 ? (
                  filteredSuppliers.map((supplier, index) => (
                    <button
                      key={index}
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleSelectSupplier(supplier)}
                    >
                      {supplier.supplier_name} - {supplier.name} -{" "}
                      {supplier.mobile_no} - {supplier.branch_id?.branch_name}
                    </button>
                  ))
                ) : (
                  <button className="dropdown-item disabled" type="button">
                    No products found
                  </button>
                )}
              </div>
            )}

            {/* Modal for adding a new supplier */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Add New Supplier</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSupplierSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="floating-label">
                          <select
                            className="form-select"
                            name="branch_id"
                            value={supplierData.branch_id}
                            onChange={handleSupplierFormChange}
                            required
                          >
                            <option value="">Select Branch</option>
                            {responseDataBranch.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.branch_id} - {item.branch_name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            name="supplier_id"
                            value={supplierData.supplier_id}
                            onChange={handleSupplierFormChange}
                            required
                          />
                          <label
                            className={addData.supplier_id ? "filled" : ""}
                          >
                            Supplier ID
                          </label>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            name="name"
                            value={supplierData.name}
                            onChange={handleSupplierFormChange}
                            required
                          />
                          <label className={addData.name ? "filled" : ""}>
                            Name
                          </label>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            name="mobile_no"
                            value={supplierData.mobile_no}
                            onChange={handleSupplierFormChange}
                            required
                          />
                          <label className={addData.mobile_no ? "filled" : ""}>
                            Mobile
                          </label>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            placeholder=""
                            type="email"
                            name="email"
                            value={supplierData.email}
                            onChange={handleSupplierFormChange}
                            required
                          />
                          <label className={addData.email ? "filled" : ""}>
                            Email
                          </label>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            name="supplier_name"
                            value={supplierData.supplier_name}
                            onChange={handleSupplierFormChange}
                            required
                          />
                          <label
                            className={addData.supplier_name ? "filled" : ""}
                          >
                            Supplier Name
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            name="door_no"
                            value={supplierData.door_no}
                            onChange={handleSupplierFormChange}
                            required
                          />
                          <label className={addData.door_no ? "filled" : ""}>
                            Door No
                          </label>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            name="street_name"
                            value={supplierData.street_name}
                            onChange={handleSupplierFormChange}
                            required
                          />
                          <label
                            className={addData.street_name ? "filled" : ""}
                          >
                            Street Name
                          </label>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            name="area"
                            value={supplierData.area}
                            onChange={handleSupplierFormChange}
                            required
                          />
                          <label className={addData.area ? "filled" : ""}>
                            Area
                          </label>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            name="pin_code"
                            value={supplierData.pin_code}
                            onChange={handleSupplierFormChange}
                            required
                          />
                          <label className={addData.pin_code ? "filled" : ""}>
                            Pin Code
                          </label>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            name="gst_no"
                            value={supplierData.gst_no}
                            onChange={handleSupplierFormChange}
                            required
                          />
                          <label className={addData.gst_no ? "filled" : ""}>
                            GST
                          </label>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            placeholder=""
                            type="text"
                            name="notes"
                            value={supplierData.notes}
                            onChange={handleSupplierFormChange}
                          />
                          <label className={addData.notes ? "filled" : ""}>
                            Notes
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    variant="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" className="ms-2">
                    Submit
                  </Button>
                </form>
              </Modal.Body>
            </Modal>
          </div>
          {/*  email*/}
          <div className="col-lg-3">
            <label>Email:</label>
            <input
              type="text"
              className="form-control"
              name="email"
              value={addData.email}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  email: e.target.value,
                })
              }
              required
            />
          </div>
          {/* mobile */}
          <div className="col-lg-3">
            <label>Mobile:</label>
            <input
              type="text"
              className="form-control"
              name="mobile"
              value={addData.mobile}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  mobile: e.target.value,
                })
              }
              required
            />
          </div>

          <div className="col-lg-3">
            <label>Branch:</label>
            <input
              type="text"
              className="form-control"
              name="mobile"
              value={addData.branch_id}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  branch_id: e.target.value,
                })
              }
              required
            />
          </div>
          {/* add button */}
          <div className="col-lg-1 mt-4"></div>
        </div>

        <div className="col-lg-2"></div>
      </div>

      <div className="card invoice_card p-3">
        <div className="row ">
          <div className="card invoice_card p-3">
            <div className="row ">
              <div className="col-lg-3">
                <label>Product Name:</label>
                <div className="input-group">
                  <input
                    className="form-control"
                    name="product_name"
                    value={productSearchTerm}
                    onChange={handleProductSearch}
                    onFocus={handleProductInputFocus}
                    autoComplete="off"
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => setShowProductModal(true)}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
                {showProductDropdown && (
                  <div
                    ref={productDropdownRef}
                    className="dropdown-menu show"
                    style={{
                      display: "block",
                      width: "100%",
                      maxHeight: "200px",
                      overflowY: "auto",
                    }}
                  >
                    {filteredProducts.length > 0 ? (
                      filteredProducts.map((product) => (
                        <button
                          key={product._id}
                          className="dropdown-item"
                          type="button"
                          onClick={() => handleSelectProduct(product)}
                        >
                          {product.name} - {product.hsn_code}
                        </button>
                      ))
                    ) : (
                      <button className="dropdown-item" type="button" disabled>
                        No products found
                      </button>
                    )}
                  </div>
                )}
              </div>

              <div className="col-lg-3">
                <label>Available Qty:</label>
                <input
                  type="text"
                  className="form-control"
                  value={availableQuantity}
                  readOnly
                  disabled
                />
              </div>

              <div className="col-lg-3">
                <label>HSN Code:</label>
                <input
                  type="text"
                  className="form-control"
                  name="hsn_code"
                  value={productForm.hsn_code}
                  onChange={handleProductChange}
                  required
                />
              </div>

              <div className="col-lg-3">
                <label>Quantity:</label>
                <input
                  type="text"
                  className="form-control"
                  name="quantity"
                  value={productForm.quantity}
                  onChange={handleProductChange}
                  required
                />
              </div>
            </div>

            {/* Rest of the existing product form */}
            <div className="row mt-3">
              <div className="col-lg-3">
                <label>Price:</label>
                <input
                  type="text"
                  className="form-control"
                  name="price"
                  value={productForm.price}
                  onChange={handleProductChange}
                  required
                />
              </div>
              <div className="col-lg-3">
                <label>Tax:</label>
                <input
                  type="text"
                  className="form-control"
                  name="tax"
                  value={productForm.tax}
                  onChange={handleProductChange}
                  required
                />
              </div>

              <div className="col-lg-3">
                <label>Total:</label>
                <input
                  type="text"
                  className="form-control"
                  name="total"
                  value={productForm.total}
                  readOnly
                />
              </div>

              <div className="col-lg-3 mt-4">
                <button
                  type="button"
                  className="btn btn-secondary Add"
                  onClick={handleAdd}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card invoice_card p-3">
        <div className="row first">
          <div className="col-lg-2">
            <label>Paid Till Date:</label>
            <input
              type="text"
              className="form-control"
              name="paid_till_date"
              value={addData.paid_till_date}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  paid_till_date: e.target.value,
                })
              }
            />
          </div>

          <div className="col-lg-2">
            <label>Payment Due Date:</label>
            <input
              type="date"
              className="form-control"
              name="payment_due_date"
              value={addData.payment_due_date}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  payment_due_date: e.target.value,
                })
              }
            />
          </div>

          <div className="col-lg-2">
            <label>Next Due Date:</label>
            <input
              type="date"
              className="form-control"
              name="next_due_date"
              value={addData.next_due_date}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  next_due_date: e.target.value,
                })
              }
            />
          </div>

          <div className="col-lg-3">
            <label>Person Name:</label>
            <input
              type="text"
              className="form-control"
              name="person_name"
              value={addData.person_name}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  person_name: e.target.value,
                })
              }
            />
          </div>

          <div className="col-lg-3">
            <label>Contact:</label>
            <input
              type="text"
              className="form-control"
              name="contact"
              value={addData.contact}
              onChange={(e) =>
                setAddData({
                  ...addData,
                  contact: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>

      {/* ////////////////////////////////////////////////////////////////////////////// */}
      <div className="card invoice_card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>S.NO</th>
                  <th>Description</th>
                  <th>HSN Code</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Tax</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {responseDatas.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.product_name}</td>
                    <td>{item.hsn_code}</td>
                    <td>{item.quantity}</td>
                    <td>{item.price}</td>
                    <td>{item.tax}</td>
                    <td>{parseFloat(item.total).toFixed(2)}</td>
                    <td>
                      <i
                        className="fa-sharp-duotone fa-solid fa-circle-xmark mx-2 fs-2"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleDelete(index)}
                      ></i>
                    </td>
                  </tr>
                ))}
                {responseDatas.length === 0 && (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No items added
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <div className="card invoice_card p-3">
        <div className="row">
          <h5>Previous Payment History</h5>
          <div className="col-lg-7">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Details</th>
                  <th>Date</th>
                  <th>Paid</th>
                  <th>Paytype</th>
                  <th>Paid By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory.map((payment, index) => (
                  <tr key={payment.id}>
                    <td>{index + 1}</td>
                    <td>{payment.details}</td>
                    <td>{payment.date}</td>
                    <td>{payment.paid}</td>
                    <td>{payment.paytype}</td>
                    <td>{payment.paidby}</td>
                    <td>
                      <i
                        className="fa-sharp-duotone fa-solid fa-circle-xmark mx-2 fs-5"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleDeletePayment(payment.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
                {paymentHistory.length === 0 && (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No payment history
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* subtotal */}
          <div className="col-lg-5 ">
            <div className="row">
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Subtotal</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="subtotal"
                  value={addData.subtotal}
                  readOnly
                />
              </div>
              {/* gst */}
              <div
                className="col-lg-5 col-md-5 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <p>
                  Gst{" "}
                  <input
                    type="checkbox"
                    name="gst_enabled"
                    onChange={handleChange}
                  />
                </p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="gst"
                  value={addData.gst}
                  onChange={handleChange}
                />
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Discount</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="discount"
                  value={addData.discount}
                  onChange={handleChange}
                />
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Nettotal</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="nettotal"
                  value={addData.nettotal}
                  readOnly
                />
              </div>
              {/* paytype */}
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Paytype</p>
              </div>{" "}
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <select
                  className="form-control paytype"
                  name="paytype"
                  value={addData.paytype}
                  onChange={handleChange}
                >
                  <option value="">Select Paytype</option>
                  <option value="gpay">GPay</option>
                  <option value="phonepe">PhonePe</option>
                  <option value="cash">Cash</option>
                  <option value="paytm">Paytm</option>
                </select>
              </div>
              {/* paid_by */}
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Paid By</p>
              </div>{" "}
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <select
                  className="form-control paytype"
                  name="paid_by"
                  value={addData.paid_by}
                  onChange={handleChange}
                >
                  <option value="">Select Payment Paid By</option>
                  <option value="Client">Client</option>
                  <option value="Company">Company</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              {/* paid */}
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Paid</p>
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="paid"
                  value={addData.paid}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-2">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleAddPayment}
                >
                  Add
                </button>
              </div>
              {/* balance */}
              <div
                className="col-lg-5 col-md-5 col-sm-12 "
                style={{ textAlign: "center" }}
              >
                <p>Balance</p>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ width: "80%" }}
                  placeholder="0.00"
                  type="number"
                  className="form-control"
                  name="balance"
                  value={addData.balance}
                  readOnly
                />
              </div>
            </div>

            <div className="row">
              <div className="d-flex justify-content-evenly mt-3 buttonstyle ">
                <button
                  type="button"
                  className="btn btn-danger invoice-button"
                  onClick={() => navigate("/purchaseorder")}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-info text-white invoice-button"
                  style={{ backgroundColor: "#007BFF" }}
                  onClick={resetForm}
                >
                  Clear
                </button>

                <button
                  type="button"
                  className="btn btn-secondary invoice-button"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-success invoice-button"
                  onClick={() => {
                    handleSubmit();
                    // Add print functionality here
                    window.print();
                  }}
                >
                  Save&Print
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={showProductModal}
          onHide={() => setShowProductModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleProductSubmit}>
              <div className="mb-3">
                <label className="form-label">Product Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={newProductData.name}
                  onChange={handleProductFormChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">HSN Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="hsn_code"
                  value={newProductData.hsn_code}
                  onChange={handleProductFormChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Brand Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="brand_name"
                  value={newProductData.brand_name}
                  onChange={handleProductFormChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  className="form-control"
                  name="description"
                  value={newProductData.description}
                  onChange={handleProductFormChange}
                ></textarea>
              </div>
              <div className="mb-3">
                <label className="form-label">Status</label>
                <select
                  className="form-select"
                  name="status"
                  value={newProductData.status}
                  onChange={handleProductFormChange}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="text-end">
                <Button
                  variant="secondary"
                  onClick={() => setShowProductModal(false)}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit" className="ms-2">
                  Submit
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default AddPurchaseOrder;
