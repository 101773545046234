



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import ApiUrl from '../Environment/ApiUrl';
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";

function Salary_generate() {
    const [leave, setleave] = useState([]);
    const [attendance, setattendance] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [posting, setPosting] = useState(false);  // State for handling the "Add" button post

    const navigate = useNavigate();

    useEffect(() => {
        fetchDataleave();
        fetchDataattendnace();
    }, []);

    const fetchDataattendnace = async () => {
        try {
            const response = await axios.get(ApiUrl + "/attendance_overall");
            setattendance(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const fetchDataleave = async () => {
        try {
            const response = await axios.get(ApiUrl + "/leave");
            setleave(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };



    const handleGenerate = async () => {
        if (!fromDate || !toDate) {
            alert("Please select both From Date and To Date");
            return;
        }

        const parsedFromDate = new Date(fromDate);
        const parsedToDate = new Date(toDate);

        const fromDateStr = parsedFromDate.toISOString().split("T")[0];
        const toDateStr = parsedToDate.toISOString().split("T")[0];

        try {
            setLoading(true);
            const response = await axios.get(ApiUrl + "/salary_getall", {
                params: {
                    startdate: fromDateStr,
                    todate: toDateStr,
                },
            });

            if (response.status === 200) {
                setFilteredData(response.data);
                toast.success("Salary data generated successfully!");
            } else {
                toast.error("Failed to generate salary data");
            }
        } catch (error) {
            console.error("Error generating salary data:", error);
            toast.error("An error occurred while fetching the data.");
        } finally {
            setLoading(false);
        }
    };



    const handleAdd = async () => {
        // Log the fromDate and toDate to check their values
        console.log("From Date:", fromDate);
        console.log("To Date:", toDate);

        // Ensure fromDate and toDate are provided
        if (!fromDate || !toDate) {
            alert("Please provide both fromDate and toDate.");
            return;
        }

        // Parse fromDate and toDate into date objects
        const parsedFromDate = new Date(fromDate);
        const parsedToDate = new Date(toDate);

        // Check if the dates are valid
        if (isNaN(parsedFromDate) || isNaN(parsedToDate)) {
            alert("Invalid date format. Please provide valid dates.");
            return;
        }

        // Convert the dates into 'yyyy-mm-dd' format
        const startDate = parsedFromDate.toISOString().split("T")[0];  // Use startDate and endDate here
        const endDate = parsedToDate.toISOString().split("T")[0];

        // Prepare postData with the filtered data and date range
        const postData = filteredData.map(item => ({
            ...item,
            startdate: startDate, // Include the startDate in the posted data
            todate: endDate,      // Include the endDate in the posted data
        }));

        try {
            setPosting(true);

            // Send filteredData (with date range) to the backend for posting
            const response = await axios.post(ApiUrl + "/salary_post", postData);



            if (response.status === 200) {
                toast.success("Salary data added successfully!");
                navigate("/salarydetails");  // Redirect to the salary page after adding data
            } else {
                toast.error("Failed to add salary data");
            }
        } catch (error) {
            console.error("Error posting salary data:", error);
            toast.error("An error occurred while posting the data.");
        } finally {
            setPosting(false);
        }
    };





    // Pagination and search logic
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const filtered = currentItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (typeof value === "number" && value.toString().includes(searchQuery))
        )
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage === totalPages ? prevPage : prevPage + 1
        );
    };

    const goToPrevPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
    };

    return (
        <>
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-message">
                        {/* <img
                            className="loadinggif"
                            src={require("../../assets/img/output-onlinegiftools.gif")}
                        /> */}
                    </div>
                </div>
            )}
            <div className='top'>
                <div className="" style={{ marginTop: '-80px' }}>
                    <div className="row mb-3">
                        <div className="col-lg-11">
                            <Link to="/dashboard" className="text-secondary dashBoard_text">
                                <b>Dashboard / </b>
                            </Link>
                            <Link to="/salarydetails" className="text-secondary dashBoard_text">
                                <b>Salary Details/ </b>
                            </Link>
                            <Link to="" className="text-danger dashBoard_text">
                                <b>Salary Generate</b>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='d-flex'>
                    <h5 className='mt-2'>Salary Generate</h5>
                </div>

                {/* Date picker and Generate button */}
                <div className="container my-1">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                            <label htmlFor="fromDate" className="form-label fw-bold">
                                From Date:
                            </label>
                            <input
                                type="date"
                                className="form-control"
                                value={fromDate}
                                onChange={handleFromDateChange}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                            <label htmlFor="toDate" className="form-label fw-bold">
                                To Date:
                            </label>
                            <input
                                type="date"
                                className="form-control"
                                value={toDate}
                                onChange={handleToDateChange}
                            />
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mt-3 d-flex align-items-end">
                            <button className="btn btn-dark w-100" onClick={handleGenerate}>
                                Generate
                            </button>
                        </div>
                    </div>
                </div>

                {/* Display salary data */}
                <div className='card'>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Staff ID</th>
                                <th>Staff Name</th>
                                <th>Designation</th>
                                <th>Month & Year</th>
                                <th>Actual Salary</th>
                                <th>Paid Salary</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.length > 0 ? (
                                filtered.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.staffId}</td>
                                        <td>{item.staffName}</td>
                                        <td>{item.designation}</td>
                                        <td>{item.monthYear}</td>
                                        <td>{item.actualSalary}</td>
                                        <td>{item.paidSalary}</td>
                                        <td>{item.status}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className='text-center'>No data available! Please generate salary data first.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className='row'>
                    <div className='col-lg-4'></div>
                    <div className='col-lg-4'>
                        <div className="mt-3">
                            <button className="btn btn-dark w-100" onClick={handleAdd} disabled={posting}>
                                {posting ? "Adding..." : "Add Salary Data"}
                            </button>
                        </div>
                    </div>
                    <div className='col-lg-4'></div>
                </div>


                {/* Pagination */}
                <div className="mt-3 pb-5">
                    <div className="row">
                        <div className="col-lg-10 col-sm-12">
                            <nav className="d-flex float-right">
                                <ul className="pagination justify-content-center">
                                    <li
                                        className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                                    >
                                        <button className="page-link" onClick={goToPrevPage}>
                                            Previous
                                        </button>
                                    </li>
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <li
                                            key={i}
                                            className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""}`}
                                        >
                                            <button
                                                className="page-link"
                                                onClick={() => paginate(i + 1)}
                                            >
                                                {i + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li
                                        className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                                    >
                                        <button className="page-link" onClick={goToNextPage}>
                                            Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-lg-2 col-sm-12 mt-1">
                            <div className="float-end">
                                <span className="text-dark">
                                    Page {currentPage} of {totalPages}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Add Button */}

            </div>
        </>
    );
}

export default Salary_generate;





